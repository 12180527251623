import config from '../../../config.json';
const env = process.env.REACT_APP_ENVIRONMENT || 'stage';
const BACKEND_URL = config.api[env];
import axios from 'axios';

import { getToken } from './../../models/auth';


export default function reactivateSchedule(scheduleId) {
  return axios.post(`${BACKEND_URL}/v2/boUser/schedule/reactivateSchedule`,
    { scheduleId },
    {
      headers: { Authorization: `Bearer ${getToken()}` }
    }
  );
}
