import { backendAPI } from '../../helper/apis';

export const FETCH_LEADS = "FETCH_LEADS";

export function fetchLeads({ filter, type }) {
  const params = {
    quantityPerPage: 999999
  };
  params[type] = filter;

  const leadsPromise = backendAPI.get(`/v2/bo/leads`, { params });

  return {
    type: FETCH_LEADS,
    payload: leadsPromise
  };
}
