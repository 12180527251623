"use strict";

import React, { Component } from "react";

export default class EditService extends Component {
    constructor(props) {
        super(props);

        this.state = {
            pack: props.pack,
            prepack: props.pack,
            area: props.area
        }
    }
    addService(evt) {
        const { pack, prepack } = this.state;
        if (
            !prepack.value ||
            !prepack.count ||
            !prepack.title ||
            !prepack.serviceList
        ) {
            alert(
                "Por favor, verifique se todos os campos estão corretos e tente novamente."
            );
            return;
        }

        prepack.value = parseFloat(prepack.value);
        prepack.count = parseInt(prepack.count);

        if (!pack.services) pack.services = [prepack];
        else pack.services.push(prepack);

        const { originalAmount, totalAmount } = this.calculatePack(pack);

        pack.originalAmount = originalAmount;
        pack.totalAmount = totalAmount;

        this.setState({
            prepack: {},
            pack
        });
    }

    calculatePack(pack) {
        const originalAmount = pack.services.reduce(
            (total, service) => total + service.value * service.count,
            0
        );
        let totalAmount;
        if (!pack.discountInPercent) totalAmount = originalAmount;
        else totalAmount = originalAmount * (1 - pack.discountInPercent / 100);
        return { originalAmount, totalAmount };
    }

    handleSelectedService(evt) {
        const { prepack } = this.state;
        prepack.serviceList = [evt.target.value];
        prepack.value =
            evt.nativeEvent.target[evt.target.selectedIndex].dataset.price;
        prepack.title = evt.nativeEvent.target[evt.target.selectedIndex].text;
        this.setState({ prepack });
    }

    handleServiceCount(evt) {
        const { prepack } = this.state;
        prepack.count = evt.target.value;
        this.setState({ prepack });
    }

    handleRemoveService(serviceIndex) {
        const { pack } = this.state;
        pack.services.splice(serviceIndex);
        this.setState({ pack });
    }

    handleFormEdit = (evt) => {
        const { value, name } = evt.target;
        const { pack } = this.state;
        pack[name] = value;
        if (name === "discountInPercent") {
            const { originalAmount, totalAmount } = this.calculatePack(pack);
            pack.originalAmount = originalAmount;
            pack.totalAmount = totalAmount;
        }
        this.setState({ pack });
    }

    renderSelectedServices() {
        if (!this.state.pack.services) {
            return <p>Não há serviços selecionados</p>;
        }
        return (
            <ul>
                {this.state.pack.services.map((service, index) => {
                    if (service.title && service.title["pt-BR"])
                        service.title = service.title["pt-BR"];
                    return (
                        <li key={index}>
                            {service.title} - R$ {parseFloat(service.value).toFixed(2)} -{" "}
                            {service.count} Voucher(s) - {service.serviceList[0]}
                            <button
                                type="button"
                                style={{ marginLeft: "10px" }}
                                className="btn btn-xs btn-danger"
                                title="Remover Serviço"
                                onClick={this.handleRemoveService.bind(this, index)}
                            >
                                <i className="glyphicon glyphicon-trash" />
                            </button>
                        </li>
                    );
                })}
            </ul>
        );
    }


    renderServicesAvaiable() {
        if (!this.state.area) return;
        const { area } = this.state;
        return (
            <div className="col-xs-12 form-group">
                <hr />
                <div className="row">
                    <div className="col-xs-12 form-group">
                        <label>Serviços Selecionados: </label>
                        {this.renderSelectedServices()}
                    </div>

                    <hr />
                    <div className="col-xs-12">
                        <label>Adicionar Serviços</label>
                    </div>
                    <div className="clearfix" />
                    <div className="col-xs-3 form-group">
                        <label>Serviços</label>
                        <select
                            className="form-control form-control-normal"
                            onChange={this.handleSelectedService.bind(this)}
                        >
                            <option value="" defaultValue>
                                Selecione um serviço
                            </option>
                            {area.categories
                                ? area.categories.map(category => {
                                    return (
                                        <optgroup label={category.activity}>
                                            {category.services.map(service => {
                                                if (!service.active) return;
                                                return (
                                                    <option
                                                        value={service._id}
                                                        data-price={service.minValue}
                                                        name={service.name["pt-BR"]}
                                                        className="checkbox-inline"
                                                    >
                                                        {service.name["pt-BR"]}
                                                    </option>
                                                );
                                            })}
                                        </optgroup>
                                    );
                                })
                                : ""}
                        </select>
                    </div>
                    <div className="col-xs-2 form-group">
                        <label>Valor do Serviço</label>
                        <input
                            className="form-control form-control-normal"
                            name={"value"}
                            tabIndex={1}
                            required
                            value={this.state.prepack.value ? this.state.prepack.value : 0}
                        />
                    </div>
                    <div className="col-xs-2">
                        <label>Quantidade</label>
                        <div className="input-group">
                            <input
                                type="number"
                                className="form-control"
                                name={"count"}
                                tabIndex={1}
                                value={this.state.prepack.count ? this.state.prepack.count : ""}
                                onChange={this.handleServiceCount.bind(this)}
                            />
                            <span className="input-group-btn">
                                <button
                                    className="btn btn-default"
                                    type="button"
                                    onClick={this.addService.bind(this)}
                                >
                                    Adicionar
                                </button>
                            </span>
                        </div>
                    </div>
                    <div className="col-xs-2">
                        <label>Quantidade de Parcelas</label>
                        <select 
                            className="form-control form-control-normal"
                            name="installmentMaxLimit"
                            onChange={this.handleFormEdit}
                            value={this.state.pack.installmentMaxLimit}
                        >
                            {
                                Array(12).fill(0).map((item, idx) => (
                                    <option 
                                        key={idx} 
                                        value={idx+1}
                                    >
                                        {idx+1}
                                    </option>
                                ))
                            }
                        </select>
                    </div>
                    <div className="col-xs-3">
                        <p className="pack-total">
                            Total de serviços: R${" "}
                            {parseFloat(this.state.pack.originalAmount).toFixed(2) || 0}
                        </p>
                        <p>
                            Valor com desconto: R${" "}
                            {parseFloat(this.state.pack.totalAmount).toFixed(2) || 0}
                        </p>
                    </div>
                    <div className="clearfix" />
                    <hr />
                </div>
            </div>
        );
    }

    render() {
        return (
            <div style={{ marginLeft: 25, marginTop: 25 }}>
                <div className="col-xs-6 form-group">
                    <label>Desconto em % (valor usado para calculo na cobrança do pacote)</label>
                    <input
                        className="form-control"
                        name={"discountInPercent"}
                        tabIndex={5}
                        required
                        value={this.state.pack.discountInPercent}
                        onChange={this.handleFormEdit}
                    />
                </div>
                <div className="col-xs-4 form-group">
                    <label>Desconto para exibição no app em %</label>
                    <input
                        className="form-control"
                        name={"discountAmount"}
                        tabIndex={6}
                        required
                        value={this.state.pack.discountAmount}
                        onChange={this.handleFormEdit}
                    />
                </div>

                {this.renderServicesAvaiable()}
            </div>
        )
    }
}