import { backendAPI } from '../../helper/apis'

export const UPDATE_ARTIST = 'UPDATE_ARTIST';

export function updateArtist(artistId, data) {
  data.smartMEI = false

  const artistUpdatePromise = backendAPI.put(`/v2/bo/artistsWithValidation/${artistId}`, data);

  return {
    type: UPDATE_ARTIST,
    payload: artistUpdatePromise,
    meta: { data, id: artistId }
  };
}
