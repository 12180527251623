import React, { Component } from "react";

import ConfigurationList from "../../components/Leads/LeadSchedule/ConfigurationList";
import { getScheduleDate } from "../../actions/leads/get_schedule_date";
import Swal from "sweetalert2";

class LeadSchedulesConfiguration extends Component {
  constructor(props) {
    super(props)
    this.state = {
      evaluationScheduleList: [],
      loading: true,
      areaId: "",
      category: "",
      isOnlyActives: false,
      order: "",
      evaluationType: ""
    }
  }

  onChangeArea = (areaId) => {
    this.setState({ areaId }, this.loadData)
  }

  onChangeCategory = (category) => {
    this.setState({ category }, this.loadData);
  }

  onChangeEvaluationType = (evaluationType) => {
    this.setState({ evaluationType }, this.loadData);
  }

  onChangeOnlyActives = () => {
    this.setState({ isOnlyActives: !this.state.isOnlyActives }, this.loadData);
  }

  onChangeOrder = order => {
    this.setState({ order }, this.loadData);
  }

  loadData = () => {
    const params = {};

    if (this.state.order) Object.assign(params, { order: this.state.order });

    if (this.state.areaId) Object.assign(params, { areaId: this.state.areaId });

    if (this.state.category) Object.assign(params, { category: this.state.category });

    if (this.state.evaluationType) Object.assign(params, { evaluationType: this.state.evaluationType });

    if (this.state.isOnlyActives) Object.assign(params, { isOnlyActives: true });
    
    getScheduleDate(null, params).then(({ data }) => {
      this.setState({
        evaluationScheduleList: data
      })
    }).catch(((err) => {
      console.log(err);
      const { data } = err
      let message = "Não foi possível buscar as configurações de datas, por favor, tente novamente.";
      if (data && data.error && data.error.description) {
        message = data.error.description;
      }
      Swal.fire("Ops...", message, "error");
    }))
      .finally(() => {
        this.setState({ loading: false })
      })
  }

  componentDidMount() {
    this.loadData()
  }

  render() {
    return (
      <ConfigurationList
        loading={this.state.loading}
        onChangeArea={this.onChangeArea}
        onChangeCategory={this.onChangeCategory}
        onChangeEvaluationType={this.onChangeEvaluationType}
        onChangeOnlyActives={this.onChangeOnlyActives}
        onChangeOrder={this.onChangeOrder}
        evaluationScheduleList={this.state.evaluationScheduleList}
      />
    )
  }
}

export default LeadSchedulesConfiguration;