import React, { Component } from 'react';
import axios from 'axios';
import config from '../../../../config.json';
const env = process.env.REACT_APP_ENVIRONMENT || 'stage';
const NODE_API_URL = config.nodeAPI[env];

import { Button } from 'react-bootstrap';
import "./RealTimeOrdersActions.css";
import WhatsappTextButton from './../WhatsappTextButton/WhatsappTextButton';
import NotificationSystem from 'react-notification-system';
import { getToken } from './../../../models/auth';

class RealTimeOrdersActions extends Component {
  componentDidMount() {
    this.notificationSystem = this.refs.notificationSystem;
  }

  openDetailsModal() {
    this.props.rowData.actions.openDetailsModal(this.props.rowData);
  }

  openCancelModal() {
    this.props.rowData.actions.openCancelModal(this.props.rowData.id);
  }

  openActionsModal() {
    this.props.rowData.actions.openActionsModal(this.props.rowData);
  }

  notify(message, level) {
    const title = level === 'success' ? 'Sucesso' : 'Erro';

    this.notificationSystem.addNotification({
      title,
      message,
      level,
      position: 'tc'
    });
  }

  copyBtnDisabled() {
    const { shortCode } = this.props.rowData;
    return !shortCode;
  }

  getArtistLocation() {
    const { address, partnerId } = this.props.rowData;
    const geo = address.geoposition;

    if (partnerId) {
      axios.get(`${NODE_API_URL}/v2/artist/location/${partnerId}`, {
        headers: { Authorization: `Bearer ${getToken()}` },
      }).then(result => {

        var location = result.data.location.coordinates;
        var win = window.open(`http://maps.google.com/maps/dir/'${location[1]},${location[0]}'/${geo[1]},${geo[0]}/data=!3m1!4b1!4m7!4m6!1m3!2m2!1d-46.737424!2d-23.5808012!1m0!3e3`, '_blank');
        win.focus();
      }).catch(() => {
        alert('Localização da artista não encontrada');
      });
    } else {
      alert('Pedido não possui uma profissional');
    }
  }

  render() {
    return (
      <div className="ScheduleActions">
        <NotificationSystem ref="notificationSystem" />

        <div className="ScheduleActions-btnWrapper">
          <Button onClick={() => this.openDetailsModal()}
            className="ScheduleActions-btn btn btn-info"
            title="Detalhes do agendamento">
            <span className="glyphicon glyphicon-info-sign"></span>
          </Button>

          <Button
            onClick={() => this.openActionsModal()}
            className="ScheduleActions-btn btn btn-info"
            title="Açoes do agendamento">
            <span className="glyphicon glyphicon-calendar"></span>
          </Button>
        </div>

        <div className="ScheduleActions-btnWrapper">
          <Button
            onClick={() => this.getArtistLocation()}
            className="ScheduleActions-btn btn btn-info"
            title="Encontrar artista">
            <span className="ss-icon ss-location"></span>
          </Button>

          <Button
            onClick={() => this.openCancelModal()}
            className="ScheduleActions-btn btn btn-info"
            title="Cancelar pedido">
            <span className="glyphicon glyphicon-remove"></span>
          </Button>
        </div>

        <div className="ScheduleActions-btnWrapper">
          <WhatsappTextButton data={this.props.rowData}
            disabled={this.copyBtnDisabled()}
            notify={(msg, lvl) => this.notify(msg, lvl)}
          />
        </div>
      </div>
    );
  }
};

export default RealTimeOrdersActions;