/* global BACKEND_URL */
/* global BACKEND_URL */
/* global axios */
import React from "react";

import { Component } from "react";
import PageHeader from "../../components/Global/PageHeader";
import { savePassword } from '../../actions/staff/save_password'

class ChangePassword extends Component {
  state = { submitting: false, fieldType: "password", newPassword: "", token: undefined };

  componentWillMount() {
    const { passwordToken } = localStorage
    this.setState({ token: passwordToken })
  }

  changePassword(evt) {
    evt.preventDefault();
    this.setState({ submitting: true });
    const { newPassword } = this.state;
    savePassword(newPassword, this.state.token)
      .then(() => {
        alert("Senha alterado com sucesso.");
        this.props.history.push('/login');
      })
      .catch(({ response }) => {
        alert(
          response.data && response.data.description || "Ocorreu um erro ao trocar a sua senha.\nPor favor, tente novamente."
        );
      })
      .finally(_ => {
        this.setState({
          submitting: false
        });
      });
  }

  changeField(evt) {
    this.setState({ newPassword: evt.target.value });
  }

  submitButton() {
    if (!this.state.submitting) {
      return (
        <button type="submit" className="btn btn-success" >
          Salvar
        </button>
      );
    } else {
      return (
        <button className="btn btn-success" disabled>
          Salvando...
        </button>
      );
    }
  }

  changeView() {
    const { fieldType } = this.state;
    if (fieldType === "password") this.setState({ fieldType: "text" });
    else this.setState({ fieldType: "password" });
  }

  render() {

    return (
      <div>
        <PageHeader title="Alterar Senha" />
        <div className="col-xs-4 col-xs-offset-4">
          <form onSubmit={this.changePassword.bind(this)}>
            <div className="col-xs-12 form-group">
              <div className="col-xs-12 input-group">
                <input
                  value={this.state.newPassword}
                  onChange={this.changeField.bind(this)}
                  type={this.state.fieldType}
                  className="form-control form-control-normal"
                />
                <span
                  className="input-group-addon"
                  onClick={this.changeView.bind(this)}
                >
                  <i
                    className={`glyphicon glyphicon-eye-${this.state.fieldType === "password" ? "open" : "close"
                      }`}
                  />
                </span>
              </div>
            </div>
            <div className="col-xs-12 form-group text-right">
              {this.submitButton()}
            </div>
          </form>
        </div>
      </div>
    );
  }
}

export default ChangePassword;
