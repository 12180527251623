import React from "react";
import { Component, PropTypes } from "react";

import moment from "moment-timezone";

import "./ArtistTakeRate.css";
class TakeRate extends Component {
  state = {
    takeRateHistory: [],
    currentPage: 1,
    itensPerPage: 3
  };

  componentWillReceiveProps({ takeRateHistory }) {
    if (takeRateHistory && takeRateHistory.length > 0) {
      this.setState({
        takeRateHistory: takeRateHistory.filter(
          element => typeof element === "object"
        )
      });
    }
  }

  handleClick = event => {
    const page = Number(event.target.id);
    this.setState({ currentPage: page });
    this.props.fetchTakeRateHistory(page, this.state.itensPerPage);
  };

  render() {
    const { takeRateHistory, currentPage, itensPerPage } = this.state;

    // Logic for displaying page numbers
    const pageNumbers = [];
    for (let i = 1; i <= Math.ceil(this.props.totalItems / itensPerPage); i++) {
        pageNumbers.push(i);
    }

    const renderPageNumbers = pageNumbers.map(number => {
      return (
        <li
          key={number}
          id={number}
          className={currentPage === number ? "currentPage" : ""}
          onClick={this.handleClick}
        >
          {number}
        </li>
      );
    });

    return (
      <div>
        <div className="takeRateHistory-container">
          <h2 className="section-title">Histórico de Take Rate</h2>

          <div className="container-flex">
            {takeRateHistory.length > 0
              ? takeRateHistory.map((element, index) => {
                  if (moment(element.expiration).isBefore()) {
                    return (
                      <div className="takeRate-box" key={index}>
                        {element.createdBy ? (
                          <p className="takeRate-user">
                            <strong>Criado por:</strong>{" "}
                            {element.createdBy.email}
                          </p>
                        ) : (
                          <p>N/A</p>
                        )}
                        {element.value ? (
                          <p className="takeRate-perc">
                            <strong>Artista recebia: </strong>
                            {100 - element.value}%
                          </p>
                        ) : (
                          <p>N/A</p>
                        )}
                        {element.createdAt ? (
                          <p className="takeRate-creat">
                            <strong>Criado em:</strong>
                            {moment
                              .tz(element.createdAt, "America/Sao_Paulo")
                              .format("DD/MM/YY HH:mm")}
                          </p>
                        ) : (
                          <p>N/A</p>
                        )}
                        {element.createdAt ? (
                          <p className="takeRate-exp">
                            <strong>Expirado em:</strong>
                            {moment
                              .tz(element.expiration, "America/Sao_Paulo")
                              .format("DD/MM/YY HH:mm")}
                          </p>
                        ) : (
                          <p>N/A</p>
                        )}
                      </div>
                    );
                  } else {
                    return (
                      <div className="takeRate-box" key={index}>
                        {element.createdBy ? (
                          <p className="takeRate-user">
                            <strong>Criado por:</strong>{" "}
                            {element.createdBy.email}
                          </p>
                        ) : (
                          <p>N/A</p>
                        )}
                        {element.value ? (
                          <p className="takeRate-perc">
                            <strong>Artista recebe: </strong>
                            {100 - element.value}%
                          </p>
                        ) : (
                          <p>N/A</p>
                        )}
                        {element.createdAt ? (
                          <p className="takeRate-creat">
                            <strong>Criado em:</strong>
                            {moment
                              .tz(element.createdAt, "America/Sao_Paulo")
                              .format("DD/MM/YY HH:mm")}
                          </p>
                        ) : (
                          <p>N/A</p>
                        )}
                        {element.createdAt ? (
                          <p className="takeRate-exp">
                            <strong>Expira em:</strong>
                            {moment
                              .tz(element.expiration, "America/Sao_Paulo")
                              .format("DD/MM/YY HH:mm")}
                          </p>
                        ) : (
                          <p>N/A</p>
                        )}
                      </div>
                    );
                  }
                })
              : "Sem Histórico de Take Rate"}
          </div>

          <div>
            <ul id="page-numbers" className="page-numbers">
              {renderPageNumbers}
            </ul>
          </div>
        </div>
      </div>
    );
  }
}

export default TakeRate;
