import _ from "lodash"

const upload = {
  isValidPartnerImage: file => {
    const allowedExtentions = ['jpg', 'jpeg', 'JPG', 'JPEG', 'png', 'PNG'];
    const mbSizeLimit = 10;
    const sizeOnMBytes = Math.floor(file.size / 1000000);
    const extention = _.takeRight(file.name.split('.'))[0];

    if (!_.includes(allowedExtentions, extention)) {
      return {
        valid: false,
        error: 'extention',
        allowedExtentions
      };
    } else if (sizeOnMBytes >= mbSizeLimit) {
      return {
        valid: false,
        error: 'size',
        mbSizeLimit
      };
    }

    return {
      valid: true
    };
  }
};

export default upload;
