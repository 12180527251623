import React from 'react';
import RealTimeOrdersActions from "./RealTimeOrdersActions/RealTimeOrdersActions";
import moment from 'moment-timezone';
import _ from 'underscore';
import { Link } from 'react-router';

const RequestedDateDisplay = (props) => {
  const isCurrentDate = props.rowData.requestedOn.date.isSame(moment(), 'day');
  const date = props.rowData.requestedOn.date.format('DD/MM/YYYY');
  const scheduleHour = props.rowData.requestedOn.date.format("HH:mm");
  const displayDate = isCurrentDate ? 'HOJE' : date;
  return <div><div style={{ fontWeight: isCurrentDate ? 'bold' : 'normal' }}> {displayDate} </div> {scheduleHour}</div>
};

const RealTimePartnerDisplay = (props) => {
  const status = props.rowData.statusCode;
  const { partnerName, partnerId } = props.rowData;

  if (status === 1 && props.rowData.professionalPreferences.length > 0) {
    return <div style={{ color: '#f44165', fontWeight: 'bold' }}> Preferencia por profissional </div>;
  };

  const name = status === 1 || status === 17 || status === 12 ? '' : (partnerName || 'Não alocado');

  return (<div style={{ fontWeight: 'bold' }}> {partnerId ? <Link to={`/artist/${partnerId}`}>{name}</Link> : name} </div>)
}

const RealTimeServiceDisplay = (props) => {
  return <strong>{props.rowData.serviceName} </strong>

}

const UserLink = (props) => {
  const { userId, name } = props.rowData;

  return (
    <Link to={`/users/${userId}`}>{name}</Link>
  );
};

const AgentComponent = ({ rowData }) => {
  const agent = rowData.agent;
  const source = agent === 'android'
    ? 'https://s3-sa-east-1.amazonaws.com/singu/backoffice/ic_android.png'
    : 'https://s3-sa-east-1.amazonaws.com/singu/backoffice/ic_apple.png';

  return (
    <p className="text-center">
      <img src={source} alt={agent} />
    </p>
  );
};

const RealTimeOrdersMetadata = {
  column: [
    // visible columns
    {
      columnName: 'url'
    },
    {
      columnName: 'id',
      displayName: 'ID'
    },
    {
      columnName: 'requestedOn',
      displayName: 'Solicitado',
      customComponent: RequestedDateDisplay
    },
    {
      columnName: 'serviceName',
      displayName: 'Serviço',
      customComponent: RealTimeServiceDisplay
    },
    {
      columnName: 'name',
      displayName: 'Cliente',
      customComponent: UserLink
    },
    {
      columnName: 'email',
      displayName: 'E-mail'
    },
    {
      columnName: 'voucher',
      displayName: 'Voucher'
    },
    {
      columnName: 'phone',
      displayName: 'Telefone'
    },
    {
      columnName: 'address',
      displayName: 'Endereço'
    },
    {
      columnName: 'status',
      displayName: 'Status',
      cssClassName: 'Realtime-status'
    },
    {
      columnName: 'partnerName',
      displayName: 'Profissional',
      customComponent: RealTimePartnerDisplay,
    },
    {
      columnName: 'agent',
      displayName: 'Device',
      customComponent: AgentComponent
    },
    {
      columnName: 'actions',
      displayName: 'Ações',
      customComponent: RealTimeOrdersActions
    },
    {
      columnName: 'formattedAddress',
      displayName: 'Endereço'
    },
    {
      columnName: 'paymentStatus',
      displayName: 'Status do Pagamento'
    },
    {
      columnName: 'oldId',
      displayName: 'Id antigo',
      visible: false
    },
    {
      columnName: 'address',
      visible: false
    },
    {
      columnName: 'partnerId',
      visible: false
    },
    {
      columnName: 'partnerEmail',
      visible: false
    },
    {
      columnName: 'partnerPhone',
      visible: false
    },
    {
      columnName: 'serviceId',
      visible: false
    },
    {
      columnName: 'statusCode',
      visible: false
    },
    {
      columnName: 'actions',
      visible: false
    },
    {
      columnName: 'scheduledOn.toString',
      visible: false
    },
    {
      columnName: 'scheduledOn.date',
      visible: false
    },
    {
      columnName: 'scheduledOn.formattedDate',
      visible: false
    },
    {
      columnName: 'scheduledOn.sortableDate',
      visible: false
    },
    {
      columnName: 'requestedOn.toString',
      visible: false
    },
    {
      columnName: 'requestedOn.date',
      visible: false
    },
    {
      columnName: 'requestedOn.formattedDate',
      visible: false
    },
    {
      columnName: 'requestedOn.sortableDate',
      visible: false
    },
    {
      columnName: 'scheduledRangeEnd.toString',
      visible: false
    },
    {
      columnName: 'scheduledRangeEnd.date',
      visible: false
    },
    {
      columnName: 'scheduledRangeEnd.formattedDate',
      visible: false
    },
    {
      columnName: 'scheduledRangeEnd.sortableDate',
      visible: false
    },
    {
      columnName: 'payment',
      visible: false
    },
    {
      columnName: 'shortCode',
      displayName: 'Código',
      visible: false
    },
    {
      columnName: 'originalValue',
      visible: false
    },
    {
      columnName: 'userId',
      visible: false
    },
    {
      columnName: 'index',
      visible: false
    }
  ],
  row: {
    key: 'id',
    bodyCssClassName: ({ statusCode }) => {
      const rowClass = 'Realtime-row';
      const redStatus = [3, 4, 5, 6, 12, 13, 14];
      const yellowStatus = [1, 7, 8, 9, 12];
      const greenStatus = [2, 10, 11];
      const isRed = _.contains(redStatus, statusCode);
      const isYellow = _.contains(yellowStatus, statusCode);
      const isGreen = _.contains(greenStatus, statusCode);

      if (isRed) {
        return `${rowClass} Realtime-redRow`;
      } else if (isYellow) {
        return `${rowClass} Realtime-yellowRow`;
      } else if (isGreen) {
        return `${rowClass} Realtime-greenRow`;
      }

      return `${rowClass} default-row`;
    }
  }
};

export default RealTimeOrdersMetadata;
