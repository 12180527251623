import ArtistActions from './ArtistActions';

const artistsMetadata = [
  {
    columnName: 'id',
    displayName: 'ID'
  },
  {
    columnName: 'createdDate',
    displayName: 'Cadastro'
  },
  {
    columnName: 'name',
    displayName: 'Nome'
  },
  {
    columnName: 'email',
    displayName: 'E-mail'
  },
  {
    columnName: 'phone',
    displayName: 'Telefone'
  },
  {
    columnName: 'formattedAddress',
    displayName: 'Endereço'
  },
  {
    columnName: 'actions',
    displayName: 'Ações',
    customComponent: ArtistActions
  }
];

export default artistsMetadata;
