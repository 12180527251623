import React, { Component, PropTypes } from 'react';
import classNames from 'classnames';
import './SwitchCheckbox.css';

class SwitchCheckbox extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isChecked: props.checked || false,
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.checked !== this.props.checked) {
      this.setState({ isChecked: this.props.checked || false });
    }
  }

  toggleCheckbox = () => {
    const { isChecked } = this.state;
    this.setState({ isChecked: !isChecked }, () => {
      if (this.props.onChange) {
        this.props.onChange(this.state.isChecked);
      }
    });
  };

  render() {
    const { isChecked } = this.state;
    const { className } = this.props;

    const checkboxClasses = classNames('switch-checkbox', {
      checked: isChecked,
    });

    return (
      <div className={`${checkboxClasses} ${className}`} onClick={this.toggleCheckbox}>
        <div className="switch-inner" />
      </div>
    );
  }
}

SwitchCheckbox.propTypes = {
  checked: PropTypes.bool,
  onChange: PropTypes.func,
  className: PropTypes.string,
};

export default SwitchCheckbox;
