import config from '../../../config.json';
const env = process.env.REACT_APP_ENVIRONMENT || 'stage';
const BACKEND_URL = config.api[env];
import axios from 'axios';

export const FETCH_TRANSFERS = 'FETCH_TRANSFERS';

import { getToken } from './../../models/auth';

export function fetchTransfers(professional, status, createAt, page) {
  const params = {
    limit: 10,
    page: page || 1,
    isOwner: true,
  }

  if (createAt) {
    params.createAt = createAt;
  }

  if (professional) {
    params.professional = professional;
  }

  if (status) {
    params.status = status;
    params.isOwner = false;
  }

  return axios.get(`${BACKEND_URL}/transfer/order`,
    {
      params: params,
      headers: { Authorization: `Bearer ${getToken()}` }
    });
}
