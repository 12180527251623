import React, { Component } from "react";
import { fetchService } from "../../actions/service/fetch_service"; //Trocar
import ListServiceComponent from "../../components/Services/List";

export default class ListService extends Component {
  constructor() {
    super();
    this.state = {
      status: "READY",
      services: [],
    }
  }

  componentDidMount() {
    this.fetchServices()
  }

  fetchServices = () => {
    this.setState({ status: "LOADING" })
    fetchService().then(({ data }) => {
      this.setState({ services: data });
    }).finally(() => {
      this.setState({ status: "READY" })
    })
  }

  render() {
    return (
      <ListServiceComponent
        services={this.state.services}
        status={this.state.status}
      />
    )
  }
}