const request = (actionCreator, notifyFn, successMsg) => {
  const fulfill = action => {
    console.log("action: ", action)
    if (action && action.error) {
      if (action.payload.data) {
        const errorMessage =
          action.payload?.data?.error?.message ??
          action.payload?.data?.error?.description ??
          action.payload?.data?.description ??
          action.payload?.data?.message ??
          "Erro genérico na atualização"
        notifyFn(errorMessage, 'error');
      } else if (action.payload?.error) {
        const errorMessage =
          action.payload?.error?.message ??
          action.payload?.error?.description ??
          "Erro genérico na atualização";
        notifyFn(errorMessage, 'error');
      }
      else if (action.payload.response.data.description) {
        notifyFn(action.payload.response.data.description, 'error');
      } else {
        notifyFn(action.payload.message, 'error');
      }
    } else {
      notifyFn(successMsg, 'success');
    }
  };
  // const error = () => notifyFn('Ocorreu um erro inesperado', 'error');

  return actionCreator().then(fulfill);
};

export default request;
