import React, { Component } from "react";
import moment from 'moment';

import { Toast } from '../../helper/alerts';
import formatters from '../../helper/formatters';
import {
  scheduleTypes,
  areasList,
  categoriesList,
  SCHEDULE_TYPES_ENUM,
  LEAD_STATUS_ENUM,
} from '../../helper/leads';

import { fetchAreasService } from "../../actions/areas/fetch_areas";
import { fetchLeads } from "../../actions/leads/fetch_leads";
import { createLead, updateLead, getLeadSchedulesAvailable } from "../../services/leads";
import { setCurrentLead } from "../../actions/leads/set_lead";
import { connect } from "react-redux";

import NewLeadForm from "../../components/Leads/NewLeadForm";
import Loading from "../../components/Global/Loading/Loading";

class NewLeadContainer extends Component {
  constructor(props) {
    super(props);

    const { _id } = props.routeParams;

    this.state = {
      isUpdatingLead: !!_id,
      lead: null,
      availableCities: null,
      availableServices: null,
      availableLeadScheduleDates: null,
      loadingAvailableScheduleDates: false,
      submitting: null,
      loading: true
    };
  }

  componentDidMount() {
    try {
      (async () => {
        if (this.state.isUpdatingLead) {
          const { _id } = this.props.routeParams;

          await this.loadUpdatingLead(_id);
        }
        await this.loadAvailableCities();
        this.setState({ loading: false});
      })();
    } catch (error) {
      console.error(`[AREAS ERROR] ${error}`);
    }
  }

  loadUpdatingLead = async (_id) => {
    try {
      const { data: leads } = await fetchLeads({ filter: _id, type: '_id' }).payload;

      const lead = leads[0];

      if (!lead) throw new Error('Não existe um Lead com esse ID.');

      const { evaluation, categoryIds } = lead;

      if (evaluation) {
        const { data: availableLeadScheduleDates } = await getLeadSchedulesAvailable({
          areaId: evaluation.areaId || '',
          type: evaluation.type.code || '',
          meetLink: evaluation.meetLink || '',
          category: categoryIds && categoryIds.length > 0 ? categoriesList.find(category => category.value === categoryIds[0]).text : '',
          end: new Date()
        });

        this.setState({ availableLeadScheduleDates });
      }

      this.setState({ lead });
    } catch (err) {
      console.error(err);
      Toast().fire({
        title: err.message || 'Erro ao carregar o Lead.',
      })
    }
  }

  loadAvailableCities = async () => {
    const { data: areas } = await fetchAreasService();

    const formattedAreas = areas.filter(area => area.active).map(area => ({
      value: area._id,
      text: area.city,
      categories: area.categories
    }))

    this.setState({ availableCities: formattedAreas });
  }

  handleLoadServices = () => {
    const { availableCities, evaluation } = this.state;

    if (!availableCities || !evaluation.areaId) return;

    const { categories } = availableCities.filter(city => city.value === evaluation.areaId)[0];

    const availableServices = categories.map(category => ({
      value: category._id,
      label: category.name['pt-BR']
    }));

    this.setState({ availableServices });
  }

  getAvailableLeadScheduleDates = async (evaluation, categoryId) => {
    try {
      this.setState({ loadingAvailableScheduleDates: true, availableLeadScheduleDates: [] });

      const params = {
        areaId: evaluation.areaId,
        type: evaluation.type,
        category: categoriesList.find(category => category.value === categoryId).text,
        end: new Date(),
      };

      const { data: availableLeadScheduleDates } = await getLeadSchedulesAvailable(params);

      this.setState({ availableLeadScheduleDates });
    } catch (error) {
      console.error(error);
      Toast().fire({
        icon: 'error',
        title: error.message || 'Erro ao carregar opções disponíveis'
      });
    } finally {
      this.setState({ loadingAvailableScheduleDates: false })
    }
  }

  onSubmit = async (event, form) => {
    try {
      event.preventDefault();

      if (!form || !form.name || !form.phoneNumber || !form.cpf || !form.areaId || !form.categoryId) {
        throw new Error('Por favor informe todos os campos obrigatorios!');
      }

      if (form.areaId === 'anotherCity' && (!form.anotherCity || form.anotherCity === '')) {
        throw new Error('É necessário informar o nome da outra cidade caso a opção esteja selecionada.')
      }

      const { invalidInputs, certificationRequired, hasCertification } = form;

      if (certificationRequired && !hasCertification) throw new Error('É necessário possuir certificado para a categoria selecionada');

      if (invalidInputs[0]) throw new Error(`O campo ${invalidInputs[0]} não está preenchido corretamente.`);

      const { evaluation = {}, selectedEvaluationDay, selectedEvaluationHour } = form;

      if ( (evaluation.areaId && evaluation.type) || evaluation.type === SCHEDULE_TYPES_ENUM.ONLINE) {
        if (!selectedEvaluationDay || !selectedEvaluationHour) {
          throw new Error('Por favor, preencha corretamente os valores do teste ou deixe todos vazios!');
        }

        const leadScheduleDate = this.state.availableLeadScheduleDates.find(als => als.id = evaluation._id);

        const splitedHour = selectedEvaluationHour.split(':');

        const evaluationDate = moment(selectedEvaluationDay)
          .tz('America/Sao_Paulo')
          .hour(parseInt(splitedHour[0]))
          .minute(parseInt(splitedHour[1]) || 0)
          .second(0)
          .millisecond(0)
          .toDate();

        const scheduleType = scheduleTypes.find(st => st.value === evaluation.type);

        if (!scheduleType) throw new Error('Esse tipo de teste não existe!');

        const formattedEvaluation = {
          _id: leadScheduleDate.id,
          areaId: evaluation.areaId,
          type: evaluation.type,
          meetLink: evaluation.meetLink,
          date: evaluationDate
        }

        if (evaluation.type !== SCHEDULE_TYPES_ENUM.ONLINE) formattedEvaluation.location = leadScheduleDate.location;

        form.evaluation = formattedEvaluation;
      } else {
        delete form.evaluation;
      }

      if (form.statusId === LEAD_STATUS_ENUM.NAO_AGENDADO) delete form.evaluation;

      this.setState({ submitting: true });

      const isAnotherCity = form.areaId === 'anotherCity';

      const data = {
        ...form,
        phone: formatters.phoneToObject(form.phoneNumber),
        areaIds: !isAnotherCity ? [form.areaId] : [],
        cities: !isAnotherCity ? [areasList.find(area => area.value === form.areaId).text] : [],
        categoryIds: [form.categoryId],
        techniques: categoriesList.find(category => category.value === form.categoryId).text,
        anotherCity: isAnotherCity ? form.anotherCity : ''
      };

      delete data.areaId;
      delete data.categoryId;
      delete data.selectedLeadScheduleDateID;
      delete data.selectedEvaluationDay;
      delete data.selectedEvaluationHour;
      delete data.phoneNumber;
      delete data.availableCities;
      delete data.availableCategories;
      delete data.certificationRequired;
      delete data.invalidInputs;
      delete data.availableDays;
      delete data.createArtist;

      const { isUpdatingLead, lead } = this.state;
      const { createArtist } = form;

      if (createArtist) {
        this.props.setCurrentLead(lead);
        this.props.history.push('/new-artist');
        return;
      }

      if (isUpdatingLead && lead) {

      const isConfirmed = confirm('Tem certeza que deseja alterar esse lead?');
      if (!isConfirmed) return ;

        await updateLead(lead._id, data);
        Toast().fire({ icon: 'success', title: 'Lead atualizado com sucesso!' });
        window.location.reload()
      } else {
        await createLead(data);

        this.props.history.push('leads');

        Toast().fire({ icon: 'success', title: 'Lead inserido com sucesso!' });
      }
    } catch (error) {
      console.log(JSON.stringify(error));
      let message = 'Ocorreu um erro ao inserir o lead, tente novamente';

      if (error.response && error.response.data && error.response.data.message) message = error.response.data.message;

      if (error.message) message = error.message;

      Toast().fire({
        icon: 'error',
        title: message,
        timer: 3000
      });
    } finally {
      this.setState({ submitting: false });
    }
  }

  render() {
    const { isUpdatingLead, lead, loading } = this.state;

    if (isUpdatingLead && !lead) return <Loading />

    return (
      loading ? 
      <Loading/>
      :
      <NewLeadForm
        lead={lead}
        getAvailableLeadScheduleDates={this.getAvailableLeadScheduleDates.bind(this)}
        availableLeadScheduleDates={this.state.availableLeadScheduleDates}
        loadingAvailableScheduleDates={this.state.loadingAvailableScheduleDates}
        onSubmit={this.onSubmit.bind(this)}
        availableCities={this.state.availableCities}
        availableServices={this.state.availableServices}
        submitting={this.state.submitting}
      />
    );
  }
}

function mapStateToProps(state) {
  return {
    leads: state.leads.all
  };
}

const mapActionsToProps = {
  setCurrentLead
};

export default connect(mapStateToProps, mapActionsToProps)(NewLeadContainer);