import React, { Component, PropTypes } from 'react';
import { SimpleSelect } from 'react-selectize';
import { Button } from 'react-bootstrap';
import enumHelper from './../../../helper/enumHelper';
import formatter from './../../../helper/formatters';
import handleRequest from './../../../helper/requests';
import { fetchArtistsToSchedule } from '../../../actions/artists/fetch_artist_to_schedule';

// styles
import './ChangePartner.css';

const statusToDisableUpdate = [enumHelper.scheduleStatusEnum.cancelByBackOffice.value];
statusToDisableUpdate.push(enumHelper.scheduleStatusEnum.cancelByUser.value);
statusToDisableUpdate.push(enumHelper.scheduleStatusEnum.cancelByPartner.value);

class ChangePartner extends Component {
  constructor(props) {
    super(props);

    const actualPartner = this.props.actualPartner || {};
    const parseArtist = this.parseArtist(actualPartner);

    this.state = {
      partners: parseArtist._id ? [parseArtist] : [],
      partner: parseArtist._id ? parseArtist : null,
      disableSaveBtn: true,
      disableRemoveBtn: parseArtist._id ? false : true
    }
  }

  parseArtist = (artist) => {
    const moipOK = !!(artist.moipCredentials && artist.moipCredentials && artist.moipCredentials.moipAccountId)
    return Object.assign({ moipOK, label: artist.name, value: artist._id }, artist);
  }

  onSearchChange = (nameToFilter) => {
    const { scheduleId } = this.props;
    fetchArtistsToSchedule(scheduleId, nameToFilter).payload.then(resp => {
      const partners = (resp.data || []).map(this.parseArtist);
      this.setState({ partners });
    }).catch(error => {
      console.log(error);
      alert('Erro ao buscar profissional');
    });
  };

  onValueChange = (selectedValue) => {
    const findPartner = partner => partner.value === (selectedValue ? selectedValue.value : '');
    const selectedPartner = this.state.partners.find(findPartner);

    const partner = {
      ...selectedPartner,
      formattedPhone: selectedPartner ? formatter.formatPhone(selectedPartner.phone) : ''
    };

    const disableSaveBtn = partner.value === (this.state.partner ? this.state.partner._id : '');

    this.setState({ partner, disableSaveBtn });
  };

  savePartner() {
    const { partner } = this.state;
    const { scheduleId, updateCallback, notify } = this.props;
    const actionCreator = () => updateCallback(scheduleId, partner._id, partner);
    const requestFinish = () => this.setState({ disableRemoveBtn: false });
    handleRequest(actionCreator, notify, 'Artista atualizado com sucesso').then(requestFinish);
  };

  deletePartner() {
    const { scheduleId, removeCallback, notify } = this.props;
    const actionCreator = () => removeCallback(scheduleId);
    const requestFinish = () => this.cleanFields();
    handleRequest(actionCreator, notify, 'Artista removido com sucesso').then(requestFinish);
  };

  cleanFields() {
    this.setState({
      disableRemoveBtn: true,
      partner: {}
    });
  }

  mapOptions(item) {
    const moipFlag = () => {
      if (!item.moipOK) {
        return (
          <span className="ChangePartner-moipFlag">MOIP</span>
        );
      }
      return null;
    };

    return (
      <div className="option-wrapper">
        <div className="simple-option">
          <span className="ChangePartner-optionLabel">{item.label}</span>
          {moipFlag()}
        </div>
      </div>
    );
  }

  render() {
    return (
      <div className="Partner clearfix">
        <div className="row">
          <div className="col-sm-2">
            <div className="Partner-title">Profissional:</div>
          </div>
          <div className="col-sm-8">
            <SimpleSelect
              options={this.state.partners}
              placeholder="Pesquise pelo nome da profissional..."
              onSearchChange={this.onSearchChange}
              onValueChange={this.onValueChange}
              value={this.state.partner}
              renderOption={(item) => this.mapOptions(item)}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-sm-2">
            <div className="Partner-title">E-mail:</div>
          </div>
          <div className="col-sm-10">
            <div className="Partner-detail">{this.state.partner ? this.state.partner.email : 'N/A'}</div>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-2">
            <div className="Partner-title">Telefone:</div>
          </div>
          <div className="col-sm-10">
            <div className="Partner-detail">{this.state.partner ? this.state.partner.formattedPhone : 'N/A'}</div>
          </div>
        </div>
        <div className="row">
          <div className="Partner-actions clearfix">
            <div className="col-sm-12">
              <Button className="Partner-btn"
                disabled={this.state.disableRemoveBtn}
                bsStyle="danger"
                onClick={this.deletePartner.bind(this)}>Remover Artista</Button>

              <Button className="Partner-btn"
                disabled={this.state.disableSaveBtn}
                bsStyle="primary"
                onClick={this.savePartner.bind(this)}>Salvar</Button>
            </div>
          </div>
        </div>
      </div>
    );
  }
};

ChangePartner.propTypes = {
  scheduleId: PropTypes.string,
  notify: PropTypes.func,
  updateCallback: PropTypes.func,
  removeCallback: PropTypes.func
};

export default ChangePartner;
