import axios from 'axios';
import config from '../../../config.json';
const env = process.env.REACT_APP_ENVIRONMENT || 'stage';
const BACKEND_URL = config.api[env];

import { getToken } from '../../models/auth';

export const ADD_COMMENT_ARTIST = 'ADD_COMMENT_ARTIST';

export function addCommentInArtist(artistId, message) {
  return axios.post(
    `${BACKEND_URL}/v2/bo/artist/comment`,
    { artistId, message },
    {
      headers: { Authorization: `Bearer ${getToken()}` },
    },
  );
}
