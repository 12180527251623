import { FETCH_REALTIME } from './../actions/realtime_orders/fetch_realtime_orders';
import { CANCEL_REALTIME } from './../actions/realtime_orders/cancel_realtime_orders';

const INITIAL_STATE = {
    all: []
};

const realtimeOrders = (state = INITIAL_STATE, action) => {
    if (action.error) {
        return state;
    }

    const { data } = action.payload || {};

    switch (action.type) {
        case FETCH_REALTIME:
            return {
                ...state,
                all: data.realTimeOrders,
                numberOfPages: Math.ceil(data.totalItems / data.itemsPerPage)
            };

        case CANCEL_REALTIME:
            return {
                ...state,
                // all: _.map(state.all, s => schedule(s, action))
            };

        default:
            return state;
    }
};

export default realtimeOrders;
