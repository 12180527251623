import axios from 'axios';
import config from '../../../config.json';
const env = process.env.REACT_APP_ENVIRONMENT || 'stage';
const BACKEND_URL = config.api[env];
import { getToken } from './../../models/auth';

export function fetchArtistTakeRateHistory(id, page, limit) {
    return axios.get(`${BACKEND_URL}/v2/bo/artist/takeratehistory/${id}?page=${page}&limit=${limit}`, {
        headers: { Authorization: `Bearer ${getToken()}` }
    });
}
