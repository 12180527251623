import config from '../../../config.json';
const env = process.env.REACT_APP_ENVIRONMENT || 'stage';
const BACKEND_URL = config.api[env];
import axios from 'axios';

import { getToken } from './../../models/auth';

export const FETCH_CREDITS_HISTORY = 'FETCH_CREDITS_HISTORY';

export function fetchUserCreditsHistory(id, page) {
  const params = { 
    itemsPerPage: 10,
    page
  };

  const creditsHistoryPromise = axios.get(`${BACKEND_URL}/v2/bo/user/credits?userId=${id}`, {
    headers: { Authorization: `Bearer ${getToken()}` },
    params
  });

  return {
    type: FETCH_CREDITS_HISTORY,
    payload: creditsHistoryPromise
  };
}
