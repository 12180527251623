import React, { Component } from 'react'
import { Modal, Button } from 'react-bootstrap'
import { dictionaryCac, dictionarySector, columns } from './InvestimentMetaData'
import InvestimentButton from './InvestimentButton'
import InvestimentEditForm from './InvestimentFormEdit'
import Griddle from 'griddle-react'
import moment from 'moment-timezone'
class InvestimentList extends Component {
    constructor(props) {
        super(props)

        this.state = {
            investimentSelected: null,
            modalOpen: false,
            investiment: null
        }
    }

    mapData = data => {
        const cac = dictionaryCac[data.cac]
        const date = moment(data.date).add({ hours: 3 }).format('DD/MM/yyyy');
        const value = `R$ ${data.value}`
        const sector = dictionarySector[data.sector]

        return { ...data, cac, date, value, sector }
    }

    handleOnOpenEditModal = investiment => {
        this.setState({
            investimentSelected: investiment,
            modalOpen: true,
            investiment
        })
    }

    handleOnCloseEditModal = () => {
        this.setState({
            investimentSelected: null,
            modalOpen: false
        })
    }

    onChangeValue = value => {
        this.setState(prev => ({ ...prev, investiment: { ...prev.investiment, value } }))
    }

    onChangeDate = date => {
        this.setState(prev => ({ ...prev, investiment: { ...prev.investiment, date } }))
    }

    onChangeDescription = description => {
        this.setState(prev => ({ ...prev, investiment: { ...prev.investiment, description } }))
    }

    onChangeCategory = category => {
        this.setState(prev => ({ ...prev, investiment: { ...prev.investiment, category } }))
    }

    onChangeCac = cac => {
        this.setState(prev => ({ ...prev, investiment: { ...prev.investiment, cac } }))
    }

    onChangeSetor = sector => {
        this.setState(prev => ({ ...prev, investiment: { ...prev.investiment, sector } }))
    }

    onChangeService = service => {
        this.setState(prev => ({ ...prev, investiment: { ...prev.investiment, service} }))
    }

    reloadComponent = investiments => {
        this.setState({ investiments })
    }

    handleOnUpdate = () => {
        this.props.onUpdate(this.state.investiment._id, this.state.investiment, this.reloadComponent)
        this.setState({ modalOpen: false })
    }

    render() {
        return (
            <div>
                <div>
                    <Griddle
                        results={this.props.investiments}
                        columns={columns}
                        useExternal
                        externalSetPageSize={this.props.itemsPerPage}
                        resultsPerPage={this.props.itemsPerPage}
                        externalSetPage={this.props.getPage}
                        externalCurrentPage={this.props.currentPage - 1}
                        externalMaxPage={Math.ceil(this.props.totalItems / this.props.itemsPerPage)}
                        useGriddleStyles={false}
                        tableClassName={'GriddleTable table'}
                        settingsToggleClassName={'btn btn-default'}
                        columnMetadata={[
                            {
                                columnName: 'value',
                                displayName: 'Custo (R$)',
                                customComponent: ({ rowData }) => <p>{this.mapData(rowData).value}</p>
                            },
                            {
                                columnName: 'sector',
                                displayName: 'Setor',
                                customComponent: ({ rowData }) => <p>{this.mapData(rowData).sector}</p>
                            },
                            {
                                columnName: 'cac',
                                displayName: 'CAC',
                                customComponent: ({ rowData }) => <p>{this.mapData(rowData).cac}</p>
                            },
                            {
                                columnName: 'category',
                                displayName: 'Categoria',
                                customComponent: ({ rowData }) => <p>{rowData?.category}</p>
                            },
                            {
                                columnName: 'service',
                                displayName: 'Serviço',
                                customComponent: ({ rowData }) => <p>{rowData?.service}</p>
                            },
                            {
                                columnName: 'date',
                                displayName: 'Entrada',
                                customComponent: ({ rowData }) => <p>{this.mapData(rowData).date}</p>
                            },
                            {
                                columnName: 'description',
                                displayName: 'Descrição',
                                customComponent: ({ rowData }) => <p>{rowData.description}</p>
                            },
                            {
                                columnName: 'actions',
                                displayName: 'Ações',
                                customComponent: ({ rowData }) => (
                                    <InvestimentButton onClick={() => this.handleOnOpenEditModal(rowData)} />
                                )
                            }
                        ]}
                    />
                </div>
                <div>
                    <Modal bsSize="large" show={this.state.modalOpen} onHide={this.handleOnCloseEditModal}>
                        <Modal.Header closeButton>
                            <Modal.Title>Atualizar Investimento</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <InvestimentEditForm
                                investiment={this.state.investimentSelected}
                                onChangeValue={this.onChangeValue}
                                onChangeDate={this.onChangeDate}
                                onChangeDescription={this.onChangeDescription}
                                onChangeCategory={this.onChangeCategory}
                                onChangeCac={this.onChangeCac}
                                onChangeSetor={this.onChangeSetor}
                                onChangeService={this.onChangeService}
                            />
                        </Modal.Body>
                        <Modal.Footer>
                            <Button onClick={this.handleOnCloseEditModal}>Fechar</Button>
                            <Button className="btn btn-info" onClick={this.handleOnUpdate}>
                                Atualizar
                            </Button>
                        </Modal.Footer>
                    </Modal>
                </div>
            </div>
        )
    }
}

export default InvestimentList