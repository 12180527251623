import React, { Component, PropTypes } from 'react';


class SelectField extends Component {

  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      selectedValue: this.props?.inputData?.value || 'default'
    };
  }

  componentDidUpdate() {
    const {inputData: {value}} = this.props;

    if (!value && this.state.selectedValue!=='default') this.setState({selectedValue: 'default'});
    if (value && value !== this.state.selectedValue) this.setState({selectedValue: value});
  }

  handleChange = evt => {
    const { inputData } = this.props;
    if (!inputData.disabled) {
      inputData.onChange(evt.target.value);
    }
    this.setState({selectedValue: evt.target.value})
  }

  isInvalid() {
    const { inputData } = this.props;
    const { value = [], valid, touched } = inputData;

    return (!value && value && !value.length > 0 && !valid && touched);
  }

  renderErrorMessage() {
    const { error } = this.props.inputData || {};

    if (this.isInvalid()) {
      return (<span className="Form-error">{error}</span>);
    }

    return null;
  }

  render() {
    const { name, label, listValues, inputData, disabled } = this.props;

    return (
      <div className={`Form-inputWrapper form-group`}>
        <div className="Form-labelWrapper">
          <div className="Form-label">
            {label}{this.renderErrorMessage()}
          </div>
        </div>
        <select disabled={disabled} className={`Form-input ${!inputData.value && 'no-selection'}`} name={name} id={name} onChange={this.handleChange} value={this.state.selectedValue}>
          <option value='default' disabled label="Selecione uma opção"></option>
          {
            listValues.map(option => {
              return (
                <option value={option.value}>{option.text}</option>
              )
            })}
        </select>
      </div >
    );
  }
}

SelectField.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  inputData: PropTypes.object,
  listValues: PropTypes.array,
  optionSelected: PropTypes.string,
};

export default SelectField;
