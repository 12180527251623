import axios from 'axios';
import config from '../../../config.json';
const env = process.env.REACT_APP_ENVIRONMENT || 'stage';
const BACKEND_URL = config.api[env];

import { getToken } from './../../models/auth';

export const UPDATE_PARTNER = 'UPDATE_PARTNER';

export function updatePartner(partnerId, data) {
  const updatePartnerPromise = axios.put(`${BACKEND_URL}/v2/bo/partners/${partnerId}`,
    data,
    {
      headers: { Authorization: `Bearer ${getToken()}` }
    }
  );

  return {
    type: UPDATE_PARTNER,
    payload: updatePartnerPromise,
    meta: { data, id: partnerId }
  };
}
