import React, { Component, PropTypes } from 'react';
import _ from 'underscore';
import { Button } from 'react-bootstrap';
import DateTime from 'react-datetime';
import enumHelper from './../../../helper/enumHelper';
import handleRequest from './../../../helper/requests';
import moment from 'moment-timezone';

// styles
import './UpdateDate.css';

const statusToDisableUpdate = [enumHelper.scheduleStatusEnum.cancelByBackOffice.value];
statusToDisableUpdate.push(enumHelper.scheduleStatusEnum.cancelByUser.value);
statusToDisableUpdate.push(enumHelper.scheduleStatusEnum.cancelByPartner.value);

class UpdateDate extends Component {
  constructor(props) {
    super(props);
    const disabled = _.contains(statusToDisableUpdate, this.props.rowData.statusCode);

    this.state = {
      disabled,
      selectedDateTime: this.props.rowData.scheduledOn.date,
      selectedDateTimeEnd: this.props.rowData.scheduledRangeEnd.date
    };
  }

  validDate(current) {
    return current.isAfter(moment().subtract(1, 'day'));
  }

  confirmUpdateDateModal() {
    const { rowData: { id }, notify, updateCallback } = this.props;
    const selectedDateTime = this.state.selectedDateTime;
    const selectedDateTimeEnd = this.state.selectedDateTimeEnd;

    const newDate = selectedDateTime.format('YYYY-MM-DDTHH:mm');

    var endDate = newDate;

    endDate = selectedDateTimeEnd.format('YYYY-MM-DDTHH:mm');
    if (selectedDateTime.isAfter(selectedDateTimeEnd)) {
      alert("Data final é menor que inicial");
      return;
    }

    if (selectedDateTimeEnd.diff(selectedDateTime, 'days') > 0) {
      alert("Datas precisam estar no mesmo dia");
      return;
    }

    const actionCreator = () => updateCallback(id, newDate, endDate);

    handleRequest(actionCreator, notify, 'Agendamento atualizado com sucesso');
  }

  onDateTimeChange(selectedDateTime) {
    this.setState({ selectedDateTime });
  };

  onDateTimeChangeEnd(selectedDateTimeEnd) {
    this.setState({ selectedDateTimeEnd });
  };

  render() {
    return (
      <div className="UpdateDate">
        <div className="UpdateDate-row">
          <DateTime
            className="UpdateDate-datepicker"
            onChange={this.onDateTimeChange.bind(this)}
            value={this.state.selectedDateTime}
            isValidDate={this.validDate}
            locale="pt-BR"
          />

          <br />
          <br />

          <DateTime
            className="UpdateDate-datepicker"
            onChange={this.onDateTimeChangeEnd.bind(this)}
            value={this.state.selectedDateTimeEnd}
            isValidDate={this.validDate}
            locale="pt-BR"
          />

          <Button className="UpdateDate-btn"
            bsStyle="primary"
            onClick={this.confirmUpdateDateModal.bind(this)}>Trocar Data</Button>
        </div>
      </div>
    );
  }
}

UpdateDate.propTypes = {
  rowData: PropTypes.object,
  notify: PropTypes.func,
  updateCallback: PropTypes.func
};

export default UpdateDate;
