import React, { Component } from "react";
import UploadField from "../FormFields/SimpleUploadField";
import { getSignedUrl, uploadCampaignImg } from "../../actions/campaigns/uploadImg";
import Swal from "sweetalert2";

export default class NewCampaign extends Component {
  constructor(props) {
    super(props);
    const { campaign } = this.props;
    this.state = {
      campaign: {
        title: "",
        introduction: "",
        text: "",
        type: "user",
        img: "",
        cities: [],
        link: "",
        userSegment: "both",
        ...campaign
      },
      areas: [],
      base64Img: "",
      loading: false
    };
  }

  componentWillReceiveProps(newProps) {
    const { areas } = newProps;
    this.setState({ areas });
  }

  changeText = evt => {
    const { target } = evt;
    const { campaign } = this.state;

    const value = target.value === "true" ? true : target.value === "false" ? false : target.value;

    campaign[target.name] = value;

    this.setState({
      campaign: {
        ...campaign,
        [target.name]: value
      }
    });
  };

  changeCities = evt => {
    const { target } = evt;
    const { campaign } = this.state;
    if (campaign.cities.includes(target.value)) campaign.cities.pop(target.value);
    else campaign.cities.push(target.value);

    this.setState({
      campaign: {
        ...campaign
      }
    });
  };

  uploadImg = (file, onProgress) => {
    const { campaign } = this.state;
    return getSignedUrl(file).then(({ data }) => {
      this.setState({
        campaign: {
          ...campaign,
          img: data.split("?")[0]
        }
      });
      return uploadCampaignImg(data, file, onProgress).then(_ => {
        return data;
      });
    });
  };

  saveCampaign = () => {
    const { campaign } = this.state;
    this.setState({ loading: true });
    if (!campaign.title) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Título é obrigatório."
      }).then(() => {
        this.setState({ loading: false });
      });
      return;
    }
    if (!campaign.introduction) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Resumo é obrigatório."
      }).then(() => {
        this.setState({ loading: false })
      });
      return;
    }
    if (!campaign.text) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Descrição é obrigatória."
      }).then(() => {
        this.setState({ loading: false })
      });
      return;
    }
    if (!campaign.img) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Imagem é obrigatória."
      }).then(() => {
        this.setState({ loading: false })
      });
      return;
    }
    if (campaign.cities.length === 0) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Você deve selecionar, ao menos, uma cidade."
      }).then(() => {
        this.setState({ loading: false })
      });
      return;
    }
    const handleSuccess = () => {
      Swal.fire({ icon: "success", title: "Sucesso", text: "Campanha salva com sucesso" });
      this.setState({
        loading: false
      });
      this.props.history.push("/campaigns");
    };

    const handleError = ({ data }) => {
      let message = "Aconteceu algo de errado ao salvar a campanha.";
      if (data && data.error && data.error.description) message = data.error.description;
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: message
      });
      this.setState({ loading: false });
      return;
    };
    if (campaign._id) {
      const { _id } = campaign;
      delete campaign._id;
      this.props
        .editCampaign(_id, campaign)
        .then(handleSuccess)
        .catch(handleError);
    } else {
      this.props
        .save(campaign)
        .then(handleSuccess)
        .catch(handleError);
    }
  };

  render() {
    const { title, introduction, text, type, img, link, userSegment } = this.state.campaign;
    const { areas } = this.state;
    const { loading } = this.state;
    return (
      <div className="container">
        <div className="col-xs-12 col-md-8">
          <div className="col-xs-12">
            <h2>Adicionar nova Campanha</h2>
          </div>
          <div className="col-xs-12">
            <form>
              {/* Título */}
              <div className="form-group">
                <label>Título da Campanha</label>
                <input
                  name="title"
                  type="text"
                  className="form-control form-control-normal"
                  placeholder="Título"
                  value={title}
                  onChange={this.changeText}
                />
              </div>
              {/* Resumo */}
              <div className="form-group">
                <label>Resumo</label>
                <input
                  name="introduction"
                  type="text"
                  className="form-control form-control-normal"
                  placeholder="Resumo"
                  value={introduction}
                  onChange={this.changeText}
                />
              </div>
              {/* Descrição */}
              <div className="form-group">
                <label>Descrição completa</label>
                <textarea
                  name="text"
                  type="text"
                  rows="10"
                  style={{ resize: "none" }}
                  className="form-control form-control-normal"
                  placeholder="Descrição completa"
                  value={text}
                  onChange={this.changeText}
                />
              </div>
              {/* URL call to action */}
              <div className="form-group">
                <label>Link</label>
                <input
                  name="link"
                  type="text"
                  style={{ resize: "none" }}
                  className="form-control form-control-normal"
                  placeholder="https://singu.com.br/"
                  value={link}
                  onChange={this.changeText}
                />

              </div>
              {/* Imagem de exibição */}
              <div className="form-group">
                <label>Imagem de exibição</label>
                <UploadField
                  name="img"
                  label="Upload da imagem de exibição da campanha"
                  inputData={img}
                  disabled={false}
                  uploadCallback={(file, onProgress) => this.uploadImg(file, onProgress)}
                  type="image"
                  crop={false}
                />
              </div>
              {/* Cidade */}
              <div className="form-group">
                <label>Cidade disponíveis</label>
                <div className="col-xs-12">
                  <div className="form-group">
                    {areas.map((city) => {
                      return (
                        <label className="checkbox-inline" key={city._id}>
                          <input
                            type="checkbox"
                            name={city.city}
                            value={city._id}
                            onChange={this.changeCities}
                          />
                          {city.city}
                        </label>
                      )
                    })}
                  </div>
                </div>
              </div>
              {/* Disponível para */}
              <div className="form-group">
                <label>Aparecer para:</label>
                <div className="col-xs-12">
                  <div className="form-group">
                    <label className="radio-inline">
                      <input
                        name="type"
                        type="radio"
                        value="user"
                        checked={type === "user" ? true : false}
                        onChange={this.changeText}
                      />
                    Cliente
                  </label>
                    <label className="radio-inline">
                      <input
                        type="radio"
                        name="type"
                        value="artists"
                        checked={type === "artists" ? true : false}
                        onChange={this.changeText}
                      />
                    Profissional
                  </label>
                  </div>
                </div>
              </div>
              {/* Camapanha segmentada para qual tipo de usuários */}
              <div className="form-group">
                <label>Campanha segmentada para: </label>
                <div className="col-xs-12">
                  <div className="form-group">
                    <label className="radio-inline">
                      <input
                        name="userSegment"
                        type="radio"
                        value="newUsers"
                        checked={userSegment === "newUsers"}
                        onChange={this.changeText}
                      />
                      Novos Usuários
                    </label>
                    <label className="radio-inline">
                      <input
                        name="userSegment"
                        type="radio"
                        value="returningUsers"
                        checked={userSegment === "returningUsers"}
                        onChange={this.changeText}
                      />
                      Usuários Recorrentes
                    </label>
                    <label className="radio-inline">
                      <input
                        name="userSegment"
                        type="radio"
                        value="both"
                        checked={userSegment === "both"}
                        onChange={this.changeText}
                      />
                      Ambos (Todos os usuários)
                    </label>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
        <div className="col-md-4">
          <div>Preview:</div>
          <h2>{title}</h2>
          <p>{introduction}</p>
          <a href={link}>Toque para saber mais!</a>
          <hr />
          <pre
            style={{
              backgroundColor: "transparent",
              border: "none",
              overflow: "hidden",
              lineBreak: "normal",
              whiteSpace: "pre-line",
              fontFamily: "Source Sans, Helvetica Neue, Helvetica, Arial, sans-serif",
              lineHeight: "1.42857143"
            }}
          >
            {text}
          </pre>

          <p>Poderá ser visto por: {type === "user" ? "Clientes" : "Profissionais"}</p>
        </div>
        <div className="col-md-12">
          <div className="form-group">
            <button className="btn btn-success" onClick={this.saveCampaign} disabled={loading}>
              {loading ? "Aguarde..." : "Salvar Campanha"}
            </button>
          </div>
        </div>
      </div>
    );
  }
}
