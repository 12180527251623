import config from '../../../config.json';
const env = process.env.REACT_APP_ENVIRONMENT || 'stage';
const BACKEND_URL = config.api[env];
import axios from 'axios';

import { getToken } from './../../models/auth';

export const UPDATE_SCHEDULE_PARTNER = 'UPDATE_SCHEDULE_PARTNER';

export function updateSchedulePartner(scheduleId, newPartnerId, partner) {
  const updatePartnerPromise = axios.put(`${BACKEND_URL}/v2/boUser/schedule/changePartner`,
    { scheduleId, newPartnerId },
    {
      headers: { Authorization: `Bearer ${getToken()}` }
    }
  );

  return {
    type: UPDATE_SCHEDULE_PARTNER,
    payload: updatePartnerPromise,
    meta: { partner, id: scheduleId }
  };
}
