import axios from 'axios';
import config from '../../../config.json';
const env = process.env.REACT_APP_ENVIRONMENT || 'stage';
const BACKEND_URL = config.api[env];

import { getToken } from '../../models/auth';

export const FETCH_AREAS = 'FETCH_AREAS';

export function fetchAreas() {
    return {
        type: FETCH_AREAS,
        payload: axios.get(`${BACKEND_URL}/v2/bo/areas`, {
            headers: { Authorization: `Bearer ${getToken()}` }
        })
    };
}