import axios from 'axios';
import config from '../../../config.json';
const env = process.env.REACT_APP_ENVIRONMENT || 'stage';
const BACKEND_URL = config.api[env];
import { getToken } from '../../models/auth';

export function fetchManualPoints(id) {
    return axios.get(`${BACKEND_URL}/gamification/${id}`, {
        headers: { Authorization: `Bearer ${getToken()}` }
    });
}
