import React, { Component } from "react";
import { Button } from 'react-bootstrap';

export default class Actions extends Component {
  render() {
    const { rowData } = this.props;
    const { openPackagePurchaseDetails } = rowData.actions;
    
    return (
      <div style={{ marginRight: '5px', display: 'flex', alignItems: 'center' }}  >
        <Button 
          bsStyle="info" 
          style={{ marginRight: '5px' }} 
          onClick={_ => openPackagePurchaseDetails(rowData.packagePurchase)}
          title="Detalhes da compra">
            Detalhes
          <span className="glyphicon glyphicon-info-sign" style={{ marginLeft: '5px' }}/>
        </Button>
      </div>
    )
  }
}
