import config from '../../../config.json';
const env = process.env.REACT_APP_ENVIRONMENT || 'stage';
const BACKEND_URL = config.api[env];
import axios from 'axios';

import { getToken } from './../../models/auth';

export default function blockProfessional(professionalId, userId) {
  return axios.put(`${BACKEND_URL}/user/block_professional`,
    { userId, professionalId },
    { headers: { Authorization: `Bearer ${getToken()}` } }
  );
}
