import _ from "underscore";
import moment from "moment";

import moneyHelper from "../../helper/money";

const mapDayOfWeekNumberToString = {
  1: "monday", //Segunda
  2: "tuesday", //Terça
  3: "wednesday", //Quarta
  4: "thursday", //Quinta
  5: "friday", //Sexta
  6: "saturday", //Sabado
  7: "sunday", //Domingo
};

export function roundTime(moment, minutesToRound) {
  const intervalInAnHour = 60 / minutesToRound;
  let intervals = Math.floor(moment.minutes() / minutesToRound);
  if (moment.minutes() % minutesToRound !== 0) intervals++;
  if (intervals === intervalInAnHour) {
    moment.add(1, 'hour');
    intervals = 0;
  }
  moment.minutes(intervals * minutesToRound);
  moment.seconds(0);
  return moment;
}

const _getIsRangeLimitEnd = (date, limit) => {
  return date.hour() > limit || date.hour() === limit && date.minute() > 1;
}

const _getStartHour = (dynamicPrice) => {
  const { startHour } = dynamicPrice.time.flex;
  return { hour: startHour, minute: 0, second: 0, millisecond: 0 };
}

export const handleMinCheckoutDate = (date, dynamicPrice) => {
  const { endHour, minMinutesToScheduleFromNow, interval } = dynamicPrice.time.flex;

  const handleDate = moment(date);
  const checkoutDate = roundTime(handleDate.clone().add(minMinutesToScheduleFromNow, "minutes"), interval);

  const { beginAtRange } = getDynamicPriceTimeRange(dynamicPrice);

  const timeLimit = roundTime(checkoutDate.clone().add(beginAtRange, "minutes"), interval);

  if (_getIsRangeLimitEnd(checkoutDate, endHour) || _getIsRangeLimitEnd(timeLimit, endHour) || !timeLimit.isSame(checkoutDate, "day")) {
    checkoutDate.add(1, "day").set(_getStartHour(dynamicPrice));
  }

  return roundTime(checkoutDate, interval);
}

const getDynamicPriceTimeRange = (dynamicPrice) => {
  return dynamicPrice.time.flex.range[dynamicPrice.time.flex.range.length - 1];
}

export const calculateDynamicPrice = (schedule) => {
  let values = {};
  let fixo = {};
  let flex = {};
  const service = schedule.services[0];
  const nowTime = schedule.requestedOn;
  const dynamicPrice = schedule.dynamicPrice;
  const scheduleTime = moment(schedule.date);
  const mustUseDynamicPrice =
    !_.isEmpty(dynamicPrice) &&
    !_.isEmpty(dynamicPrice.time) &&
    !_.isEmpty(dynamicPrice.time.flex) &&
    !_.isEmpty(dynamicPrice.time.flex.range) &&
    schedule.scheduleDateType == "FLEX";

  if (!mustUseDynamicPrice) return service.value;

  var multiplier = 0;
  var newOriginalValue = 0.0;

  dynamicPrice.time.flex.range.forEach((range) => {
    let dateRangeEnd = moment(schedule.dateRangeEnd);
    let dateRangeStart = moment(schedule.dateRangeStart);
    let minutesFromNowSinceStartDate = dateRangeStart.diff(nowTime, "minutes");
    // Ajustar régua do preço dinâmico para now e super now.
    const minMinutesToScheduleFromNow =
      dynamicPrice.time.flex.minMinutesToScheduleFromNow;

    if (minutesFromNowSinceStartDate < minMinutesToScheduleFromNow) {
      const hoursFromNowSinceStartDate = minutesFromNowSinceStartDate / 60;
      const minHoursToScheduleFromNow = minMinutesToScheduleFromNow / 60;

      const diffHour = minHoursToScheduleFromNow - hoursFromNowSinceStartDate;

      dateRangeStart.add(diffHour, "h");
      minutesFromNowSinceStartDate =
        minutesFromNowSinceStartDate + diffHour * 60;
    }

    if (minutesFromNowSinceStartDate >= range.beginAt) {
      fixo = range.reference.includes("fixo") ? range : fixo;
      flex = range.reference.includes("flex") ? range : flex;

      if (dateRangeEnd.diff(dateRangeStart) >= range.beginAtRange * 60 * 1000) {
        schedule.rule = range;

        if (dynamicPrice.multiplierPerDayOfWeek) {
          var weekday = mapDayOfWeekNumberToString[scheduleTime.isoWeekday()];
          var multiplierPerDay = dynamicPrice.multiplierPerDayOfWeek[weekday];
          multiplier = schedule?.user?.hasSignature
            ? 0
            : multiplierPerDay || multiplier;
        }
        const calculatedValueToReplace = moneyHelper.calculateDinamicPrice(
          range.price,
          multiplier
        );
        newOriginalValue =
          calculatedValueToReplace >= 0 ? calculatedValueToReplace : 0;
        values.type = range.reference.includes("fixo") ? "fixo" : "flex";
      }
      values.flexPrice = flex.price
        ? moneyHelper.calculateDinamicPrice(flex.price, multiplier)
        : flex.price;
      values.fixPrice = fixo.price
        ? moneyHelper.calculateDinamicPrice(fixo.price, multiplier)
        : fixo.price;
    }
  });
  values.newOriginalValue =
    newOriginalValue > 0 ? newOriginalValue : service.value;
  return values;
};
