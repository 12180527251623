import React, { Component } from "react";
import { Row, Col, Button } from 'react-bootstrap';
import Swal from "sweetalert2";

import { saveAreaCategoryService } from '../../actions/areas/save_area';
import RadioGroup from '../FormFields/RadioGroup';
import { hasNullValue } from '../../helper/utils';
import UploadField from "../FormFields/SimpleUploadField";
import { getSignedUrl, uploadServiceImage } from "../../actions/areas/uploadImg";
import AreaServicePrice from "./AreaServicePrice";

const yesAndNo = [
  {
    label: 'Sim',
    value: `true`
  },
  {
    label: 'Não',
    value: `false`
  }
];

export default class AreaCategories extends Component {
  constructor(props) {
    super(props);
    this.state = {
      active: true,
      selectedCategory: "",
      services: [],
      isFormLoading: false,
      serviceId: null,
      minValue: null,
      minDuration: null,
      avgDuration: null,
      durationDescription: '',
      duration: null,
      preference: null,
      preferenceType: null,
      description: "",
      serviceImage: ""
    };
  }

  onCategoryChange = (event) => {
    const { categories } = this.props;
    const newCategory = categories.find(category => category._id === event.target.value)
    const newServices = newCategory.services;

    this.setState({
      selectedCategory: newCategory._id,
      active: true,
      services: newServices,
      serviceId: null,
      minValue: null,
      minDuration: null,
      avgDuration: null,
      durationDescription: '',
      duration: null,
      preference: null,
      preferenceType: null,
      description: "",
    })
  }

  onServiceChange = (event) => {
    const { services } = this.state;

    const {
      _id,
      active,
      minValue,
      minDuration,
      avgDuration,
      duration,
      durationDescription,
      description,
      preference,
      mobileImage
    } = services.find(service => service._id === event.target.value)

    this.setState({
      serviceId: _id,
      active,
      minValue,
      minDuration,
      avgDuration,
      duration,
      durationDescription: durationDescription['pt-BR'],
      preference: preference.value,
      preferenceType: preference.type,
      description: description['pt-BR'],
      serviceImage: mobileImage
    })
  }

  onPreferenceTypeChange = (event) => {
    this.setState({ preferenceType: event.target.value });
  }

  handleFormSubmit = (event) => {
    event.preventDefault();

    const { areaId } = this.props;
    const {
      active,
      selectedCategory,
      serviceId,
      minValue,
      minDuration,
      avgDuration,
      durationDescription,
      duration,
      preference,
      preferenceType,
      description,
      serviceImage
    } = this.state;

    const isCategoryEmpty = selectedCategory === "";
    const isServiceEmpty = !serviceId;
    const isDescriptionEmpty = description === "";

    if (isCategoryEmpty || isServiceEmpty || isDescriptionEmpty) {
      return;
    }

    this.setState({ isFormLoading: true });

    const data = {
      active,
      minValue,
      minDuration,
      avgDuration,
      durationDescription,
      duration,
      preference: {
        value: preference,
        type: preferenceType
      },
      description,
      mobileImage: serviceImage,
      mobileImageAndroid: serviceImage,
      mobileImageIOS: serviceImage
    }

    if (hasNullValue(data)) return;

    saveAreaCategoryService(areaId, serviceId, data)
      .then(() => {
        Swal.fire({ icon: "success", title: "Sucesso", text: "Detalhes do serviço atualizados com sucesso" })
      })
      .catch(error => {
        console.error(error);
        Swal.fire({ icon: "error", title: "Oops...", text: "Erro ao atualizar detalhes da área" });
      })
      .finally(() => this.setState({ isFormLoading: false }));
  }

  uploadImg = (file, onProgress) => {
    return getSignedUrl(file).then(({ data }) => {

      this.setState({
        serviceImage: data.split("?")[0]
      });

      return uploadServiceImage(data, file, onProgress).then(() => {
        return data;
      });
    });
  };

  render() {
    const { categories } = this.props;

    const {
      active,
      selectedCategory,
      services,
      serviceId,
      minValue,
      minDuration,
      avgDuration,
      durationDescription,
      duration,
      preference,
      description,
      isFormLoading,
      serviceImage
    } = this.state;

    const isCategoryEmpty = selectedCategory === "";
    const isServiceEmpty = !serviceId;
    const isFormDisabled = isCategoryEmpty || isServiceEmpty;

    return (
      <Row>
        <Col md={6}>
          <form onSubmit={this.handleFormSubmit}>
            <Col>
              <Row>
                <Col sm={6}>
                  <div className="form-group">
                    <label className="form-label" htmlFor="category">Categoria</label>
                    <select
                      className="Form-input"
                      name="category"
                      id="category"
                      defaultValue={selectedCategory}
                      onChange={this.onCategoryChange}>
                      <option value="" disabled>Selecione</option>
                      {
                        categories.map(category => {
                          return (
                            <option
                              key={category._id}
                              value={category._id}
                            >
                              {category.name["pt-BR"]}
                            </option>
                          )
                        })
                      }
                    </select>
                  </div>
                </Col>
                <Col sm={6}>
                  <div className="form-group">
                    <label className="form-label" htmlFor="service">Serviço</label>
                    <select
                      className="Form-input"
                      name="service" id="service"
                      defaultValue={serviceId || ""}
                      onChange={this.onServiceChange}>
                      <option value="">Selecione</option>
                      {
                        services.map(service => {
                          return (
                            <option
                              key={service._id}
                              value={service._id}
                            >
                              {service.name["pt-BR"]}
                            </option>
                          )
                        })
                      }
                    </select>
                  </div>
                </Col>
              </Row>
              <div style={{ display: isServiceEmpty ? "none" : "block" }}>
                <Row>
                  <Col sm={4}>
                    <div className="form-group">
                      <RadioGroup
                        name="active"
                        label="Serviço ativo?"
                        list={yesAndNo}
                        inputData={{
                          value: `${active}`,
                          onChange: (active) => {
                            return this.setState({ active: active === 'true' ? true : false })
                          }
                        }}
                      />
                    </div>
                  </Col>
                  <Col sm={4}>
                    <div className="form-group">
                      <label htmlFor="minValue">Valor mínimo</label>
                      <input
                        type="number"
                        min={0}
                        step=".01"
                        className="Form-input"
                        value={minValue || ""}
                        id="minValue"
                        placeholder="Valor mínimo"
                        onChange={(e) => this.setState({ minValue: e.target.value })}
                        disabled={isFormDisabled}
                        required />
                    </div>
                  </Col>
                  <Col sm={4}>
                    <div className="form-group">
                      <label htmlFor="minDuration">Duração mínima</label>
                      <input
                        type="number"
                        min={0}
                        step=".01"
                        className="Form-input"
                        value={minDuration || ""}
                        id="minDuration"
                        placeholder="Duração mínima"
                        onChange={(e) => this.setState({ minDuration: e.target.value })}
                        disabled={isFormDisabled}
                        required />
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col sm={6}>
                    <div className="form-group">
                      <label htmlFor="avgDuration">Duração média</label>
                      <input
                        type="number"
                        min={0}
                        step=".01"
                        className="Form-input"
                        value={avgDuration || ""}
                        id="avgDuration"
                        placeholder="Duração média"
                        onChange={(e) => this.setState({ avgDuration: e.target.value })}
                        disabled={isFormDisabled}
                        required />
                    </div>
                  </Col>
                  <Col sm={6}>
                    <div className="form-group">
                      <label htmlFor="duration">Duração máxima</label>
                      <input
                        type="number"
                        min={0}
                        step=".01"
                        className="Form-input"
                        value={duration || ""}
                        id="duration"
                        placeholder="Duração máxima"
                        onChange={(e) => this.setState({ duration: e.target.value })}
                        disabled={isFormDisabled}
                        required />
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col sm={12}>
                    <div className="form-group">
                      <label htmlFor="duration">Descrição duração do serviço</label>
                      <input
                        type="text"
                        className="Form-input"
                        value={durationDescription || ""}
                        id="durationDescription"
                        placeholder="Descrição duração do serviço"
                        onChange={(e) => this.setState({ durationDescription: e.target.value })}
                        disabled={isFormDisabled}
                        required />
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col sm={6}>
                    <div className="form-group">
                      <label htmlFor="preference" style={{ display: 'block' }}>Tipo Valor de Preferência</label>
                      <label className="radio-inline">
                        <input
                          type="radio"
                          name="inlineRadioOptions"
                          id="inlineRadio1"
                          value="P"
                          onChange={this.onPreferenceTypeChange}
                          checked={this.state.preferenceType === 'P'}
                          disabled={isFormDisabled} />
                        %
                      </label>
                      <label className="radio-inline">
                        <input
                          type="radio"
                          name="inlineRadioOptions"
                          id="inlineRadio2"
                          value="V"
                          onChange={this.onPreferenceTypeChange}
                          checked={this.state.preferenceType === 'V'}
                          disabled={isFormDisabled} />
                        Valor
                      </label>
                    </div>
                  </Col>
                  <Col sm={6}>
                    <div className="form-group">
                      <label htmlFor="preference">Valor de Preferência</label>
                      <input
                        type="number"
                        min={0}
                        step=".01"
                        className="Form-input"
                        value={preference || ""}
                        id="preference"
                        placeholder="Valor de Preferência"
                        onChange={(e) => this.setState({ preference: e.target.value })}
                        disabled={isFormDisabled}
                        required />
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col sm={12}>
                    <div className="form-group">
                      <label htmlFor="description">Descrição</label>
                      <textarea
                        type="text"
                        className="Form-input"
                        defaultValue={description || ""}
                        value={description || ''}
                        id="description"
                        placeholder="Descrição do serviço"
                        onChange={(e) =>
                          this.setState({ description: e.target.value })}
                        disabled={isFormDisabled}
                      />
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col sm={12}>
                    <div className="form-group">
                      <label>Imagem de exibição do serviço</label>
                      {(!isServiceEmpty && !isCategoryEmpty) && (
                        <UploadField
                          name="img"
                          label="Faça o upload da imagem de exibição do serviço"
                          inputData={serviceImage}
                          disabled={false}
                          uploadCallback={(file, onProgress) => this.uploadImg(file, onProgress)}
                          type="image"
                          crop={false}
                        />
                      )}
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col sm={12}>
                    {
                      isFormLoading ?
                        (
                          <Button type="submit" bsStyle="info" className="button-icon" bsSize="large" disabled={isFormLoading}>
                            <span className="spinner glyphicon glyphicon-refresh" ></span>
                            Salvando...
                          </Button>
                        ) :
                        (
                          <Button type="submit" bsStyle="info" bsSize="large" disabled={isFormDisabled}>
                            Salvar
                          </Button>
                        )
                    }
                  </Col>
                </Row>
              </div>
            </Col>
          </form>
        </Col>

        <Col md={6}>
          <div style={{ display: isServiceEmpty ? "none" : "block" }}>
            {
              !isServiceEmpty && (
                <AreaServicePrice
                  serviceId={serviceId}
                  areaId={this.props.areaId}
                  isFormDisabled={isFormDisabled}
                />
              )
            }
          </div>
        </Col>
      </Row>
    )

  }
}