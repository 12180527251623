import config from '../../../config.json';
const env = process.env.REACT_APP_ENVIRONMENT || 'stage';
const BACKEND_URL = config.api[env];
import axios from 'axios';

import { getToken } from './../../models/auth';

export const RECHARGE_SCHEDULE = 'RECHARGE_SCHEDULE';

export function rechargeSchedule(scheduleId) {
  const rechargePromise = axios.get(`${BACKEND_URL}/v2/bo/recharge/${scheduleId}`,
    {
      headers: { Authorization: `Bearer ${getToken()}` }
    }
  );

  return {
    type: RECHARGE_SCHEDULE,
    payload: rechargePromise,
    meta: { id: scheduleId }
  };
}
