import React, { Component, PropTypes } from 'react';
import CheckboxGroup from "../Helpers/CheckboxGroup";
import Loading from '../Global/Loading/Loading';

import './LeadReport.css';

const categoriesList = [
  { text: "MANICURE", value: "Manicure" },
  { text: "MASSOTERAPEUTA", value: "Massoterapeuta" },
  { text: "DEPILADOR(A)", value: "Depilador(a)" },
  { text: "ESCOVISTA", value: "Escovista" },
  { text: "ESTETICISTA", value: "Esteticista" },
  { text: "ESTETICISTA", value: "Limpeza de Pele" },
  { text: "MAQUIADORA", value: "Maquiadora" },
]

const citiesList = [
  { text: "São Paulo", value: "São Paulo" },
  { text: "Rio de Janeiro", value: "Rio de Janeiro" },
  { text: "Brasília", value: "Brasília" },
  { text: "Minas Gerais", value: "Minas Gerais" },
  { text: "Outros", value: "Outors" }
]

class LeadReport extends Component {

  constructor(props) {
    super(props);

    this.state = {
      minDate: props.filters.dateRangeStart,
      maxDate: props.filters.dateRangeEnd,
      cities: [],
      categories: [],
      data: [],
      not_scheduled: "",
      scheduled: "",
      giveup: "",
      cantattend: "",
      testfailed: "",
      artist: "",
    };
  }

  componentWillMount() {
    if (this.props.data) {
      this.setState({ data: this.props.data });
    }
  }

  componentWillReceiveProps(newProps) {
    if (newProps.data) {
      this.setState({ data: newProps.data });
    }
  }

  _handleMinDateChange = (e) => {
    this.setState({ minDate: e.target.value });
  }

  _handleMaxDateChange = (e) => {
    this.setState({ maxDate: e.target.value });
  }

  _handleChangeCity = (values) => {
    this.setState({ cities: values });
  }

  _handleChangeCategory = (values) => {
    this.setState({ categories: values })
  }

  _handleKeyPress = (e) => {
    if (e.charCode === 13) {
      this._handleOnSearchClick();
    }
  }

  _handleOnSearchClick = () => {
    this.props.onReload(this._handleParams());
  }

  _handleOnExportClick = () => {
    this.props.onExport(this._handleParams());
  }

  _handleParams = () => {
    return {
      dateRangeStart: this.state.minDate,
      dateRangeEnd: this.state.maxDate,
      categories: this.state.categories,
      cities: this.state.cities
    };
  }

  render() {
    const { loading } = this.props;
    const { data } = this.state;

    const showDataTable = data && Object.keys(data).length > 0 && !loading;

    const scheduled = Object.keys(data).reduce((total, key) => {
      if (!['2', '3', '4', '6', '11'].includes(key)) return total;

      return total + data[key].total;
    }, 0);

    return (
      <div className="LeadReport" >
        <div className="row">
          {/* Filter */}
          <div className="col-xs-12">
            <div className="row">
              {/* Date Range */}
              <div className="col-xs-12" style={{ marginBottom: '0.5rem' }}>
                <div className="row">
                  <div className="col-xs-12">
                    <b style={{ display: 'block', marginBottom: '0.5rem' }}>Cadastrado Entre:</b>
                  </div>
                  <div className="col-xs-12 col-sm-5 col-md-4 col-lg-3">
                    <input
                      name="minDate"
                      onChange={this._handleMinDateChange}
                      type="date"
                      width="300px"
                      value={this.state.minDate}
                      className="form-control"
                      style={{ width: '100%', marginBottom: '0.25rem' }}
                    />
                  </div>
                  <div className="col-xs-12 col-sm-5 col-md-4 col-lg-3">
                    <input
                      name="maxDate"
                      onChange={this._handleMaxDateChange}
                      type="date"
                      width="300px"
                      value={this.state.maxDate}
                      className="form-control"
                      style={{ width: '100%', marginBottom: '0.25rem' }}
                    />
                  </div>
                </div>
              </div>
              {/* Cities */}
              <div className="col-xs-12" style={{ marginBottom: '0.5rem' }}>
                <div className="row">
                  <div className="col-xs-12">
                    <b style={{ display: 'block', marginBottom: '0.5rem' }}>Cidade:</b>
                  </div>
                  <div className="col-xs-12">
                    <CheckboxGroup
                      onChange={this._handleChangeCity}
                      selectedItems={this.state.cities}
                      itemsList={citiesList}
                    />
                  </div>
                </div>
              </div>
              {/* Categories */}
              <div className="col-xs-12" style={{ marginBottom: '0.5rem' }}>
                <div className="row">
                  <div className="col-xs-12">
                    <b style={{ display: 'block', marginBottom: '0.5rem' }}>Categoria:</b>
                  </div>
                  <div className="col-xs-12">
                    <CheckboxGroup
                      onChange={this._handleChangeCategory}
                      selectedItems={this.state.categories}
                      itemsList={categoriesList}
                    />
                  </div>
                </div>
              </div>
              {/* Search Button */}
              <div className="col-xs-12" style={{ marginBottom: '0.5rem' }}>
                <div className="row">
                  <div className="col-xs-12">
                    <button
                      className="btn btn-info"
                      style={{ marginLeft: 10, margin: '0 auto' }}
                      onClick={this._handleOnSearchClick}
                    >
                      <b>BUSCAR LEADS</b>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* Results */}
          {showDataTable && (
            <div className="col-xs-12">
              <div className="row">
                <div className="col-xs-12">
                  <h3>Resultados da busca</h3>
                </div>
                {/* Report Results  */}
                <div className="col-xs-12 col-md-6 col-lg-4">
                  <div className="row">
                    <div className="col-xs-12">
                      <div className="row report-macro-data">
                        <div className="col-xs-8">
                          <h4>Não agendado</h4>
                        </div>
                        <div className="col-xs-4">
                          <h3>{this.state.data['1'] && this.state.data['1'].total}</h3>
                        </div>
                      </div>
                    </div>
                    <div className="col-xs-12">
                      <hr />
                    </div>
                    <div className="col-xs-12">
                      <div className="row report-macro-data">
                        <div className="col-xs-8">
                          <h4>Agendado</h4>
                        </div>
                        <div className="col-xs-4">
                          <h3>{scheduled}</h3>
                        </div>
                      </div>
                      <div className="row report-micro-data">
                        <div className="col-xs-8">
                          <h5>Teste não realizado</h5>
                        </div>
                        <div className="col-xs-4">
                          <h4>{this.state.data['2'] && this.state.data['2'].total}</h4>
                        </div>
                      </div>
                      <div className="row report-micro-data">
                        <div className="col-xs-8">
                          <h5>Desistiu de entrar na Singu</h5>
                        </div>
                        <div className="col-xs-4">
                          <h4>{this.state.data['3'] && this.state.data['3'].total}</h4>
                        </div>
                      </div>
                      <div className="row report-micro-data">
                        <div className="col-xs-8">
                          <h5>Não pode comparecer ao teste</h5>
                        </div>
                        <div className="col-xs-4">
                          <h4>{this.state.data['4'] && this.state.data['4'].total}</h4>
                        </div>
                      </div>
                      <div className="row report-micro-data">
                        <div className="col-xs-8">
                          <h5>Não passou no teste</h5>
                        </div>
                        <div className="col-xs-4">
                          <h4>{this.state.data['6'] && this.state.data['6'].total}</h4>
                        </div>
                      </div>
                      <div className="row report-micro-data">
                        <div className="col-xs-8">
                          <h5>Transformado em artista</h5>
                        </div>
                        <div className="col-xs-4">
                          <h4>{this.state.data['11'] && this.state.data['11'].total}</h4>
                        </div>
                      </div>
                    </div>
                    <div className="col-xs-12">
                      <hr />
                    </div>
                    <div className="col-xs-12">
                      <div className="row report-macro-data">
                        <div className="col-xs-8">
                          <h4>Total</h4>
                        </div>
                        <div className="col-xs-4">
                          <h3>{this.state.data['total'] && this.state.data['total'].total}</h3>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* Result to CSV Button */}
                <div className="col-xs-12">
                  <div className="row">
                    <div className="col-xs-12">
                      <button
                        style={{ marginTop: 3 }}
                        className="btn btn-info"
                        onClick={this._handleOnExportClick}>
                        EXPORTAR PARA CSV &nbsp;<span className="glyphicon glyphicon-export" />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
          {loading && (
            <div className="col-xs-12">
              <Loading />
            </div>
          )}
        </div>
      </div>
    );
  }
}

LeadReport.propTypes = {
  data: PropTypes.object,
  loading: PropTypes.bool,
  onChangeFilters: PropTypes.func,
  filters: PropTypes.object
};

export default LeadReport;
