import React, { Component } from 'react';
import moment from 'moment-timezone';

export default class CreatedTime extends Component {
  render() {
    const { data: createdTime } = this.props;

    return (
      <h4 style={{ margin: 0 }}>{moment.tz(createdTime, 'America/Sao_Paulo').format('DD/MM/YY HH:mm')}</h4>
    )
  }
}