import React, { Component } from 'react';
import { Modal, Button } from 'react-bootstrap';
import moment from 'moment';

import ProgressBar from '../../../Global/ProgressBar'; 
import bronzeMedal from '../../../../images/gamification/medals/bronze.png';
import silverMedal from '../../../../images/gamification/medals/prata.png'; 
import goldMedal from '../../../../images/gamification/medals/ouro.png';
import diamondMedal from '../../../../images/gamification/medals/diamante.png'; 
import singularMedal from '../../../../images/gamification/medals/singular.png';
import './ArtistGamificationModal.css';
import PointsGamification from './PointsGamification';

const months = [
  'Janeiro',
  'Fevereiro',
  'Março',
  'Abril',
  'Maio',
  'Junho',
  'Julho',
  'Agosto',
  'Setembro',
  'Outubro',
  'Novembro',
  'Dezembro'
];
 
const medals = {
  'Bronze': bronzeMedal,
  'Prata': silverMedal,
  'Ouro': goldMedal,
  'Diamante': diamondMedal,
  'Singular': singularMedal
}

export default class ArtistGamificationModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pointsModal: false
    }
  }
 

  renderCurrentGamification = () => {
    const { gamification } = this.props; 
    if (!gamification || !gamification.currentMonth) return <h3>Sem dados necessários para exibição do mês atual.</h3>

    const { currentMonth } = gamification;
    
    const tier = currentMonth.tier? currentMonth.tier : this.props.artist.tier;
    const currentDate = new Date();
    const momentNow = moment(currentDate);
    const daysFromMonthBegin = momentNow.diff(moment(currentDate.setDate(0)), 'days');
    const daysInMonth = momentNow.daysInMonth();
    const currentMonthName = months[momentNow.month()];

    const progressBarSteps = ['1', '7', '15', '23', daysInMonth.toString()].map(step => <div>{step}</div>);

    return (
      <div className="gamification_current_container">
        <div className="row">
          <div className="col-sm-2">
            <h3 className="gamification_title">
              MÊS ATUAL
              <span>{currentMonthName}</span>
            </h3>
          </div>
          <div className="col-sm-10 text-right">
            <button className="btn btn-default" onClick={() => { this.setState({ pointsModal: true }) }}>Adicionar pontos</button>
          </div>
        </div>
        <ProgressBar height={20} value={daysFromMonthBegin} max={daysInMonth} steps={progressBarSteps} style={{ marginBottom: '0.5rem' }} />
        <div className="gamification_current_metrics">
          <div className="gamification_current_metric">
            <div>
              <p>{daysFromMonthBegin}</p>
            </div>
            <span>Dias</span>
          </div>
          <div className="gamification_current_metric">
            <div style={{ textAlign: 'center' }}> 
              {tier? <img src={medals[tier]} alt="" /> : 'Sem nível'}
            </div>
            <span>{tier || '-'}</span>
          </div>
          <div className="gamification_current_metric">
            <div>
              <p className={`${(currentMonth.points && currentMonth.points > 1000) && 'metric-bigger-1000'}`}>{currentMonth.points || '-'}</p>
            </div>
            <span>Pontos</span>
          </div> 
        </div>
      </div>
    )
  }

  renderPastMonthGamification = () => {
    const { gamification } = this.props;

    if (!gamification || !gamification.lastMonth ) {
      return <h3>Sem dados necessários para exibição do mês passado.</h3>
    }

    const { lastMonth } = gamification;

    const currentDate = new Date();
    const momentNow = moment(currentDate);
    const pastMonthName = months[momentNow.month() - 1];

    return (
      <div className="gamification_current_container">
        <h3 className="gamification_title">
          MÊS ANTERIOR
          <span>{pastMonthName}</span>
        </h3>
        <div className="gamification_current_metrics">
          <div className="gamification_current_metric">
            <div style={{ textAlign: 'center' }}>
              {lastMonth.tier ? <img src={medals[lastMonth.tier]} alt="" /> : 'Sem nível'}
            </div>
            <span>{lastMonth.tier || '-'}</span>
          </div>
          <div className="gamification_current_metric">
            <div>
              <p className={`${lastMonth && lastMonth.points > 1000 && 'metric-bigger-1000'}`}>{lastMonth && lastMonth.points || 0}</p>
            </div>
            <span>Pontos</span>
          </div> 
        </div> 
      </div>
    )
  }

  render() {
    const { isOpen, toggle } = this.props;
    const { pointsModal } = this.state

    return (
      <Modal bsSize="large" show={isOpen} onHide={toggle}>
        <Modal.Header closeButton>
          <Modal.Title className="text-center">Gamification</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="gamification_container">

            {this.renderCurrentGamification()}
            <hr />
            {this.renderPastMonthGamification()}

            <PointsGamification
              id={this.props.artist._id}
              open={pointsModal}
              addPointsGamification={this.props.addPointsGamification}
              close={() => { this.setState({ pointsModal: false }) }}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={toggle}>Fechar</Button>
        </Modal.Footer>
      </Modal>
    )
  }
}