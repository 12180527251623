import axios from 'axios';
import config from '../../../config.json';
const env = process.env.REACT_APP_ENVIRONMENT || 'stage';
const BACKEND_URL = config.api[env];
import { getToken } from "../../models/auth";

export function uploadServiceImage(signedUrl, file, onProgress) {
  var instance = axios.create();
  const splitedName = file.name.split(".");
  const options = {
    progress: onProgress,
    headers: {
      'Content-Type': splitedName[splitedName.length - 1]
    }
  };
  return instance.put(signedUrl, file, options);
}

export function getSignedUrl(file) {
  return axios.get(`${BACKEND_URL}/areas/img/url`, {
    params: { filename: file.name, filetype: file.type },
    headers: { Authorization: `Bearer ${getToken()}` }
  });
}
