/* global _ */

import { NEW_ARTIST } from './../actions/artists/new_artist';
import { FETCH_ARTISTS } from './../actions/artists/fetch_artists';
import { UPDATE_ARTIST } from './../actions/artists/update_artist';
import { FETCH_ARTIST } from './../actions/artists/fetch_artist';
import { RESET_CURRENT_ARTIST } from './../actions/artists/reset_artist';

const artist = (state, action) => {
  switch (action.type) {
    case UPDATE_ARTIST:
      if (state._id === action.meta.id) {
        return {
          ...state,
          ...action.meta.data
        };
      }

      return state;

    default:
      return state;
  }
};

const INITIAL_STATE = {
  all: [],
  current: null
};

const partnersReducer = (state = INITIAL_STATE, action) => {
  if (action.error) {
    return state;
  }

  switch (action.type) {
    case FETCH_ARTISTS:
      return {
        ...state,
        all: action.payload.data.artists,
        totalItems: action.payload.data.totalItems,
        itemsPerPage: action.payload.data.itemsPerPage
      };

    case UPDATE_ARTIST:
      return {
        ...state,
        all: _.map(state.all, a => artist(a, action))
      };

    case FETCH_ARTIST:
      return {
        ...state,
        current: action.payload.data
      };

    case NEW_ARTIST:
      return state;

    case RESET_CURRENT_ARTIST:
      return {
        ...state,
        current: null
      };

    default:
      return state;
  }
};

export default partnersReducer;
