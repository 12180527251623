import React, { Component } from "react";

import ListComponent from "../../components/Staff/List";

import { fetchStaffs } from "../../actions/staff/fetch_staffs";
import { saveStaff } from "../../actions/staff/save_staffs";
import Swal from "sweetalert2";

export default class ListStaff extends Component {
  constructor(props) {
    super(props);
    this.state = {
      staffs: [],
      loading: true,
    };
  }

  componentWillMount() {
    this.getStaffs();
  }

  getStaffs = async () => {
    try {
      const { data: staffs } = await fetchStaffs();

      this.setState({ staffs })
    } catch (err) {
      console.error(err);
      Swal.fire({
        icon: "error",
        text: "Não foi possível carregar os acessos.\nTeste mais tarde.",
      });
    } finally {
      this.setState({ loading: false });
    }
  };

  _saveStaff = async (params) => {
    try {
      // Chamar api de google auth
      // Se tiver token salva staff
      // Se não, pega url e redireciona e volta pra ca

      return saveStaff(params);
    } catch (err) {
      console.error(err);
    }
  };

  render() {
    const { loading, staffs } = this.state;

    if (loading) {
      return <h1>Carregando...</h1>;
    } else {
      return <ListComponent 
        staffs={staffs} 
        saveStaff={this._saveStaff} 
        getStaffs={this.getStaffs}
        location={this.props.location}
        />;
    }
  }
}
