import React, { Component, PropTypes } from "react";
import { fetchAreasService } from "../../actions/areas/fetch_areas";
import Loading from "../Global/Loading/Loading";

export default class Areas extends Component {
  constructor(props) {
    super(props)
    this.state = {
      areas: [],
      categories: [],
      selectedAreas: [],
      selectedServices: [],
    }
  }

  componentDidMount() {
    fetchAreasService().then(({ data }) => {
      this.setState({ areas: data })
    });
  }

  render() {
    const { areas } = this.state
    if (areas && areas.length > 0) {
      return (
        <div className="col-xd-12">
          <p>Cidade</p>
          <div className={`Form`} onChange={(evt) => this.props.onChange(evt.target.value)}>
            <select defaultValue={this.props.selected} className="form-control">
              {!this.props.selected && <option value="">Selecione uma cidade</option>}
              {
                areas.map((area, index) => {
                  return (<option key={index} value={area._id}>{area.city}</option>)
                })
              }
            </select>
          </div>
        </div>
      )
    } else {
      return (
        <div>
          <p>Carregando areas</p>
          <Loading />
        </div>
      )
    }
  }
}

// Areas.prototype = {
//   onChange: PropTypes.func,
//   select: PropTypes.string
// }

// export default Areas;