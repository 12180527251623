import React, { Component } from "react";

export default class UserLink extends Component {
  render() {
    const { data: user } = this.props;
    const url = `#/users/${user._id}`;

    return (
      <a href={url} target="_blank" style={{ textDecoration: 'none' }}>
        <h4 style={{ margin: 0, fontWeight: 'bold' }}>{user.name}</h4>
        <h5 style={{ margin: 0, color: '#333333' }}>{user.email}</h5>
      </a>
    )
  }
}