import config from '../../../config.json';
const env = process.env.REACT_APP_ENVIRONMENT || 'stage';
const BACKEND_URL = config.api[env];
import axios from 'axios';

import { getToken } from "./../../models/auth";

export const generateUserToken = ({ id, name, email }) => {
  return axios.post(
    `${BACKEND_URL}/user/token`,
    { _id: id, name, email },
    { headers: { Authorization: `Bearer ${getToken()}` } }
  );
};
