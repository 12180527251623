import axios from 'axios';
import config from '../../../config.json';
const env = process.env.REACT_APP_ENVIRONMENT || 'stage';
const BACKEND_URL = config.api[env];

import React from "react";
import _ from 'underscore';
import moment from 'moment';

import { Component, PropTypes } from 'react';
import Griddle from 'griddle-react';
import { connect } from 'react-redux';
import { Button, Modal } from 'react-bootstrap';
import NotificationSystem from 'react-notification-system';
import PartnersMetadata from './Partners_metadata';
import formatter from '../../helper/formatters';
import { getToken } from '../../models/auth';
import PageHeader from './../../components/Global/PageHeader';
import Loading from './../../components/Global/Loading/Loading';
import UpdatePartnerProgress from './../../components/Partners/UpdatePartnerProgress/UpdatePartnerProgress';
import BanPartner from './../../components/Partners/BanPartner/BanPartner';

// actions
import { fetchPartners } from './../../actions/partners/fetch_partners';
import { updatePartner } from './../../actions/partners/update_partner';
import { banPartner } from './../../actions/partners/ban_partner';
import { setCurrentPartner } from './../../actions/partners/set_partner';

class Partners extends Component {
  constructor(props) {
    super(props);

    this.state = {
      partnersArrived: false,
      error: false,
      partners: [],
      isConfirmationModalOpened: false
    };
  }

  componentWillMount() {
    this.getAllPartners();
  }

  componentWillReceiveProps(newProps) {
    const { partners } = newProps;

    if (partners) {
      this.mapPartners(partners);
    }
  }

  getAllPartners() {
    const success = () => this.setState({ partnersArrived: true });
    const error = () => this.setState({ error: true });

    this.props.fetchPartners().then(success, error);
  }

  notify(message, level) {
    const title = level === 'success' ? 'Sucesso' : 'Erro';
    this.refs.notificationSystem.addNotification({
      title,
      message,
      level,
      position: 'tc'
    });
  }

  migratePartner(index) {
    const partner = this.props.partners[index];

    this.props.setCurrentPartner(partner);
    this.context.router.push('new-artist');
  }

  mapPartners(partners) {
    const self = this;
    const mappedPartners = _.map(partners, (partner, i) => (
      {
        id: partner._id,
        index: i,
        createdDate: moment(partner.createdDate).format('DD/MM/YYYY HH:mm'),
        name: partner.name,
        email: partner.email,
        phone: formatter.formatPhone(partner.phone),
        address: formatter.formatAddress(partner.address) || 'Sem endereço',
        actions: {
          openConfirmationModal: self.openConfirmationModal.bind(self),
          migratePartner: self.migratePartner.bind(self)
        }
      }
    ));

    this.setState({ partners: mappedPartners });
  }

  openConfirmationModal(partner, modalContent) {
    this.setState({ modalContent, partner, isConfirmationModalOpened: true });
  }

  closeModal() {
    this.setState({ isConfirmationModalOpened: false });
  }

  exportCsv() {
    axios.get(
      `${BACKEND_URL}/v2/bo/partners-export`, {
        headers: { Authorization: `Bearer ${getToken()}` }
      }
    ).then(({ data }) => {
      const dataBlob = new Blob([data], { type: 'text/csv' });
      const csvURL = window.URL.createObjectURL(dataBlob);

      const tempLink = document.createElement('a');
      tempLink.href = csvURL;
      tempLink.setAttribute('download', 'Artistas.csv');
      tempLink.click();
    });
  }

  renderModalContent() {
    const { modalContent } = this.state;

    if (modalContent === 'ban-partner') {
      return (
        <BanPartner updateCallback={this.props.banPartner}
          cancelCallback={() => this.closeModal()}
          partner={this.state.partner}
          notify={(msg, lvl) => this.notify(msg, lvl)}
        />
      );
    }

    return (
      <UpdatePartnerProgress updateCallback={this.props.updatePartner}
        cancelCallback={() => this.closeModal()}
        partner={this.state.partner}
        notify={(msg, lvl) => this.notify(msg, lvl)}
      />
    );
  }

  render() {
    const { partners, partnersArrived } = this.state;

    if (!partnersArrived) {
      return (
        <div>
          <PageHeader title="Partners" />
          <Loading />
        </div>
      );
    }

    return (
      <div>
        <PageHeader title="Partners" />

        <div className="row">
          <Button onClick={this.exportCsv.bind(this)}
            className="Schedules-btn-export btn btn-info"
            title="Exportar csv">
            Exportar csv <span className="glyphicon glyphicon-export" />
          </Button>
        </div>

        <NotificationSystem ref="notificationSystem" />

        <div className="row">
          <Griddle
            results={partners}
            showFilter={true}
            showSettings={true}
            columnMetadata={PartnersMetadata}
            resultsPerPage={10}
            useFixedLayout={false}
            tableClassName={'GriddleTable table'}
            useGriddleStyles={false}
            settingsToggleClassName={'btn btn-default'}
            columns={['id', 'createdDate', 'name', 'email', 'phone', 'address', 'actions']}
          />
        </div>

        <Modal className="Modal"
          show={this.state.isConfirmationModalOpened}
          onHide={() => this.closeModal()}>
          <Modal.Header closeButton>
            <Modal.Title className="Modal-title">Detalhes do Artista</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {this.renderModalContent()}
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={() => this.closeModal()}>Fechar</Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

Partners.propTypes = {
  fetchPartners: PropTypes.func,
  partners: PropTypes.array,
  updatePartner: PropTypes.func,
  banPartner: PropTypes.func
};

Partners.contextTypes = {
  router: PropTypes.object.isRequired
};

function mapStateToProps(state) {
  return {
    partners: state.partners.all
  };
}

const mapActionsToProps = {
  fetchPartners,
  updatePartner,
  banPartner,
  setCurrentPartner
};

export default connect(mapStateToProps, mapActionsToProps)(Partners);
