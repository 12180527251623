import axios from 'axios';
import config from '../../../config.json';
const env = process.env.REACT_APP_ENVIRONMENT || 'stage';
const BACKEND_URL = config.api[env];

import { getToken } from './../../models/auth';

export const FETCH_PARTNERS = 'FETCH_PARTNERS';

export function fetchPartners(active = false) {
  const partnersPromise = axios.get(`${BACKEND_URL}/v2/bo/partners`, {
    headers: { Authorization: `Bearer ${getToken()}` },
    params: {
      activated: active,
      itemsPerPage: 999999
    }
  });

  return {
    type: FETCH_PARTNERS,
    payload: partnersPromise
  };
}
