import React from "react";
import ReactQuill from "react-quill";
import { Col, Row, Button } from "react-bootstrap";
import './ArtistPGMEdit.css';

class ArtistPGPEdit extends React.Component {
  constructor(props) {
    super(props);
  }

  modules = {
    toolbar: [
      ["bold", "italic", "underline", "strike"],
      [{ color: ["#FF7A66", "red", "black", "blue", "green", "white"] }],
      ["clean"],
    ],
  };
  
  formats = ["strike", "bold", "italic", "underline", "color"];

    render() {
        return (
            <div className="container">
                <Row>
                        <h1>Editar PGP</h1>

                        <div className="form-group">
                          <label htmlFor="title">Título</label>
                          <textarea
                            type="text"
                            className="Form-input"
                            onChange={(ev) => this.props.onChange(ev.target.value, 'title')}
                            value={this?.props?.values?.title}
                            required
                          />
                        </div>

                        <div className="form-group">
                          <label htmlFor="text">Conteúdo</label>
                          <ReactQuill
                            theme="snow"
                            modules={this.modules}
                            formats={this.formats}
                            defaultValue={this.props.values.message}
                            value={this?.props?.values?.message}
                            onChange={value => this.props.onChange(value, 'message')}
                          />
                        </div>

                        <div className="form-group">
                          <label htmlFor="title">Mensagem Compartilhada</label>
                          <textarea
                            type="text"
                            className="Form-input"
                            onChange={(ev) => this.props.onChange(ev.target.value, 'share')}
                            value={this?.props?.values?.share}
                            required
                          />
                        </div>

                        <div className="form-group">
                          <label htmlFor="pgm-activated">Ativo</label>
                          <input 
                            type="checkbox" 
                            id="pgm-activated"
                            name="activated"
                            checked={this?.props?.values?.activated}
                            onChange={ev => this.props.onChange(ev.target.checked, 'activated')}
                          />
                        </div>
                </Row>

                <Row>
                  <Col md={6}>
                    <div className="form-group">
                      <label htmlFor="title">Valor ganho quando lead é aprovado</label>
                      <input
                        type="number"
                        className="Form-input"
                        onChange={(ev) => this.props.onChange(ev.target.value, 'approved')}
                        value={this?.props?.values?.approved}
                        required
                      />
                    </div>

                    <div className="form-group">
                      <label htmlFor="title">Valor quantidade de pedidos para necessário</label>
                      <input
                        type="number"
                        className="Form-input"
                        onChange={(ev) => this.props.onChange(ev.target.value, 'engagementQuantity')}
                        value={this?.props?.values?.engagementQuantity}
                        required
                      />
                    </div>
                  </Col>

                  <Col md={6}>
                    <div className="form-group">
                      <label htmlFor="title">Valor ganho por engajamento</label>
                      <input
                        type="number"
                        className="Form-input"
                        onChange={(ev) => this.props.onChange(ev.target.value, 'engaged')}
                        value={this?.props?.values?.engaged}
                        required
                      />
                    </div>

                    <div className="form-group">
                      <label htmlFor="title">Valor ganho pelo lead quando aprovado</label>
                      <input
                        type="number"
                        className="Form-input"
                        onChange={(ev) => this.props.onChange(ev.target.value, 'leadGain')}
                        value={this?.props?.values?.leadGain}
                        required
                      />
                    </div>
                  </Col>
                </Row>

                <Row className="text-right">
                  <Button 
                    bsStyle="info" 
                    bsSize="large" 
                    onClick={this.props.onSave}
                  >
                    Salvar
                  </Button>
                </Row>
            </div>
        )
    }
}

export default ArtistPGPEdit;