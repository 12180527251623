import axios from 'axios';
import config from '../../../config.json';
const env = process.env.REACT_APP_ENVIRONMENT || 'stage';
const BACKEND_URL = config.api[env];

import { getToken } from "../../models/auth"; 
export const FETCH_POLYGONSBYAREAID = 'FETCH_POLYGONSBYAREAID';

export function fetchPolygonsByAreaId(areaId) {
  return {
    type: FETCH_POLYGONSBYAREAID,
    payload: axios.get(`${BACKEND_URL}/polygons/${areaId}`, {
      headers: { Authorization: `Bearer ${getToken()}` }
    })
  }
}