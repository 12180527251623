import React, { Component } from 'react';

import './styles.css';

class Dropdown extends Component {
  state = {
    open: false,
    query: ""
  }

  open() {
    this.state.open ? this.setState({ open: false }) : this.setState({ open: true });
  }

  filter(options) {
    return options.filter(option => {
      return option[this.props.label].toLowerCase().indexOf(this.state.query.toLowerCase()) > -1

    });
  }

  displayValue() {
    if (this.state.query.length > 0) {
      return this.state.query
    }

    if (this.props.value) {
      return this.props.value[this.props.label]
    }
    return ''
  }

  render() {
    const { open } = this.state;
    const { options, id, label, header, value, onChange, isInformed } = this.props;
    return (
      <div className="dropdown">

        <div className="control" onClick={(() => this.open())}>
          <div className="select-value">
            <input
              className="filter from-control form-control-normal"
              type="text"
              placeholder={value ? value[label] : header}
              value={this.state.query}
              onChange={e => { this.setState({ query: e.target.value }) }}
              onClick={() => this.open()}
            />
          </div>
          <div className={`arrow ${open ? "open" : null}`}></div>
        </div>

        <div className={`options ${open ? "open" : null}`}>

          {this.filter(options).map((option) => (
            <div key={option[id]} className={`option ${value === option ? "selected" : null}`}
              onClick={() => {
                this.setState({ query: '', open: false });
                onChange(option);
              }
              }>{option[isInformed] ? `${option[label]} (Avisado)` : `${option[label]} (Não Avisado)`}</div>

          ))}
        </div>

      </div>
    )
  }

}

export default Dropdown;