import config from '../../../config.json';
const env = process.env.REACT_APP_ENVIRONMENT || 'stage';
const BACKEND_URL = config.api[env];
import axios from 'axios';

import { getToken } from './../../models/auth';


export function saveStaff(params) {
  if (params._id) {
    return axios.put(`${BACKEND_URL}/staff`, params,
      { headers: { Authorization: `Bearer ${getToken()}` } });
  }

  return axios.post(`${BACKEND_URL}/staff`, params,
    { headers: { Authorization: `Bearer ${getToken()}` } });
}
