import React from "react";
import { Component } from "react";
import PageHeader from "./../../components/Global/PageHeader";
import LeadReport from "./../../components/Leads/LeadReport";
import { getReport, downloadReport } from "./../../services/leads";
import Swal from 'sweetalert2';

import FileDownload from "js-file-download"

class LeadsReport extends Component {

  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      reportResult: {
        "1": {},
        "2": {},
        "3": {},
        "4": {},
        "6": {},
        "11": {},
        "total": ""
      },
      filters: {
        dateRangeStart: "",
        dateRangeEnd: ""
      }
    };
  }

  componentWillMount() {
    const minDate = new Date();
    minDate.setDate(minDate.getDate() - 7);
    minDate.setHours(0, 0, 0, 0);

    const maxDate = new Date();

    let { dateRangeStart, dateRangeEnd } = this.state.filters;

    dateRangeStart = minDate.toISOString().substr(0, minDate.toISOString().search("T"));
    dateRangeEnd = maxDate.toISOString().substr(0, maxDate.toISOString().search("T"));

    this.setState({
      filters: {
        dateRangeStart,
        dateRangeEnd
      }
    }, () => this.loadData(this.state.filters));
  }

  loadData(params) {
    this.setState({
      loading: true
    });

    getReport(params)
      .then(response => {
        const { data } = response;

        if (response.status === 200) {
          const { reportResult } = this.state
          for (const reportData of data) {
            reportResult[reportData.code] = reportData
          }
          this.setState({ reportResult });
        }
      })
      .catch(error => Swal.fire({
        title: "Ops...",
        icon: "error",
        text: error.message || error.description || "Ocorreu um erro ao recuperar dados, tente novamente"
      }))
      .finally(() => this.setState({ loading: false }));
  }

  downloadData(params) {
    this.setState({
      loading: true
    });

    downloadReport(params)
      .then(response => {
        FileDownload(response.data, "leads.csv")
        console.log(response);
      })
      .catch(error => Swal.fire({
        title: "Ops...",
        icon: "error",
        text: error.message || error.description || "Ocorreu um erro ao exportar dados, tente novamente"
      }))
      .finally(() => this.setState({ loading: false }));
  }

  render() {
    return (
      <div>
        <PageHeader title="Leads" />
        <LeadReport
          loading={this.state.loading}
          data={this.state.reportResult}
          filters={this.state.filters}
          onReload={this.loadData.bind(this)}
          onExport={this.downloadData.bind(this)}
        />
      </div>
    );
  }
}

export default LeadsReport;
