"use strict";

import React, { Component } from "react";

import { Nav, NavItem } from 'react-bootstrap';
import PackageService from './PackageService';
import PackageVoucher from './PackageVoucher';
import PackageCategory from "./PackageCategory";

export default class AddPackage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeNavItem: "Serviços",
      areas: props.areas,
      selectedAreas: [],
      pack: {},
      prepack: {},
      packageStructure: {
        title: "",
        subtitle: "",
        totalAmount: 0,
        discountAmount: 0,
        discountInPercent: 0,
        expiresAfterDays: 0,
        mobileImage: "",
        originalAmount: 0,
        serviceDescription: "",
        installmentMaxLimit: 1,
        services: [
          {
            count: 0,
            serviceList: [],
            title: "",
            value: 0
          }
        ]
      }
    };
  }


  savePackage = (evt) => {
    if (this.state.pack.serviceDescription.length > 255) {
      alert("Descrição deve ter no máximo 255 caracteres.");
      return;
    }
    evt.preventDefault();

    this.props
      .addPackage(this.state.selectedAreas, this.state.pack)
      .then(result => {
        alert("Pacote adicionado com sucesso.");
        this.props.handleClose();
        window.location.reload();
      })
      .catch(err => {
        alert("Não foi possível adicionar o pacote.");
        console.log(err);
      });
  }

  onUpdatePack = (pack) => {
    this.setState({ pack })
  }

  handleFormEdit(evt) {
    const { value, name } = evt.target;
    const { pack } = this.state;
    pack[name] = value;
    this.setState({ pack });
  }

  handleSelectArea(evt) {
    const { selectedAreas } = this.state;
    if (evt.target.checked === true) {
      selectedAreas.push(evt.target.value);
    } else {
      selectedAreas.pop(evt.target.value);
    }
    this.setState({ selectedAreas });
  }

  renderId() {
    if (this.state.package && this.state.package._id) {
      return (
        <input type={"hidden"} name={"_id"} value={this.state.package._id} />
      );
    }
  }

  handleNavItemSelection = (key) => {
    this.setState({ activeNavItem: String(key) })
  }

  renderAreas() {
    const { areas } = this.state;
    return (
      <div className="col-xs-12 form-group">
        <label htmlFor="">Selecione uma Area</label>
        <div className="col-xs-12">
          {areas.map(area => {
            return (
              <label className="checkbox-inline">
                <input
                  type="checkbox"
                  onChange={this.handleSelectArea.bind(this)}
                  value={area._id}
                />{" "}
                {area.name["pt-BR"]}
              </label>
            );
          })}
        </div>
      </div>
    )
  }

  render() {
    const { prepack, pack, areas } = this.state;
    return (
      <div className="row">
        <form onSubmit={this.savePackage}>
          {this.renderId()}
          <div className="col-xs-6 form-group">
            <label>Título</label>
            <input
              className="form-control form-control-normal"
              name={"title"}
              tabIndex={1}
              required
              value={this.state.pack.title}
              onChange={this.handleFormEdit.bind(this)}
            />
          </div>
          <div className="col-xs-6 form-group">
            <label>Subtitulo</label>
            <input
              className="form-control form-control-normal"
              name={"subtitle"}
              tabIndex={2}
              required
              value={this.state.pack.subtitle}
              onChange={this.handleFormEdit.bind(this)}
            />
          </div>
          <div className="col-xs-12 form-group">
            <label>Url da Imagem</label>
            <input
              className="form-control form-control-normal"
              name={"mobileImage"}
              tabIndex={3}
              required
              value={this.state.pack.mobileImage}
              onChange={this.handleFormEdit.bind(this)}
            />
          </div>
          <div className="col-xs-12 form-group">
            <label>Descrição do Pacote</label>
            <input
              className="form-control form-control-normal"
              name={"serviceDescription"}
              tabIndex={3}
              max={255}
              required
              value={this.state.pack.serviceDescription}
              onChange={this.handleFormEdit.bind(this)}
            />
          </div>
          <div className="col-xs-4 form-group">
            <label>Dias para expirar</label>
            <input
              type="number"
              className="form-control form-control-normal"
              name={"expiresAfterDays"}
              tabIndex={4}
              required
              value={this.state.pack.expiresAfterDays}
              onChange={this.handleFormEdit.bind(this)}
            />
          </div>
          {this.renderAreas()}
          <div className="clearfix" />

          <hr />
          <div>
            <Nav bsStyle="tabs" activeKey={this.state.activeNavItem} onSelect={k => this.handleNavItemSelection(k)}>
              <NavItem eventKey="Serviços" href="/home">
                Serviços
            </NavItem>
              <NavItem eventKey="Voucher" href="/home">
                Voucher
            </NavItem>
            <NavItem eventKey="Categorias" href="/home">
              Categorias
            </NavItem>
            </Nav>
            <div className="row">
              {this.state.activeNavItem === "Voucher" &&
                <PackageVoucher pack={pack} />
              }
              {this.state.activeNavItem === "Serviços" &&
                <PackageService pack={pack} prepack={prepack} area={areas} />
              } 
              {this.state.activeNavItem === 'Categorias' && <PackageCategory pack={pack} areas={areas} onUpdatePack={this.onUpdatePack}/>} 
            </div>
          </div>

          <div className="text-right">
            <button
              type={"button"}
              className="btn btn-pack btn-default"
              onClick={this.props.handleClose}
            >
              Fechar
            </button>
            <button className="btn btn-pack btn-success" type="submit">
              Salvar
            </button>
          </div>
        </form>
      </div>
    );
  }
}
