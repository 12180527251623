import axios from "axios";
import { Map, GoogleApiWrapper } from "google-maps-react";
import React, { Component, PropTypes } from "react";
import { Polygon } from "google-maps-react/dist/components/Polygon";
import { connect } from "react-redux";
import Swal from "sweetalert2";

import { getToken } from "../../models/auth";

//Actions
import { fetchPolygonsByAreaId } from "../../actions/dynamic_prices/fetch_polygons_by_area";
import { fetchAreas } from "../../actions/areas/fetch_areas";

class PolygonMap extends Component {
  constructor(props) {
    super(props);
    this.style = {
      width: "94%",
      margin: "0",
      minHeight: "500px",
    };
    this.textAreaToCopyToClipboard = "";
    this.state = {
      color: "#ffff75",
      loadingPoligons: false,
      serviceId: "",
      areaId: "",
      fetchPolygons: false,
      polygons: [],
      arr: [],
      err: false,
      polygonConfig: {},
      areas: [],
      id: "",
      categories: [],
      services: [],
      selectedPolygons: [],
      selectedServices: [],
      monday: { monday: 0 },
      tuesday: { tuesday: 0 },
      wednesday: { wednesday: 0 },
      thursday: { thursday: 0 },
      friday: { friday: 0 },
      saturday: { saturday: 0 },
      sunday: { sunday: 0 },
    };
  }

  componentWillMount() {
    this.props.fetchAreas().then((action) => {
      const { data } = action.payload;
      this.setState({ areas: data });
    });
  }

  loadPolygons = () => {
    this.setState({
      loadingPoligons: true,
      fetchPolygons: false,
      polygons: [],
      polygonConfig: {},
      err: false,
    });

    return this.props
      .fetchPolygonsByAreaId(this.state.areaId)
      .then((data) => {
        this.setState({
          fetchedPolygons: true,
        });
        this.fetchPolygons(data.payload.data);
      })
      .catch((err) => {
        console.log(err);
        this.setState({ loadingPoligons: false });

        if (!this.state.areaId) {
          Swal.fire({
            icon: "info",
            text: "Por favor, selecione uma cidade",
          });
        } else {
          Swal.fire({
            icon: "error",
            title: "Ops...",
            text: "Houve um erro ao buscar os polígonos.\nPor favor, tente novamente.",
          });
        }
      });
  };

  changeServiceId = (e) => {
    this.setState({ serviceId: e.target.value });
  };

  changeAreaId = (e) => {
    this.setState({ areaId: e.target.value });
  };

  updatePolygon = () => {
    const { polygonRanges, _id } = this.state.polygonConfig;

    this.props
      .updatePolygon({ _id, rules: polygonRanges })
      .then((responseData) => {
        Swal.fire({
          icon: "success",
          text: "Preço dinamico alterado com sucesso.",
        });
      })
      .catch((err) => {
        alert(
          err.data && err.data.error && err.data.error.description
            ? err.data.error.description
            : "Não foi possível alterar o polígono. Por favor, contate a equipe de suporte."
        );
        console.log(err);
      });
  };

  fetchPolygons = (serverPolygons) => {
    if (!this.state.fetchedPolygons) {
      return;
    }
    this.setState({ loadingPoligons: false });
    const polygons = serverPolygons.map((item) => {
      let polygonPoints;
      if (item.location.coordinates) {
        polygonPoints = item.location.coordinates[0].map((polygonLatLng) => {
          return {
            lat: Number(polygonLatLng[1]),
            lng: Number(polygonLatLng[0]),
          };
        });
      }
      if (item.location.geometries) {
        const geometriesArray = item.location.geometries.map(
          (allGeometries) => {
            return allGeometries.coordinates[0].map((polygonLatLng) => {
              return {
                lat: Number(polygonLatLng[1]),
                lng: Number(polygonLatLng[0]),
              };
            });
          }
        );
        polygonPoints = geometriesArray.flat();
      }

      const { color } = this.state;

      return (
        <Polygon
          coordinates={JSON.stringify(item.location)}
          rule={item.rule}
          paths={polygonPoints}
          name={item.name}
          _id={item._id}
          serviceId={item.serviceId}
          strokeColor={"#000000"}
          strokeOpacity={0.8}
          strokeWeight={1}
          fillColor={color}
          fillOpacity={0.55}
          onClick={this.selectPolygon}
        />
      );
    });
    this.setState({ polygons });
  };

  selectPolygon = (_props, polygon) => {
    const { areas, areaId } = this.state;
    let selectedPolygons = this.state.selectedPolygons;
    let arr = this.state.arr;
    let categoriesData = [];
    let servicesData = [];

    if (arr.find((element) => element === polygon.name)) {
      arr.splice(polygon.name, 1);
      polygon.setOptions({ fillColor: "" });
      this.setState({ arr });
    } else {
      /**
       * Adiciona os serviços e as categorias da região selecionada
       * no state categories e services
       */
      categoriesData = areas.find((area) => area._id === areaId);
      categoriesData.categories.map((c) => {
        c.services.map((s) => {
          s._id.substring(0, 4) === "c106"
            ? Object.assign(s, { id: "c104m" })
            : null;

          Object.assign(s, { checked: false });
          servicesData.push(s);
        });
      });
      this.setState({ categories: categoriesData, services: servicesData });

      /**
       * adiciona o poligono selecionado e muda a cor da area.
       */
      polygon.setOptions({ fillColor: "#00FA9A" });
      arr.push(polygon.name);
      this.setState({ arr });

      /**
       * Adiciona a id do poligono selecionado no state para enviar
       * para o end point
       */
      selectedPolygons.push(polygon._id);
      this.setState({ selectedPolygons });
    }
  };

  renderPolygonMap() {
    if (this.state.loadingPoligons) {
      return <p>Carregando Polígonos do Serviço...</p>;
    }
    let polygonInfo = "";
    if (Object.keys(this.state.polygonConfig).length > 0) {
      polygonInfo = (
        <div>
          <h4>{this.state.polygonConfig.name}</h4>
          <p>ID: {this.state.polygonConfig._id}</p>
          <p>Id do Serviço: {this.state.polygonConfig.serviceId}</p>
          {this.renderDynamicPriceRange()}
        </div>
      );
    }
    const mapInit = {
      "56b108332a3c15e79c87bf4f": {
        lat: -22.938015,
        lng: -43.209913,
      },
      "5f5817f40a24df8509a03843": {
        lat: -19.8955594,
        lng: -44.0298069,
      },
      "56b106cb2a3c15e79c87bf4e": {
        lat: -23.5276,
        lng: -46.6473,
      },
      "5f5817110a24df8509a03842": {
        lat: -22.9216427,
        lng: -43.1852334,
      },
      "5efe32b9882c13205fdc3773": {
        lat: -15.8188,
        lng: -47.962,
      },
    };
    return (
      <div>
        <h4 className="title" style={{ marginBottom: "10px" }}>
          Polígonos Disponíveis
        </h4>

        <Map
          google={this.props.google}
          style={this.style}
          initialCenter={
            mapInit[this.state.areaId || "56b106cb2a3c15e79c87bf4e"]
          }
          zoom={11}
        >
          {this.state.polygons}
        </Map>
        <div className="col-xs-12 col-sm-12 col-md-12">
          {polygonInfo}
          <div className="col-xs-12 col-sm-12 col-md-12"></div>
        </div>
      </div>
    );
  }

  checkAll = (e) => {
    let name = e.target.name;
    let selectedServices = this.state.selectedServices;
    this.setState((state) => {
      const service = state.services.map((s) => {
        if (s._id.substring(0, 4) === name) {
          if (s.checked) {
            Object.assign(s, { checked: false });
            selectedServices.splice(selectedServices.indexOf(s._id), 1);
          } else {
            Object.assign(s, { checked: true });
            selectedServices.push(s._id);
          }
        } else if (s.id && s.id === name) {
          if (s.checked) {
            Object.assign(s, { checked: false });
            selectedServices.splice(selectedServices.indexOf(s._id), 1);
          } else {
            Object.assign(s, { checked: true });
            selectedServices.push(s._id);
          }
        }
      });
      return service;
    });
  };

  handleCheck = (e) => {
    let name = e.target.name;
    let selectedServices = this.state.selectedServices;

    this.setState((state) => {
      const service = state.services.map((s) => {
        if (s._id === name) {
          if (s.checked) {
            selectedServices.splice(selectedServices.indexOf(s._id), 1);
            Object.assign(s, { checked: false });
          } else {
            Object.assign(s, { checked: true });
            selectedServices.push(s._id);
          }
        }
      });
      this.setState({ selectedServices });
      return service;
    });
  };

  handlerChangePrice = (e) => {
    e.preventDefault();

    const params = {
      _id: this.state.selectedPolygons,
      services: this.state.selectedServices,
      dynamicPrices: {
        ...this.state.monday,
        ...this.state.tuesday,
        ...this.state.wednesday,
        ...this.state.thursday,
        ...this.state.friday,
        ...this.state.saturday,
        ...this.state.sunday,
      },
    };

    Swal.fire({
      icon: "info",
      text: "Essa alteração afetará o valor de todos os dias da semana. Deseja continuar?",
      showCancelButton: true,
      confirmButtonText: "Continuar",
    }).then((result) => {
      if (result.isConfirmed) {
        axios.put(`${BACKEND_URL}/dynamic_prices_per_area`, params, {
          headers: {
            Authorization: `Bearer ${getToken()}`,
          },
        }).then((res) => {
          Swal.fire({
            icon: "success",
            text: "Preço dinâmico alterado com sucesso.",
          });
        }).catch((err) => {
          alert(
            err.data && err.data.error && err.data.error.description
              ? err.data.error.description
              : "Não foi possível alterar os valores. Por favor, contate a equipe de suporte."
          );
          console.log(err);
        });
      }
    });
  };

  handleChange = (e) => {
    e.target.name === "SEG"
      ? this.setState({ monday: { monday: e.target.value } })
      : null;
    e.target.name === "TER"
      ? this.setState({ tuesday: { tuesday: e.target.value } })
      : null;
    e.target.name === "QUA"
      ? this.setState({ wednesday: { wednesday: e.target.value } })
      : null;
    e.target.name === "QUI"
      ? this.setState({ thursday: { thursday: e.target.value } })
      : null;
    e.target.name === "SEX"
      ? this.setState({ friday: { friday: e.target.value } })
      : null;
    e.target.name === "SAB"
      ? this.setState({ saturday: { saturday: e.target.value } })
      : null;
    e.target.name === "DOM"
      ? this.setState({ sunday: { sunday: e.target.value } })
      : null;
  };

  render() {
    const weekend = ["SEG", "TER", "QUA", "QUI", "SEX", "SAB", "DOM"];
    const { areas, services, categories } = this.state;

    if (this.state.loading) loadingContainer();
    return (
      <div className={"col-xs-12"}>
        <h1>Preço Dinâmico - Agendado</h1>

        <div className="row">
          <div className="col-xs-4">
            <h4>Selecione a área</h4>
            <select
              style={{ width: "100%" }}
              className="form-control"
              name=""
              id=""
              onChange={this.changeAreaId}
            >
              <option value="">Selecione a cidade</option>
              {areas.map((region) => {
                return (
                  <option value={region._id} key={region._id}>
                    {region.city}
                  </option>
                );
              })}
            </select>
          </div>
          <div className="col-xs-3">
            <h4>&nbsp;</h4>
            <button
              className="btn btn-default btn-success"
              onClick={this.loadPolygons}
            >
              Buscar
            </button>
          </div>
        </div>

        <div className="row" style={{ marginTop: "30px" }}>
          <div className="col-xs-6">{this.renderPolygonMap()}</div>

          <div className="col-xs-3">
            <h4 className="title">Selecione o serviço</h4>
            <ul
              style={{
                marginTop: "10px",
                paddingLeft: 0,
                listStyle: "none",
                height: "65vh",
                overflowY: categories._id ? "scroll" : "hidden",
              }}
            >
              {categories._id ? (
                categories.categories.map((c) => {
                  return (
                    <div>
                      <li key={c._id}>
                        <label>
                          <input
                            type="checkbox"
                            onChange={this.checkAll}
                            name={c._id}
                          />
                          <span style={{ marginLeft: "5px" }}>
                            <strong>{c.name["pt-BR"]}</strong>
                          </span>
                        </label>

                        <ul style={{ listStyle: "none" }}>
                          {services.map((s) => {
                            if (s._id.substring(0, 4) === c._id) {
                              return (
                                <li key={s._id}>
                                  <label>
                                    <input
                                      type="checkbox"
                                      onChange={this.handleCheck}
                                      name={s._id}
                                      checked={s.checked}
                                    />
                                    <span style={{ marginLeft: "5px" }}>
                                      {s.name["pt-BR"]}
                                    </span>
                                  </label>
                                </li>
                              );
                            } else if (s.id === c._id) {
                              return (
                                <li key={s._id}>
                                  <label>
                                    <input
                                      type="checkbox"
                                      onChange={this.handleCheck}
                                      name={s._id}
                                      checked={s.checked}
                                    />
                                    <span style={{ marginLeft: "5px" }}>
                                      {s.name["pt-BR"]}
                                    </span>
                                  </label>
                                </li>
                              );
                            }
                          })}
                        </ul>
                      </li>
                    </div>
                  );
                })
              ) : (
                <div>
                  <h4 className="title">Selecione uma região no mapa.</h4>
                </div>
              )}
            </ul>
          </div>

          <div className="col-xs-3">
            <form onSubmit={this.handlerChangePrice}>
              <h4 className="title">Alteração de preço dinâmico</h4>
              {weekend.map((w) => (
                <div className="col-xs-12" style={{ margin: "5px auto" }}>
                  <span
                    style={{ textAlign: "center", padding: "6px 0 0 0" }}
                    className="col-xs-2"
                  >
                    {w}
                  </span>
                  <div className="col-xs-9">
                    <div className="input-group">
                      <input
                        style={{ zIndex: 0 }}
                        className="form-control"
                        type="number"
                        min={0}
                        step=".00000001"
                        onChange={this.handleChange}
                        name={w}
                      />
                      <span className="input-group-addon">%</span>
                    </div>
                  </div>
                </div>
              ))}

              <input
                style={{
                  marginTop: "20",
                  marginBottom: "50px",
                  marginLeft: "271px",
                  marginRight: "85px",
                }}
                className="btn btn-default btn-success"
                type="submit"
                value="Alterar"
              />
            </form>
          </div>
        </div>
      </div>
    );
  }
}

const loadingContainer = (props) => (
  <div>
    <h4> Carregando o Mapa. </h4>
  </div>
);

PolygonMap.propTypes = {
  polygon: PropTypes.array,
};

PolygonMap.contextTypes = {
  router: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  return {
    dynamicPrices: state.dynamicPrices,
    polygons: state.all,
  };
}

export default connect(mapStateToProps, {
  fetchAreas,
  fetchPolygonsByAreaId,
})(
  GoogleApiWrapper({
    apiKey: "AIzaSyBT3vNYVkejeaHoro4DikXez6z7GACBBak",
  })(PolygonMap)
);
