import axios from 'axios';
import config from '../../config.json';
const env = process?.env?.REACT_APP_ENVIRONMENT ?? 'stage';
const BACKEND_URL = config.api[env];
const ARTIST_API_URL = config.artistApi[env];
const NODE_API_URL = config.nodeAPI[env];
const ALLOCATION_API_URL = config.allocationApi[env];

import { getToken } from "../models/auth";

axios.defaults.timeout = 30000;
export const backendAPI = axios.create({
  baseURL: BACKEND_URL,
  headers: { "Authorization": `Bearer ${getToken()}` }
});

export const allocationAPI = axios.create({
  baseURL: ALLOCATION_API_URL,
  headers: { "Authorization": `Bearer ${getToken()}` }
});

export const artistAPI = axios.create({
  baseURL: ARTIST_API_URL,
  headers: { "Authorization": `Bearer ${getToken()}` }
});

export const nodeAPI = axios.create({
  baseURL: NODE_API_URL,
  headers: { "Authorization": `Bearer ${getToken()}` }
});