import React, { Component } from 'react';
import { Modal, Label, Button } from 'react-bootstrap';
import Swal from "sweetalert2";
import moment from 'moment-timezone';

import { fetchPaymentStatus } from '../../../actions/package_purchases/fetch_payment_status';
import { fetchVoucherStatus } from '../../../actions/package_purchases/fetch_voucher_status';

const normalizePayment = {
  'CREATED': { text: 'CRIADO', variant: 'default' },
  'WAITING': { text: 'AGUARDANDO PAGAMENTO', variant: 'warning' },
  'ANALYSIS': { text: 'EM ANÁLISE', variant: 'warning' },
  'AUTHORIZED': { text: 'AUTORIZADO', variant: 'success' },
  'PRE_AUTHORIZED': { text: 'PRÉ AUTORIZADO', variant: 'warning' },
  'CANCELLED': { text: 'FALHOU', variant: 'danger' },
  'CANCELED': { text: 'FALHOU', variant: 'danger' },
  'DECLINED': { text: 'FALHOU', variant: 'danger' },
  'REFUNDED': { text: 'REEMBOLSADO', variant: 'info' },
  'REVERSED': { text: 'REVERTIDO', variant: 'info' },
  'SETTLED': { text: 'AUTORIZADO', variant: 'success' },
  'PAID': { text: 'AUTORIZADO', variant: 'success' },
}

export default class PackagePurchaseDetailsModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      vouchers: [],
      loadedVouchers: false
    }
  }

  normalizePaymentStatus = (payment) => {
    console.log(payment)
    if (!payment || !payment.status) return {
      text: 'FALHA AO CRIAR PEDIDO',
      variant: 'warning',
    }
    return normalizePayment[payment.status];
  }

  reprocessPackagePayment = async () => {
    try {
      const { isConfirmed } = await Swal.fire({
        title: 'Voce tem certeza?',
        text: 'Reprocessar o pagamento enviará um email com os vouchers para o cliente.',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Sim, reprocessar!'
      });

      if (!isConfirmed) return;

      Swal.fire({ icon: "success", title: "Sucesso", text: "Pagamento reprocessado com sucesso!" });
    } catch (err) {
      console.error('Reprocess payment', err);
      Swal.fire({ icon: "error", title: "Oops...", text: "Não foi possível reprocessar a compra de pacote." });
    }
  }

  reprocessPackagePayment = async () => {
    try {
      const { data: packagePurchase } = this.props;

      const { isConfirmed } = await Swal.fire({
        title: 'Voce tem certeza?',
        text: 'Reprocessar o pagamento enviará um email com os vouchers para o cliente.',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Sim, reprocessar!'
      });

      if (!isConfirmed) return;

      const { data } = await fetchPaymentStatus(packagePurchase._id);

      Swal.fire({ icon: "success", title: "Sucesso", text: data.description || "Pagamento reprocessado com sucesso!" });
    } catch (err) {
      console.error('Reprocess payment', err);
      Swal.fire({ icon: "error", title: "Oops...", text: "Não foi possível reprocessar a compra de pacote." });
    }
  }

  getVoucherStatus = async () => {
    try {
      this.setState({ loadedVouchers: false });
      const { data: packagePurchase } = this.props;

      if (Object.keys(packagePurchase).length === 0) return;

      const { data: vouchers } = await fetchVoucherStatus(packagePurchase._id);

      this.setState({ vouchers, loadedVouchers: true });
    } catch (err) {
      console.error('Package Voucher Status', err);
      Swal.fire({ icon: "error", title: "Oops...", text: "Não foi possível carregar o status dos vouchers." });
    }
  }

  renderVouchers = () => {
    const { loadedVouchers, vouchers } = this.state;

    if (!loadedVouchers) return;

    return (
      <div style={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}>
        {
          !vouchers || vouchers.length === 0 ?
            <Label>Nenhum voucher encontrado</Label> :
            (
              vouchers.map(voucher => {
                const { end, code, usedTimes, aquisition, recurrence } = voucher;

                let isUsed = false;

                if (aquisition?.usedTimes > 0) isUsed = true
                else if (recurrence?.usedTimes > 0) isUsed = true

                const voucherStatus = (() => {
                  if (moment(end).isBefore(new Date())) {
                    return {
                      variant: 'danger',
                      text: 'Expirado'
                    }
                  }

                  if (isUsed) {
                    return {
                      variant: 'warning',
                      text: 'Utilizado'
                    }
                  }

                  return {
                    variant: 'success',
                    text: 'Disponível'
                  }
                })();

                return (
                  <div
                    key={voucher._id}
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      background: '#eaeaea',
                      padding: '0.25rem 0.5rem',
                      borderRadius: '5px',
                      marginRight: '5px',
                      marginBottom: '5px',
                    }}>
                    <h5 style={{ margin: 0, fontWeight: 'bold' }}>Código</h5>
                    <p style={{ marginBottom: '5px' }}>{code}</p>
                    <h5 style={{ margin: 0, fontWeight: 'bold' }}>Expiração</h5>
                    <p style={{ marginBottom: '5px' }}>{moment.tz(end, 'America/Sao_Paulo').format('DD/MM/YY HH:mm')}</p>
                    <h5 style={{ margin: 0, fontWeight: 'bold' }}>Utilizações</h5>
                    <p style={{ marginBottom: '5px' }}>{usedTimes === 0 ? 'Nenhuma' : `${usedTimes} ${usedTimes === 1 ? 'vez' : 'vezes'} `}</p>
                    <Label bsStyle={voucherStatus.variant}>{voucherStatus.text}</Label>
                  </div>
                )
              })
            )}
      </div>
    )
  }

  _onHide = () => {
    this.setState({ vouchers: [], loadedVouchers: false });
    this.props.onHide();
  }

  render() {
    const { show, data: packagePurchase } = this.props;
    const { loadedVouchers } = this.state;

    const { pack, user, payment, createdTime } = packagePurchase;

    if (!show) return null;

    const paymentStatus = this.normalizePaymentStatus(payment);

    return (
      <Modal bsSize="large" show={show} onHide={this._onHide}>
        <Modal.Header closeButton>
          <Modal.Title>Detalhes da Compra de Pacote</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h3 style={{ margin: 0, fontWeight: 'bold' }}>{user.name}</h3>
          <h4 style={{ margin: 0, fontWeight: 'bold' }}>{user.email}</h4>
          <h4 style={{ margin: '10px 0 5px 0', fontWeight: 'bold' }}>{pack.title['pt-BR']} - {pack.subtitle['pt-BR']}</h4>
          <div>
            <h4 style={{ margin: 0 }}>
              <Label bsStyle="success">R$ {pack.totalAmount.toFixed(2)}</Label>
              <Label bsStyle="primary" style={{ marginLeft: '5px' }}>Compra {moment.tz(createdTime, 'America/Sao_Paulo').format('DD/MM/YY HH:mm')}</Label>
            </h4>
          </div>
          <h4 style={{ margin: '10px 0 5px 0', fontWeight: 'bold' }}>Serviços</h4>
          <div>
            {pack.services && pack.services.map((service, index) => (
              <Label key={`service_${index}`} bsStyle="default" style={{ marginRight: '5px', fontSize: '14px' }}>
                {service.title['pt-BR']}
              </ Label>
            ))}
          </div>
          <h4 style={{ margin: '10px 0 5px 0', fontWeight: 'bold' }}>Pagamento</h4>
          <div>
            <Label bsStyle={paymentStatus?.variant}>{paymentStatus?.text}</Label>
            <Button
              bsStyle="info"
              bsSize="small"
              onClick={this.reprocessPackagePayment}
              style={{ display: 'block', marginTop: '5px' }}>
              Reprocessar pagamento
              <span className="glyphicon glyphicon-refresh" style={{ marginLeft: '5px' }}></span>
            </Button>
          </div>
          <h4 style={{ margin: '10px 0 5px 0', fontWeight: 'bold' }}>Vouchers</h4>
          <div>
            {!loadedVouchers && (
              <Button
                bsStyle="info"
                bsSize="small"
                onClick={this.getVoucherStatus}
                style={{ display: 'block', marginTop: '5px' }}>
                Carregar vouchers
                <span className="glyphicon glyphicon-tag" style={{ marginLeft: '5px' }}></span>
              </Button>
            )}
            {this.renderVouchers()}
          </div>
        </Modal.Body>
      </Modal>
    )
  }
}