import React, { Component } from "react";
import { Label } from 'react-bootstrap';

const normalizePayment = {
  'CREATED': { text: 'CRIADO', variant: 'default' },
  'WAITING': { text: 'AGUARDANDO PAGAMENTO', variant: 'warning' },
  'ANALYSIS': { text: 'EM ANÁLISE', variant: 'warning' },
  'AUTHORIZED': { text: 'AUTORIZADO', variant: 'success' },
  'PRE_AUTHORIZED': { text: 'PRÉ AUTORIZADO', variant: 'warning' },
  'CANCELLED': { text: 'FALHOU', variant: 'danger' },
  'CANCELED': { text: 'FALHOU', variant: 'danger' },
  'DECLINED': { text: 'FALHOU', variant: 'danger' },
  'REFUNDED': { text: 'REEMBOLSADO', variant: 'info' },
  'REVERSED': { text: 'REVERTIDO', variant: 'info' },
  'SETTLED': { text: 'AUTORIZADO', variant: 'success' },
  'PAID': { text: 'AUTORIZADO', variant: 'success' },
}

export default class Payment extends Component {
  normalizePaymentStatus = (payment) => {
    console.log
    if (!payment || !payment.status || !normalizePayment[payment.status]) return {
      text: 'FALHA AO CRIAR PEDIDO',
      variant: 'warning',
    }

    return normalizePayment[payment.status];
  }

  render() {
    const { data: paymentStatus } = this.props;

    const { text, variant } = this.normalizePaymentStatus(paymentStatus);

    return (
      <h4>
        <Label bsStyle={variant}>
          {text}
        </Label>
      </h4>
    )
  }
}
