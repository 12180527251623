import _ from "lodash";
import React, { Component } from "react";
import { fetchVouchers } from "../../actions/vouchers/fetch_vouchers";
import ListVoucherComponent from "../../components/Vouchers/Voucher";

export default class ListVouchers extends Component {
  constructor() {
    super();
    this.state = {
      status: "READY",
      vouchers: [],
      page: 1,
      code: '',
      showInactive: false,
      showOthers: false,
    }
  }

  componentWillMount() {
    this.fetchVoucher();
  }


  componentWillReceiveProps(newProps) {
    const { vouchers } = newProps;

    if (vouchers) {
      this.setState({ vouchers });
    }
  }

  search = (code, showInactive, showOthers) => {
    this.setState({ code, showInactive, showOthers }, this.fetchVoucher);
  }

  clearSearch = () => {
    this.setState({ code: "", showInactive: "", showOthers: "" }, this.fetchVoucher);
  }

  fetchVoucher = () => {
    this.setState({ status: "LOADING" })
    const { page, code, showInactive, showOthers } = this.state;
    fetchVouchers(code, showInactive, showOthers, page)
      .then(({ data }) => {
        this.setState({ vouchers: data.vouchers, totalItems: data.totalItems });
      }).finally(() => {
        this.setState({ status: "READY" })
      })
  }

  setPage = (page) => {
    console.log(page)
    this.setState({ page: page + 1 }, () => this.fetchVoucher())
  }

  render() {
    const { vouchers } = this.state;
    const props = {
      vouchers,
      status: this.state.status,
      totalItems: this.state.totalItems,
      page: this.state.page
    }
    return (
      <ListVoucherComponent
        {...props}
        setPage={this.setPage}
        handlerSearch={this.search}
      />
    )
  }
}