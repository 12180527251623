import config from '../../../config.json';
const env = process.env.REACT_APP_ENVIRONMENT || 'stage';
const BACKEND_URL = config.api[env];
import axios from "../../helper/axios";
import { getToken } from '../../models/auth';

export function saveArea(areaId, data) {
  if (!areaId) return console.error('Area ID is missing');

  return axios.put(`${BACKEND_URL}/area/${areaId}`, {
    params: data
  }, {
    headers: { Authorization: `Bearer ${getToken()}` }
  });
}

export function saveAreaDetails(areaId, data) {
  if (!areaId) return console.error('Area ID is missing');

  return axios.put(`${BACKEND_URL}/area/${areaId}/details`, data, {
    headers: { Authorization: `Bearer ${getToken()}` }
  })
}

export function saveAreaPGP(areaId, data) {
  if (!areaId) return console.error('Area ID is missing');

  return axios.put(`${BACKEND_URL}/area/${areaId}/pgp`, data, {
    headers: { Authorization: `Bearer ${getToken()}` }
  })
}

export function saveAreaPGM(areaId, data) {
  if (!areaId) return console.error('Area ID is missing');

  return axios.put(`${BACKEND_URL}/area/${areaId}/pgm`, data, {
    headers: { Authorization: `Bearer ${getToken()}` }
  })
}

export function saveAreaCategoryService(areaId, serviceId, data) {
  if (!areaId) return console.error('Area ID is missing');

  if (!serviceId) return console.error('Service ID is missing');

  return axios.put(`${BACKEND_URL}/area/${areaId}/service/${serviceId}`, data, {
    headers: { Authorization: `Bearer ${getToken()}` }
  })
}