/* global _ */

import Griddle from "griddle-react";
import React, { Component, PropTypes } from "react";
import { connect } from "react-redux";

import LeadsMetadata from "./Leads.metadata";
import formatter from "./../../helper/formatters";
import LeadDetails from "./../../components/Leads/LeadDetails/LeadDetails";
import { Button, Modal } from "react-bootstrap";
import Loading from "./../../components/Global/Loading/Loading";
import PageHeader from "./../../components/Global/PageHeader";

// actions
import { fetchLeads } from "./../../actions/leads/fetch_leads";
import { updateLead } from "./../../actions/leads/update_lead";
import { setCurrentLead } from "./../../actions/leads/set_lead.js";

import { getLeadSchedulesAvailable } from "../../services/leads";

class Leads extends Component {
  constructor(props) {
    super(props);

    this.state = {
      leadsArrived: false,
      error: false,
      leads: [],
      lead: null,
      leadIndex: 0,
      isDetailsModalOpened: false,
      cpf: "",
      email: "",
      evaluationLocationList: []
    };
  }

  componentWillMount() {
    if (this.props.params && this.props.params._id) {
      this.searchByRouteParams('_id', this.props.params._id);
      this.getAllLeads("_id", this.props.params._id);
    } else {
      this.getAllLeads();
    }
  }

  componentWillReceiveProps(newProps) {
    const { leads } = newProps;

    if (leads) {
      this.mapLeads(leads);
    }
  }

  getAllLeads = (type, _id) => {
    const success = () => this.setState({ leadsArrived: true });
    const error = () => this.setState({ error: true, leadsArrived: true });

    let filter;

    if (type) {
      const { cpf, email } = this.state;

      if (!cpf && !email && !_id) {
        return;
      }

      this.setState({ leadsArrived: false });

      if (type === "cpf") filter = cpf;
      if (type === "email") filter = email;
      if (type === '_id') filter = _id;
    } else {
      type = "quantityPerPage";
      filter = 100;
    }

    this.props.fetchLeads({ type, filter }).then(success, error);
  }

  mapLeads(leads) {
    const self = this;
    const reduceServices = (acc, service) => {
      if (service.categoryName && !_.includes(acc, service.categoryName)) {
        return [...acc, service.categoryName];
      }

      return acc;
    };
    const mappedLeads = _.map(leads, (lead, i) => {
      const reducedServices = _.reduce(lead.services, reduceServices, []);

      return {
        index: i,
        id: lead._id,
        cpf: lead.cpf,
        name: lead.name,
        email: lead.email,
        phoneNumber: formatter.formatPhone(lead.phone),
        status: lead.status,
        services: reducedServices.length > 0 ? reducedServices.join(", ") : "Nenhum",
        actions: {
          openDetailsModal: self.openDetailsModal,
          openDetails: self.openDetails
        }
      };
    });

    this.setState({ leads: mappedLeads });
  }

  openDetails = (leadId) => {
    this.props.history.push(`leads/${leadId}`);
  }

  openDetailsModal = (index) => {
    this.setState({
      isDetailsModalOpened: true,
      leadIndex: index
    }, () => {
      const lead = this.props.leads[index];
      this.handleEvaluationLocationsList(lead);
    });
  }

  closeModal() {
    this.setState({ isDetailsModalOpened: false });
  }

  changeFilter(type, event) {
    const { state } = this;
    state[type] = event.target.value;
    this.setState(state);
  }

  searchByRouteParams(type, value) {
    const { state } = this;
    state[type] = value;
    this.setState(state);
  }

  handleEvaluationLocationsList({ evaluation = {} }) {
    const availableServiceParams = {};

    if (evaluation && evaluation.areaId) {
      Object.assign(availableServiceParams, { areaId: evaluation.areaId });
    }
    if (evaluation.type && evaluation.type.code) {
      Object.assign(availableServiceParams, { type: evaluation.type.code });
    }

    getLeadSchedulesAvailable(availableServiceParams).then(({ data }) => {
      const options = []
      if (data && data.length > 0) {
        for (let i = 0; i < data.length; i++) {
          const { location } = data[i];
          if (location) {
            options.push({
              text: location.formatted_address,
              value: data[i]._id.toString()
            });
          }
        }
        this.setState({ evaluationLocationList: options });
      }
    })
      .catch(error => console.error(error))
  }

  render() {
    const { leadsArrived, isDetailsModalOpened, leadIndex } = this.state;
    const lead = this.props.leads[leadIndex];

    if (!leadsArrived) {
      return (
        <div>
          <PageHeader title="Leads" />
          <Loading />
        </div>
      );
    }

    return (
      <div>
        <PageHeader title="Leads" />
        <div className="col-xs-12">
          <b style={{ marginLeft: 10, marginRight: 10 }}>Filtrar CPF:</b>{" "}
          <input
            value={this.state.cpf}
            onChange={this.changeFilter.bind(this, "cpf")}
            type="text"
            width="300px"
            className="form-control"
            placeholder="CPF"
          />
          <button className="btn btn-info" style={{ marginLeft: 10 }} onClick={this.getAllLeads.bind(this, "cpf")}>
            BUSCAR
          </button>
        </div>
        <div className="col-xs-12" style={{ marginTop: "10px" }}>
          <b style={{ marginLeft: 10, marginRight: 10 }}>Filtrar email:</b>{" "}
          <input
            value={this.state.email}
            onChange={this.changeFilter.bind(this, "email")}
            type="text"
            width="300px"
            className="form-control"
            placeholder="EMAIL"
          />
          <button className="btn btn-info" style={{ marginLeft: 10 }} onClick={this.getAllLeads.bind(this, "email")}>
            BUSCAR
          </button>
        </div>
        <div className="col-xs-12">
          <Griddle
            results={this.state.leads}
            useFixedLayout={false}
            useGriddleStyles={false}
            showSettings={false}
            tableClassName={"GriddleTable table"}
            settingsToggleClassName={"btn btn-default"}
            resultsPerPage={10}
            columnMetadata={LeadsMetadata.column}
            columns={["cpf", "name", "email", "phoneNumber", "status", "actions"]}
          />
        </div>
        <Modal className="Modal Modal--large" show={isDetailsModalOpened} onHide={() => this.closeModal()}>
          <Modal.Header closeButton>
            <Modal.Title className="Modal-title">Detalhes do Lead</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <LeadDetails
              lead={lead}
              leadIndex={leadIndex}
              getAllLeads={this.getAllLeads}
              updateCallback={this.props.updateLead}
              migrateCallback={this.props.setCurrentLead}
              router={this.context.router}
              evaluationLocationList={this.state.evaluationLocationList}
            />
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={() => this.closeModal()}>Fechar</Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

Leads.propTypes = {
  fetchLeads: PropTypes.func,
  updateLead: PropTypes.func,
  leads: PropTypes.array
};

Leads.contextTypes = {
  router: PropTypes.object.isRequired
};

function mapStateToProps(state) {
  return {
    leads: state.leads.all
  };
}

const mapActionsToProps = {
  fetchLeads,
  updateLead,
  setCurrentLead
};

export default connect(mapStateToProps, mapActionsToProps)(Leads);
