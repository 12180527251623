import config from '../../../config.json';
const env = process.env.REACT_APP_ENVIRONMENT || 'stage';
const BACKEND_URL = config.api[env];
import axios from 'axios';

import { getToken } from './../../models/auth';

export function reprocessTransfers(fourteenDays) {
    return axios.post(`${BACKEND_URL}/v2/artist/transfers/reprocess/${!!fourteenDays}`, {}, {
        headers: { Authorization: `Bearer ${getToken()}` }
    });
}
export function reprocessBonus() {
    return axios.post(`${BACKEND_URL}/v2/artist/transfers/bonus/reprocess`, {}, {
        headers: { Authorization: `Bearer ${getToken()}` }
    });
}
