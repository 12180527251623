import React, { Component } from 'react'
import { Button } from 'react-bootstrap';

class InvestimentButton extends Component {
    constructor(props){
        super(props)
    }

    render(){
        return (
            <Button
              onClick={this.props.onClick}
              className="ScheduleActions-btn btn btn-info"
              title="Editar Investimento"
            >
              <span className="glyphicon glyphicon-edit" />
            </Button>
        )
    }
}

export default InvestimentButton