import React, { Component, PropTypes } from 'react';
import ReactDOM from 'react-dom';

class Accordion extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpened: this.props.opened
    };
  }

  componentDidMount() {
    const { opened } = this.props;
    const content = ReactDOM.findDOMNode(this.refs.content);
    const openedHeight = content.offsetHeight;

    this.setState({
      openedHeight,
      height: opened ? openedHeight : 0
    });
  }

  toggleAccordion() {
    const { disabled, onClick } = this.props;
    const { isOpened, openedHeight } = this.state;
    const opened = !isOpened;

    if (!disabled) {
      if (onClick) {
        onClick();
      }
      this.setState({
        isOpened: opened,
        height: opened ? `${openedHeight}px` : '0px'
      });
    }
  }

  contentStyle() {
    return {
      padding: 0,
      height: this.state.height
    };
  }

  isOpenedClass() {
    const { isOpened } = this.state;

    return isOpened ? 'is-opened' : '';
  }

  render() {
    const { title, children, onClick } = this.props;

    return (
      <div className={`Form-accordion ${this.isOpenedClass()}`}>
        <div className="Form-accordionTitle"
              onClick={() => this.toggleAccordion()}>
          <span className="ss-plus ss-icon"></span>
          <span>{title}</span>
        </div>
        <div className="Form-accordionContent"
              style={this.contentStyle()}>
          <div ref="content">{children}</div>
        </div>
      </div>
    );
  }
}

Accordion.propTypes = {
  title: PropTypes.string,
  disabled: PropTypes.bool,
  opened: PropTypes.bool,
  onClick: PropTypes.any
};

export default Accordion;
