import React from "react"
import moment from "moment";
import InfractionDataActions from './InfractionDataActions'

export const columnData = [
  {
    columnName: "id_professional_infraction",
    displayName: "ID"
  },
  {
    columnName: "created_at",
    displayName: "Data da Criação",
    customComponent: props => {
      const momentDate = moment(props.rowData.created_at).format("D/MM/YYYY");
      return <p>{momentDate}</p>;
    }
  },
  {
    columnName: "occurrence_date",
    displayName: "Data da Ocorrência",
    customComponent: props => {
      const momentDate = moment(props.rowData.occurrence_date).format(
        "D/MM/YYYY"
      );
      return <p>{momentDate}</p>;
    }
  },
  {
    columnName: "level",
    displayName: "Nível"
  },
  {
    columnName: "points",
    displayName: "Pontos"
  },

  {
    columnName: "penalty",
    displayName: "Multa"
  },
  {
    columnName: "client_discount",
    displayName: "Desconto"
  },
  {
    columnName: "relieve_points",
    displayName: "Aliviar Pontos?",
    customComponent: props => (
      <p>{props.rowData.relieve_points ? "Sim" : "Não"}</p>
    )
  },
  {
    columnName: "relieve_penalty",
    displayName: "Aliviar Multa?",
    customComponent: props => (
      <p>{props.rowData.relieve_penalty ? "Sim" : "Não"}</p>
    )
  },
  {
    columnName: "relieve_client_discount",
    displayName: "Aliviar Desconto?",
    customComponent: props => (
      <p>{props.rowData.relieve_client_discount ? "Sim" : "Não"}</p>
    )
  },
  {
    columnName: "paid",
    displayName: "Paga?",
    customComponent: props => <p>{props.rowData.paid ? "Sim" : "Não"}</p>
  },
  {
    columnName: "singu_informed",
    displayName: "Singu Informada?",
    customComponent: props => <p>{props.rowData.singu_informed ? "Sim" : "Não"}</p>
  },
  {
    columnName: "actions",
    displayName: "Ações",
    customComponent: InfractionDataActions
  }
];

export const columnNames = [
  "id_professional_infraction",
  "created_at",
  "occurrence_date",
  "level",
  "points",
  "penalty",
  "client_discount",
  "relieve_points",
  "relieve_penalty",
  "relieve_client_discount",
  "paid",
  "singu_informed",
  "actions"
];
