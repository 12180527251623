import React, { Component, PropTypes } from 'react';
import moment from 'moment';

import EditableField from './../../EditableField/EditableField';
import handleRequest from './../../../helper/requests';
import { downloadBlobResponse } from '../../../helper/utils';
import { backendAPI } from '../../../helper/apis';
import { Toast, LoadingAlert } from '../../../helper/alerts';
import CommentComponent from './../Comments/addComment';
import NotificationSystem from "react-notification-system";
import BlockProfessionalComponent from '../../BlockProfessional/BlockProfessional';
import SchedulePixPayments from './SchedulePixPayments';


// styles
import './ScheduleDetails.css';
import { getScheduleByIdV3 } from '../../../services/schedules';
import AlertV2 from '../../_v2/Alert.component';

const dateFormat = 'DD/MM/YYYY HH:mm';

class ScheduleDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      blocking: {
        status: "READY"
      },
      pixCode: '',
      showAlert: false,
      alertAnimation: 'fade-in',
    }

  }

  isEditingAllowed() {
    return false;
  }

  onDataConfirmationClick() {
    const { rowData: { userId }, dataConfirmationAction, notify } = this.props;
    const actionCreator = () => dataConfirmationAction(userId, { emailPhoneConfirmed: false });

    return handleRequest(actionCreator, notify, 'Pedido de confirmação enviado com sucesso');
  }

  notify(message, level) {
    const title = level === "success" ? "Sucesso" : "Erro";
    this.refs.notificationSystem.addNotification({
      title,
      message,
      level,
      position: "tc"
    });
  }

  normalizeDayOfWeek(day) {
    if (typeof day !== 'number') return null;

    const weekDaysObject = {
      0: 'sunday',
      1: 'monday',
      2: 'tuesday',
      3: 'wednesday',
      4: 'thursday',
      5: 'friday',
      6: 'saturday',
    }

    return weekDaysObject[day] || null;
  }


  calculateDynamicPrice(services, date) {
    try {
      if (!date || !moment(date).isValid()) throw { message: 'Invalid date' };

      if (!services[0] || !services[0].dynamicPrice || !services[0].dynamicPrice.multiplierPerDayOfWeek) {
        throw { message: 'Invalid Dynamic Values' };
      }

      const value = services[0].dynamicPrice.multiplierPerDayOfWeek[this.normalizeDayOfWeek(moment(date).get('day'))];

      if (typeof value !== 'number') throw { message: 'Invalid value' };

      return `${value * 100}%`;
    } catch (err) {
      console.error(err);
      return 'Não calculado'
    }
  }

  handleDownloadConstestationPDF = async (scheduleId) => {
    try {
      LoadingAlert('Gerando PDF ...');
      const response = await backendAPI.get(`/schedule/${scheduleId}/chargeback-contestation`, {
        responseType: 'blob',
      });

      downloadBlobResponse(response, `pedido-${scheduleId}.pdf`);

      Toast().fire({
        icon: 'success',
        title: 'PDF gerado com sucesso!'
      });
    } catch (err) {

      let message = 'Erro ao gerar PDF';

      if (err.response && err.response.data && err.response.data.description) {
        message = err.response.data.description;
      }

      console.error(message, err);

      Toast().fire({
        icon: 'error',
        title: 'Ooops',
        text: 'Erro ao gerar PDF'
      });
    }
  }

  async fetchPixCode() {
    const { id } = this.props.rowData

    const findCodeInterval = setInterval(async () => {
      try {
        const schedule = await getScheduleByIdV3(id)
        const pixCode =
          schedule.data?.payment?.paymentMethod?.pixInfo?.copyPastText;

        const stripeCode = schedule.data?.payment?.urlPayment;

        if (pixCode || stripeCode) {
          this.setState({ pixCode: pixCode || stripeCode })
          clearInterval(findCodeInterval);
          return;
        }
      } catch (e) {
        console.error(e)
        clearInterval(findCodeInterval);
        return;
      }
    }, 2000);

  }

  handleCopyCodePix() {
    navigator.clipboard.writeText(this.state.pixCode);
    
    this.setState({
      showAlert: true,
      alertAnimation: 'fade-in',
    });

    setTimeout(() => {
      this.setState({ alertAnimation: 'fade-out' });
    }, 2000);

    setTimeout(() => {
      this.setState({ showAlert: false });
    }, 2500);
  }

  componentDidMount() {
    this.fetchPixCode()
  }

  render() {
    const {
      id,
      statusCode,
      infractionProfessionalDiscount,
      infractionProfessionalDiscountId,
      name,
      email,
      phone,
      address: {
        neighbor,
        place,
        streetNumber,
        complement,
        zipCode,
        uf,
        lat,
        lng
      },
      agent,
      statusHistory,
      artistEvents,
      dateHistory,
      date,
      services,
      takeRateHistory,
      artistsHistory,
      tz,
      rule,
      originalValue,
      totalValue,
      discount,
      voucherDiscount,
      singuCreditsUsed,
      infractionClientDiscount,
      preference,
      totalValuePreference,
      originalValuePreference,
      discountPreference,
      voucherDiscountPreference,
      singuCreditsUsedPreference,
      cancelValue,
      transfer,
      payment,
      alerts,
      artistValue,
      partnerSinguPayment,
      numberOfClients,
      professionalPreferences,
      comments,
      infractionHistory,
      partnerName,
      onlyForPreference,
      tipValue,
      tipPayment,
      note,
      takeRate,
      serviceValue,
      serviceValuePreference,
      pixPayments
    } = this.props.rowData;
    const { userId, partnerId } = this.props.rowData;

    const professionalTakeRate = `${100 - takeRate?.value}%`;

    const googleMapsURL = 'https://www.google.com/maps/dir';
    const addressOnMap = `${googleMapsURL}/${lat},${lng}`;

    const updateCallback = data => this.props.updateCallback(id, data);
    const notAvailable = 'Não informado';

    var displayClientAlerts = alerts.map(alert => {
      const alertDate = moment.tz(alert.date, tz);
      return (
        <div className="ScheduleDetails-history">
          <b>{alert.message}</b> <br /><br />
          <b>Data: </b>{alertDate.format(dateFormat)}
        </div>
      )
    });

    var displayStatusHistory = statusHistory.map(status => {
      const action = status.name || status.description || (status.code === 11 ? 'Serviço finalizado' : '');
      const eventDate = moment.tz(status.date, tz);
      const responsible = status.blame && status.blame.email ? status.blame.email : 'System';

      return (
        <div className="ScheduleDetails-history">
          <b>{action}</b> <br /><br />
          <b>Data: </b>{eventDate.format(dateFormat)}<br /><br />
          <b>Responsável: </b> {responsible}
        </div>
      )
    });

    var displayArtistHistory = artistsHistory.map(event => {
      const eventDate = moment.tz(event.date, tz);
      return (
        <div className="ScheduleDetails-history">
          <p><b>Ação: </b> {event.action}</p>
          <p><b>Artista: </b> {event.artist ? event.artist.name : ""}</p>
          <p><b>Quem realizou: </b> {event.blame ? event.blame.email : ""}</p>
          <p><b>Data: </b>{eventDate.format(dateFormat)}</p>
        </div>
      )
    });

    var displayArtistEvents = artistEvents.map(event => {
      const eventDate = moment.tz(event.date, tz);
      const hasLocation = event.location && event.location.lat && event.location.lng;
      const link = hasLocation ? `${googleMapsURL}/${event.location.lat},${event.location.lng}/${lat},${lng}/data=!3m1!4b1!4m2!4m1!3e3` : '';
      return (
        <div className="ScheduleDetails-history">
          <p><b>Evento: </b> {event.type}</p>
          <p><b>Data: </b>{eventDate.format(dateFormat)}</p>
          <a href={link} target="_blank" style={{ display: hasLocation ? 'block' : 'none' }}>Ver no mapa</a>
        </div>
      )
    });

    var displayDateHistory = dateHistory.map(event => {
      const eventDate = moment.tz(event.updatedAt, tz);
      const newDateStart = moment.tz(event.dateRangeStart, tz);
      const dateRangeEnd = moment.tz(event.dateRangeEnd, tz);
      const user = event.updatedBy ? event.updatedBy.email : 'Cliente';

      return (
        <div className="ScheduleDetails-history">
          <p><b>Nova Data Inicial: </b>{newDateStart.format(dateFormat)}</p>
          <p><b>Nova Data Final: </b>{dateRangeEnd.format(dateFormat)}</p>
          <p><b>Data da atualização: </b> {eventDate.format(dateFormat)}</p>
          <p><b>Usuário responsavel: </b>{user}</p>
        </div>
      )
    });

    var displayTakeRateHistory = takeRateHistory.map(event => {
      const eventDate = moment.tz(event.createdAt, tz);
      const expiration = moment.tz(event.expiration, tz);
      const user = event.createdBy ? event.createdBy.email : 'Não disponível';

      return (
        <div className="ScheduleDetails-history">
          <p><b>Nova porcentagem: </b>{event.value}%</p>
          <p><b>Nova data de expiração: </b> {expiration.format(dateFormat)}</p>
          <p><b>Data da atualização: </b> {eventDate.format(dateFormat)}</p>
          <p><b>Usuário responsavel: </b>{user}</p>
        </div>
      )
    });

    var displayProfessionalPreferences = professionalPreferences.map(professional => {
      return (
        <div className="ScheduleDetails-history">
          <p><b>Identificador: </b> <a href={`/#/artist/${professional._id}`} target={"_blank"}>{professional._id} </a> </p>
          <p><b>Nome: </b>{professional.name}</p>
        </div>
      )
    });

    return (
      <div className="ScheduleDetails">
        <center><h4>{rule && rule.reference && rule.reference.toUpperCase() || ''}</h4></center>

        <div>
          <center>
            {
              infractionHistory !== undefined && Object.keys(infractionHistory).length !== 0 ?
                <a href={`/#/infraction/schedule/${id}`} target={"_blank"}>Ver infrações do Agendamento</a> :
                <p>Agendamento sem infrações</p>
            }
          </center>
        </div>

        <div style={{ display: statusCode === 5 ? 'block' : 'none' }}>
          <h4><b>Valores de cancelamento</b></h4>
          <div className="Modal-section">
            <p><b>Multa para cliente no valor de: </b> {cancelValue.totalValue}</p>
            <p><b>Valor a receber pela artista: </b> {cancelValue.artistValue > 0 ? artistValue : 0}</p>
          </div>
        </div>
        <br />
        <div style={{ display: statusCode !== 5 ? 'block' : 'none' }}>
          <h4><b>Precificação Cliente</b></h4>
          <div className="Modal-section">
            <div>
              <p><b>Numero de clientes no local: </b> {numberOfClients}</p>
              <p><b>Pedido feito com preferência: </b> {preference ? 'Sim' : 'Não'}</p>
              <p><b>Valor original: </b> {preference ? originalValuePreference : originalValue}</p>
              <p><b>Valor dinâmico: </b> {this.calculateDynamicPrice(services, date)}</p>
              <p><b>Valor a cobrar da cliente: </b> {preference ? totalValuePreference : totalValue}</p>
              <p><b>Desconto aplicado:</b> {preference ? discountPreference : discount}</p>
              <ul>
                <li><b>Singu créditos: </b> {preference ? singuCreditsUsedPreference : singuCreditsUsed}</li>
                <li><b>Voucher: </b> {preference ? voucherDiscountPreference : voucherDiscount}</li>
                <li><b>Devido atraso de profissional: </b> {infractionClientDiscount || 0}</li>
              </ul>
            </div>
          </div>
        </div>

        <br />

        <h4><b>Precificação Profissional</b></h4>
        <div className="Modal-section">
          <p>
            <b>Profissional responsável: </b>
            {partnerName || "Sem artista"}
            {partnerId && <BlockProfessionalComponent
              userId={userId}
              partnerId={partnerId}
            />
            }
          </p>
          <p><b>Pedido feito com preferência: </b> {preference ? 'Sim' : 'Não'}</p>
          {takeRate && <p><b>Artista recebe do valor base: </b> {professionalTakeRate}</p>}
          <p><b>Valor base: </b> {preference ? serviceValuePreference : serviceValue} </p>
          <p><b>Valor a receber profissional: </b> {artistValue}</p>
          <p><b>Valor recebido pela profissional: </b> {transfer.amount}</p>
          <p><b>Valor que foi descontado da profissional: </b> {infractionProfessionalDiscount}</p>
          <p><b>Infração que gerou o desconto para a
            profissional: </b> {infractionProfessionalDiscountId > 0 ? infractionProfessionalDiscountId : 'Não existente'}
          </p>
        </div>

        <div style={{ display: displayProfessionalPreferences.length > 0 ? 'block' : 'none' }}>
          <h4><b>Preferencia por profissional:</b></h4>
          <p><b>A cliente deseja ser atendide somente pela preferência? {onlyForPreference ? "SIM" : "NÃO"}</b></p>
          <div className="Modal-section">
            {displayProfessionalPreferences}
          </div>
        </div>
        <br />

        <div className="Modal-section clearfix">
          <div className="Modal-sectionTitle">
            OBSERVAÇÃO DA CLIENTE
          </div>
          <span>{note || "SEM OBSERVAÇÃO"}</span>
        </div>

        <div className="Modal-section clearfix">
          <div className="Modal-sectionTitle">
            COMENTÁRIOS
          </div>
          <CommentComponent
            scheduleId={id}
            comments={comments}
            notify={(msg, lvl) => this.notify(msg, lvl)}
            updateCallback={this.props.addCommentAction}
          />
        </div>
        <br />
        <div>
          <h4><b>Informações do cliente</b></h4>
          <div className="Modal-section">
            <div className="row">

              <div className="col-sm-5">
                <div className="Modal-displayLabel">Nome</div>
                <div className="ScheduleDetails-detail">
                  {name || notAvailable}
                </div>
              </div>

              <div className="col-sm-5">
                <div className="Modal-displayLabel">E-mail</div>
                <div className="ScheduleDetails-detail">
                  {email || notAvailable}
                </div>
              </div>

              <div className="col-sm-5">
                <div className="Modal-displayLabel">Telefone</div>
                <div className="ScheduleDetails-detail">
                  {phone || notAvailable}
                </div>
              </div>


              <div className="col-sm-5">
                <div className="Modal-displayLabel">Device</div>
                <div className="ScheduleDetails-detail">
                  {agent}
                </div>
              </div>
            </div>
          </div>

          <div style={{ display: displayClientAlerts.length > 0 ? 'block' : 'none' }}>
            <h4><b>Alertas recebidos pela cliente</b></h4>
            <div className="Modal-section">
              {displayClientAlerts}
            </div>
          </div>
          <h4><b>Endereço do agendamento </b> - <a target="_blank" href={addressOnMap}>Ver no mapa</a></h4>
          <div className="Modal-section">
            <div className="row">
              <div className="col-sm-4">
                <EditableField
                  initialValue={uf}
                  fieldTitle="Cidade"
                  fieldName="uf"
                  defaultValue={notAvailable}
                  editDisabled={true}
                  updateCallback={updateCallback} />
              </div>
              <div className="col-sm-4">
                <EditableField
                  initialValue={place}
                  fieldTitle="Rua"
                  fieldName="place"
                  defaultValue={notAvailable}
                  editDisabled={true}
                  updateCallback={updateCallback} />
              </div>
              <div className="col-sm-4">
                <EditableField
                  initialValue={streetNumber}
                  fieldTitle="Número"
                  fieldName="streetNumber"
                  defaultValue={notAvailable}
                  editDisabled={this.isEditingAllowed()}
                  updateCallback={updateCallback} />
              </div>

            </div>
            <br />
            <div className="row">
              <div className="col-sm-4">
                <EditableField
                  initialValue={neighbor}
                  fieldTitle="Bairro"
                  fieldName="neighbor"
                  defaultValue={notAvailable}
                  editDisabled={true}
                  updateCallback={updateCallback} />
              </div>
              <div className="col-sm-4">
                <EditableField
                  initialValue={zipCode}
                  fieldTitle="CEP"
                  fieldName="zipCode"
                  defaultValue={notAvailable}
                  editDisabled={true}
                  updateCallback={updateCallback}
                  type="zipcode" />
              </div>
              <div className="col-sm-4">
                <EditableField
                  initialValue={complement}
                  fieldTitle="Complemento"
                  fieldName="complement"
                  defaultValue={notAvailable}
                  editDisabled={this.isEditingAllowed()}
                  updateCallback={updateCallback} />
              </div>
            </div>
          </div>
        </div>


        <br />
        <div>
          <h4><b>Informações de Pagamento</b></h4>
          <div className="Modal-section">
            <div>
              <p><b>Status do Pagamento: </b>
                <a target="_blank"
                  href={`https://conta.wirecard.com.br/orders/list?q=${id}%09#result`}>{payment.status}</a>
              </p>
              <p><b>Status da Transferência: </b>
                <a target="_blank"
                  href={`https://conta.wirecard.com.br/transfers/${transfer.id}`}>{transfer.status}</a>
              </p>
            </div>

            <SchedulePixPayments schedule={{ id, totalValue: preference ? totalValuePreference : totalValue, pixPayments, partnerName, payment, statusCode }} />

            {/* Link Payment or Copy and Paste Code */}

            {this.state.pixCode && (
              <div className='payment-info-contaier' >
                <p><b>Link de pagamento:</b></p>
                <div className='pix-container'>
                  <span>
                    { this.state.pixCode || 'Buscando link de pagamento...' }
                  </span>
                  <div onClick={() => this.handleCopyCodePix()}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                      <g clipPath="url(#clip0_383_2853)">
                        <path d="M12.7188 4H5.78125C4.79749 4 4 4.79749 4 5.78125V12.7188C4 13.7025 4.79749 14.5 5.78125 14.5H12.7188C13.7025 14.5 14.5 13.7025 14.5 12.7188V5.78125C14.5 4.79749 13.7025 4 12.7188 4Z" stroke="#FF6F61" strokeWidth="2" stroke-linejoin="round" />
                        <path d="M11.9844 4L12 3.25C11.9987 2.78628 11.8139 2.34192 11.486 2.01402C11.1581 1.68612 10.7137 1.50132 10.25 1.5H3.5C2.97005 1.50157 2.46225 1.71278 2.08752 2.08752C1.71278 2.46225 1.50157 2.97005 1.5 3.5V10.25C1.50132 10.7137 1.68612 11.1581 2.01402 11.486C2.34192 11.8139 2.78628 11.9987 3.25 12H4" stroke="#FF6F61" strokeWidth="2" strokeLinecap="round" stroke-linejoin="round" />
                      </g>
                      <defs>
                        <clipPath id="clip0_383_2853">
                          <rect width="16" height="16" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>
                  </div>
                </div>
              </div>
            )}

            
            {this.state.showAlert && (
              <AlertV2 
                text="Link copiado para a sua área de transferência!" 
                className={this.state.alertAnimation}
              />
            )}

          </div>
        </div>

        <br />
        <div>
          <h4><b>Histórico do agendamento</b></h4>
          <div className="Modal-section">
            {displayStatusHistory}
          </div>
        </div>

        <br />
        <div style={{ display: displayArtistHistory.length > 0 ? 'block' : 'none' }}>
          <h4><b>Histórico de profissionais</b></h4>
          <div className="Modal-section">
            {displayArtistHistory}
          </div>
        </div>

        <br />
        <div style={{ display: displayDateHistory.length > 0 ? 'block' : 'none' }}>
          <h4><b>Histórico de alteração de data</b></h4>
          <div className="Modal-section">
            {displayDateHistory}
          </div>
        </div>

        <br />
        <div style={{ display: displayTakeRateHistory.length > 0 ? 'block' : 'none' }}>
          <h4><b>Histórico de alteração da % SINGU</b></h4>
          <div className="Modal-section">
            {displayTakeRateHistory}
          </div>
        </div>

        <br />
        <div style={{ display: displayArtistEvents.length > 0 ? 'block' : 'none' }}>
          <h4><b>Notificações da profissional</b></h4>
          <div className="Modal-section">
            {displayArtistEvents}
          </div>
        </div>

        <NotificationSystem ref="notificationSystem" />
      </div >
    );
  }
}

ScheduleDetails.propTypes = {
  rowData: PropTypes.object,
  notify: PropTypes.func,
  dataConfirmationAction: PropTypes.func,
  updateCallback: PropTypes.func
};

export default ScheduleDetails;
