/* eslint-disable react/no-did-mount-set-state */
import React from 'react';
import ArtistPGMEdit from '../../components/Artists/ArtistPGMEdit';
import Swal from "sweetalert2";
import { savePGM, getPGM } from "../../actions/areas/pgm";

class ArtistPGM extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            pgm: {
                title: '',
                message: '',
                share: '',
                'aquisition.discount': 0,
                'aquisition.professionalGain': 0,
                'recurrence.discount': 0,
                'recurrence.professionalGain': 0,
                activated: false
            },
            loading: true
        }
    }

    async componentDidMount() {
        try {
            const { data } = await getPGM();
            this.loadPGM(data);
        } catch (error) {
            console.log(error);
        } finally {
            this.setState({ loading: false });
        }
    }

    loadPGM = data => {
        const pgm = {
            title: data?.title || '',
            message: data?.message || '',
            share: data?.share || '',
            activated: data?.activated || '',
            'aquisition.discount': data?.aquisition?.discount || 0,
            'aquisition.professionalGain': data?.aquisition?.professionalGain || 0,
            'recurrence.discount': data?.recurrence?.discount || 0,
            'recurrence.professionalGain': data?.recurrence?.professionalGain || 0,
        };
        this.setState({ pgm });
    }

    onChange = (value, field) => {
        const { pgm } = this.state;
        pgm[field] = value;
        this.setState({ pgm });
    }

    onSave = async () => {
        try {
            await savePGM(this.state);
            Swal.fire("Sucesso", "PGM Alterado com sucesso", "success");
        } catch (error) {
            const message = error?.data?.error?.description || "Houve um erro ao atualizar o PGM";
            Swal.fire("Ops..", message, "error");
        }
    }

    render(){
        return (
            <ArtistPGMEdit 
                onChange={this.onChange}
                onSave={this.onSave}
                values={this.state.pgm}
            />
        )
    }
}

export default ArtistPGM;