import React, { Component, PropTypes } from 'react';
import _ from 'underscore';
import { SimpleSelect } from 'react-selectize';
import { Button } from 'react-bootstrap';
import handleRequest from './../../../helper/requests';

// style
import './ChangeService.css';

class ChangeService extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dropdownService: [],
      selectedValue: null
    };
  }

  componentDidMount() {
    const scheduleAreaId = this.props.rowData.address.areaId;
    const scheduleServiceId = this.props.rowData.serviceId;
    const { servicesByEachArea } = this.props;
    const findArea = ({ areaId }) => areaId === scheduleAreaId;
    const findCurrentService = ({ value }) => value === scheduleServiceId;
    const mapServices = (service) => {
      return {
        label: service.name['pt-BR'],
        value: service
      };
    };
    const scheduleServices = _.find(servicesByEachArea, findArea);

    if (scheduleServices) {
      const { areaServices } = scheduleServices;
      const dropdownServices = areaServices.map(mapServices);
      const currentService = _.find(dropdownServices, findCurrentService);

      this.setState({ dropdownServices, selectedValue: currentService });
    }
  }

  onValueChange(selectedValue) {
    this.setState({ selectedValue });
  }

  changeService() {
    const { rowData, updateCallback, notify } = this.props;
    const service = this.state.selectedValue.value;
    const { _id, categoryId } = service;
    const { areaId } = rowData.address;
    const scheduleId = rowData.id;
    const serviceId = _id;
    const actionCreator = () => updateCallback(scheduleId, areaId, serviceId, categoryId, service);

    handleRequest(actionCreator, notify, 'Serviço trocado com sucesso');
  }

  render() {
    return (
      <div>
        <div className="ChangeService-row">
          <SimpleSelect className="ChangeService-select"
                        placeholder="Selecione..."
                        options={this.state.dropdownServices}
                        onValueChange={this.onValueChange.bind(this)}
                        value={this.state.selectedValue}
          />
          <Button className="ChangeService-btn"
                  disabled={!this.state.selectedValue}
                  bsStyle="primary"
                  onClick={this.changeService.bind(this)}>Trocar serviço</Button>
        </div>
      </div>
    );
  }
}

ChangeService.propTypes = {
  rowData: PropTypes.object,
  servicesByEachArea: PropTypes.array,
  notify: PropTypes.func,
  updateCallback: PropTypes.func
};

export default ChangeService;
