import React, { Component } from "react";

import LeadScheduleConfiguration from "../../components/Leads/LeadSchedule/ConfigurationForm";
import { saveEvaluationScheduleDate } from "../../actions/leads/save_schedule_date"
import { getScheduleDate } from "../../actions/leads/get_schedule_date"
import moment from 'moment'
import Swal from "sweetalert2";

class LeadSchedulesConfiguration extends Component {

  constructor(props) {
    super(props)
    this.state = {
      loading: false,
      scheduleDate: {}
    }
  }


  saveConfiguration = (fields, _id) => {
    return saveEvaluationScheduleDate(fields, _id);
  }

  componentDidMount() {
    try {
      const { params } = this.props;
      
      if (this.props && params && params.scheduleDateId) {
        this.setState({ loading: true });

        (async () => {
          const { data } = await getScheduleDate(params.scheduleDateId);

          Object.assign(data , { 
            start: moment(data.start).format("YYYY-MM-DD"),
            end: moment(data.end).format("YYYY-MM-DD"),
            days: data.days.map(day => day.toString()),
            type: data.type.id
          });

          this.setState({ scheduleDate: data });
        })();
      }
    } catch (err) {
      console.log('LOAD LEAD DATES ERROR', err)
      Swal.fire('Ops...', 'Não foi possível recuperar os dados para edição', 'error');
    } finally {
      this.setState({ loading: false });
    }
  }

  render() {
    return (
      <LeadScheduleConfiguration
        loading={this.state.loading}
        scheduleDate={this.state.scheduleDate}
        onSubmit={this.saveConfiguration}
      />
    )
  }
}

export default LeadSchedulesConfiguration;