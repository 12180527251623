import { FETCH_ZONES } from './../actions/administrative_zones/fetch_zones';

const INITIAL_STATE = {
  all: []
};

const administrativeZones = (state = INITIAL_STATE, action) => {
  if (action.error) {
    return state;
  }

  switch (action.type) {
    case FETCH_ZONES:
      return {
        ...state,
        all: action.payload.data
      }
    default:
      return state;
    }
};

export default administrativeZones;
