import React, { Component } from "react";

import Loading from "../Global/Loading/Loading";
import  ServiceGridle from "./ServiceGriddle";
import PageHeader from '../Global/PageHeader';

export default class ListServiceComponent extends Component {
  render() {
    return (
      <div className="row">
        <div className="col-md-12">
          <PageHeader title="Serviços disponíveis" />
          {this.props.status === "LOADING" ? 
            <Loading /> :
            <ServiceGridle services={this.props.services} />
          }
        </div>
      </div>
    )
  }
}