import React, { Component } from "react"

import Accordion from '../FormFields/Accordion';

import './DetailsStyles.css';

export default class AreaDetails extends Component {
  render() {
    const { area } = this.props
    if (!area || !area._id) return <div></div>
    return (
      <div className="row">
        <div className="areaInfoWrapper">
          <div className="infoDetails">
            <p className="title">PGM:</p>
            <span className="desc">Recebe: {area.pgm.discount}</span>
            <span className="desc" style={{ marginLeft: 10 }}>Envia: {area.pgm.professionalGain}</span>
          </div>
          <div className="infoDetails">
            <p className="title">PGP:</p>
            <span className="desc">Aprovada: {area.pgp.approved}</span>
            <span className="desc" style={{ marginLeft: 10 }}>Engajada: {area.pgp.engaged}</span>
          </div>
        </div>
        {area.categories.map((category) => {
          return (
            <div key={category} className="modalAccordion">
              <Accordion title={category.name["pt-BR"]}>
                <div className="row">
                  {category.services.map(service => {
                    return (
                      <div key={service._id} className="category">
                        <p className="title">Nome: <span className="desc">{service.name["pt-BR"]}</span></p>
                        <p className="title">Valor mínimo: <span className="desc">{service.minValue}</span></p>
                        <p className="title">Duração mínima: <span className="desc">{service.minDuration}</span></p>
                        <p className="title">Duração média: <span className="desc">{service.avgDuration}</span></p>
                        <p className="title">Duração máxima: <span className="desc">{service.duration}</span></p>
                      </div>
                    )
                  })}
                </div>
              </Accordion>
            </div>
          )
        })}
      </div>
    );
  }
}