import React, { Component, PropTypes } from "react";
import NewCampaignComponent from "../../components/Campaigns/NewCampaign";
import { saveCampaign } from "../../actions/campaigns/camapaings";
import { fetchAreas } from "../../actions/areas/fetch_areas";
import { connect } from "react-redux";


class NewCampaign extends Component {
  componentWillMount() {
    this.getAreas();
  }

  getAreas() {
    return this.props.fetchAreas();
  }

  save = params => {
    return saveCampaign(params);
  };

  render() {
    const props = {
      areas: this.getAreas,
      save: this.save,
      ...this.props
    };

    return (
      <NewCampaignComponent {...props} history={this.props.history} />
    );
  }
}

NewCampaign.propTypes = {
  fetchAreas: PropTypes.func
};

const mapActionsToProps = {
  fetchAreas
};

function mapStateToProps(state) {
  return {
    areas: state.areas.all
  };
}

export default connect(
  mapStateToProps,
  mapActionsToProps
)(NewCampaign);

