import config from '../../../config.json';
const env = process.env.REACT_APP_ENVIRONMENT || 'stage';
const BACKEND_URL = config.api[env];
import axios from 'axios';

import { getToken } from './../../models/auth';

export const UPDATE_SCHEDULE_VOUCHER = 'UPDATE_SCHEDULE_VOUCHER';

export function updateScheduleVoucher(scheduleId, voucher) {
  const updateVoucherPromise = axios.put(`${BACKEND_URL}/v2/bo/schedule/changeVoucher`,
    { scheduleId, voucherCode: voucher.code },
    {
      headers: { Authorization: `Bearer ${getToken()}` }
    }
  ).catch(err => {
    if (err && err.response && err.response.data) {
      return Promise.reject(err.response.data);
    }
    return Promise.reject(err);
  });

  return {
    type: UPDATE_SCHEDULE_VOUCHER,
    payload: updateVoucherPromise,
    meta: { voucher, id: scheduleId }
  };
}
