import React from 'react';

export default function ({ checked, text, value, onChange, name }) {
  return (
    <div className={`Form-choice ${checked ? "is-selected" : ""}`}>
      <input
        id={`${name || text || value}-${value}`}
        type="radio"
        className="Form-radio"
        value={value}
        name={name || text || value}
        onClick={evt => onChange(evt.target.value)}
      />
      <label
        htmlFor={`${name || text || value}-${value}`}
        className="Form-choiceLabel Form-choiceLabel--radio"
      >
        {text}
      </label>
    </div>
  )
}