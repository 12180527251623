import axios from 'axios';
import config from '../../../config.json';
const env = process.env.REACT_APP_ENVIRONMENT || 'stage';
const BACKEND_URL = config.api[env];

import { getToken } from './../../models/auth';

export function importTransfers(transfers) {
    return axios.post(`${BACKEND_URL}/v2/artist/bonus/batch`, transfers, {
        headers: {
            Authorization: `Bearer ${getToken()}`   
        }
    });
}