import axios from 'axios';
import config from '../../../config.json';
const env = process.env.REACT_APP_ENVIRONMENT || 'stage';
const BACKEND_URL = config.api[env];

import { getToken } from "../../models/auth";

export function saveCampaign(params) {
  return axios.post(`${BACKEND_URL}/campaigns`, params, {
    headers: { Authorization: `Bearer ${getToken()}` }
  });
}

export function editCampaign(_id, params) {
  return axios.put(`${BACKEND_URL}/campaigns/${_id}`, params, {
    headers: { Authorization: `Bearer ${getToken()}` }
  });
}
