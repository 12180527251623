import React, { Component } from 'react';
import Clipboard from 'clipboard';
import { Button } from 'react-bootstrap';
import { getClipboardMessage } from './../../../helper/scheduleClipboard.js';

class WhatsappTextButton extends Component {
  componentWillMount() {
    this.clipboardMsg = getClipboardMessage(this.props.data);
  }

  componentDidMount() {
    this.clipboard = new Clipboard('.btn-clipboard');
  }

  componentWillReceiveProps(newProps){
    this.clipboardMsg = getClipboardMessage(newProps.data);
  }

  copyToClipboardClick() {
    this.props.notify('Informações copiadas com sucesso.', 'success');
  }

  render() {
    return (
      <Button className="ScheduleActions-btn btn btn-info btn-clipboard"
              data-clipboard-text={this.clipboardMsg}
              onClick={() => this.copyToClipboardClick()}
              disabled={this.props.disabled}
              title="Copiar linha">

              <span className="glyphicon glyphicon-copy" />
      </Button>
    );
  }
}

export default WhatsappTextButton;
