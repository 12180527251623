import React, { Component, PropTypes } from 'react';
import ReactDOM from 'react-dom';
import VMasker from 'vanilla-masker';

import formatter from '../../helper/formatters';

class EditableTextField extends Component {
  constructor(props) {
    super(props);
    const { currentValue } = this.props;

    this.state = {
      newValue: currentValue,
      currentValue,
    };
  }

  componentDidMount() {
    const { mask } = this.props;
    const input = ReactDOM.findDOMNode(this.refs.input);

    if (mask) {
      const isMaskArray = Array.isArray(mask);
      const isFullPhone = input.value.length > 11 ? 1 : 0;
      const maskSelected = isMaskArray ? mask[isFullPhone] : mask;

      const maskedValue = VMasker.toPattern(input.value, { pattern: maskSelected });

      VMasker(input).maskPattern(maskSelected);

      this.setState({ currentValue: maskedValue });
    }

    input.addEventListener('keydown', evt => {
      const { keyCode } = evt;

      switch (keyCode) {
        case 13:
          // ENTER key
          this.doneEditing();
          break;
        case 27:
          // ESC key
          this.cancelEdit();
          break;
      }
    });
  }

  editField() {
    const { methods } = this.props;
    const callback = () => {
      const input = ReactDOM.findDOMNode(this.refs.input);

      input.focus();
      input.setSelectionRange(0, input.value.length);
    };

    methods.editField(callback);
  }

  doneEditing() {
    const {
      methods: { saveField, notify },
      type,
    } = this.props;
    const { newValue } = this.state;
    const fulfill = action => {
      if (action.error) {
        if (action.payload.data) {
          notify(action.payload.data.error.description, 'error');
        } else {
          notify(action.payload.message, 'error');
        }
      } else {
        this.setState(
          {
            currentValue: newValue,
            newValue,
          },
          () => notify('Valor alterado com sucesso', 'success'),
        );
      }
    };
    const error = () => notify('Ocorreu um erro inesperado', 'error');
    let saveValue = newValue;

    if (type === 'phone') {
      saveValue = formatter.assemblePhoneObject(newValue);

      if (!saveValue) {
        notify('Inserir telefone válido', 'error');
        return;
      }
    }

    saveField(saveValue, fulfill, error);
  }

  cancelEdit() {
    const { cancelEdit } = this.props.methods;
    const { currentValue } = this.state;

    this.setState(
      {
        newValue: currentValue,
      },
      cancelEdit,
    );
  }

  onFieldChange(evt) {
    const { mask, type } = this.props;

    const input = evt.target;
    let newValue = input.value;

    if (type === 'phone') {
      VMasker(input).unMask();
      newValue = newValue.replace(/\D/g, '');
      const isFullPhone = newValue.length > 11 ? 1 : 0;
      VMasker(input).maskPattern(mask[isFullPhone]);
      newValue = VMasker.toPattern(newValue, mask[isFullPhone]);
    }

    this.setState({ newValue });
  }

  render() {
    const defaultValue = 'Não informado';
    const { newValue, currentValue } = this.state;
    const { editDisabled } = this.props;

    return (
      <div className="EditableField-detail">
        <div className="EditableField-infoWrapper">
          <span className="EditableField-infoDisplay">{currentValue || defaultValue}</span>
          <span className="EditableField-infoInput">
            <input type="text" ref="input" value={newValue} onChange={evt => this.onFieldChange(evt)} />
          </span>
        </div>
        {!editDisabled && (
          <div className="EditableField-buttonsWrapper">
            <span
              onClick={() => this.editField()}
              className="EditableField-btn EditableField-btn--edit glyphicon glyphicon-edit"
            ></span>
            <span className="EditableField-controls">
              <span
                onClick={() => this.doneEditing()}
                className="EditableField-btn EditableField-btn--save glyphicon glyphicon-ok"
              ></span>
              <span
                onClick={() => this.cancelEdit()}
                className="EditableField-btn EditableField-btn--cancel glyphicon glyphicon-remove"
              ></span>
            </span>
          </div>
        )}
      </div>
    );
  }
}

EditableTextField.propTypes = {
  methods: PropTypes.object,
  currentValue: PropTypes.string,
  mask: PropTypes.string,
  type: PropTypes.string,
};

export default EditableTextField;
