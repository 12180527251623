
import axios from 'axios';
import config from '../../../config.json';
const env = process.env.REACT_APP_ENVIRONMENT || 'stage';
const BACKEND_URL = config.api[env];
import { getToken } from './../../models/auth';

export function fetchArtistGamificationRanking(professionalId) {
  return axios.get(`${BACKEND_URL}/gamification/${professionalId}/ranking`, {
    headers: { Authorization: `Bearer ${getToken()}` }
  });
}

export function fetchArtistGamificationPerformance(professionalId) {
  return axios.get(`${BACKEND_URL}/gamification/${professionalId}/performance`, {
    headers: { Authorization: `Bearer ${getToken()}` }
  });
}
