"use strict";

import React, { Component } from "react";
import UploadField from "../FormFields/SimpleUploadField";
import { getSignedUrl, uploadCampaignImg } from "../../actions/campaigns/uploadImg";
import Swal from "sweetalert2";

export default class EditCampaign extends Component {
  constructor(props) {
    super(props);
    const { campaign, area, cities } = props;

    this.state = {
      area: area || {},
      cityList: cities,
      campaign: Object.assign({}, campaign),
      campaignStructure: {
        title: "",
        text: "",
        introduction: "",
        cities: [],
        img: "",
        active: "",
        link: ""
      }
    };
  }

  saveCampaign(e) {
    e.preventDefault();
    this.props
      .editCampaign(this.state.campaign._id, this.state.campaign)
      .then(() => {
        Swal.fire({ icon: "success", title: "Sucesso", text: "Campanha alterada com sucesso" }).then(() => {
          this.props.handleClose();
          window.location.reload();
        });
      })
      .catch(err => {
        Swal.fire({ icon: "error", title: "Oops...", text: "Erro ao alterar campanha" });
        console.log(err);
      });
  }

  handleFormEdit(e) {
    const { value, name } = e.target;
    const { campaign } = this.state;

    console.log({ value, name });

    if (name === "cities") {
      console.log(campaign.cities)
      if (!campaign.cities.includes(value)) {
        campaign.cities.push(value);
      } else {
        campaign.cities = campaign.cities.filter(i => i !== value);
      }
    } else {
      campaign[name] = value === "true" ? true : value === "false" ? false : value;
    }

    console.log(campaign)
    this.setState({ campaign });
  }

  handleUploadImage(file, onProgress) {
    const { campaign } = this.state;
    return getSignedUrl(file)
      .then(({ data }) => {
        this.setState({
          campaign: {
            ...campaign,
            img: data.split("?")[0]
          }
        });
        return uploadCampaignImg(data, file, onProgress)
          .then(_ => {
            return data;
          });
      });
  }

  renderId() {
    if (this.state.campaign && this.state.campaign._id) {
      return (
        <input type={"hidden"} name={"_id"} value={this.state.campaign._id} />
      );
    }
  }

  render() {
    const { title, introduction, text, type, img, link, cities, userSegment } = this.state.campaign;
    const { cityList } = this.state
    return (
      <div className="row">
        <form onSubmit={this.saveCampaign.bind(this)}>
          {this.renderId()}
          <div className="col-xs-12 form-group">
            <label>Título da Campanha</label>
            <input
              className="form-control form-control-normal"
              name={"title"}
              required
              value={title}
              onChange={this.handleFormEdit.bind(this)}
            />
          </div>
          <div className="col-xs-12 form-group">
            <label>Resumo</label>
            <input
              className="form-control form-control-normal"
              name={"introduction"}
              required
              value={introduction}
              onChange={this.handleFormEdit.bind(this)}
            />
          </div>
          <div className="col-xs-12 form-group">
            <label>Descrição completa</label>
            <textarea
              className="form-control form-control-normal"
              name={"text"}
              required
              value={text}
              onChange={this.handleFormEdit.bind(this)}
            />
          </div>
          <div className="col-xs-12 form-group">
            <label>Link</label>
            <input
              className="form-control form-control-normal"
              name={"link"}
              value={link}
              onChange={this.handleFormEdit.bind(this)}
            />
          </div>
          <div className="col-xs-12 form-group">
            <label>Imagem de exibição</label>
            <UploadField
              name="img"
              label="Upload da imagem de exibição da campanha"
              inputData={img}
              disabled={false}
              uploadCallback={this.handleUploadImage.bind(this)}
              type="image"
              crop={false}
            />
          </div>
          <div className="form-group">
            <label>Cidade disponíveis</label>
            <div className="col-xs-12">
              {cityList.map((city, index) => {
                return (
                  <label className="checkbox-inline" key={city._id}>
                    <input
                      type="checkbox"
                      name={"cities"}
                      value={city._id}
                      defaultChecked={cities.includes(city._id) ? true : false}
                      onChange={this.handleFormEdit.bind(this)}
                    />
                    {city.city}
                  </label>
                )
              })}
            </div>
          </div>
          <div className="form-group">
            <label>Aparecer para:</label>
            <div className="col-xs-12">
              <label className="radio-inline">
                <input
                  name="type"
                  type="radio"
                  value="user"
                  checked={type === "user" ? true : false}
                  onChange={this.handleFormEdit.bind(this)}
                />
                Cliente
              </label>
              <label className="radio-inline">
                <input
                  type="radio"
                  name="type"
                  value="artists"
                  checked={type === "artists" ? true : false}
                  onChange={this.handleFormEdit.bind(this)}
                />
                Profissional
              </label>
            </div>
          </div>
          {userSegment !== undefined && userSegment !== null && (
            <div className="form-group">
              <label>Campanha segmentada para: </label>
              <div className="col-xs-12">
                <label className="radio-inline">
                  <input
                    type="radio"
                    name="userSegment"
                    value="newUsers"
                    checked={userSegment === "newUsers"}
                    onChange={this.handleFormEdit.bind(this)}
                  />
                  Novos Usuários
                </label>
                <label className="radio-inline">
                  <input
                    type="radio"
                    name="userSegment"
                    value="returningUsers"
                    checked={userSegment === "returningUsers"}
                    onChange={this.handleFormEdit.bind(this)}
                  />
                  Usuários Recorrentes
                </label>
                <label className="radio-inline">
                  <input
                    name="userSegment"
                    type="radio"
                    value="both"
                    checked={userSegment === "both"}
                    onChange={this.handleFormEdit.bind(this)}
                  />
                  Ambos (Todos os usuários)
                </label>
              </div>
            </div>
          )}
          <div className="text-right">
            <button
              type={"button"}
              className="btn btn-campaign btn-default"
              onClick={this.props.handleClose}
            >
              Fechar
            </button>
            <button className="btn btn-campaign btn-success" type="submit">
              Salvar
            </button>
          </div>
        </form>
      </div>
    );
  }
}
