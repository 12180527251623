import InvestimentForm from "../../components/Investiments/InvestimentForm"
import investimentValidator from "./validate"
import PageHeader from "../../components/Global/PageHeader"
import { Toast } from "../../helper/alerts"
import { Link } from "react-router"
import { createInvestiment } from "../../services/investiments"

const NewInvestiment = () => {
    const handleOnSubmit = async investiment => {
        try {
            await investimentValidator(investiment)
            await createInvestiment(investiment)

            Toast().fire({ title: 'Investimento Adicionado!', icon: 'success' })
            window.location = "#/investiments"
        } catch (e) {
            Toast().fire({ title: e.message, icon: 'error' })
        }

    }

    return (
        <div>
            <PageHeader title={"Adicionar Investimentos"} />
            <Link to="investiments">Lista de Investimentos</Link>
            <InvestimentForm onSubmit={handleOnSubmit} />
        </div>
    )
}

export default NewInvestiment