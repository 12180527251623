import axios from 'axios';
import config from '../../../config.json';
const env = process.env.REACT_APP_ENVIRONMENT || 'stage';
const BACKEND_URL = config.api[env];
import { getToken } from '../../models/auth';

export const FETCH_ARTISTS_TO_SCHEDULE = 'FETCH_ARTISTS_TO_SCHEDULE';

export function fetchArtistsToSchedule(scheduleId, filter) {
  let params = {
    filter
  };

  const artistsPromise = axios.get(`${BACKEND_URL}/schedule/find_partner/${scheduleId}`, {
    headers: { Authorization: `Bearer ${getToken()}` },
    params
  });

  return {
    type: FETCH_ARTISTS_TO_SCHEDULE,
    payload: artistsPromise
  };
}
