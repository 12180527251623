import React, { Component } from 'react';
import { Modal, Button } from 'react-bootstrap';
import moment from 'moment-timezone';

import { fetchManualPoints } from '../../../../actions/artists/fetch_manual_points';
import Loading from "../../../Global/Loading/Loading";

class PointsGamification extends Component {
    state = {
        pointsValue: 0,
        pointsError: '',
        reasonError: '',
        reason: '',
        status: 'READY',
        manualPoints: []
    }

    componentDidMount() {
        this.fetchPoints()
    }

    fetchPoints = () => {
        this.setState({ status: 'LOADING' })
        if (this.props.id) {
            fetchManualPoints(this.props.id).then(({ data }) => {
                this.setState({ manualPoints: data.manualPointsHistory })
            }).finally(() => {
                this.setState({ status: 'READY' })
            })
        }
    }

    validatePoints = () => {
        const { pointsValue } = this.state;
        this.setState({
            pointsError: pointsValue ? null : 'Preenchimento Obrigatório',
        });
    };

    validateReason = () => {
        const { reason } = this.state;
        this.setState({
            reasonError: reason ? null : 'Preenchimento Obrigatório',
        });
    };

    addPointsGamification = () => {

        this.validateReason();
        this.validatePoints();

        const { pointsValue, reason } = this.state;
        if (pointsValue && reason) {
            this.props.addPointsGamification({
                id: this.props.id,
                points: pointsValue,
                reason
            }).then(() => {
                this.props.close();
                window.location.reload();
            }).catch((error) => {
                console.log(error);
                alert('Erro ao adicionar pontos para a profissional.');
            });
        }
    };

    render() {
        const { manualPoints, status } = this.state;
        moment.locale('pt-br');
        const currentDate = moment().tz('America/Sao_Paulo').format('MMMM - yyyy');

        return (
            <Modal bsSize="lg" show={this.props.open} onHide={this.props.close}>
                <Modal.Header closeButton>
                    <Modal.Title>Adicionar pontos Gamification</Modal.Title>
                </Modal.Header>

                {status === "LOADING" ?
                    <Loading /> :
                    <Modal.Body>
                        <div>
                            <p style={{ textAlign: 'center' }}>Gamification corrente: <b>{currentDate}</b></p>
                        </div>

                        <div className="form-group">
                            <label>
                                Quantidade de Pontos*
                            </label>
                            <input type="number" className={`Form-input ${this.state.pointsValueError ? 'is-invalid' : ''}`} value={this.state.pointsValue} onChange={e => this.setState({ pointsValue: e.target.value }, () => { this.validatePoints() })} />
                            <div className='invalid-feedback'>{this.state.transferValueError}</div>
                        </div>

                        <div className="form-group">
                            <label>Motivo*</label>
                            <textarea className={`Form-input ${this.state.reasonError ? 'is-invalid' : ''}`} value={this.state.reason} onChange={e => this.setState({ reason: e.target.value.trimLeft().replace(/\s\s+/g, ' ') }, () => { this.validateReason() })} >
                            </textarea>
                            <div className='invalid-feedback'>{this.state.reasonError}</div>
                        </div>

                        <div className="form-group">
                            <div className="col-xs-12 text-right">

                                <Button variant="secondary" onClick={this.props.close}>
                                    Cancelar
                                </Button>
                                <Button variant="primary" disabled={status !== "READY"} onClick={this.addPointsGamification}>
                                    {status === "READY" ? "Adicionar" : "Aguarde"}
                                </Button>
                            </div>
                        </div>

                        <div>
                            <h4 style={{ textAlign: 'center' }}>Pontos adicionados</h4>
                            <div className="row">
                                {manualPoints && manualPoints.length > 0 ? manualPoints.map((element, index) => {
                                    return (
                                        <div className="col-sm-6">
                                            <div className="comment" key={index} >
                                                {element.blame ? <p className="comment-user"><strong>Email:</strong> {element.blame.email} </p> : <p> N/A </p>}
                                                {element.points ? <p className="comment-user"><strong>Pontos:</strong> {element.points} </p> : <p> N/A </p>}
                                                {element.reason ? <p className="comment-user"><strong>Motivo:</strong> {element.reason}</p> : <p> N/A </p>}
                                            </div>
                                        </div>
                                    )
                                }) : <p className="text-center">Sem histórico de adição manual de pontos</p>}
                            </div>
                        </div>

                    </Modal.Body>}
            </Modal>
        )
    }
}

export default PointsGamification;