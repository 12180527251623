import React, { PropTypes } from 'react';
import { Button } from 'react-bootstrap';

const LeadActions = (props) => {
  const { rowData } = props;
  console.log(rowData)
  return (
    <div>
      <Button onClick={() => rowData.actions.openDetails(rowData.id)} title="Editar do lead" className="ScheduleActions-btn btn btn-info">
        <span title="Editar do lead" className="glyphicon glyphicon-pencil"></span>
      </Button>
      <Button onClick={() => rowData.actions.openDetailsModal(rowData.index)}
        title="Visualizar Lead"
        className="ScheduleActions-btn btn btn-info"
      >
        <span title="Visualizar Lead" className="glyphicon glyphicon-eye-open"></span>
      </Button>
    </div>
  );
};

LeadActions.propTypes = {
  rowData: PropTypes.object
};

export default LeadActions;
