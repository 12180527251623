import axios from 'axios';
import config from '../../../config.json';
const env = process.env.REACT_APP_ENVIRONMENT || 'stage';
const BACKEND_URL = config.api[env];

import {
    getToken
} from '../../models/auth';

export const ADD_COMMENT_SCHEDULE = 'ADD_COMMENT_SCHEDULE';

export function addCommentInSchedule(scheduleId, message) {

    const addCommentInSchedule = axios.post(`${BACKEND_URL}/v2/bo/schedule/comment`, { scheduleId, message },
        {
            headers: { Authorization: `Bearer ${getToken()}` }
        }
    );

    return {
        type: ADD_COMMENT_SCHEDULE,
        payload: addCommentInSchedule,
        meta: {
            message,
            id: scheduleId
        }
    };
}