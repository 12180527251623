import React, { Component } from "react";
import { getService } from "../../actions/service/get_service";
import errorHandler from "../../helper/errorHandler";
import ServiceComponent from "../../components/Services/Edit";

export default class ServiceFormContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      services: {}
    }
    this.serviceId = props && props.params && props.params.serviceId || null;
  }

  componentDidMount() {
    this.getService();
  }

  getService = () => {
    if (this.serviceId) {
      getService(this.serviceId).then(result => {
        this.setState({ services: result.data });
      }).catch(err => errorHandler(err, "Não foi possível recuperar o serviço selecionado para edição"))
    }
  }

  save = () => {
    const { services } = this.state;
    saveService(services, this.serviceId).then(res => {

    }).catch(err => errorHandler);
  }

  render() {
    return (
      <ServiceComponent
        services={this.state.services}
      />
    )
  }
}