import React, { Component, PropTypes } from 'react';
import { SimpleSelect } from 'react-selectize';
import { Button } from 'react-bootstrap';
import handleRequest from './../../../helper/requests';
import { fetchVouchers } from "../../../actions/vouchers/fetch_vouchers";

// style
import './ChangeVoucher.css';

class ChangeVoucher extends Component {
  constructor(props) {
    super(props);

    const actualVoucherCode = this.props.actualVoucherCode;
    const selectedValue = actualVoucherCode ? {
      label: actualVoucherCode,
      value: actualVoucherCode,
      voucher: actualVoucherCode
    } : null;

    this.state = {
      vouchers: selectedValue ? [selectedValue] : [],
      selectedValue: selectedValue
    };
  }

  parseVouchers = (voucher) => {
    return {
      label: voucher.code,
      value: voucher.code,
      voucher: voucher.code
    };
  };

  vouchersDisabled() {
    const { selectedValue } = this.state;
    const actualVoucherCode = this.props.actualVoucherCode;
    return (!selectedValue || actualVoucherCode === selectedValue.value);
  }

  onValueChange = (selectedValue) => {
    this.setState({ selectedValue });
  }

  changeVoucher = () => {
    const { voucher } = this.state.selectedValue;
    const { scheduleId, notify, updateCallback } = this.props;
    const actionCreator = () => updateCallback(scheduleId, { code: voucher });
    handleRequest(actionCreator, notify, 'Voucher trocado com sucesso');
  }

  onSearchChange = (codeToFilter) => {
    fetchVouchers(codeToFilter, true, false).then(({ data }) => {
      const vouchers = (data.vouchers || []).map(this.parseVouchers);
      this.setState({ vouchers });
    }).catch(error => {
      console.log(error);
      alert('Erro ao buscar vouchers');
    });
  };

  render() {
    return (
      <div>
        <div className="ChangeVoucher-row">
          <SimpleSelect className="ChangeVoucher-select"
            placeholder="Pesquise pelo nome do voucher..."
            onSearchChange={this.onSearchChange}
            options={this.state.vouchers}
            onValueChange={this.onValueChange}
            value={this.state.selectedValue}
          />
          <Button className="ChangeVoucher-btn"
            disabled={this.vouchersDisabled()}
            bsStyle="primary"
            onClick={this.changeVoucher}>{this.props.actualVoucherCode ? "Trocar Voucher" : "Inserir Voucher"}</Button>
        </div>
      </div>
    );
  }
}

ChangeVoucher.propTypes = {
  scheduleId: PropTypes.string,
  actualVoucherCode: PropTypes.string,
  notify: PropTypes.func,
  updateCallback: PropTypes.func
};

export default ChangeVoucher;
