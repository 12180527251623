import React from "react";

const PageHeader = props => {
  return (
    <div className="row">
      <div className="col-lg-12 col-centered text-center">
        <div className="section-title">{props.title}</div>
      </div>
    </div>
  );
};

export default PageHeader;
