import React, { Component, PropTypes } from 'react';

import './style.css';

// -- PROPS --
// selectOptions: Array default obrigatório de opções para o select; 
// onValueChange: Função obrigatória que retorna valor selecionado ou digitado
// disabled: Boolean default opcional para desabilitar o componente (default false);

// Essa versão permite apenas selects aonde é um array de strings onde o value e o texto do select sejam iguais.
class CreatableSelect extends Component {
  constructor(props){
    super(props);
    
    const { selectOptions } = this.props;

    this.state = {
      value: selectOptions[0] || '',
      mode: 'SELECT',
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    if(this.props !== nextProps) return false;

    return true;
  }

  componentDidUpdate() {
    const { value } = this.state;
    const { onValueChange } = this.props;

    onValueChange(this.state.value)
  }

  handleSelectChange = (event) => {
    const { onValueChange } = this.props;
    const val = event.target.value;

    if(val !== 'Other') {
      this.setState({ value: val });
      return;
    }

    this.setState({ mode: 'INPUT' });
    this.setState({ value: '', mode: 'INPUT' });
  }

  handleInputChange = (event) => {
    const { onValueChange } = this.props;
    const val = event.target.value;

    this.setState({ value: val })
  }

  handleCloseInput = () => {
    const { onValueChange, selectOptions } = this.props;

    this.setState({ mode: 'SELECT' });
    this.setState({ value: selectOptions[0] || '' });
  }

  render() {
    const { value, mode } = this.state;
    const { selectOptions, disabled } = this.props;

    if(mode === 'SELECT') {
      return (
        <select 
          disabled={disabled || false}
          className="Form-input"
          defaultValue={value}
          onChange={this.handleSelectChange}
        >
          {selectOptions.map(option => <option key={option} value={option}>{option}</option>)}
          <option value="Other">Outro</option>
        </select>
      )
    } else if(mode === 'INPUT') {
      return (
        <div className="creatable-select-input">
          <input 
            type="text"
            placeholder="Banco" 
            className="Form-input"
            value={value}
            onChange={this.handleInputChange}
            disabled={disabled || false}
          />
          <span 
            className="glyphicon glyphicon-remove" 
            onClick={this.handleCloseInput}></span>
        </div>
      )
    } else return null;
  }
}

CreatableSelect.PropTypes = {
  selectOptions: PropTypes.array.isRequired, 
  onValuChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool
}

export default CreatableSelect;