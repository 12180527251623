import { FETCH_AREAS } from './../actions/areas/fetch_areas';

const INITIAL_STATE = {
  all: []
};

const areasReducer = (state = INITIAL_STATE, action) => {
  if (action.error) {
    return state;
  }

  switch (action.type) {
  case FETCH_AREAS:
    return {
      ...state,
      all: action.payload.data
    };

  default:
    return state;
  }
};

export default areasReducer;
