import config from '../../../config.json';
const env = process.env.REACT_APP_ENVIRONMENT || 'stage';
const BACKEND_URL = config.api[env];
import axios from 'axios';

import { getToken } from './../../models/auth';

export const UPDATE_SCHEDULE_SERVICE = 'UPDATE_SCHEDULE_SERVICE';

export function updateScheduleService(scheduleId, areaId, serviceId, categoryId, service) {
  const updateServicePromise = axios.put(`${BACKEND_URL}/v2/bo/schedule/changeService`,
    { scheduleId, areaId, serviceId, categoryId },
    {
      headers: { Authorization: `Bearer ${getToken()}` }
    }
  );

  return {
    type: UPDATE_SCHEDULE_SERVICE,
    payload: updateServicePromise,
    meta: { service, id: scheduleId }
  };
}
