import React, { Component } from 'react';
import moment from "moment-timezone";
import "./Style.css"

class List extends Component {
  state = {
    currentPage: 1
  }
  
  handleClick = (event) => this.setState({ currentPage: Number(event.target.id) });
  
  render() {
    const commentsPerPage = 4;
    const { currentPage } = this.state;
    const { comments } = this.props;
    
    const indexOfLastTodo = currentPage * commentsPerPage;
    const indexOfFirstTodo = indexOfLastTodo - commentsPerPage;
    const currentComments = comments.slice(indexOfFirstTodo, indexOfLastTodo);
    
    // Logic for displaying page numbers
    const pageNumbers = [];
    for (let i = 1; i <= Math.ceil(comments.length / commentsPerPage); i++) {
      pageNumbers.push(i);
    }
    
    const renderPageNumbers = pageNumbers.map(number => {
      return (
        <li
          key={number}
          id={number}
          className={currentPage === number ? 'currentPage' : ''}
          onClick={this.handleClick}
        >
          {number}
        </li>
      );
    });
    return (
      <div className="comments-container">
        <h2 className="section-title">Comentários</h2>
        <div className="row">
          {comments.length > 0 ? currentComments.map((element, index) => {
            return (
              <div className="col-xs-6" key={index}>
                <div className="comment">
                  {element.createdBy ?
                    <p className="comment-user"><strong>Usuario:</strong> {element.createdBy.email}</p> : <p>N/A</p>}
                  {element.message ? <p className="comment-message"><strong>Messagem: </strong>{element.message}</p> :
                    <p>N/A</p>}
                  {element.createdAt ? <p className="comment-date">
                    <strong>Data:</strong>{moment.tz(element.createdAt, 'America/Sao_Paulo').format('DD/MM/YY HH:mm')}
                  </p> : <p>N/A</p>}
                </div>
              </div>
            );
          }) : <p className="text-center">Sem histórico de comentários</p>}
        </div>
        <div>
          <ul id="page-numbers" className="page-numbers">
            {renderPageNumbers}
          </ul>
        </div>
      </div>
    );
  }
}

export default List;