import React, { Component } from 'react'
import TextField from "../FormFields/TextField"
import DateField from "../FormFields/DateField"
import SelectField from "../FormFields/SelectField"
import moment from 'moment'
import { cacList, sectorList, serviceList } from './InvestimentMetaData'

import "./../../style/form.css"

class InvestimentEditForm extends Component{
    constructor(props){
        super(props)

        this.state = {
            value: null,
            date: null,
            category: null,
            description: null,
            cac: null,
            sector: null,
            service: null
        }
    }

    componentDidMount(){
        const { value, date, category, description, cac, sector, service } = this.props.investiment
        this.setState({ 
            value, 
            date: moment(date).format('yyyy-MM-DD'), 
            category, 
            description: description || '', 
            cac, 
            sector,
            service
        })
    }

    render(){
        return (
            <form onSubmit={this.handleOnSubmit}>
                <div className="Form-formGroup">
                    <div className="row">
                        <div className="col-sm-6">
                          <TextField
                            name="value"
                            label="Valor do Custo(R$) *"
                            number="true"
                            inputData={{
                                value: this.state.value,
                                onChange: value => {
                                    this.setState({ value })
                                    this.props.onChangeValue(value)
                                }
                            }}
                            placeholder="Insira aqui o valor do custo"
                          />
                        </div>

                        <div className="col-sm-6">
                            <DateField
                                name="date"
                                label="Data de Entrada *"
                                inputData={{
                                    value: this.state.date,
                                    onChange: date => {
                                        this.setState({ date })
                                        this.props.onChangeDate(date)
                                    }
                                }}
                            />
                        </div>

                        <div className="col-sm-6">
                          <TextField
                            name="description"
                            label="Descrição *"
                            required="true"
                            inputData={{
                                value: this.state.description,
                                onChange: description => {
                                    this.setState({ description })
                                    this.props.onChangeDescription(description)
                                }
                            }}
                            placeholder="Insira aqui a descrição do custo"
                          />
                        </div>

                        <div className="col-sm-6">
                          <TextField
                            name="catogory"
                            label="Categoria *"
                            inputData={{
                                value: this.state.category,
                                onChange: category => {
                                    this.setState({ category })
                                    this.props.onChangeCategory(category)
                                }
                            }}
                            placeholder="Insira aqui a categoria do custo"
                          />
                        </div>

                        <div className="col-sm-6">
                            <SelectField
                                name="cac"
                                label="Tipo de CAC *"
                                listValues={cacList}
                                inputData={{
                                    value: this.state.cac,
                                    onChange: cac => {
                                        this.setState({ cac })
                                        this.props.onChangeCac(cac)
                                    }
                                }}
                            />
                        </div>

                        <div className="col-sm-6">
                            <SelectField
                                name="sector"
                                label="Setor *"
                                listValues={sectorList}
                                inputData={{
                                    value: this.state.sector,
                                    onChange: sector => {
                                        this.setState({ sector })
                                        this.props.onChangeSetor(sector)
                                    }
                                }}
                            />
                        </div>

                        <div className="col-sm-6">
                            <SelectField
                                name="service"
                                label="Serviço *"
                                listValues={serviceList}
                                inputData={{
                                    value: this.state.service,
                                    onChange: service => {
                                        this.setState({ service })
                                        this.props.onChangeService(service)
                                    }
                                }}
                            />
                        </div>
                    </div>
                </div>
            </form>
        )
    }
}

export default InvestimentEditForm