"use strict";

import React, { Component } from "react";

export default class EditPackageVoucher extends Component {
    constructor(props) {
        super(props);
        this.state = {
            pack: props.pack
        }
    }
    handleFormEdit(evt) {
        const { value, name } = evt.target;
        const { pack } = this.state;
        pack[name] = value;
        this.setState({ pack });
        if (name === 'originalAmount' || name === 'totalAmount' || name === 'count') {
            pack[name] = parseFloat(pack[name])
        }
    }

    render() {
        return (
            <div style={{ marginTop: 25, marginLeft: 50 }}>
                <div className="row">
                    <div className="col-xs-2 form-group">
                        <label>Valor do voucher {this.state.pack.valueType === 'P' ? 'em %' : 'em R$'}</label>
                        <input
                            className="form-control form-control-normal"
                            name={"originalAmount"}
                            tabIndex={5}
                            value={this.state.pack.originalAmount}
                            onChange={this.handleFormEdit.bind(this)}
                        />
                    </div>
                    <div className="col-xs-2 form-group">
                        <label>Quantidade</label>
                        <input
                            className="form-control form-control-normal"
                            type="number"
                            name={"count"}
                            tabIndex={1}
                            value={this.state.pack.count}
                            onChange={this.handleFormEdit.bind(this)}
                        />
                    </div>
                    <div className="col-xs-3 form-group">
                        <label>Valor a ser mostrado no App</label>
                        <input
                            className="form-control form-control-normal"
                            name={"totalAmount"}
                            tabIndex={1}
                            value={this.state.pack.totalAmount}
                            onChange={this.handleFormEdit.bind(this)}
                        />
                    </div>
                </div>
                <div className="col-xs-3 col-xs-offset-9" style={{ marginTop: 25 }}>
                    <p className="pack-total">
                        Total de voucher: {this.state.pack.valueType === 'P' ?
                            <text>{parseFloat(this.state.pack.originalAmount) || 0} %</text>
                            : <text>R$ {parseFloat(this.state.pack.originalAmount).toFixed(2) || 0}</text>}
                    </p>
                    <p>
                        Valor a pagar: R${" "}
                        {parseFloat(this.state.pack.totalAmount).toFixed(2) || 0}
                    </p>
                </div>
                <div className="clearfix" />
                <hr />
            </div>
        )
    }
}