import React, { Component } from "react"
import Griddle from "griddle-react";
import { Link } from 'react-router'
import { Button, Modal } from "react-bootstrap";

import AreaDetail from "./Details"

import './DetailsStyles.css';

export default class AreasGriddle extends Component {
  constructor() {
    super()
    this.state = {
      showDetails: false,
      area: {}
    }
  }
  openDetailsModal = (area) => {
    this.setState({ area, showDetails: true });
  }
  render() {
    const { areas } = this.props
    return (
      <div className="row">
        <Griddle
          filterPlaceholderText={"Filtrar Resultados"}
          noDataMessage={"Não há dados para serem exibidos"}
          columns={["_id", "name", "state", "active", "actions"]}
          results={areas || []}
          showFilter={true}
          showSettings={false}
          useFixedLayout={false}
          columnMetadata={[
            {
              columnName: "_id",
              displayName: "ID"
            },
            {
              columnName: "name",
              displayName: "Cidade",
              customComponent: ({ rowData }) => {
                return <p>{rowData.city}</p>
              }
            },
            {
              columnName: "state",
              displayName: "Estado",
              customComponent: ({ rowData }) => {
                return <p>{rowData.stateShort}</p>
              }
            },
            {
              columnName: "active",
              displayName: "Ativo",
              customComponent: ({ rowData }) => {
                return <p>{rowData.active ? "Ativa" : "Inativa"}</p>
              }
            },
            {
              columnName: "actions",
              displayName: "Ações",
              customComponent: ({ rowData }) => {
                return (
                  <div style={{ display: "flex" }}>
                    <Link to={`/area/edit/${rowData._id}`}>
                      <Button
                        className="ScheduleActions-btn btn btn-info"
                        title="Editar Cidade">
                        Editar
                      </Button>
                    </Link>
                  </div>
                )
              }
            },
          ]}
          tableClassName={"GriddleTable table"}
          useGriddleStyles={false}
          settingsToggleClassName={"btn btn-default"}
        />
        <Modal bsSize="large" show={this.state.showDetails} onHide={() => this.setState({ showDetails: false, area: {} })}>
          <Modal.Header closeButton>
            <Modal.Title>Detalhes da Cidade - {this.state.area.city}/{this.state.area.stateShort}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <AreaDetail area={this.state.area} />
          </Modal.Body>
        </Modal>
      </div>
    )
  }
}