import axios from 'axios';
import config from '../../../config.json';
const env = process.env.REACT_APP_ENVIRONMENT || 'stage';
const BACKEND_URL = config.api[env];
import { getToken } from './../../models/auth';

export const FETCH_ARTISTS = 'FETCH_ARTISTS';

export function fetchArtists(page, filter, itemsPerPage) {
  let params = {
    itemsPerPage: itemsPerPage || 5,
    page: page,
    filter
  };

  const artistsPromise = axios.get(`${BACKEND_URL}/v2/bo/artists`, {
    headers: { Authorization: `Bearer ${getToken()}` },
    params
  });

  return {
    type: FETCH_ARTISTS,
    payload: artistsPromise
  };
}
