import React, { Component } from "react";
import PropTypes from "prop-types";
import { fetchAreasService } from "../../actions/areas/fetch_areas";
import Loading from "../Global/Loading/Loading";
import Accordion from "../FormFields/Accordion";

class Areas extends Component {
  constructor(props) {
    super(props)
    this.state = {
      areas: [],
      categories: [],
      selectedAreas: props.defaultAreas || [],
      selectedServices: props.defaultServices || [],
    }
  }

  componentDidMount() {
    fetchAreasService().then(({ data }) => {
      this.setState({ areas: data }, this.parseCategoriesToRender)
    });
  }


  parseCategoriesToRender = () => {
    const parsedCategories = {};
    const { areas } = this.state;
    areas.forEach(area => {
      const { categories } = area;
      categories.forEach(category => {
        if (!parsedCategories[category._id]) {
          parsedCategories[category._id] = {
            _id: category._id,
            name: category.name["pt-BR"],
            services: {}
          }
        }
        const { services } = category;
        services.forEach(service => {
          if (!parsedCategories[category._id].services[service._id]) {
            parsedCategories[category._id].services[service._id] = {
              _id: service._id,
              name: service.name["pt-BR"]
            }
          }
        });
      });
    });
    console.log(parsedCategories);
    this.setState({ categories: parsedCategories });
  }

  selectArea = (event) => {
    const { value } = event.target;
    let { selectedAreas } = this.state;
    if (selectedAreas.includes(value)) selectedAreas = selectedAreas.filter(item => value !== item);
    else selectedAreas.push(value);
    this.setState({ selectedAreas });
    this.props.setAreas && this.props.setAreas(selectedAreas);
  }

  selectService = (event) => {
    const { value } = event.target;
    let { selectedServices } = this.state;
    if (selectedServices.includes(value)) selectedServices = selectedServices.filter(item => value !== item);
    else selectedServices.push(value);
    this.setState({ selectedServices });
    this.props.setServices && this.props.setServices(selectedServices);
  }

  renderAreas() {
    const { areas } = this.state;
    return areas.map((area, i) => {
      return (
        <div className={`Form-choice ${this.state.selectedAreas.includes(area._id) ? "is-selected" : ""}`} key={i}>
          <label htmlFor={`${area._id}-${i}`}
            className="Form-choiceLabel"
            key={i}
          >
            <input type="checkbox"
              className="Form-checkbox"
              name={area._id}
              value={area._id}
              id={`${area._id}-${i}`}
              onChange={this.selectArea}
              ref={`checkboxInput-${i}`}
            />
            <div className="Form-fakeCheckbox">
              <div className="ss-icon ss-check"></div>
            </div>
            {area.name["pt-BR"]}
          </label>
        </div>
      )
    });
  }

  renderRenderServices() {
    const { categories } = this.state;

    return Object.keys(categories).map(categoryId => {
      const category = categories[categoryId];
      const { services } = category;
      return (
        <Accordion
          title={category.name}
          key={category.name}
          opened={this.props.opened || false}
        >
          {
            Object.keys(services).map((serviceId, indexService) => {
              const service = services[serviceId]
              return (
                <div className={`Form-choice ${this.state.selectedServices.includes(service._id) ? "is-selected" : ""}`} key={indexService}>
                  <label htmlFor={`${service._id}-${indexService}`}
                    className="Form-choiceLabel"
                    key={indexService}
                  >
                    <input type="checkbox"
                      className="Form-checkbox"
                      name={service._id}
                      value={service._id}
                      id={`${service._id}-${indexService}`}
                      onChange={this.selectService}
                      ref={`checkboxInput-${indexService}`}
                    />
                    <div className="Form-fakeCheckbox">
                      <div className="ss-icon ss-check"></div>
                    </div>
                    {service.name}
                  </label>
                </div>
              )
            })
          }
        </Accordion>
      )
    })

  }

  render() {
    const { areas } = this.state
    if (areas && areas.length > 0) {
      return (
        <div className="row">
          <div className="col-xs-12">
            <h3>Cidades disponíveis: </h3>
            {this.renderAreas()}
          </div>
          <div className="col-xs-12">
            <h3>Serviços disponíveis: </h3>
            {this.renderRenderServices()}
          </div>
        </div>
      )
    } else {
      return (
        <div>
          <h1>Carregando areas</h1>
          <Loading />
        </div>
      )
    }
  }
}

Areas.propTypes = {
  defaultAreas: PropTypes.array,
  defaultServices: PropTypes.array,
  setAreas: PropTypes.func,
  setServices: PropTypes.func
};

export default Areas;