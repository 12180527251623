import config from '../../../config.json';
const env = process.env.REACT_APP_ENVIRONMENT || 'stage';
const BACKEND_URL = config.api[env];
import axios from 'axios';

import { getToken } from './../../models/auth';

export const FETCH_SCHEDULES = 'FETCH_SCHEDULES';

export function fetch_userSchedules(id, page, orderBy, orderType, filter, minDate, maxDate, status, area, shortCode, onlyPreference) {
  const params = {
    itemsPerPage: 10,
    page,
    filter,
    minDate,
    maxDate
  };

  if (status && status !== 0) {
    params.status = status;
  }

  if (onlyPreference) {
    params.onlyPreference = true
  }

  if (area) {
    params.areaId = area;
  }

  if (shortCode) {
    params.shortCode = shortCode.trim().toUpperCase();
  }

  if (orderBy && orderType) {
    params.orderBy = orderBy;
    params.orderType = orderType;
  }

  const schedulesPromise = axios.get(`${BACKEND_URL}/v2/bo/schedules?user._id=${id}`, {
    headers: { Authorization: `Bearer ${getToken()}` },
    params
  });

  return {
    type: FETCH_SCHEDULES,
    payload: schedulesPromise
  };
}
