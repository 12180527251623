import config from '../../../config.json';
const env = process.env.REACT_APP_ENVIRONMENT || 'stage';
const BACKEND_URL = config.api[env];
import axios from 'axios';

import { getToken } from './../../models/auth';

export const UPDATE_SCHEDULE_TAKE_RATE = 'UPDATE_SCHEDULE_TAKE_RATE';

export function updateScheduleTakeRate(scheduleId, takeRate) {
  const { expiration, value } = takeRate;

  const updateTakeRatePromise = axios.put(`${BACKEND_URL}/v2/bo/schedule/changeTakeRate`,
    { scheduleId, expiration, value: parseInt(value) },
    {
      headers: { Authorization: `Bearer ${getToken()}` }
    }
  );

  return {
    type: UPDATE_SCHEDULE_TAKE_RATE,
    payload: updateTakeRatePromise,
    meta: { takeRate, id: scheduleId }
  };

}