import React, { Component } from 'react';
import { Button, Modal } from 'react-bootstrap';
import Griddle from "griddle-react";
import "./TransfersGriddle.css";
import Loading from '../Global/Loading/Loading';
import moment from "moment";
import { Toast } from '../../helper/alerts';
import bonus from "../../helper/bonus";
import { updateStatustransfers } from '../../actions/transfers/update_status_transfers';

class ListTransfer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      currentPage: 1,
      transfers: [],
      itensPerPage: 10,
      professional: null,
      status: null,
      createAt: null,
      orderBy: null,
      isOpenChangeStatusModalIndividual: false,
      isOpenChangeStatusModalCollective: false,
      rowData: [],
      isApproval: null,
      selectedItems: [],
      selectAll: false,
      comment: '',
    };
  }
  statusBonus = {
    1: "Pendente head de operações",
    2: "Pendente head financeiro",
    3: "Aprovado",
    4: "Recusado pelo head de operações",
    5: "Recusado pelo head financeiro",
  }

  reasons = bonus.REASONS;

  getStatusOptions() {
    return Object.keys(this.statusBonus).map(key => (
      <option key={key} value={key}>{this.statusBonus[key]}</option>
    ));
  }

  openChangeStatusModal(rowData, isApproval) {
    this.setState({
      isOpenChangeStatusModalIndividual: true,
      rowData: rowData,
      isApproval: isApproval
    });
  }

  closeChangeStatusModal() {
    this.setState({
      isOpenChangeStatusModalIndividual: false,
      rowData: [],
      isApproval: null,
    });
  }

  openChangeStatusModalCollective(isApproval) {
    const { selectedItems } = this.state;

    if (selectedItems.length === 0) {
      Toast().fire({
        icon: 'error',
        title: 'Selecione os itens',
      });
      return;
    }

    this.setState({
      isOpenChangeStatusModalCollective: true,
      isApproval: isApproval
    });
  }

  closeChangeStatusModalCollective() {
    this.setState({
      isOpenChangeStatusModalCollective: false,
      selectedItems: [],
      isApproval: null,
    });
  }

  handleSelectAll() {
    const allItems = this.props.transfers.map((item) => item._id);
    console.log(allItems);

    this.setState((prevState) => ({
      selectAll: !prevState.selectAll,
      selectedItems: prevState.selectAll ? [] : allItems,
    }));
  }

  handleCheckboxChange(itemId) {
    this.setState((prevState) => {
      const { selectedItems } = prevState;

      if (selectedItems.includes(itemId)) {
        return {
          selectedItems: selectedItems.filter((id) => id !== itemId),
        };
      } else {
        return {
          selectedItems: [...selectedItems, itemId],
        };
      }
    });
  }

  ApprovalItemIndividual(item, status, comment) {
    updateStatustransfers([item], status, comment)
      .then(() => {
        Toast().fire({
          icon: 'success',
          title: 'Bônus atualizado com sucesso!',
        });
        window.location.reload();
      }).catch(error => {
        console.log(error);
        Toast().fire({
          icon: 'error',
          title: 'Erro ao atualizar bônus',
        });
      });
  }

  ApprovalItemICollective(itens, status, comment) {
    updateStatustransfers(itens, status, comment)
      .then(() => {
        Toast().fire({
          icon: 'success',
          title: 'Bônus selecionados atualizados com sucesso!',
        });
        window.location.reload();
      }).catch(error => {
        console.log(error);
        Toast().fire({
          icon: 'error',
          title: 'Erro ao atualizar bônus',
        });
      });

  }

  search = (professional, status, others) => {
    this.props.handlerSearch(professional, status, others);
  };

  render() {
    return (
      <div className="transfers">
        <div className="row">
          <center className="Filter">
            <b style={{ marginRight: 10 }}>PROFISSIONAL:</b>{" "}
            <input
              value={this.state.professional}
              onChange={evt => this.setState({ professional: evt.target.value })}
              style={{ width: "150px" }}
              placeholder="Ex: ANA"
              className="form-control"
            />
            <b style={{ marginLeft: 15, marginRight: 10 }}>STATUS:</b>
            <select
              onChange={evt => this.setState({ status: evt.target.value })}
              className="form-control"
            >
              <option value="0"> Selecione</option>
              {this.getStatusOptions()}
            </select>
            <b style={{ marginLeft: 15, marginRight: 10 }}>
              DATA:
            </b>{" "}
            <input
              name="minDate"
              onChange={evt => this.setState({ createAt: evt.target.value })}
              type="date"
              style={{ width: "300px" }}
              value={this.state.createAt}
              className="form-control"
            />
            <button
              className="btn-info  btn btn-default"
              style={{ marginLeft: 15 }}
              onClick={() => this.search(this.state.professional, this.state.status, this.state.createAt)}
            >
              <b>BUSCAR</b>
            </button>
          </center>
          <center className="Filter">
            <button
              className="btn-success  btn btn-default"
              style={{ marginRight: 15 }}
              onClick={() => this.openChangeStatusModalCollective(true)}
            >
              <b>APROVAR SELECIONADOS</b>
            </button>
            <button
              className="btn-danger  btn btn-default"

              onClick={() => this.openChangeStatusModalCollective(false)}
            >
              <b>REPROVAR SELECIONADOS</b>
            </button>
          </center>
          {this.props.loading ? (
            <Loading />
          ) : (
            <Griddle
              ref="Griddle"
              useExternal={true}
              noDataMessage={'Não há dados para serem exibidos'}
              results={this.props.transfers}
              columnMetadata={[
                {
                  columnName: 'selectTransfer',
                  displayName: (
                    <input
                      type="checkbox"
                      checked={this.state.selectAll}
                      onChange={() => this.handleSelectAll()}
                    />
                  ),
                  customHeaderComponent: ({ column }) => (
                    <div>
                      <span>{column.displayName}</span>
                    </div>
                  ),
                  customComponent: ({ rowData }) => {
                    return (
                      <input
                        type="checkbox"
                        name="aprovallCheck"
                        checked={this.state.selectedItems.includes(rowData._id)}
                        onChange={() => this.handleCheckboxChange(rowData._id)}
                      />
                    )
                  }
                },
                {
                  columnName: 'professionalName',
                  displayName: 'Profissional',
                },
                {
                  columnName: 'value',
                  displayName: 'Valor',
                  customComponent: ({ rowData }) => {
                    return (<p>R$ {parseFloat(rowData.value / 100).toFixed(2)}</p>)
                  }
                },
                {
                  columnName: 'description',
                  displayName: 'Descrição',
                  customComponent: ({ rowData }) => {
                    const maxDescriptionLength = 100;

                    let description = rowData.description || '';
                    if (description.length > maxDescriptionLength) {
                      description = description.substring(0, maxDescriptionLength) + '...';
                    }

                    return (<p>{description}</p>);
                  },
                },
                {
                  columnName: 'responsible',
                  displayName: 'Responsável',
                  customComponent: ({ rowData }) => {
                    return (<p>{rowData?.responsible?.name || rowData?.responsible?.email}</p>);
                  }
                },
                {
                  columnName: 'reason',
                  displayName: 'Objetivo',
                  customComponent: ({ rowData }) => {
                    if (rowData.reason === "23" && rowData.reasonText) {
                      return (
                        <p>{rowData.reasonText}</p>
                      );
                    }

                    const reason = this.reasons.find(item => item.id === parseInt(rowData.reason));
                    return (
                      <p>{reason ? reason.description : 'Objetivo não encontrada'}</p>
                    );
                  }
                },
                {
                  columnName: 'approval',
                  displayName: 'Status',
                  customComponent: ({ rowData }) => {
                    return (<p>{rowData.approval ? this.statusBonus[rowData.approval.status] : 'N/A'}</p>);
                  }
                },
                {
                  columnName: 'createdAt',
                  displayName: 'Data',
                  customComponent: ({ rowData }) => {
                    return (<p>{moment(rowData.createdAt).tz('America/Sao_Paulo').format('DD/MM/YY HH:mm')}</p>);
                  },
                  cssClassName: 'Transfers-status'
                },
                {
                  columnName: 'actions',
                  displayName: 'Ação',
                  customComponent: ({ rowData }) => {
                    return (
                      <div className="TransfersActions">
                        <div className="TransfersActions-btnWrapper">
                          <Button onClick={() => this.openChangeStatusModal(rowData, false)}
                            className="TransfersActions-btn btn btn-info"
                            title="Reprovar"
                          >
                            <span className="glyphicon glyphicon-remove"></span>
                          </Button>
                          <Button onClick={() => this.openChangeStatusModal(rowData, true)}
                            className="TransfersActions-btn btn btn-info"
                            title="Aprovar"
                          >
                            <span className="glyphicon glyphicon-ok"></span>
                          </Button>
                        </div>
                      </div>
                    )
                  }
                }
              ]}
              resultsPerPage={10}
              tableClassName={'GriddleTable table'}
              externalSetPage={this.props.setPage}
              useFixedLayout={false}
              useGriddleStyles={false}
              columns={[
                "selectTransfer",
                "professionalName",
                "value",
                "description",
                "responsible",
                "reason",
                "approval",
                "createdAt",
                "actions"
              ]}
              externalSetFilter={() => { }}
              externalSetPageSize={15}
              externalChangeSort={this.sort}
              externalCurrentPage={this.props.currentPage - 1}
              externalMaxPage={Math.ceil(this.props.numberOfPages / 10)}
            />
          )}
        </div>
        <div>
          {/* Modal Ação Aprovar/Reprovar Individual */}
          <Modal
            show={this.state.isOpenChangeStatusModalIndividual}
            onHide={() => this.closeChangeStatusModal()}
          >
            <Modal.Header closeButton>
              <Modal.Title>{this.state.isApproval ? 'Aprovar' : 'Reprovar'} Bônus</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="row">
                <div className="col-md-12">
                  <div className="comment">
                    <p style={{ textAlign: 'center' }}>Informações do Bônus </p>
                    <p>
                      <b>Bônus: </b>
                      {this.state.rowData._id}
                    </p>
                    <p>
                      <b>Responsável: </b>
                      {this.state.rowData?.responsible?.name || this.state.rowData?.responsible?.email}
                    </p>
                    <p>
                      <b>Objetivo: </b>
                      {
                        this.state.rowData.reason === "23" && this.state.rowData.reasonText
                          ? this.state.rowData.reasonText
                          : this.reasons.find(item => item.id === parseInt(this.state.rowData.reason))?.description || 'Objetivo não encontrada'
                      }
                    </p>
                    <p>
                      <b>Valor: </b>
                      R$ {parseFloat(this.state.rowData.value / 100).toFixed(2)}
                    </p>
                  </div>
                  <br />
                  <p>
                    <label htmlFor="comment">Comentário</label>
                    <textarea
                      type="text"
                      className="Form-input"
                      name='comment'
                      rows={4}
                      value={this.state.comment}
                      onChange={(evt) => this.setState({ ...this.state, comment: evt.target.value })}
                    />
                  </p>
                </div>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={() => this.closeChangeStatusModal()}>
                Fechar
              </Button>
              {this.state.isApproval ? (
                <Button
                  variant="primary"
                  className="btn btn-success"
                  onClick={() => this.ApprovalItemIndividual(this.state.rowData._id, true, this.state.comment)}>
                  Aprovar
                </Button>
              ) : (
                <Button
                  variant="secondary"
                  className="btn btn-danger"
                  onClick={() => this.ApprovalItemIndividual(this.state.rowData._id, false, this.state.comment)}>
                  Reprovar
                </Button>
              )}
            </Modal.Footer>
          </Modal>
        </div>
        {/* Modal Ação Aprovar/Reprovar coletivo */}
        <div>
          <Modal
            show={this.state.isOpenChangeStatusModalCollective}
            onHide={() => this.closeChangeStatusModal()}
          >
            <Modal.Header closeButton>
              <Modal.Title>{this.state.isApproval ? 'Aprovar' : 'Reprovar'} Bônus</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="row">
                <div className="col-md-12">
                  <div className="comment">
                    <p style={{ textAlign: 'center' }}>Informações dos Bônus Selecionados</p>
                    {this.state.selectedItems.map((itemId) => {
                      const selectedItem = this.props.transfers.find((item) => item._id === itemId);
                      return (
                        <div key={itemId}>
                          <p>
                            <b>Bônus: </b>
                            {selectedItem._id}
                          </p>
                        </div>
                      );
                    })}
                  </div>
                  <p>
                    <label htmlFor="comment">Comentário</label>
                    <textarea
                      type="text"
                      className="Form-input"
                      name='comment'
                      rows={4}
                      value={this.state.comment}
                      onChange={(evt) => this.setState({ ...this.state, comment: evt.target.value })}
                    />
                  </p>
                </div>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={() => this.closeChangeStatusModalCollective()}>
                Fechar
              </Button>
              {this.state.isApproval ? (
                <Button
                  variant="primary"
                  className="btn btn-success"
                  onClick={() => this.ApprovalItemICollective(this.state.selectedItems, true, this.state.comment)}>
                  Aprovar
                </Button>
              ) : (
                <Button
                  variant="secondary"
                  className="btn btn-danger"
                  onClick={() => this.ApprovalItemICollective(this.state.selectedItems, false, this.state.comment)}>
                  Reprovar
                </Button>
              )}
            </Modal.Footer>
          </Modal>
        </div>
      </div>
    );
  }
}

export default ListTransfer;
