import axios from 'axios';

export const UPLOAD_PARTNER_IMAGE = 'UPLOAD_PARTNER_IMAGE';

export function uploadPartnerImage(signedUrl, file, onProgress) {
  var instance = axios.create();
  const splitedName = file.name.split(".");
  const options = {
    progress: onProgress,
    headers: {
      'Content-Type': splitedName[splitedName.length - 1]
    }
  };

  const uploadPromise = instance.put(signedUrl, file, options);

  return {
    type: UPLOAD_PARTNER_IMAGE,
    payload: uploadPromise
  };
}
