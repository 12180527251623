import axios from 'axios';
import { getToken } from './../../models/auth';

import config from '../../../config.json';
const env = process.env.REACT_APP_ENVIRONMENT || 'stage';
const BACKEND_URL = config.api[env];

export const FETCH_ZONES = 'FETCH_ZONES';

export function fetchZones(params = {}) {
    return {
        type: FETCH_ZONES,
        payload: axios.get(`${BACKEND_URL}/v2/prices/zone/search`, {
            params,
            headers: {
                Authorization: `Bearer ${getToken()}`
            }
        })
    }
}