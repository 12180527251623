import React, { Component } from "react";
import { Row, Col, Button } from "react-bootstrap";
import ReactQuill from "react-quill";
import UploadField from "../FormFields/SimpleUploadField";
import { getUrlSigned, uploadMGMImg } from "../../actions/areas/mgm";

import "react-quill/dist/quill.snow.css";

export default class EditMGM extends Component {
  state = {
    step1: "",
    step2: "",
    step3: "",
  };

  modules = {
    toolbar: [
      ["bold", "italic", "underline", "strike"],
      [{ color: ["#FF7A66", "red", "black", "blue", "green", "white"] }],
      ["clean"],
    ],
  };

  formats = ["strike", "bold", "italic", "underline", "color"];

  handleFormSubmit = (event) => {
    event.preventDefault();
    this.props.saveMGM();
  };

  handleChangeInputPhoto = (file, onProgress) => {
    const name = file.name.split(" ")[0];
    return getUrlSigned(file, name).then(({ data }) => {
      return uploadMGMImg(data, file, onProgress, name).then((img) => {
        this.props.changeValue({
          target: {
            name: "imageURL",
            value: `https://singu.s3.sa-east-1.amazonaws.com/mgm/${name}`,
          },
        });
      });
    });
  };

  render() {
    const {
      title,
      subtitle,
      shareText,
      imageURL,
      discount,
      credits,
      stepText,
    } = this.props.mgm;
    const { savingMGM } = this.props;

    return (
      <Col className="container">
        <form onSubmit={this.handleFormSubmit}>
          <Row>
            <Col md={12}>
              <h3 style={{ marginBottom: 16 }}>
                <b>Edição de MGM</b>
              </h3>
            </Col>
            <Col sm={12}>
              <div className="form-group">
                <label htmlFor="title">Título</label>
                <textarea
                  type="text"
                  className="Form-input"
                  onChange={this.props.changeValue}
                  value={title}
                  id="title"
                  name="title"
                  placeholder="Título"
                  required
                />
              </div>
            </Col>
            <Col
              sm={12}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <UploadField
                name="img"
                label="Upload da imagem do MGM - Recomendamos uma imagem 170x70px"
                inputData={imageURL}
                disabled={false}
                uploadCallback={this.handleChangeInputPhoto}
                type="image"
                crop={false}
                accept={".png, .jpeg, .jpg"}
                width={170}
                height={70}
              />
            </Col>
            <Col sm={12}>
              <div className="form-group">
                <label htmlFor="title">Subtitulo</label>
                <textarea
                  type="text"
                  className="Form-input"
                  onChange={this.props.changeValue}
                  value={subtitle}
                  id="subtitle"
                  name="subtitle"
                  placeholder="Título"
                  required
                />
              </div>
            </Col>
            <Col sm={12}>
              <h3 style={{ marginBottom: 24, marginTop: 8 }}>Etapas</h3>
            </Col>
            <Col sm={12}>
              <div className="form-group">
                <label htmlFor="text">Etapa 1</label>
                <ReactQuill
                  theme="snow"
                  modules={this.modules}
                  formats={this.formats}
                  defaultValue={stepText[0]}
                  onChange={(value) => {
                    let newStepText = stepText;
                    newStepText[0] = value;
                    this.props.changeValue({
                      target: { name: "stepText", value: newStepText },
                    });
                  }}
                />
              </div>
            </Col>
            <Col sm={12}>
              <div className="form-group">
                <label htmlFor="shareText">Etapa 2</label>
                <ReactQuill
                  theme="snow"
                  modules={this.modules}
                  formats={this.formats}
                  defaultValue={stepText[1]}
                  onChange={(value) => {
                    let newStepText = stepText;
                    newStepText[1] = value;
                    this.props.changeValue({
                      target: { name: "stepText", value: newStepText },
                    });
                  }}
                />
              </div>
            </Col>
            <Col sm={12} style={{ marginBottom: 32 }}>
              <div className="form-group">
                <label htmlFor="shareText">Etapa 3</label>
                <ReactQuill
                  theme="snow"
                  modules={this.modules}
                  formats={this.formats}
                  defaultValue={stepText[2]}
                  onChange={(value) => {
                    let newStepText = stepText;
                    newStepText[2] = value;
                    this.props.changeValue({
                      target: { name: "stepText", value: newStepText },
                    });
                  }}
                />
              </div>
            </Col>
            <Col sm={12}>
              <div className="form-group">
                <label htmlFor="shareText">
                  Texto de compartilhamento -{" "}
                  <span style={{color: 'red', fontSize: 13}}>
                    O texto aparece na tela MGM e no Popup da tela inicial
                  </span>
                </label>
                <p>
                  <small>
                    Para modificar o texto atente-se de que é necessário
                    adicionar "XXXX" para que o sistema entenda a troca dos
                    caracteres pelo código do voucher dos clientes
                  </small>
                </p>
                <textarea
                  type="text"
                  className="Form-input"
                  onChange={this.props.changeValue}
                  value={shareText}
                  id="shareText"
                  name="shareText"
                  placeholder="Compartilhe seu voucher XXXX e ganhe R$ REAIS quando o primeiro pedido for finalizado..."
                  required
                />
              </div>
            </Col>
            <Col sm={6}>
              <div className="form-group">
                <label htmlFor="discount">Desconto</label>
                <input
                  type="number"
                  min={0}
                  step=".01"
                  className="Form-input"
                  onChange={this.props.changeValue}
                  value={discount}
                  id="discount"
                  name="discount"
                  placeholder="Desconto"
                  required
                />
              </div>
            </Col>
            <Col sm={6}>
              <div className="form-group">
                <label htmlFor="credits">
                  Créditos -{" "}
                  <span style={{color: 'red', fontSize: 13}}>
                    Os créditos aparecem na tela MGM e no Popup da tela inicial
                  </span>
                </label>
                <input
                  type="number"
                  min={0}
                  step=".01"
                  className="Form-input"
                  onChange={this.props.changeValue}
                  value={credits}
                  id="credits"
                  name="credits"
                  placeholder="Créditos"
                  required
                />
              </div>
            </Col>
            <Col className="text-right" sm={12} style={{ marginBottom: 32 }}>
              {savingMGM === "LOADING" ? (
                <Button
                  type="submit"
                  bsStyle="info"
                  className="button-icon"
                  bsSize="large"
                  disabled={true}
                >
                  <span className="spinner glyphicon glyphicon-refresh"></span>
                  Salvando...
                </Button>
              ) : (
                <Button type="submit" bsStyle="info" bsSize="large">
                  Salvar
                </Button>
              )}
            </Col>
          </Row>
        </form>
      </Col>
    );
  }
}
