import axios from 'axios';
import config from '../../../config.json';
const env = process.env.REACT_APP_ENVIRONMENT || 'stage';
const BACKEND_URL = config.api[env];

import { getToken } from "../../models/auth";

export const SAVE_REALTIME_DYNAMIC_PRICES = "SAVE_REALTIME_DYNAMIC_PRICES";

export function saveRealtimeDynamicPrices(serviceId, params) {
  return {
    type: SAVE_REALTIME_DYNAMIC_PRICES,
    payload: axios.put(`${BACKEND_URL}/dynamic_price/realtime/${serviceId}`, params, {
      headers: {
        Authorization: `Bearer ${getToken()}`
      }
    })
  };
}
