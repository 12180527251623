import config from '../../../config.json';
const env = process.env.REACT_APP_ENVIRONMENT || 'stage';
const NODE_API_URL = config.nodeAPI[env];
import axios from 'axios';

export function getVoucher(code, areaId, token) {
  return axios.get(`${NODE_API_URL}/v2/voucher?code=${code}&areaId=${areaId}`, {
    headers: { Authorization: `Bearer ${token}` },
  });
}
