import React, { Component } from "react";

import InputField from "./InputField";
import services from "./services";
require("./style.css");

class Zones extends Component {
  constructor(props) {
    super(props);
    const realtimeServices = {};
    Object.keys(services).forEach(index => {
      realtimeServices[index] = {
        value: 0,
        expiration: "",
        loading: false
      };
    });
    this.state = {
      services: realtimeServices
    };
  }

  onSave = evt => {
    const serviceId = evt.target.dataset.serviceid;
    const { value, expiration } = this.state.services[serviceId];
    const services = this.state.services;
    if (value === undefined || value === null) {
      alert("Valor do preço dinâmico não deve ser vazio.");
      return;
    }
    if (!expiration) {
      alert("Selecione a data de expiração do preço dinâmico");
      return;
    }
    services[serviceId].loading = true;
    this.setState({ services });
    this.props
      .savePrices(serviceId, { value, expiration })
      .then(() => {
        alert("Preço dinâmico salvo com sucesso.");
        services[serviceId].loading = false;
        this.setState({ services });
      })
      .catch(error => {
        let message = "Houve um erro ao salvar preços dinâmicos";
        if (error && error.data && error.data.error && error.data.error.description) {
          message = error.data.error.description;
        }
        services[serviceId].loading = false;
        this.setState({ services });
        alert(message);
      });
  };

  setValue = evt => {
    const { services } = this.state;
    services[evt.target.dataset.serviceid].value = evt.target.value;
    this.setState({ services });
  };

  setDate = (serviceId, momentDate) => {
    const { services } = this.state;
    services[serviceId].expiration = momentDate.format();
    this.setState({ services });
  };

  componentWillReceiveProps(newProps) {
    const { multipliers } = newProps;
    if (multipliers && multipliers.length > 0) {
      const { services } = this.state;
      Object.keys(multipliers).forEach(index => {
        if (!services[multipliers[index]._id] || !multipliers[index].realtimeMultiplier) return;
        services[multipliers[index]._id].value = multipliers[index].realtimeMultiplier.value * 100;
        services[multipliers[index]._id].expiration = multipliers[index].realtimeMultiplier.expiration;
      });
      this.setState({ services });
    }
  }

  render() {
    return (
      <div className={"col-xs-12"}>
        <div className={"col-xs-12"}>
          <div className="col-xs-12">
            <h3>Alterar todos os preços dinâmicos: </h3>
            <p>
              <small>Todos os preços dinâmicos de todas as regiões serão alterados por esta funcionalidade</small>
            </p>
          </div>
          <div className="col-xs-12">
            <div className="col-xs-12">
              {Object.keys(services).map(index => {
                return (
                  <InputField
                    key={index}
                    onSave={this.onSave}
                    onChange={this.setValue}
                    serviceId={index}
                    serviceName={`${services[index].category} - ${services[index].name}`}
                    value={this.state.services[index].value}
                    expiration={this.state.services[index].expiration}
                    onChangeDate={this.setDate.bind(this, index)}
                    loading={this.state.services[index].loading}
                  />
                );
              })}
            </div>
          </div>

          <hr />
          {/* <small>
            Caso haja necessidade de especificidade entrar em <br />
            "Área de atuação" e alterar o preço para os locais específicos
          </small> */}
        </div>
      </div>
    );
  }
}
export default Zones;
