import moment from "moment";

import uploadHelper from './../../helper/upload.js';

const validateEmail = email => {
  var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  return re.test(email);
};

const unmaskNumber = (text) => {
  return text.replace(/[^0-9]/g, '');
};

const validatePhone = phone => {
  if (phone) {
    const re = /\d{10,11}$/g;

    return re.test(unmaskNumber(phone.number ? phone.number : phone));
  }

  return false;
};

function newArtistValidation(fields) {
  const errors = {};
  const {
    name,
    email,
    phoneNumber,
    fullAddress,
    place,
    streetNumber,
    neighbor,
    zipcode,
    city,
    uf,
    rg,
    cpf,
    birthdate,
    gender,
    active,
    status,
    rgUpload,
    cpfUpload,
    profilePicture
  } = fields;

  const haveAddress = () => (
    place ||
    neighbor ||
    streetNumber ||
    zipcode ||
    city ||
    uf
  );

  // full name validation
  if (!name) {
    errors.name = 'Insira um nome';
  }

  // email validation
  if (!email) {
    errors.email = 'Insira um email';
  } else if (!validateEmail(email)) {
    errors.email = 'Insira um email válido';
  }

  // phoneNumber validation
  if (!validatePhone(phoneNumber)) {
    errors.phoneNumber = 'Insira um telefone válido';
  }

  if (!fullAddress && !haveAddress()) {
    errors.fullAddress = 'Insira o endereço';
  }

  // place validation
  if (!place) {
    errors.place = 'Insira a rua';
  }

  // streetNumber validation
  if (!streetNumber) {
    errors.streetNumber = ' ';
  }

  // neighbor validation
  if (!neighbor) {
    errors.neighbor = 'Insira o bairro';
  }

  // city validation
  if (!city) {
    errors.city = 'Insira a cidade';
  }

  // uf validation
  if (!uf) {
    errors.uf = 'Inserir o estado';
  } else if (uf.length >= 3) {
    errors.uf = 'Inserir a sigla do estado';
  }

  // zipcode validation
  if (!zipcode) {
    errors.zipcode = 'Insira o cep';
  }

  // rg validation
  if (!rg) {
    errors.rg = 'Insira um RG';
  }

  // cpf validation
  if (!cpf) {
    errors.cpf = 'Insira um CPF';
  }

  if (cpf && unmaskNumber(cpf).length < 11) {
    errors.cpf = 'Insira um CPF válido';
  }

  // birthdate validation
  if (birthdate && !moment(birthdate, 'DD/MM/YYYY', true).isValid()) {
    errors.birthdate = 'Insira uma data válida';
  }

  // gender validation
  if (!gender) {
    errors.gender = 'Escolha uma opção';
  }

  if (!status) {
    errors.status = 'Escolha uma opção';
  }

  // rgUpload validation
  if (rgUpload && rgUpload.file) {
    const fileValidation = uploadHelper.isValidPartnerImage(rgUpload.file);

    if (!fileValidation.valid) {
      let msg = 'Erro desconhecido';

      if (fileValidation.error === 'extention') {
        const allowed = fileValidation.allowedExtentions.join(', ');
        msg = `O upload deve ser uma imagem. Extensões permitidas: ${allowed}`;
      } else {
        msg = `A imagem deve ter no máximo ${fileValidation.mbSizeLimit}mb`;
      }

      errors.rgUpload = msg;
    }
  }

  // cpfUpload validation
  if (cpfUpload && cpfUpload.file) {
    const fileValidation = uploadHelper.isValidPartnerImage(cpfUpload.file);

    if (!fileValidation.valid) {
      let msg = 'Erro desconhecido';

      if (fileValidation.error === 'extention') {
        const allowed = fileValidation.allowedExtentions.join(', ');
        msg = `O upload deve ser uma imagem. Extensões permitidas: ${allowed}`;
      } else {
        msg = `A imagem deve ter no máximo ${fileValidation.mbSizeLimit}mb`;
      }

      errors.cpfUpload = msg;
    }
  }

  // profilePicture validation
  if (profilePicture && profilePicture.file) {
    const fileValidation = uploadHelper.isValidPartnerImage(profilePicture.file);

    if (!fileValidation.valid) {
      let msg = 'Erro desconhecido';

      if (fileValidation.error === 'extention') {
        const allowed = fileValidation.allowedExtentions.join(', ');
        msg = `O upload deve ser uma imagem. Extensões permitidas: ${allowed}`;
      } else {
        msg = `A imagem deve ter no máximo ${fileValidation.mbSizeLimit}mb`;
      }

      errors.profilePicture = msg;
    }
  }

  return errors;
}

export default newArtistValidation;
