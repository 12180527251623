import React, { Component } from 'react';
import SwitchCheckbox from '../FormFields/SwitchCheckbox';
import './ArtistScheduling.css';

class ArtistScheduling extends Component {
  constructor(props) {
    super(props);

    this.state = {
      availability: []
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.availability !== this.props.availability) {
      const { availability } = this.props;
      this.setState({
        availability
      });
    }
  }

  weekDaysValueList = [
    { value: 0, day: 'Domingo' },
    { value: 1, day: 'Segunda-Feira' },
    { value: 2, day: 'Terça-Feira' },
    { value: 3, day: 'Quarta-Feira' },
    { value: 4, day: 'Quinta-Feira' },
    { value: 5, day: 'Sexta' },
    { value: 6, day: 'Sábado' }
  ];

  hasSchedules = (day) => {
    const dayAvailability = this.state?.availability.find(item => item?.dayOfWeek === day);
    return !!dayAvailability && Array.isArray(dayAvailability.schedules) && dayAvailability.schedules.length > 0;
  };
  
  handleDayChange = (day) => {
    this.setState((prevState) => {
      const updatedAvailability = [...prevState.availability];
      const dayIndex = updatedAvailability.findIndex(item => item.dayOfWeek === day);

      if (dayIndex !== -1) {
        const dayAvailability = updatedAvailability[dayIndex];

        if (dayAvailability.schedules.length > 0) {
          updatedAvailability.splice(dayIndex, 1);
        } else {
          dayAvailability.schedules = [{ start: '07:00', end: '22:00' }];
        }
      } else {
        const newDay = { dayOfWeek: day, schedules: [{ start: '07:00', end: '22:00' }] };
        updatedAvailability.push(newDay);
      }
      this.props.onUpdateAvailability(updatedAvailability);
      return { availability: updatedAvailability };
    });
  };

  handleAddTimeBlock = (day) => {
    this.setState((prevSchedule) => {
      const updatedAvailability = [...prevSchedule.availability];
      const dayIndex = updatedAvailability.findIndex(item => item.dayOfWeek === day);

      if (dayIndex !== -1) {
        updatedAvailability[dayIndex] = {
          ...updatedAvailability[dayIndex],
          schedules: [
            ...updatedAvailability[dayIndex].schedules,
            { start: '07:00', end: '22:00' },
          ],
        };
      } else {
        const newDay = { dayOfWeek: day, schedules: [{ start: '07:00', end: '22:00' }] };
        updatedAvailability.push(newDay);
      }
      this.props.onUpdateAvailability(updatedAvailability);
      return { availability: updatedAvailability };
    });
  };

  handleRemoveTimeBlock = (day, index) => {
    this.setState((prevSchedule) => {
      const updatedAvailability = [...prevSchedule.availability];

      const dayIndex = updatedAvailability.findIndex(item => item.dayOfWeek === day);

      if (dayIndex !== -1) {
        const dayAvailability = updatedAvailability[dayIndex];

        if (dayAvailability) {
          const updatedTime = [...dayAvailability.schedules];
          updatedTime.splice(index, 1);

          dayAvailability.schedules = updatedTime;

          if (updatedTime.length === 0) {
            updatedAvailability.splice(dayIndex, 1);
          }
        }
      }
      this.props.onUpdateAvailability(updatedAvailability);
      return { availability: updatedAvailability };
    });
  };

  handleTimeChange = (time, day, index) => {
    this.setState((prevState) => {
      const updatedAvailability = [...prevState.availability];
      const dayIndex = updatedAvailability.findIndex(item => item.dayOfWeek === day);

      if (dayIndex !== -1) {
        const updatedSchedules = [...updatedAvailability[dayIndex].schedules];

        updatedSchedules[index] = {
          ...updatedSchedules[index],
          [time.name]: time.value,
        };

        updatedAvailability[dayIndex] = {
          ...updatedAvailability[dayIndex],
          schedules: updatedSchedules,
        };
      }
      this.props.onUpdateAvailability(updatedAvailability);
      return { availability: updatedAvailability };
    });
  };


  render() {
    const { availability } = this.state;
    return (
      <div className='container-schedule-artist'>
        {this.weekDaysValueList.map((dayObj) => (
          <div className='form-container' key={dayObj.value}>
            <div className="day-title">
              <span>{dayObj.day}:</span>
            </div>
            <SwitchCheckbox
              checked={availability.some(item => item.dayOfWeek === dayObj.value)}
              onChange={() => this.handleDayChange(dayObj.value)}
            />
            {this.hasSchedules(dayObj.value) && (
              <div className='scheduling-block'>
                {availability.find(item => item.dayOfWeek === dayObj.value)?.schedules.map((block, index) => (
                  <div className='scheduling-time-block' key={`time-block-${dayObj.day}-${index}`}>
                    <input
                      className="Form-input"
                      name={`start-${dayObj.day}-${index}`}
                      type='time'
                      value={block.start}
                      onChange={(e) => { this.handleTimeChange({ name: 'start', value: e.target.value }, dayObj.value, index); console.log(dayObj.value, index, block.start); }}
                    />
                    <span>até</span>
                    <input
                      className="Form-input"
                      name={`end-${dayObj.day}-${index}`}
                      type='time'
                      value={block.end}
                      onChange={(e) => this.handleTimeChange({ name: 'end', value: e.target.value }, dayObj.value, index)}
                    />
                    {index >= 1 && (
                      <span
                        className='AddField-btn AddField-btn--remove glyphicon glyphicon glyphicon-minus-sign'
                        onClick={() => this.handleRemoveTimeBlock(dayObj.value, index)}
                      />
                    )}
                    {index === 0 && (
                      <span
                        className='AddField-btn AddField-btn--add glyphicon glyphicon-plus-sign'
                        onClick={() => this.handleAddTimeBlock(dayObj.value)}
                      />
                    )}
                  </div>
                ))}
              </div>
            )}
            {!this.hasSchedules(dayObj.value) && <span className='Form-scheduleTitle'>Não estou disponível!</span>}
          </div>
        ))}
      </div>
    );
  }
}

export default ArtistScheduling;
