"use strict";
import React, { Component } from "react";
import PageHeader from "../../components/Global/PageHeader";
import Loading from "../Global/Loading/Loading";
import { Modal, Grid, Col, Row } from "react-bootstrap";
import "./Packages.css";
import EditPackage from "./EditPackage";
import AddPackage from "./AddPackage";

export default class PackagesList extends Component {
  state = {
    packageItem: {},
    showEditModal: false,
    showNewModal: false,
    areas: [],
    avaiableServices: [],
    selectedArea: {},
    packageInfo: {},
  };

  componentWillReceiveProps(newProps) {
    const { areas } = newProps;
    this.setState({ areas });
  }

  handleClose() {
    this.setState({
      showEditModal: false,
      showNewModal: false,
    });
  }

  newPackage() {
    const { packageItemStructure } = this.state;
    this.setState({ packageItem: packageItemStructure, showNewModal: true });
  }

  editPackage = (e) => {
    const bulk = this.state.selectedArea.bulk.filter((bulk) => {
      if (bulk.type === "P") return bulk;
    })[0];

    this.setState({
      packageItem: Object.assign({}, bulk.packs[e.target.value]),
      showEditModal: true,
    });
  };

  deletePackage(areaId, packId) {
    if (window.confirm("Você realmente excluir este pacote?")) {
      this.props.deletePackage(areaId, packId).then((res) => {
        alert("Pacote excluido com sucesso.");
        window.location.reload();
      });
    }
  }

  handleAreaChoice(selectedArea, evt) {
    this.setState({ selectedArea });
  }

  renderSelectedArea() {
    const bulk = this.state.selectedArea.bulk.filter((bulk) => {
      if (bulk.type === "P") return bulk;
    })[0];
    const { packs } = bulk;
    const renderedPacks = packs.map((pack, index) => {
      return (
        <div
          className="col-md-4 col-xs-12"
          id={`pack-${pack._id}`}
          key={`${index}p`}
        >
          <div className="panel panel-default">
            <div className="panel-heading">
              <button
                value={index}
                className={"btn-edit-pack"}
                onClick={this.editPackage}
              >
                [EDITAR]
              </button>
              <button
                className={"btn-edit-pack"}
                onClick={this.deletePackage.bind(
                  this,
                  this.state.selectedArea._id,
                  pack._id
                )}
              >
                [EXCLUIR]
              </button>
            </div>
            <div className="panel-body">
              <h3 className={"text-center pack-title"}>
                {pack.title["pt-BR"]}{" "}
              </h3>
              <Col xs={8} xsOffset={2} style={{ padding: "20px 0" }}>
                <img
                  src={pack.mobileImage}
                  alt="Imagem do pacote"
                  className="img-responsive"
                />
              </Col>
              <div className="clearfix" />
              <Col xs={12}>
                <p>
                  <b>Valor Total do Pacote: </b> R${" "}
                  {pack.originalAmount.toFixed(2)}
                </p>

                <p>
                  <b>Desconto em Porcentagem: </b>
                  {pack.services.length > 0
                    ? parseFloat(pack.discountInPercent).toFixed(2)
                    : (0).toFixed(2)}
                  %
                </p>
                <p>
                  <b>Valor a ser mostrado no App: </b>
                  R${" "}
                  {pack.services.length > 0
                    ? pack.totalAmount.toFixed(2)
                    : (0).toFixed(2)}
                </p>
                <p>
                  <b>Quantidade de Parcelas: </b>
                  {pack.installmentMaxLimit ? pack.installmentMaxLimit : 1}
                </p>
                <p>
                  <b>Tempo para expirar:</b> {pack.expiresAfterDays} dias
                </p>
                <p>
                  <b>Subtítulo:</b> {pack.subtitle["pt-BR"]}
                </p>
                {pack.services.length <= 0 ? (
                  <div> </div>
                ) : (
                  <div className="col-xs-12">
                    <b>Serviços: </b>
                    <ul>
                      {pack.services.map((service) => {
                        return (
                          <li key={service.serviceList[0]}>
                            <p>
                              <b>Serviço: </b> {service.title["pt-BR"]} (
                              {service.serviceList[0]})
                            </p>
                            <p>
                              {pack.valueType === "P" ? (
                                <span>
                                  <b>Valor:</b> %{service.value}{" "}
                                  <b>Quantidade:</b>{" "}
                                </span>
                              ) : (
                                <span>
                                  <b>Valor:</b> R${" "}
                                  {parseFloat(service.value).toFixed(2)}{" "}
                                  <b>Quantidade:</b>{" "}
                                </span>
                              )}

                              {service.count}
                            </p>
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                )}
              </Col>
            </div>
          </div>
        </div>
      );
    });
    return (
      <div className="col-xs-12" style={{ marginTop: "20px" }}>
        {renderedPacks}
      </div>
    );
  }

  renderPackages() {
    if (!this.state.areas && this.state.areas.length > 0) {
      return <Loading />;
    }
    return (
      <div className="row">
        <button
          onClick={() => {
            this.setState({ showNewModal: true });
          }}
          className="btn btn-add btn-pack btn-default"
          type="button"
        >
          Novo Pacote &nbsp; <i className="glyphicon glyphicon-plus" />
        </button>
        <h3 className={"text-center"}>
          Escolha a cidade para gerenciar/visualizar os pacotes:{" "}
        </h3>
        <div className="col-xs-12 text-center">
          {this.state.areas.map((area) => {
            return (
              <button
                key={area._id}
                id={area._id}
                className={`btn btn-pack btn-default btn-area ${
                  this.state.selectedArea &&
                  this.state.selectedArea._id === area._id
                    ? "active"
                    : ""
                }`}
                onClick={this.handleAreaChoice.bind(this, area)}
              >
                {area.city} - {area.stateShort}
              </button>
            );
          })}
        </div>
      </div>
    );
  }

  render() {
    const { showEditModal, packageItem, selectedArea, showNewModal } =
      this.state;

    return (
      <Col xs={12}>
        <Row>
          <PageHeader title={"Pacotes"} />
          {this.renderPackages()}
          {Object.keys(this.state.selectedArea).length > 0
            ? this.renderSelectedArea()
            : ""}
        </Row>
        <Modal
          show={showEditModal}
          onHide={this.handleClose.bind(this)}
          className="Modal Modal--large"
        >
          <Modal.Header closeButton>
            <Modal.Title>Editar Pacote</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <EditPackage
              editPackage={this.props.editPackage}
              showEditModal={showEditModal}
              pack={packageItem}
              area={selectedArea}
              handleClose={this.handleClose.bind(this)}
            />
          </Modal.Body>
        </Modal>
        <Modal
          show={showNewModal}
          onHide={this.handleClose.bind(this)}
          className="Modal Modal--large"
        >
          <Modal.Header closeButton>
            <Modal.Title>Novo Pacote</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <AddPackage
              addPackage={this.props.addPackage}
              showNewModal={showNewModal}
              pack={packageItem}
              areas={this.state.areas}
              handleClose={this.handleClose.bind(this)}
            />
          </Modal.Body>
        </Modal>
      </Col>
    );
  }
}
