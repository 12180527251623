import React from "react"

import { Button } from 'react-bootstrap';
import './Voucher.css';
import dateHelper from "../../helper/dates";

const Actions = (props) => {
  return (
    <div className="VoucherActions-btnWrapper">
      <Button
        onClick={() => props.rowData.actions.openUpdateModal(props.rowData)}
        className="VoucherActions-btn btn btn-info"
        title="Editar">
        <span className="glyphicon glyphicon-edit"></span>
      </Button>
      <Button
        onClick={() => props.rowData.actions.openDeleteModal(props.rowData.code)}
        className="VoucherActions-btn btn btn-info"
        title="Desativar">
        <span className="glyphicon glyphicon-trash"></span>
      </Button>
    </div>
  )
};

const Start = (props) => {
  let start = props.rowData.start;
  const startDateFormatedd = dateHelper.formatDateDDMMYY(start);
  return (<div>{startDateFormatedd}</div>)
};

const End = (props) => {
  let end = props.rowData.end;
  const endDateFormatedd = dateHelper.formatDateDDMMYY(end);
  return (<div>{endDateFormatedd}</div>)
};

const Type = (props) => {
  const voucherType = props.rowData.type;

  const voucherTypeFormatedd = voucherType === "P" ? "Porcentagem" : "Valor";

  return (
    <div>{voucherTypeFormatedd}</div>
  )
};

const Value = (props) => {
  const voucherType = props.rowData.type;
  const voucherValue = props.rowData.value;

  const valueFormatedd = voucherType === "P" ? `${voucherValue}%` : `R$ ${voucherValue}`;

  return (
    <div>{valueFormatedd}</div>
  )
};

export default [
  {
    columnName: "id",
    visible: false
  },
  {
    columnName: "code",
    displayName: "Código"
  },
  {
    columnName: "campaign",
    displayName: "Campanha"
  },
  {
    columnName: "justNew",
    displayName: "Novos usuários ?"
  },
  {
    columnName: "start",
    displayName: "Início",
    customComponent: Start
  },
  {
    columnName: "end",
    displayName: "Fim",
    customComponent: End
  },
  {
    columnName: "maxTimes",
    displayName: "MAX"
  },
  {
    columnName: "timesByUser",
    displayName: "MAX P/USUÁRIO"
  },
  {
    columnName: "numberOfClients",
    displayName: "MIN DE CLIENTES"
  },
  {
    columnName: "usedTimes",
    displayName: "Utilizações"
  },
  {
    columnName: "value",
    displayName: "Valor",
    customComponent: Value
  },
  {
    columnName: "type",
    displayName: "Tipo",
    customComponent: Type
  },
  {
    columnName: 'actions',
    displayName: 'Ações',
    customComponent: Actions
  }
];
