/**
 * SINGU CORE -> https://gitlab.com/singu-project/npm/core
 */
const money = require('./money');
const moment = require('moment-timezone');

const OLD_DEFAULT_TAKE_RATE = 30;
const OLD_SINGU_PAYMENT_TAKE_RATE = 35;

const DEFAULT_TAKE_RATE = 35;
const SINGU_PAYMENT_TAKE_RATE = 40;

const takeRateMigrationDate = moment('2018-09-21T03:00:00Z').tz('America/Sao_Paulo');

// Schedule > Partner > Default
const calculateTakeRate = (partner, schedule) => {
    if (schedule.takeRate && schedule.takeRate.force && moment().isBefore(schedule.takeRate.expiration, 'minutes')) {
        return schedule.takeRate;
    }

    if (partner.takeRate) {
        let validTakeRate = true;
        const takeRateValue = partner.takeRate.value;
        const validTakeRateValue = takeRateValue >= 0 && takeRateValue <= 100;

        if (partner.takeRate.expiration && moment().isAfter(partner.takeRate.expiration, 'minutes')) {
            validTakeRate = false;
        }

        if (validTakeRate && validTakeRateValue) {
            return {
                value: takeRateValue,
                expiration: schedule.dateRangeEnd,
                force: false
            };
        }
    }

    return {
        value: partner.singuPayment === true ? SINGU_PAYMENT_TAKE_RATE : DEFAULT_TAKE_RATE,
        expiration: schedule.dateRangeEnd,
        force: false
    };
};

const calculateArtistValue = (schedule) => {
    const discount = schedule.infractionProfessionalDiscount || 0;
    let originalValue = schedule.originalValue;
    const professionalPreferences = schedule.professionalPreferences || [];

    if (professionalPreferences.length > 0 && schedule.originalValuePreference > 0 && schedule.hasPreference === true) {
        originalValue = schedule.originalValuePreference;
    }

    if (schedule.realtime && schedule.originalValueRealtime > 0) {
        originalValue = schedule.originalValueRealtime;
    }

    if (schedule.status && schedule.status[0].code === 5) {
        return schedule.cancelValue && schedule.cancelValue.artistValue ? schedule.cancelValue.artistValue : 0;
    }

    let scheduleRequest = moment(schedule.requestedOn).tz('America/Sao_Paulo');
    let scheduleRequestIsBeforeTheTakeRateMigration = scheduleRequest.isBefore(takeRateMigrationDate, 'day');

    let TAKE_RATE_BASE = schedule.realtime || scheduleRequestIsBeforeTheTakeRateMigration ? OLD_DEFAULT_TAKE_RATE : DEFAULT_TAKE_RATE;
    let TAKE_RATE_SINGU_PAYMENT_BASE = schedule.realtime || scheduleRequestIsBeforeTheTakeRateMigration ? OLD_SINGU_PAYMENT_TAKE_RATE : SINGU_PAYMENT_TAKE_RATE;

    let takeRate = schedule.partner && schedule.partner.singuPayment === true ? TAKE_RATE_SINGU_PAYMENT_BASE : TAKE_RATE_BASE;

    if (schedule.takeRate && schedule.takeRate.value >= 0 && schedule.takeRate.value <= 100) {
        takeRate = schedule.takeRate.value;
    }

    const artistValue = money.getTakeRate(originalValue, takeRate);

    const artistValueWithDiscount = money.valueDesc(artistValue, discount);

    //Nunca pode ser negativo o valor a receber da artista
    return artistValueWithDiscount < 0 ? 0 : artistValueWithDiscount;
};

module.exports = {
    calculateTakeRate,
    calculateArtistValue
};