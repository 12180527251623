import React, { Component } from "react";


export default class PackageService extends Component {
    constructor(props) {
        super(props);

        this.state = {
            pack: props.pack,
            prepack: props.prepack,
            areas: props.area
        };
    }

    handleSelectedService(evt) {
        const { prepack } = this.state;
        prepack.serviceList = [evt.target.value];
        prepack.value =
            evt.nativeEvent.target[evt.target.selectedIndex].dataset.price;
        prepack.title = evt.nativeEvent.target[evt.target.selectedIndex].text;
        this.setState({ prepack });
    }

    renderServices() {
        return (
            <div className="col-xs-12 form-group">
                <div className="row">
                    <div className="col-xs-12 form-group">
                        <label>Serviços Selecionados: </label>
                        {this.renderSelectedServices()}
                    </div>
                    <div className="col-xs-12">
                        <label>Adicionar Serviços</label>
                    </div>
                    <div className="clearfix" />
                    {this.renderServicesAvaiables()}
                    <div className="col-xs-2 form-group">
                        <label>Valor do Serviço</label>
                        <input
                            className="form-control form-control-normal"
                            name={"value"}
                            tabIndex={1}
                            required
                            value={this.state.prepack.value || 0}
                        />
                    </div>
                    <div className="col-xs-2">
                        <label>Quantidade</label>
                        <div className="input-group">
                            <input
                                type="number"
                                className="form-control"
                                name={"count"}
                                tabIndex={1}
                                value={this.state.prepack.count || 0}
                                onChange={this.handleServiceCount}
                            />
                            <span className="input-group-btn">
                                <button
                                    className="btn btn-default"
                                    type="button"
                                    onClick={this.addService}
                                >
                                    Adicionar
                                </button>
                            </span>
                        </div>
                    </div>
                    <div className="col-xs-2">
                        <label>Quantidade de Parcelas</label>
                        <select 
                            className="form-control form-control-normal"
                            name="installmentMaxLimit"
                            onChange={this.handleFormEdit}
                            value={this.state.pack.installmentMaxLimit || 1}
                        >
                            {
                                Array(12).fill(0).map((item, idx) => (
                                    <option 
                                        key={idx} 
                                        value={idx+1}
                                    >
                                        {idx+1}
                                    </option>
                                ))
                            }
                        </select>
                    </div>
                    <div className="col-xs-3">
                        <p className="pack-total">
                            Total de serviços: R${" "}
                            {parseFloat(this.state.pack.originalAmount).toFixed(2) || 0}
                        </p>
                        <p>
                            Valor com desconto: R${" "}
                            {parseFloat(this.state.pack.totalAmount).toFixed(2) || 0}
                        </p>
                    </div>
                    <div className="clearfix" />
                    <hr />
                </div>
            </div>
        );
    }

    renderSelectedServices() {
        if (!this.state.pack.services) {
            return <p>Não há serviços selecionados</p>;
        }
        return (
            <ul>
                {this.state.pack.services.map((service, index) => {
                    return (
                        <li key={index}>
                            {service.title} - R$ {parseFloat(service.value).toFixed(2)} -{" "}
                            {service.count} Voucher(s) - {service.serviceList[0]}
                            <button
                                type="button"
                                style={{ marginLeft: "10px" }}
                                className="btn btn-xs btn-danger"
                                title="Remover Serviço"
                                onClick={this.handleRemoveService.bind(this, index)}
                            >
                                <i className="glyphicon glyphicon-trash" />
                            </button>
                        </li>
                    );
                })}
            </ul>
        );
    }

    renderServicesAvaiables() {
        return (
            <div className="col-xs-3 form-group">
                <label>Serviços</label>
                <select
                    className="form-control form-control-normal"
                    onChange={this.handleSelectedService.bind(this)}
                >
                    <option value="" disabled>
                        Selecione um serviço
                    </option>
                    {this.getServicesOptions()}
                </select>
            </div>
        );
    }

    getServicesOptions() {
        const { areas } = this.state;
        const options = [];
        const servicesAdded = [];
        const categoriesAdded = [];
        areas.forEach(area => {
            const { categories } = area;
            categories.forEach(category => {
                if (!categoriesAdded.includes(category._id)) {
                    categoriesAdded.push(category._id);
                    options.push(
                        <optgroup label={category.activity}>
                            {category.services.map(service => {
                                if (!servicesAdded.includes(service._id)) {
                                    servicesAdded.push(service._id);
                                    if (service.active === true) {
                                        return (
                                            <option data-price={service.minValue} value={service._id}>
                                                {service.name["pt-BR"]}
                                            </option>
                                        );
                                    }
                                }
                            })}
                        </optgroup>
                    );
                }
            });
        })
        return options;
    }

    handleServiceCount = (evt) => {
        const { prepack } = this.state;
        prepack.count = evt.target.value;
        this.setState({ prepack });
    }

    handleRemoveService(serviceIndex) {
        const { pack } = this.state;
        pack.services.splice(serviceIndex);
        this.setState({ pack });
    }

    addService = () => {
        const { pack, prepack } = this.state;
        if (
            !prepack.value ||
            !prepack.count ||
            !prepack.title ||
            !prepack.serviceList
        ) {
            alert(
                "Por favor, verifique se todos os campos estão corretos e tente novamente."
            );
            return;
        }

        prepack.value = parseFloat(prepack.value);
        prepack.count = parseInt(prepack.count);

        if (!pack.services) pack.services = [prepack];
        else pack.services.push(prepack);

        const { originalAmount, totalAmount } = this.calculatePack(pack);

        pack.originalAmount = originalAmount;
        pack.totalAmount = totalAmount;

        this.setState({
            prepack: {},
            pack
        });
    }

    handleFormEdit = (evt) => {
        const { value, name } = evt.target;
        const { pack } = this.state;
        pack[name] = value;
        if (name === "discountInPercent") {
            const { originalAmount, totalAmount } = this.calculatePack(pack);
            pack.originalAmount = originalAmount;
            pack.totalAmount = totalAmount;
        }
        this.setState({ pack });
    }

    calculatePack(pack) {
        if(!pack.services || pack.services.length <= 0) return { originalAmount: 0, totalAmount: 0 }
        const originalAmount = pack.services.reduce(
            (total, service) => total + service.value * service.count,
            0
        );
        let totalAmount;
        if (!pack.discountInPercent) totalAmount = originalAmount;
        else totalAmount = originalAmount * (1 - pack.discountInPercent / 100);
        return { originalAmount, totalAmount };
    }

    render() {
        return (
            <div style={{ marginTop: 25, marginLeft: 25 }}>
                {this.renderServices()}
                <div className="col-xs-6 form-group">
                    <label>
                        Desconto em % (valor usado para calculo na cobrança do pacote)
                    </label>
                    <input
                        className="form-control"
                        name={"discountInPercent"}
                        tabIndex={5}
                        required
                        value={this.state.pack.discountInPercent}
                        onChange={this.handleFormEdit}
                    />
                </div>
                <div className="col-xs-4 form-group">
                    <label>Desconto para exibição no app em %</label>
                    <input
                        className="form-control"
                        name={"discountAmount"}
                        tabIndex={6}
                        required
                        value={this.state.pack.discountAmount}
                        onChange={this.handleFormEdit}
                    />
                </div>
                
            </div>

        )
    }
}