import axios from 'axios';
import config from '../../../config.json';
const env = process.env.REACT_APP_ENVIRONMENT || 'stage';
const ORDERS_URL = config.orders[env];

export function getPrice(service, area, lat, lng) {
  return axios.get(`${ORDERS_URL}/price`, {
    params: {
      area,
      service,
      lat,
      lng,
    },
  });
}
