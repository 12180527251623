import config from '../../../config.json';
const env = process.env.REACT_APP_ENVIRONMENT || 'stage';
const BACKEND_URL = config.api[env];
import axios from 'axios';

import { getToken } from '../../models/auth';

export const FETCH_AIRPORTS = 'FETCH_AIRPORTS';

export function fetchAirport(page, filter) {
  let params = {
    itemsPerPage: 10,
    page: page,
    filter
  };

  const airportsPromise = axios.get(`${BACKEND_URL}/v2/bo/airports`, {
    headers: { Authorization: `Bearer ${getToken()}` },
    params
  });

  return {
    type: FETCH_AIRPORTS,
    payload: airportsPromise
  };
}
