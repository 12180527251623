import { combineReducers } from 'redux';
import schedulesReducer from './reducer_schedules';
import leadsReducer from './reducer_leads';
import vouchersReducer from './reducer_vouchers';
import dynamicPriceReducer from './reducer_dynamic_prices';
import administrativeZonesReducers from './reducer_administrative_zones';
import partnersReducer from './reducer_partners';
import usersReducer from './reducer_users';
import userInfoReducer from './reducer_user_info';
import areasReducer from './reducer_areas';
import configsReducer from './reducer_configs';
import artistsReducer from './reducer_artists';
import realTimeOrdersReducer from './reducer_realtime';
import airportsReducer from './reducer_airports';
import { reducer as formReducer } from 'redux-form';

const appReducer = combineReducers({
  configs: configsReducer,
  leads: leadsReducer,
  dynamicPrices: dynamicPriceReducer,
  administrativeZones: administrativeZonesReducers,
  vouchers: vouchersReducer,
  partners: partnersReducer,
  users: usersReducer,
  userInfo: userInfoReducer,
  areas: areasReducer,
  schedules: schedulesReducer,
  artists: artistsReducer,
  airports: airportsReducer,
  realTimeOrders: realTimeOrdersReducer,
  form: formReducer
});

export default appReducer;
