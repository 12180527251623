import React, { Component } from "react";
import { Button } from "react-bootstrap";
import axios from "../../helper/axios";
import InfractionDetailModal from "./InfractionDetails";

export default class InfractionDataActions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      selectedInfraction: {}
    };
  }

  showModal(row) {
    this.setState({
      open: true,
      selectedInfraction: row
    });
  }

  ignoreInfraction(infractionId) {
    const ignore = confirm(
      "Você tem certeza que deseja ignorar essa infração ? Os pontos serão revertidos e a multa será paga."
    );
    if (ignore) {
      axios
        .put(`${BACKEND_URL}/v2/infraction/artist/${infractionId}/ignore`)
        .then(_ => {
          window.location.reload();
        })
        .catch(error => {
          alert(error.data);
        });
    }
  }

  componentWillReceiveProps(newProps) {
    this.props = newProps
  }

  render() {
    return (
      <div style={{display:"flex"}}>
        <Button
          title="Ignorar infração"
          onClick={() =>
            this.ignoreInfraction(this.props.rowData.id_professional_infraction)
          }
        >
          <span className="glyphicon glyphicon-remove" />
        </Button>
        <Button
          title="Visualizar Detalhes"
          onClick={this.showModal.bind(this, this.props.rowData)}
        >
          <span className="glyphicon glyphicon-eye-open" />
        </Button>
        {this.state.open && (
          <InfractionDetailModal
            infraction={this.state.selectedInfraction}
            open={this.state.open}
            closeModal={() => this.setState({ open: false })}
          />
        )}
      </div>
    );
  }
}
