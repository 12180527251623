import axios from 'axios';
import config from '../../../config.json';
const env = process.env.REACT_APP_ENVIRONMENT || 'stage';
const BACKEND_URL = config.api[env];

import { getToken } from "../../models/auth";


export const FETCH_REALTIME_PRICES = "FETCH_REALTIME_PRICES";

export function getRealtimePrices() {
  return {
    type: FETCH_REALTIME_PRICES,
    payload: axios.get(`${BACKEND_URL}/dynamic_price/realtime`, {
      headers: {
        Authorization: `Bearer ${getToken()}`
      }
    })
  };
}
