import React, { Component, PropTypes } from 'react';
import { Button, Modal } from 'react-bootstrap';
import getScheduleById from '../../../services/schedules';
import handleRequest from '../../../helper/requests';

//style
import './addComment.css';

class CommentComponent extends Component {
  constructor(props) {
    super(props);
    this.handleShow = this.handleShow.bind(this);
    this.handleClose = this.handleClose.bind(this);
    const { comment } = this.props;
    const newComment = comment;
    this.state = {
      show: false,
      comment: newComment,
      comments: []
    };
  }

  componentDidMount(){
    getScheduleById(this.props.scheduleId).then(({ data:schedule }) => {
      this.setState({ comments: schedule.comments || [] });
    })
  }

  onCommentValueChange = (e) => {
    const value = e.target.value;
    const newComment = value;
    this.setState({ comment: newComment })
  };

  confirmUpdateDateModal = async () => {
    const actionCreator = () => this.props.updateCallback(this.props.scheduleId, this.state.comment);
    await handleRequest(actionCreator, this.props.notify, 'Comentário adicionado com sucesso');
    const { data:schedule } = await getScheduleById(this.props.scheduleId);
    
    this.setState({ comments: schedule.comments, comment: '' });
    this.handleClose();
  };

  handleClose() {
    this.setState({ show: false });
  }

  handleShow() {
    this.setState({ show: true });
  }

  render() {
    if(!this.state.comments) return <p>Loading...</p>

    var displayComments = this.state.comments.map((comment, index) => {
      const user = comment.createdBy ? comment.createdBy.email : 'Não disponível';
      const message = comment.message;
      const date = comment.createdAt;

      return (
        <div key={index} className="ScheduleDetails-history">
          <p><b>Usuário: </b>{user}</p>
          <p><b>Comentário: </b>{message}</p>
          <p><b>Data: </b>{date}</p>
        </div>
      )
    });

    return (
      <div>
        <div style={{ display: displayComments.length > 0 ? 'block' : 'none' }}>
          <div className="Modal-section">
            {displayComments}
          </div>
        </div>

        <div className="addComment">
          <div className="addComment-row">
            <textarea
              className="Form-input"
              type="text" id={name}
              ref="input"
              style={{ width: '100%', height: '75px', marginBottom: '10px', resize: 'none' }}
              value={this.state.comment}
              onChange={this.onCommentValueChange.bind(this)}
            />
            <br /><br /><br />
            <Button className="addComment-btn"
              bsStyle="primary"
              onClick={this.handleShow}
            >Adicionar Comentário</Button>
            <Modal show={this.state.show} onHide={this.handleClose} className="addComment-modal">
              <Modal.Header closeButton>
                <Modal.Title>Tem certeza que deseja adicionar esse comentário?</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                Seu comentário ficará público para todos os usuários.
                </Modal.Body>
              <Modal.Footer>
                <Button onClick={this.handleClose}>Fechar</Button>
                <Button onClick={this.confirmUpdateDateModal.bind(this)}>Adicionar</Button>
              </Modal.Footer>
            </Modal>
          </div>
        </div>
      </div>
    );
  }
}

CommentComponent.propTypes = {
  notify: PropTypes.func,
  updateCallback: PropTypes.func
};

export default CommentComponent;
