import config from '../../../config.json';
const env = process.env.REACT_APP_ENVIRONMENT || 'stage';
const BACKEND_URL = config.api[env];
import axios from 'axios';

import { getToken } from './../../models/auth';

export const REMOVE_ADDRESS = 'REMOVE_ADDRESS';

export function removeAddress(userId, addressId, index) {
  const addressPromise = axios.delete(`${BACKEND_URL}/v2/bo/user/${userId}/address`, {
    headers: { Authorization: `Bearer ${getToken()}` },
    data: { addressId }
  });

  return {
    type: REMOVE_ADDRESS,
    payload: addressPromise,
    meta: { index }
  };
}
