/* global _ */

import Griddle from "griddle-react";
import React, { Component, PropTypes } from 'react';
import moment from "moment-timezone";
import NotificationSystem from "react-notification-system";
import { Modal, Button } from "react-bootstrap";
import enumHelper from "./../../helper/enumHelper";
import formatter from "./../../helper/formatters";
import RealTimeOrdersMetadata from "./RealTimeOrdersGriddle_metadata";
import Loading from "./../Global/Loading/Loading";
import { calculateArtistValue } from "../../helper/takeRate";
import requestHandle from "./../../helper/requests";
import ScheduleDetails from "../Schedules/ScheduleDetails/ScheduleDetails";
import UpdateTakeRate from "../Schedules/UpdateTakeRate/UpdateTakeRate";
import ChangePartner from "../Schedules/ChangePartner/ChangePartner";

// styles
import "./RealTimeOrdersGriddle.css";

class RealTimeOrdersGriddle extends Component {
  constructor(props) {
    super(props);

    const d1 = new Date();
    d1.setDate(d1.getDate() - 5);

    const d2 = new Date();
    d2.setDate(d2.getDate() + 5);

    this.state = {
      realtimeOrders: [],
      isDetailsModalOpened: false,
      details: {},
      orderBy: null,
      cancelId: null,
      orderType: "ASC",
      filter: null,
      shortCode: null,
      currentPage: 1,
      status: 0,
      minDate: d1.toISOString().substr(0, 10),
      maxDate: d2.toISOString().substr(0, 10)
    };
  }

  componentWillMount() {
    this.handleProps(this.props);
  }

  componentWillReceiveProps(newProps) {
    this.handleProps(newProps);
  }

  handleProps({ realtimeOrders, artists, areas, vouchers }) {
    if (realtimeOrders) {
      this.mapSchedules(realtimeOrders);
    }

    if (areas) {
      this.mapAreas(areas);
    }

    if (artists) {
      this.setState({ artists });
    }

    if (vouchers) {
      this.setState({ vouchers });
    }
  }

  mapAreas(areas) {
    const reduceAllServices = (prevCategory, currentCategory) => {
      return [
        ...prevCategory,
        ...currentCategory.services.map(service => {
          return {
            categoryId: currentCategory._id,
            ...service
          };
        })
      ];
    };
    const mapAreas = area => {
      return {
        areaId: area._id,
        areaServices: area.categories.reduce(reduceAllServices, [])
      };
    };
    const servicesByEachArea = areas.map(mapAreas);

    this.setState({ servicesByEachArea });
  }

  mapSchedules(realtimeOrders) {
    const self = this;
    const mappedSchedules = _.map(realtimeOrders, (realtimeOrder, i) => {
      const paymentStatus =
        (realtimeOrder.payment && realtimeOrder.payment.status) ||
        (realtimeOrder.payment &&
          realtimeOrder.payment.events &&
          realtimeOrder.payment.events.length > 0 &&
          realtimeOrder.payment.events[0].type) ||
        "N/A";
      const dateFormat = "DD/MM/YYYY HH:mm";
      const tz =
        realtimeOrder.area && realtimeOrder.area.tz
          ? realtimeOrder.area.tz
          : "America/Sao_Paulo";
      const service = realtimeOrder.services[0];
      const serviceName = _.isObject(service.name)
        ? service.name["pt-BR"]
        : service.name;
      const serviceId = service ? service._id : null;
      const agent = realtimeOrder.userAgent
        ? realtimeOrder.userAgent && realtimeOrder.userAgent.toUpperCase().indexOf("IOS") !== -1
          ? "iOS"
          : "android"
        : "Não identificado";
      const hasVoucher = realtimeOrder.voucher && !_.isEmpty(realtimeOrder.voucher);
      const tzRequestedOn = moment.tz(realtimeOrder.requestedOn, tz);
      const tzScheduledOn = moment.tz(realtimeOrder.date, tz);
      const futureData = moment().add(1, 'day').tz(tz);
      const tzScheduledRangeStart = futureData;
      const tzScheduledRangeEnd = futureData;

      if (realtimeOrder.takeRate && realtimeOrder.takeRate.expiration) {
        realtimeOrder.takeRate.expiration = moment.tz(realtimeOrder.takeRate.expiration, tz);
      }

      function dateToString() {
        // this functions is for sorting purposes
        return this.sortableDate;
      }

      const partner = realtimeOrder.partner || {};

      return {
        id: realtimeOrder._id,
        index: i,
        oldId: realtimeOrder.oldId || null,
        requestedOn: {
          date: tzRequestedOn,
          formattedDate: tzRequestedOn.format(dateFormat),
          sortableDate: tzRequestedOn.format(),
          toString: dateToString
        },
        scheduledOn: {
          date: tzScheduledOn,
          formattedDate: tzScheduledOn.format(dateFormat),
          sortableDate: tzScheduledOn.format(),
          toString: dateToString
        },
        scheduledRangeStart: {
          date: tzScheduledRangeStart,
          formattedDate: tzScheduledRangeStart.format(dateFormat),
          sortableDate: tzScheduledRangeStart.format(),
          toString: dateToString
        },
        scheduledRangeEnd: {
          date: tzScheduledRangeEnd,
          formattedDate: tzScheduledRangeEnd.format(dateFormat),
          sortableDate: tzScheduledRangeEnd.format(),
          toString: dateToString
        },
        serviceName,
        serviceId,
        agent,
        paymentStatus,
        scheduleDateType: realtimeOrder.scheduleDateType,
        name: realtimeOrder.user.name,
        email: realtimeOrder.user.email,
        userId: realtimeOrder.user._id,
        voucher: hasVoucher ? realtimeOrder.voucher.code : "Não utilizado",
        phone: formatter.formatPhone(realtimeOrder.user.phone),
        address: realtimeOrder.address,
        formattedAddress: formatter.formatAddress(realtimeOrder.address),
        statusCode: realtimeOrder.status[0].code,
        status: enumHelper.getScheduleStatusName(realtimeOrder.status[0].code),
        statusHistory:
          realtimeOrder.status && realtimeOrder.status.length > 0 ? realtimeOrder.status : [],
        artistEvents:
          realtimeOrder.artistEvents && realtimeOrder.artistEvents.length > 0
            ? realtimeOrder.artistEvents
            : [],
        artistsHistory:
          realtimeOrder.artistsHistory && realtimeOrder.artistsHistory.length > 0
            ? realtimeOrder.artistsHistory
            : [],
        creditCardValidated: realtimeOrder.creditCardValidated,
        rule: realtimeOrder.rule,
        tz: tz,
        numberOfClients: realtimeOrder.numberOfClients || 1,
        partnerId: partner._id,
        partnerName: partner.name,
        partnerEmail: partner.email,
        takeRate: realtimeOrder.takeRate,
        partnerPhone: formatter.formatPhone(partner.phone),
        partnerSinguPayment: partner.singuPayment,
        actions: {
          openDetailsModal: self.openDetailsModal.bind(self),
          openCancelModal: self.openCancelModal.bind(self),
          openActionsModal: self.openActionsModal.bind(self),
        },
        shortCode: realtimeOrder.shortCode || null,
        payment: {
          id: realtimeOrder.payment ? realtimeOrder.payment.id : 'Não existente',
          status: realtimeOrder.payment ? realtimeOrder.payment.status : 'Não existente',
        },
        transfer: {
          id: realtimeOrder.transfer ? realtimeOrder.transfer.id : 'Não existe',
          status: realtimeOrder.transfer ? realtimeOrder.transfer.status : 'Não existe',
        },
        comments: realtimeOrder.comments || [],
        originalValue: realtimeOrder.originalValue,
        originalValueRealtime: realtimeOrder.originalValueRealtime,
        totalValue: realtimeOrder.totalValue,
        discount: realtimeOrder.discount,
        cancelValue: realtimeOrder.cancelValue || {},
        artistValue: calculateArtistValue(realtimeOrder),
        alerts: realtimeOrders.alerts && realtimeOrders.alerts.length > 0 ? realtimeOrders.alerts : [],
        dateHistory: realtimeOrders.dateHistory || [],
        takeRateHistory: realtimeOrders.takeRateHistory || [],
        professionalPreferences: realtimeOrders.professionalPreferences || []
      };
    });

    this.setState({ realtimeOrders: mappedSchedules });
  }

  notify(message, level) {
    const title = level === "success" ? "Sucesso" : "Erro";
    this.refs.notificationSystem.addNotification({
      title,
      message,
      level,
      position: "tc"
    });
  }

  cancelRealTime() {
    const actionCreator = () => this.props.cancelRealTimeOrderAction(this.state.cancelId);

    requestHandle(actionCreator, (msg, lvl) => this.notify(msg, lvl), 'Pedido cancelado com sucesso');
  }

  openCancelModal(id) {
    this.setState({ cancelId: id, cancelModalOpen: true });
  }

  openActionsModal(rowData) {
    this.setState({
      isActionsModalOpened: true,
      details: rowData
    });
  }

  closeModal() {
    this.setState({ cancelModalOpen: false, isDetailsModalOpened: false, isActionsModalOpened: false });
  }

  openDetailsModal(rowData) {
    this.setState({
      isDetailsModalOpened: true,
      details: rowData
    });
  }

  getRealTimeOrders = () => {
    let {
      currentPage,
      orderBy,
      orderType,
      filter,
      minDate,
      maxDate,
      status,
      area,
      shortCode
    } = this.state;
    this.props.getRealtimeOrders(currentPage, {
      orderBy,
      orderType,
      filter,
      minDate,
      maxDate,
      status,
      area,
      shortCode
    });
  };

  getPage = nextPage => {
    const currentPage = nextPage + 1;

    this.setState({ currentPage }, this.getRealTimeOrders);
  };

  sort = orderBy => {
    let orderType = "ASC";

    if (this.state.orderBy === orderBy) {
      orderType = this.state.orderType === "ASC" ? "DESC" : "ASC";
    }

    this.setState(
      {
        orderBy,
        orderType
      },
      this.getRealTimeOrders
    );
  };

  filter = filter => {
    this.setState(
      {
        filter
      },
      this.getRealTimeOrders
    );
  };

  _handleMinDateChange = e => {
    this.setState({ minDate: e.target.value });
  };

  _handleMaxDateChange = e => {
    this.setState({ maxDate: e.target.value });
  };

  _handleClick = () => {
    this.setState({ currentPage: 1 }, this.getRealTimeOrders);
  };

  _handleOptionSelected = e => {
    this.setState({ status: e.target.value });
  };

  _handleAreaSelected = e => {
    this.setState({ area: e.target.value });
  };

  _handleShortCodeChange = e => this.setState({ shortCode: e.target.value });

  _handleChange = e => {
    var filterData = e.target.value;
    var re = /^[0-3]?[0-9].[0-3]?[0-9].(?:[0-9]{2})?[0-9]{2}$/;

    if (filterData.match(re)) {
      var dateSplited = filterData.split("/");
      filterData = dateSplited[2] + "-" + dateSplited[1] + "-" + dateSplited[0];
    }

    this.setState({ filter: filterData });
  };

  _handleKeyPress(e) {
    if (e.charCode === 13) {
      this._handleClick();
    }
  }

  render() {
    const { realtimeOrders } = this.state;

    let areas = [];

    if (this.props.areas && this.props.areas.length > 0) {
      this.props.areas.forEach((data, index) => {
        areas.push(
          <option value={data._id} key={index}>
            {data.city}
          </option>
        );
      });
    }

    return (
      <div className="Realtime">
        <div className="row">
          <center className="Filter">
            <b style={{ marginLeft: 10, marginRight: 10 }}>CÓDIGO:</b>{" "}
            <input
              onKeyPress={this._handleKeyPress.bind(this)}
              value={this.state.shortCode}
              onChange={this._handleShortCodeChange}
              width="150px"
              placeholder="Código..."
              className="form-control"
            />
            <b style={{ marginLeft: 10, marginRight: 10 }}>Status:</b>
            <select
              onChange={this._handleOptionSelected}
              className="form-control"
            >
              <option value="0"> Todos</option>
              <option value="17">
                {" "}
                {enumHelper.scheduleStatusEnum.creditCardConfirmation.name}{" "}
              </option>
              <option value="12">
                {" "}
                {enumHelper.scheduleStatusEnum.paymentError.name}{" "}
              </option>
              <option value="1">
                {" "}
                {enumHelper.scheduleStatusEnum.waitingAccept.name}
              </option>
              <option value="2">
                {" "}
                {enumHelper.scheduleStatusEnum.accepted.name}
              </option>
              <option value="3">
                {" "}
                {enumHelper.scheduleStatusEnum.refused.name}{" "}
              </option>
              <option value="4">
                {" "}
                {enumHelper.scheduleStatusEnum.cancelByBackOffice.name}
              </option>
              <option value="5">
                {" "}
                {enumHelper.scheduleStatusEnum.cancelByUser.name}{" "}
              </option>
              <option value="11">
                {" "}
                {enumHelper.scheduleStatusEnum.finished.name}{" "}
              </option>
              <option value="15">
                {" "}
                {enumHelper.scheduleStatusEnum.processingPayment.name}
              </option>
              <option value="18">
                {" "}
                {enumHelper.scheduleStatusEnum.antiFraud.name}
              </option>
            </select>
            <b style={{ marginLeft: 10, marginRight: 10 }}>Estados:</b>
            <select
              onChange={this._handleAreaSelected}
              className="form-control"
            >
              <option value="">Todos</option>
              {areas}
            </select>
            <b style={{ marginLeft: 10, marginRight: 10 }}>Outros:</b>{" "}
            <input
              onKeyPress={this._handleKeyPress.bind(this)}
              value={this.state.filter}
              onChange={this._handleChange}
              type="text"
              width="300px"
              placeholder="Cliente, voucher, etc.."
              className="form-control"
            />
            <br /> <br />
            <b style={{ marginLeft: 10, marginRight: 10 }}>
              Solicitado em:
            </b>{" "}
            <input
              name="minDate"
              onChange={this._handleMinDateChange}
              type="date"
              width="300px"
              value={this.state.minDate}
              className="form-control"
            />
            <input
              name="maxDate"
              onChange={this._handleMaxDateChange}
              type="date"
              width="300px"
              value={this.state.maxDate}
              className="form-control"
              style={{ marginLeft: 10 }}
            />
            <button
              className="btn-info  btn btn-default"
              style={{ marginLeft: 10 }}
              onClick={this._handleClick}
            >
              <b>BUSCAR</b>
            </button>
          </center>

          {this.props.loading ? (
            <Loading />
          ) : (
            <Griddle
              ref="Griddle"
              noDataMessage="Nenhum dado foi encontrado com o filtro realizado, verifique novamente se o pedido se encontra no intervalo de datas correto ou se as informações passadas nos filtro estão corretas"
              results={realtimeOrders}
              columnMetadata={RealTimeOrdersMetadata.column}
              rowMetadata={RealTimeOrdersMetadata.row}
              resultsPerPage={50}
              useFixedLayout={false}
              tableClassName={"GriddleTable table"}
              useGriddleStyles={false}
              columns={[
                "serviceName",
                "name",
                "partnerName",
                "voucher",
                "status",
                "requestedOn",
                "shortCode",
                "id",
                "actions"
              ]}
              useExternal
              externalSetFilter={() => { }}
              externalSetPageSize={15}
              externalChangeSort={this.sort}
              externalSetPage={this.getPage}
              externalCurrentPage={this.props.currentPage - 1}
              externalMaxPage={this.props.numberOfPages}
            />
          )}
        </div>

        <br />

        <NotificationSystem ref="notificationSystem" />

        <Modal
          className="Modal Modal--large"
          show={this.state.cancelModalOpen}
          onHide={() => this.closeModal()} >

          <Modal.Header closeButton>
            <Modal.Title className="Modal-title">
              Cancelar pedido
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            Deseja cancelar o pedido Singu Agora?
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={() => this.closeModal()}>Fechar</Button>
            <Button className="btn btn-info" onClick={() => this.cancelRealTime(this)}>Cancelar pedido</Button>
          </Modal.Footer>
        </Modal>

        <Modal
          className="Modal Modal--large"
          modalDetails={this.state.details}
          show={this.state.isDetailsModalOpened}
          onHide={() => this.closeModal()}
        >
          <Modal.Header closeButton>
            <Modal.Title className="Modal-title">
              Detalhes do agendamento
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <ScheduleDetails
              rowData={this.state.details}
              notify={(msg, lvl) => this.notify(msg, lvl)}
              dataConfirmationAction={this.props.dataConfirmationAction}
              updateCallback={this.props.updateAddressAction}
            />
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={() => this.closeModal()}>Fechar</Button>
          </Modal.Footer>
        </Modal>

        <Modal
          className="Modal"
          modalDetails={this.state.details}
          show={this.state.isActionsModalOpened}
          onHide={() => this.closeModal()}
        >
          <Modal.Header closeButton>
            <Modal.Title className="Modal-title">
              Ações do agendamento
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="Modal-section clearfix">
              <div className="Modal-sectionTitle">Trocar profissional</div>
              <ChangePartner
                scheduleId={this.state.details.id}
                actualPartner={{
                  _id: this.state.details.partnerId,
                  email: this.state.details.partnerEmail,
                  name: this.state.details.partnerName,
                  formattedPhone: this.state.details.partnerPhone
                }}
                notify={(msg, lvl) => this.notify(msg, lvl)}
                updateCallback={this.props.updatePartnerAction}
                removeCallback={this.props.removePartnerAction}
              />
            </div>

            <div className="Modal-section clearfix">
              <div className="Modal-sectionTitle">
                TROCAR % A RECEBER DA SINGU
              </div>
              <UpdateTakeRate
                rowData={this.state.details}
                notify={(msg, lvl) => this.notify(msg, lvl)}
                updateCallback={this.props.updateTakeRateAction}
              />
            </div>

          </Modal.Body>
          <Modal.Footer>
            <Button onClick={() => this.closeModal()}>Fechar</Button>
          </Modal.Footer>
        </Modal>

      </div>
    );
  }
}

RealTimeOrdersGriddle.propTypes = {
  realtimeOrders: PropTypes.array,
  artists: PropTypes.array,
  vouchers: PropTypes.array,
  areas: PropTypes.array,
  getRealtimeOrders: PropTypes.func,
  currentPage: PropTypes.number,
  numberOfPages: PropTypes.number,
  cancelRealTimeOrderAction: PropTypes.func
};

export default RealTimeOrdersGriddle;
