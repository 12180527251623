import config from '../../../config.json';
const env = process.env.REACT_APP_ENVIRONMENT || 'stage';
const BACKEND_URL = config.api[env];
import axios from 'axios';

import { getToken } from '../../models/auth';

export const BATCH_VOUCHER = 'BATCH_VOUCHER';

export function batchVoucher(data) {
  return axios.post(`${BACKEND_URL}/voucher/batch`, data, {
    headers: { Authorization: `Bearer ${getToken()}` },
  });
}
