import React, { Component } from "react"
import Griddle from "griddle-react";
import { Button, Modal } from "react-bootstrap";
import { fetchPricesByService } from "../../actions/dynamic_prices/fetch_prices_by_service";

import ServiceEdit from "./Edit";


export default class AreasGriddle extends Component {
  constructor() {
    super()
    this.state = {
      showDetails: false,
      service: {},
      neighbors: [],
      filter: ""
    }
  }

  openDetailsModal = (service) => {
    fetchPricesByService(service.serviceId).then(({data}) => {
      this.setState({ service, neighbors: data.neighbors, showDetails: true });
    }).catch(error => {
      console.log(error);
    })
  }

  render() {
    const { services } = this.props
    return (
      <div className="row">
        <div className="row">
          <div className="col-xs-12 form-group">
            <label>Buscar: </label>
            <input
              type="text"
              className="form-control"
              defaultValue={this.state.filter}
              onChange={(evt) => this.setState({ filter: evt.target.value })}
            />
          </div>
        </div>
        <Griddle
          filterPlaceholderText={"Filtrar Resultados"}
          noDataMessage={"Não há dados para serem exibidos"}
          columns={["_id", "serviceId", "name", "actions"]}
          results={
            this.state.filter ? services.filter(item => {
              console.log(item)
              if (
                (item.name && item.name.includes(this.state.filter)) ||
                (item.serviceId && item.serviceId.includes(this.state.filter))
              ) return item;
              return false
            }) : services || []
          }
          showFilter={true}
          externalSetFilter={() => { }}
          resultsPerPage={10}
          showSettings={false}
          useFixedLayout={false}
          columnMetadata={[
            {
              columnName: "_id",
              displayName: "ID"
            },
            {
              columnName: "serviceId",
              displayName: "ID do serviço",
              customComponent: ({ rowData }) => {
                return <p>{rowData.serviceId}</p>
              }
            },
            {
              columnName: "name",
              displayName: "Nome",
              customComponent: ({ rowData }) => {
                return <p>{rowData.name}</p>
              }
            },
            {
              columnName: "actions",
              displayName: "Ações",
              customComponent: ({ rowData }) => {
                return (
                  <div style={{ display: "flex" }}>
                    <Button
                      onClick={() => {
                        this.openDetailsModal(rowData)
                      }}
                      className="ScheduleActions-btn btn btn-info"
                      title="Editar Serviço">
                      Editar
                    </Button>
                  </div>
                )
              }
            },
          ]}
          tableClassName={"GriddleTable table"}
          useGriddleStyles={false}
          settingsToggleClassName={"btn btn-default"}
        />
        <Modal bsSize="large" show={this.state.showDetails} onHide={() => this.setState({ showDetails: false, area: {} })}>
          <Modal.Header closeButton>
            <Modal.Title>Edição do serviço</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <ServiceEdit 
              services={this.state.service || {}}
              neighbors={this.state.neighbors || []} 
            />
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}