import React, { Component } from 'react';
import { Button } from "react-bootstrap";
import Swal from "sweetalert2"
import "./Style.css"

class Add extends Component {
  state = {
    message: ""
  }
  
  save = () => {
    const { message } = this.state;
    if (!message || !message.trim()) {
      Swal.fire({
        title: 'Mensagem inválida',
        text: "Por favor, escreva uma mensagem válida.",
        icon: 'warning'
      })
      return;
    }
    
    Swal.fire({
      title: 'Deseja mesmo inserir o comentário?',
      text: "Esta ação não poderá ser desfeita via sistema.",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sim, inserir!'
    }).then(result => {
      if (result.isConfirmed) {
        this.props.save(message.trim()).then(() => {
          this.setState({ message: "" });
          Swal.fire({
            title: 'Salvo com sucesso',
            text: "Seu comentário foi salvo com sucesso.",
            icon: 'success',
          })
        }).catch(err => {
          let _message = "Houve um erro ao salvar o comentário";
          if (err.data && err.data.error && err.data.error.description) _message = err.data.error.description
          
          Swal.fire({
            title: 'Erro ao salvar comentário',
            text: _message,
            icon: 'error',
          })
        })
      }
    })
    
  }
  
  render() {
    return (
      <div className="addComment">
        <div className="addComment-row">
          <h3>Adicionar Comentário</h3>
          <div className="col-xs-12">
            <textarea
              className="Form-input"
              type="text"
              style={{ width: '100%', height: '75px', marginBottom: '10px', resize: 'none' }}
              value={this.state.message}
              onChange={e => {this.setState({ message: e.target.value })}}
            />
          </div>
          <div className="col-xs-12">
            <Button
              className="addComment-btn"
              bsStyle="primary"
              onClick={this.save}>
              Adicionar Comentário
            </Button>
          </div>
        </div>
      </div>
    );
  }
}

export default Add;