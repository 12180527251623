"use strict";

import React, { Component } from "react";
import { Nav, NavItem } from 'react-bootstrap';
import EditService from './EditPackageService';
import EditVoucher from './EditPackageVoucher';
import EditPackageCategory from "./EditPackageCategory";

export default class EditPackage extends Component {
  constructor(props) {
    super(props);
    const { pack, area } = props;

    if (pack.title && pack.title["pt-BR"]) {
      pack.title = pack.title["pt-BR"];
    }
    if (pack.subtitle && pack.subtitle["pt-BR"]) {
      pack.subtitle = pack.subtitle["pt-BR"];
    }
    if (pack.serviceDescription && pack.serviceDescription["pt-BR"]) {
      pack.serviceDescription = pack.serviceDescription["pt-BR"];
    }
    pack.services = pack.services.map(service => {
      service.title = service.title["pt-BR"];
      return service;
    });
    delete pack.steps;
    this.state = {
      area: area || {},
      pack: Object.assign({}, pack),
      prepack: {},
      activeNavItem: pack.services.length === 0 ? 'Voucher' : 'Serviços',
      packageStructure: {
        title: "",
        subtitle: "",
        totalAmount: 0,
        discountAmount: 0,
        discountInPercent: 0,
        expiresAfterDays: 0,
        mobileImage: "",
        originalAmount: 0,
        serviceDescription: "",
        installmentMaxLimit: 1,
        services: [
          {
            count: 0,
            serviceList: [],
            title: "",
            value: 0
          }
        ]
      }
    };
  }

  onUpdatePack = (pack) => {
    this.setState({ pack })
  }

  savePackage = (evt) => {
    evt.preventDefault();

    if (this.state.pack.serviceDescription.length > 255) {
      alert("Descrição deve ter no máximo 255 caracteres.");
      return;
    }
    
    this.props
      .editPackage(this.state.area._id, this.state.pack)
      .then(() => {
        alert("Pacote salvo com sucesso.");
        this.props.handleClose();
        window.location.reload();
      })
      .catch(err => {
        alert("Não foi possível realizar a alteração do pacote.");
        console.log(err);
      });
  }

  handleFormEdit(evt) {
    const { value, name } = evt.target;
    const { pack } = this.state;
    pack[name] = value;
    this.setState({ pack });
  }

   handleNavItemSelection = (key) => {
    this.setState({ activeNavItem: String(key) })
  }

  renderId() {
    if (this.state.package && this.state.package._id) {
      return (
        <input type={"hidden"} name={"_id"} value={this.state.package._id} />
      );
    }
  }

  render() {
    const { area, pack, prepack } = this.state;
    return (
      <div className="row">
        <form onSubmit={this.savePackage}>
          {this.renderId()}
          <div className="col-xs-6 form-group">
            <label>Título</label>
            <input
              className="form-control form-control-normal"
              name={"title"}
              tabIndex={1}
              required
              value={this.state.pack.title ? this.state.pack.title : ""}
              onChange={this.handleFormEdit.bind(this)}
            />
          </div>
          <div className="col-xs-6 form-group">
            <label>Subtitulo</label>
            <input
              className="form-control form-control-normal"
              name={"subtitle"}
              tabIndex={2}
              required
              value={this.state.pack.subtitle ? this.state.pack.subtitle : ""}
              onChange={this.handleFormEdit.bind(this)}
            />
          </div>
          <div className="col-xs-12 form-group">
            <label>Url da Imagem</label>
            <input
              className="form-control form-control-normal"
              name={"mobileImage"}
              tabIndex={3}
              required
              value={this.state.pack.mobileImage}
              onChange={this.handleFormEdit.bind(this)}
            />
          </div>
          <div className="col-xs-12 form-group">
            <label>Descrição do Pacote</label>
            <input
              className="form-control form-control-normal"
              name={"serviceDescription"}
              tabIndex={3}
              maxLength={255}
              required
              value={this.state.pack.serviceDescription}
              onChange={this.handleFormEdit.bind(this)}
            />
          </div>
          <div className="col-xs-4 form-group">
            <label>Dias para expirar</label>
            <input
              type="number"
              className="form-control form-control-normal"
              name={"expiresAfterDays"}
              tabIndex={4}
              required
              value={this.state.pack.expiresAfterDays}
              onChange={this.handleFormEdit.bind(this)}
            />
          </div>
         
          <div className="clearfix" />
          <div>
            <Nav bsStyle="tabs" activeKey={this.state.activeNavItem} onSelect={k => this.handleNavItemSelection(k)}>
              <NavItem eventKey="Serviços" href="/home">
                Serviços
            </NavItem>
              <NavItem eventKey="Voucher" href="/home">
                Voucher
            </NavItem>
            <NavItem eventKey="Categorias" href="/home">
                Categorias
            </NavItem>
            </Nav>
            <div className="row">
              {this.state.activeNavItem === "Voucher" &&
                <EditVoucher pack={pack} />
              }
              {this.state.activeNavItem === "Serviços" &&
                <EditService pack={pack} prepack={prepack} area={area} />
              } 
              {this.state.activeNavItem === "Categorias" &&
                <EditPackageCategory pack={pack} areas={area} onUpdatePack={this.onUpdatePack} />
              } 
            </div>
          </div>
          <div className="text-right">
            <button
              type={"button"}
              className="btn btn-pack btn-default"
              onClick={this.props.handleClose}
            >
              Fechar
            </button>
            <button className="btn btn-pack btn-success" type="submit">
              Salvar
            </button>
          </div>
        </form>
      </div>
    );
  }
}
