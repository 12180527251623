
import moment from "moment"
import React, { Component } from "react";
import TextField from "../FormFields/TextField";
import SelectField from "../FormFields/SelectField";
import masks from "../../helper/masks";
import PageHeader from "../Global/PageHeader";
import RadioButtonGroup from "../Helpers/RadioButtonGroup";
import "../../style/form.css";
import formatters from '../../helper/formatters';
import CheckboxGroup from "../FormFields/CheckboxGroup";
import { getAddressByZipcode } from "../../actions/leads/get_address";

import { Toast } from '../../helper/alerts';
import {
  scheduleTypes,
  experienceTimeList,
  leadStatusList,
  formatLeadScheduleDates,
  getAvailableScheduleOptions,
  leadReproveReasonsList
} from '../../helper/leads';

const emptyAddress = {
  uf: '',
  complement: '',
  zipcode: '',
  streetName: '',
  streetNumber: '',
  neighborhood: '',
  city: '',
}

const emptyEvaluation = {
  _id: null,
  areaId: null,
  type: null,
  meetLink: null,
  date: null,
}

const emptyLead = {
  name: '',
  phoneNumber: '',
  email: '',
  cpf: '',
  address: emptyAddress,
  experience: '',
  categoryId: '',
  cities: [],
  areaId: '',
  specializations: '',
  statusId: 1,
  evaluation: emptyEvaluation,
  hasCertification: false,
  anotherCity: '',
  gender: 'F',
  indicationCode: '',
  isNaturaConsultant: false
}

const categoriesMaleNotAllow = ['c101', 'c104', 'c110'];
const categoriesRequireCertification = ['c101', 'c104', 'c104m', 'c108', 'c109', 'c110'];

const ufList = [
  {value: 'AC', text: 'AC'},
  {value: 'AL', text: 'AL'},
  {value: 'AM', text: 'AM'},
  {value: 'AP', text: 'AP'},
  {value: 'BA', text: 'BA'},
  {value: 'CE', text: 'CE'},
  {value: 'DF', text: 'DF'},
  {value: 'ES', text: 'ES'},
  {value: 'GO', text: 'GO'},
  {value: 'MA', text: 'MA'},
  {value: 'MG', text: 'MG'},
  {value: 'MT', text: 'MT'},
  {value: 'MS', text: 'MS'},
  {value: 'PA', text: 'PA'},
  {value: 'PB', text: 'PB'},
  {value: 'PE', text: 'PE'},
  {value: 'PI', text: 'PI'},
  {value: 'PR', text: 'PR'},
  {value: 'RJ', text: 'RJ'},
  {value: 'RN', text: 'RN'},
  {value: 'RO', text: 'RO'},
  {value: 'RR', text: 'RR'},
  {value: 'RS', text: 'RS'},
  {value: 'SC', text: 'SC'},
  {value: 'SE', text: 'SE'},
  {value: 'SP', text: 'SP'},
  {value: 'TO', text: 'TO'}
]

const regexInput = {
  'name': /\w(\s+)\w/g,
  'email': /(.)+@+(.)/g,
  'phoneNumber': /\((\d{2})\)\s\d\s(\d{4})-(\d{4})/g,
  'cpf': /(\d{3})\.(\d{3})\.(\d{3})-(\d{2})/g,
  'zipcode': /(\d{5})\-(\d{3})/g
}

const area_anotherCity = {
  value: 'anotherCity',
  text: 'Outra cidade'
}

export default class LeadForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      invalidInputs: [],
      selectedLeadScheduleDateID: null,
      selectedEvaluationDay: null,
      selectedEvaluationHour: null,
      availableDays: [],
      availableCities: [],
      availableCategories: [],
      certificationRequired: false,
      createArtist: false,
      ...emptyLead
    };

    const { lead, availableCities } = this.props;

    Object.assign(this.state, {availableCities: availableCities.concat(area_anotherCity)});

    if (lead) {
      const experience = experienceTimeList.find(exp => exp.text === lead.experience);

      Object.assign(this.state, {
        ...lead,
        areaId: lead.anotherCity ? 'anotherCity' : (lead.areaIds && lead.areaIds.length > 0) ? lead.areaIds[0] : '',
        categoryId: lead.categoryIds && lead.categoryIds.length > 0 ? lead.categoryIds[0] : '',
        phoneNumber: formatters.formatPhone(lead.phone),
        experience: experience ? experience.text : '',
        evaluation: emptyEvaluation,
        statusId: lead.status && lead.status[0] ? +lead.status[0].code : ''
      });

      const { evaluation } = lead;

      if (evaluation) {
        const evaluationDate = moment(evaluation.date).tz('America/Sao_Paulo');

        Object.assign(this.state, {
          evaluation: {
            _id: evaluation._id || '',
            areaId: evaluation.areaId || '',
            type: evaluation.type ? scheduleTypes.find(type => type.value === evaluation.type.code).value : '',
            date: evaluationDate || '',
            meetLink: evaluation.meetLink || ''
          },
          selectedScheduleDateId: evaluation._id || '',
          selectedEvaluationDay: evaluationDate.format('MM/DD/YY') || '',
          selectedEvaluationHour: evaluationDate.format('HH:mm') || '',
        });
      }
    }
  }

  componentWillMount() {
    const { lead, availableLeadScheduleDates } = this.props;
    const { evaluation, areaId } = this.state;

    if (areaId && areaId !== 'anotherCity') this.handleSelectArea(this.state.areaId);

    if (!lead || !lead.evaluation || !lead.evaluation._id) return;

    const { _id } = evaluation && availableLeadScheduleDates && availableLeadScheduleDates.find(leadScheduleDate => leadScheduleDate._id === evaluation._id) || {};

    const newEvaluation = Object.assign(evaluation, {
      ...evaluation,
      _id
    });

    this.setState({ evaluation: newEvaluation });
  }

  componentDidUpdate(_, prevState) {
    const { getAvailableLeadScheduleDates } = this.props;
    const { evaluation, categoryId } = this.state;

    if (!evaluation || !evaluation.areaId || !evaluation.type) return;

    if (prevState.categoryId !== categoryId) {
      return getAvailableLeadScheduleDates(evaluation, categoryId);
    }

    const prevEvaluation = prevState.evaluation;

    if ((!prevEvaluation.areaId && evaluation.areaId) || (!prevEvaluation.type && evaluation.type)) {
      return getAvailableLeadScheduleDates(evaluation, categoryId);
    }

    if (prevEvaluation.areaId !== evaluation.areaId || prevEvaluation.type !== evaluation.type) {
      return getAvailableLeadScheduleDates(evaluation, categoryId);
    }

  }

  handleSelectEvaluationStatus = (statusId) => {
    try {
      const { status } = this.props.lead;
      if (statusId === 11 && status[0].code !== 11) this.setState({ statusId, createArtist: true});
      else this.setState({ statusId, createArtist: false});
    }
    catch (err) {
      console.log(err);
    }
  }

  handleSelectEvaluationArea = (event) => {
    try {
      const areaId = event.target.value;

      this.setState({
        evaluation: {
          ...this.state.evaluation,
          areaId,
        },
        selectedEvaluationDay: null,
        selectedEvaluationHour: null,
        selectedScheduleDateId: null,
      });
    } catch (err) {
      console.error(err);
    }
  }

  handleSelectEvaluationType = (event) => {
    try {
      const type = +event.target.value;

      this.setState({
        evaluation: {
          ...this.state.evaluation,
          type,
          selectedEvaluationDay: null,
          selectedEvaluationHour: null,
          selectedScheduleDateId: null,
        }
      });
    } catch (err) {
      console.error(err);
    }
  }

  handleSelectLeadScheduleDateId = (event) => {
    try {
      const { availableLeadScheduleDates } = this.props;
      const { evaluation } = this.state;

      const leadScheduleDate = availableLeadScheduleDates.find(leadScheduleDate => leadScheduleDate._id === event.target.value);

      const newEvaluation = Object.assign(evaluation, {
        ...evaluation,
        _id: leadScheduleDate ? leadScheduleDate._id : null
      })

      this.setState({ evaluation: newEvaluation });
    } catch (err) {
      Toast().fire({
        icon: 'error',
        title: 'Algo deu errado. Tente novamente',
      })
    }
  }

  renderAvailableLeadScheduleDatesSelect = () => {
    const { availableLeadScheduleDates, loadingAvailableScheduleDates } = this.props;
    const { evaluation, categoryId } = this.state;

    if (!categoryId) return <p>Selecione a técnica executada.</p>;

    if ((evaluation && !evaluation.areaId || evaluation && !evaluation.type) || !availableLeadScheduleDates) return <p>Selecione uma área e um tipo de agendamento.</p>;

    if (loadingAvailableScheduleDates) return <span style={{ color: '#000' }} className="spinner glyphicon glyphicon-refresh" ></span>;

    if (availableLeadScheduleDates.length === 0) return <p>Não há opções de agendamento disponíveis.</p>;

    const formattedAvailableOptions = formatLeadScheduleDates(availableLeadScheduleDates);

    return (
      <select
        onChange={this.handleSelectLeadScheduleDateId}
        className="form-control form-control-normal"
        value={evaluation && evaluation._id || ''}
      >
        <option value="">Selecione uma opção</option>
        {formattedAvailableOptions.map(leadScheduleDate => {
          return (
            <option key={leadScheduleDate._id} value={leadScheduleDate._id}>
              {leadScheduleDate.label}
            </option>
          );
        })}
      </select>
    )
  }

  handleSelectEvaluationDay = (event) => {
    this.setState({ selectedEvaluationDay: event.target.value });
  }

  handleSelectEvaluationHour = (event) => {
    this.setState({ selectedEvaluationHour: event.target.value });
  }

  handleSelectArea = (areaId) => {
    const { availableCities, gender } = this.state;

    let { categories } = availableCities.filter(city => areaId === city.value)[0];
    if (gender === 'M') categories = categories.filter(category => !categoriesMaleNotAllow.includes(category._id));

    const availableCategories = categories.filter(category => category.active).map(category => ({
      value: category._id,
      text: category.activity
    }));

    this.setState({ areaId, availableCategories });
  }

  handleSelectCategory = (categoryId) => {
    const certificationRequired = categoriesRequireCertification.includes(categoryId);

    this.setState({categoryId, certificationRequired});
  }

  handleSelectGender = (gender) => {
    const { status } = this.state;
    if (status && status[0].code !== 1) {
      Toast(4000).fire({ icon: 'error', title: 'Não é possível alterar o sexo \nO lead já agendou ou possui um teste agendado'});
      return;
    }

    this.setState({gender, areaId: '', categoryId: '', availableCategories: []});
  }

  handleSelectZipCode = async (zipcode) => {
    const zipcodeUnmasked = zipcode.replace('-','');
    this.setState({address: {...this.state.address, zipcode}});

    try{
      if (zipcodeUnmasked.length === 8) {
        const {data} = await getAddressByZipcode(zipcodeUnmasked);
        
        this.setState({address: {
          ...this.state.address,
          uf: data?.uf || this.state.address.uf,
          streetName: data?.logradouro || this.state.address.streetName,
          neighborhood: data?.bairro || this.state.address.neighborhood,
          city: data?.localidade || this.state.address.city,
        }});
      }
    }
    catch (err) {
      Toast(4000).fire({ icon: 'error', title: 'Houve um erro ao buscar pelo CEP'});
    }
  }

  setInputValidation = (input, isValid) => {
    const { invalidInputs } = this.state;

    if (isValid && invalidInputs.includes(input)) this.setState({invalidInputs: invalidInputs.filter(invalidInput => invalidInput !== input)})
    if (!isValid && !invalidInputs.includes(input)) this.setState({invalidInputs: invalidInputs.concat(input)});
  }

  renderAvailableLeadScheduleDateAndHourSelects = () => {
    const { availableLeadScheduleDates } = this.props;

    const { evaluation, selectedEvaluationDay, selectedEvaluationHour } = this.state;

    if (!evaluation || !evaluation._id) return;

    const leadScheduleDate = availableLeadScheduleDates.find(leadScheduleDate => leadScheduleDate._id === evaluation._id);

    if (!leadScheduleDate) return;

    const { days, hours } = getAvailableScheduleOptions(leadScheduleDate);

    if (days.length === 0) return <p>Não há dias disponíveis para esse agendamento.</p>;

    return (
      <div className="col-sm-6">
        <div className="row">
          <div className="col-sm-6" style={{ marginBottom: '1rem' }}>
            <div className="Form-labelWrapper">
              <div className="Form-label">
                <span>Dia</span>
              </div>
            </div>
            <select
              onChange={this.handleSelectEvaluationDay}
              className="form-control form-control-normal"
              value={selectedEvaluationDay || ''}
            >
              <option value="">Selecione uma opção</option>
              {days.map(day => {
                return (
                  <option key={day.format('MM/DD/YY')} value={day.format('MM/DD/YY')}>
                    {day.format('DD/MM/YY')}
                  </option>
                );
              })}
            </select>
          </div>
          <div className="col-sm-6" style={{ marginBottom: '1rem' }}>
            <div className="Form-labelWrapper">
              <div className="Form-label">
                <span>Horário</span>
              </div>
            </div>
            <select
              onChange={this.handleSelectEvaluationHour}
              className="form-control form-control-normal"
              value={selectedEvaluationHour || ''}
            >
              <option value="">Selecione uma opção</option>
              {hours.map(hour => {
                return (
                  <option key={hour} value={hour}>
                    {hour}
                  </option>
                );
              })}
            </select>
          </div>
        </div>
      </div>
    )
  }

  renderStatusHistory = () => {
    const { status: statusHistory } = this.props.lead;

    if (!statusHistory || typeof statusHistory !== "object" || statusHistory.length < 1) {
      return (
        <div className="row">
          <div className="col-xs-12">
            <p>Nenhuma atualização de status registrada.</p>
          </div>
        </div>
      )
    }

    return (
      <div className="row">
        <div className="col-xs-12">
          <table className="table">
            <thead>
              <tr>
                <td>Status</td>
                <td>Data de alteração</td>
                <td>Alterado por</td>
              </tr>
            </thead>
            <tbody>
              {statusHistory.map(status => (
                <tr>
                  <td>
                    <span className="label label-info">
                      {status.description}
                    </span>
                  </td>
                  <td>{moment(status.date).format('DD/MM/YYYY [às] HH:mm:ss')}</td>
                  <td>{status.blame ? status.blame.email : 'Informação inexistente'}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    )
  }

  renderEvaluationsHistory = () => {
    const { evaluationHistory, evalutionHistory } = this.props.lead;

    if (!evaluationHistory && !evalutionHistory) {
      return (
        <div className="row">
          <div className="col-xs-12">
            <p>Nenhuma atualização de teste registrada.</p>
          </div>
        </div>
      )
    }

    const history = evaluationHistory || evalutionHistory;

    return (
      <div className="row">
        <div className="col-md-12">
          <table className="table">
            <thead>
              <tr>
                <td>Tipo</td>
                <td>Local</td>
                <td>Data</td>
                <td>Data de alteração</td>
                <td>Alterado por</td>
              </tr>
            </thead>
            <tbody>
              {history.map(evaluation => (
                <tr>
                  <td>
                    <span className="label label-info">
                      {evaluation.type && evaluation.type.description}
                    </span>
                  </td>
                  <td>{(evaluation.location && evaluation.location.formatted_address) ? evaluation.location.formatted_address : '-'}</td>
                  <td>{evaluation.date ? moment(evaluation.date).format(`DD/MM/YYYY [às] HH:mm:ss`) : 'Informação inexistente'}</td>
                  <td>{evaluation.updatedAt ? moment(evaluation.updatedAt).format(`DD/MM/YYYY [às] HH:mm:ss`) : 'Informação inexistente'}</td>
                  <td>{evaluation.blame ? evaluation.blame.email : 'Informação inexistente'}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    );
  }

  renderMeetLink = () => {
    try {
      const { evaluation } = this.state;
      if (!evaluation || evaluation?.type != 3) return;

      return (
        <div className="col-sm-6" style={{ marginBottom: '1rem' }}>
          <div className="Form-labelWrapper">
            <div className="Form-label">
              <span>Link para o teste</span>
            </div>
          </div >
          <input type="text"
            name="meetLink"
            className="form-control form-control-normal"
            placeholder="Nenhum link foi cadastrado"
            value={evaluation?.meetLink}
            onChange={evt => this.setState({
              evaluation: {
                ...evaluation, meetLink: evt.target.value
              }
            })}
          />
        </div >
      );
    } catch (e) {
      console.error(e);
      return;
    }
  }

  render() {
    const { lead } = this.props;
    const {
      name,
      email,
      cpf,
      phoneNumber,
      areaId,
      categoryId,
      experience,
      specializations,
      evaluation,
      statusId,
      reproveReasons,
      anotherCity,
      hasCertification,
      gender,
      address,
      availableCities,
      availableCategories,
      certificationRequired,
      indicationCode,
      isNaturaConsultant,
      createArtist
    } = this.state;

    const inputDataReasons = Object.assign({},{['value']:reproveReasons})

    return (
        <form onSubmit={(event) => this.props.onSubmit(event, this.state)}>
            <PageHeader title={ lead ? 'Editar Lead' : 'Adicionar Lead'}/>

            <div className="Form-formGroup">
                <div className="Modal-sectionTitle">Dados Pessoais</div>
                <div className="row">
                    <div className="col-sm-4">
                      <TextField
                        name="name"
                        label="Nome *"
                        inputData={{
                            value: name,
                            onChange: (name) => this.setState({ name }),
                            error: 'Formato de nome inválido' 
                        }}
                        placeholder="Insira aqui o nome completo"
                        regex= {{
                          rule: regexInput.name,
                          setInputValidation: (isValid) => this.setInputValidation('Nome', isValid)
                        }}
                      />
                    </div>          
                    <div className="col-sm-4">
                      <TextField
                        name="email"
                        label="Email *"
                        inputData={{
                            value: email,
                            onChange: (email) => this.setState({ email }),
                            error: 'Formato de email inválido'
                        }}
                        placeholder="Insira aqui o email"
                        regex={{
                          rule: regexInput.email,
                          setInputValidation: (isValid) => this.setInputValidation('Email', isValid)
                        }}
                      />
                    </div>
                    <div className="col-sm-2">
                      <TextField
                        name="phone"
                        label="Telefone *"
                        mask={masks.phone}
                        inputData={{
                            value: phoneNumber,
                            onChange: (phoneNumber) => this.setState({phoneNumber}),
                            error: 'Formato de telefone inválido'
                        }}
                        placeholder="Insira aqui o telefone"
                        regex={{
                          rule: regexInput.phoneNumber,
                          setInputValidation: (isValid) => this.setInputValidation('Telefone', isValid)
                        }}
                      />
                    </div>
                    <div className="col-sm-2">
                      <TextField
                        name="cpf"
                        label="CPF *"
                        mask={masks.cpf}
                        inputData={{
                            value: cpf,
                            onChange: (cpf) => this.setState({ cpf }),
                            error: 'Formato de CPF inválido'
                        }}
                        placeholder="Insira aqui o CPF"
                        regex={{
                          rule: regexInput.cpf,
                          setInputValidation: (isValid) => this.setInputValidation('CPF', isValid)
                        }}
                      />
                    </div>
                    <div className="col-sm-4 col-lg-3" style={{marginTop: 5}}>
                        <div className="Form-labelWrapper">
                          <div className="Form-label">
                            <span>Sexo *</span>
                          </div>
                        </div>
                        <RadioButtonGroup
                          required
                          onChange={(gender) => this.handleSelectGender(gender)}
                          selected={gender}
                          itemsList={[{text: 'Masculino', value: 'M'}, {text: 'Feminino', value: 'F'}]}
                        />
                    </div>
                </div>
            </div>

            <div className="Form-formGroup">
                <div className="Modal-sectionTitle">Endereço</div>
                <div className="row">
                    <div className="col-sm-2">
                      <TextField
                        name="zipcode"
                        label="CEP"
                        mask={masks.zipcode}
                        inputData={{
                            value: address.zipcode,
                            onChange: (zipcode) => this.handleSelectZipCode(zipcode),
                            error: 'Formato de CEP inválido'
                        }}
                        placeholder="Insira aqui o CEP"
                        regex={{
                          rule: regexInput.zipcode,
                          setInputValidation: (isValid) => this.setInputValidation('CEP', isValid)
                        }}
                      />
                    </div>
                    <div className="col-sm-4">
                      <TextField
                        name="streetName"
                        label="Logradouro"
                        inputData={{
                            value: address.streetName,
                            onChange: (streetName) => this.setState({ address: {...this.state.address, streetName}})
                        }}
                        placeholder="Insira aqui o logradouro"
                      />
                    </div>
                    <div className="col-sm-2">
                      <TextField
                        name="neighborhood"
                        label="Bairro"
                        inputData={{
                            value: address.neighborhood,
                            onChange: (neighborhood) => this.setState({ address: {...this.state.address, neighborhood}})
                        }}
                        placeholder="Insira aqui o bairro"
                      />
                    </div>
                    <div className="col-sm-2">
                      <TextField
                        name="city"
                        label="Cidade"
                        inputData={{
                            value: address.city,
                            onChange: (city) => this.setState({ address: {...this.state.address, city}})
                        }}
                        placeholder="Insira aqui a cidade"
                      />
                    </div>
                    <div className="col-sm-2">
                      <SelectField
                        name='uf'
                        label='Estado'
                        listValues={ufList || []}
                        inputData={{
                          value: address.uf,
                          onChange: (uf) => this.setState({address: {...this.state.address, uf}})
                        }}
                      />
                      </div>
                </div>
            </div>

            <div className="Form-formGroup">
              <div className="Modal-sectionTitle">Informações de Cadastro</div>
                <div className="row">
                  <div className="col-sm-3">
                    <SelectField
                      name='areaId'
                      label='Cidade de atendimento *'
                      listValues={availableCities || []}
                      inputData={{
                        value: areaId,
                        onChange: (areaId) => this.handleSelectArea(areaId)
                      }}
                    />
                  </div>

                  <div className="col-sm-3">
                    <SelectField
                      name='categoryId'
                      label={`Categoria * ${areaId === 'anotherCity' ? '(Indisponível)' : ''}`}
                      listValues={availableCategories || []}
                      inputData={{
                        value: this.state.categoryId,
                        onChange: (categoryId) => this.handleSelectCategory(categoryId)
                      }}
                      disabled={!!!availableCategories.length || !!!gender || areaId === 'anotherCity'}
                    />
                  </div>
                  <div className="col-sm-3" style={{display: certificationRequired ? 'block' : 'none'}}>
                    <div className="Form-labelWrapper">
                      <div className="Form-label">
                        <span>Possui certificado ? *</span>
                      </div>
                    </div>
                    <RadioButtonGroup
                      name='hasCertification'
                      required
                      onChange={(e) => this.setState({ hasCertification: e === 'true' })}
                      selected={hasCertification}
                      itemsList={[{ text: 'Sim', value: true }, { text: 'Não', value: false }]}
                    />
                  </div>
                  <div className="col-sm-4">
                    <TextField
                      name="indicationCode"
                      label="Código de Indicação"
                      inputData={{
                          value: indicationCode,
                          onChange: (indicationCode) => this.setState({ indicationCode })
                      }}
                      placeholder="Insira aqui o código de indicação"
                    />
                  </div>
                  <div className="col-sm-3" style={{marginTop: 5}}>
                    <div className="Form-labelWrapper">
                      <div className="Form-label">
                        <span>Consultora Natura ?</span>
                      </div>
                    </div>
                    <RadioButtonGroup
                      name='isNaturaConsultant'
                      required
                      onChange={(e) => this.setState({ isNaturaConsultant: e === 'true' })}
                      selected={isNaturaConsultant}
                      itemsList={[{ text: 'Sim', value: true }, { text: 'Não', value: false }]}
                    />
                  </div>
                </div>

                
            </div>

          <div className="Form-formGroup" style={{display: lead ? 'block' : 'none'}}>
          <div className="Modal-sectionTitle">Informações sobre o teste</div>
          <div className="row">
            {lead && <div className="col-sm-3" style={{ marginBottom: '1rem' }}>
              <div className="Form-labelWrapper">
                <div className="Form-label">
                  <span>Status do agendamento</span>
                </div>
              </div >
              <select
                onChange={(e) => this.handleSelectEvaluationStatus(Number(e.target.value))}
                className="form-control form-control-normal"
                value={statusId}
              >
                <option value="">Selecione uma opção</option>
                {leadStatusList.map(status => {
                  return (
                    <option key={status.value} value={status.value}>
                      {status.text}
                    </option>
                  );
                })}
              </select>
            </div>
            }
            <div className="col-sm-3" style={{ marginBottom: '1rem' }}>
              <div className="Form-labelWrapper">
                <div className="Form-label">
                  <span>Cidade</span>
                </div>
              </div >
              <select
                onChange={this.handleSelectEvaluationArea}
                className="form-control form-control-normal"
                value={evaluation && evaluation.areaId || ''}
                disabled={!categoryId}
              >
                <option value="">Selecione uma opção</option>
                {availableCities && availableCities.map(city => {
                  return (
                    <option key={city.value} value={city.value}>
                      {city.text}
                    </option>
                  );
                })}
              </select>
            </div >
            <div className="col-sm-3" style={{ marginBottom: '1rem' }}>
              <div className="Form-labelWrapper">
                <div className="Form-label">
                  <span>Tipo</span>
                </div>
              </div >
              <select
                onChange={this.handleSelectEvaluationType}
                className="form-control form-control-normal"
                value={evaluation && evaluation.type || ""}
                disabled={!categoryId}
              >
                <option value="">Selecione uma opção</option>
                {scheduleTypes && scheduleTypes.map(type => {
                  return (
                    <option key={type.value} value={type.value}>
                      {type.text}
                    </option>
                  );
                })}
              </select>
            </div >
            {lead && statusId===6 && <div className="col-sm-12" style={{marginBottom: '1rem'}}>
                <div className="Form-labelWrapper">
                  <div className="Form-label">
                    <span>Motivos da reprovação</span>
                  </div>
                </div>
                <CheckboxGroup
                  name='reproveReasons'
                  list={leadReproveReasonsList}
                  inputData={Object.assign({},inputDataReasons, {onChange:(value)=>this.setState({reproveReasons: value})})}
                  noLabel={true}
                />
            </div>
            }
            <div className="col-sm-6" style={{ marginBottom: '1rem' }}>
              <div className="Form-labelWrapper">
                <div className="Form-label">
                  <span>Opções disponíveis</span>
                </div>
              </div >
              {this.renderAvailableLeadScheduleDatesSelect()}
            </div >
            {this.renderMeetLink()}
            {this.renderAvailableLeadScheduleDateAndHourSelects()}
            {lead && (
              <div className="col-sm-12">
                <div className="panel-group" id="accordion" role="tablist" aria-multiselectable="true">
                  <div className="panel panel-default" style={{ minHeight: '40px' }}>
                    <div className="panel-heading" role="tab" id="headingOne" style={{ background: '#fff' }}>
                      <h4 className="panel-title">
                        <a
                          role="button"
                          data-toggle="collapse"
                          data-parent="#accordion"
                          href="#collapseOne"
                          aria-expanded="false"
                          aria-controls="collapseOne"
                          style={{ textDecoration: 'none' }}
                        >
                          Histórico de Status
                        </a>
                      </h4>
                    </div>
                    <div id="collapseOne" className="panel-collapse collapse" role="tabpanel" aria-labelledby="headingOne">
                      <div className="panel-body" style={{ height: 'auto' }}>
                        {this.renderStatusHistory()}
                      </div>
                    </div>
                  </div>
                  <div className="panel panel-default" style={{ minHeight: '40px', marginTop: 10 }}>
                    <div className="panel-heading" role="tab" id="headingTwo" style={{ background: '#fff' }}>
                      <h4 className="panel-title">
                        <a
                          className="collapsed"
                          role="button"
                          data-toggle="collapse"
                          data-parent="#accordion"
                          href="#collapseTwo"
                          aria-expanded="false"
                          aria-controls="collapseTwo"
                          style={{ textDecoration: 'none' }}
                        >
                          Histórico de Agendamentos
                        </a>
                      </h4>
                    </div>
                    <div id="collapseTwo" className="panel-collapse collapse" role="tabpanel" aria-labelledby="headingTwo">
                      <div className="panel-body" style={{ height: 'auto' }}>
                        {this.renderEvaluationsHistory()}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div >
        <div className="Form-submitWrapper">
          <div className="row">
            <div className="col-sm-12">
              <button className="Form-submit" type="submit" disabled={this.props.submitting}>
                <span>{createArtist ? 'Transformar em Artista': lead ? 'Atualizar Lead' : 'Cadastrar lead'}</span>
                <span className="ss-users ss-icon" />
              </button>
            </div>
          </div>
        </div>
      </form>
    );
  }
}