import React, { Component } from 'react';
import { Modal, Button, Label } from "react-bootstrap";
import moment from 'moment-timezone';

class ArtistBonusStatus extends Component {
  render() {
    return (
      <Modal show={this.props.open} onHide={this.props.closeBonusStatus}>
        <Modal.Header closeButton>
          <Modal.Title>Histórico do Bônus</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="Modal-section">
            {this.props.historyBonus.length ? this.props.historyBonus.map((element) => (
              <div className="ScheduleDetails-history">
                <p>
                  <Label bsStyle={`${element.approved ? 'success' : 'danger'}`}>{element.approved ? 'Aprovado' : 'Reprovado'}</Label>
                </p>
                <p>
                  <b>Comentário: </b>
                  {element.comment}
                </p>
                <p>
                  <b>Data: </b>
                  {moment.tz(element.at, 'America/Sao_Paulo').format('DD/MM/YY HH:mm')}
                </p>
                <p>
                  <b>Usuário: </b>
                  {`${element.by.name} - ${element.by.email}`}
                </p>
              </div>
            )) : <p className="text-center">Sem histórico de bônus</p>}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={this.props.closeBonusStatus}>
            Fechar
          </Button>
        </Modal.Footer>
      </Modal>
    )
  }
}

export default ArtistBonusStatus;