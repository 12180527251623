import config from '../../../config.json';
const env = process.env.REACT_APP_ENVIRONMENT || 'stage';
const BACKEND_URL = config.api[env];
import axios from 'axios';

import { getToken } from './../../models/auth';

export const FETCH_USERS = 'FETCH_USERS';

export function fetchUsers(page, filter) {
  let params = {
    itemsPerPage: 10,
    page: page,
    filter
  };

  const usersPromise = axios.get(`${BACKEND_URL}/v2/bo/users`, {
    headers: { Authorization: `Bearer ${getToken()}` },
    params
  });

  return {
    type: FETCH_USERS,
    payload: usersPromise
  };
}
