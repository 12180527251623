import React, { Component } from 'react'
import moment from 'moment'

class InvestimentFilter extends Component {
    constructor(props){
        super(props)

        this.state = {
            minDate: moment().subtract({ days: 5 }).format('yyyy-MM-DD'),
            maxDate: moment().add({ days: 5 }).format('yyyy-MM-DD')
        }
    }

    _handleMinDateChange = minDate => this.setState({ minDate })

    _handleMaxDateChange = maxDate => this.setState({ maxDate })

    _handleClick = () => {
        this.props._handleOnFilter(this.state.minDate, this.state.maxDate)
    }

    render(){
        return (
            <div className='d-flex justify-content-center' style={{ margin: '1rem 0' }}>
                <b style={{ marginLeft: 10, marginRight: 10 }}>
                  Inserido entre:
                </b>{" "}
                <input
                  name="minDate"
                  onChange={e => this._handleMinDateChange(e.target.value)}
                  type="date"
                  width="300px"
                  value={this.state.minDate}
                  className="form-control"
                />
                <input
                  name="maxDate"
                  onChange={e => this._handleMaxDateChange(e.target.value)}
                  type="date"
                  width="300px"
                  value={this.state.maxDate}
                  className="form-control"
                  style={{ marginLeft: 10 }}
                />
                <button
                  className="btn-info  btn btn-default"
                  style={{ marginLeft: 10 }}
                  onClick={this._handleClick}
                >
                  <b>FILTRAR</b>
                </button>
            </div>
        )
    }
}

export default InvestimentFilter