import React, { Component } from 'react';
import FetchComments from '../../actions/comments/fetch_comments';
import AddComments from '../../actions/comments/add_comment';
import List from '../../components/Comments/List';
import Add from '../../components/Comments/Add';
import Swal from 'sweetalert2';

class Comments extends Component {
  constructor(props) {
    super(props);
    this.state = {
      comments: []
    };
  }
  
  componentWillMount() {
    const { comments, id, type } = this.props
    if ((!comments || comments.length === 0) && type && id) this.fetchComments()
    else if (comments && comments.length > 0) this.setState({ comments: comments.reverse().filter(element => typeof element === 'object') });
  }
  
  addComment = (message) => {
    const { type, id } = this.props
    return new Promise((resolve, reject) => {
      AddComments({ message, type, id }).then(response => {
        this.fetchComments();
        resolve(response);
      }).catch(reject)
    });
  }
  
  fetchComments = () => {
    const { type, id } = this.props;
    FetchComments({ type, id }).then(response => {
      this.setState({ comments: response.data });
    }).catch(() => {
      Swal.fire({
        title: "Ops...",
        icon: "error",
        text: "Erro ao recuperar comentários"
      })
    });
  }
  
  render() {
    const { comments } = this.state;
    
    return (
      <div id="artistComments">
        <List comments={comments}/>
        {this.props.enableNewComment && (<Add save={this.addComment}/>)}
      </div>
    )
    
  }
}

export default Comments;
