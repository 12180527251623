import React, {Component} from 'react';
import blockProfessionalAction from "../../actions/user_info/block_professional";
import Swal from "sweetalert2"

import './styles.css';

class BlockProfessionalComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
          blocking: {
            status: "READY"
          }
        }
        
    }
    

    blockProfessionalModal = () => {
        Swal.fire({
          title: 'Confirmar seleção',
          text: "Desejar adicionar essa profissional na lista de \"Profissionais Bloqueadas\" da cliente?",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Sim, adicionar!',
          cancelButtonText: 'Cancelar'
        }).then((result) => {
          if (result.isConfirmed) {
            this.setState({ blocking: { status: "LOADING" } });
            blockProfessionalAction(this.props.partnerId, this.props.userId).then(() => {
              Swal.fire({
                title: "Sucesso",
                text: "Profissional adicionada na lista de bloqueios do cliente",
                icon: "success"
              });
            }).catch(err => {
              let message = "Houve um erro ao efetuar mudança";
              if (err && err.response && err.response.data && err.response.data.description) message = err.response.data.description;
              Swal.fire({
                title: "Ops...",
                text: message,
                icon: "error"
              })
            })
            .finally(() => this.setState({ blocking: { status: "READY" } }));
          }
        })
      }

    render(){
        
        return(
            <a
                className={"BlockProfessional"}
                onClick={() => {this.state.blocking.status === "READY" && this.blockProfessionalModal()}}>
                {this.state.blocking.status === "LOADING" ? "BLOQUEANDO" : "BLOQUEAR"}
            </a>
        )
    }

}

export default BlockProfessionalComponent;