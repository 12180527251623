import config from '../../../config.json';
const env = process.env.REACT_APP_ENVIRONMENT || 'stage';
const BACKEND_URL = config.api[env];
import axios from 'axios';

import { getToken } from './../../models/auth';

export function updateStatustransfers(ids, approved, comment) {
  return axios.post(
    `${BACKEND_URL}/transfer/order/approval`,
    { ids, approved, comment },
    { headers: { Authorization: `Bearer ${getToken()}` } }
  );
}

