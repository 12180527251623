export const cacList = [
    { value: 'new-client', text: 'Cliente Aquisição' },
    { value: 'recurrent-client', text: 'Cliente Retencão' },
    { value: 'new-artist', text: 'Profissional Aquisição' },
    { value: 'recurrent-artist', text: 'Profissional Retenção' }
]

export const sectorList = [
    { value: 'Marketing', text: 'Marketing' },
    { value: 'Financeiro', text: 'Financeiro' },
    { value: 'Operacao', text: 'Operação' },
    { value: 'Atendimento', text: 'Atendimento' },
    { value: 'Sinergia', text: 'Sinergia' }
]

export const serviceList = [
    { value: 'Geral', text: 'Geral' },
    { value: 'Manicure', text: 'Manicure' },
    { value: 'Massagem', text: 'Massagem' },
    { value: 'Outros', text: 'Outros' },
]

export const dictionaryCac = {
    'new-client': 'Cliente Aquisição',
    'recurrent-client': 'Cliente Retenção',
    'new-artist': 'Profissional Aquisição',
    'recurrent-artist': 'Profissional Retenção',
}

export const dictionarySector = {
    'Marketing': 'Marketing',
    'Financeiro': 'Financeiro',
    'Operacao': 'Operação',
    'Atendimento': 'Atendimento',
    'Sinergia': 'Sinergia'
}

export const columns = [
    'value',
    'sector',
    'cac',
    'category',
    'service',
    'date',
    'description',
    'actions'
]