import React, { Component } from "react";
import { Row, Col, Button } from 'react-bootstrap';

import AreaService from "../../services/AreaService";
import Swal from "sweetalert2";

const initialState = {
  programado: {
    fixo: 0,
    flex: 0
  },
  now: {
    fixo: 0,
    flex: 0
  },
  superNow: {
    fixo: 0,
    flex: 0
  },
  serviceId: "",
  service: "",
  areaId: "",
  isFormLoading: false,
  isFormDisabled: true
};

export default class AreaServicePrice extends Component {
  areaService = AreaService
  constructor(props) {
    super(props);
    this.state = {
      ...initialState,
      isFormDisabled: props.isFormDisabled
    };
  }

  componentDidMount() {
    if (this.props.serviceId && this.props.areaId) {
      this.getAreaServicePrice();
    }
  }

  async getAreaServicePrice() {
    try {
      const serviceId = this.props.serviceId;
      const areaId = this.props.areaId;

      const lastPriceChange = await this.areaService.getLastPriceChange(areaId, serviceId);
      this.setState({
        programado: lastPriceChange.programado,
        now: lastPriceChange.now,
        superNow: lastPriceChange.superNow,
      })
    } catch (err) {
      console.error(err)
    }
  }

  handleSubmit = async (e) => {
    e.preventDefault();
    this.setState({ isFormLoading: true });
    try {
      const { programado, now, superNow } = this.state;
      const serviceId = this.props.serviceId;
      const areaId = this.props.areaId;

      const price = {
        programado,
        now,
        superNow
      }

      await this.areaService.changeServicePrice(areaId, serviceId, price);
      Swal.fire({
        title: 'Sucesso!',
        text: 'Preço alterado com sucesso!',
        icon: 'success',
        confirmButtonText: 'OK'
      })
    } catch (err) {
      Swal.fire({
        title: 'Erro!',
        text: 'Erro ao alterar preço!',
        icon: 'error',
        confirmButtonText: 'OK'
      })
      console.error(err)
    } finally {
      this.setState({ isFormLoading: false });
    }
  }

  render() {
    const {
      now,
      programado,
      superNow,
      isFormLoading,
      isFormDisabled
    } = this.state;


    return (
      <Row>
        <Col md={12}>
          <form onSubmit={this.handleSubmit}>
            <h3>Valores: </h3>

            <Col md={12}>
              <Col md={12}>
                <p>
                  Programado:
                </p>
              </Col>
              <Col md={6}>
                <div className="form-group">
                  <label htmlFor="programadoFixo">Fixo</label>
                  <input
                    type="number"
                    min={0}
                    className="Form-input"
                    value={programado.fixo ?? 0}
                    id="programadoFixo"
                    fixo="Valor"
                    onChange={(e) => this.setState({
                      programado: {
                        ...programado,
                        fixo: e.target.value
                      }
                    })}
                    required
                  />
                </div>
              </Col>
              <Col md={6}>
                <div className="form-group">
                  <label htmlFor="programadoFlex">Flex</label>
                  <input
                    type="number"
                    min={0}
                    className="Form-input"
                    value={programado.flex ?? 0}
                    id="programadoFlex"
                    placeholder="Valor"
                    onChange={(e) => this.setState({
                      programado: {
                        ...programado,
                        flex: e.target.value
                      }
                    })}
                    required
                  />
                </div>
              </Col>
            </Col>
            <Col md={12}>
              <Col md={12}>
                <p>
                  Now:
                </p>
              </Col>
              <Col md={6}>
                <div className="form-group">
                  <label htmlFor="nowFixo">Fixo</label>
                  <input
                    type="number"
                    min={0}
                    className="Form-input"
                    value={now.fixo ?? 0}
                    id="nowFixo"
                    placeholder="Valor"
                    onChange={(e) => this.setState({
                      now: {
                        ...now,
                        fixo: e.target.value
                      }
                    })}
                    required
                  />
                </div>
              </Col>
              <Col md={6}>
                <div className="form-group">
                  <label htmlFor="nowFlex">Flex</label>
                  <input
                    type="number"
                    min={0}
                    className="Form-input"
                    value={now.flex ?? 0}
                    id="nowFlex"
                    placeholder="Valor"
                    onChange={(e) => this.setState({
                      now: {
                        ...now,
                        flex: e.target.value
                      }
                    })}
                    required
                  />
                </div>
              </Col>
            </Col>
            <Col md={12}>
              <Col md={12}>
                <p>
                  Super Now:
                </p>
              </Col>
              <Col md={6}>
                <div className="form-group">
                  <label htmlFor="superNowFixo">Fixo</label>
                  <input
                    type="number"
                    min={0}
                    className="Form-input"
                    value={superNow.fixo ?? 0}
                    id="superNowFixo"
                    placeholder="Valor"
                    onChange={(e) => this.setState({
                      superNow: {
                        ...superNow,
                        fixo: e.target.value
                      }
                    })}
                    required
                  />
                </div>
              </Col>
              <Col md={6}>
                <div className="form-group">
                  <label htmlFor="superNowFlex">Flex</label>
                  <input
                    type="number"
                    min={0}
                    className="Form-input"
                    value={superNow.flex ?? 0}
                    id="superNowFlex"
                    placeholder="Valor"
                    onChange={(e) => this.setState({
                      superNow: {
                        ...superNow,
                        flex: e.target.value
                      }
                    })}
                    required
                  />
                </div>
              </Col>
            </Col>

            <Row>
              <Col sm={12}>
                {
                  isFormLoading ?
                    (
                      <Button type="submit" bsStyle="info" className="button-icon" bsSize="large" disabled={isFormLoading}>
                        <span className="spinner glyphicon glyphicon-refresh" ></span>
                        Salvando...
                      </Button>
                    ) :
                    (
                      <Button type="submit" bsStyle="info" bsSize="large" disabled={isFormDisabled}>
                        Salvar
                      </Button>
                    )
                }
              </Col>
            </Row>
          </form>
        </Col>
      </Row>
    )

  }
}