import React, { PropTypes } from 'react';

const ReadOnlyField = props => {
  return (
    <div className="ReadOnlyField">
      <div className="ReadOnlyField-wrapper">
        <div className="ReadOnlyField-title"><b>{props.title}</b></div>
        <div className="ReadOnlyField-info">{props.info}</div>
      </div>
    </div>
  );
};

ReadOnlyField.propTypes = {
  title: PropTypes.string,
  info: PropTypes.string
};

export default ReadOnlyField;
