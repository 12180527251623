import moment from "moment";
import React, { Component } from "react";


class UserLinkComponent extends Component {
  render() {
    const url = `#/users/${this.props.rowData.idClient}`;

    return (
      <a href={url} target="_blank">
        {this.props.data}
      </a>
    );
  }
}

class DatePaidComponent extends Component {
  render() {
    return <div>{moment(this.props.data).isValid() ? moment(this.props.data).format("DD/MM/YYYY HH:mm") : "--"}</div>;
  }
}

class StatusSchedule extends Component {
  status = {
    IN_ANALYSIS: <b>Em análise</b>,
    AUTHORIZED: <b>Autorizado</b>,
    CANCELLED: <b>Cancelado</b>,
  };

  render() {
    return this.props.data in this.status ? (
      this.status[this.props.data]
    ) : (
      <div>--</div>
    );
  }
}

const airportsMetadata = [
  {
    columnName: "airport",
    order: 0,
    locked: false,
    visible: true,
    displayName: "Aeroporto",
  },
  {
    columnName: "userName",
    order: 0,
    locked: false,
    visible: true,
    displayName: "Nome do cliente",
    customComponent: UserLinkComponent,
  },
  {
    columnName: "idSchedule",
    order: 1,
    locked: false,
    visible: true,
    displayName: "ID do pedido",
  },
  {
    columnName: "idClient",
    order: 2,
    locked: false,
    visible: true,
    displayName: "ID do cliente",
  },
  {
    columnName: "updatedAt",
    order: 3,
    locked: false,
    visible: true,
    displayName: "Data de pagamento",
    customComponent: DatePaidComponent,
  },
  {
    columnName: "status",
    order: 4,
    locked: false,
    visible: true,
    displayName: "Status",
    customComponent: StatusSchedule,
  },
];

export default airportsMetadata;
