import config from '../../../config.json';
const env = process.env.REACT_APP_ENVIRONMENT || 'stage';
const BACKEND_URL = config.api[env];
import axios from 'axios';

import { getToken } from './../../models/auth';

export const UPDATE_SCHEDULE_ADDRESS = 'UPDATE_SCHEDULE_ADDRESS';

export function updateScheduleAddress(scheduleId, data) {
  const addressUpdatePromise = axios.put(`${BACKEND_URL}/v2/bo/schedule/changeAddress/${scheduleId}`,
    data,
    {
      headers: { Authorization: `Bearer ${getToken()}` }
    }
  );

  return {
    type: UPDATE_SCHEDULE_ADDRESS,
    payload: addressUpdatePromise,
    meta: { data, id: scheduleId }
  };
}
