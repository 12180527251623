/* global _ */
import React, { Component } from "react";
import { Modal, Button, Alert } from "react-bootstrap";
import Swal from "sweetalert2";
import moment from "moment";

import {
  newSchedule,
  getServerTime,
} from "../../actions/schedules/save_schedule";
import { getPrice } from "../../actions/schedules/get_prices";
import { getArea } from "../../actions/areas/get_area";
import { getVoucher } from "../../actions/vouchers/get_voucher";
import { generateUserToken } from "../../actions/users/generate_token";
import { calculateDynamicPrice, handleMinCheckoutDate } from "./dynamicPrice";
import { config } from '@singu/core';

class ScheduleModal extends Component {
  constructor(props) {
    super(props);

    const startDate = moment().add(1, "day");

    this.state = {
      area: {},
      service: {},
      numberOfClients: 1,
      linkPayment: false,
      note: "",
      useSinguCredits: false,
      realtime: false,
      scheduleByWpp: false,
      onlyForPreference: false,
      creditCardId: "",
      massageTable: false,
      voucher: null,
      isFormLoading: false,
      serverTime: null,
      user: {
        ...props.user,
        _id: props.user.id,
        email: props.user.email,
        hasSignature: props.user.hasSignature === "Sim",
        numberOfSchedules: props.user.numberOfSchedules,
      },
      dateRangeStart: moment(startDate).set({
        hour: 7,
        minute: 0,
        second: 0,
        millisecond: 0,
      }),
      dateRangeEnd: moment(startDate).set({
        hour: 22,
        minute: 0,
        second: 0,
        millisecond: 0,
      }),
    };
  }

  componentDidMount() {
    (async () => {
      try {
        const { user } = this.props;
        if (!user || !user.addresses || user.addresses.length < 1) {
          Swal.fire(
            "Erro",
            "Não é possível criar um agendamento para este usuário pois ele não possui um endereço",
            "warning"
          );
          this.props.hideModal();
          return;
        }

        const { data } = await generateUserToken(user);
        const { data: time } = await getServerTime();
        this.setState({ serverTime: time });

        if (data && data.token) {
          this.setState({ token: data.token });
        }
      } catch (error) {
        console.error(error);
      }
    })();
  }

  validateDates = (dateRangeStart, dateRangeEnd, minDate, prices) => {
    const { startHour, endHour } = prices.time.flex;
    dateRangeStart = moment(dateRangeStart);
    dateRangeEnd = moment(dateRangeEnd);

    if (+dateRangeStart.tz(config.timezone).format('HH') < startHour) {
      throw { description: `O horário minimo para agendamento é ${startHour}:00` };
    }

    if (dateRangeEnd.tz(config.timezone).format('HH') > endHour) {
      throw { description: `O horário máximo para agendamento é ${endHour}:00` };
    }

    const formattedMinDate = moment(minDate).tz(config.timezone).format('DD/MM/YY - HH:mm');

    if (moment(minDate).isAfter(dateRangeStart)) {
      throw {
        description: `A data minima do agendamento é ${formattedMinDate}`
      }
    }

    if (moment().isAfter(dateRangeStart)) {
      throw {
        description:
          "A data e horário do pedido não pode ser menor que o horário atual",
      };
    }

    if (dateRangeStart.isAfter(dateRangeEnd)) {
      throw {
        description: "A data e horário final devem ser maior que a inicial",
      };
    }
  };

  parseSchedule = (data) => {
    const {
      selectedAddress,
      area,
      numberOfClients,
      service,
      user,
      useSinguCredits,
      listPreferenceByProfessionals,
      onlyForPreference,
      note,
      creditCardId,
      linkPayment,
      categoryId,
      code,
      realtime,
      scheduleByWpp
    } = data;
    let { dateRangeStart, dateRangeEnd } = data;

    dateRangeStart = moment(dateRangeStart).toDate();
    dateRangeEnd = moment(dateRangeEnd).toDate();

    const hasPreference =
      listPreferenceByProfessionals && listPreferenceByProfessionals.length > 0;

    const transactionId =
      Math.random().toString(36).substring(2, 15) +
      Math.random().toString(36).substring(2, 15);

    const schedule = {
      testCard: false,
      linkPayment: linkPayment,
      transactionId,
      scheduleDateType: realtime ? "FIXED" : "FLEX",
      asyncPayment: true,
      categoryId,
      addressId: selectedAddress.id,
      areaId: area._id,
      date: moment(dateRangeStart).toDate(),
      dateRangeStart: moment(dateRangeStart).toDate(),
      dateRangeEnd: moment(dateRangeEnd).toDate(),
      numberOfClients,
      serviceId: service._id,
      timestamp: new Date().getTime(),
      user: {
        _id: user.id,
      },
      useSinguCredits,
      listPreferenceByProfessionals,
      onlyForPreference,
      beginCheckout: new Date().toISOString(),
      hasPreference,
      voucher: code || "",
      realtime: realtime || false,
      scheduleByWpp: scheduleByWpp || false
    };

    if (creditCardId) Object.assign(schedule, { creditCardId });
    if (note) Object.assign(schedule, { note });

    return schedule;
  };

  loadServices = async (areaId) => {
    try {
      const { data: area } = await getArea(areaId);
      this.setState({ area });
    } catch (error) {
      console.error(error);
      Swal.fire("Erro", "Houve um erro ao carregar os serviços.", "error");
    }
  };

  displayProfessional() {
    const professionalPreferences = this.props.professionalPreferences;
    if (professionalPreferences && professionalPreferences.length) {
      return _.map(professionalPreferences, (partner, i) => {
        return (
          <div>
            <label style={{ marginLeft: 20 }} className="checkbox" key={i}>
              <input
                type="checkbox"
                name={partner.name}
                value={JSON.stringify(partner)}
                onChange={({ target }) => this.handlePreference(target)}
              />
              {partner.name}
            </label>
          </div>
        );
      });
    } else {
      return <p>Essa cliente não possui preferencia por profissional</p>;
    }
  }

  handleSelectAddress = (address) => {
    const { areaId } = address;
    this.setState({ selectedAddress: address });
    this.loadServices(areaId);
  };

  displayAddresses() {
    const { user } = this.props;

    if (user) {
      if (!user.addresses || user.addresses.length < 1) return;

      const addresses = _.map(user.addresses, (address, i) => {
        return (
          <label style={{ marginLeft: 20 }} className="radio" key={i}>
            <input
              required
              type="radio"
              name="option"
              value={JSON.stringify(address)}
              onChange={({ target }) =>
                this.handleSelectAddress(JSON.parse(target.value))
              }
            />
            {address.text}
          </label>
        );
      });

      return addresses;
    }
  }

  calculateValue = () => {
    try {
      const { service, user, prices, serverTime } = this.state;
      let { dateRangeStart, dateRangeEnd } = this.state;

      if (!service || !user || !prices) return;

      const scheduleDateType = "FLEX";

      dateRangeStart = moment(dateRangeStart).clone();
      dateRangeEnd = moment(dateRangeEnd).clone();

      const minDate = handleMinCheckoutDate(serverTime.date, prices);

      const schedule = {
        services: [service],
        user,
        requestedOn: serverTime.date,
        dateRangeStart,
        dateRangeEnd,
        dynamicPrice: prices,
        scheduleDateType,
        date: dateRangeStart,
      };

      const schedulePrices = calculateDynamicPrice(schedule);

      const flexPrice = schedulePrices.flexPrice;
      const fixPrice = schedulePrices.fixPrice;
      const schedulePrice = schedulePrices.newOriginalValue;

      console.info(schedulePrices, moment(minDate).format());

      return { prices, flexPrice, fixPrice, schedulePrice, minDate };
    } catch (error) {
      console.error("calculateValue", error);
      Swal.fire("Erro", "Houve um erro ao calcular o preço dinâmico", "error");
    }
  };

  changeSelectedService = async (service) => {
    this.setState({ isFormLoading: true });
    const { area, selectedAddress } = this.state;
    const { lat, lng } = selectedAddress;

    const categoryId = service._id.substr(0, 4);
    const { data } = await getPrice(service._id, area._id, lat, lng);
    if (categoryId !== "c101") this.setState({ massageTable: false });
    this.setState({ prices: data, service, categoryId, isFormLoading: false });
  };

  handleChangeDate = ({ value }) => {
    const { dateRangeStart, dateRangeEnd } = this.state;

    const hourStart = moment(dateRangeStart).format("HH");
    const minutesStart = moment(dateRangeStart).format("mm");
    const hourEnd = moment(dateRangeEnd).format("HH");
    const minutesEnd = moment(dateRangeEnd).format("mm");

    this.setState({
      dateRangeStart: moment(value).set({
        hour: hourStart,
        minutes: minutesStart,
        seconds: 0,
        millisecond: 0,
      }),

      dateRangeEnd: moment(value).set({
        hour: hourEnd,
        minutes: minutesEnd,
        seconds: 0,
        millisecond: 0,
      }),
    });
  };

  setTimeRange = ({ target }) => {
    const { dateRangeStart, dateRangeEnd } = this.state;
    const hour = target.value.split(":")[0];
    const minutes = target.value.split(":")[1];

    if (target.name === "start") {
      this.setState({
        dateRangeStart: moment(dateRangeStart).set({
          hour,
          minutes,
          second: 0,
          millisecond: 0,
        }),
      });
    } else {
      this.setState({
        dateRangeEnd: moment(dateRangeEnd).set({
          hour,
          minutes,
          second: 0,
          millisecond: 0,
        }),
      });
    }
  };

  incrementNumberOfClients = () => {
    const { numberOfClients } = this.state;

    if (numberOfClients >= 4) return;

    this.setState({
      numberOfClients: numberOfClients + 1,
    });
  };

  decrementNumberOfClients = () => {
    const { numberOfClients } = this.state;

    if (numberOfClients <= 1) return;

    this.setState({
      numberOfClients: this.state.numberOfClients - 1,
    });
  };

  displayCreditCards() {
    const user = this.props.user;

    if (user) {
      if (!user.creditCards || user.creditCards.length < 1) {
        return (
          <div className="UserInfo-subSectionInfo">
            Nenhum cartão de crédito cadastrado
          </div>
        );
      }

      const creditCars = _.map(user.creditCards, (card, i) => {
        return (
          <label style={{ marginLeft: 20 }} className="radio" key={i}>
            <input
              required
              type="radio"
              name="optionCard"
              value={JSON.stringify(card)}
              checked={this.state.creditCardId === card.id}
              onChange={({ target }) =>
                this.handleCreditCard(JSON.parse(target.value))
              }
            />
            {card.text}
          </label>
        );
      });

      return creditCars;
    }
  }

  handlePreference = (target) => {
    const { listPreferenceByProfessionals = [] } = this.state;
    const partner = JSON.parse(target.value);

    if (target.checked === true) {
      this.setState({
        listPreferenceByProfessionals: [
          ...listPreferenceByProfessionals,
          partner._id,
        ],
      });
    } else {
      this.setState({
        listPreferenceByProfessionals: listPreferenceByProfessionals.filter(
          (i) => i !== partner._id
        ),
      });
    }
  };

  handleCreditCard = (card) => {
    this.setState({ creditCardId: card.id, linkPayment: false });
  };

  onlyPreference = () => {
    const { onlyForPreference } = this.state;
    const professionalPreferences = this.props.professionalPreferences;

    return (
      <div>
        <label className="checkbox" style={{ marginLeft: 10 }}>
          <input
            type="checkbox"
            disabled={
              !professionalPreferences || professionalPreferences.length < 1
            }
            name="onlyForPreference"
            checked={onlyForPreference}
            onChange={() =>
              this.setState({ onlyForPreference: !onlyForPreference })
            }
          />
          Apenas Preferência
        </label>
      </div>
    );
  };

  handleLinkPayment = ({ value }) => {
    if (value === "on") {
      this.setState({ linkPayment: true, creditCardId: undefined });
    } else {
      this.setState({ linkPayment: false });
    }
  };

  handleVoucher = () => {
    return (
      <div>
        <input
          className="form-control"
          style={{ width: 200 }}
          type="text"
          placeholder="Pesquise pelo nome do voucher..."
          value={this.state.code}
          onChange={(e) => this.setState({ code: e.target.value })}
        />
        <Button
          bsStyle="info"
          style={{ marginLeft: 20 }}
          onClick={this.handleSearchVoucher}
        >
          Buscar
        </Button>
        {this.state.voucher && (
          <div className="row">
            <label>Voucher aplicado: {this.state.code}</label>
          </div>
        )}
      </div>
    );
  };

  handleSubmitSchedule = async () => {
    try {
      const schedule = this.parseSchedule(this.state);
      await newSchedule(schedule);
      Swal.fire({
        title: "Sucesso",
        text: "Agendamento realizado!",
        icon: "success",
      });
      this.props.hideModal();
    } catch (err) {
      console.error(err);
      let message = err.description || "Houve um erro ao criar o agendamento";
      if (err.data && err.data.error && err.data.error.description) {
        message = err.data.error.description;
      }

      if (err.data && err.data.description) {
        message = err.data.description;
      }

      Swal.fire("Erro", message, "error");
    } finally {
      this.setState({ isFormLoading: false });
    }
  };

  handleSearchVoucher = async () => {
    const { area, code, token } = this.state;
    try {
      const { data: voucher } = await getVoucher(code, area._id, token);
      this.setState({ voucher });
      Swal.fire("Sucesso!", "Voucher adicionado com sucesso!", "success");
    } catch (err) {
      this.setState({ voucher: null, code: null });
      console.error(err);
      let message = "Houve um erro ao buscar o voucher";

      if (err.data && err.data.error && err.data.error.description) {
        message = err.data.error.description;
      } else if (err.data && err.data.description) {
        message = err.data.description;
      }
      Swal.fire("Erro", message, "error");
    }
  };

  save = async (event) => {
    try {
      event.preventDefault();

      this.setState({ isFormLoading: true });

      const { user } = this.props;

      const {
        service,
        voucher,
        numberOfClients,
        listPreferenceByProfessionals,
        realtime,
        dateRangeStart,
        dateRangeEnd,
      } = this.state;
      let totalValue;
      let discount;

      let type = "FLEX";

      const start = moment(dateRangeStart);
      const end = moment(dateRangeEnd);

      let hoursCount = 0;

      while (start.format("HH:mm") !== end.format("HH:mm")) {
        if (hoursCount >= 4) break;
        start.add(30, "minutes");
        hoursCount += 1;
      }

      if (hoursCount >= 4) type = "FLEX";
      else type = "FIXED";

      const { prices, flexPrice, fixPrice, minDate } = this.calculateValue();

      this.validateDates(dateRangeStart, dateRangeEnd, minDate, prices);

      if (realtime) {
        totalValue = prices.realtime.price;
      } else {
        const price = type === "FIXED" ? fixPrice : flexPrice;

        totalValue = price;
      }

      if (numberOfClients > 1) {
        totalValue = totalValue * numberOfClients;
      }

      if (
        listPreferenceByProfessionals &&
        listPreferenceByProfessionals.length > 0
      ) {
        if (!user.hasSignature) {
          const preferenceValue = service.preference.value;
          totalValue = totalValue + preferenceValue;
        }
      }

      if (numberOfClients > 1) {
        totalValue = totalValue * numberOfClients;
      }

      if (voucher) {
        if (voucher.type === "P") {
          discount = totalValue * voucher.value;
        } else {
          discount = voucher.value;
        }
      }

      if (discount) {
        totalValue = totalValue - discount;
      }

      const result = await Swal.fire({
        icon: "warning",
        title: "Confirmar pedido?",
        html: `<div><p>Pedido de ${service.name["pt-BR"]}, no valor de R$ ${totalValue}</p></br></div>`,
        showCloseButton: true,
        showCancelButton: true,
        focusConfirm: false,
        confirmButtonText: "Confirmar!",
        cancelButtonText: "Cancelar",
      });

      if (result.isConfirmed) {
        await this.handleSubmitSchedule();
      } else {
        this.setState({ isFormLoading: false });
      }
    } catch (error) {
      this.setState({ isFormLoading: false });
      Swal.fire(
        "Erro",
        error.description || "Houve um erro ao salvar o agendamento",
        "error"
      );
    }
  };

  render() {
    const {
      area,
      numberOfClients,
      useSinguCredits,
      note,
      linkPayment,
      realtime,
      massageTable,
      isFormLoading,
      dateRangeStart,
      dateRangeEnd,
      categoryId,
      scheduleByWpp
    } = this.state;

    if (!this.props.modalSchedule) return <div></div>;
    const user = this.props.user;

    return (
      <Modal
        className="Modal Modal--large"
        show={this.props.modalSchedule}
        onHide={this.props.hideModal}
      >
        <Modal.Header closeButton>
          <Modal.Title>{"Novo Agendamento"}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Alert bsSize="small" bsStyle="danger">
            Os serviços da Singu podem ser agendados das 07:00 ás 22:00
          </Alert>
          <form
            id="new-Schedule-form"
            name="new-Schedule-form"
            onSubmit={this.save}
          >
            <div className="Form-formGroup">
              <div className="row">
                <div className="col-sm-3">
                  <div className="Form-labelWrapper">
                    <label className="Form-label" htmlFor={name}>
                      Cliente
                    </label>
                  </div>
                  <input
                    style={{ width: 235 }}
                    name="client"
                    type="text"
                    disabled={true}
                    className="form-control"
                    placeholder="Cliente"
                    value={user.name}
                  />
                </div>
                <div className="col-sm-3" style={{ marginLeft: 100 }}>
                  <div className="Form-labelWrapper">
                    <label className="Form-label" htmlFor={name}>
                      Endereço
                    </label>
                  </div>
                  {this.displayAddresses()}
                </div>
                <div className="col-sm-3" style={{ marginLeft: 50 }}>
                  <div className="Form-labelWrapper">
                    <label className="Form-label" htmlFor={name}>
                      Serviço
                    </label>
                  </div>
                  <select
                    required
                    style={{ width: "100%" }}
                    onChange={({ target }) =>
                      this.changeSelectedService(JSON.parse(target.value))
                    }
                    className={"form-control"}
                  >
                    <option value="">Selecione um serviço</option>
                    {area &&
                      area.categories &&
                      area.categories.map((category) => {
                        return category.services.map((service) => {
                          return (
                            <option
                              key={service._id}
                              value={JSON.stringify(service)}
                            >
                              {category.name["pt-BR"]} - {service.name["pt-BR"]}
                            </option>
                          );
                        });
                      })}
                  </select>
                </div>
              </div>
              <div className="row" style={{ marginTop: 50 }}>
                <div className="col-sm-3">
                  <div className="Form-labelWrapper">
                    <label className="Form-label" htmlFor={name}>
                      Data
                    </label>
                  </div>
                  <input
                    required
                    name="start"
                    type="date"
                    min={moment().format("YYYY-MM-DD")}
                    max={moment().add(30, "days").format("YYYY-MM-DD")}
                    value={moment(dateRangeStart).format("YYYY-MM-DD")}
                    onChange={({ target }) => this.handleChangeDate(target)}
                    style={{ width: "100%" }}
                    className="Form-input"
                  />
                </div>
                <div className="col-sm-3" style={{ marginLeft: 100 }}>
                  <div className="Form-labelWrapper">
                    <label className="Form-label" htmlFor={name}>
                      Horário inicial
                    </label>
                  </div>
                  <input
                    required
                    name="start"
                    type="time"
                    min="07:00"
                    max="22:00"
                    value={moment(dateRangeStart).format("HH:mm")}
                    onChange={this.setTimeRange}
                    style={{ width: "100%" }}
                    className="Form-input"
                    disabled={realtime}
                  />
                </div>
                <div className="col-sm-3" style={{ marginLeft: 100 }}>
                  <div className="Form-labelWrapper">
                    <label className="Form-label" htmlFor={name}>
                      Horário final
                    </label>
                  </div>
                  <input
                    required
                    name="end"
                    type="time"
                    min="07:00"
                    max="22:00"
                    value={moment(dateRangeEnd).format("HH:mm")}
                    onChange={this.setTimeRange}
                    style={{ width: "100%" }}
                    className="Form-input"
                    disabled={realtime}
                  />
                </div>
              </div>
              <div className="row" style={{ marginTop: 50 }}>
                <div className="col-sm-2" style={{ marginLeft: 20 }}>
                  <div className="Form-labelWrapper">
                    <label className="Form-label" htmlFor={name}>
                      Singu Agora
                    </label>
                  </div>
                  <label className="checkbox" style={{ marginLeft: 10 }}>
                    <input
                      type="checkbox"
                      name="realtime"
                      checked={realtime}
                      onChange={() => this.setState({ realtime: !realtime })}
                    />
                    Pedido Singu Agora
                  </label>
                </div>
                <div className="col-sm-2" style={{ marginLeft: 20 }}>
                  <div className="Form-labelWrapper">
                    <label className="Form-label" htmlFor={name}>
                      Pedido via Whatsapp?
                    </label>
                  </div>
                  <label className="checkbox" style={{ marginLeft: 10 }}>
                    <input
                      type="checkbox"
                      name="scheduleByWpp"
                      checked={scheduleByWpp}
                      onChange={() => this.setState({ scheduleByWpp: !scheduleByWpp })}
                    />
                    Sim
                  </label>
                </div>
                <div className="col-sm-2" style={{ marginLeft: 170 }}>
                  <div className="Form-labelWrapper">
                    <label className="Form-label" htmlFor={name}>
                      Quantos clientes
                    </label>
                  </div>
                  <div className="row" style={{ marginLeft: 20 }}>
                    <Button
                      onClick={this.decrementNumberOfClients}
                      disabled={this.state.numberOfClients <= 1}
                    >
                      -
                    </Button>
                    <label style={{ marginInline: 20 }}>
                      {numberOfClients}
                    </label>
                    <Button
                      onClick={this.incrementNumberOfClients}
                      disabled={this.state.numberOfClients >= 4}
                    >
                      +
                    </Button>
                  </div>
                </div>
                {categoryId === "c101" && (
                  <div className="col-sm-3" style={{ marginLeft: 200 }}>
                    <div className="Form-labelWrapper">
                      <label className="Form-label" htmlFor={name}>
                        Maca de massagem
                      </label>
                    </div>
                    <label className="checkbox" style={{ marginLeft: 10 }}>
                      <input
                        type="checkbox"
                        name="massageTable"
                        checked={massageTable}
                        onChange={() =>
                          this.setState({ massageTable: !massageTable })
                        }
                      />
                      Possui maca de massagem
                    </label>
                  </div>
                )}
              </div>
            </div>
            <div className="Form-formGroup">
              <div className="row">
                <div className="col-sm-3">
                  <div className="Form-labelWrapper">
                    <label className="Form-label" htmlFor={name}>
                      Preferência
                    </label>
                  </div>
                  {this.displayProfessional()}
                </div>
                <div
                  className="col-sm-3"
                  style={{ marginLeft: 100, marginTop: 30 }}
                >
                  {this.onlyPreference()}
                </div>
                <div className="col-sm-3" style={{ marginLeft: 100 }}>
                  <div className="Form-labelWrapper">
                    <label className="Form-label" htmlFor={name}>
                      Observação
                    </label>
                  </div>
                  <textarea
                    className="Form-input"
                    name="note"
                    value={note}
                    onChange={(e) => this.setState({ note: e.target.value })}
                  />
                </div>
              </div>
              <div className="row" style={{ marginTop: 50 }}>
                <div className="col-sm-4">
                  <div className="Form-labelWrapper">
                    <label className="Form-label" htmlFor={name}>
                      Voucher
                    </label>
                  </div>
                  {this.handleVoucher()}
                </div>
                <div className="col-sm-4" style={{ marginLeft: 50 }}>
                  <div className="Form-labelWrapper">
                    <label className="Form-label" htmlFor={name}>
                      Formas de pagamento
                    </label>
                  </div>
                  <div>
                    <div>
                      <label className="radio" style={{ marginLeft: 20 }}>
                        <input
                          type="radio"
                          name="optionCard"
                          checked={linkPayment}
                          required={!linkPayment && !this.state.creditCardId}
                          onChange={({ target }) =>
                            this.handleLinkPayment(target)
                          }
                        />
                        Link Plataforma Pagbank
                      </label>
                    </div>
                    {this.displayCreditCards()}
                  </div>
                </div>
                <div className="col-sm-3">
                  <div className="Form-labelWrapper">
                    <label className="Form-label" htmlFor={name}>
                      Singu Créditos
                    </label>
                  </div>
                  {user.singuCredits ? (
                    <div>
                      <label className="checkbox" style={{ marginLeft: 20 }}>
                        <input
                          type="checkbox"
                          name="SinguCredits"
                          checked={useSinguCredits}
                          onChange={() =>
                            this.setState({ useSinguCredits: !useSinguCredits })
                          }
                        />
                        Utilizar Singu Créditos
                      </label>
                      <div>
                        Total Disponível: R$ {user.singuCredits || "0,00"}
                      </div>
                    </div>
                  ) : (
                    <div>Cliente não possuí Singu Créditos</div>
                  )}
                </div>
              </div>
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={this.props.hideModal}>Cancelar</Button>
          {isFormLoading ? (
            <input
              type="submit"
              form="new-Schedule-form"
              className="btn btn-info"
              value="Carregando..."
              disabled={isFormLoading}
            />
          ) : (
            <input
              type="submit"
              form="new-Schedule-form"
              className="btn btn-info"
              value="Salvar"
            />
          )}
        </Modal.Footer>
      </Modal>
    );
  }
}

export default ScheduleModal;
