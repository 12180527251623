import React, { Component } from "react";
import { Row, Col, Button } from "react-bootstrap";
import Swal from "sweetalert2";

import { updateNeighborServicePrice } from "../../actions/dynamic_prices/update_price";
import { hasNullValue } from '../../helper/utils';

export default class ServiceEditComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isFormLoading: false,
      selectedNeighbor: null
    }
  }

  componentDidUpdate() {
    if (this.state && this.state.services && this.state.neighbors) return;
    // eslint-disable-next-line react/no-did-update-set-state
    this.setState({ services: this.props.services, neighbors: this.props.neighbors });
  }

  handleEditPrices = (e) => {
    const priceName = e.target.name;
    const value = e.target.value;

    if(priceName === 'neighbor'){
      return this.setState({ selectedNeighbor: JSON.parse(value) })
    }

    if (priceName === 'name') {
      return this.setState({
        services: {
          ...this.state.services, [priceName]: value
        }
      })
    } else {
      const currentPrice = this.state.selectedNeighbor.prices[priceName];

      return this.setState({
        selectedNeighbor: {
          ...this.state.selectedNeighbor,
          prices: { ...this.state.selectedNeighbor.prices, [priceName]: { ...currentPrice, price: Number(value) } },
        },
      });
    }
  };

  handleFormSubmit = (e) => {
    try {
      e.preventDefault();

    this.setState({ isFormLoading: true });

    const { selectedNeighbor } = this.state;
    const prices = [];

    Object.keys(selectedNeighbor.prices).forEach(priceKey => {
      prices.push(selectedNeighbor.prices[priceKey]);
    })

    if (hasNullValue(selectedNeighbor)) throw new Error('Erro ao atualizar o serviço');

    updateNeighborServicePrice(selectedNeighbor.priceId, { range: prices })
      .then(() => {
        Swal.fire({ icon: "success", title: "Sucesso", text: "Serviço atualizado com sucesso" })
      })
      .catch(error => {
        console.error(error);
        Swal.fire({ icon: "error", title: "Oops...", text: "Erro ao atualizar o serviço" });
      })
      .finally(() => this.setState({ isFormLoading: false }));
    } catch (error) {
      Swal.fire({ icon: "error", title: "Oops...", text: "Erro ao atualizar o serviço" });
    }finally{
      this.setState({ isFormLoading: false });
    }
    
  }


  render() {
    const { services, neighbors, isFormLoading, selectedNeighbor } = this.state || {};
    const { prices } = selectedNeighbor || {};

    return (
      <Col>
        <h3>{services && services.name}</h3>

        <Col md={12}>
          <form onSubmit={this.handleFormSubmit}>
            <Row className="justify-content-md-center">
              <Col sm={12}>
                <div className="form-group" style={{ marginLeft: "10px" }}>
                  <label htmlFor="name">Nome do serviço</label>
                  <input
                    type="text"
                    className="form-control form-control-normal"
                    id="name"
                    name={"name"}
                    value={services && services.name}
                    onChange={this.handleEditPrices}
                  />
                </div>
              </Col>
              <Col sm={4}>
                <div className="form-group">
                  <label htmlFor="serviceId">ID do Serviço</label>
                  <input
                    type="text"
                    className="form-control form-control-normal"
                    id="serviceId"
                    value={services && services.serviceId}
                    onChange={this.handleEditName}
                    disabled={true}
                  />
                </div>
              </Col>
              <Col sm={4}>
                <div className="form-group">
                  <label htmlFor="category">Categoria do Serviço</label>
                  <input
                    type="text"
                    className="form-control form-control-normal"
                    id="category"
                    defaultValue={services && services.category}
                    onChange={this.handleEdit}
                    name={"category"}
                  />
                </div>
              </Col>
              <Col sm={4}>
                <div className="form-group">
                  <label htmlFor="category">Bairro</label>
                  <select
                    className="form-control form-control-normal"
                    id="neighbor"
                    onChange={this.handleEditPrices}
                    name="neighbor"
                  >
                    <option></option>
                    {
                      neighbors && neighbors.map(neighbor => (
                        <option 
                          key={neighbor.priceId}
                          value={JSON.stringify(neighbor)}
                        >{
                          neighbor.neighborName}
                        </option>
                      ))
                    }
                  </select>
                </div>
              </Col>
            </Row>
            <h3>Categorias</h3>
            <Row style={{ marginTop: "30px" }}>
              {
                prices && Object.keys(prices).map((priceKey, idx) => (
                  <Col sm={6} key={idx}>
                    <div className="form-group">
                      <label htmlFor="flexSchedule">{priceKey}</label>
                      <input
                        type="number"
                        min={1}
                        step=".01"
                        name={priceKey}
                        value={prices[priceKey].price || false}
                        onChange={this.handleEditPrices}
                        className="form-control form-control-normal"
                        id="flexSchedule"
                        placeholder="Valor"
                      />
                    </div>
                  </Col>
                ))
              }
            </Row>
            <Row>
              <Col sm={12} className="text-right">
                {
                  isFormLoading ?
                    (
                      <Button type="submit" bsStyle="info" className="button-icon" bsSize="large" disabled={isFormLoading}>
                        <span className="spinner glyphicon glyphicon-refresh" ></span>
                        Salvando...
                      </Button>
                    ) :
                    (
                      <Button type="submit" bsStyle="info" bsSize="large">
                        Salvar
                      </Button>
                    )
                }
              </Col>
            </Row>
          </form>
        </Col>
      </Col>
    );
  }
}
