import React, { Component } from "react";
import { Alert } from "react-bootstrap";
import DateTime from 'react-datetime';
import moment from "moment";

import AreasServices from "../../components/Helpers/AreasMultiSelect";

export default class EditVoucher extends Component {

  isValidStartDate = date => date.isAfter(today) || date.isSame(today, 'date');

  render() {
    const { voucher, changeValue, changeJustNew, changeAquisition, changeRecurrence } = this.props;
    return (
      <div className="row">
        <div className="col-md-12">
          <form id="edit-voucher-form" name='edit-voucher-form'>
            <Alert bsSize="small" bsStyle="danger">
              Não é possivel atualizar alguns campos devido a possivel perda de dados históricos
            </Alert>
            <div className="Form-formGroup">
              <div className="row">
                <div className="col-sm-3" style={{ marginLeft: 25 }}>
                  <div className="Form-labelWrapper">
                    <label className="Form-label" htmlFor={name}>Código</label>
                  </div>
                  <input
                    style={{ width: 180 }}
                    name="code"
                    type="text"
                    value={voucher.code}
                    disabled={true}
                    className="form-control"
                    placeholder="Insira o código"
                  />
                </div>
                <div className="col-sm-3" style={{ marginLeft: 50 }}>
                  <div className="Form-labelWrapper">
                    <label className="Form-label" htmlFor={name}> Data Final</label>
                  </div>
                  <input
                    name="endDate"
                    type="datetime-local"
                    min={moment().format("YYYY-MM-DDT:HH:mm")}
                    value={moment(voucher.end).format("YYYY-MM-DDTHH:mm")}
                    style={{ width: "100%" }}
                    className="Form-input"
                    onChange={({ target }) => changeValue('end', target.value)}
                    required
                  />
                </div>

                {(!voucher.aquisition || !voucher.recurrence) &&
                  (<div className="col-sm-3" style={{ marginLeft: 50 }}>
                    <div className="Form-labelWrapper">
                      <input
                        style={{ marginRight: 25 }}
                        type="checkbox"
                        checked={voucher.justNew}
                        onChange={() => changeJustNew('justNew')}
                      />
                      <label className="Form-label" htmlFor={name}>Apenas novos usuários</label>
                    </div>
                  </div>)
                }
              </div>
            </div>

            <div className="Form-formGroup">
              <div className="row">
                <div className="col-sm-3">
                  <div className="Form-labelWrapper">
                    <div className="Form-label">
                      <span>Horário permitido para uso - inicio</span>
                    </div>
                  </div>
                  <DateTime
                    name="evaluationDate"
                    viewMode="time"
                    value={moment(voucher.dateRangeStart).format("YYYY-MM-DDTHH:mm") || ''}
                    dateFormat="DD/MM/YYYY"
                    isValidDate={this.isValidStartDate}
                    onChange={({ target }) => changeValue('dateRangeStart', target.value)}
                    className="Form-input input-date"
                  />
                </div>
                <div className="col-sm-3">
                  <div className="Form-labelWrapper">
                    <div className="Form-label">
                      <span>Horário permitido para uso uso - final</span>
                    </div>
                  </div>
                  <DateTime
                    name="evaluationDate"
                    viewMode="time"
                    value={moment(voucher.dateRangeEnd).format("YYYY-MM-DDTHH:mm") || ''}
                    dateFormat="DD/MM/YYYY"
                    isValidDate={this.isValidEndDate}
                    onChange={({ target }) => changeValue('dateRangeEnd', target.value)}
                    className="Form-input input-date"
                  />
                </div>
              </div>
            </div>

            <div className="Form-formGroup">
              {(voucher.aquisition &&
                <h4>Aquisição:</h4>)}
              <div className="row">
                <div className="col-sm-3" style={{ marginLeft: 25 }}>
                  <div className="Form-labelWrapper">
                    <label className="Form-label" htmlFor={name}>Máximo de vezes de uso</label>
                  </div>
                  <input
                    style={{ width: 180 }}
                    type="number"
                    className="form-control"
                    min={voucher.lastMaxTimes}
                    value={voucher.maxTimes || (voucher.aquisition && voucher.aquisition.maxTimes)}
                    onChange={({ target }) => voucher.aquisition ? changeAquisition('maxTimes', target.value) :
                      changeValue('maxTimes', target.value)}
                  />
                </div>
                <div className="col-sm-3" style={{ marginLeft: 50 }}>
                  <div className="Form-labelWrapper">
                    <label className="Form-label" htmlFor={name}>
                      Tipo
                    </label>
                  </div>
                  <select
                    className="form-control"
                    value={voucher.type || (voucher.aquisition && voucher.aquisition.type)}
                    disabled={voucher.usedTimes > 0}
                    onChange={({ target }) => voucher.aquisition ? changeAquisition('type', target.value) :
                      changeValue('type', target.value)}
                    style={{ width: 180 }}>
                    <option value="P">Porcentagem</option>
                    <option value="V">Valor</option>
                  </select>
                </div>
                <div className="col-sm-3" style={{ marginLeft: 50 }}>
                  <div className="Form-labelWrapper">
                    <label className="Form-label" htmlFor={name}>
                      Valor
                    </label>
                  </div>
                  <input
                    disabled={voucher.usedTimes > 0}
                    type="number"
                    className="form-control"
                    min={0}
                    defaultValue={voucher.currentValue || (voucher.aquisition && voucher.aquisition.value)}
                    onChange={({ target }) => voucher.aquisition ? changeAquisition('value', target.value) :
                      changeValue('value', target.value)}
                  />
                </div>
              </div>
            </div>
            {(!!voucher.aquisition && !!voucher.recurrence) && (
              <div className="Form-formGroup">
                <h4>Recorrência:</h4>
                <div className="row">
                  <div className="col-sm-3" style={{ marginLeft: 25 }}>
                    <div className="Form-labelWrapper">
                      <label className="Form-label" htmlFor={name}>Máximo de vezes de uso</label>
                    </div>
                    <input
                      type="number"
                      className="form-control"
                      min={voucher.lastMaxTimes}
                      defaultValue={voucher.recurrence.maxTimes}
                      onChange={({ target }) => changeRecurrence('maxTimes', target.value)}
                    />
                  </div>
                  <div className="col-sm-3" style={{ marginLeft: 50 }}>
                    <div className="Form-labelWrapper">
                      <label className="Form-label" htmlFor={name}>
                        Tipo
                      </label>
                    </div>
                    <select
                      className="form-control"
                      onChange={({ target }) => changeRecurrence('type', target.value)}
                      defaultValue={voucher.recurrence.type}
                      disabled={voucher.usedTimes > 0}
                      style={{ width: 180 }}>
                      <option value="P">Porcentagem</option>
                      <option value="V">Valor</option>
                    </select>
                  </div>
                  <div className="col-sm-3" style={{ marginLeft: 50 }}>
                    <div className="Form-labelWrapper">
                      <label className="Form-label" htmlFor={name}>
                        Valor
                      </label>
                    </div>
                    <input
                      disabled={voucher.usedTimes > 0}
                      type="number"
                      className="form-control"
                      min={0}
                      defaultValue={voucher.recurrence.value}
                      onChange={({ target }) => changeRecurrence('value', target.value)}
                    />
                  </div>
                </div>
              </div>)}
            <AreasServices
              defaultAreas={voucher.locations}
              defaultServices={voucher.services}
              setServices={this.props.changeServices}
              setAreas={this.props.changeAreas}
            />
          </form>
        </div>

      </div>

    );
  }
}