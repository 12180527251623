import config from '../../../config.json';
const env = process.env.REACT_APP_ENVIRONMENT || 'stage';
const BACKEND_URL = config.api[env];
import axios from 'axios';

import { getToken } from './../../models/auth';

export const REMOVE_CARD = 'REMOVE_CARD';

export function removeCard(userId, cardId, index) {
  const cardPromise = axios.delete(`${BACKEND_URL}/v2/bo/user/${userId}/creditcard`, {
    headers: { Authorization: `Bearer ${getToken()}` },
    data: { creditCardId: cardId }
  });

  return {
    type: REMOVE_CARD,
    payload: cardPromise,
    meta: { index }
  };
}
