import React, { Component, PropTypes } from 'react';
import _ from 'underscore';
import { Button } from 'react-bootstrap';
import DateTime from 'react-datetime';
import handleRequest from '../../../helper/requests';
import moment from 'moment-timezone';

// styles
import './UpdateTakeRate.css';

class UpdateTakeRate extends Component {

  constructor(props) {
    super(props);

    const { id, takeRate, scheduledRangeEnd } = this.props.rowData;

    const newTakeRate = takeRate ? takeRate : { value: 0, expiration: scheduledRangeEnd.date };

    this.state = { id, takeRate: newTakeRate };
  }

  onTakeRateValueChange = (e) => {
    const value = e.target.value;
    const newTakeRate = Object.assign(this.state.takeRate, { value: value < 0 ? 0 : value > 100 ? 100 : value });
    this.setState({ takeRate: newTakeRate })
  };

  onExpirationDateChange = (e) => {
    const newTakeRate = Object.assign(this.state.takeRate, { expiration: e });
    this.setState({ takeRate: newTakeRate })
  };

  validDate(current) {
    return current.isAfter(moment().subtract(1, 'day'));
  };

  confirmUpdateDateModal = () => {
    const actionCreator = () => this.props.updateCallback(this.state.id, this.state.takeRate);
    handleRequest(actionCreator, this.props.notify, 'Agendamento atualizado com sucesso');
  };

  render() {
    return (
      <div className="UpdateTakeRate">
        <div className="UpdateTakeRate-row">

          <p style={{ marginTop: 5, marginRight: 10 }} >Data de expiração:</p>
          <DateTime
            className="UpdateTakeRate-datepicker"
            onChange={this.onExpirationDateChange.bind(this)}
            value={this.state.takeRate.expiration}
            isValidDate={this.validDate}
            locale="pt-br"
          />

          <br /><br /><br />

          <p style={{ marginTop: 5, marginRight: 10 }} >Porcentagem a receber:</p>
          <input
            className="form-control"
            type="number"
            min="0"
            max="100"
            onChange={this.onTakeRateValueChange.bind(this)}
            value={this.state.takeRate.value}
          />

          <Button className="UpdateTakeRate-btn"
            bsStyle="primary"
            onClick={this.confirmUpdateDateModal.bind(this)}>Alterar take rate</Button>
        </div>
      </div>
    );

  }
}

UpdateTakeRate.propTypes = {
  rowData: PropTypes.object,
  notify: PropTypes.func,
  updateCallback: PropTypes.func
};

export default UpdateTakeRate;
