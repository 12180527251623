import config from '../../../config.json';
const env = process.env.REACT_APP_ENVIRONMENT || 'stage';
const BACKEND_URL = config.api[env];
import axios from 'axios';

import { getToken } from '../../models/auth';

export function deleteVouchers(code) {
    return axios.delete(`${BACKEND_URL}/v2/bo/vouchers/${code}`, {
        headers: { Authorization: `Bearer ${getToken()}` }
    });
}