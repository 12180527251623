import React from 'react';
import { login } from '../../models/auth.js';
import './Login.css';
import Cookies from 'universal-cookie';

const Login = React.createClass({

    contextTypes: {
        router: React.PropTypes.object.isRequired
    },

    componentWillMount() {
        var cookies = document.cookie.split("; ");
        localStorage.clear()
        for (var c = 0; c < cookies.length; c++) {
            var d = window.location.hostname.split(".");
            while (d.length > 0) {
                var cookieBase = encodeURIComponent(cookies[c].split(";")[0].split("=")[0]) + '=; expires=Thu, 01-Jan-1970 00:00:01 GMT; domain=' + d.join('.') + ' ;path=';
                var p = location.pathname.split('/');
                document.cookie = cookieBase + '/';
                while (p.length > 0) {
                    document.cookie = cookieBase + p.join('/');
                    p.pop();
                }
                d.shift();
            }
        }
    },

    getInitialState() {
        return {
            error: false
        };
    },

    handleSubmit(event) {
        event.preventDefault();

        const cookies = new Cookies()

        const email = this.refs.email.value;
        const pass = this.refs.pass.value;

        login(email, pass, (result) => {
            if (result.login === false) {
                return this.setState({ error: true });
            }

            const { location } = this.props;

            const env = process.env.REACT_APP_ENVIRONMENT || 'stage'
            const isProd = env.includes('prod')

            const domain = isProd ? '.singu.com.br' : '.singu.io'

            cookies.set('auth_bo_token',localStorage.jwtToken,{ domain })
            cookies.set('auth_bo_expiration',localStorage.expiration,{ domain })
            cookies.set('auth_bo_user',localStorage.user,{ domain })
            cookies.set('auth_bo_permissions',localStorage.permissions,{ domain })

            if (result.firstLogin) {
                this.context.router.replace('/change-password');
                return;
            }

            if (location.state && location.state.nextPathname) {
                this.context.router.replace(location.state.nextPathname);
                return
            }

            this.context.router.replace('/');
        });

        


    },

    render() {
        return (
            <div className="LoginBody">
                <div className="Login">
                    <h2>Backoffice Singu</h2>
                    <br />
                    <form className="form-horizontal" onSubmit={this.handleSubmit}>
                        <div className="form-group">
                            <label className="col-sm-4 control-label"> E-mail </label>
                            <div className="col-sm-4">
                                <input ref="email" className="form-control" type="email" autoComplete='username' />
                            </div>
                        </div>

                        <div className="form-group">
                            <label className="col-sm-4 control-label">Senha</label>
                            <div className="col-sm-4">
                                <input ref="pass" type="password" className=" form-control" autoComplete='current-password' />
                            </div>
                        </div>

                        <div className="form-group" style={{ width: 364 }}>
                            <button type="submit" className="Login-btn btn btn-default">Entrar</button>
                        </div>

                        {this.state.error && (
                            <div className="col-sm-offset-4 col-sm-4 alert alert-danger" role="alert">
                                <span className="glyphicon glyphicon-exclamation-sign" aria-hidden="true"></span>
                                <span className="sr-only">Error:</span>
                                Dados inválidos
                            </div>
                        )}
                    </form>
                </div >
            </div>
        );
    }
});

export default Login;
