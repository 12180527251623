import axios from "axios";
import { backendAPI } from "../../helper/apis";

// Digitar rotas sem BACKEND_URL, backendAPI ja possui essa URL base
export function getMGM() {
  return backendAPI.get("/mgm");
}

export function saveMGM(data) {
  return backendAPI.put("/mgm", data);
}

export function getUrlSigned(file, name) { 
  return backendAPI.get("/mgm/url-signed", {
    params: { filename: name, filetype: file.type },
  });
}

export function uploadMGMImg(signedUrl, file, onProgress, name) {
  var instance = axios.create();
  const splitedName = name.split(".");
  const options = {
    progress: onProgress,
    headers: {
      "Content-Type": splitedName[splitedName.length - 1],
    },
  };
  return instance.put(signedUrl, file, options);
}
