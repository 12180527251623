/* global BACKEND_URL */
/* global _ */
/* global axios */
/* global $ */
import React from "react";

import moment from 'moment';
import { Component, PropTypes } from 'react';
import { connect } from 'react-redux';
import PageHeader from './../../components/Global/PageHeader';
import RealTimeOrdersGriddle from './../../components/RealTimeOrders/RealTimeOrdersGriddle';
import { removeSchedulePartner } from './../../actions/schedules/remove_schedule_partner';

// actions
import { fetchRealtimeOrders } from './../../actions/realtime_orders/fetch_realtime_orders';
import { fetchAreas } from './../../actions/areas/fetch_areas';
import { cancelRealTimeOrder } from "./../../actions/realtime_orders/cancel_realtime_orders";
import { updateSchedulePartner } from './../../actions/schedules/update_schedule_partner';
import { updateScheduleTakeRate } from './../../actions/schedules/update_schedule_take_rate';

class RealTimeOrders extends Component {
    constructor(props) {
        super(props);

        this.state = { loading: true, currentPage: 1 };
    }

    componentWillMount() {
        this.loadData().then(() => this.setLoading(false));
    }

    componentWillReceiveProps(newProps) {
        const { realtimeOrders, artists, areas, vouchers } = newProps;

        if (realtimeOrders) {
            this.setState({ realtimeOrders });
        }

        if (areas) {
            this.setState({ areas });
        }

        if (artists) {
            this.setState({ artists });
        }

        if (vouchers) {
            this.setState({ vouchers });
        }
    }

    loadData() {
        var d1 = new Date();
        d1.setDate(d1.getDate() - 5);

        var d2 = new Date();
        d2.setDate(d2.getDate() + 5);

        const promises = [
            this.getAllRealtimeOrders(1, { minDate: d1.toISOString().substr(0, 10), maxDate: d2.toISOString().substr(0, 10) }),
            this.getAllAreas(),
        ];

        return Promise.all(promises);
    }

    setLoading = loading => this.setState({ loading });

    getAllRealtimeOrders = (page, { orderBy, orderType, filter, minDate, maxDate, status, area, shortCode }) => {
        this.setLoading(true);
        return this.props.fetchRealtimeOrders(page, orderBy, orderType, filter, minDate, maxDate, status, area, shortCode)
            .then(() => this.setState({ loading: false, currentPage: page }));
    };

    getAllAreas() {
        return this.props.fetchAreas();
    }

    renderRealtimeGriddle() {
        const { realtimeOrders, artists, areas, vouchers } = this.state;
        const props = {
            realtimeOrders,
            artists,
            areas,
            vouchers,
            removePartnerAction: this.props.removeSchedulePartner,
            cancelRealTimeOrderAction: this.props.cancelRealTimeOrder,
            updateTakeRateAction: this.props.updateScheduleTakeRate,
            updatePartnerAction: this.props.updateSchedulePartner
        };

        return (
            <RealTimeOrdersGriddle
                {...props}
                getRealtimeOrders={this.getAllRealtimeOrders}
                currentPage={this.state.currentPage}
                numberOfPages={this.props.numberOfPages}
                loading={this.state.loading}
            />
        );
    }

    render() {
        return (
            <div>
                <PageHeader title="Singu Agora" />
                {this.renderRealtimeGriddle()}
            </div>
        );
    }
}

RealTimeOrders.PropTypes = {
    realtimeOrders: PropTypes.array,
    artists: PropTypes.array,
    vouchers: PropTypes.array,
    areas: PropTypes.array,
    fetchRealtimeOrders: PropTypes.func,
    fetchAreas: PropTypes.func,
    cancelRealTimeOrder: PropTypes.func,
    updateScheduleTakeRate: PropTypes.func
};

function mapStateToProps(state) {
    return {
        realtimeOrders: state.realTimeOrders.all,
        numberOfPages: state.realTimeOrders.numberOfPages,
        artists: state.artists.all,
        vouchers: state.vouchers.all,
        areas: state.areas.all
    };
}

const mapActionsToProps = {
    fetchRealtimeOrders,
    fetchAreas,
    cancelRealTimeOrder,
    updateSchedulePartner,
    updateScheduleTakeRate,
    removeSchedulePartner
};

export default connect(mapStateToProps, mapActionsToProps)(RealTimeOrders);