import config from '../../../config.json';
const env = process.env.REACT_APP_ENVIRONMENT || 'stage';
const BACKEND_URL = config.api[env];


import React, { Component } from "react";
import { Modal, Button } from "react-bootstrap";
import Swal from "sweetalert2";

import axios from "../../helper/axios";
import Dropdown from '../../components/DropDown/Dropdown'

const infrationTypes = {
  CANCELLATION: "Desistência",
  QUALITY: "Qualidade",
  ARREAR: "Atraso",
  DAILY_PROBLEMS: "Problema com Diária",
  MONEY: "Financeiro",
  FINANCIAL: "Financeiro"
};

const inputCustomInput = {
  width: "100%",
  resize: "none"
};

export default class DataModal extends Component {
  constructor(props) {
    super(props);
    const { closeModal, modalOpen, schedule, artist, artistId } = this.props;
    this.state = {
      infractions: [],
      infraction: {},
      plots: 1,
      artistId,
      artist: artist || {},
      schedule: schedule || {},
      saving: false,
      closeModal,
      modalOpen,
      value: null,
      customOptions: {
        comment: "",
        occurrenceDate: new Date().toISOString().split('T')[0],
        relievePoints: false,
        relievePenalty: false,
        relieveClientDiscount: false
      }
    };
    this.fetchInfractions();
    if (artistId && (!artistId || Object.keys(artist).length === 0))
      this.fetchArtistInfo();
  }

  componentWillReceiveProps(props) {
    if (props.artist) {
      this.setState({
        artist: props.artist
      });
    }
  }

  fetchArtistInfo() {
    axios
      .get(`${BACKEND_URL}/v2/bo/artist/${this.state.artistId}`)
      .then(response => {
        this.setState({
          artist: response.data || {}
        });
      });
  }

  fetchInfractions() {
    axios.get(`${BACKEND_URL}/v2/infraction/rules`).then(response => {
      const { data } = response;
      const infractions = data.map(item => {
        if (!item.manual) return;
        return item;
      });

      this.setState({ infractions });
    });
  }

  async saveFinancialInfraction(event) {
    try {
      event.preventDefault();
      const { _id } = this.state.artist;
      const { penalty } = this.state.infraction;
      this.setState({ saving: true });

      await axios.post(`${BACKEND_URL}/v2/bo/artist/financial/infraction`,
        { professionalId: _id, value: penalty, plots: this.state.plots || 1 });

      Swal.fire('Sucesso!', 'Infrações inseridas com sucesso!', 'success');
      this.props.closeModal();
    } catch (error) {
      const description = error && error.error && error.error.description || 'Houve um erro ao inserir as infrações';
      Swal.fire('Ops', description, 'error');
    } finally {
      this.setState({ saving: false });
    }
  }

  saveInfraction(evt) {
    evt.preventDefault();
    this.setState({ saving: true });

    const { id_infraction_rule } = this.state.infraction;
    const artistId = this.state.artist._id;
    const scheduleId = this.state.schedule !== undefined && this.state.schedule._id !== undefined ? this.state.schedule._id : "";
    axios
      .post(
        `${BACKEND_URL}/v2/infraction/${id_infraction_rule}/${artistId}/${scheduleId}`,
        this.state.customOptions
      )
      .then(_ => {
        alert("Infração Adicionada com sucesso");
        this.props.closeModal();
        this.setState({
          customOptions: {
            comment: "",
            occurrenceDate: new Date().toISOString().split('T')[0],
            relievePoints: false,
            relievePenalty: false,
            relieveClientDiscount: false
          }
        });
      })
      .catch(err => {
        console.log(err);
        let message = "Não foi possível inserir a infração."
        if (err && err.response && err.response.data && err.response.data.error && err.response.data.error.description) {
          message = err.response.data.error.description;
        }

        alert(message);
      })
      .finally(_ => {
        this.setState({
          saving: false
        });
        if (
          this.props.griddleReload &&
          typeof this.props.griddleReload === "function"
        ) {
          this.props.griddleReload();
        }
      });
  }

  changeInput(evt) {
    const { customOptions } = this.state;
    if (evt.target.type === "checkbox") {
      customOptions[evt.target.name] = !customOptions[evt.target.name];
    }
    else {
      customOptions[evt.target.name] = evt.target.value;
    }

    this.setState(customOptions);
  }

  selectInfraction(evt) {
    try {
      const infraction = JSON.parse(evt.target.value);
      this.setState({
        infraction
      });
    } catch (err) {
      this.setState({
        infraction: {}
      });
    }
  }

  handleScheduleId(evt) {
    const { schedule } = this.state;
    schedule._id = evt.target.value;
    this.setState({
      schedule
    });
  }

  handleChangePlots({ target }) {
    this.setState({ plots: +target.value });
  }

  render() {
    const { infractions, value } = this.state
    const financialInfraction = this.state.infraction.level === 'MICRO_CREDITO' || this.state.infraction.level === 'CREDITO_CONSULTORA';

    return (
      <Modal
        className="Modal Modal--medium"
        show={this.props.modalOpen || false}
        onHide={this.props.closeModal}
      >
        <Modal.Header closeButton>
          <Modal.Title className="Modal-title">
            <p>
              Adicionar infração ou taxa para a artista: &nbsp;
              {this.state.artist.name}
            </p>
          </Modal.Title>
        </Modal.Header>
        <form onSubmit={!financialInfraction ? this.saveInfraction.bind(this) : this.saveFinancialInfraction.bind(this)}>
          <Modal.Body>
            <div className="col-sm-12 form-group">
              <label htmlFor="">Escolha a taxa ou a infração</label>
              <Dropdown
                options={infractions}
                id="id_infraction_rule"
                label="description"
                value={value}
                isInformed={"singu_informed"}
                header="Selecione uma infração"
                onChange={(val) => this.setState({ infraction: val, value: val })}
              />
            </div>
            <div className="col-xs-12 form-grou">
              <p>
                Infração: &nbsp;
                {this.state.infraction.description}
              </p>
              <p>
                Tipo de Infração: &nbsp;
                {infrationTypes[this.state.infraction.type]}
              </p>
              <p>
                Nível da Infração: &nbsp;
                {this.state.infraction.level}
              </p>
              <p>Multa para profissional: R$ {this.state.infraction.penalty}</p>
              <p>Pontos de infração: &nbsp; {this.state.infraction.points}</p>
              <p>
                Desconto para cliente: R${" "}
                {this.state.infraction.client_discount}
              </p>
              <p>Dias de Suspensão: {this.state.infraction.suspended_days}</p>
              {!financialInfraction && (
                <p>
                  Retirar Pontos? :{" "}
                  <input
                    value={this.state.customOptions.relievePoints}
                    type="checkbox"
                    name={"relievePoints"}
                    onChange={this.changeInput.bind(this)}
                  />
                </p>
              )}
              {!financialInfraction && (
                <p>
                  Retirar Multa? :{" "}
                  <input
                    value={this.state.customOptions.relievePenalty}
                    type="checkbox"
                    name={"relievePenalty"}
                    onChange={this.changeInput.bind(this)}
                  />
                </p>
              )}
              {!financialInfraction && (
                <p>
                  Retirar Desconto da Cliente? :{" "}
                  <input
                    value={this.state.customOptions.relieveClientDiscount}
                    type="checkbox"
                    name={"relieveClientDiscount"}
                    onChange={this.changeInput.bind(this)}
                  />
                </p>
              )}
            </div>
            <div className="row">
              {financialInfraction ? (
                <div className="form-group col-sm-6 col-xs-12">
                  <label htmlFor="">Número de infrações a inserir: </label>
                  <input
                    value={this.state.plots}
                    onChange={this.handleChangePlots.bind(this)}
                    style={inputCustomInput}
                    type="text"
                    className="form-control"
                  />
                </div>
              ) : (
                <div className="form-group col-sm-6 col-xs-12">
                  <label htmlFor="">ID do Agendamento (opcional): </label>
                  <input
                    value={this.state.schedule._id}
                    onChange={this.handleScheduleId.bind(this)}
                    style={inputCustomInput}
                    type="text"
                    className="form-control"
                  />
                </div>
              )}
              <div className="form-group col-sm-6 col-xs-12">
                <label htmlFor="">Dia da ocorrência: </label>
                <input
                  name="occurrenceDate"
                  onChange={this.changeInput.bind(this)}
                  value={this.state.customOptions.occurrenceDate || moment().format("YYYY-MM-DD")}
                  type="date"
                  className="form-control"
                  style={inputCustomInput}
                />
              </div>
            </div>
            <div className="form-group">
              <label htmlFor="">Comentários:</label>
              <textarea
                name="comment"
                value={this.state.customOptions.comment}
                onChange={this.changeInput.bind(this)}
                className={"form-control"}
                style={inputCustomInput}
                rows="2"
              />
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={this.state.closeModal.bind(this)}>Cancelar</Button>
            <Button
              type="submit"
              onSubmit={this.saveInfraction.bind(this)}
              disabled={
                Object.keys(this.state.infraction).length !== 0
                  ? this.state.saving
                  : true
              }
            >
              {Object.keys(this.state.infraction).length !== 0
                ? this.state.saving
                  ? "Salvando"
                  : "Salvar"
                : "Escolha uma Infração"}
            </Button>
          </Modal.Footer>
        </form>
      </Modal>
    );
  }
}
