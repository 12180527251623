import Actions from './../../components/Partners/PartnerActions/PartnerActions';

export default [
  {
    columnName: 'id',
    locked: false,
    displayName: 'ID'
  },
  {
    columnName: 'createdDate',
    locked: false,
    displayName: 'Cadastro'
  },
  {
    columnName: 'name',
    locked: false,
    displayName: 'Nome'
  },
  {
    columnName: 'email',
    locked: false,
    displayName: 'E-mail'
  },
  {
    columnName: 'phone',
    locked: false,
    displayName: 'Telefone'
  },
  {
    columnName: 'address',
    locked: false,
    displayName: 'Endereço'
  },
  {
    columnName: 'actions',
    displayName: 'Ações',
    customComponent: Actions
  },
  {
    columnName: 'actions',
    displayName: 'Ações',
    customComponent: Actions
  },
  {
    columnName: 'actions',
    visible: false
  }
];