/* global _, LANG */
import React, { Component } from "react";

import NotificationSystem from 'react-notification-system';
import NewBatchVoucherForm from '../../components/Vouchers/NewBatchVoucherForm';
class NewBatchVoucher extends Component {

  render() {
    return (
      <div>
        <NotificationSystem ref="notificationSystem" />
        <NewBatchVoucherForm />
      </div>
    );
  }
}

export default NewBatchVoucher;
