/* global _ */
const enumHelper = {};

enumHelper.scheduleStatusEnum = {
  waitingAccept: { name: 'Aguardando profissional', value: 1 },
  waitingAutomaticAllocation: {
    name: 'Aguardando alocação automática',
    value: 19,
  },
  orderCreated: { name: 'Pedido Criado', value: 0 },
  accepted: { name: 'Aceito pelo profissional', value: 2 },
  refused: { name: 'Recusado pelo profissional', value: 3 },
  cancelledByOperations: { name: 'Cancelado por operações', value: 20 },
  cancelByBackOffice: { name: 'Cancelado pela equipe Singu', value: 4 },
  cancelByUser: { name: 'Cancelado pelo cliente', value: 5 },
  cancelByPartner: { name: 'Cancelado pelo profissional', value: 6 },
  sendedPush: { name: 'Notificação enviada', value: 7 },
  viewedPush: { name: 'Notificação visualizada', value: 8 },
  started: { name: 'Serviço iniciado', value: 9 },
  ended: { name: 'Serviço terminado', value: 10 },
  finished: { name: 'Serviço finalizado', value: 11 },
  paymentError: { name: 'Cancelado por erro no pagamento', value: 12 },
  partnerNoShow: { name: 'No show do profissional', value: 13 },
  customerNoShow: { name: 'No show do cliente', value: 14 },
  processingPayment: { name: 'Processando pagamento', value: 15 },
  singuDayEvent: { name: ' Evento especial - singuday', value: 16 },
  creditCardConfirmation: {
    name: 'Aguardando confirmação do cartão',
    value: 17,
  },
  antiFraud: { name: 'Cancelado por risco de fraude', value: 18 },
};

enumHelper.getScheduleStatusName = function getScheduleStatusName(value) {
  let name = '';
  _.each(_.keys(enumHelper.scheduleStatusEnum), function callback(key) {
    if (enumHelper.scheduleStatusEnum[key].value === value) {
      name = enumHelper.scheduleStatusEnum[key].name;
    }
  });
  return name;
};

module.exports = enumHelper;
