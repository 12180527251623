import { FETCH_VOUCHERS } from './../actions/vouchers/fetch_vouchers';

const INITIAL_STATE = {
  all: [],
  totalItems: 0
};

const vouchersReducer = (state = INITIAL_STATE, action) => {
  if (action.error) {
    return state;
  }

  switch (action.type) {
  case FETCH_VOUCHERS:
    return {
      ...state,
      all: action.payload.data.vouchers,
      totalItems: action.payload.data.totalItems
    };

  default:
    return state;
  }
};

export default vouchersReducer;
