import React from "react";
import { Component, PropTypes } from "react";
import { fetchAreas } from "../../actions/areas/fetch_areas";
import { connect } from "react-redux";
import axios from "../../helper/axios";
import CampaignList from "../../components/Campaigns/CampaignsList";

class Campaigns extends Component {
  componentWillMount() {
    this.getAreas();
  }

  getAreas() {
    return this.props.fetchAreas();
  }

  editCampaign(campaignId, params) {
    return axios.put(`${BACKEND_URL}/campaign/${campaignId}`, params);
  }

  deleteCampaign(campaignId) {
    return axios.delete(`${BACKEND_URL}/campaign/${campaignId}`);
  }

  listCampaigns(areaId, { page, limit }) {
    return axios.get(`${BACKEND_URL}/campaigns`, {
      params: {
        cities: areaId,
        limit: limit || 9,
        page: page || 1
      }
    });
  }

  render() {
    const props = {
      areas: this.getAreas,
      editCampaign: this.editCampaign,
      deleteCampaign: this.deleteCampaign,
      listCampaigns: this.listCampaigns,
      ...this.props
    };

    return (
      <CampaignList {...props} />
    );
  }
}

Campaigns.propTypes = {
  fetchAreas: PropTypes.func
};

const mapActionsToProps = {
  fetchAreas
};

function mapStateToProps(state) {
  return {
    areas: state.areas.all
  };
}

export default connect(
  mapStateToProps,
  mapActionsToProps
)(Campaigns);
