import { FETCH_USERS } from './../actions/users/fetch_users';

const INITIAL_STATE = {
  all: [],
  numberOfPages: 0,
  totalItems: 0
};

const usersReducer = (state = INITIAL_STATE, action) => {
  if (action.error) {
    return state;
  }

  switch (action.type) {
  case FETCH_USERS:
    return {
      ...state,
      all: action.payload.data.users,
      totalItems: action.payload.data.totalItems,
      itemsPerPage: action.payload.data.itemsPerPage
    };

  default:
    return state;
  }
};

export default usersReducer;
