/* global LANG */
import React from "react";

import _ from 'lodash';
import { Component, PropTypes } from 'react';
import { reduxForm } from 'redux-form';
import SendPushForm from './../../components/Artists/SendPushForm';
import Loading from './../../components/Global/Loading/Loading';

import { Toast } from '../../helper/alerts';

// actions
import { fetchAreas } from './../../actions/areas/fetch_areas';
import { fetchConfigs } from './../../actions/configs/fetch_configs';

class SendPush extends Component {
  constructor(props) {
    super(props);

    this.state = {
      cities: null,
      services: null
    };
  }

  componentWillMount() {
    const { fetchAreas } = this.props;

    (async () => {
      try {
        const action = await fetchAreas();

        if (action.error) {
          if (action.payload.data) {
            return Toast().fire({ icon: 'error', title: action.payload.data.error.description })
          } else {
            return Toast().fire({ icon: 'error', title: action.payload.message })
          }
        }

        this.mapAreas(action.payload.data);
      } catch (err) {
        console.error(err);
      }
    })();
  }

  componentWillReceiveProps(newProps) {
    const { areas } = this.props;

    const newCities = newProps.fields.enabledCities.value;

    const includedAreas = areas.filter(area => newCities.includes(area._id))

    this.mapServices(includedAreas);
  }

  mapAreas(areas) {
    const cities = areas.map(area => ({ label: area.city, value: area._id }))

    this.setState({ dataArrived: true, cities });
  }

  mapServices(areas) {
    const reduceCategories = (acc, category) => {
      const mapServices = service => (
        {
          label: service.name[LANG],
          value: service._id,
          categoryName: category.name[LANG]
        }
      );
      const services = _.map(category.services, mapServices);

      return [
        ...acc,
        ...services
      ];
    };
    const reduceAreas = (acc, area) => (
      [
        ...acc,
        ...(_.reduce(area.categories, reduceCategories, []))
      ]
    );
    const reducedServices = _.reduce(areas, reduceAreas, []);
    const uniqueServices = _.uniqBy(reducedServices, 'value');
    const servicesByCategory = _.groupBy(uniqueServices, 'categoryName');
    const sortedKeys = _.keys(servicesByCategory).sort();
    const services = _.reduce(sortedKeys, (acc, key) => {
      acc[key] = servicesByCategory[key];
      return acc;
    }, {});

    this.setState({ services });
  }

  render() {
    const { dataArrived, cities, services } = this.state;

    if (!dataArrived) return <Loading />;

    const props = {
      cities,
      services,
      notify: (msg, lvl) => this.notify(msg, lvl),
      router: this.context.router,
      ...this.props
    };

    return <SendPushForm {...props} />;
  }
}

const formFields = {
  form: 'SendPushForm',
  fields: [
    'pushTitle',
    'pushText',
    'pushLink',
    'pushQuery',
    'enabledCities',
    'enabledServices',
  ]
};

SendPush.propTypes = {
  areas: PropTypes.array,
  fetchAreas: PropTypes.func,
  fetchConfigs: PropTypes.func
};

SendPush.contextTypes = {
  router: PropTypes.object.isRequired
};

function mapStateToProps(state) {
  return {
    areas: state.areas.all
  };
}

const mapActionsToProps = {
  fetchAreas,
  fetchConfigs
};

export default reduxForm(formFields, mapStateToProps, mapActionsToProps)(SendPush);
