import axios from 'axios';
import config from '../../../config.json';
const env = process.env.REACT_APP_ENVIRONMENT || 'stage';
const BACKEND_URL = config.api[env];

import { getToken } from './../../models/auth';

export const FETCH_REALTIME = 'FETCH_REALTIME_ORDERS';

export function fetchRealtimeOrders(page, orderBy, orderType, filter, minDate, maxDate, status, area, shortCode) {
  const params = {
    itemsPerPage: 10,
    page,
    filter,
    minDate,
    maxDate
  };

  if (status && status !== 0) {
    params.status = status;
  }

  if (area) {
    params.areaId = area;
  }

  if (shortCode) {
    params.shortCode = shortCode.trim().toUpperCase();
  }

  if (orderBy && orderType) {
    params.orderBy = orderBy;
    params.orderType = orderType;
  }

  const realtimeOrdersPromise = axios.get(`${BACKEND_URL}/v2/bo/order/realtime`, {
    headers: { Authorization: `Bearer ${getToken()}` },
    params
  });

  return {
    type: FETCH_REALTIME,
    payload: realtimeOrdersPromise
  };
}
