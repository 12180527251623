import { backendAPI } from '../helper/apis';

export function createLead(data) {
  return backendAPI.post('/lead', data);
}

export function updateLead(id, data) {
  return backendAPI.put(`/lead/${id}`, data);
}

export function getReport(params) {
  return backendAPI.get(`/lead/report`, { params });
}

export function downloadReport(params) {
  return backendAPI.get(`/lead/report/export`, { params });
}

export const getLeadsSchedulesByDay = (day, params) => {
  return backendAPI.get(`/v2/bo/leads/day/${day}`, { params })
};

export const getLeadSchedulesByMonth = (date, params) => {
  return backendAPI.get(`/v2/bo/leads/month/${date}`, { params })
};

export const getLeadSchedulesAvailable = (params) => {
  return backendAPI.get(`/lead/schedule/available`, { params })
};
