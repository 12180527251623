import React, { Component } from 'react'
import InvestimentFilter from '../../components/Investiments/InvestimentFilter'
import InvestimentList from "../../components/Investiments/InvestimentList"
import investimentValidator from "./validate"
import PageHeader from "../../components/Global/PageHeader"
import { Toast } from "../../helper/alerts"
import { Link } from "react-router"
import { updateInvestiment, getInvestiments } from "../../services/investiments"

class Investiments extends Component {
    state = {
        investiments: [],
        currentPage: 1,
        itemsPerPage: 5,
        totalItems: 0,
        loading: true,
        minDate: null,
        maxDate: null
    }

    componentDidMount() {
        this.fetchInvestiments()
    }

    handleOnUpdateInvestiment = async (id, investiment) => {
        try {
            await investimentValidator(investiment)
            await updateInvestiment(id, investiment)

            await Toast().fire({ title: 'Investimento Atualizado!', icon: 'success' })
            const { data } = await getInvestiments(this.state.currentPage, this.state.itemsPerPage);

            const { investments, itemsPerPage, totalItems } = data;

            this.setState({ investiments: investments, totalItems, itemsPerPage });

        } catch (e) {
            Toast().fire({ title: e.message, icon: 'error' })
        }
    }

    fetchInvestiments = async () => {
        try {
            const { data } = await getInvestiments(
                this.state.currentPage,
                this.state.itemsPerPage,
                this.state.minDate || null,
                this.state.maxDate || null
            )

            const { investments, itemsPerPage, totalItems } = data

            this.setState({ investiments: investments, totalItems, itemsPerPage })
        } catch (e) {
            Toast().fire({ title: e.message, icon: 'error' })
        }finally {
            this.setState({ loading: false })
        }
    }

    getPage = nextPage => {
        const currentPage = nextPage + 1
        this.setState({ currentPage }, this.fetchInvestiments)
    }

    _handleOnClick = () => {
        this.setState({ currentPage: 1 }, this.fetchInvestiments)
    }

    _handleOnFilter = (minDate, maxDate) => {
        this.setState({ currentPage: 1, minDate, maxDate }, this.fetchInvestiments)
    }

    render() {
        return (
            <div>
                <PageHeader title="Investimentos" />
                <Link to="new-investiment">Adicionar Investimento</Link>
                <InvestimentFilter
                    _handleOnFilter={this._handleOnFilter}
                />
                <InvestimentList
                    onUpdate={this.handleOnUpdateInvestiment}
                    investiments={this.state.investiments}
                    currentPage={this.state.currentPage}
                    totalItems={this.state.totalItems}
                    itemsPerPage={this.state.itemsPerPage}
                    getPage={this.getPage}
                    _handleOnClick={this._handleOnClick}
                />
            </div>
        )
    }
}

export default Investiments