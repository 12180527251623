import React, { Component } from "react";
import moment from "moment";

import {
  getLeadsSchedulesByDay,
  getLeadSchedulesByMonth,
  getLeadSchedulesAvailable
} from "../../services/leads";
import LeadsCalendarComponent from "../../components/Leads/LeadsCalendar/LeadsCalendarComponent";

class LeadsCalendarContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      momentContext: moment().locale("pt-br"),
      days: null,
      daysWithLeads: null,
      selectedDay: null,
      leads: null,
      leadsDay: null,
      locations: [],
      areaId: "",
      category: "",
      evaluationType: ""
    };
  }

  onChangeArea = (areaId) => { this.setState({ areaId }, this.fillDays) }
  onChangeCategory = (category) => { this.setState({ category }, this.fillDays) }
  onChangeEvaluationType = (evaluationType) => { this.setState({ evaluationType }, this.fillDays) }

  componentDidMount() {
    this.fillDays();
  }

  fillDays() {
    let days = [];
    for (let i = 0; i < this.state.momentContext.startOf("month").get("day"); i++) {
      days.push(0);
    }

    for (let i = 0; i < this.state.momentContext.daysInMonth(); i++) {
      days.push(
        moment(this.state.momentContext).add(i, "days").format("YYYY-MM-DD")
      );
    }
    this.setState({ days, selectedDay: null, leadsDay: null });

    try {
      const { areaId, category, evaluationType } = this.state;
      this.getLocationsAvailable(areaId, category, evaluationType);
      this.getEvaluations(areaId, category, evaluationType);
    } catch (error) {
      console.error(error);
    }
  }

  handlePrevMonth = () => {
    this.setState({
      momentContext: this.state.momentContext.subtract(1, "month"),
    });
    this.fillDays();
  };

  handleNextMonth = () => {
    this.setState({ momentContext: this.state.momentContext.add(1, "month") });
    this.fillDays();
  };

  handleChange = (value) => {
    this.setState({ momentContext: moment(value).locale("pt-br") });
    this.fillDays();
  };

  handleSelectDay = (value) => {
    this.setState({ selectedDay: value });
    this.getLeadsDay(value);
  };

  getLeadsDay = (day) => {
    try {
      const { areaId, category, evaluationType, evaluationLocationId } = this.state
      getLeadsSchedulesByDay(day, { areaId, category, evaluationType, evaluationLocation: evaluationLocationId }).then((response) => {
        this.setState({ leadsDay: response.data });
      });
    } catch (error) {
      console.error(error);
    }
  };

  getLeads = (date) => {
    try {
      getLeadSchedulesByMonth(date).then((response) => {
        this.setState({ leads: response.data });
      });
    } catch (error) {
      console.error(error);
    }
  };

  getEvaluations(areaId, category, evaluationType, evaluationLocation = "") {
    getLeadSchedulesByMonth(
      this.state.momentContext.format("YYYY-MM-DD"),
      { areaId, category, evaluationType, evaluationLocation }
    ).then((response) => {
      const daysWithLeads = [];
      response.data && Object.keys(response.data).forEach((day) => {
        daysWithLeads.push(day);
      });
      this.setState({
        leads: response.data,
        daysWithLeads: daysWithLeads
      });
    });
  }

  getLocationsAvailable(areaId, category, evaluationType) {
    if (!evaluationType || !areaId) return;

    const params = {
      "type": evaluationType,
      "areaId": areaId
    };

    if (category) {
      Object.assign(params, { category });
    }

    getLeadSchedulesAvailable(params)
      .then(({ data }) => {
        this.setState({ locations: data });
      })
      .catch(error => {
        console.error(error);
      });
  }

  onChangeEvaluationLocation = (evaluationLocationId) => {
    try {
      const { areaId, category, evaluationType } = this.state;
      this.getEvaluations(areaId, category, evaluationType, evaluationLocationId);
      this.setState({ evaluationLocationId })
    } catch (error) {
      console.error(error);
    }
  }

  render() {
    return (
      <LeadsCalendarComponent
        history={this.props.history}
        days={this.state.days}
        leads={this.state.leads}
        leadsDay={this.state.leadsDay}
        selectedDay={this.state.selectedDay}
        daysWithLeads={this.state.daysWithLeads}
        momentContext={this.state.momentContext}
        locations={this.state.locations}
        onChangeArea={this.onChangeArea}
        handleChange={this.handleChange}
        handlePrevMonth={this.handlePrevMonth}
        handleNextMonth={this.handleNextMonth}
        handleSelectDay={this.handleSelectDay}
        onChangeCategory={this.onChangeCategory}
        onChangeEvaluationType={this.onChangeEvaluationType}
        onChangeEvaluationLocation={this.onChangeEvaluationLocation}
      />
    );
  }
}

export default LeadsCalendarContainer;
