/* global _ */
import { FETCH_LEADS } from './../actions/leads/fetch_leads';
import { UPDATE_LEAD } from './../actions/leads/update_lead';
import { SET_CURRENT_LEAD } from './../actions/leads/set_lead.js';

import { RESET_CURRENT_LEAD } from './../actions/leads/reset_lead';

const lead = (state, action) => {
  switch(action.type) {
  case UPDATE_LEAD:
    if (state._id === action.meta.id) {
      return {
        ...state,
        ...action.meta.data
      };
    }

    return state;
  }
};

const INITIAL_STATE = {
  all: [],
  current: null
};

const LeadsReducer = (state = INITIAL_STATE, action) => {
  if (action.error) {
    return state;
  }

  switch (action.type) {
  case FETCH_LEADS:
    return {
      ...state,
      all: action.payload.data
    };

  case UPDATE_LEAD:
    return {
      ...state,
      all: _.map(state.all, l => lead(l, action))
    };

  case SET_CURRENT_LEAD:
    return {
      ...state,
      current: action.meta.lead
    };

  case RESET_CURRENT_LEAD:
    return {
      ...state,
      current: null
    };

  default:
    return state;
  }
};

export default LeadsReducer;
