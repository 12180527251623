import config from "../../../config.json";
const env = process.env.REACT_APP_ENVIRONMENT || "stage";
const BACKEND_URL = config.nodeAPI[env];
import axios from "axios";

import { getToken } from "./../../models/auth";

export const ADD_ADDRESS = "ADD_ADDRESS";

export async function addAddress(userId, address) {
  const addressPromise = await axios.post(
    `${BACKEND_URL}/user/address/${userId}`,
    { ...address },
    {
      headers: { Authorization: `Bearer ${getToken()}` },
    }
  );

  return {
    type: ADD_ADDRESS,
    payload: addressPromise,
  };
}
