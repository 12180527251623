import { FETCH_AIRPORTS } from '../actions/airport/fetch_airport';

const INITIAL_STATE = {
  all: [],
  itemsPerPage: 0,
  totalItems: 0
};

const airportsReducer = (state = INITIAL_STATE, action) => {
  if (action.error) {
    return state;
  }
 
  switch (action.type) {
  case FETCH_AIRPORTS:
    return {
      ...state,
      all: action.payload.data.airports,
      itemsPerPage: action.payload.data.itemsPerPage,
      totalItems: action.payload.data.totalItems
    };

  default:
    return state;
  }
};

export default airportsReducer;
