import React, { Component } from "react";
import Griddle from "griddle-react";
import axios from "../../helper/axios";
import { columnData, columnNames } from "./InfractionData";

export default class InfractionsGriddle extends Component {
  constructor(props) {
    super(props);
    this.state = {
      schedule: {},
      artist: {},
      scheduleId: props.scheduleId,
      scheduleInfractions: [],
      numberOfPages: 30,
      currentPage: 1
    };
    this.fetchScheduleInfo()
  }

  getPage(nextPage) {
    const currentPage = nextPage + 1;
    this.setState({ currentPage }, this.fetchScheduleInfo);
  }

  fetchScheduleInfo() {
    axios
      .get(`${BACKEND_URL}/v2/bo/schedule/${this.state.scheduleId}?page=${this.currentPage}&limit=10`)
      .then(response => {
        if (res.data.length < 10) {
          this.setState({ numberOfPages: this.state.currentPage });
        }
        this.setState({
          schedule: response.data ? response.data : {},
          newInfractionOptions: {
            artist: response.data,
            infraction: {}
          }
        });
        this.fetchArtistInfractios();
      })
      .catch(err => { });
  }

  componentWillReceiveProps(props) {
    this.props = props;
    if (props.professionalId !== this.state.professionalId) {
      this.setState({ professionalId: props.professionalId });
      this.fetchArtistInfractions();
    }
  }

  componentWillMount() {
    this.fetchScheduleInfractions();
  }

  fetchScheduleInfractions() {
    axios
      .get(`${BACKEND_URL}/v2/infractions/schedule/${this.state.scheduleId}`)
      .then(res => {
        this.setState({
          scheduleInfractions: res.data
        });
      });
  }

  render() {
    const { scheduleInfractions } = this.state;
    return (
      <div className="col-xs-12">
        <h3>Infrações para o agendamento: {this.state.scheduleId}</h3>
        <br />

        <Griddle
          filterPlaceholderText={"Filtrar Resultados"}
          noDataMessage={"Não há dados para serem exibidos"}
          columns={columnNames}
          columnMetadata={columnData}
          results={scheduleInfractions}
          showFilter={true}
          showSettings={false}
          resultsPerPage={10}
          useFixedLayout={false}
          tableClassName={"GriddleTable table"}
          useGriddleStyles={false}
          settingsToggleClassName={"btn btn-default"}
          useExternal
          externalChangeSort={() => { }}
          externalSetFilter={() => { }}
          externalSetPageSize={10}
          externalSetPage={this.getPage.bind(this)}
          externalCurrentPage={this.state.currentPage - 1}
          externalMaxPage={1}
        />
      </div>
    );
  }
}
