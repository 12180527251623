import config from '../../../config.json';
const env = process.env.REACT_APP_ENVIRONMENT || 'stage';
const BACKEND_URL = config.api[env];
import axios from 'axios';

import { getToken } from './../../models/auth';

export const UPDATE_SCHEDULE_DATE = 'UPDATE_SCHEDULE_DATE';

export function updateScheduleDate(scheduleId, newDate, newDateEnd ) {
  const updateDatePromise = axios.put(`${BACKEND_URL}/v1/boUser/schedule/changeDate`,
    { scheduleId, newDate, newDateEnd },
    {
      headers: { Authorization: `Bearer ${getToken()}` }
    }
  );

  return {
    type: UPDATE_SCHEDULE_DATE,
    payload: updateDatePromise,
    meta: { newDate, newDateEnd, id: scheduleId }
  };
}
