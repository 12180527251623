import React, { Component, PropTypes } from 'react';

// --- Style --- \\

import './ScheduleReactivationModal.css';
import { Button } from 'react-bootstrap';
import { CopyToClipboard } from 'react-copy-to-clipboard';

const paymentMethodsToReactivation = [
  {
    label: "Pré-autorizaçao",
    value: "CREDIT_CARD"
  },
  {
    label: "Pix",
    value: "PIX"
  },
  {
    label: "Link Plataforma Stripe",
    value: "CHECKOUT"
  },
]

class ScheduleReactivationModalContent extends Component {
  constructor(props) {
    super(props);
    this.state = {}
  }

  copyToClipboardClick() {
    this.props.notify('Informações copiadas com sucesso.', 'success');
  }

  renderPaymentMethodSelection() {
    return (
      <div>
        <p style={{ fontWeight: 400, fontSize: "20px", color: "#212121", lineHeight: "30px" }}>
          Por favor, selecione um meio de pagamento para a reativação deste pedido
        </p>
        <select className="payment-method-select" onChange={(e) => this.props.handleChangeReactivationType(e.target.value)}>
          <option disabled="disabled" selected>
            Selecionar meio de pagamento
          </option>
          {paymentMethodsToReactivation.map((paymentMethod, index) => (
            <option
              key={paymentMethod.label + index}
              value={paymentMethod.value}
            >
              {paymentMethod.label}
            </option>
          ))}
        </select>
      </div>
    );
  }


  render() {
    const { step } = this.props;

    return (
      <div className="content">
        {step === 1 && this.renderPaymentMethodSelection()}

        {step === 2 && this.props.reactivationType === "CREDIT_CARD" && (
          <div>
            <p className="content-text">
              Antes de reativar o pedido, relembre o cliente sobre o cartão estar desbloqueada e possuir limite.
            </p>
            <p className="content-text" style={{ marginTop: "32px" }}>
              Ao confirmar esta ação, irá gerar uma nova pré-autorização no cartão do cliente, o cliente irá receber um push notification avisando sobre o status de pré-autorização.
            </p>
          </div>
        )}

        {step === 2 && this.props.reactivationType === "CHECKOUT" && (
          <div>
            <p className="content-text">Ao confirmar essa ação você estará gerando um novo link de pagamento Stripe que deverá ser compartilhado com o cliente. </p>
          </div>
        )}
        {this.props.reactivationLoading && (
          <div>
            <p className="content-text">
              Aguarde enquanto estamos reativando este agendamento...
            </p>
          </div>
        )}


        {/* Steps For Pix */}

        {step === 2 && this.props.reactivationType === "PIX" && (
          <div>
            <p className="content-text">
              Ao confirmar essa ação você estará gerando um novo link de
              pagamento Pix que deverá ser compartilhado com o cliente.
            </p>
          </div>
        )}

        {step === 4 && this.props.reactivationType === "PIX" && this.props.reactivationDetails.status === "SUCCESS" && (
          <div>
            <p className="content-text">
              O pedido será reativado após pagamento do Pix.
            </p>
            <div className="input-container">
              <input
                className="input-copy-pix"
                value={this.props.reactivationDetails.copyAndPasteCode}
              />
              <CopyToClipboard
                text={this.props.reactivationDetails.copyAndPasteCode}
                onCopy={() => this.copyToClipboardClick()}
              >
                <Button
                  style={{
                    position: 'absolute',
                    right: '5px',
                    bottom: '5px'
                  }}
                  className="ScheduleActions-btn btn btn-info btn-clipboard"
                  disabled={this.props.disabled}
                  title="Copiar linha"
                >
                  <span className="glyphicon glyphicon-copy" />
                </Button>
              </CopyToClipboard>
            </div>
          </div>
        )}

        {step === 4 && this.props.reactivationType === "CHECKOUT" && this.props.reactivationDetails.status === "SUCCESS" && (
          <div>
            <p className="content-text">
              O pedido será reativado após pagamento do Pedido.
            </p>
            <div className="input-container">
              <input
                className="input-copy-pix"
                value={this.props.reactivationDetails.linkPayment}
              />
              <CopyToClipboard
                text={this.props.reactivationDetails.linkPayment}
                onCopy={() => this.copyToClipboardClick()}
              >
                <Button
                  style={{
                    position: 'absolute',
                    right: '5px',
                    bottom: '5px'
                  }}
                  className="ScheduleActions-btn btn btn-info btn-clipboard"
                  disabled={this.props.disabled}
                  title="Copiar linha"
                >
                  <span className="glyphicon glyphicon-copy" />
                </Button>
              </CopyToClipboard>
            </div>
          </div>
        )}

        {step === 4 && this.props.reactivationDetails.status === "FAILED" && (
          <div>
            <p className="content-text">
              Não foi possível reativar esse pedido, por favor tente novamente.
            </p>
          </div>
        )}

        {step === 4 && this.props.reactivationDetails.status === "FAILED" && this.props.reactivationType === "CREDIT_CARD" && (
          <div>
            <p className="content-text">
              Pré-autorização negada, não foi possível reativar o pedido. Por favor instrua o cliente a desbloquear o cartão e tentar novamente, ou trocar o meio de pagamento, caso o erro persista crie um novo pedido via backoffice.
            </p>
          </div>
        )}

        {step === 4 && this.props.reactivationDetails.status === "SUCCESS" && this.props.reactivationType === "CREDIT_CARD" && (
          <div>
            <p className="content-text">
              Pré autorização aprovada, a cobrança foi pré aprovada  no cartão do cliente, o pedido foi realizado.
            </p>
          </div>
        )}

      </div>
    );
  }
}

ScheduleReactivationModalContent.propTypes = {
  rowData: PropTypes.object,
  notify: PropTypes.func,
  dataConfirmationAction: PropTypes.func,
  updateCallback: PropTypes.func
};

export default ScheduleReactivationModalContent;
