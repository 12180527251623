import React, { Component } from 'react';
import moment from 'moment';
import Griddle from 'griddle-react';

import AreaSelect from "../../Helpers/AreaSelect"

import { scheduleTypes, leadTechniques } from "../../../helper/leads";

import './styles.css';


class LeadsCalendarComponent extends Component {

  constructor(props) {
    super(props);

    this.state = {
      isLocationEnable: false,
      availableEvaluationLocations: []
    }
  }

  componentWillReceiveProps(newProps) {
    const { locations } = newProps;
    if (locations) {
      this.setState({ availableEvaluationLocations: locations });
    }
  }

  handleLocationVisible(value) {
    this.setState({ isLocationEnable: ["1", "2"].includes(value.toString()) });
  }

  render() {
    const { momentContext, days, daysWithLeads, selectedDay, leads, leadsDay, handlePrevMonth, handleNextMonth, handleChange, handleSelectDay } = this.props;
    moment.locale('pt-br');
    const weekDays = moment.weekdaysShort();
    let leadsOfTheDayCounter = 0;

    return (
      <div className="container">
        <div className="row">

          <h3>Filtros</h3>
          <div className="col-xs-3">
            <AreaSelect onChange={this.props.onChangeArea} />
          </div>
          <div className="col-xs-3">
            <p>Categoria</p>
            <select className="form-control" defaultValue="" onChange={evt => this.props.onChangeCategory(evt.target.value)}>
              <option value="">Selecione uma categoria</option>
              {
                leadTechniques.map(techique => {
                  return <option value={techique.value}>{techique.text}</option>
                })
              }
            </select>
          </div>
          <div className="col-xs-3">
            <p>Tipo do Teste</p>
            <select className="form-control" defaultValue="" onChange={evt => {
              const type = evt.target.value;
              this.props.onChangeEvaluationType(type);
              this.handleLocationVisible(type);
            }}>
              <option value="">Selecione um tipo</option>
              {scheduleTypes.map(evaluationType => {
                return (<option value={evaluationType.value}>{evaluationType.text}</option>)
              })}
            </select>
          </div>
          <div className="col-xs-3">
            <p>Local</p>
            <select className="form-control" defaultValue="" onChange={evt => this.props.onChangeEvaluationLocation(evt.target.value)} disabled={!this.state.isLocationEnable}>
              <option value="">Selecione um local</option>
              {this.state.availableEvaluationLocations.map(({ _id, location }) => {
                if (!location || !location.formatted_address) return;
                return (<option value={_id}>{location.formatted_address}</option>)
              })}
            </select>
          </div>
        </div>
        <div className="row">
          <div className="calendarWrapper">
            <div className="headerWrapper">
              <div className="arrowsWrapper">
                <span onClick={handlePrevMonth}>Voltar Mês</span>
                <input type="date" value={momentContext.format('YYYY-MM-DD')} onChange={(e) => handleChange(e.target.value)} />
                <span onClick={handleNextMonth}>Próximo Mês</span>
              </div>
            </div>

            <div className="weekdaysWrapper">
              {!!weekDays && weekDays.map(weekName => {
                return <span key={weekName} className='weekDay'>{weekName}</span>
              })}
            </div>

            <div className="daysWrapper">
              {
                days &&
                days.map((day, index) =>
                  <span key={index} onClick={() => handleSelectDay(day)} className={`${day !== 0 ? 'day' : 'blankDay'} ${day == moment().format('YYYY-MM-DD') && 'active'} ${selectedDay && selectedDay === day && 'selected'}`}>
                    {day === 0 ? ' ' : moment(day).format('DD')}
                    <div style={{ marginTop: "20px" }}>
                      {(() => {
                        leadsOfTheDayCounter = 0;
                        return;
                      })()}
                      {
                        day !== 0 && daysWithLeads && daysWithLeads
                          .sort((a, b) => new Date(a) - new Date(b))
                          .map((dayWLead) => {

                            if (!dayWLead.includes(day) || !leads) return;

                            const leadsOfTheDay = leads[dayWLead];
                            if (!leadsOfTheDay) return;

                            const hour = moment(dayWLead).tz("America/Sao_Paulo").format('HH:mm');

                            let presencial = 0;
                            let recruiter = 0;
                            let online = 0;

                            const leadCodes = {
                              1: () => presencial++,
                              2: () => recruiter++,
                              3: () => online++
                            }

                            leadsOfTheDay.forEach(lead => {
                              if (!lead?.evaluation?.type?.code || lead?.status[0]?.code !== 2) return;

                              const code = lead.evaluation.type.code;
                              const incrementLeadType = leadCodes[code];
                              incrementLeadType && incrementLeadType();

                              leadsOfTheDayCounter++;
                            });

                            return (
                              <div className="total-helper" key={hour}>
                                {!!presencial && <span>{presencial} Presencial as {hour}</span>}
                                {!!recruiter && <span>{recruiter} Recrutadora as {hour}</span>}
                                {!!online && <span>{online} Online as {hour}</span>}
                              </div>
                            );
                          })
                      }
                      {
                        leadsOfTheDayCounter !== 0 && (
                          <span
                            style={{
                              fontWeight: 'bold',
                              fontSize: "14px",
                              position: "absolute"
                            }}>Total: {leadsOfTheDayCounter}</span>
                        )
                      }
                    </div>
                  </span>)}
            </div>
          </div>
        </div>
        <div className="row">
          <h3>Leads do dia selecionado</h3>
          <Griddle
            results={leadsDay || []}
            resultsPerPage={10}
            useFixedLayout={false}
            columnMetadata={[
              {
                columnName: "cpf",
                displayName: "CPF",
              },
              {
                columnName: "name",
                displayName: "Nome",
              },
              {
                columnName: 'phone',
                displayName: 'Telefone',
                customComponent: ({ rowData }) => {
                  return (
                    <span>{rowData.phone.number}</span>
                  );
                },
              },
              {
                columnName: 'evaluation.type',
                displayName: 'Modalidade',
                customComponent: ({ rowData }) => {
                  return (
                    <span>{rowData.evaluation.type.description}</span>
                  );
                },
              },
              {
                columnName: 'evaluation',
                displayName: 'Horário',
                customComponent: ({ rowData }) => {
                  return (
                    <div>
                      <span>{moment(rowData.evaluation.date).tz("America/Sao_Paulo").format('HH:mm')}</span>
                      <button className="detailsButton" onClick={() => this.props.history.push(`/leads/${rowData._id}`)}>Detalhes</button>
                    </div>
                  );
                },
              },
            ]}
            tableClassName={"GriddleTable table"}
            useGriddleStyles={false}
            settingsToggleClassName={"btn btn-default"}
            columns={[
              "cpf",
              "name",
              "phone",
              "evaluation.type",
              "evaluation",
            ]}
          />
        </div>
      </div >
    )
  }
}

export default LeadsCalendarComponent;

