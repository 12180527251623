import React, { Component } from "react";

export default class PackageCategory extends Component {
  constructor(props) {
    super(props);
    this.state = {
      originalAmount: 0,
      discountAmount: 0,
      discountInPercent: 0,
      totalAmount: 0,
      voucherAmount: 0,
      voucherCount: 1,
      installmentMaxLimit: 1,
      pack: {
        ...props.pack,
        services: [{ count: 0, serviceList: [], value: 0 }],
        valueType: "P",
      },
    };
  }

  renderCategories = () => {
    const { areas } = this.props;
    const categories = [];
    const options = [];

    areas.forEach((area) => {
      area.categories.forEach((category) => {
        if (!categories.includes(category._id)) {
          categories.push(category._id);
          options.push(
            <option key={category._id} value={JSON.stringify(category)}>
              {category.activity}
            </option>
          );
        }
      });
    });
    return options;
  };

  calculateAmount(discountInPercent, originalAmount) {
    const discountAmount =
      originalAmount - originalAmount * (1 - discountInPercent / 100);
    const totalAmount = originalAmount - discountAmount;
    return { discountAmount: discountInPercent, totalAmount };
  }

  handleOnAmountsChange = (ev) => {
    const { pack } = this.state;
    let { name, value } = ev.target;
    value = Number(value);

    if (name === "voucherCount") pack.services[0].count = value;

    if (name === "voucherAmount") pack.services[0].value = value;

    if (name === "installmentMaxLimit") pack.installmentMaxLimit = value;

    if (name === "discountInPercent") {
      const { discountAmount, totalAmount } = this.calculateAmount(
        value,
        this.state.originalAmount
      );
      pack.discountAmount = discountAmount;
      pack.totalAmount = totalAmount;
      pack.discountInPercent = value;
      this.setState({ totalAmount, discountAmount });
    }
    if (name === "originalAmount") {
      const { discountAmount, totalAmount } = this.calculateAmount(
        this.state.discountInPercent,
        value
      );
      pack.discountAmount = discountAmount;
      pack.totalAmount = totalAmount;
      pack.originalAmount = value;
      this.setState({ totalAmount, discountAmount });
    }

    this.setState({ [name]: value, pack });
    this.props.onUpdatePack(pack);
  };

  handleOnCategoryChange = (ev) => {
    const { pack } = this.state;
    const category = JSON.parse(ev.target.value);
    pack.services[0].serviceList = category.services.map(
      (service) => service._id
    );
    pack.services[0].title = category.name["pt-BR"];
    this.setState({ pack, category });
    this.props.onUpdatePack(pack);
  };

  render() {
    return (
      <div style={{ padding: "25px" }}>
        <div className="row">
          <div className="col-xs-4 form-group">
            <label>Categorias</label>
            <select
              onChange={this.handleOnCategoryChange}
              className="form-control form-control-normal"
            >
              <option value=""> - </option>
              {this.renderCategories()}
            </select>
          </div>
          <div className="col-xs-4 form-group">
            <label>Valor do voucher em %</label>
            <input
              className="form-control form-control-normal"
              type="number"
              max={100}
              min={1}
              name="voucherAmount"
              value={this.state.voucherAmount}
              onChange={this.handleOnAmountsChange}
            />
          </div>
          <div className="col-xs-4 form-group">
            <label>Quantidade de vouchers</label>
            <input
              className="form-control form-control-normal"
              name="voucherCount"
              type="number"
              min={1}
              value={this.state.voucherCount}
              onChange={this.handleOnAmountsChange}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-xs-4 form-group">
            <label>Valor do pacote em R$</label>
            <input
              className="form-control form-control-normal"
              name="originalAmount"
              min={1}
              value={this.state.originalAmount}
              onChange={this.handleOnAmountsChange}
            />
          </div>
          <div className="col-xs-4">
            <label>Quantidade de Parcelas</label>
            <select
              className="form-control form-control-normal"
              name="installmentMaxLimit"
              onChange={this.handleOnAmountsChange}
              value={this.state.installmentMaxLimit}
            >
              {Array(12)
                .fill(0)
                .map((item, idx) => (
                  <option key={idx} value={idx + 1}>
                    {idx + 1}
                  </option>
                ))}
            </select>
          </div>
          <div className="col-xs-4 form-group">
            <label>Desconto em %</label>
            <input
              className="form-control form-control-normal"
              name="discountInPercent"
              min={1}
              max={100}
              value={this.state.discountInPercent}
              onChange={this.handleOnAmountsChange}
            />
          </div>
        </div>
        <div className="col-xs-12">
          <p className="pack-total">
            Total de serviços: R${" "}
            {parseFloat(this.state.pack.originalAmount).toFixed(2) || 0}
          </p>
          <p>
            Valor com desconto: R${" "}
            {parseFloat(this.state.pack.totalAmount).toFixed(2) || 0}
          </p>
        </div>
      </div>
    );
  }
}
