import React from "react";
import { Component, PropTypes } from 'react';
import { connect } from 'react-redux';
import Loading from './../../components/Global/Loading/Loading';
import ArtistsGriddle from './../../components/Artists/ArtistsGriddle';

// actions
import { fetchArtists } from './../../actions/artists/fetch_artists';
import { updateArtist } from './../../actions/artists/update_artist';

class Artists extends Component {
  state = {
    currentPage: 1,
    filter: '',
    dataArrived: false,
    artists: []
  };

  componentWillMount() {
    const fulfill = () => this.setState({ dataArrived: true });
    this.fetchArtists().then(fulfill);
  }

  componentWillReceiveProps(newProps) {
    const { artists } = newProps;

    if (artists) {
      this.setState({ artists });
    }
  }

  fetchArtists() {
    return this.props.fetchArtists(this.state.currentPage, this.state.filter);
  }

  getPage = nextPage => {
    const currentPage = nextPage + 1;
    this.setState({ currentPage }, this.fetchArtists);
  };

  _handleChange = (e) => {
    this.setState({ filter: e.target.value });
  };

  _handleClick = () => {
    this.setState({ currentPage: 1 }, this.fetchArtists);
  };

  render() {
    const { dataArrived, artists } = this.state;

    if (!dataArrived) {
      return (
        <div>
          <Loading />
        </div>
      );
    }

    const props = {
      artists,
      router: this.context.router,
      updateAction: this.props.updateArtist,
      getPage: this.getPage,
      _handleChange: this._handleChange,
      _handleClick: this._handleClick,
      currentPage: this.state.currentPage,
      totalItems: this.props.totalItems,
      itemsPerPage: this.props.itemsPerPage
    };

    return (
      <div>
        <ArtistsGriddle {...props} />
      </div>
    );
  }
}

Artists.propTypes = {
  artists: PropTypes.array,
  fetchArtists: PropTypes.func,
  updateArtist: PropTypes.func
};

Artists.contextTypes = {
  router: PropTypes.object.isRequired
};

function mapStateToProps(state) {
  return {
    artists: state.artists.all,
    currentArtist: state.artists.current,
    totalItems: state.artists.totalItems,
    itemsPerPage: state.artists.itemsPerPage
  };
}

const mapActionsToProps = {
  fetchArtists,
  updateArtist
};

export default connect(mapStateToProps, mapActionsToProps)(Artists);
