import config from '../../../config.json';
const env = process.env.REACT_APP_ENVIRONMENT || 'stage';
const BACKEND_URL = config.api[env];
import _ from 'underscore';
import moment from 'moment';

import React from "react";
import Griddle from "griddle-react";
import { Component, PropTypes } from "react";
import artistsMetadata from "./Artists_metadata";
import formatter from "./../../helper/formatters";
import PageHeader from "../../components/Global/PageHeader";
import { Modal, Button } from "react-bootstrap";
import { createAccount } from "../../actions/artists/create_moip_artist";
import NotificationSystem from "react-notification-system";
import auth from './../../models/auth';

class ArtistsGriddle extends Component {
  state = {
    isEditModalOpened: false,
    artist: null
  };

  componentDidMount() {
    const { artists } = this.props;
    this.mapArtists(artists);
  }

  componentWillReceiveProps(newProps) {
    const { artists } = newProps;

    if (artists) {
      this.mapArtists(artists);
    }
  }
  artist(artist) {
    this.props.router.push(`artist/${artist._id}`);
  }

  editArtist(artist) {
    this.props.router.push(`edit-artist/${artist._id}`);
  }

  addInfraction(artist) {
    this.props.router.push(`infraction/artist/${artist._id}`);
  }

  openMoipModal(code) {
    this.setState({ moipModalOpen: true, code });
  }

  closeMoipModal() {
    this.setState({ moipModalOpen: false });
  }

  notify(message, level) {
    const title = level === "success" ? "Sucesso" : "Erro";
    this.refs.notificationSystem.addNotification({
      title,
      message,
      level,
      position: "tc"
    });
  }

  createMoipAccount() {
    const { code } = this.state;
    const artistId = code._id;

    createAccount(artistId)
      .then(res => {
        let message;
        let status;

        if (res.status === 200) {
          console.log("createMoipAccount -> success:", JSON.stringify(res));

          const result = JSON.stringify(res.data);
          const objectResult = JSON.parse(result);

          if (objectResult.code === "singu-000") {
            message = objectResult.message || "Conta Moip criado com sucesso.";
            status = "success";

            const link = objectResult.data._links.setPassword.href;

            window.open(link, "_blank");
          } else {
            message =
              objectResult.message || "Houve um erro para criar conta na Moip.";
            status = "error";
          }

          this.notify(message, status);
          return;
        } else {
          console.log("createMoipAccount -> not success:", JSON.stringify(res));

          throw { message: "Houve um erro para criar conta na Moip." };
        }
      })
      .catch(error => {
        console.log("createMoipAccount -> error:", JSON.stringify(error));

        const messageError =
          error.message || "Houve um erro para criar conta na Moip.";

        this.notify(messageError, "error");
        return;
      });

    this.closeMoipModal();
  }

  mapArtists(artists) {
    const self = this;
    const mapArtists = artist => ({
      ...artist,
      id: artist._id,
      createdDate: moment(artist.createdDate).format("DD/MM/YYYY HH:mm"),
      name: artist.name,
      email: artist.email,
      phone: formatter.formatPhone(artist.phone),
      formattedAddress:
        formatter.formatAddress(artist.address) || "Sem endereço",
      address: artist.address,
      comments: artist.comments || [],
      actions: {
        artist: self.artist.bind(self),
        editArtist: self.editArtist.bind(self),
        addInfraction: self.addInfraction.bind(self),
        openMoipModal: self.openMoipModal.bind(self)
      }
    });
    const mappedArtists = _.map(artists, mapArtists);

    this.setState({ artists: mappedArtists });
  }

  render() {
    const { artists } = this.state;

    return (
      <div>
        <PageHeader title="Profissionais" />

        <div className="Artists">
          <a
            href={`${BACKEND_URL}/v2/infractions/export?token=${auth.getToken()}`}
            target="_blank"
            className="btn btn-default"
            style={{
              float: "right",
              marginTop: 40,
              marginBottom: 10,
              textDecoration: "none"
            }}
          >
            Exportar todas as infrações
          </a>

          <b style={{ marginLeft: 10, marginRight: 10 }}>Filtrar:</b>  <input
            value={this.props.filter}
            onChange={this.props._handleChange}
            type="text"
            width="300px"
            className="form-control"
            placeholder="shortcode, id, status, artista ..."
          />

          <button className="btn btn-info"
            style={{ marginLeft: 10 }}
            onClick={this.props._handleClick}>
            BUSCAR
        </button>

          <Griddle
            useExternal
            externalSetFilter={() => { }}
            externalSetPageSize={this.props.itemsPerPage}
            results={artists}
            resultsPerPage={this.props.itemsPerPage}
            useFixedLayout={false}
            columnMetadata={artistsMetadata}
            tableClassName={"GriddleTable table"}
            useGriddleStyles={false}
            settingsToggleClassName={"btn btn-default"}
            externalSetPage={this.props.getPage}
            externalCurrentPage={this.props.currentPage - 1}
            externalMaxPage={Math.ceil(this.props.totalItems / this.props.itemsPerPage)}
            columns={[
              "id",
              "createdDate",
              "name",
              "email",
              "phone",
              "formattedAddress",
              "actions"
            ]}
          />
        </div>

        <NotificationSystem ref="notificationSystem" />

        <Modal
          className="Modal Modal--large"
          show={this.state.moipModalOpen}
          onHide={() => this.closeMoipModal()}
        >
          <Modal.Header closeButton>
            <Modal.Title className="Modal-title">Moip</Modal.Title>
          </Modal.Header>
          <Modal.Body>Deseja criar a conta da profissional na Moip?</Modal.Body>
          <Modal.Footer>
            <Button onClick={() => this.closeMoipModal()}>Fechar</Button>
            <Button
              className="btn btn-info"
              onClick={() => this.createMoipAccount(this)}
            >
              Criar conta
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

ArtistsGriddle.propTypes = {
  artists: PropTypes.array,
  router: PropTypes.object,
  editAction: PropTypes.func,
  updateAction: PropTypes.func
};

export default ArtistsGriddle;
