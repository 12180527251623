import React, { Component } from "react";
import Swal from "sweetalert2";
import '../../style/checkbox.css';
import { makeGoogleAdmin } from "../../actions/staff/make_google_admin";

export default class StaffForm extends Component {
  state = {
    _id: "",
    name: "",
    email: "",
    password: "",
    active: true,
    type: "staff",
    isSaving: false,
  };

  profiles = {
    administrator: "Administrador",
    staff: "Acesso básico",
    marketing : "Marketing",
    operation: "Operações",
    finance : "Financeiro",
    operationHead: "Head Operacional",
    financeHead: "Head Financeiro",
  }
  componentWillMount() {
    if (this.props.staff._id) {
      this.setState({
        _id: this.props.staff._id,
        name: this.props.staff.name,
        email: this.props.staff.email,
        active: this.props.staff.active,
        password: "",
        type: this.props.staff.type,
      });
    }
  }

  saveStaff = async () => {
    try { 
      const { saveStaff, getStaffs } = this.props;
      this.setState({ isSaving: true });

      const { email, name } = this.state;

      if(name === '' || email === '') return;

      const formattedEmail = email.includes('@singu.com.br') ? email : `${email}@singu.com.br`;

      const data = {
        ...this.state,
        email: formattedEmail.toLowerCase(),
      }
      
      delete data.isSaving;

      const result = await Swal.fire({
        title: 'Você tem certeza?',
        html: `
          <div>
            <h3>${data._id ? 'Atualizar usuário' : 'Criar usuário'}</h3>
            <p>Nome: <b>${data.name}</b></p>
            <p>E-mail: <b>${data.email}</b></p>
            <p>Acesso: <b>${this.props.getProfiles[data.type]}</b></p>
            ${data._id && data.password !== '' ? `<p>Senha: <b>${data.password}</b></p>` : ''}
            <p>Ativo: <b>${data.active ? 'Ativado' : 'Desativado'}</b></p>
            ${!data.active ? `<b>Desativar o usuário removerá todas suas informações do Google.</b>` : ''}
          </div>
        `,
        icon: 'warning',
        reverseButtons: true,
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Sim, salvar!',
        cancelButtonText: 'Cancelar',
      })
      
      if(!result.isConfirmed) return;

      if(!data.active) {
        const deleteGoogleResult = await Swal.fire({
          title: 'Deletar usuário Google?',
          text: 'Deletar o usuário removerá todas as suas informações do Google.',
          icon: 'warning',
          reverseButtons: true,
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Sim, deletar!',
          cancelButtonText: 'Não deletar',
        });

        if(deleteGoogleResult.isConfirmed) {
          data.deleteGoogle = true
        }
      }

      const response = await saveStaff(data);

      Swal.fire({ text: response.data.message, icon: 'success' });
      getStaffs();
    } catch (err) {
      let errorMessage = 'Erro, por favor tente novamente.';

      if(err.response.data && err.response.data.error) errorMessage = err.response.data.error.description

      Swal.fire({
        icon: "error",
        text: errorMessage,
      });
      
    } finally {
      this.setState({ isSaving: false });
    }
  };

  makeAdmin = async () => {
    try {
      console.log(this.props)
      const { makeAdmin, staff } = this.props;

      console.log(makeAdmin)

      if(!staff._id) return;

      await Swal.fire({
        title: 'Tornar staff Admin Google?',
        text: 'Essa ação tornará o staff admin na sua conta Google.',
        icon: 'warning',
        reverseButtons: true,
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Sim, tornar!',
        cancelButtonText: 'Cancelar',
        showLoaderOnConfirm: true,
        preConfirm: async () => await makeGoogleAdmin({ staffId: staff._id }),
        allowOutsideClick: false
      });

      Swal.fire({ text: 'Sucesso, o staff virou Admin no Google!', icon: 'success' });
    } catch (err) {
      console.error('Make Admin Error', err);
      Swal.fire({ icon: 'error', text: err.message });
    }
  }

  onNameChange = (event) => {
    const { value } = event.target;

    const words = value.split(' ');
    const firstName = words[0];
    const lastName = words[words.length - 1];

    const email = `${firstName}.${words.length > 1 ? lastName : ''}`

    this.setState({
      name: value,
      email: !firstName ? '' : email.toLowerCase(),
    })
  } 

  render() {
    const isNew = this.props.staff._id ? false : true;
    const { name, email, password, active, type, profile, isSaving } = this.state;

    return (
      <div className="row">
        <div className="col-xs-12">
          {!isNew && (
            <form>
              <button className="btn btn-success" onClick={this.makeAdmin}>
                Tornar Admin Google
              </button>
            </form>
          )}
        </div>
        <div className="col-xs-12">
          <h2>{isNew ? "Novo Colocaborador" : "Editar Colaborador"}</h2>
        </div>
        <form>
          <div className="col-xs-12 form-group">
            <label>Nome do colaborador</label>
            <input
              className="form-control form-control-normal"
              name={"name"}
              required
              value={name}
              type="text"
              autoComplete="off"
              onChange={this.onNameChange}
            />
          </div>
          <div className={isNew ? "col-xs-12 form-group" : "col-xs-6 form-group"}>
            <label>Email da singu</label>
            <div className="input-group">
              <input 
                className="form-control" 
                type="text" 
                name="email"
                required
                value={email}
                onChange={(evt) => this.setState({ ...this.state, email: evt.target.value })} />
              <span className="input-group-addon">@singu.com.br</span>
            </div>
          </div>
          <div className={isNew ? "col-xs-12 form-group" : "col-xs-6 form-group"}>
            <label>Tipo de acesso</label>
            <select
              className="form-control form-control-normal"
              required
              value={type}
              onChange={(evt) => this.setState({ ...this.state, type: evt.target.value })}
            >
              {Object.entries(this.props.getProfiles).map(([value, key]) => (
                <option key={value} value={value}>
                  {key}
                </option>
              ))}
            </select>
          </div>
          {!isNew ? (
            <div className="col-xs-6 form-group">
              <label>Senha</label>
              <input
                className="form-control form-control-normal"
                name={"password"}
                value={password}
                onChange={(evt) => this.setState({ ...this.state, password: evt.target.value })}
              />
            </div>
          ) : (
              ""
            )}
          <div className="col-xs-2 form-group">
            <label htmlFor="active" style={{ display: 'block' }}>Ativo</label>
            <label htmlFor="active" className="custom-checkbox checkbox-bounce">
              <input
                type="checkbox"
                id="active"
                checked={active}
                onChange={() => this.setState({ ...this.state, active: !active })}
              />
              <svg viewBox="0 0 21 21">
                <polyline points="5 10.75 8.5 14.25 16 6"></polyline>
              </svg>
            </label>
          </div>
        </form>

        <div className="col-xs-12 text-right" style={{ paddingBottom: "20px" }}>
          <button className="btn btn-warning" onClick={this.props.handleClose}>
            Cancelar
          </button>
          <button className="btn btn-success" onClick={this.saveStaff} style={{ marginLeft: "10px" }}>
            {isSaving ? 'Salvando...' : 'Salvar'}
          </button>
        </div>
      </div>
    );
  }
}
