import config from '../../../config.json';
const env = process.env.REACT_APP_ENVIRONMENT || 'stage';
const BACKEND_URL = config.api[env];

import React, { Component } from "react";
import { Link } from "react-router";
import { moment } from "react-datetime";
import Griddle from "griddle-react";

import { backendAPI } from "../../helper/apis";
import { columnData, columnNames } from "./InfractionData";
import auth from "./../../models/auth";

export default class ArtistInfractionsGriddle extends Component {
  constructor(props) {
    super(props);
    this.state = {
      professionalId: this.props.professionalId,
      artistInfractions: [],
      artistInfractionsSummary: { points: 0, penalty: 0, clientDiscount: 0 },
      artist: this.props.artist,
      currentPage: 1,
      numberOfPages: 10
    };
  }

  getPage(nextPage) {
    console.log(nextPage);
    const currentPage = nextPage + 1;
    this.setState({ currentPage }, this.fetchArtistInfractions);
  }

  componentWillReceiveProps(props) {
    this.props = props;
    if (
      this.props.professionalId !== this.state.professionalId ||
      this.props.reload === true
    ) {
      this.setState({ professionalId: this.props.professionalId });
    }
    this.setState({
      artist: this.props.artist
    });
  }

  componentWillMount() {
    this.fetchArtistInfractions();
    this.fetchArtistInfractionsSummary();
  }

  async fetchArtistInfractions() {
    try {
      const { scheduleId, reloaded } = this.props;
      const { currentPage, professionalId } = this.state;

      const response = await backendAPI.get(`/v2/infractions/artist/${professionalId}/${scheduleId}?page=${currentPage}&limit=10`);

      this.setState({
        artistInfractions: response.data,
        numberOfPages: response.headers["x-total-count"]
      });
      if (reloaded && typeof this.props.reloaded === "function") {
        this.props.reloaded();
      }
    } catch (err) {
      console.error('fetchArtistInfractions', err);
    }
  }

  async fetchArtistInfractionsSummary() {
    try {
      const { professionalId } = this.state;
      const { reloaded } = this.props;

      const response = await backendAPI.get(`/v2/infractions/artist/summary/${professionalId}`)

      this.setState({
        artistInfractionsSummary: response.data
      });
      if (reloaded && typeof reloaded === "function") { this.props.reloaded(); }
    } catch (err) {
      console.error('fetchArtistInfractionsSummary', err);
    }
  }

  render() {
    const {
      artistInfractionsSummary,
      artistInfractions,
      artist,
      currentPage,
      numberOfPages
    } = this.state;

    return (
      <div className="col-xs-12">
        <h3>
          Infrações da profissional:
          <b>
            {" "}
            {artist ? (
              <Link to={`/artist/${artist._id}`}>{artist.name}</Link>
            ) : (
              ""
            )}
          </b>
        </h3>
        <b>Ativo:</b> {artist && artist.active ? "Sim" : "Não"} <br />
        <b>Desabilitado: </b>
        {artist &&
          artist.disabledUntil &&
          moment(artist.disabledUntil).isAfter(moment())
          ? `Desabilitado até ${moment(artist.disabledUntil).format(
            "DD/MM/YYYY"
          )}`
          : "Não está desabilitado"}
        <br /> <br />
        <div style={{ fontSize: 20, fontFamily: "monospace" }}>
          <center>
            <b>Pontos:</b> {artistInfractionsSummary.points || 0} --{" "}
            <b>Multa a cobrar:</b> R$
            {artistInfractionsSummary.penalty || "0,00"} --{" "}
            <b>Gastos com cliente:</b> R$
            {artistInfractionsSummary.clientDiscount || "0,00"}
            <br />
            <i style={{ fontSize: 12 }}>Acumulados nos ultimos 90 dias</i>
          </center>
        </div>
        <a
          href={`${BACKEND_URL}/v2/infractions/export/${artist._id}?token=${auth.getToken()}`}
          target="_blank"
          className="btn btn-default"
          style={{
            float: "right",
            marginTop: "50px",
            marginBottom: "20px",
            textDecoration: "none"
          }}
        >
          Exportar todas as infrações da profissional
        </a>
        <Griddle
          filterPlaceholderText={"Filtrar Resultados"}
          noDataMessage={"Não há dados para serem exibidos"}
          columns={columnNames}
          results={artistInfractions || {}}
          showFilter={true}
          showSettings={false}
          useFixedLayout={false}
          columnMetadata={columnData}
          tableClassName={"GriddleTable table"}
          useGriddleStyles={false}
          settingsToggleClassName={"btn btn-default"}
          useExternal
          externalChangeSort={() => { }}
          externalSetFilter={() => { }}
          externalSetPageSize={10}
          externalSetPage={this.getPage.bind(this)}
          externalCurrentPage={currentPage - 1}
          externalMaxPage={numberOfPages}
        />
      </div>
    );
  }
}
