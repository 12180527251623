import { allocationAPI } from '../../helper/apis';

export const UPDATE_PARAMETES_ARTIST = 'UPDATE_PARAMETES_ARTIST';
export function updateParametesArtist(artistId, availability, polygons, isAvailableForOrderAllocation) {
  const updateParametes = allocationAPI.put(
    `/artists/${artistId}/allocation-settings`,
    {
      availability,
      polygons,
      isAvailableForOrderAllocation,
    }
  );
  
  return {
    type: UPDATE_PARAMETES_ARTIST,
    payload: updateParametes
  };
}
