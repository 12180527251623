import React from 'react';

import { Button } from 'react-bootstrap';

// styles
import './PartnerActions.css';

const PartnerActions = props => {
  const { actions: { openConfirmationModal, migratePartner } } = props.rowData;

  return (
    <div className="PartnerActions">
      <div className="PartnerActions-btnWrapper">
        <Button onClick={() => openConfirmationModal(props.rowData, 'update-progress')}
                className="PartnerActions-btn btn btn-info">
          <span className="ss-icon ss-notebook"></span>
        </Button>
        <Button onClick={() => openConfirmationModal(props.rowData, 'ban-partner')}
                className="PartnerActions-btn btn btn-info">
          <span className="ss-icon ss-ban"></span>
        </Button>
        <Button onClick={() => migratePartner(props.rowData.index)}
                className="PartnerActions-btn btn btn-info">
          <span className="ss-icon ss-femaleuser"></span>
        </Button>
      </div>
    </div>
  );
};

export default PartnerActions;
