import React, { Component } from 'react';
import { Button } from 'react-bootstrap';
import moment from 'moment';

import { deleteVouchers } from '../../actions/vouchers/delete_vouchers';
import { Toast } from '../../helper/alerts';

class VoucherView extends Component {
  deleteVoucher() {
    const { voucher } = this.props;
    deleteVouchers(voucher.code)
      .then(() => {
        window.location.reload();
      })
      .catch(error => {
        console.log(error);
        Toast().fire({
          icon: 'error',
          title: 'Erro ao desativar o voucher',
        });
      });
  }

  render() {
    const { voucher } = this.props;
    return (
      <div className="row">
        <div className="col-md-12">
          <div className="comment">
            <p style={{ textAlign: 'center' }}>Voucher criado por: </p>
            <p>
              <b>Usuário: </b>
              {voucher.blame ? voucher.blame.email : 'N/A'}
            </p>
            <p>
              <b>Data: </b>
              {moment(voucher.createdAt).format('DD/MM/YYYY - HH:mm')}
            </p>
            {moment(voucher.end).isBefore(moment()) || (voucher.active && voucher.active === false) ? (
              <p>
                <b>Voucher Desativado - Para ativa-lo troque a data final</b>
              </p>
            ) : (
              <p>
                <b>Voucher Ativo</b>
                <Button
                  style={{ padding: '0 0.25rem', color: '#ff7a66' }}
                  bsStyle="link"
                  title="Desativar"
                  onClick={() => this.deleteVoucher()}
                >
                  <span className="glyphicon glyphicon-refresh"></span>
                </Button>
              </p>
            )}
          </div>

          <div>
            <p style={{ marginTop: 25, textAlign: 'center' }}>
              <b>Usuários que utilizaram o voucher:</b>
            </p>
          </div>
          <div style={{ overflow: 'scroll', height: 100 }}>
            {voucher.usedBy && voucher.usedBy.length > 0 ? (
              voucher.usedBy.map((element, index) => {
                return (
                  <div style={{ marginLeft: 25 }}>
                    <p key={index}>
                      <a target="_blank" href={`#/users/${element}`}>
                        {element}
                      </a>
                    </p>
                  </div>
                );
              })
            ) : (
              <p> </p>
            )}

            {voucher.aquisition && voucher.aquisition.usedBy && voucher.aquisition.usedBy.length > 0 ? (
              voucher.aquisition.usedBy.map((element, index) => {
                return (
                  <div>
                    <p key={index}>
                      <a target="_blank" href={`#/users/${element}`}>
                        {element}
                      </a>
                    </p>
                  </div>
                );
              })
            ) : (
              <p> </p>
            )}

            {voucher.recurrence && voucher.recurrence.usedBy && voucher.recurrence.usedBy.length > 0 ? (
              voucher.recurrence.usedBy.map((element, index) => {
                return (
                  <div>
                    <p key={index}>
                      <a target="_blank" href={`#/users/${element}`}>
                        {element}
                      </a>
                    </p>
                  </div>
                );
              })
            ) : (
              <p> </p>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default VoucherView;
