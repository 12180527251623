import config from '../../../config.json';
const env = process.env.REACT_APP_ENVIRONMENT || 'stage';
const BACKEND_URL = config.api[env];
import axios from 'axios';

import { getToken } from './../../models/auth';

export const NEW_VOUCHER = 'NEW_VOUCHER';

export function newVoucher(data) {
  return axios.post(`${BACKEND_URL}/v2/bo/vouchers`,
    data,
    {
      headers: { Authorization: `Bearer ${getToken()}` }
    }
  );
}

