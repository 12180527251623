import React from "react"
import Checkbox from "./Checkbox";

export default function ({ itemsList = [], selectedItems = [], onChange }) {
  return (
    <div>
      {
        itemsList.map(item => (
          <Checkbox
            key={item.value.toString()}
            onChange={value => {
              let helper = selectedItems;
              if (selectedItems.includes(value.toString())) helper = selectedItems.filter(item => value.toString() !== item.toString());
              else { helper.push(value.toString()) }
              console.log(helper)
              onChange(helper);
            }}
            checked={selectedItems.includes(item.value.toString())}
            text={item.text}
            value={item.value.toString()}
          />
        ))
      }
    </div>
  )
}