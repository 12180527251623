import "./ArtistTop.css";
import moment from "moment";
import React, { Component } from "react";
import { Jumbotron, Col, Row, Image } from "react-bootstrap";

import ArtistBonus from './Modals/ArtistBonus';
import ChangePasswordModal from "./Modals/ChangePasswordModal";
import ArtistGamificationModal from './Modals/ArtistGamificationModal';
import { unBlockLoginFraudArtist } from "../../../actions/artists/unblock_login_fraud_artist";

class Top extends Component {
    state = {
        isChangePasswordOpen: false,
        isArtistBonusOpen: false,
        isGamificationOpen: false,
        handleUnblockLoginFraudArtistLoading: false,
    }

    toggleChangePasswordModal = () => this.setState({ isChangePasswordOpen: !this.state.isChangePasswordOpen });

    toggleGamificationModal = () => {
        const { isGamificationOpen } = this.state;
        this.setState({ isGamificationOpen: !isGamificationOpen });
    }

    showArtistBonus = () => this.setState({ isArtistBonusOpen: true });

    closeArtistBonus = () => this.setState({ isArtistBonusOpen: false });

    handleActive({ active, disabledUntil }) {
        const momentdDisabledUntil = moment(disabledUntil);

        if (active && momentdDisabledUntil.isValid()) {
            return momentdDisabledUntil.isAfter() ? `Usuário desativado até ${momentdDisabledUntil.format('DD/MM/YY')}` : "Usuário ativo";
        }

        return "Usuário inativo";
    }

    handleTakeRate({ takeRate, singuPayment }) {
        const response = (value, expiration = '') => `Artista recebe ${100 - value}% ${expiration}`;

        if (takeRate && moment(takeRate.expiration).isAfter()) {
            return response(takeRate.value, `até o dia ${moment(takeRate.expiration).format('DD/MM/YY')}`)
        }

        return response(singuPayment === true ? 38 : 33);
    }

    handleServices(services, { enabledServices }) {
        if (services && Object.keys(services).length > 0 && enabledServices && enabledServices.length > 0) {
            return enabledServices.map((element, index) => {
                let service = services[element] || `ID: ${element}`;
                return (<p className="service-btn" key={index}>{service}</p>)
            });
        }

        return <p>Sem serviços habilitados</p>;
    }

    handleUnBlockLoginFraudArtist = async () => {
        this.setState({ handleUnblockLoginFraudArtistLoading: true });
        const response = await unBlockLoginFraudArtist(this.props.artist._id);
        if (response.status === 200) {
            window.location.reload();
            this.setState({ handleUnblockLoginFraudArtistLoading: false });
        } else {
            this.setState({ handleUnblockLoginFraudArtistLoading: false });
        }
        this.setState({ handleUnblockLoginFraudArtistLoading: false });
    };

    render() {
        const { artist, services, gamification } = this.props;
        const { isArtistBonusOpen, isGamificationOpen } = this.state;
        const isLoginFraud = artist?.deactivationReason?.toLowerCase() === "fraude de login";

        return (
            <div>
                <Jumbotron className="Top">
                    <Row className="Top-row">
                        <Col md={12}>
                            <div className="btns-container">
                                <span
                                    className="ArtistActions-btn btn btn-info"
                                    title="Desbloquear Artista por Fraude de Login"
                                    onClick={!this.state.handleUnblockLoginFraudArtistLoading ? this.handleUnBlockLoginFraudArtist : () => { }}
                                    style={{ height: 40, width: 54 }}
                                >
                                    {this.state.handleUnblockLoginFraudArtistLoading ?
                                        <span className="spinner glyphicon glyphicon-refresh" /> :
                                        <span className="glyphicon glyphicon-lock" />
                                    }
                                </span>
                                <span className="ArtistActions-btn btn btn-info"
                                    title="Pagar bonus"
                                    onClick={this.showArtistBonus}
                                    style={{ height: 40, width: 54 }}>
                                    <span className="glyphicon glyphicon-usd"></span>
                                </span>
                                <a href={`#/edit-artist/${artist._id}`}
                                    className="ArtistActions-btn btn btn-info"
                                    title="Editar profissional"
                                    style={{ marginLeft: isLoginFraud ? 5 : 0, height: 40, width: 54 }}>
                                    <span className="glyphicon glyphicon-edit"></span>
                                </a>
                                <a href={`#/infraction/artist/${artist._id}`}
                                    className="ArtistActions-btn btn btn-info"
                                    title="Infrações"
                                    style={{ marginLeft: 5, height: 40, width: 54 }}>
                                    <span className="glyphicon glyphicon-warning-sign"></span>
                                </a>
                                <span onClick={this.toggleGamificationModal}
                                    className="ArtistActions-btn btn btn-info"
                                    title="Gamification"
                                    style={{ marginLeft: 5, height: 40, width: 54 }}>
                                    <span className="glyphicon glyphicon-tower"></span>
                                </span>
                            </div>
                            <div className="top-profile">
                                <Image className="profile-img" src={artist.profilePicture} circle />
                                <div className="infos-importantes">
                                    <small><em>{artist.code}</em></small>
                                    <h1 className="top-title">{artist.name}</h1>
                                    <p>{artist.email}</p>
                                    <p>{this.handleActive(artist)}</p>
                                    <p>{this.handleTakeRate(artist)} e {artist.singuPayment ? "adianta recebível" : "não adianta recebível"}</p>
                                    <p>{artist.realtime ? "Tem acesso ao Singu Agora" : "Não tem acesso ao Singu Agora"}</p>
                                </div>
                            </div>
                            <div>
                                {this.handleServices(services, artist)}
                            </div>
                        </Col>
                    </Row>
                </Jumbotron >
                <ArtistBonus
                    artist={artist}
                    open={isArtistBonusOpen}
                    closeArtistBonus={this.closeArtistBonus}
                    payBonus={this.props.payBonus}
                    getIdSchedulesBonus={this.props.getIdSchedulesBonus}
                />
                <ArtistGamificationModal
                    artist={artist}
                    addPointsGamification={this.props.addPointsGamification}
                    isOpen={isGamificationOpen}
                    toggle={this.toggleGamificationModal}
                    gamification={gamification}
                />
                <ChangePasswordModal
                    show={this.state.isChangePasswordOpen}
                    onHide={this.toggleChangePasswordModal}
                    artist={this.props.artist}
                />
            </div>
        );
    }
}

export default Top;