import axios from 'axios';
import config from '../../../config.json';
const env = process.env.REACT_APP_ENVIRONMENT || 'stage';
const BACKEND_URL = config.api[env];

import { getToken } from './../../models/auth';

export const CANCEL_SCHEDULE = 'CANCEL_SCHEDULE';

export function cancelSchedule(scheduleId, cancelReason) {
  const data = {
    scheduleId,
    cancelCode: cancelReason.code
  };

  if (cancelReason.comment) {
    data.comment = cancelReason.comment;
  }
  
  if (cancelReason.cancelReasonCode) {
    data.cancelReasonCode = cancelReason.cancelReasonCode;
  }

  const cancelPromise = axios.put(`${BACKEND_URL}/v1/boUser/schedule/cancel`, data, {
    headers: { Authorization: `Bearer ${getToken()}` }
  });

  return {
    type: CANCEL_SCHEDULE,
    payload: cancelPromise,
    meta: { cancelReason, id: scheduleId }
  };
}


export function getCancelReasons() {
  const cancelPromise = axios.get(`${BACKEND_URL}/v3/bo/config/cancelReason`,
    {
      headers: { Authorization: `Bearer ${getToken()}` }
    }
  );

  return {
    type: 'GET_CANCEL_REASONS',
    payload: cancelPromise
  };
}
