import axios from 'axios';
import config from '../../../config.json';
const env = process.env.REACT_APP_ENVIRONMENT || 'stage';
const BACKEND_URL = config.api[env];

import { getToken } from "../../models/auth"

export default function fetchComments({ type, id }) {
  return axios.get(`${BACKEND_URL}/comments`, {
    headers: { Authorization: `Bearer ${getToken()}` },
    params: { type, id, limit: 200 }
  })
}
