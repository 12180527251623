/* global _ */

import { FETCH_PARTNERS } from './../actions/partners/fetch_partners';
import { UPDATE_PARTNER } from './../actions/partners/update_partner';
import { BAN_PARTNER } from './../actions/partners/ban_partner';
import { SET_CURRENT_PARTNER } from './../actions/partners/set_partner';
import { RESET_CURRENT_PARTNER } from './../actions/partners/reset_partner';

const partner = (state, action) => {
  switch (action.type) {
    case UPDATE_PARTNER:
      if (state._id === action.meta.id) {
        return {
          ...state,
          ...action.meta.data
        };
      }
  }
};

const INITIAL_STATE = {
  all: [],
  current: null
};

const partnersReducer = (state = INITIAL_STATE, action) => {
  if (action.error) {
    return state;
  }
  
  switch (action.type) {
    case FETCH_PARTNERS:
      return {
        ...state,
        all: action.payload.data.partners
      };
    
    case UPDATE_PARTNER:
      return {
        ...state,
        all: _.map(state.all, p => partner(p, action))
      };
    
    case BAN_PARTNER:
      // eslint-disable-next-line no-case-declarations
      const index = _.findIndex(state.all, p => p._id === action.meta.id);
      return {
        ...state,
        all: [
          ...state.all.slice(0, index),
          ...state.all.slice(index + 1)
        ]
      };
    
    case SET_CURRENT_PARTNER:
      return {
        ...state,
        current: action.meta.partner
      };
    
    case RESET_CURRENT_PARTNER:
      return {
        ...state,
        current: null
      };
    
    default:
      return state;
  }
};

export default partnersReducer;
