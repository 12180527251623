import React, { Component } from "react";
import moment from "moment";
import axios from "../../../helper/axios";

export default class ScheduleRating extends Component {
  componentWillReceiveProps(props) {
    this.props = props;
  }
  deleteRating(_id) {
    if (confirm("Deseja mesmo excluir esta avaliação?")) {
      axios.delete(`${BACKEND_URL}/v2/bo/rating/${_id}`)
        .then(_ => {
          this.props.onSuccess();
          setTimeout(() => {
            alert("Avaliação excluída com sucesso.");
          }, 1000);
        })
    }
  }

  renderRatings() {
    const { ratings } = this.props;
    if (ratings.length === 0) {
      return (
        <div className="Modal-section">
          <h3>Não há avaliações para serem exibidas</h3>
        </div>
      );
    }

    return (
      <div>
        {ratings.map(rating => {
          let stars = [];

          let color = "green";
          if (rating.rating <= 3) {
            color = "orange";
          }
          if (rating.rating <= 2) {
            color = "red";
          }
          for (let x = 1; x <= rating.rating; x++) {
            stars.push(<span className="ss-icon ss-star" style={{ color }} />);
          }

          return (
            <div key={rating._id} id={rating._id} className="Modal-section">
              <div className="row">
                <div
                  style={{
                    float: "right",
                    position: "absolute",
                    right: "20px",
                    top: "10px"
                  }}
                >
                  <button
                    className="btn btn-xs"
                    title="Remover Avaliação"
                    onClick={this.deleteRating.bind(this, rating._id)}
                  >
                    <span className="ss-icon ss-trash" />
                  </button>
                </div>
                <div className="col-md-8 col-xs-12">
                  <span>
                    Data da Avaliação:{" "}
                    {moment(rating.date).format("DD/MM/YYYY")}
                  </span>
                </div>
                <div className="col-xs-12 col-md-4">{stars}</div>
                <div className="col-xs-12">
                  <h4>Comentário: </h4>
                  <p>
                    {rating.feedback === ""
                      ? "Nenhum Comentário"
                      : rating.feedback}
                  </p>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    );
  }

  render() {
    return <div>{this.renderRatings()}</div>;
  }
}
