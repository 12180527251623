import React, { Component, PropTypes } from 'react';

class LinkComponent extends Component {
  render() {
    const url = `#/users/${this.props.rowData.id}`;

    return (<a href={url} target="_blank">{this.props.data}</a>);
  }
}

const usersMetadata = [
  {
    columnName: 'id',
    order: 0,
    locked: false,
    visible: true,
    displayName: 'ID'
  },
  {
    columnName: 'createdDate',
    order: 1,
    locked: false,
    visible: true,
    displayName: 'Cadastro'
  },
  {
    columnName: 'name',
    order: 2,
    locked: false,
    visible: true,
    displayName: 'Nome',
    customComponent: LinkComponent
  },
  {
    columnName: 'email',
    order: 3,
    locked: false,
    visible: true,
    displayName: 'E-mail'
  },
  {
    columnName: 'phone',
    order: 4,
    locked: false,
    visible: true,
    displayName: 'Telefone'
  },
  {
    columnName: 'address',
    order: 5,
    locked: false,
    visible: true,
    displayName: 'Endereço'
  },
  {
    columnName: 'gender',
    order: 6,
    locked: false,
    visible: true,
    displayName: 'Sexo'
  },
  {
    columnName: 'numberOfSchedules',
    order: 7,
    locked: false,
    visible: true,
    displayName: 'Agendamentos'
  }
];

export default usersMetadata;
