import React, { Component, PropTypes } from 'react';
import DateTime from 'react-datetime';
import moment from 'moment-timezone';

class EditableDateField extends Component {
  constructor(props) {
    super(props);
    const { currentValue } = this.props;

    this.state = {
      newValue: {
        formatted: currentValue
      },
      currentValue
    };
  }

  doneEditing() {
    const { methods: { saveField, notify } } = this.props;
    const { newValue } = this.state;
    const formattedDate = newValue.date.format();
    const fulfill = action => {
      if (action.error) {
        if (action.payload.data) {
          notify(action.payload.data.errmsg, 'error');
        } else {
          notify(action.payload.data.errmsg, 'error');
        }
      } else {
        this.setState({
          currentValue: newValue.formatted
        }, () => notify('Data alterada com sucesso', 'success'));
      }
    };
    const error = () => {
      notify('Ocorreu um erro inesperado', 'error');
    };

    saveField(formattedDate, fulfill, error);
  }

  editField() {
    const { editField } = this.props.methods;
    const { currentValue } = this.state;
    const splittedDate = currentValue.split('/');
    const day = splittedDate[0];
    const month = splittedDate[1];
    const year = splittedDate[2];

    this.setState({
      newValue: {
        date: moment(`${year}-${month}-${day}`),
        formatted: currentValue
      }
    }, editField);
  }

  cancelEdit() {
    const { cancelEdit } = this.props.methods;
    const { currentValue } = this.state;

    this.setState({
      newValue: {
        formatted: currentValue
      }
    }, cancelEdit);
  }

  onFieldChange(date) {
    const { dateFormat } = this.props;

    this.setState({
      newValue: {
        date,
        formatted: moment(date).format(dateFormat)
      }
    });
  }

  render() {
    const defaultValue = 'Não informado';
    const { newValue, currentValue } = this.state;
    const { type } = this.props;

    let value = defaultValue;

    if (currentValue) {
      if (type === 'dateAndTime') {
        value = moment(currentValue || undefined, this.props.dateFormat).format("D/MM/YYYY HH:mm")
      } else {
        value = moment(currentValue || undefined, this.props.dateFormat).format("DD/MM/YYYY")
      }
    }

    return (
      <div className="EditableField-detail">
        <div className="EditableField-infoWrapper">
          <span className="EditableField-infoDisplay">
            {value}
          </span>
          <span className="EditableField-infoInput">
            <DateTime className="UpdateDate-datepicker"
              onChange={(date) => this.onFieldChange(date)}
              timeFormat={type === 'dateAndTime'}
              locale="pt-br" />
          </span>
        </div>
        <div className="EditableField-buttonsWrapper">
          <span onClick={() => this.editField()}
            className="EditableField-btn EditableField-btn--edit
                           glyphicon glyphicon-edit"></span>
          <span className="EditableField-controls">
            <span onClick={() => this.doneEditing()}
              className="EditableField-btn EditableField-btn--save
                             glyphicon glyphicon-ok"></span>
            <span onClick={() => this.cancelEdit()}
              className="EditableField-btn EditableField-btn--cancel
                             glyphicon glyphicon-remove"></span>
          </span>
        </div>
      </div>
    );
  }
}

EditableDateField.propTypes = {
  methods: PropTypes.object,
  currentValue: PropTypes.string,
  dateFormat: PropTypes.string,
  type: PropTypes.string
};

export default EditableDateField;
