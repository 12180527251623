import React, { Component } from "react";
import { Link } from 'react-router';
import { Row, Col, Button } from 'react-bootstrap'

import Loading from "../Global/Loading/Loading";
import AreaCategories from './AreaCategories';

export default class AreaEdit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeNavItem: "Categorias"
    };
  }

  render() {
    const { area } = this.props || {};

    if (!area._id) return <Loading />

    return (
      <Row>
        <Col md={8}>
          <h2>{`${area.name["pt-BR"]} - ${area.city}/${area.state}`}</h2>
        </Col>
        <Col md={4}>
          <Row>
            <Col xs={4} smOffset={8}>
              <Link to="/areas">
                <Button bsStyle="info" className="button-icon" style={{ display: 'block', marginLeft: 'auto' }}>
                  <span className="glyphicon glyphicon-arrow-left" />
                  Voltar
                </Button>
              </Link>

            </Col>
          </Row>
        </Col>
        <Col md={12}>
          <AreaCategories areaId={area._id} categories={area.categories} />
        </Col>
      </Row>

    )
  }
}
