import index from './app/index';
import config from './config.json';
const env = process.env.REACT_APP_ENVIRONMENT || 'stage';
global.BACKEND_URL = config.api[env];
global.APP_VERSION = process.env.REACT_APP_VERSION;
global.API_ENV = env;
global.LANG = 'pt-BR';
global.ARTIST_API_URL = config.artistApi[env];
global.NODE_API_URL = config.nodeAPI[env];
global.ORDERS_URL = config.orders[env];
global._ = require('underscore');
export default index;
