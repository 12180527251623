import React, { Component } from 'react';
import PageHeader from '../../components/Global/PageHeader';
import Zones from './Zones';


class AdministrativeZone extends Component {
  render() {
    return (
      <div className={"container"}>
        <PageHeader title="Zona Administrativa de Preços Dinâmicos" />
        <div className={"col-lg-12"}>
          <Zones />
        </div>
      </div>
    );
  }
}

export default AdministrativeZone;
