import axios from 'axios';
import config from '../../../config.json';
const env = process.env.REACT_APP_ENVIRONMENT || 'stage';
const BACKEND_URL = config.api[env];
import { getToken } from '../../models/auth';
export const FETCH_VOUCHERS = 'FETCH_VOUCHERS';

export function fetchVouchers(code, showInactive, showOthers, page) {
  const params = { 
    code,
    showInactive,
    showOthers,
    page: page || 1,
  }

  const config = { 
    'Authorization': `Bearer ${getToken()}` 
  }
  return axios.get(`${BACKEND_URL}/v2/bo/vouchers`,
    {
      params: params,
      headers: config
    });
}

export function fetchVouchersUsingUserId(userId) {
  const params = { 
    userId
  }

  const config = { 
    'Authorization': `Bearer ${getToken()}` 
  }
  return axios.get(`${BACKEND_URL}/v2/bo/vouchers`,
    {
      params: params,
      headers: config
    }
  );
}
