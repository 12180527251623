import React from "react"
import LeadActions from './../../components/Leads/LeadActions';

export default {
  column: [
    {
      columnName: 'cpf',
      displayName: 'CPF'
    },
    {
      columnName: 'name',
      displayName: 'Nome'
    },
    {
      columnName: 'email',
      displayName: 'Email'
    },
    {
      columnName: 'phoneNumber',
      displayName: 'Telefone'
    },
    {
      columnName: 'status',
      displayName: 'Status',
      customComponent: (props) => {
        const { rowData } = props;

        return (
          <p>{rowData.status && rowData.status[0] && rowData.status[0].description}</p>
        );
      }
    },
    {
      columnName: 'actions',
      displayName: 'Ações',
      customComponent: LeadActions
    }
  ]
};
