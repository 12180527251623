/* eslint-disable react/no-did-mount-set-state */
import React from 'react';
import ArtistPGPEdit from '../../components/Artists/ArtistPGPEdit';
import Swal from "sweetalert2";
import { savePGP, getPGP } from "../../actions/areas/pgp";

class ArtistPGP extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            pgp: {
                title: '',
                message: '',
                share: '',
                activated: false,
                approved: 0,
                engaged: 0,
                engagementQuantity: 0,
                leadGain: 0
            },
            loading: true
        }
    }

    async componentDidMount() {
        try {
            const { data } = await getPGP();
            this.loadPGP(data);
        } catch (error) {
            console.log(error);
        } finally {
            this.setState({ loading: false });
        }
    }

    loadPGP = data => {
        const pgp = {
            title: data.title || '',
            message: data.message || '',
            share: data.share || '',
            activated: data.activated || '',
            approved: data.approved || 0,
            engaged: data.engaged || 0,
            leadGain: data.leadGain || 0,
            engagementQuantity: data.engagementQuantity || 0
        };
        this.setState({ pgp });
    }

    onChange = (value, field) => {
        const { pgp } = this.state;
        pgp[field] = value;
        this.setState({ pgp });
    }

    onSave = async () => {
        try {
            await savePGP(this.state);
            Swal.fire("Sucesso", "PGP Alterado com sucesso", "success");
        } catch (error) {
            const message = error?.data?.error?.description || "Houve um erro ao atualizar o PGP";
            Swal.fire("Ops..", message, "error");
        }
    }

    render(){
        return (
            <ArtistPGPEdit 
                onChange={this.onChange}
                onSave={this.onSave}
                values={this.state.pgp}
            />
        )
    }
}

export default ArtistPGP;