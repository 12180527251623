import Griddle from "griddle-react";
import React, { Component } from "react";
import { Button, Modal } from "react-bootstrap";
import { Toast } from '../../helper/alerts';
import { updateCategories } from "../../actions/categories/update_categories";

export default class CategoriesGriddle extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
      categories: [],
      rowData: [],
      isAvailableForOrderAllocation: false,
      isUpdating: false,
    };
  }

  handleClose = () => {
    this.setState({ showModal: false, rowData: [], isAvailableForOrderAllocation: false });
  };

  showModal = (rowData) => {
    this.setState({
      showModal: true,
      rowData: rowData,
      isAvailableForOrderAllocation: rowData.isAvailableForOrderAllocation,
    });
  };

  updateCategory(id, params) {
    updateCategories(id, eval(params))
      .then(() => {
        Toast().fire({
          icon: 'success',
          title: 'Categoria atualizada com sucesso!',
        });
        window.location.reload();
        this.setState({ isUpdating: false })
      }).catch(error => {
        console.log(error);
        Toast().fire({
          icon: 'error',
          title: 'Erro ao atualizar categoria!',
        });
      });
  }

  render() {
    return (
      <div className="row">
        <Griddle
          ref="Griddle"
          tableClassName={"GriddleTable table"}
          settingsToggleClassName={"btn btn-default"}
          useExternal={true}
          results={this.props.categories}
          noDataMessage={"Não há dados para serem exibidos"}
          useFixedLayout={false}
          useGriddleStyles={false}
          externalSetPage={1}
          resultsPerPage={30}
          externalMaxPage={1}
          columnMetadata={[
            {
              columnName: "id",
              displayName: "Id",
            },
            {
              columnName: "name",
              displayName: "Nome",
            },
            {
              columnName: "isAvailableForOrderAllocation",
              displayName: "Disponível para a alocação automática",
              customComponent: ({ rowData }) => {
                return (<p>{rowData.isAvailableForOrderAllocation ? 'Sim' : 'Não'}</p>);
              },
            },
            {
              columnName: "actions",
              displayName: "Ações",
              customComponent: ({ rowData }) => {
                return (
                  <Button onClick={() => this.showModal(rowData)}
                    className="btn btn-info"
                    title="Editar Categoria">
                    <span className="glyphicon glyphicon-edit"></span>
                  </Button>
                );
              },
            },
          ]}
          columns={["id", "name", "isAvailableForOrderAllocation", "actions"]}
        />

        <Modal show={this.state.showModal} onHide={this.handleClose} className="Modal Modal--medium">
          <Modal.Header closeButton>
            <Modal.Title className="Modal-title">
              Editar Categoria
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="form-group">
              <div className="row">
                <div className="col-sm-6">
                  <label className="form-label">ID</label>
                  <input
                    type="text"
                    className="Form-input"
                    value={this.state.rowData.id} disabled />
                </div>
                <div className="col-sm-6">
                  <label className="form-label">Nome</label>
                  <input
                    type="text"
                    className="Form-input"
                    value={this.state.rowData.name} disabled />
                </div>
                <div className="col-sm-12 pt-2">
                  <label className="form-label">Disponível para a alocação automática? *</label>
                  <br />
                  <select
                    className="Form-input custom-select"
                    onChange={e => this.setState({ isAvailableForOrderAllocation: e.target.value })}
                    value={this.state.isAvailableForOrderAllocation}
                  >
                    <option value={false} >Não</option>
                    <option value={true} >Sim</option>
                  </select>
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              className="btn btn-danger"
              onClick={this.handleClose}>
              Cancelar
            </Button>
            <Button
              variant="primary"
              className="btn btn-success"
              onClick={() => {
                this.setState({ isUpdating: true }),
                this.updateCategory(this.state.rowData.id, this.state.isAvailableForOrderAllocation)
              }}      
              disabled={this.state.isUpdating}
              >
              {this.state.isUpdating ? "Atualizando" : "Salvar"}
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}
