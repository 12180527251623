import React, { Component } from 'react'

export default class RestrictAccess extends Component {
  render() {
    return (
      <div className="col-sm-offset-2 col-sm-8 alert alert-danger" role="alert">
        <span className="glyphicon glyphicon-exclamation-sign" aria-hidden="true"></span>
        <span className="sr-only">Acesso Negado:</span>
        Você não tem permissão para acessar esta página.
      </div>
    );
  }
}
