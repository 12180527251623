import React, { Component } from "react";
import Griddle from 'griddle-react';

import Loading from '../../../../components/Global/Loading/Loading'
import Metadata from './Metadata';

class PackagePurchasesTable extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    const { 
      data, 
      loading, 
      currentPage, 
      limit, 
      total,
      getNextPage
    } = this.props;

    if(loading) return <Loading/>

    return (
      <Griddle 
        results={data}
        columnMetadata={Metadata}
        resultsPerPage={limit}
        useFixedLayout={false}
        tableClassName={'GriddleTable table'}
        useGriddleStyles={false}
        settingsToggleClassName={'btn btn-default'}
        useExternal
        columns={[
          "_id",
          "client",
          "package",
          "date",
          "payment",
          "actions"
        ]}
        externalSetFilter={() => { }}
        externalSetPageSize={limit}
        externalSetPage={getNextPage}
        externalCurrentPage={currentPage - 1}
        externalMaxPage={Math.ceil(total / limit)}
      />
    );
  }
}

export default PackagePurchasesTable;