import config from '../../../config.json';
const env = process.env.REACT_APP_ENVIRONMENT || 'stage';
const BACKEND_URL = config.api[env];
import React from 'react';
import _ from 'underscore';
import moment from 'moment';

import Griddle from 'griddle-react';
import { Component, PropTypes } from 'react';
import { connect } from 'react-redux';
import UsersMetadata from './Users_metadata';
import formatter from './../../helper/formatters';
import PageHeader from './../../components/Global/PageHeader';
import { getToken } from './../../models/auth';
import Loading from './../../components/Global/Loading/Loading';
import { fetchUsers } from './../../actions/users/fetch_users';

class Users extends Component {
  constructor(props) {
    super(props);

    this.state = {
      currentPage: 1,
      filter: '',
      usersArrived: false,
      error: false,
      users: [],
    };
  }

  componentWillMount() {
    this.getAllUsers();
  }

  componentWillReceiveProps(newProps) {
    const { users } = newProps;
    if (users) {
      this.mapUsers(users);
    }
  }

  getAllUsers() {
    const success = () => this.setState({ usersArrived: true });
    const error = () => this.setState({ error: true });
    this.props.fetchUsers(this.state.currentPage, this.state.filter).then(success, error);
  }

  mapUsers(users) {
    const mappedUsers = _.map(users, user => ({
      id: user._id,
      createdDate: moment(user.createdDate).format('DD/MM/YYYY HH:mm'),
      name: user.name,
      email: user.email,
      phone: formatter.formatPhone(user.phone),
      gender: formatter.formatGender(user.gender),
      numberOfSchedules: user.numberOfSchedules || 0,
    }));

    this.setState({ users: mappedUsers });
  }

  getPage = nextPage => {
    const currentPage = nextPage + 1;

    this.setState({ currentPage }, this.getAllUsers);
  };

  exportCsv() {
    return `${BACKEND_URL}/v2/bo/users-export?token=${getToken()}`;
  }

  _handleChange = e => {
    this.setState({ filter: e.target.value });
  };

  _handleClick = () => {
    this.setState({ currentPage: 1 }, this.getAllUsers);
  };

  render() {
    const { users, usersArrived } = this.state;

    if (!usersArrived) {
      return (
        <div>
          <PageHeader title="Clientes" />
          <Loading />
        </div>
      );
    }

    return (
      <div>
        <PageHeader title="Clientes" /> 
        <br />
        <b style={{ marginLeft: 10, marginRight: 10 }}>Filtrar:</b>{' '}
        <input
          value={this.state.filter}
          onChange={this._handleChange}
          type="text"
          width="300px"
          className="form-control"
          placeholder="shortcode, id, status, artista ..."
        />
        <button className="btn btn-info" style={{ marginLeft: 10 }} onClick={this._handleClick}>
          BUSCAR
        </button>
        <div className="row">
          <Griddle
            results={users}
            columnMetadata={UsersMetadata}
            resultsPerPage={5}
            useFixedLayout={false}
            tableClassName={'GriddleTable table'}
            useGriddleStyles={false}
            settingsToggleClassName={'btn btn-default'}
            useExternal
            externalSetFilter={() => {}}
            externalSetPageSize={15}
            externalSetPage={this.getPage}
            externalCurrentPage={this.state.currentPage - 1}
            externalMaxPage={Math.ceil(this.props.totalItems / this.props.itemsPerPage)}
          />
        </div>
      </div>
    );
  }
}

Users.propTypes = {
  users: PropTypes.array,
  itemsPerPage: PropTypes.number,
  totalItems: PropTypes.number,
  fetchUsers: PropTypes.func,
};

function mapStateToProps(state) {
  return {
    users: state.users.all,
    totalItems: state.users.totalItems,
    itemsPerPage: state.users.itemsPerPage,
  };
}

export default connect(mapStateToProps, { fetchUsers })(Users);
