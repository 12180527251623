import React, { PropTypes } from 'react';
import { Button } from 'react-bootstrap';
import handleRequest from './../../../helper/requests';

// styles
import './BanPartner.css';

const BanPartner = props => {
  const { updateCallback, cancelCallback, partner, notify } = props;
  const { id, name } = partner;
  const actionCreator = () => updateCallback(id);
  const update = () => handleRequest(actionCreator, notify, 'Artista excluído com sucesso');

  return (
    <div className="BanPartner">
      <div className="BanPartner-message">Você tem certeza que deseja excluir<br />o artista <strong>{name}</strong>?</div>
      <div className="BanPartner-btnWrapper">
        <Button className="BanPartner-btn btn btn-info" onClick={update}>Sim</Button>
        <Button className="BanPartner-btn btn btn-danger" onClick={cancelCallback}>Cancelar</Button>
      </div>
    </div>
  );
};

BanPartner.propTypes = {
  updateCallback: PropTypes.func,
  cancelCallback: PropTypes.func,
  partner: PropTypes.object
};

export default BanPartner;
