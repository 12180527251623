import config from '../../../config.json';
const env = process.env.REACT_APP_ENVIRONMENT || 'stage';
const NODE_API_URL = config.nodeAPI[env];
import axios from 'axios';

import { getToken } from './../../models/auth';

export const USER_PASSWORD_RESET = 'USER_PASSWORD_RESET';

export function resetUserPassword(email) {
  const resetPromise = axios.post(`${NODE_API_URL}/v1/user/forgotPassword`, {
    headers: { Authorization: `Bearer ${getToken()}` },
    email
  });

  return {
    type: USER_PASSWORD_RESET,
    payload: resetPromise
  };
}
