import Swal from "sweetalert2";

export const Toast = (timer = 1500) => Swal.mixin({
  toast: true,
  position: 'top-end',
  showConfirmButton: false,
  timer: timer,
  timerProgressBar: true,
  // didOpen: (toast) => {
  //   toast.addEventListener('mouseenter', Swal.stopTimer)
  //   toast.addEventListener('mouseleave', Swal.resumeTimer)
  // }
});

export const LoadingAlert = (title) => {
  Swal.fire({
    title,
    allowOutsideClick: false
  });
  Swal.showLoading();
}