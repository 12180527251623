const moment = require('moment-timezone');

export const weekDaysAbbreviatonList = [
  'Dom',
  'Seg',
  'Ter',
  'Qua',
  'Qui',
  'Sex',
  'Sab'
];

export const weekDaysValueList = [
  { value: 1, text: 'Segunda' },
  { value: 2, text: 'Terça' },
  { value: 3, text: 'Quarta' },
  { value: 4, text: 'Quinta' },
  { value: 5, text: 'Sexta' },
  { value: 6, text: 'Sábado' }
];

export const daysToString = {
  0: 'Domingo',
  1: 'Segunda',
  2: 'Terça',
  3: 'Quarta',
  4: 'Quinta',
  5: 'Sexta',
  6: 'Sabado'
};

export const experienceTimeList = [
  { text: 'Menos de 6 meses', value: 'Menos de 6 meses' },
  { text: 'Entre 6 e 11 meses', value: 'Entre 6 e 11 meses' },
  { text: '1 ano', value: '1 ano' },
  { text: '2 anos', value: '2 anos' },
  { text: '3 anos', value: '3 anos' },
  { text: '4 anos', value: '4 anos' },
  { text: '5 anos', value: '5 anos' },
  { text: 'Mais de 5 anos', value: 'Mais de 5 anos' },
];

export const areasList = [
  { text: 'São Paulo', value: '56b106cb2a3c15e79c87bf4e' },
  { text: 'Rio de Janeiro', value: '56b108332a3c15e79c87bf4f' },
  { text: 'Brasília', value: '5efe32b9882c13205fdc3773' },
  { text: 'Distrito Federal', value: '5efe32b9882c13205fdc3773' },
  { text: 'Belo Horizonte', value: '5f5817f40a24df8509a03843' },
  { text: 'Niterói', value: '5f5817110a24df8509a03842' },
  { text: 'Campinas', value: '601dac754008f43fd49d14bc' },
  { text: 'Nova Lima', value: '5f5818090a24df8509a03844' }
];

export const categoriesList = [
  { text: 'Manicure', value: 'c100' },
  { text: 'Massoterapeuta', value: 'c101' },
  { text: 'Escovista', value: 'c102' },
  { text: 'Depiladora', value: 'c104' },
  { text: 'Depiladora masculina', value: 'c104m'},
  { text: 'Maquiadora', value: 'c110' },
  { text: 'Esteticista', value: 'c108' }
];

export const leadStatusList = [
  { value: 1, text: "Não agendado" },
  { value: 2, text: "Agendado" },
  { value: 3, text: "Desistiu de entrar na Singu" },
  { value: 4, text: "Não pode comparecer ao teste" },
  { value: 5, text: "Lead em Standby" },
  { value: 6, text: "Reprovada" },
  { value: 7, text: "Desligada da Singu" },
  { value: 11, text: "Transformado em artista" }
];

export const leadReproveReasonsList = [
  { value: 'pontualidade', label: 'Pontualidade' },
  { value: 'postura', label: 'Postura' },
  { value: 'material', label: 'Material' },
  { value: 'qualidade', label: 'Qualidade na execução' },
  { value: 'tempo', label: 'Tempo na execução' },
  { value: 'formacao', label: 'Formação na área' },
  { value: 'experiencia', label: 'Experiência mínima' },
  { value: 'documentacao', label: 'Documentação' }
]

export const scheduleTypes = [
  { value: 1, text: 'Presencial' },
  { value: 2, text: 'Artista recrutadora' },
  { value: 3, text: 'Online' },
  { value: 4, text: 'Salão' },
];

export const leadTechniques = [
  { value: 'Manicure', text: 'Manicure' },
  { value: 'Massoterapeuta', text: 'Massoterapeuta' },
  { value: 'Escovista', text: 'Escovista' },
  { value: 'Depiladora', text: 'Depiladora' },
  { text: 'Esteticista', value: 'Limpeza de Pele' },
  { text: 'Limpeza de Pele', value: 'Limpeza de Pele' },
  { text: 'Maquiadora', value: 'Maquiadora' },
];

export const SCHEDULE_TYPES_ENUM = {
  PRESENCIAL: 1,
  ARTISTA_RECRUTADORA: 2,
  ONLINE: 3,
  SALAO: 4
};

export const LEAD_STATUS_ENUM = {
  NAO_AGENDADO: 1,
  AGENDADO: 2,
  DESISTIU: 3,
  NAO_COMPARECEU: 4,
  STANDBY: 5,
  REPROVADO: 6,
  DESLIGADO: 7,
  APROVADO: 11
};

export const formatLeadScheduleDates = (leadScheduleDates) => {
  if (!leadScheduleDates) return [];

  return leadScheduleDates.map(leadScheduleDate => {
    const days = leadScheduleDate.days.sort().map(d => daysToString[d]).join(', ');
    const { start, end } = leadScheduleDate.timeRange;

    let option = `${days} das ${start}h às ${end}h`;
    if (start === end) option = `${days} às ${start}h`;

    const label = leadScheduleDate.type.id === 3 ? `${option} online via WhatsApp` : `${option} no endereço: ${leadScheduleDate.location.formatted_address}`;

    return { ...leadScheduleDate, label }
  })
}

export const getAvailableScheduleOptions = (leadScheduleDate) => {
  const { start, end } = leadScheduleDate.timeRange;

  const diffInDays = moment(leadScheduleDate.end).tz('America/Sao_Paulo').diff(moment().tz('America/Sao_Paulo'), 'days');

  const limit = diffInDays > 14 && leadScheduleDate.days.length >= 2 ? 14 : diffInDays > 28 ? 28 : diffInDays;
  const splitedStart = parseInt(start.split(':')[0]);
  const splitedEnd = parseInt(end.split(':')[0]);

  leadScheduleDate.days = leadScheduleDate.days.sort();

  const hours = [];

  if (splitedStart === splitedEnd) {
    hours.push(start < 10 ? `0${start}` : start);
  } else {
    for (let hour = splitedStart; hour <= splitedEnd; hour++) {
      hours.push(`${hour < 10 ? `0${hour}` : hour}:${start.split(':')[1]}`);
    }
  }

  const days = [];

  for (let optionIndex = 1; optionIndex <= limit; optionIndex++) {
    const today = moment()
    const date = today
      .add(optionIndex, 'days')
      .hour(splitedStart)
      .minute(start.split(':')[1])
      .second(0)
      .millisecond(0);

    if (!leadScheduleDate.days.includes(date.weekday().toString())) continue;

    days.push(date);
  }

  return { days, hours };
}
