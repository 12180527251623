/* global _ */

import React, { Component, PropTypes } from 'react';

class RadioGroup extends Component {
  componentWillMount() {
    const { inputData } = this.props;

    if (!inputData.autofilled) {
      // Adds initial value as an empty string
      // for validation purposes
      inputData.onChange('');
    }
  }

  onRadioChange(radio) {
    const { disabled, inputData: { onChange } } = this.props;

    if (!disabled) {
      onChange(radio.value);
    }
  }

  isInvalid() {
    const { inputData } = this.props;
    const { value = [], valid, touched } = inputData;

    return (!value.length > 0 && !valid && touched);
  }

  renderErrorMessage() {
    const { error } = this.props.inputData;

    if (this.isInvalid()) {
      return (<span className="Form-error">{error}</span>);
    }

    return null;
  }

  haveSelectedClass() {
    const { value } = this.props.inputData;

    return value.length > 0 ? 'is-selected' : '';
  }

  isCheckedClass(value) {
    const formValue = this.props.inputData.value;

    if (formValue) {
      return formValue === value ? 'is-selected' : '';
    }

    return '';
  }

  onRadioClick() {
    // Forces blur trigger so touched flag
    // will be set to true
    const { disabled, inputData: { onBlur } } = this.props;
    !disabled && onBlur && typeof onBlur === "function" && onBlur();
  }

  wrapperClass() {
    const { disabled, inputData } = this.props;
    const { valid } = inputData;
    const invalidClass = this.isInvalid() ? 'is-error' : '';
    const validClass = valid ? 'is-valid' : '';
    const disabledClass = disabled ? 'is-disabled' : '';

    return `${invalidClass} ${validClass} ${disabledClass}`;
  }

  renderRadios() {
    const { name, list } = this.props;
    const mapRadios = (radio, i) => (
      <div className={`Form-choice ${this.isCheckedClass(radio.value)}`} key={i}>
        <input type="radio"
          className="Form-radio"
          name={name}
          value={radio.value}
          id={`${name}-${i}`}
          onChange={evt => this.onRadioChange(evt.target)}
          onClick={() => this.onRadioClick()}
        />
        <label htmlFor={`${name}-${i}`}
          className="Form-choiceLabel Form-choiceLabel--radio"
          key={i}
        >
          {radio.label}
        </label>
      </div>
    );

    return _.map(list, mapRadios);
  }

  render() {
    const { label, name } = this.props;
    return (
      <div id={`${name}Wrapper`}
        className={`Form-inputWrapper form-group ${this.wrapperClass()}`}
      >
        <div className="Form-labelWrapper">
          <div className="Form-label">
            {label}{this.renderErrorMessage()}
          </div>
        </div>
        <div className={`Form-choiceGroup ${this.haveSelectedClass()}`}>
          {this.renderRadios()}
        </div>
      </div>
    );
  }
}

RadioGroup.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  inputData: PropTypes.object,
  disabled: PropTypes.bool,
  list: PropTypes.array
};

export default RadioGroup;
