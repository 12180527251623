/* global BACKEND_URL */
import React from "react";

import { Component } from 'react';
import PageHeader from '../../components/Global/PageHeader';

class MeetRoom extends Component {
    render() {
        return (
            <div>
                <PageHeader title="Sala de Reunião" />                
                <iframe src="https://calendar.google.com/calendar/embed?src=singu.com.br_am2m8qhaovbkh1umu5cqjdod9c%40group.calendar.google.com&ctz=America%2FSao_Paulo"  width="100%" height="600" frameborder="0" scrolling="no"></iframe>
            </div>  
        );
    };
};

export default MeetRoom;
