import React, { Component } from "react";
import PageHeader from "../../components/Global/PageHeader";
import PolygonMap from "./PolygonMap";

class DynamicPrice extends Component {
  render = () => (
    <div>
      <PageHeader title="Administração dos Polígonos de Atuação" />
      <PolygonMap />
    </div>
  )
}

export default DynamicPrice;
