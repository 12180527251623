import * as yup from 'yup'

const investmentSchema = yup.object({
    value: yup.number().moreThan(1, 'O valor deve ser maior que zero').required('Custo é campo obrigatório!'),
    cac: yup.string().required('CAC é campo obrigatório!'),
    sector: yup.string().required('Setor é campo obrigatório!'),
    description: yup.string().required('Descrição é campo obrigatório!'),
    category: yup.string().required('Categoria é campo obrigatório!'),
    date: yup.string().required('Data de entrada é campo obrigatório!'),
    service: yup.string().required('Serviço é um campo obrigatório!')
})

const validate = async investment => {
    const investmentValidated = await investmentSchema.validate(investment) 
    return investmentValidated
}

export default validate