import { backendAPI } from '../../helper/apis'

export const FETCH_AREAS = 'FETCH_AREAS';

export function fetchAreas() {
  return {
    type: FETCH_AREAS,
    payload: backendAPI.get(`/v2/bo/areas`)
  };
}

export function fetchAreasService() {
  return backendAPI.get(`/v2/bo/areas`);
}