import { Component } from "react";
import CategoriesGriddle from "../../components/Categories/CatagoriesGriddle";
import PageHeader from "../../components/Global/PageHeader";
import { listCategories } from "../../actions/categories/fetch_categories";

class Categories extends Component {
  constructor() {
    super();
    this.state = {
      categories: [],
    }
  }

  componentWillMount() {
    this.getCategories();
  }

  getCategories = () => {
    listCategories().then(({ data }) => {
      this.setState({ categories: data });
    })
  };

  render() {
    return (
      <div>
        <PageHeader title="Categorias" />
        <CategoriesGriddle
          categories={this.state.categories}
        />
      </div>
    );
  }
}
export default Categories;