import axios from 'axios';
import config from '../../../config.json';
const env = process.env.REACT_APP_ENVIRONMENT || 'stage';
const BACKEND_URL = config.api[env];

export const FETCH_CONFIGS = 'FETCH_CONFIGS';

export function fetchConfigs() {
  const configsPromise = axios.get(`${BACKEND_URL}/v2/configs`);

  return {
    type: FETCH_CONFIGS,
    payload: configsPromise
  };
}
