/* global LANG */
import React from "react";

import { Component, PropTypes } from 'react';
import { reduxForm } from 'redux-form';
import SendPushForm from './../../components/Users/SendPushForm';
import Loading from './../../components/Global/Loading/Loading';

import { Toast } from '../../helper/alerts';

// actions
import { fetchAreas } from './../../actions/areas/fetch_areas';
import { fetchConfigs } from './../../actions/configs/fetch_configs';

class SendPush extends Component {
  constructor(props) {
    super(props);

    this.state = {
      cities: null
    };
  }

  componentWillMount() {
    const { fetchAreas } = this.props;

    (async () => {
      try {
        const action = await fetchAreas();

        if (action.error) {
          if (action.payload.data) {
            return Toast().fire({ icon: 'error', title: action.payload.data.error.description })
          } else {
            return Toast().fire({ icon: 'error', title: action.payload.message })
          }
        }
        this.mapAreas(action.payload.data);
      } catch (err) {
        console.error(err);
      }
    })();
  }

  mapAreas(areas) {
    const cities = areas.map(area => ({ label: area.city, value: area._id }))

    this.setState({ dataArrived: true, cities });
  }

  render() {
    const { dataArrived, cities } = this.state;

    if (!dataArrived) {
      return (
        <div>
          <Loading />
        </div>
      );
    }

    const props = {
      cities,
      notify: (msg, lvl) => this.notify(msg, lvl),
      router: this.context.router,
      ...this.props
    };

    return <SendPushForm {...props} />;
  }
}

const formFields = {
  form: 'SendPushForm',
  fields: [
    'pushTitle',
    'pushText',
    'pushLink',
    'pushQuery',
    'enabledCities'
  ]
};

SendPush.propTypes = {
  areas: PropTypes.array,
  fetchAreas: PropTypes.func,
  fetchConfigs: PropTypes.func
};

SendPush.contextTypes = {
  router: PropTypes.object.isRequired
};

function mapStateToProps(state) {
  return {
    areas: state.areas.all
  };
}

const mapActionsToProps = {
  fetchAreas,
  fetchConfigs
};

export default reduxForm(formFields, mapStateToProps, mapActionsToProps)(SendPush);
