"use strict";
import React, { Component } from "react";

export default class Paginate extends Component {

  state = {
    page: 1
  }

  componentWillReceiveProps(newProps) {
    this.setState({
      page: newProps.page || this.state.page
    })
  }

  handlePrevBtn() {
    if (this.state.page > 1) {
      return (
        <div className="griddle-previous">
          <button type="button" onClick={this.handlePrevClick.bind(this)}>
            <span>Previous</span>
          </button>
        </div>
      );
    }
  }

  handleNextBtn() {
    if (this.state.page < this.props.pages) {
      return (
        <div className="griddle-next" >
          <button type="button" onClick={this.handleNextClick.bind(this)}>
            <span>Next</span>
          </button>
        </div>
      );
    }
  }

  handlePrevClick(e) {
    e.preventDefault();
    const prev = this.props.page - 1;
    if (prev >= 1) {
      this.paginate(prev);
    }
  }

  handleNextClick(e) {
    e.preventDefault();
    const next = this.state.page + 1;
    if (next <= this.props.pages) {
      this.paginate(next);
    }
  }

  handleListPages() {
    const pages = [];
    const total = new Array(this.props.pages);

    for (let [i, _] of total.entries()) {
      let page = i + 1;
      pages.push(
        <option key={page} value={page}>{page}</option>
      );
    }

    return pages;
  }

  handlePageChange(e) {
    const selected = +e.target.value;
    this.paginate(selected);
  }

  paginate(page) {
    this.props.paginate(page);
  }

  render() {
    if (this.props.pages > 1) {
      return (
        <div className="griddle-footer">
          <div className="griddle-container">
            {this.handlePrevBtn()}
            <div className="griddle-page">
              <select defaultValue={this.state.page} onChange={this.handlePageChange.bind(this)}>
                {this.handleListPages()}
              </select>
              <span> / </span>
              <span>{this.props.pages}</span>
            </div>
            {this.handleNextBtn()}
          </div>
        </div>
      );
    }

    return (<div />);
  }
}