import React, { Component } from "react";
import { Button } from "react-bootstrap";

import { backendAPI } from '../../helper/apis';
import NewInfractionModal from "./NewInfractionModal";
import ArtistInfractionsGriddle from "./ArtistInfractionsGriddle";
import ScheduleInfractionGriddle from "./ScheduleInfractionGriddle";

export default class Infraction extends Component {
  constructor(props) {
    super(props);
    this.state = {
      artist: {},
      reloadGriddle: false,
      artistId: this.props.params.artistId || null,
      scheduleId: this.props.params.scheduleId || ""
    };
    if (this.props.params.artistId) this.fetchArtistInfo();
  }

  griddlerReloaded() {
    this.setState({
      reloadGriddle: false
    });
  }

  griddleReload() {
    this.setState({
      reloadGriddle: true
    });
  }

  openNewInfractionModal() {
    this.setState({
      modalOpen: true
    });
  }

  closeModal() {
    this.setState({
      modalOpen: false
    });
  }

  loadInfractions() {
    const { artistId, artist, schedule, scheduleId, reloadGriddle } = this.state;

    if (artistId) {
      return (
        <ArtistInfractionsGriddle
          artist={artist}
          scheduleId={scheduleId}
          reload={reloadGriddle}
          reloaded={this.griddlerReloaded.bind(this)}
          professionalId={artistId}
        />
      );
    } else {
      return (
        <ScheduleInfractionGriddle
          reload={reloadGriddle}
          schedule={schedule}
          reloaded={this.griddlerReloaded.bind(this)}
          scheduleId={scheduleId}
        />
      );
    }
  }

  fetchArtistInfo() {
    backendAPI
      .get(`/v2/bo/artist/${this.state.artistId}`)
      .then(response => {
        this.setState({
          artist: response.data || {}
        });
      });
  }

  render() {
    const { artist, schedule, modalOpen } = this.state;

    return (
      <div style={{ position: 'relative', top: -60 }}>
        <NewInfractionModal
          artist={artist}
          schedule={schedule}
          modalOpen={modalOpen}
          closeModal={this.closeModal.bind(this)}
          griddleReload={this.griddleReload.bind(this)}
        />

        <Button
          onClick={this.openNewInfractionModal.bind(this)}
          style={{
            zIndex: 20,
            float: "right",
            right: 15,
            top: 115,
            position: "relative",
            marginBottom: "30px"
          }}
        >
          Nova Infração ou taxa &nbsp;
          <i className="glyphicon glyphicon-plus" />
        </Button>

        <div>{this.loadInfractions()}</div>
      </div>
    );
  }
}
