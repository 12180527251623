import React, { Component } from 'react';
import RadioGroup from '../FormFields/RadioGroup';

export default class PackageVoucher extends Component {
    constructor(props) {
        super(props);

        this.state = {
            valueType: '',
            pack: { ...props.pack, valueType: "V" }
        };
    }

    handleFormEdit(evt) {
        const { value, name } = evt.target;
        const { pack } = this.state;
        pack[name] = value;
        this.setState({ pack });
        if (name === 'originalAmount' || name === 'totalAmount' || name === 'count') {
            pack[name] = parseFloat(pack[name])
        }
    }

    render() {
        const { pack } = this.state;

        const { valueType } = this.state;
        return (
            <div style={{ marginTop: 25, marginLeft: 50 }}>
                <div className="row">
                    <div className="col-xs-2 form-group">
                        <label>Valor do voucher em R$</label>
                        <input
                            className="form-control form-control-normal"
                            name={"originalAmount"}
                            tabIndex={5}
                            value={pack.originalAmount}
                            onChange={this.handleFormEdit.bind(this)}
                        />
                    </div>
                    <div className="col-xs-2 form-group">
                        <label>Quantidade</label>
                        <input
                            className="form-control form-control-normal"
                            type="number"
                            name={"count"}
                            tabIndex={1}
                            value={pack.count || null}
                            onChange={this.handleFormEdit.bind(this)}
                        />
                    </div>
                    <div className="col-xs-2 form-group">
                        <label>Valor do pacote</label>
                        <input
                            className="form-control form-control-normal"
                            name={"totalAmount"}
                            tabIndex={1}
                            value={pack.totalAmount || 0}
                            onChange={this.handleFormEdit.bind(this)}
                        />
                    </div>
                    <div className="col-xs-4 form-group">
                        <label>Porcentagem de desconto para mostrar no app</label>
                        <input
                            className="form-control "
                            name={"discountAmount"}
                            tabIndex={1}
                            value={pack.discountAmount || 0}
                            onChange={this.handleFormEdit.bind(this)}
                        />
                    </div>
                </div>
                <div className="col-xs-3 col-xs-offset-9" style={{ marginTop: 25 }}>
                    <p className="pack-total">
                        <text>R$ {parseFloat(pack.originalAmount * pack.count).toFixed(2) || 0}</text> :
                    </p>
                    <p>
                        Valor a pagar: R${" "}
                        {parseFloat(pack.totalAmount).toFixed(2) || 0}
                    </p>
                </div>
                <div className="clearfix" />
                <hr />
            </div>
        )
    }
}
