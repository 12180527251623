import axios from 'axios';
import config from '../../../config.json';
const env = process.env.REACT_APP_ENVIRONMENT || 'stage';
const BACKEND_URL = config.api[env];

import { getToken } from '../../models/auth';

export function fetchStaffs( page ) {
  const config = { 
    'Authorization': `Bearer ${getToken()}` 
  }
  return axios.get(`${BACKEND_URL}/staff`,
    {
      params: {
        page: page || 1,
        limit : 30,
      },
      headers: config
    });
}