import { backendAPI } from '../../helper/apis';

export const UPDATE_LEAD = 'UPDATE_LEAD';

export function updateLead(leadId, data) {
  const updatePromise = backendAPI.put(`/v2/bo/leads/${leadId}`, data);

  return {
    type: UPDATE_LEAD,
    payload: updatePromise,
    meta: { data, id: leadId }
  };
}
