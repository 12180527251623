import React, { Component, PropTypes } from 'react';
import { Modal, Button } from 'react-bootstrap';
import handleRequest from './../../../helper/requests';

// styles
import './RemoveableField.css';

const FullInfoItem = (props) => {
  return (
    <div className="Modal-row">
      <strong className="Modal-subTitle">{props.title}:&nbsp;</strong>
      <div className="">{props.info}</div>
    </div>
  );
};

class RemoveableField extends Component {
  constructor(props) {
    super(props);

    this.state = {
      modalOpened: false
    };
  }

  onRemoveClick() {
    this.setState({
      modalOpened: true
    });
  }

  closeModal() {
    this.setState({
      modalOpened: false
    });
  }

  remove() {
    const { notify, removeAction } = this.props;
    const requestFinsih = () => {
      this.setState({
        modalOpened: false
      });
    };

    return handleRequest(removeAction, notify, 'Índice removido com sucesso').then(requestFinsih);
  }

  getFullInfo() {
    return _.map(this.props.fullInfo, info => {
      const key = Object.keys(info)[0];
      const detail = info[key];

      return <FullInfoItem key={key} title={key} info={detail} />
    });
  }

  render() {
    return (
      <div className="RemoveableField" onClick={() => this.onRemoveClick()}>
        <span className="RemoveableField-info">{this.props.text}</span>
        <span className="RemoveableField-icon glyphicon glyphicon-remove"></span>
        <Modal className="Modal"
               show={this.state.modalOpened}
               onHide={() => this.closeModal()}>
          <Modal.Header>
            <Modal.Title className="Modal-title">Remover Índice</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="Modal-section">
              {this.getFullInfo()}
              <div className="Modal-row">
                <Button bsStyle="info"
                        className="RemoveableField-btn"
                        onClick={() => this.remove()}>
                        Sim
                </Button>
                <Button bsStyle="danger"
                        className="RemoveableField-btn"
                        onClick={() => this.closeModal()}>
                        Não
                </Button>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}

RemoveableField.propTypes = {
  text: PropTypes.string,
  fullInfo: PropTypes.array,
  key: PropTypes.string,
  removeAction: PropTypes.func,
  notify: PropTypes.func
};

export default RemoveableField;
