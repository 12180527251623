import { Map, GoogleApiWrapper } from "google-maps-react";
import React, { Component, PropTypes } from "react";
import { Polygon } from "google-maps-react/dist/components/Polygon";
import { connect } from "react-redux";
import Swal from "sweetalert2";
import { Label, Button, Alert } from 'react-bootstrap'
import SwitchCheckbox from "../../components/FormFields/SwitchCheckbox";

import { listCategories } from "../../actions/categories/fetch_categories";
import { fetchAreas } from '../../actions/areas/fetch_areas';
import { fetchPolygonsByAreaId } from "../../actions/dynamic_prices/fetch_polygons_by_area";
import { savePolygon } from "../../actions/polygons/save_polygon";
import { updatePolygonAllocationSettings, updatePolygons } from "../../actions/polygons/update_polygons";
import Loading from "../../components/Global/Loading/Loading";

import '../../style/checkbox.css';

const defaultMapCenter = { "lat": -23.6815315, "lng": -46.8754951 };

class PolygonsMap extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedArea: {},
      selectedPolygons: [],
      loadingPolygons: false,
      loadedPolygons: false,
      loadingAreas: true,
      polygons: [],
      areas: [],
      getCategories: [],
      editionMode: false,
      showModal: false,
      isUpdating: false,
      isAvailableCategory: false,
      rowData: [],
      // Creation Form
      submitingForm: false,
      polygonName: '',
      minimumTime: 0,
      categories: [],
      isAvailableForOrderAllocation: false,
      polygonCoordinates: '',
      polygonActive: true,
      showForm: false,
      searchedArea: {},

      mapCenter: {},
      zoom: null
    };

    this.mapRef = null;
  }

  componentDidMount() {
    this.loadAreas();
    this.getCategories();
  }

  getCategories = () => {
    listCategories().then(({ data }) => {
      this.setState({ getCategories: data });
    })
  };

  handleCategoryToggle = (categoryId) => {
    const { categories } = this.state;
    const index = categories.indexOf(categoryId);

    if (index === -1) {
      this.setState({ categories: [...categories, categoryId] });
    } else {
      const updatedCategories = [...categories.slice(0, index), ...categories.slice(index + 1)];
      this.setState({ categories: updatedCategories });
    }
  };

  renderCategories = () => {
    const { getCategories, categories } = this.state;
    return (
      <div className="row">
        <div className="col-xs-12">
          <table className="table">
            <thead>
              <tr>
                <td>Id</td>
                <td>Nome</td>
                <td>Disponível para a alocação</td>
              </tr>
            </thead>
            <tbody>
              {getCategories.map(data => (
                <tr>
                  <td>{data.id}</td>
                  <td>{data.name}</td>
                  <td>
                    <SwitchCheckbox
                      checked={categories.includes(data.id)}
                      onChange={() => this.handleCategoryToggle(data.id)}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    )
  }

  renderPolygonForm = () => {
    const {
      polygonName,
      minimumTime,
      isAvailableForOrderAllocation,
      polygonCoordinates,
      polygonActive,
      submitingForm,
      selectedPolygons,
      showForm
    } = this.state;

    if (!showForm) return;

    const isUpdate = selectedPolygons.length > 0;
    const formTitle = isUpdate > 0 ? 'Atualizar polígono' : 'Adicionar Polígono';
    const formButtonText = isUpdate > 0 ? 'ATUALIZAR' : 'ADICIONAR';

    return (
      <div className="col-xs-12">
        <h4 style={{ margin: 0, fontWeight: 'bold', marginBottom: '5px' }}>{formTitle}</h4>
        {isUpdate &&
          <Label bsStyle="default" style={{ fontSize: '14px', margin: 0, marginRight: '5px', marginBottom: '5px', }}>
            {selectedPolygons[0].name}
          </Label>
        }
        <form className="row" style={{ marginTop: '10px' }} onSubmit={this.handleFormSubmit}>
          <div className="col-xs-12">
            <div className="form-group">
              <label htmlFor="name">Nome do Polígono</label>
              <input
                type="text"
                className="Form-input"
                name="name"
                value={polygonName}
                onChange={(e) => this.setState({ polygonName: e.target.value })}
                id="name"
                placeholder="Ex.: Butantã, Moema"
                required="true" />
            </div>
          </div>
          {!isUpdate && (
            <div className="col-xs-12">
              <div className="form-group">
                <label htmlFor="coordinates">Código das Coordenadas</label>
                <textarea
                  id="coordinates"
                  name="coordinates"
                  className="Form-input"
                  style={{ height: '160px' }}
                  value={polygonCoordinates}
                  onChange={(e) => {
                    const { polygons } = this.state;
                    const polygon = JSON.parse(e.target.value);
                    let mapCenter = {}
                    if (polygon && polygon.features && polygon.features[0] && polygon.features[0].geometry && polygon.features[0].geometry.coordinates) {

                      const polygonPath = polygon.features[0].geometry.coordinates[0].map(polygonLatLng => {
                        return {
                          lat: Number(polygonLatLng[1]),
                          lng: Number(polygonLatLng[0])
                        };
                      }).flat()
                      const lowX = polygonPath[0].lat;
                      const highX = polygonPath[polygonPath.length - 1];
                      const lowy = polygonPath[0].lng;
                      const highy = polygonPath[polygonPath.length - 1].lng;

                      const centerX = lowX + ((highX - lowX) / 2);
                      const centerY = lowy + ((highy - lowy) / 2);

                      Object.assign(mapCenter, { location: { lat: centerX, lng: centerY }, zoom: 9 })
                      polygons.push(
                        <Polygon
                          key={`newOne${new Date()}`}
                          coordinates={polygon.features[0].geometry}
                          paths={polygonPath}
                          fillColor={"#00ff00"}
                          fillOpacity={0.55}
                          strokeColor={"#2b2b2b"}
                          strokeOpacity={0.8}
                          strokeWeight={1}
                          onMouseover={this.handleOnMouseoverPolygon.bind(this)}
                          onMouseout={this.handleOnMouseoutPolygon.bind(this)}
                        >
                        </Polygon>
                      )
                    }
                    this.setState({ polygonCoordinates: e.target.value, polygons, mapCenter })
                  }}
                  placeholder="Insira o código JSON das coordenadas"
                  required={true} />
              </div>
            </div>
          )}
          <div className="col-xs-12">
            <div className="form-group">
              <label htmlFor="active" style={{ display: 'block' }}>Ativar polígono?</label>
              <label htmlFor="active" className="custom-checkbox checkbox-bounce">
                <input
                  type="checkbox"
                  id="active"
                  checked={polygonActive}
                  onChange={() => this.setState({ polygonActive: !polygonActive })}
                />
                <svg viewBox="0 0 21 21">
                  <polyline points="5 10.75 8.5 14.25 16 6"></polyline>
                </svg>
              </label>
            </div>
            {isUpdate && (
              <div className="form-group">
                <label htmlFor="isAvailableForOrderAllocation" style={{ display: 'block' }}>Ativar novo fluxo de alocação?</label>
                <label htmlFor="isAvailableForOrderAllocation" className="custom-checkbox checkbox-bounce">
                  <input
                    type="checkbox"
                    id="isAvailableForOrderAllocation"
                    checked={isAvailableForOrderAllocation}
                    onChange={() => this.setState({ isAvailableForOrderAllocation: !isAvailableForOrderAllocation })}
                  />
                  <svg viewBox="0 0 21 21">
                    <polyline points="5 10.75 8.5 14.25 16 6"></polyline>
                  </svg>
                </label>
              </div>
            )}
            {isUpdate && isAvailableForOrderAllocation && (
              <div>
                <div className="form-group">
                  <label htmlFor="minimumTime">Tempo mínimo entre os pedidos no Polígono</label>
                  <div className="row">
                    <div className="col-xs-8">
                      <input
                        type="range"
                        min="0"
                        max="180"
                        onChange={(e) => this.setState({ minimumTime: e.target.value })}
                        className="Form-input"
                        value={minimumTime ? minimumTime : 0}
                        id="minimumTime"
                        name=""
                        placeholder="Ex: 20"
                        required="true"
                      />
                    </div>
                    <div className="col-xs-4">
                      <p><output id="value">{minimumTime ? minimumTime : 0} minutos</output></p>
                    </div>
                  </div>
                </div>
                <div className="form-group">
                  <label htmlFor="service-categories">Categorias dos serviços</label>
                  <div
                    style={{ marginTop: "16px" }}
                    className="panel-group"
                    id="accordion"
                    role="tablist"
                    aria-multiselectable="true"
                  >
                    <div className="panel panel-default" style={{ minHeight: '40px' }}>
                      <div className="panel-heading" role="tab" id="categories" style={{ background: '#fff' }}>
                        <h4 className="panel-title">
                          <a
                            role="button"
                            data-toggle="collapse"
                            data-parent="#accordion"
                            href="#collapseOne"
                            aria-expanded="true"
                            aria-controls="collapseOne"
                            style={{ textDecoration: 'none' }}
                          >
                            Categorias
                          </a>
                        </h4>
                      </div>
                      <div id="collapseOne" className="panel-collapse collapse in" role="tabpanel" aria-labelledby="categories">
                        <div className="panel-body" style={{ height: 'auto' }}>
                          {this.renderCategories()}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className="col-xs-12">
            <Button type="submit" bsStyle="info">
              {submitingForm ? 'Salvando...' : formButtonText}
            </Button>
          </div>
        </form>
      </div>
    )
  };

  loadAreas = async () => {
    try {
      const { fetchAreas } = this.props;
      const { payload } = await fetchAreas();

      const areas = payload.data

      this.setState({ areas });
    } catch (err) {
      console.error('Load Areas', err);
    } finally {
      this.setState({ loadingAreas: false })
    }
  }

  loadPolygons = async () => {
    try {
      const { selectedArea } = this.state;
      const { fetchPolygonsByAreaId } = this.props;

      if (!selectedArea._id) {
        return Swal.fire({
          icon: "info",
          text: "Por favor, selecione uma área."
        });
      }

      this.setState({
        loadingPolygons: true,
        loadedPolygons: false,
        editionMode: false,
        selectedPolygons: [],
        polygonName: '',
        minimumTime: 0,
        categories: [],
        isAvailableForOrderAllocation: false,
        polygonCoordinates: '',
        polygonActive: false,
        searchedArea: selectedArea
      });

      const { payload } = await fetchPolygonsByAreaId(selectedArea._id);
      const serverPolygons = payload.data;

      const polygons = serverPolygons.map((poly, index) => {
        let polygonPoints;
        if (poly.location.coordinates) {
          polygonPoints = poly.location.coordinates[0].map(polygonLatLng => {
            return {
              lat: Number(polygonLatLng[1]),
              lng: Number(polygonLatLng[0])
            };
          });
        }
        if (poly.location.geometries) {
          const geometriesArray = poly.location.geometries.map(allGeometries => {
            return allGeometries.coordinates[0].map(polygonLatLng => {
              return {
                lat: Number(polygonLatLng[1]),
                lng: Number(polygonLatLng[0])
              };
            });
          });
          polygonPoints = geometriesArray.flat();
        }

        const polyColor = poly.active ? '#00ff22' : '#ff0000';
        return (
          <Polygon
            key={poly._id}
            coordinates={JSON.stringify(poly.location)}
            active={poly.active}
            rule={poly.rule}
            paths={polygonPoints}
            name={poly.name}
            _id={poly._id}
            serviceId={poly.serviceId}
            minimumTime={poly?.parametersFilterOrders?.minimumTime || 0}
            isAvailableForOrderAllocation={poly?.parametersFilterOrders?.isAvailable || false}
            categories={poly?.parametersFilterOrders?.categories || []}
            fillColor={polyColor}
            fillOpacity={0.55}
            strokeColor={"#2b2b2b"}
            strokeOpacity={0.8}
            strokeWeight={1}
            onClick={this.handleOnClickPolygon.bind(this)}
            onMouseover={this.handleOnMouseoverPolygon.bind(this)}
            onMouseout={this.handleOnMouseoutPolygon.bind(this)}
          >
            <h1>{poly.name}</h1>
          </Polygon>
        );
      });

      this.setState({
        loadedPolygons: true,
        polygons,
      });
    } catch (err) {
      console.error('Load Polygons', err);
      Swal.fire({
        icon: "error",
        title: "Ops...",
        text: "Houve um erro ao buscar os polígonos.\nPor favor, tente novamente."
      });
    } finally {
      this.setState({ loadingPolygons: false });
    }
  };

  handleSelectArea = event => {
    const { areas } = this.state;
    const { value: selectedAreaID } = event.target

    const area = areas.find(area => area._id === selectedAreaID);

    if (!area) return;

    this.setState({ selectedArea: area });
  };

  handleToggleEditionMode = event => {
    const { editionMode } = this.state;
    this.setState({ editionMode: !editionMode });
  }

  handleFormSubmit = async (event) => {
    try {
      event.preventDefault();
      this.setState({ submitingForm: true });
      const {
        polygonName,
        minimumTime,
        isAvailableForOrderAllocation,
        categories,
        polygonCoordinates,
        polygonActive,
        selectedArea,
        selectedPolygons
      } = this.state;

      if (selectedPolygons.length > 0) {
        const data = {
          name: polygonName,
          active: polygonActive,
          polygonsIds: selectedPolygons.map(sp => sp._id)
        };

        const allocationData = {
          parametersFilterOrders: {
            minimumTime: minimumTime ? parseInt(minimumTime) : 0,
            isAvailable: isAvailableForOrderAllocation,
            categories: categories
          }
        }

        if (allocationData.minimumTime === 0) {
          await Swal.fire({
            icon: 'error',
            text: 'Tempo mínimo entre os pedidos no Polígono precisa ser maior que 0.'
          });
          return;
        } else {
          await updatePolygonAllocationSettings(allocationData, selectedPolygons.map(sp => sp._id)).catch(error => {
            console.error(error.response.data.message);
            Swal.fire({
              icon: "error",
              title: "Opss..",
              text: error.response.data.message
            });
          });
        }

        await updatePolygons(data);

        await Swal.fire({
          icon: 'success',
          text: 'Polígono atualizado com sucesso.'
        });
      } else {
        const polyCoordsObject = JSON.parse(polygonCoordinates);

        const location = polyCoordsObject.features[0].geometry;

        const data = {
          areaId: selectedArea._id,
          name: polygonName,
          minimumTime: minimumTime,
          isAvailableForOrderAllocation: isAvailableForOrderAllocation,
          categories: categories,
          location,
          active: polygonActive,
        };

        await savePolygon(data);

        await Swal.fire({
          icon: 'success',
          text: 'Polígono criado com sucesso.'
        });
      }

      this.loadPolygons();
    } catch (err) {
      console.error('Form submit', err);
    } finally {
      this.setState({ submitingForm: false });
    }
  }

  handleOnClickPolygon = (props, poly, e) => {
    const { selectedPolygons } = this.state;
    this.setState({ showForm: true, minimumTime: 0 });

    const polyColor = poly.active ? '#00ff22' : '#ff0000'

    if (selectedPolygons.length > 0) {
      const currentSelectedPolygon = selectedPolygons[0];

      if (poly._id === currentSelectedPolygon._id) {
        poly.setOptions({ fillColor: polyColor });
        return this.setState({
          selectedPolygons: [],
          polygonName: '',
          minimumTime: 0,
          isAvailableForOrderAllocation: false,
          categories: [],
          polygonActive: false
        });
      }

      currentSelectedPolygon.setOptions({
        fillColor: currentSelectedPolygon.active ? '#00ff22' : '#ff0000'
      });
      poly.setOptions({ fillColor: '#ae00ff' });
      return this.setState({
        selectedPolygons: [poly],
        polygonName: poly.name,
        minimumTime: poly.minimumTime,
        isAvailableForOrderAllocation: poly.isAvailableForOrderAllocation,
        categories: poly.categories,
        polygonActive: poly.active
      });
    }

    poly.setOptions({ fillColor: '#ae00ff' });
    this.setState({
      selectedPolygons: [poly],
      polygonName: poly.name,
      minimumTime: poly.minimumTime,
      isAvailableForOrderAllocation: poly.isAvailableForOrderAllocation,
      categories: poly.categories,
      polygonActive: poly.active
    });
  }

  handleOnMouseoverPolygon = (props, poly, e) => {
    const { selectedPolygons } = this.state;

    if (selectedPolygons.length > 0) return;

    const polyColor = poly.active ? '#00c91b' : '#b80000'

    poly.setOptions({ fillColor: polyColor });
  }

  handleOnMouseoutPolygon = (props, poly, e) => {
    const { selectedPolygons } = this.state;

    if (selectedPolygons.length > 0) return;

    const polyColor = poly.active ? '#00ff22' : '#ff0000'

    poly.setOptions({ fillColor: polyColor });
  }

  renderMapArea() {
    console.log("rendering")
    const {
      polygons,
      loadingPolygons,
      searchedArea,
      loadedPolygons,
      mapCenter,
      zoom
    } = this.state;

    if (loadingPolygons) return <Loading />;

    return (
      <div className="col-xs-12" style={{ marginTop: '20px', marginBottom: '30px' }}>
        <div className="row">
          <div className="col-md-6" style={{ height: '600px' }}>
            <Map
              // ref={ref => this.mapRef = ref}
              google={this.props.google}
              style={{ width: '100%', height: '100%' }}
              initialCenter={mapCenter && mapCenter.location || searchedArea && searchedArea.location || defaultMapCenter}
              zoom={zoom || 11}
            >
              {polygons.length > 0 && polygons || null}
            </Map>
          </div>
          <div className="col-md-6">
            <div className="col-xs-12">
              <div className="row">
                {loadedPolygons ? (
                  <div className="col-xs-12" style={{ display: 'flex', alignItems: 'center' }}>
                    <h3 style={{
                      fontWeight: 'bold',
                      marginTop: 0,
                      marginBottom: '5px',
                      marginRight: '5px'
                    }}>{searchedArea.name["pt-BR"]}</h3>
                    <button
                      style={{ marginLeft: "10px" }}
                      className="btn btn-default btn-primary"
                      onClick={() => {
                        this.setState({
                          selectedPolygons: [],
                          polygonName: "",
                          showForm: true
                        })
                        window.open("http://geojson.io", "_blank")
                      }}
                    >
                      Novo Polígono
                    </button>
                  </div>
                ) : (
                  <div className="col-xs-9">
                    <Alert bsStyle="info">
                      Busque por alguma <strong>área</strong>!
                    </Alert>
                  </div>
                )}
                {loadedPolygons && this.renderPolygonForm()}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  render() {
    const { loadingAreas, areas } = this.state;

    if (loadingAreas) return <Loading />

    return (
      <div className="row">
        <div className="col-xs-12">
          <h1>Polígonos</h1>
        </div>
        <div className="col-md-12 col-lg-10">
          <div className="row">
            <div className="col-md-3">
              <h4>Selecione uma área</h4>
              <select
                style={{ width: "100%" }}
                className="form-control"
                name=""
                id=""
                onChange={this.handleSelectArea}
              >
                <option disabled={true} selected value="">Selecione a cidade</option>
                {
                  areas.map(area => {
                    if (area.active) return <option key={area._id} value={area._id}>{area.city}</option>
                  })
                }
              </select>
            </div>
            <div className="col-md-3">
              <h4>&nbsp;</h4>
              <button className="btn btn-success" onClick={this.loadPolygons}>
                Buscar
              </button>
            </div>
          </div>
        </div>
        {this.renderMapArea()}
      </div>
    );
  }
}

PolygonsMap.propTypes = {
  polygon: PropTypes.array
};

PolygonsMap.contextTypes = {
  router: PropTypes.object.isRequired
};

function mapStateToProps(state) {
  return {
    polygons: state.all
  };
}

export default connect(mapStateToProps, {
  fetchAreas,
  fetchPolygonsByAreaId
})(
  GoogleApiWrapper({
    apiKey: "AIzaSyBT3vNYVkejeaHoro4DikXez6z7GACBBak",
  })(PolygonsMap)
);
