import config from '../../../config.json';
const env = process.env.REACT_APP_ENVIRONMENT || 'stage';
const BACKEND_URL = config.api[env];
import axios from 'axios';

import { getToken } from './../../models/auth';

export const REMOVE_SCHEDULE_PARTNER = 'REMOVE_SCHEDULE_PARTNER';

export function removeSchedulePartner(scheduleId) {
  const removePartnerPromise = axios.put(`${BACKEND_URL}/v2/boUser/scheduleDeletePartner`,
    { scheduleId },
    {
      headers: { Authorization: `Bearer ${getToken()}` }
    }
  );

  return {
    type: REMOVE_SCHEDULE_PARTNER,
    payload: removePartnerPromise,
    meta: { id: scheduleId }
  };
}
