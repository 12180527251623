import React, { Component, PropTypes } from 'react';

class DateField extends Component {
  constructor(props) {
    super(props);

    this.state = {
      date: new Date().toISOString()
    };
  }

  onBlur = () => {
    const { onBlur } = this.props.inputData;
    onBlur && onBlur();
  }

  onChange = e => {
    this.props.inputData.onChange(e.target.value)
  }

  render() {
    const { name, label, inputData, disabled, placeholder } = this.props;
    const inputProps = { placeholder, className: 'Form-input' };

    return (
      <div id={`${name}Wrapper`}
        className={`Form-inputWrapper form-group`}
      >
        {
          !!label && (
          <div className="Form-labelWrapper">
            <label className="Form-label" htmlFor={name}>
              {label}
            </label>
            <span className={`Form-fieldSpinner ss-icon ss-loading`}></span>
          </div>
          )
        }
        
        <input 
          { ...inputProps }
          type="date" 
          name={name}
          disabled={disabled}
          value={inputData.value}
          onChange={this.onChange}
          onBlur={this.onBlur}
        />
      </div>
    );
  }
}

DateField.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  inputData: PropTypes.object,
  disabled: PropTypes.bool,
};

export default DateField;
