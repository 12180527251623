/* eslint-disable no-unused-vars */
import React, { Component } from "react";
// import { saveArea } from "../../actions/areas/save_areas";
import { getArea } from "../../actions/areas/get_area";
import errorHandler from "../../helper/errorHandler";
import AreaFormComponent from "../../components/Areas/AreaEdit";

export default class AreaFormContainer extends Component {

  constructor(props) {
    super(props);
    this.state = {
      areaId: props && props.params && props.params.areaId || null,
      area: {}
    }
  }

  componentDidMount() {
    this.getArea();
  }

  getArea = () => {
    const { areaId } = this.state;
    if (areaId) {
      getArea(areaId).then(result => {
        console.log(result.data)
        this.setState({ area: result.data });
      }).catch(err => errorHandler(err, "Não foi possível recuperar a cidade selecionada para edição"))
    }
  }

  save = () => {
    const { area, areaId } = this.state;
    saveArea(area, areaId).then(res => {

    }).catch(err => errorHandler);
  }


  render() {
    return (
      <AreaFormComponent
        area={this.state.area || {}}
      />
    )
  }
}