import { nodeAPI } from "../../helper/apis";
import { getToken } from "../../models/auth";

export function newSchedule(schedule) {
  let route = "/v2/schedule";
  if (schedule.realtime) route = "/order/realtime"
  return nodeAPI.post(route, schedule, {
    headers: { "app-system": true, Authorization: `Bearer ${getToken()}` },
  });
}

export function getServerTime() {
  return nodeAPI.get('/v2/area_time/sp');
}
