/* global BACKEND_URL */
import React from "react";

import { Component } from 'react';
import { Col, Row } from "react-bootstrap";
import { Right } from "react-bootstrap/lib/Media";
import PageHeader from '../../components/Global/PageHeader';
import { fetchBalance } from "./../../actions/moip/fetch_balance";
import { reprocessTransfers, reprocessBonus } from "./../../actions/transfers/reprocess_transfers";

class Payments extends Component {
    state = {
        current: 0,
        future: 0,
        unavailable: 0
    }

    componentWillMount() {
        fetchBalance().then(res => {
            const data = res.data;
            if (data.length > 0) {
                let balance = data[0];
                this.setState({
                    current: balance.current / 100,
                    future: balance.future / 100,
                    unavailable: balance.unavailable / 100,
                })
            }
        }).catch(error => {
            console.log(error);
            alert('Não foi possível recuperar o salvo MOIP');
        })
    };

    reprocessArtistTransfers = (fourteenDays) => {
        reprocessTransfers(fourteenDays).then(() => {
            alert('Reprocessando pagamentos');
        }).catch(error => {
            console.log(error);
            alert('Erro ao solicitar o reprocessamento dos pagamentos');
        })
    };

    reprocessArtistsBonus = () => {
        reprocessBonus().then(() => {
            alert('Reprocessando pagamentos');
        }).catch(error => {
            console.log(error);
            alert('Erro ao solicitar o reprocessamento dos pagamentos');
        })
    };

    render() {
        return (
            <div>
                <PageHeader title="Pagamentos" />

                <Row>
                    <div className="col-xs-12">
                        <b> Saldo disponível: </b> R$ {this.state.current}
                        <b> ---- Saldo a receber: </b> R$ {this.state.future}
                        <b> ---- Saldo bloqueado: </b> R$ {this.state.unavailable}
                    </div>
                </Row>
                <br />
                <Row>
                    <button className="btn btn-info" onClick={() => this.reprocessArtistTransfers()}>Reprocessar pagamentos de adiantamento de recebiveis</button>
                </Row>
                <br />
                <Row>
                    <button className="btn btn-info" onClick={() => this.reprocessArtistTransfers(true)} >Reprocessar pagamentos de 14 dias</button>
                </Row>
                <br />
                <Row>
                    <button className="btn btn-info" onClick={this.reprocessArtistsBonus} >Reprocessar bonus</button>
                </Row>
            </div >
        );
    };
};

export default Payments;
