import React from 'react';
import { Modal } from "react-bootstrap";
import { Map, GoogleApiWrapper, Polygon } from 'google-maps-react';

class ZoneMapModal extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      fetchPolygons: false,
      zone: null,
      mapCenter: {
        "lat": -23.5276,
        "lng": -46.6473
      },
      polygons: []
    };
  }

  componentWillReceiveProps(newProps) {
    this.props = newProps;
  }

  renderPolygons() {
    if (!this.props.zone) return;
    let mapCenter = {};
    let polygons = [];
    this.getPolygons().then(result => {
      polygons = result.data.map(item => {

        if (!item || !item.location || !item.location.coordinates || !item.location.coordinates[0]) {
          return;
        }
        const polygonPoints = item.location.coordinates[0].map(coordinates => {
          return mapCenter = {
            lat: coordinates[1],
            lng: coordinates[0]
          }
        })
        return (<Polygon
          paths={polygonPoints}
          name={item.name}
          id={item._id}
          strokeColor="#0000FF"
          strokeOpacity={0.8}
          strokeWeight={1}
          fillColor="#0000FF"
          fillOpacity={0.35}
        />)
      });
      this.setState({ polygons, mapCenter });
    });
  }

  getMap() {
    if (this.state.polygons.length > 0) {
      return (<Map
        google={this.props.google}
        containerStyle={{
          width: '100%',
          height: '100%',
          position: 'relative !important',
          padding: '20px'
        }}
        initialCenter={this.state.mapCenter}
        zoom={11}>
        {this.state.polygons}
      </Map>)
    } else {
      return (
        <div>
          <h4 className={"text-center"}>Não há polígonos para serem exibidos</h4>
        </div>
      )
    }
  }

  render() {
    if (this.props.zone && this.props.isZoneMapModalOpen) {
      return (
        <Modal
          className="Modal Modal--large"
          show={this.props.isZoneMapModalOpen || false}
          onHide={() => this.props.closeModal()}
        >
          <Modal.Header closeButton>
            <Modal.Title className="Modal-title">
              Mapa dos Poligonos
          </Modal.Title>
          </Modal.Header>
          <Modal.Body style={{
            height: "500px"
          }}>
            {this.getMap()}
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={() => this.props.closeModal()}>Fechar</Button>
          </Modal.Footer>
        </Modal>
      );
    } else {
      return (
        <div></div>
      )
    }
  }
}

export default GoogleApiWrapper({
  apiKey: "AIzaSyBdlcgxa3ueG4y5U7qbPUVbcnndWL3Oabc"
})(ZoneMapModal);
