import React, { PropTypes } from 'react';
import { Button } from 'react-bootstrap';
import handleRequest from './../../../helper/requests';

// styles
import './UpdatePartnerProgress.css';

const UpdatePartnerProgress = props => {
  const { updateCallback, cancelCallback, partner, notify } = props;
  const data = { progressSignup: 100, backofficeUpdate: true };
  const { id, name } = partner;
  const actionCreator = () => updateCallback(id, data);
  const update = () => handleRequest(actionCreator, notify, 'Cadastro marcado como completo');

  return (
    <div className="UpdatePartnerProgress">
      <div className="UpdatePartnerProgress-message">Você tem certeza que deseja completar<br />o progresso do cadastro da artista <strong>{name}</strong>?</div>
      <div className="UpdatePartnerProgress-btnWrapper">
        <Button className="UpdatePartnerProgress-btn btn btn-info" onClick={update}>Sim</Button>
        <Button className="UpdatePartnerProgress-btn btn btn-danger" onClick={cancelCallback}>Cancelar</Button>
      </div>
    </div>
  );
};

UpdatePartnerProgress.propTypes = {
  updateCallback: PropTypes.func,
  cancelCallback: PropTypes.func,
  partner: PropTypes.object
};

export default UpdatePartnerProgress;
