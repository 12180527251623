import React, { Component } from 'react';
import Griddle from 'griddle-react';
import moment from 'moment';

import Loading from '../../Global/Loading/Loading';
import AreaSelect from '../../Helpers/AreaSelect';

require('../LeadDetails/LeadDetails');

import { weekDaysAbbreviatonList, scheduleTypes, leadTechniques } from '../../../helper/leads'

const categories = {
  c100: 'Manicure',
  c101: 'Massoterapeuta',
  c102: 'Escovista',
  c104: 'Depiladora',
  c108: 'Limpeza de Pele',
  c110: 'Maquiadora'
}
class ConfigurationList extends Component {
  render() {
    return (
      <div className="container">
        <div className="row">
          <div className="col-xs-11">
            <h1>Lista dos horários diponíveis para teste</h1>
          </div>
          <div className="col-xs-1">
            <a href="/#leads/evaluation/schedules/config/new" className="btn-info  btn btn-default">NOVO</a>
          </div>
        </div>

        <div className="row">
          <h3>Filtros</h3>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>

            <div className="col-xs-2">
              <AreaSelect onChange={this.props.onChangeArea} />
            </div>
            <div className="col-xs-2">
              <p>Categoria</p>
              <select className="form-control" defaultValue="" onChange={evt => this.props.onChangeCategory(evt.target.value)}>
                <option disabled value="" >Selecione uma categoria</option>
                {
                  leadTechniques.map(techique => {
                    return <option key={techique.value} value={techique.value}>{techique.text}</option>
                  })
                }
              </select>
            </div>
            <div className="col-xs-2">
              <p>Tipo do Teste</p>
              <select className="form-control" defaultValue="" onChange={evt => this.props.onChangeEvaluationType(evt.target.value)}>
                <option value="" disabled>Selecione uma categoria</option>
                {scheduleTypes.map(evaluationType => {
                  return (<option key={evaluationType.value} value={evaluationType.value}>{evaluationType.text}</option>)
                })}
              </select>
            </div>

            <div className="col-xs-2">
              <p>Ordenar</p>
              <select className="form-control" defaultValue="" onChange={evt => this.props.onChangeOrder(evt.target.value)}>
                <option value="" disabled>Selecione uma opção</option>
                <option value="DESC">Mais recente</option>
                <option value="CRESC">Mais antigo</option>
              </select>
            </div>

            <div className="col-xs-2" >
              <p>Somente Ativos</p>
              <input type="checkbox" onChange={this.props.onChangeOnlyActives} style={{ width: 100 }} />
            </div>
          </div>

        </div>

        {
          this.props.loading ?
            <Loading /> :
            <Griddle
              results={this.props.evaluationScheduleList}
              resultsPerPage={10}
              useFixedLayout={false}
              rowMetadata={{
                "bodyCssClassName": function (rowData) {

                  const end = moment(rowData.end)
                  if (end < moment()) {
                    return 'red-row';
                  }
                  return 'default-row';
                }
              }}
              columnMetadata={[
                {
                  columnName: '_id',
                  displayName: 'Id',
                },
                {
                  columnName: 'days',
                  displayName: 'Dias',
                  customComponent: ({ rowData }) => {
                    return (
                      <p>{rowData && rowData.days && rowData.days.map(day => weekDaysAbbreviatonList[day]).join(', ') || 'Nenhum dia habilitado'}</p>
                    )
                  }
                },
                {
                  columnName: 'timeRange',
                  displayName: 'Horário',
                  customComponent: ({ rowData }) => {
                    return (
                      <p>Das {rowData.timeRange.start} às {rowData.timeRange.end}h</p>
                    )
                  }
                },
                {
                  columnName: 'disponibility',
                  displayName: 'Disponibilidade',
                  customComponent: ({ rowData }) => {
                    return (
                      <p>Do dia {moment(rowData.start).format('DD/MM/YYYY')} até {moment(rowData.end).format('DD/MM/YYYY')}</p>
                    )
                  }
                },
                {
                  columnName: 'category',
                  displayName: 'Categoria',
                  customComponent: ({ rowData }) => {
                    return (
                      <p>{categories[rowData.category]}</p>
                    )
                  }

                },
                {
                  columnName: 'type',
                  displayName: 'Tipo',
                  customComponent: ({ rowData }) => {
                    const type = rowData && rowData.type && rowData.type.id || 1
                    return <p>{scheduleTypes.filter(item => item.value === type)[0].text}</p>
                  }
                },
                {
                  columnName: 'limit',
                  displayName: 'Qtd Pessoas'
                },
                {
                  columnName: 'actions',
                  displayName: 'Ações',
                  customComponent: (props) => {
                    const { rowData } = props;
                    return (
                      <div>
                        <button
                          className="btn-info"
                          name={rowData._id}
                          onClick={(evt) => {
                            window.location = `/#/leads/evaluation/schedules/config/edit/${evt.target.name}`
                          }}
                        >
                          Editar
                        </button>
                      </div>
                    );
                  },
                },
              ]}
              tableClassName={'GriddleTable table'}
              useGriddleStyles={false}
              settingsToggleClassName={'btn btn-default'}
              columns={[
                '_id',
                'days',
                'timeRange',
                'disponibility',
                'category',
                'type',
                'limit',
                'actions'
              ]}
            />
        }

      </div >
    )
  }
}

export default ConfigurationList;