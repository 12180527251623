import "./AddressModal.css";
import { Modal, Button } from "react-bootstrap";
import TextField from "../../components/FormFields/TextField";
import React from "react/lib/React";
import Autocomplete from "react-google-autocomplete";
import masks from "../../helper/masks";
import formatter from "../../helper/formatters";

export class AddressModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      fields: {
        place: "",
        streetNumber: "",
        neighbor: "",
        city: "",
        uf: "",
        zipCode: "",
        complement: "",
        location: "",
        identification: "",
      },
      addressArrived: false,
    };
  }

  async handleOnSave() {
    const address = {
      place: this.state.fields.place,
      streetNumber: this.state.fields.streetNumber,
      city: this.state.fields.city,
      uf: this.state.fields.uf,
      zipCode: this.state.fields.zipCode,
      complement: this.state.fields.complement,
      neighbor: this.state.fields.neighbor,
      lat: this.state.fields.location.coordinates[1],
      lng: this.state.fields.location.coordinates[0],
      typeAddress: 1,
      addressIdentification:
        this.state.fields.identification || "endereço pessoal",
    };

    await this.props.onSave(address);

    this.setState({ addressArrived: false });
  }

  handleAddressInfo(address) {
    this.setState({
      fields: {
        place: address.place || address.streetName,
        streetNumber: address.streetNumber,
        neighbor: address.neighbor || address.neighborhood,
        city: address.city,
        uf: address.uf,
        zipCode: formatter.formatMaskedValue(
          address.zipCode || address.zipcode,
          masks.zipcode
        ),
        complement: address.complement,
        location: address.location || {},
      },
      addressArrived: true,
    });
  }

  populateAddressInfo(address) {
    let lat,
      lng,
      place,
      streetNumber,
      neighbor,
      city,
      uf,
      zipCode,
      complement = "";

    if (address && address.geometry && address.geometry.location) {
      lat =
        (address.geometry.location.lat && address.geometry.location.lat()) || 0;
      lng =
        (address.geometry.location.lng && address.geometry.location.lng()) || 0;
    }

    address.address_components.forEach((loc) => {
      if (loc.types.indexOf("street_number") !== -1) {
        streetNumber = loc.long_name;
      } else if (
        loc.types.indexOf("route") !== -1 ||
        loc.types.indexOf("street_address") !== -1
      ) {
        place = loc.long_name;
      } else if (
        loc.types.indexOf("sublocality_level_1") !== -1 ||
        loc.types.indexOf("sublocality") !== -1 ||
        loc.types.indexOf("neighborhood") !== -1
      ) {
        neighbor = loc.long_name;
      } else if (
        loc.types.indexOf("administrative_area_level_2") !== -1 ||
        loc.types.indexOf("locality") !== -1
      ) {
        city = loc.long_name;
      } else if (loc.types.indexOf("administrative_area_level_1") !== -1) {
        uf = loc.short_name;
      } else if (loc.types.indexOf("postal_code") !== -1) {
        zipCode = loc.long_name;
      }
    });

    this.handleAddressInfo({
      location: {
        type: "Point",
        coordinates: [lng, lat],
      },
      place,
      streetNumber,
      neighbor,
      city,
      uf,
      zipCode,
      complement,
    });
  }

  renderAddressFields() {
    const { addressArrived } = this.state;

    const {
      place,
      streetNumber,
      complement,
      neighbor,
      city,
      uf,
      zipCode,
      location,
      identification,
    } = this.state.fields;

    if (addressArrived) {
      return (
        <div>
          <div className="row">
            <div className="col-sm-12">
              <TextField
                name="identification"
                label="Identificador *"
                inputData={{
                  value: identification,
                  onChange: (value) =>
                    this.setState({
                      fields: { ...this.state.fields, identification: value },
                    }),
                }}
                placeholder="Insira uma descrição que identifique o endereço"
              />
            </div>
          </div>
          <div className="row">
            <div className="col-sm-3">
              <TextField
                name="place"
                label="Rua *"
                inputData={{ value: place }}
                placeholder="Insira aqui a rua"
                disabled={true}
              />
            </div>
            <div className="col-sm-3">
              <TextField
                name="streetNumber"
                label="Número *"
                inputData={{
                  value: streetNumber,
                  onChange: (value) =>
                    this.setState({
                      fields: { ...this.state.fields, streetNumber: value },
                    }),
                }}
                placeholder="Insira aqui o número"
              />
            </div>
            <div className="col-sm-6">
              <TextField
                name="complement"
                label="Complemento"
                inputData={{
                  value: complement,
                  onChange: (value) =>
                    this.setState({
                      fields: { ...this.state.fields, complement: value },
                    }),
                }}
                placeholder="Insira aqui o complemento"
              />
            </div>
          </div>

          <div className="row">
            <div className="col-sm-3">
              <TextField
                name="neighbor"
                label="Bairro *"
                inputData={{ value: neighbor }}
                placeholder="Insira aqui seu bairro"
                disabled={true}
              />
            </div>
            <div className="col-sm-3">
              <TextField
                name="city"
                label="Cidade *"
                inputData={{ value: city }}
                placeholder="Insira aqui a cidade"
                disabled={true}
              />
            </div>
            <div className="col-sm-3">
              <TextField
                name="uf"
                label="Estado *"
                inputData={{ value: uf }}
                placeholder="Insira aqui o Estado"
                disabled={true}
              />
            </div>
            <div className="col-sm-3">
              <TextField
                name="zipcode"
                label="CEP *"
                inputData={{ value: zipCode }}
                placeholder="Insira aqui o cep"
                mask={masks.zipcode}
                disabled={true}
              />
            </div>
            <input
              name="location"
              style={{ display: "none" }}
              value={{ value: location }}
            />
          </div>
        </div>
      );
    }

    return null;
  }

  handleOnClose = () => {
    this.props.close();
    this.setState({
      addressArrived: false,
    });
  };

  render() {
    return (
      <Modal
        show={this.props.show}
        className="Modal Modal--large"
        onHide={this.handleOnClose}
      >
        <Modal.Body>
          <div className="Form-formGroup">
            <div className="row">
              <div className="col-sm-12">
                <div className="Form-labelWrapper">
                  <div className="Form-label">
                    <span>Novo Endereço</span>
                  </div>
                </div>
                <Autocomplete
                  apiKey={"AIzaSyBdlcgxa3ueG4y5U7qbPUVbcnndWL3Oabc"}
                  placeholder="Insira aqui seu endereço"
                  className="Form-input"
                  style={{ width: "100%", zIndex: -100 }}
                  onPlaceSelected={(addr) => this.populateAddressInfo(addr)}
                  types={[]}
                  componentRestrictions={{ country: "br" }}
                />
              </div>
            </div>
            {this.renderAddressFields()}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={() => this.handleOnSave()}>Salvar</Button>
        </Modal.Footer>
      </Modal>
    );
  }
}
