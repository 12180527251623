import config from '../../../config.json';
const env = process.env.REACT_APP_ENVIRONMENT || 'stage';
const BACKEND_URL = config.api[env];

import $ from 'jquery'

import React, { Component } from 'react';
import PageHeader from '../../components/Global/PageHeader';
import { importTransfers } from "./../../actions/artists/import_transfers";
import { fetchBalance } from "./../../actions/moip/fetch_balance";
import { Modal, Button } from "react-bootstrap";
import auth from './../../models/auth';
import bonus from "../../helper/bonus";

const reasons = bonus.REASONS;

class ImportBatch extends Component {
    state = {
        loading: false,
        current: 0,
        future: 0,
        unavailable: 0,
        open: false
    }

    componentWillMount() {
        fetchBalance().then(res => {
            const data = res.data;
            if (data.length > 0) {
                let balance = data[0];
                this.setState({
                    current: balance.current / 100,
                    future: balance.future / 100,
                    unavailable: balance.unavailable / 100,
                })
            }
        }).catch(error => {
            console.log(error);
            alert('Não foi possível recuperar o salvo MOIP');
        })
    }

    handleModal = () => this.setState({ open: !this.state.open });

    sendTransfers() {
        const files = $('#transfersInport')[0].files;

        if (files[0] && confirm(`Tem certeza que quer enviar ${files[0].name}`)) {
            const formData = new FormData();
            formData.append('batch', files[0]);
            this.setState({ loading: true });
            importTransfers(formData).then(() => {
                this.setState({ loading: false });
                alert("Importação efetuada com sucesso!");
            }).catch(error => {
                console.log(error.data);
                this.setState({ loading: false });
                alert(error.data.message || 'Ocorreu um erro\nBaixe e verifique o arquivo com as linhas com problema.');
                window.open(`${BACKEND_URL}/v2/artist/bonus/error/${error.data}?token=${auth.getToken()}`, '_blank');
            });
        }
    }

    render() {
        return (
            <div>
                <PageHeader title="Importação de Pagamentos" />

                <center>
                    <b> Saldo disponível: </b> R$ {this.state.current}
                    <b> ---- Saldo a receber: </b> R$ {this.state.future}
                    <b> ---- Saldo bloqueado: </b> R$ {this.state.unavailable}
                </center>

                <br />

                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginBottom: 15 }}>
                    <b style={{ marginLeft: 10, marginRight: 10 }}>Importar arquivo:</b>
                    <button onClick={() => $('#transfersInport').click()} className="file-name btn btn-default">Escolher Arquivo .csv</button>
                    <input id="transfersInport" name="arquivo" className="input-file" type="file" accept=".csv" style={{ opacity: 0, height: 0, width: 0, overflow: 'hidden' }}
                        onChange={() => {
                            {
                                var files = $('#transfersInport')[0].files;
                                $('.file-name').text(`${files[0].name}`)
                            }
                        }}></input>
                    <button type="submit" className="btn btn-info" style={{ marginLeft: 15 }} onClick={() => this.sendTransfers()} >Enviar</button>
                    <p style={{ display: this.state.loading ? 'block' : 'none', marginLeft: 10 }}> Carregando ...</p>
                </div>
                <center>
                    <a href="https://s3.amazonaws.com/br.com.singu.transfers/bonus_example.csv" download="bonus_example.csv">
                        Baixar arquivo de exemplo
                    </a>
                </center>

                <Button style={{ float: 'left' }} onClick={this.handleModal}> Ver motivos para pagamento</Button>

                <br /><br />

                <Modal show={this.state.open} onHide={this.handleModal}>
                    <Modal.Header closeButton>
                        <Modal.Title>Motivos para pagamento</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <center>
                            {reasons.map(value => <div><b>{value.id}</b> {value.description} <br /> <br /></div>)}
                        </center>
                    </Modal.Body>
                </Modal>
            </div >
        );
    }
}

export default ImportBatch;
