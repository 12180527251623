import React, { Component } from "react";
import PageHeader from "../../components/Global/PageHeader";
import { saveRealtimeDynamicPrices } from "../../actions/administrative_zones/save_realtime_dynamic_prices";
import { getRealtimePrices } from "../../actions/administrative_zones/fetch_realtime_prices";
import EditDynamicPrices from "../../components/RealtimeDynamicPrices/EditDynamicPrices";
import { connect } from "react-redux";

class RealtimeDynamicPrices extends Component {
  constructor(props){
    super(props)
    this.state = {
      multipliers: {}
    }
  }
  savePrices = (serviceId, params) => {
    return this.props.saveRealtimeDynamicPrices(serviceId, params);
  };

  componentWillMount() {
    this.getPrices();
  }

  getPrices = () => {
    this.props
      .getRealtimePrices()
      .then(response => {
        console.log(this.setState({multipliers: response.payload.data}))
      })
      .catch(error => {
        console.log(error)
        let message = "Não foi possível recuperar os dados do preço dinâmico";
        if (error && error.data && error.data.error && error.data.error.description) {
          message = error.data.error.description;
        }
        alert(message);
      });
  };

  render() {
    return (
      <div className={"container"}>
        <PageHeader title="Singu Agora - Edição de preços dinâmicos" />
        <div className={"col-lg-12"}>
          <EditDynamicPrices savePrices={this.savePrices} multipliers={this.state.multipliers} />
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
}

export default connect(
  mapStateToProps,
  {
    saveRealtimeDynamicPrices,
    getRealtimePrices
  }
)(RealtimeDynamicPrices);
