/* global nodeAPI */
import React, { Component } from "react";
import PageHeader from "../../components/Global/PageHeader";
import { getUrl } from "../../actions/consultant/getUrl"
import Swal from "sweetalert2";

class GetUrl extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      id: "",
      loading: false
    }
  }
  getUrlFromEmail = (e) => {
    e.preventDefault();
    this.setState({ loading: true })
    getUrl({ email: this.state.email }).then(({ data }) => {
      Swal.fire("Sucesso", data.url, "success");
    }).catch(err => {
      console.log(err);
      let message = "Houve um erro ao processar a solicitação"
      if (err && err.data && err.data.error && err.data.error.description) message = err.data.error.description
      Swal.fire("Ops...", message, "error");
    }).finally(() => {
      this.setState({ loading: false })
    });
  }


  getUrlFromId = (e) => {
    e.preventDefault();
    this.setState({ loading: true })
    getUrl({ id: this.state.id }).then(({ data }) => {
      Swal.fire("Sucesso", data.url, "success");
    }).catch(err => {
      console.log(err);
      let message = "Houve um erro ao processar a solicitação"
      if (err && err.data && err.data.error && err.data.error.description) message = err.data.error.description
      Swal.fire("Ops...", message, "error");
    }).finally(() => {
      this.setState({ loading: false })
    });
  }

  render() {
    return (
      <div className={"container"}>
        <PageHeader title="Recupear url de integração da Consultora" />
        <div className={"col-lg-12"}>
          <div className={"col-xs-12"}>
            <form >
              <div className="col-xs-10">
                <p>Email da consultora</p>
                <input
                  required
                  name="email"
                  type="email"
                  value={this.state.email}
                  onChange={(evt) => { this.setState({ email: evt.target.value }) }}
                  style={{ width: '100%' }}
                  className="Form-input"
                />
              </div>
              <div className="col-xs-2">
                <p>&nbsp;</p>
                <button onClick={this.getUrlFromEmail} disabled={this.state.loading} className="btn btn-default btn-primary" type="submit">Recuperar URL</button>
              </div>
              <div className="col-xs-12" style={{ textAlign: "center" }}>
                <h3>OU</h3>
              </div>
              <div className="col-xs-10">
                <p>_ID da consultora</p>
                <input
                  required
                  name="id"
                  type="id"
                  value={this.state.id}
                  onChange={(evt) => { this.setState({ id: evt.target.value }) }}
                  style={{ width: '100%' }}
                  className="Form-input"
                />
              </div>
              <div className="col-xs-2">
                <p>&nbsp;</p>
                <button onClick={this.getUrlFromId} disabled={this.state.loading} className="btn btn-default btn-primary" type="submit">Recuperar URL</button>
              </div>
            </form>
          </div>
        </div>
      </div >
    );
  }
}

export default GetUrl;
