import React, { Component } from "react";
import Swal from "sweetalert2";
import moment from 'moment';
import { Button } from 'react-bootstrap';

import { Toast } from '../../../helper/alerts';
import CreatableSelect from '../../Helpers/CreatableSelect';
import GeneratePixPayment from './GeneratePixPayment';

import {
  findPixPayments,
  createPixPayment,
  deletePixPayment,
  updatePixPaymentStatus
} from '../../../actions/schedules/pix_payments';

import './SchedulePixPayments.css';

const AVAILABLE_BANKS = ['Itaú'];
export default class SchedulePixPayments extends Component {
  constructor(props) {
    super(props);

    const { schedule } = props;

    this.state = {
      pixPayments: schedule && schedule.pixPayments || [],
      id: '', // Pix ID
      bank: AVAILABLE_BANKS[0], // Pix Bank
      value: null, // Pix Value
      isFormLoading: false,
      isTableLoading: false,
      pixPaid: (schedule && schedule.payment && schedule.payment.paid) || false,
      isChangingPixPaid: false,
      shareLink: schedule.payment.id ? `https://plataforma.aarin.com.br/cobrancas/compartilhar/${schedule.payment.id}` : null,
      copyPaste: schedule.payment.copyPaste || null
    }
  }

  renderPixPaymentsTable = () => {
    const { schedule } = this.props;
    const { pixPayments } = this.state;

    const totalPixPaid = pixPayments.reduce((total, curr) => total += curr.value, 0);

    return (
      <table className="table pix-payments-table">
        <thead>
          <tr>
            <th>ID</th>
            <th>Banco</th>
            <th>Data</th>
            <th>Valor</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {pixPayments && pixPayments.map((pixPayment, index) => {
            return (
              <tr key={index}>
                <td>{pixPayment.id}</td>
                <td>{pixPayment.bank}</td>
                <td>{moment.tz(pixPayment.createdAt, 'America/Sao_Paulo').format('DD/MM/YY HH:mm')}</td>
                <td>R$ {pixPayment.value}</td>
                <td>
                  <button
                    className="btn btn-sm btn-danger"
                    onClick={() => this.handleDeletePixPayment(pixPayment.id)}
                    disabled={pixPayment.loading}>
                    {
                      pixPayment.loading ?
                        <span className="spinner glyphicon glyphicon-refresh" ></span>
                        : 'Excluir'
                    }
                  </button>
                </td>
              </tr>
            )
          })}
          {this.renderAddPixPayment()}
        </tbody>
        <tfoot>
          <tr>
            <th>TOTAL</th>
            <th></th>
            <th></th>
            <th>PIX: R$ {totalPixPaid}</th>
            <th>Pedido: R$ {schedule.totalValue}</th>
          </tr>
        </tfoot>
      </table>
    )

  }

  renderAddPixPayment = () => {
    const { id, value, isFormLoading } = this.state;
    const { schedule } = this.props;
    const hasProfessional = !!schedule.partnerName;

    const isFormDisabled = isFormLoading || !hasProfessional;

    return (
      <tr>
        <td>
          <input
            type="text"
            placeholder="Código do PIX"
            className="Form-input"
            value={id}
            onChange={(e) => this.setState({ id: e.target.value })}
            disabled={isFormDisabled}
          />
        </td>
        <td>
          <CreatableSelect
            disabled={isFormDisabled}
            selectOptions={AVAILABLE_BANKS}
            onValueChange={(value) => this.setState({ bank: value })}
          />
        </td>
        <td>
          Data de criação
        </td>
        <td>
          <input
            type="number"
            min={0}
            step=".01"
            placeholder="Valor"
            className="Form-input"
            value={value}
            onChange={(e) => this.setState({ value: e.target.value })}
            disabled={isFormDisabled}
          />
        </td>
        <td>
          <button
            type="button"
            className="btn btn-sm btn-success"
            disabled={isFormDisabled}
            onClick={this.handleAddPixPayment}>
            {isFormDisabled ?
              isFormLoading ?
                <span className="spinner glyphicon glyphicon-refresh"></span>
                : 'Adicionar'
              : 'Adicionar'
            }
          </button>
        </td>
      </tr>
    )
  }

  renderNoProfessionalText = () => {

    const hasProfessional = !!this.props.schedule.partnerName;

    if (hasProfessional) return;

    return <p className="pix-payments-noprofessional"> Edição desabilitada for falta de profissional. </p>
  }

  renderPixPaymentsStatus = () => {
    const { pixPaid, isChangingPixPaid } = this.state;
    const { schedule } = this.props;

    const pixPaidTextColor = `text-${pixPaid ? 'success' : 'danger'}`;
    const pixPaidText = pixPaid ? 'PAGO' : 'NÃO PAGO';
    const pixPaidButtonText = isChangingPixPaid ?
      <span className="spinner glyphicon glyphicon-refresh" ></span>
      : pixPaid ? 'Atualizar para não pago' : 'Atualizar para pago';
    const hasProfessional = !!schedule.partnerName;

    return (
      <div className="pix-payments-status" style={{ paddingTop: "10px" }}>
        <p>STATUS: <span className={pixPaidTextColor}>{pixPaidText}</span></p>
        <button className="btn btn-sm btn-info" disabled={!hasProfessional} onClick={() => this.handleUpdatePixPaymentsStatus()}>
          {pixPaidButtonText}
        </button>
      </div>
    )

  }

  renderPixCopyPastAndShareLink = () => {
    const { schedule } = this.props;
    const { shareLink, copyPaste } = this.state;

    async function clipboardText(text) {
      try {
        await navigator.clipboard.writeText(text);

        Toast().fire({
          icon: 'success',
          title: 'Informação copiada com sucesso'
        });
      } catch(err) {
        Toast().fire({
          icon: 'error',
          title: 'Erro ao copiar informação'
        });
      }
    }

    const generatePixPaymentCallback = (share_link, copy_paste) => {
      this.setState({
        shareLink: share_link,
        copyPaste: copy_paste
      });
    }
    const { id, payment, statusCode} = schedule;
    const showGeneratePixPayment = !payment?.paid && (statusCode === 11 || statusCode === 5);

    return (
      <div className="col-sm-12 col-md-8">
        {showGeneratePixPayment &&
          <GeneratePixPayment
            scheduleId={id}
            generatePixPaymentCallback={generatePixPaymentCallback}
          />}
        {!!shareLink && (
          <div>
            <b>PIX - Link de compartilhamento</b>
            <Button style={{ padding: '0 0.25rem', color: '#ff7a66'}} bsStyle="link" onClick={() => clipboardText(shareLink)} title="Copiar">
              <span className="glyphicon glyphicon-copy"></span>
            </Button>
            <p style={{ color: '#909090' }}>{shareLink}</p>
          </div>
        )}
        {!!copyPaste && (
          <div>
            <b>PIX - Copia e cola</b> 
            <Button style={{ padding: '0 0.25rem', color: '#ff7a66'}} bsStyle="link" onClick={() => clipboardText(copyPaste)} title="Copiar">
              <span className="glyphicon glyphicon-copy"></span>
            </Button>
            <p style={{ color: '#909090' }}>{copyPaste}</p>
          </div>
        )}
      </div>
    )
  }

  handleError = (error, defaultMessage) => {
    if (error.data) {
      // Back-end
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: error.data.error.description
      });
      return;
    }
    // Front-end
    Swal.fire({
      icon: 'error',
      title: 'Oops...',
      text: error.description || defaultMessage
    });
  }

  getPixPayments = async () => {
    const { schedule } = this.props;

    this.setState({ isTableLoading: true });

    try {
      //data: { pixPayments: array, pixPaid: boolean }
      const { data } = await findPixPayments(schedule.id);
      const { pixPayments, pixPaid } = data;
      this.setState({
        isTableLoading: true,
        pixPayments,
        pixPaid,
      });
    } catch (err) {
      this.setState({
        isTableLoading: false,
      });
      this.handleError(err, 'Erro ao carregar pagamentos PIX');
    }
  }

  updatePixPayment = (id, object) => {
    const updatedPixPayments = this.state.pixPayments.map(pixPayment => {
      if (pixPayment.id != id) return pixPayment;

      return {
        ...pixPayment,
        ...object
      }
    });

    this.setState({ pixPayments: updatedPixPayments });
  }

  handleUpdatePixPaymentsStatus = async () => {
    const { schedule } = this.props;
    const { pixPaid } = this.state;

    let result = await Swal.fire({
      icon: "warning",
      title: "Cuidado",
      showCancelButton: true,
      text: "Atualizar este pedido para pago sem uma trasação pode causar problemas no calculo de fluxo de caixa. Deseja confirmar esta ação?",
      confirmButtonText: "Confirmar!",
      cancelButtonText: "Cancelar"
    });

    if (!result.isConfirmed) {
      return;
    }

    result = await Swal.fire({
      icon: "warning",
      showCancelButton: true,
      title: "Tem certeza mesmo?",
      confirmButtonText: "Sim",
      cancelButtonText: "Cancelar"
    });


    if (!result.isConfirmed) {
      return;
    }

    try {
      this.setState({ isChangingPixPaid: true })
      await updatePixPaymentStatus(schedule.id)

      this.setState({ pixPaid: !pixPaid });
      Swal.fire({
        icon: 'success',
        title: 'Sucesso',
        text: 'Status dos pagamentos PIX atualizado!'
      });
    } catch (err) {
      this.handleError(err, 'Erro ao atualizar status dos pagamentos PIX');
    } finally {
      this.setState({ isChangingPixPaid: false })
    }
  }

  handleAddPixPayment = async () => {
    this.setState({ isFormLoading: true });

    const schedule = this.props.schedule;
    const { pixPayments, id, bank, value } = this.state;
    const pixPayment = { id, bank, value: parseFloat(value) };

    try {
      // Checa valores não permitidos ou vazios
      const isSomeEmpty = Object.values(pixPayment).some(value => value === '' || value === null || (typeof 'number' && value < 0));
      if (isSomeEmpty) return;

      // Checa se ja existe código pix na lista
      const pixPaymentExists = pixPayments.find(pixPayment => pixPayment.id == id);
      if (pixPaymentExists) throw { description: 'Esse código PIX já está cadastrado!' };

      // Checa se o valor do pix somado ao da lista excederá o total do pedido
      const totalPixPaid = pixPayments.reduce((total, curr) => total += curr.value, 0);
      if ((totalPixPaid + pixPayment.value) > schedule.totalValue) throw { description: 'Os pagamentos PIX não podem exceder o total do pedido!' };

      // Cria pix no banco
      await createPixPayment(schedule.id, pixPayment);

      const isPixPaymentsEqualToTotalValue = totalPixPaid + pixPayment.value === schedule.totalValue;
      // Adiciona pix do banco no estado e zera estados do formulário
      this.setState({
        pixPayments: [
          ...pixPayments,
          {
            ...pixPayment,
            loading: false,
          }
        ],
        pixPaid: isPixPaymentsEqualToTotalValue,
        id: '',
        bank: AVAILABLE_BANKS[0],
        value: 0
      });
      Swal.fire({
        icon: 'success',
        title: 'Sucesso',
        text: 'Pagamento PIX criado!'
      });
    } catch (err) {
      this.handleError(err, 'Erro ao adicionar pagamento PIX!');
    } finally {
      this.setState({
        isFormLoading: false,
      })
    }

  }

  handleDeletePixPayment = async (pixPaymentId) => {
    const { schedule } = this.props;
    this.updatePixPayment(pixPaymentId, { loading: true });

    try {
      await deletePixPayment(schedule.id, pixPaymentId)

      const newPixPayments = this.state.pixPayments.filter(pixPayment => pixPayment.id !== pixPaymentId);

      this.setState({
        pixPayments: newPixPayments,
        // Sempre se deletado um pagamento PIX o total pago não sera igual ao do pedido
        pixPaid: false
      });
      Swal.fire({
        icon: 'success',
        title: 'Sucesso',
        text: 'Pagamento PIX excluido!'
      });
    } catch (err) {
      this.handleError(err, 'Erro ao deletar pagamento PIX');
      this.updatePixPayment(pixPaymentId, { loading: false });
    }
  }

  render() {
    return (
      <div className="row">
        <div className="col-sm-12 col-lg-7 pix-payments-container">
          <div className="pix-payments-header">
          </div>
          {this.renderPixPaymentsStatus()}
          {this.renderNoProfessionalText()}
        </div>
      </div>
    );
  }
}
