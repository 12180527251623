import { Component } from "react";
import ListTransfer from "../../components/Transfer/TransfersGriddle";
import PageHeader from "../../components/Global/PageHeader";
import { fetchTransfers } from "../../actions/transfers/fetch_transfers";

class Transfer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      transfers: [],
      loading: true,
      page: 1,
      status: null,
      professional: '',
      createAt: null,
    }
  }

  componentWillMount() {
    this.getTransfers();
  }

  setLoading = loading => this.setState({ loading });

  search = (professional, status, createAt) => {
    this.setState({ professional, status, createAt }, this.getTransfers);
  }

  getTransfers = () => {
    this.setLoading(true);
    const { professional, status, createAt, page } = this.state;
    fetchTransfers(professional, status, createAt, page)
      .then(({ data }) => {
        this.setState({ transfers: data.bonus, totalItems: data.totalItems });
      }).finally(() => {
        this.setState({ loading: false, page: page });
      })
  };

  setPage = (page) => {
    this.setState({ page: page + 1 }, () => this.getTransfers())
  }



  renderTransferGriddle() {
    return (
      <ListTransfer
        transfers={this.state.transfers}
        currentPage={this.state.page}
        numberOfPages={this.state.totalItems}
        loading={this.state.loading}
        handlerSearch={this.search}
        setPage={this.setPage}
      />
    );
  }

  render() {
    return (
      <div>
        <PageHeader title="Transferências" />
        {this.renderTransferGriddle()}
      </div>
    );
  }
}

export default Transfer;