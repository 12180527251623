import React, { Component, PropTypes } from 'react';
import ReactDOM from 'react-dom';
import _ from 'underscore';

class NavbarLink extends Component {
  constructor(props) {
    super(props);

    this.state = {
      openedHeight: 0
    };
  }

  componentDidMount() {
    const { subMenu } = this.props;

    if (subMenu) {
      const content = ReactDOM.findDOMNode(this.refs.content);

      setTimeout(() => {
        const openedHeight = content.offsetHeight;

        this.setState({ height: openedHeight });
      }, 30);
    }
  }

  onLinkMouseEnter() {
    this.setState({
      isSubMenuOpened: true
    });
  }

  onLinkMouseLeave() {
    this.setState({
      isSubMenuOpened: false
    });
  }

  linkClass(subMenu, isSubMenuOpened) {
    if (subMenu) {
      return `Navbar-link Navbar-link--hasSubmenu ${isSubMenuOpened ? 'is-opened' : 'is-closed'}`;
    }

    return 'Navbar-link';
  }

  renderSubmenu() {
    const { subMenu, subMenuItems } = this.props;
    const mapSubMenuItems = (item, i) => (
      <li className="Navbar-submenuLink" key={i}>
        <a href={item.linkTo} >
          <span className={`Navbar-submenuIcon ${item.iconClass}`}></span>
          <span>{item.text}</span>
        </a>
      </li>
    );

    if (subMenu) {
      return (
        <div className="Navbar-submenu">
          <ul ref="content" className="Navbar-submenuContent">
            {_.map(subMenuItems, mapSubMenuItems)}
          </ul>
        </div>
      );
    }

    return null;
  }

  render() {
    const { subMenu, linkTo, iconClass, text, notClickable } = this.props;
    const { isSubMenuOpened } = this.state;
    const props = {};

    if (subMenu) {
      props.onMouseEnter = () => this.onLinkMouseEnter();
      props.onMouseLeave = () => this.onLinkMouseLeave();
    }

    return (
      <li className={this.linkClass(subMenu, isSubMenuOpened)} {...props}>

        {
          notClickable
            ? <a href={linkTo} onClick={(event) => event.preventDefault()}>
              <span className={`Navbar-icon ${iconClass}`}></span>
              <span>{text}</span>
            </a>

            : <a href={linkTo}>
              <span className={`Navbar-icon ${iconClass}`}></span>
              <span>{text}</span>
            </a>
        }

        {this.renderSubmenu()}
      </li>
    );
  }
}

NavbarLink.propTypes = {
  linkTo: PropTypes.string,
  text: PropTypes.string,
  iconClass: PropTypes.string,
  subMenu: PropTypes.bool,
  subMenuItems: PropTypes.array,
};

export default NavbarLink;
