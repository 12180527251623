import config from "../../../config.json";
const env = process.env.REACT_APP_ENVIRONMENT || "stage";
const BACKEND_URL = config.api[env];
import axios from "axios";

import { getToken } from "./../../models/auth";

export const FETCH_USER = "FETCH_USER";

export function fetchUser(id) {
  const userPromise = axios.get(`${BACKEND_URL}/v2/bo/users/${id}`, {
    headers: { Authorization: `Bearer ${getToken()}` },
  });

  return {
    type: FETCH_USER,
    payload: userPromise,
  };
}

export function getArtistsByID(_ids) {
  return axios.get(`${BACKEND_URL}/v2/bo/artists/${_ids}`, {
    headers: { Authorization: `Bearer ${getToken()}` },
  });
}

export function removeExcludedPartnerById(userId, excludedPartnerId) {
  return axios.delete(`${BACKEND_URL}/v2/bo/user/${userId}/excludedPartner`, {
    headers: { Authorization: `Bearer ${getToken()}` },
    data: { excludedPartnerId },
  });
}
