import axios from 'axios';
import config from '../../../../../config.json';
const env = process.env.REACT_APP_ENVIRONMENT || 'stage';
const BACKEND_URL = config.api[env];

import React, { Component } from "react";
import { Button, Modal } from "react-bootstrap";
import { getToken } from '../../../../models/auth';

class ChangePasswordModal extends Component
{
    state = {
        isWaiting: false,
        showPassword: false,
        password: '',
        error: false,
        message: ''
    }

    handleHideModal = () => {
        this.props.onHide();
        this.setState({
            showPassword: false, 
            password: '',
            error: false,
            message: ''
        })
    }

    toggleShowPassword = () => this.setState({ showPassword: !this.state.showPassword });

    handleChangePassword = (event) => this.setState({ password: event.target.value });

    handleSubmit = async (event) => {
        event.preventDefault();

        if (!this.state.password) {
            this.setState({ error: true, message: 'Insira uma senha' });
            return;
        }

        const data = {
            password: this.state.password
        };

        const artistId = this.props.artist._id;
        
        try {

            await axios.put(
                `${BACKEND_URL}/v2/artists/change-password/${artistId}`,
                data,
                { headers: { Authorization: `Bearer ${getToken()}` } }
            );

            this.setState({error: false, message: 'Senha alterada com sucesso!'});

        } catch(err) {
            this.setState({  error: true, message: 'Algo deu errado )=' });
        }

    }

    render() {
        return (
            <Modal show={this.props.show} onHide={this.handleHideModal}>
            <Modal.Header closeButton>
                <Modal.Title className="text-center">Alterar Senha</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <form 
                    onSubmit={this.handleSubmit}
                    style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}
                >
                    <input 
                        className="Form-input"
                        style={{margin: 8}} 
                        type={ this.state.showPassword ? "text" : "password" } name="password"
                        placeholder="Digite a nova senha"
                        value={this.state.password}
                        onChange={this.handleChangePassword}
                    />
                    <span 
                        className={this.state.showPassword ? "glyphicon glyphicon-eye-close" : "glyphicon glyphicon-eye-open"} 
                        style={{cursor: 'pointer', width: 20, height: 20 }}
                        onClick={this.toggleShowPassword}
                    ></span>
                    <Button 
                        type="submit"
                        style={{marginLeft: 8}} 
                    >Confirmar</Button>
                </form>

                {this.state.error && (
                    <div className="col-sm-offset-4 col-sm-4 alert alert-danger" role="alert">
                        <span className="glyphicon glyphicon-exclamation-sign" aria-hidden="true"></span>
                        <span className="sr-only">Error:</span>
                        {this.state.message}    
                    </div>
                )}

                {!this.state.error && this.state.message && (
                    <div className="col-sm-offset-4 col-sm-4 alert alert-success" role="alert">
                        <span className="glyphicon glyphicon-ok" aria-hidden="true"></span>
                        <span className="sr-only">Success:</span>
                        {this.state.message}    
                    </div>
                )}
            </Modal.Body>
        </Modal>
        )
    }
}

export default ChangePasswordModal;