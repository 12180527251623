/* global LANG */
import React from "react";
import _ from "lodash";
import { Component, PropTypes } from 'react';
import { reduxForm } from 'redux-form';
import NotificationSystem from 'react-notification-system';
import validate from './validation';
import ArtistForm from './../../components/Artists/ArtistForm';
import Loading from './../../components/Global/Loading/Loading';

// actions
import { fetchAreas } from './../../actions/areas/fetch_areas';
import { fetchConfigs } from './../../actions/configs/fetch_configs';
import { uploadPartnerImage } from './../../actions/partners/upload_partner_image';
import { newArtist } from './../../actions/artists/new_artist';
import { resetLead } from './../../actions/leads/reset_lead';
import { resetPartner } from './../../actions/partners/reset_partner';
import { resetArtist } from './../../actions/artists/reset_artist';

class NewArtist extends Component {
  constructor(props) {
    super(props);

    this.state = {
      dataArrived: false,
      services: null,
      cities: null
    };
  }

  componentWillMount() {
    const fulfill = action => {
      if (action.error) {
        if (action.payload.data) {
          this.notify(action.payload.data.error.description, 'error');
        } else {
          this.notify(action.payload.message, 'error');
        }
      }

      this.mapAreas(this.props.areas || []);
    };

    this.props.fetchAreas().then(fulfill);
  }

  componentWillReceiveProps(newProps) {
    const oldCities = this.props.fields.enabledCities.value;
    const newCities = newProps.fields.enabledCities.value;

    if ((oldCities && oldCities.length) !== (newCities && newCities.length)) {
      const filterAreas = area => _.includes(newCities, area._id);
      const includedAreas = _.filter(this.props.areas, filterAreas);
      this.mapServices(includedAreas || []);
    }
  }

  notify(message, level) {
    const title = level === 'success' ? 'Sucesso' : 'Erro';
    this.refs.notificationSystem.addNotification({
      title,
      message,
      level,
      position: 'tc'
    });
  }

  mapAreas(areas) {
    const mapAreasFn = area => ({
      label: area.city,
      value: area._id
    });

    this.setState({ dataArrived: true, cities: _.map(areas || [], mapAreasFn) });
  }

  mapServices(areas) {
    const reduceCategories = (acc, category) => {
      const mapServices = service => (
        {
          label: service.name[LANG],
          value: service._id,
          categoryName: category.name[LANG]
        }
      );
      const services = _.map(category.services, mapServices);

      return [
        ...acc,
        ...services
      ];
    };
    const reduceAreas = (acc, area) => (
      [
        ...acc,
        ...(_.reduce(area.categories, reduceCategories, []))
      ]
    );
    const reducedServices = _.reduce(areas, reduceAreas, []);
    const uniqueServices = _.uniqBy(reducedServices, 'value');
    const servicesByCategory = _.groupBy(uniqueServices, 'categoryName');
    const sortedKeys = _.keys(servicesByCategory).sort();
    const services = _.reduce(sortedKeys, (acc, key) => {
      acc[key] = servicesByCategory[key];
      return acc;
    }, {});

    this.setState({ services });
  }

  render() {
    const { dataArrived, cities, services } = this.state;

    if (!dataArrived) {
      return (
        <div>
          <NotificationSystem ref="notificationSystem" />
          <Loading />
        </div>
      );
    }

    const props = {
      cities,
      services,
      notify: (msg, lvl) => this.notify(msg, lvl),
      router: this.context.router,
      ...this.props
    };

    return (
      <div>
        <NotificationSystem ref="notificationSystem" />
        <ArtistForm {...props} />
      </div>
    );
  }
}

NewArtist.propTypes = {
  ArtistForm: PropTypes.object,
  areas: PropTypes.array,
  lead: PropTypes.object,
  artist: PropTypes.object,
  partner: PropTypes.object,
  fetchAreas: PropTypes.func,
  fetchConfigs: PropTypes.func,
  uploadPartnerImage: PropTypes.func,
  newArtist: PropTypes.func,
  resetLead: PropTypes.func,
  resetArtist: PropTypes.func,
  resetPartner: PropTypes.func
};

NewArtist.contextTypes = {
  router: PropTypes.object.isRequired
};

const formFields = {
  form: 'ArtistForm',
  fields: [
    'name',
    'email',
    'indicationCode',
    'phoneNumber',
    'fullAddress',
    'place',
    'location',
    'streetNumber',
    'complement',
    'neighbor',
    'city',
    'uf',
    'zipcode',
    'cpf',
    'beginner',
    'novice',
    'singuPayment',
    'realtime',
    'comment',
    'nacionality',
    'naturality',
    'dataexpedition',
    'entityemission',
    'fatherName',
    'motherName',
    'rg',
    'profilePicture',
    'gender',
    'birthdate',
    'rgUpload',
    'cpfUpload',
    'enabledCities',
    'enabledServices',
    'active',
    'status',
    'disabledUntil',
    'leadId',
    'artistId',
    'partnerId',
    'totalRating',
    'totalReviewSched',
    'moipCredentials',
    'password',
    'comments',
    'takeRateValue',
    'takeRateIncreaseValue',
    'takeRateIncreaseForEachDays',
    'expiration',
    'naturaID',
    'naturaIsActive',
    'naturaStatus',
    'naturaDigitalSpaceName'
  ],
  validate
};

function mapStateToProps(state) {
  return {
    ArtistForm: state.form,
    areas: state.areas.all,
    lead: state.leads.current,
    artist: state.artists.current,
    partner: state.partners.current
  };
}

const mapActionsToProps = {
  fetchAreas,
  fetchConfigs,
  uploadPartnerImage,
  newArtist,
  resetLead,
  resetArtist,
  resetPartner
};

export default reduxForm(formFields, mapStateToProps, mapActionsToProps)(NewArtist);
