import _ from "lodash";
import React, { Component, PropTypes } from "react";
import AvatarEditor from "react-avatar-editor";
import { Button, Modal } from "react-bootstrap";
import uploadHelper from "../../helper/upload";
import imageConverter from "../../helper/imageConverter";
import fileHelper from "../../helper/file.js";

const NOT_UPLOADED = "NOT_UPLOADED";
const UPLOADING = "UPLOADING";
const UPLOADED = "UPLOADED";

class SimpleUploadField extends Component {
  constructor(props) {
    super(props);

    let fileName;

    if (props.inputData) {
      const inputData = props.inputData.split("/");
      fileName = inputData[inputData.length - 1].split(".")[0];
    }

    this.state = {
      uploadStatus: props.inputData ? UPLOADED : NOT_UPLOADED,
      uploadProgress: 0,
      isPreviewModalOpened: false,
      isCropModalOpened: false,
      uploadCallback: null,
      imageToCropUrl: null,
      initialValueSet: props.inputData ? true : false,
      fileName,
      fileUrl: props.inputData,
    };
  }

  isInvalid() {
    const { inputData } = this.props;

    return !inputData.valid && inputData.touched;
  }

  wrapperClass() {
    const { uploadStatus } = this.state;
    const { inputData, disabled } = this.props;
    const { active, valid, touched } = inputData;
    const invalidClass = this.isInvalid() ? "is-error" : "";
    const focusedClass = active ? "is-focused" : "";
    const validClass =
      uploadStatus === UPLOADED && touched && valid && !active
        ? "is-valid"
        : "";
    const disabledClass = disabled ? "is-disabled" : "";

    return `${invalidClass} ${focusedClass} ${validClass} ${disabledClass}`;
  }

  uploadFieldClass() {
    const { uploadStatus } = this.state;

    if (uploadStatus === UPLOADING) {
      return "is-uploading";
    } else if (uploadStatus === UPLOADED) {
      return "is-uploaded";
    }

    return "";
  }

  componentWillReceiveProps({ inputData }) {
    const { initialValueSet } = this.state;
    const fileUrl = inputData;

    if (inputData && !initialValueSet) {
      const fileName = _.takeRight(fileUrl.split("/"))[0].split(".")[0];
      const uncachedFileUrl = `${fileUrl}?${parseInt(
        Math.random() * 10000,
        10
      )}`;
      this.setState({
        fileName,
        fileUrl: uncachedFileUrl,
        uploadStatus: UPLOADED,
        initialValueSet: true,
      });
    }
  }

  onChange(evt) {
    const {
      uploadCallback,
      inputData: { onChange },
    } = this.props;
    const file = evt.target.files[0];

    const onProgress = (progressEvt) => {
      const progress = Math.round(
        (progressEvt.loaded / progressEvt.total) * 100
      );
      this.setState({ uploadProgress: progress });
    };

    const uploadFile = () => {
      if (file && uploadHelper.isValidPartnerImage(file).valid) {
        this.setState({ uploadStatus: UPLOADING });

        const fileCallback = (e) => {
          const base64 = e.target.result;
          const img = document.createElement("img");
          const doneConverting = (data) => {
            const convertedFile = new File([data.blob], file.name);
            uploadCallback(convertedFile, onProgress).then((_response) => {
              this.setState({
                uploadStatus: UPLOADED,
                fileUrl: data.base64,
                fileName: file.name.split("."),
                uploadProgress: 0,
              });
              const img = document.createElement("img");
              img.src = data.base64;
            });
          };

          img.src = base64;
          imageConverter(img, "png")
            .then(doneConverting)
            .catch((err) => {
              console.log(err);
              alert(
                "Não foi possível fazer upload da image. Por favor, tente novamente"
              );
            });
        };

        fileHelper.getFileDataUrl(file, fileCallback);
      }
    };
    uploadFile();
  }

  renderErrorMessage() {
    const { error } = this.props.inputData;

    if (this.isInvalid()) {
      return <span className="Form-error">{error}</span>;
    }

    return null;
  }

  renderTypeContent() {
    const { type, name } = this.props;
    const { fileUrl } = this.state;
    const width = this.props.width || 400; 
    const height = this.props.height || 200;
    const isUploaded = this.state.uploadStatus === UPLOADED;
    const previewClass = `Form-uploadPreview ${isUploaded ? "is-uploaded" : ""
      }`;

    if (type === "image") {
      const bgStyle = {
        backgroundImage: `url(${fileUrl})`,
        backgroundSize: "cover",
      };

      return (
        <div className={previewClass} style={{ width: width, height: height }}>
          <div className="Form-uploadPreviewImage" style={bgStyle}></div>
          <label className="Form-uploadPreviewEmptyIcon" htmlFor={name}>
            <span className="ss-icon ss-picture"></span>
          </label>
        </div>
      );
    }

    return null;
  }

  renderUploadPreview() {
    const { avatar } = this.props;
    const imageClass = `Form-uploadModalImage ${avatar ? "Form-uploadModalImage--avatar" : ""
      }`;

    return (
      <div className={imageClass}>
        <img src={this.state.fileUrl} />
      </div>
    );
  }

  renderUploadFace() {
    const { name } = this.props;
    const { uploadStatus, uploadProgress, fileName } = this.state;

    if (uploadStatus === UPLOADING) {
      const barStyle = { width: `${uploadProgress}%` };

      return (
        <div className="Form-uploadFace">
          <div className="Form-uploadProgress" style={barStyle}></div>
          <span className="Form-uploadingText">{uploadProgress}%</span>
        </div>
      );
    }

    if (uploadStatus === UPLOADED) {
      return (
        <label htmlFor={name} className="Form-uploadFace">
          <span className="Form-uploadedText">{fileName}</span>
          <span className="Form-uploadIcon ss-icon ss-repeat"></span>
        </label>
      );
    }

    return (
      <div className="Form-uploadFace">
        <label className="Form-uploadLabel" htmlFor={name}>
          <span>UPLOAD</span>&nbsp;
          <span className="Form-uploadIcon ss-icon ss-upload"></span>
        </label>
      </div>
    );
  }

  render() {
    const { label, name, disabled } = this.props;
    const accept = this.props.accept || "";

    return (
      <div
        id={`${name}Wrapper`}
        className={`Form-inputWrapper form-group ${this.wrapperClass()}`}
      >
        <div className="Form-labelWrapper">
          <div className="Form-label">
            {label}
            {this.renderErrorMessage()}
          </div>
        </div>
        {this.renderTypeContent()}
        <div className={`Form-uploadField ${this.uploadFieldClass()}`}>
          {this.renderUploadFace()}
          <input
            type="file"
            disabled={disabled}
            onChange={(evt) => this.onChange(evt)}
            id={name}
            name={name}
            accept={accept}
          />
        </div>

        <Modal
          className="Modal"
          show={this.state.isCropModalOpened}
          onHide={() => this.closeModal()}
        >
          <Modal.Header closeButton>
            <Modal.Title className="Modal-title">Posicionar imagem</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="Form-uploadCropAvatar">
              <AvatarEditor
                image={this.state.imageToCropUrl}
                width={this.props.cropWidth}
                height={this.props.cropHeight}
                border={0}
                scale={this.state.cropScale}
                ref="avatarEditor"
              />
              <div className="Form-submitWrapper">
                <div className="row">
                  <div className="col-sm-12">
                    <button
                      className="Form-submit"
                      type="submit"
                      onClick={() => this.state.uploadCallback()}
                    >
                      <span>Prosseguir</span>
                      <span className="ss-icon ss-picture"></span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={() => this.closeModal()}>Fechar</Button>
          </Modal.Footer>
        </Modal>

        <Modal
          className="Modal"
          show={this.state.isPreviewModalOpened}
          onHide={() => this.closeModal()}
        >
          <Modal.Header closeButton>
            <Modal.Title className="Modal-title">Visualizar Upload</Modal.Title>
          </Modal.Header>
          <Modal.Body>{this.renderUploadPreview()}</Modal.Body>
          <Modal.Footer>
            <Button onClick={() => this.closeModal()}>Fechar</Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

SimpleUploadField.propTypes = {
  name: PropTypes.string,
  type: PropTypes.string,
  inputData: PropTypes.object,
  disabled: PropTypes.bool,
};

export default SimpleUploadField;
