/* global API_ENV */

import money from './money';
import moment from 'moment-timezone';
import { helper } from '@singu/core'

import { connect } from 'react-redux';

const defaultTakeRate = helper.takeRate.getArtistTakeRate({ tier: 'ouro'});
const defaultTakeRateNow = helper.takeRate.getArtistTakeRate({ tier: 'ouro', singuPayment: true });

const scheduleClipboard = {
    getClipboardMessage(data) {
        const {
          scheduledOn,
          serviceName,
          name,
          scheduledRangeStart,
          scheduledRangeEnd,
          originalValue,
          schedule
        } = data;

        let {
            artistValueSinguPayment,
            artistValue,
        } = data;

        const scheduled = scheduledOn.date.format("DD/MM/YYYY");
        const startHour = scheduledRangeStart.date.format("HH:mm");
        const endHour = scheduledRangeEnd.date.format("HH:mm");

        // Gold %
        if(originalValue) {
          artistValueSinguPayment = money.getTakeRate(originalValue, handleTakerate(null, defaultTakeRateNow));
          artistValue = money.getTakeRate(originalValue, handleTakerate(null, defaultTakeRate));
        }


        let { shortCode } = data;
        shortCode ? shortCode = shortCode.toUpperCase() : '';

        const clientName = `*Nome do cliente*: ${name}\n`;
        const codeMsg = `*Código*: ${shortCode}\n`;
        const scheduleMsg = `*Agendamento*: ${scheduled}\n`;
        const rangeHour = startHour === endHour ? `*Disponibilidade*: ${startHour}\n` : `*Disponibilidade início*: ${startHour} até ${endHour}\n`;
        const addressToArtist = `*Endereço*: ${data.address && data.address.place}, ${data.address && data.address.streetNumber} - ${data.address && data.address.neighbor}\n`;
        const cityToArtist = `*Cidade*: ${data.address && data.address.city}\n`;
        const neighborMsg = `*Local*: https://www.google.com/maps/dir/${data.address && data.address.lat},${data.address && data.address.lng}\n`;
        const serviceMsg = `*Serviço*: ${serviceName}\n`;
        const valueToArtistMsg = `*${!!originalValue && 'OURO: '}Pagamento em 14 dias*: R$${artistValue}\n`;
        const valueToArtist = `*${!!originalValue && 'OURO: '}Pagamento na hora*: R$${artistValueSinguPayment}\n`;
        const scheduleLink = `*Link para o pedido*: artist${API_ENV === 'stage' ? '-staging' : ''}://details/${schedule._id}/whatsapp\n`;

        return `${codeMsg}${scheduleMsg}${rangeHour}${clientName}${serviceMsg}${valueToArtistMsg}${valueToArtist}${addressToArtist}${cityToArtist}${neighborMsg}${scheduleLink}`;
    },

    getClipboardMessageRealtime(data) {
        const {
            takeRate,
            serviceName,
            name,
            id
        } = data;

        const originalValue = data.originalValueRealtime;

        // Gold %
        const PARTNER_AMOUNT_NOW = money.getTakeRate(originalValue, handleTakerate(takeRate, defaultTakeRateNow));
        const PARTNER_AMOUNT = money.getTakeRate(originalValue, handleTakerate(takeRate, defaultTakeRate));

        let { shortCode } = data;
        shortCode = shortCode && shortCode.toUpperCase() || '';

        const clientName = `*Nome do cliente*: ${name}\n`;
        const codeMsg = `*Código*: ${shortCode}\n`;
        const addressToArtist = `*Endereço*: ${data.address.place}, ${data.address.streetNumber} - ${data.address.neighbor}\n`;
        const cityToArtist = `*Cidade*: ${data.address.city}\n`;
        const neighborMsg = `*Local*: https://www.google.com/maps/dir/${data.address.lat},${data.address.lng}\n`;
        const serviceMsg = `*Serviço*: ${serviceName}\n`;
        const valueToArtistMsg = `*${!!originalValue && 'OURO: '}Pagamento em 14 dias*: R$${PARTNER_AMOUNT}\n`;
        const valueToArtist = `*${!!originalValue && 'OURO: '}Pagamento na hora*: R$${PARTNER_AMOUNT_NOW}\n`;
        const scheduleLink = `*Link para o pedido*: artist${API_ENV === 'stage' ? '-staging' : ''}://details/${id}/whatsapp\n`;

        return `*SINGU AGORA*\n${codeMsg}${clientName}${serviceMsg}${valueToArtistMsg}${valueToArtist}${addressToArtist}${cityToArtist}${neighborMsg}${scheduleLink}`;
    }
};

function handleTakerate(take, valueDefault) {
    if (take) {
        return moment(take.expiration).isAfter() ? take.value : valueDefault;
    }
    return valueDefault;
}

export const {
    getClipboardMessage,
    getClipboardMessageRealtime
} = scheduleClipboard;

function mapStateToProps(data) {
    return {
        takeRate: data.takeRate.all,
        value: data.takeRate.value.all,
    };
}

export default connect(mapStateToProps)(scheduleClipboard);