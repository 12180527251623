
import Griddle from "griddle-react";
import React, { Component } from "react";
import _ from 'underscore';
import moment from "moment-timezone";
import NotificationSystem from "react-notification-system";
import { Modal, Button } from "react-bootstrap";
import enumHelper from "./../../helper/enumHelper";
import formatter from "./../../helper/formatters";
import SchedulesMetadata from "./SchedulesGriddle_metadata";
import Loading from "./../Global/Loading/Loading";
import ScheduleDetails from "./ScheduleDetails/ScheduleDetails";
import ChangePartner from "./ChangePartner/ChangePartner";
import UpdateDate from "./UpdateDate/UpdateDate";
import UpdateTakeRate from "./UpdateTakeRate/UpdateTakeRate";
import CancelSchedule from "./CancelSchedule/CancelSchedule";
import ChangeVoucher from "./ChangeVoucher/ChangeVoucher";
import ChangeService from "./ChangeService/ChangeService";
import ScheduleRating from './ScheduleDetails/ScheduleRating'
import AddInfraction from './Infraction/AddInfraction'
import axios from '../../helper/axios';
import { CopyToClipboard } from 'react-copy-to-clipboard';

import config from '../../../config.json';
const env = process.env.REACT_APP_ENVIRONMENT || 'stage';

const BACKEND_URL = config.api[env];
const BACKEND_NODE_URL = config.nodeAPI[env];

// styles
import "./SchedulesGriddle.css";
import ScheduleReactivationModalContent from "./Reactivation/ScheduleReactivationModal";

Date.prototype.addHours = function (h) {
  this.setHours(this.getHours() + h);
  return this;
};

const ASC = "ASC";
const DESC = "DESC";

class SchedulesGriddle extends Component {

  constructor(props) {
    super(props);

    var d1 = new Date();
    d1.setDate(d1.getDate() - 5);

    var d2 = new Date();
    d2.setDate(d2.getDate() + 5);

    this.state = {
      schedules: [],
      isDetailsModalOpened: false,
      isActionsModalOpened: false,
      editDisabled: false,
      details: {},
      orderBy: null,
      orderType: ASC,
      filter: null,
      shortCode: null,
      currentPage: 1,
      status: "",
      minDate: d1.toISOString().substr(0, 10),
      maxDate: d2.toISOString().substr(0, 10),
      onlyPreference: false,

      isReactivationModalOpened: false,
      reactivationModalStep: 1,
      reactivationType: "CREDIT_CARD",
      reactivationLoading: false,
      reactivationDetails: {},
    };
  }

  componentWillMount() {
    this.handleProps(this.props);
  }

  componentWillReceiveProps(newProps) {
    this.handleProps(newProps);
  }

  handleProps({ schedules, artists, areas, vouchers }) {
    if (schedules) {
      this.mapSchedules(schedules);
    }

    if (areas) {
      this.mapAreas(areas);
    }

    if (artists) {
      this.setState({ artists });
    }

    if (vouchers) {
      this.setState({ vouchers });
    }
  }

  mapAreas(areas) {
    const reduceAllServices = (prevCategory, currentCategory) => {
      return [
        ...prevCategory,
        ...currentCategory.services.map(service => {
          return {
            categoryId: currentCategory._id,
            ...service
          };
        })
      ];
    };
    const mapAreas = area => {
      return {
        areaId: area._id,
        areaServices: area.categories.reduce(reduceAllServices, [])
      };
    };
    const servicesByEachArea = areas.map(mapAreas);

    this.setState({ servicesByEachArea });
  }

  mapSchedules(schedules) {
    const self = this;
    const mappedSchedules = _.map(schedules, (schedule, i) => {
      const paymentStatus =
        (schedule.payment && schedule.payment.status) ||
        (schedule.payment &&
          schedule.payment.events &&
          schedule.payment.events.length > 0 &&
          schedule.payment.events[0].type) ||
        "N/A";
      const dateFormat = "DD/MM/YYYY HH:mm";
      const tz =
        schedule.area && schedule.area.tz
          ? schedule.area.tz
          : "America/Sao_Paulo";
      const service = schedule.services[0];
      const serviceName = _.isObject(service.name)
        ? service.name["pt-BR"]
        : service.name;
      const serviceId = service ? service._id : null;
      const agent = schedule.userAgent ? schedule.userAgent.toUpperCase().indexOf("IOS") !== -1 ? "iOS" : "android" : "Não identificado";
      const hasVoucher = schedule.voucher && !_.isEmpty(schedule.voucher);
      const tzRequestedOn = moment.tz(schedule.requestedOn, tz);
      const tzScheduledOn = moment.tz(schedule.date, tz);
      const tzScheduledRangeStart = moment.tz(schedule.dateRangeStart || schedule.date, tz);
      const tzScheduledRangeEnd = moment.tz(schedule.dateRangeEnd || schedule.date, tz);

      if (schedule.takeRate && schedule.takeRate.expiration) {
        schedule.takeRate.expiration = moment.tz(schedule.takeRate.expiration, tz);
      }

      function dateToString() {
        // this functions is for sorting purposes
        return this.sortableDate;
      }

      const partner = schedule.partner || {};

      let status = schedule.status[0].description;
      if (schedule.status[0].blame === "INADIMPLENCIA") {
        status += " INADIMPLENCIA";
      }

      return {
        schedule: schedule,
        date: schedule.date,
        services: schedule.services,
        id: schedule._id,
        index: i,
        oldId: schedule.oldId || null,
        requestedOn: {
          date: tzRequestedOn,
          formattedDate: tzRequestedOn.format(dateFormat),
          sortableDate: tzRequestedOn.format(),
          toString: dateToString
        },
        scheduledOn: {
          date: tzScheduledOn,
          formattedDate: tzScheduledOn.format(dateFormat),
          sortableDate: tzScheduledOn.format(),
          toString: dateToString
        },
        scheduleDate: {
          date: tzScheduledOn,
          formattedDate: tzScheduledOn.format(dateFormat),
          sortableDate: tzScheduledOn.format(),
          toString: dateToString
        },
        scheduledRangeStart: {
          date: tzScheduledRangeStart,
          formattedDate: tzScheduledRangeStart.format(dateFormat),
          sortableDate: tzScheduledRangeStart.format(),
          toString: dateToString
        },
        scheduledRangeEnd: {
          date: tzScheduledRangeEnd,
          formattedDate: tzScheduledRangeEnd.format(dateFormat),
          sortableDate: tzScheduledRangeEnd.format(),
          toString: dateToString
        },
        serviceName,
        serviceId,
        agent,
        paymentStatus,
        scheduleDateType: schedule.scheduleDateType,
        name: schedule.user.name,
        email: schedule.user.email,
        userId: schedule.user._id,
        voucher: hasVoucher ? schedule.voucher.code : "Não utilizado",
        phone: formatter.formatPhone(schedule.user.phone),
        address: schedule.address,
        formattedAddress: formatter.formatAddress(schedule.address),
        statusCode: schedule.status[0].code,
        status: status,
        statusHistory:
          schedule.status && schedule.status.length > 0 ? schedule.status : [],
        artistEvents:
          schedule.artistEvents && schedule.artistEvents.length > 0
            ? schedule.artistEvents
            : [],
        artistsHistory:
          schedule.artistsHistory && schedule.artistsHistory.length > 0
            ? schedule.artistsHistory
            : [],
        creditCardValidated: schedule.creditCardValidated,
        rule: schedule.rule,
        tz: tz,
        numberOfClients: schedule.numberOfClients || 1,
        partnerId: partner._id,
        partnerName: partner.name,
        partnerEmail: partner.email,
        takeRate: schedule.takeRate,
        partnerPhone: formatter.formatPhone(partner.phone),
        partnerSinguPayment: partner.singuPayment,
        actions: {
          openDetailsModal: self.openDetailsModal.bind(self),
          openRatingModal: self.openRatingModal.bind(self),
          openSuggestionModal: self.openSuggestionModal.bind(self),
          openActionsModal: self.openActionsModal.bind(self),
          rechargeSchedule: self.props.rechargeScheduleAction,
          openReactivationModal: self.openReactivationModal.bind(self)
        },
        shortCode: schedule.shortCode || null,
        payment: {
          id: schedule.payment ? schedule.payment.id : 'Não existente',
          status: schedule.payment ? schedule.payment.status : 'Não existente',
          copyPaste: schedule.payment ? schedule.payment.copyPaste : null,
          paid: schedule.payment && schedule.payment.paid ? schedule.payment.paid : false,
          method: schedule.payment && schedule.payment.method ? schedule.payment.method : false
        },
        transfer: {
          id: schedule.transfer ? schedule.transfer.id : 'Não existe',
          status: schedule.transfer ? schedule.transfer.status : 'Não existe',
          amount: schedule.transfer ? schedule.transfer.amount / 100 : '-'
        },
        originalValue: schedule.originalValue,
        totalValue: schedule.totalValue,

        discount: schedule.discount,
        voucherDiscount: schedule.voucherDiscount,
        singuCreditsUsed: schedule.singuCreditsUsed,
        infractionClientDiscount: schedule.infractionClientDiscount,

        preference: schedule.hasPreference === true,
        originalValuePreference: schedule.originalValuePreference,
        totalValuePreference: schedule.totalValuePreference,
        discountPreference: schedule.discountPreference,
        voucherDiscountPreference: schedule.voucherDiscountPreference,
        singuCreditsUsedPreference: schedule.singuCreditsUsedPreference,
        infractionClientDiscountPreference: schedule.infractionClientDiscountPreference,

        serviceValue: schedule.serviceValue,
        serviceValuePreference: schedule.serviceValuePreference,

        pixPayments: schedule.pixPayments,

        cancelValue: schedule.cancelValue || {},
        infractionProfessionalDiscount: schedule.infractionProfessionalDiscount || 0,
        infractionProfessionalDiscountId: schedule.infractionProfessionalDiscountId || 0,
        infractionHistory: schedule.infractionHistory || {},
        artistValue: schedule.artistValue,
        artistValueSinguPayment: schedule.artistValueSinguPayment,
        alerts: schedule.alerts && schedule.alerts.length > 0 ? schedule.alerts : [],
        dateHistory: schedule.dateHistory || [],
        takeRateHistory: schedule.takeRateHistory || [],
        comments: schedule.comments || [],
        reward: schedule.reward,
        rewardReceived: schedule.rewardReceived,
        professionalPreferences: schedule.professionalPreferences || [],
        onlyForPreference: schedule.onlyForPreference,
        tipValue: schedule.tipValue,
        tipPayment: schedule.tipPayment,
        note: schedule.note
      };
    });

    this.setState({ schedules: mappedSchedules });
  }

  notify(message, level) {
    const title = level === "success" ? "Sucesso" : "Erro";
    this.refs.notificationSystem.addNotification({
      title,
      message,
      level,
      position: "tc"
    });
  }

  openActionsModal(rowData) {
    this.setState({
      isActionsModalOpened: true,
      details: rowData
    });
  }

  openDetailsModal(rowData) {
    this.setState({
      isDetailsModalOpened: true,
      details: rowData
    });
  }

  openRatingModal(rowData) {
    this.setState({
      isRatingModalOpened: true,
      details: rowData
    }, this.getScheduleRating);
  }

  openSuggestionModal(rowData) {
    this.setState({
      isSuggestionModalOpened: true,
      details: rowData
    });
  }

  onDeleteRating() {
    this.closeModal();

    const { details } = this.state;
    details.ratings = [];
    this.setState({
      ratingLoading: false,
      details
    })
  }

  getScheduleRating() {
    const { details } = this.state;
    this.setState({
      ratingLoading: true
    }, () => {
      axios.get(`${BACKEND_URL}/v2/bo/rating/schedule/${details.id}`).then(response => {
        details.ratings = response.data.filter(v => v.valid === true);
        this.setState({
          ratingLoading: false,
          details
        });
      })
    })
  }

  handleChangeReactivationModalType(newType) {
    this.setState({
      reactivationType: newType
    });
  }

  openReactivationModal(rowData) {
    this.setState({
      isReactivationModalOpened: true,
      details: rowData
    });
  }

  increaseReactivationModalStep(scheduleId) {
    const step = this.state.reactivationModalStep + 1;

    if (step === 3) {
      this.setState({
        reactivationLoading: true,
      });

      this.handleReactivationSchedule(scheduleId);
    }

    this.setState({
      reactivationModalStep: step
    });
  }

  startReactivationPolling(scheduleId) {
    this.reactivationPolling = setInterval(() => {
      this.getReactivationDetails(scheduleId);
    }, 2000);
  }

  getReactivationDetails(scheduleId) {
    try {
      axios.get(`${BACKEND_NODE_URL}/v4/schedule/reactivation/status/${scheduleId}`).then(response => {
        if (response.data.status === "SUCCESS" || response.data.status === "FAILED") {
          clearInterval(this.reactivationPolling);
          this.increaseReactivationModalStep();
          this.setState({
            reactivationLoading: false,
            reactivationDetails: response.data
          })
        }

      })
    } catch (error) {
      console.log("error =>", error);
    }
  }

  closeModal() {
    this.setState({
      isRatingModalOpened: false,
      isDetailsModalOpened: false,
      isActionsModalOpened: false,
      isSuggestionModalOpened: false,
      isReactivationModalOpened: false,
      reactivationModalStep: 1,
      reactivationLoading: false
    });
    clearInterval(this.reactivationPolling);
  }

  handleReactivationSchedule(scheduleId) {
    axios.post(`${BACKEND_NODE_URL}/v4/schedule/reactivation`, {
      scheduleId,
      reactivationType: this.state.reactivationType,
    }).then(response => {
      if (response.status === 200) {
        this.startReactivationPolling(scheduleId);
      }
    }).catch(error => {
      const errorMessage = error?.response?.data?.error?.description || "Ocorreu um erro ao tentar reativar esse agendamento";
      this.notify(errorMessage, 'error');

      setTimeout(() => {
        this.closeModal();
      }, 2000);
    });
  }

  copyToClipboardClick() {
    this.notify('Informações copiadas com sucesso.', 'success');
  }

  getSchedules = () => {
    let {
      currentPage,
      orderBy,
      orderType,
      filter,
      minDate,
      maxDate,
      status,
      area,
      shortCode,
      onlyPreference,
    } = this.state;
    this.props.getSchedules(currentPage, {
      orderBy,
      orderType,
      filter,
      minDate,
      maxDate,
      status,
      area,
      shortCode,
      onlyPreference
    });
  };

  getPage = nextPage => {
    const currentPage = nextPage + 1;

    this.setState({ currentPage }, this.getSchedules);
  };

  sort = orderBy => {
    let orderType = ASC;

    if (this.state.orderBy === orderBy) {
      orderType = this.state.orderType === ASC ? DESC : ASC;
    }

    this.setState(
      {
        orderBy,
        orderType
      },
      this.getSchedules
    );
  };

  filter = filter => {
    this.setState(
      {
        filter
      },
      this.getSchedules
    );
  };

  _handleMinDateChange = e => {
    this.setState({ minDate: e.target.value });
  };

  _handleMaxDateChange = e => {
    this.setState({ maxDate: e.target.value });
  };

  _handleClick = () => {
    this.setState({ currentPage: 1 }, this.getSchedules);
  };

  _handleOptionSelected = e => {
    this.setState({ status: e.target.value });
  };

  _handleAreaSelected = e => {
    this.setState({ area: e.target.value });
  };

  _handleOnlyPreferenceChange = () => {
    this.setState({ onlyPreference: !this.state.onlyPreference });
  };

  _handleShortCodeChange = e => this.setState({ shortCode: e.target.value });

  _handleChange = e => {
    var filterData = e.target.value;
    var re = /^[0-3]?[0-9].[0-3]?[0-9].(?:[0-9]{2})?[0-9]{2}$/;

    if (filterData.match(re)) {
      var dateSplited = filterData.split("/");
      filterData = dateSplited[2] + "-" + dateSplited[1] + "-" + dateSplited[0];
    }

    this.setState({ filter: filterData });
  };

  _handleKeyPress(e) {
    if (e.charCode === 13) {
      this._handleClick();
    }
  }

  generateButtonText() {
    if (this.state.reactivationModalStep === 1) return 'Reativar pedido';
    if (this.state.reactivationModalStep === 2 && this.state.reactivationType === "PIX") return 'Gerar código PIX';
    if (this.state.reactivationModalStep === 2 && this.state.reactivationType === "CHECKOUT") return 'Gerar link de pagamento';
    return 'Reativar pedido';
  }

  render() {
    const { schedules } = this.state;

    let areas = [];

    if (this.props.areas && this.props.areas.length > 0) {
      this.props.areas.forEach((data, index) => {
        areas.push(
          <option value={data._id} key={index}>
            {data.city}
          </option>
        );
      });
    }

    return (
      <div className="Schedules">
        <div className="row">
          <center className="Filter">
            <b style={{ marginLeft: 10, marginRight: 10 }}>CÓDIGO:</b>{" "}
            <input
              onKeyPress={this._handleKeyPress.bind(this)}
              value={this.state.shortCode}
              onChange={this._handleShortCodeChange}
              width="150px"
              placeholder="Ex: ABCD"
              className="form-control"
            />
            <b style={{ marginLeft: 10, marginRight: 10 }}>Status:</b>
            <select
              onChange={this._handleOptionSelected}
              className="form-control"
            >
              <option value=""> Todos</option>
              <option value="0">
                {" "}
                {enumHelper.scheduleStatusEnum.orderCreated.name}{" "}
              </option>
              <option value={enumHelper.scheduleStatusEnum.creditCardConfirmation.value}>
                {" "}
                {enumHelper.scheduleStatusEnum.creditCardConfirmation.name}{" "}
              </option>
              <option value={enumHelper.scheduleStatusEnum.waitingAutomaticAllocation.value}>
                {" "}
                {enumHelper.scheduleStatusEnum.waitingAutomaticAllocation.name}
              </option>
              <option value={enumHelper.scheduleStatusEnum.paymentError.value}>
                {" "}
                {enumHelper.scheduleStatusEnum.paymentError.name}{" "}
              </option>
              <option value={enumHelper.scheduleStatusEnum.waitingAccept.value}>
                {" "}
                {enumHelper.scheduleStatusEnum.waitingAccept.name}
              </option>
              <option value={enumHelper.scheduleStatusEnum.accepted.value}>
                {" "}
                {enumHelper.scheduleStatusEnum.accepted.name}
              </option>
              <option value={enumHelper.scheduleStatusEnum.cancelByBackOffice.value}>
                {" "}
                {enumHelper.scheduleStatusEnum.cancelByBackOffice.name}
              </option>
              <option value={enumHelper.scheduleStatusEnum.cancelByUser.value}>
                {" "}
                {enumHelper.scheduleStatusEnum.cancelByUser.name}{" "}
              </option>
              <option value={enumHelper.scheduleStatusEnum.finished.value}>
                {" "}
                {enumHelper.scheduleStatusEnum.finished.name}{" "}
              </option>
            </select>
            <b style={{ marginLeft: 10, marginRight: 10 }}>Estados:</b>
            <select
              onChange={this._handleAreaSelected}
              className="form-control"
            >
              <option value="">Todos</option>
              {areas}
            </select>
            <b style={{ marginLeft: 10, marginRight: 10 }}>Outros:</b>{" "}
            <input
              onKeyPress={this._handleKeyPress.bind(this)}
              value={this.state.filter}
              onChange={this._handleChange}
              type="text"
              width="300px"
              placeholder="Nome, voucher, etc.."
              className="form-control"
            />
            <br /> <br />
            <b style={{ marginLeft: 10, marginRight: 10 }}>
              Agendado entre:
            </b>{" "}
            <input
              name="minDate"
              onChange={this._handleMinDateChange}
              type="date"
              width="300px"
              value={this.state.minDate}
              className="form-control"
            />
            <input
              name="maxDate"
              onChange={this._handleMaxDateChange}
              type="date"
              width="300px"
              value={this.state.maxDate}
              className="form-control"
              style={{ marginLeft: 10 }}
            />
            <b style={{ marginLeft: 10 }}>Somente preferencia </b><input
              name="onlyPreference"
              onChange={this._handleOnlyPreferenceChange}
              type="checkbox"
              value={this.state.onlyPreference}
            />
            <button
              className="btn-info  btn btn-default"
              style={{ marginLeft: 10 }}
              onClick={this._handleClick}
            >
              <b>BUSCAR</b>
            </button>
          </center>

          {this.props.loading ? (
            <Loading />
          ) : (
            <Griddle
              ref="Griddle"
              noDataMessage="Nenhum dado foi encontrado com o filtro realizado, verifique novamente se o pedido se encontra no intervalo de datas correto ou se as informações passadas nos filtro estão corretas"
              results={schedules}
              columnMetadata={SchedulesMetadata.column}
              rowMetadata={SchedulesMetadata.row}
              resultsPerPage={50}
              useFixedLayout={false}
              tableClassName={"GriddleTable table"}
              useGriddleStyles={false}
              columns={[
                "serviceName",
                "name",
                "partnerName",
                "voucher",
                "status",
                "requestedOn",
                "scheduleRangeEnd",
                "scheduleDate",
                "shortCode",
                "id",
                "actions"
              ]}
              useExternal
              externalSetFilter={() => { }}
              externalSetPageSize={15}
              externalChangeSort={this.sort}
              externalSetPage={this.getPage}
              externalCurrentPage={this.props.currentPage - 1}
              externalMaxPage={this.props.numberOfPages}
            />
          )}
        </div>

        <br />

        <NotificationSystem ref="notificationSystem" />
        <Modal
          className="Modal"
          modalDetails={this.state.details}
          show={this.state.isActionsModalOpened}
          onHide={() => this.closeModal()}
        >
          <Modal.Header closeButton>
            <Modal.Title className="Modal-title">
              Ações do agendamento
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            { }
            <div className="row">
              {this.state.details && this.state.details.partnerId ? <AddInfraction details={this.state.details} /> : ""}
            </div>
            <div className="Modal-section clearfix">
              <div className="Modal-sectionTitle">Trocar profissional</div>
              <ChangePartner
                scheduleId={this.state.details.id}
                actualPartner={{
                  _id: this.state.details.partnerId,
                  email: this.state.details.partnerEmail,
                  name: this.state.details.partnerName,
                  formattedPhone: this.state.details.partnerPhone
                }}
                notify={(msg, lvl) => this.notify(msg, lvl)}
                updateCallback={this.props.updatePartnerAction}
                removeCallback={this.props.removePartnerAction}
              />
            </div>

            <div className="Modal-section clearfix">
              <div className="Modal-sectionTitle">
                Trocar data do agendamento
              </div>
              <UpdateDate
                rowData={this.state.details}
                notify={(msg, lvl) => this.notify(msg, lvl)}
                updateCallback={this.props.updateDateAction}
              />
            </div>

            <div className="Modal-section clearfix">
              <div className="Modal-sectionTitle">
                TROCAR % A RECEBER DA SINGU
              </div>
              <UpdateTakeRate
                rowData={this.state.details}
                notify={(msg, lvl) => this.notify(msg, lvl)}
                updateCallback={this.props.updateTakeRateAction}
              />
            </div>

            <div className="Modal-section clearfix">
              <div className="Modal-sectionTitle">Trocar serviço</div>
              <ChangeService
                rowData={this.state.details}
                servicesByEachArea={this.state.servicesByEachArea}
                notify={(msg, lvl) => this.notify(msg, lvl)}
                updateCallback={this.props.updateServiceAction}
              />
            </div>

            <div className="Modal-section clearfix">
              <div className="Modal-sectionTitle">Trocar voucher</div>
              <ChangeVoucher
                scheduleId={this.state.details.id}
                actualVoucherCode={this.state.details.voucher}
                notify={(msg, lvl) => this.notify(msg, lvl)}
                updateCallback={this.props.updateVoucherAction}
              />
            </div>

            <div className="Modal-section clearfix">
              <div className="Modal-sectionTitle">Alterar status</div>
              <CancelSchedule
                rowData={this.state.details}
                cancelReasons={this.props.cancelReasons}
                notify={(msg, lvl) => this.notify(msg, lvl)}
                cancelCallback={this.props.cancelScheduleAction}
              />
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={() => this.closeModal()}>Fechar</Button>
          </Modal.Footer>
        </Modal>

        <Modal
          className="Modal Modal--large"
          rowData={this.state.details}
          show={this.state.isDetailsModalOpened}
          onHide={() => this.closeModal()}
        >
          <Modal.Header closeButton>
            <Modal.Title className="Modal-title">
              Detalhes do agendamento
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <ScheduleDetails
              rowData={this.state.details}
              notify={(msg, lvl) => this.notify(msg, lvl)}
              dataConfirmationAction={this.props.dataConfirmationAction}
              updateCallback={this.props.updateAddressAction}
              addCommentAction={this.props.addCommentAction}
            />
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={() => this.closeModal()}>Fechar</Button>
          </Modal.Footer>
        </Modal>

        <Modal
          rowData={this.state.details}
          className="Modal Modal--medium"
          show={this.state.isRatingModalOpened}
          onHide={() => this.closeModal()}
        >
          <Modal.Header closeButton>
            <Modal.Title className="Modal-title">
              Avalições do Agendamento
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <ScheduleRating
              rowData={this.state.details}
              ratings={this.state.details.ratings || []}
              onSuccess={() => this.onDeleteRating()}
            />
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={() => this.closeModal()}>Fechar</Button>
          </Modal.Footer>
        </Modal>

        <Modal
          rowData={this.state.details}
          className="Modal-Reactivation"
          show={this.state.isReactivationModalOpened}
          onHide={() => this.closeModal()}
        >
          <Modal.Header closeButton>
            <Modal.Title className="Modal-title">
              Reativação de pedido
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <ScheduleReactivationModalContent
              step={this.state.reactivationModalStep}
              reactivationType={this.state.reactivationType}
              handleChangeReactivationType={(newType) => this.handleChangeReactivationModalType(newType)}
              reactivationLoading={this.state.reactivationLoading}
              reactivationDetails={this.state.reactivationDetails}
              notify={(msg, lvl) => this.notify(msg, lvl)}
            />
          </Modal.Body>
          <Modal.Footer>
            <Button
              onClick={() => this.closeModal()}
              style={{
                backgroundColor: "#6C757D",
                color: "#FFF"
              }}
            >
              Cancelar
            </Button>

            {(this.state.reactivationModalStep === 1 || this.state.reactivationModalStep === 2) && (
              <Button
                onClick={() => this.increaseReactivationModalStep(this.state.details.id)}
                style={{
                  backgroundColor: "#0D6EFD",
                  color: "#FFF",
                  cursor: "pointer"
                }}
                disabled={this.state.reactivationLoading}
              >
                  {this.generateButtonText()}
              </Button>
            )}

            {(this.state.reactivationModalStep === 4 && this.state.reactivationType === "CREDIT_CARD") && (
              <Button
                onClick={() => this.closeModal()}
                style={{
                  backgroundColor: "#0D6EFD",
                  color: "#FFF",
                  cursor: "pointer"
                }}
                disabled={this.state.reactivationLoading}
              >
                  Entendi
              </Button>
            )}

            {this.state.reactivationModalStep === 4
            && this.state.reactivationType === "PIX"
            && this.state.reactivationDetails.status === "SUCCESS" && (
              <CopyToClipboard
                text={this.state.reactivationDetails.copyAndPasteCode}
                onCopy={() => this.copyToClipboardClick()}
              >
                <Button
                  style={{
                    backgroundColor: "#0D6EFD",
                    color: "#FFF",
                    cursor: "pointer"
                  }}
                  title="Copiar linha"
                >
                  Copiar código PIX
                </Button>
              </CopyToClipboard>
            )}

            {this.state.reactivationModalStep === 4
            && this.state.reactivationType === "CHECKOUT"
            && this.state.reactivationDetails.status === "SUCCESS" && (
              <CopyToClipboard
                text={this.state.reactivationDetails.linkPayment}
                onCopy={() => this.copyToClipboardClick()}
              >
                <Button
                  style={{
                    backgroundColor: "#0D6EFD",
                    color: "#FFF",
                    cursor: "pointer"
                  }}
                  title="Copiar linha"
                >
                  Copiar link de pagamento
                </Button>
              </CopyToClipboard>
            )}
          </Modal.Footer>
        </Modal>

      </div>
    );
  }
}

export default SchedulesGriddle;
