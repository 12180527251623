const REASONS = [
  { id: 1, description: "Bônus retorno cliente novo" },
  { id: 32, description: "Pagamento por pedido cancelado inadimplencia" },
  { id: 33, description: "Projeto de alocação" },
  { id: 34, description: "Projeto Espaço Físico" },
  { id: 35, description: "Projeto Espaço Físico NASP" },
  { id: 36, description: "Projeto Espaço Físico CAJAMAR" },
  { id: 2, description: "Adiantamento diária" },
  { id: 3, description: "Bônus diária artista" },
  { id: 4, description: "Bônus qtdd pedidos" },
  { id: 5, description: "Bônus engajamento - Recrutamento" },
  { id: 6, description: "Bônus indicação - Recrutamento" },
  { id: 7, description: "Bônus recorrência" },
  { id: 8, description: "Bônus corrida Now" },
  { id: 9, description: "Complemento 100% - Pedido Planejado" },
  { id: 10, description: "Complemento 100% - Pedido Now" },
  { id: 11, description: "Complemento combo - Pedido Planejado" },
  { id: 12, description: "Complemento diária Now" },
  { id: 13, description: "Complemento diária Singu Planejado" },
  { id: 14, description: "Complemento mãos de inverno" },
  { id: 15, description: "Evento Marketing" },
  { id: 16, description: "Erro Operação" },
  { id: 17, description: "Pagamento artista B2b" },
  { id: 18, description: "Pagamento material - Bolsa" },
  { id: 19, description: "Pagamento material - Camiseta" },
  { id: 20, description: "Pagamento material - Outros" },
  { id: 21, description: "Treinamento Qualidade" },
  { id: 22, description: "Treinamento Responsabilidade" },
  { id: 24, description: "Adiantamento Covid-19" },
  { id: 25, description: "Captação de LEAD" },
  { id: 26, description: "Captação de Cliente" },
  { id: 27, description: "Bônus taxa de qualidade" },
  { id: 28, description: "Bônus Qualidade" },
  { id: 29, description: "Papel Complementar" },
  { id: 30, description: "Micro crédito" },
  { id: 31, description: "Crédito consultora Natura" },
  { id: 23, description: "Outros" },
];

module.exports = {
  REASONS
};