import config from '../../../config.json';
const env = process.env.REACT_APP_ENVIRONMENT || 'stage';
const BACKEND_URL = config.api[env];
import axios from 'axios';

import { getToken } from '../../models/auth';

export function updateNeighborServicePrice(priceId, data) {
  return axios.put(`${BACKEND_URL}/dynamic_price/neighbor/${priceId}`, data, {
    headers: { Authorization: `Bearer ${getToken()}` },
  });
}