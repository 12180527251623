import { instanceOf } from 'prop-types';
import React, { Component, PropTypes } from 'react';
import ReactDOM from 'react-dom';
import VMasker from 'vanilla-masker';

class TextField extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      isValid: true
    };
  }

  componentDidMount() {
    const { mask, currency, number, inputData } = this.props;
    const input = ReactDOM.findDOMNode(this.refs.input);

    if (mask) {
      if (Array.isArray(mask)) return VMasker(input).maskPattern(mask[1]);
      VMasker(input).maskPattern(mask);
    } else if (currency) {
      inputData.onChange('0,00');
      VMasker(input).maskMoney(currency);
    }
  }

  componentWillReceiveProps(newProps) {
    const { isLoading } = newProps;
    const { currency, percentage, inputData } = this.props;
    const input = ReactDOM.findDOMNode(this.refs.input);

    if (!newProps.currency && currency) {
      VMasker(input).unMask();
    } else if (newProps.currency && !currency) {
      inputData.onChange('0,00');
      VMasker(input).maskMoney(currency);
    }
    if (newProps.percentage && !percentage) {
      VMasker(input).unMask();
      inputData.onChange(this.formatPercentage(inputData.value || ''));
    } else if (!newProps.percentage && percentage) {
      inputData.onChange((inputData.value && inputData.value.replace('%', '')) || '');
    }

    this.setState({ isLoading });
  }

  isInvalid() {
    const { inputData } = this.props;

    return (!inputData.valid && inputData.touched);
  }

  wrapperClass() {
    const { inputData, disabled } = this.props;
    const { active, valid, dirty } = inputData;
    const invalidClass = this.isInvalid() ? 'is-error' : '';
    const focusedClass = active ? 'is-focused' : '';
    const validClass = (dirty && valid && !active) ? 'is-valid' : '';
    const disabledClass = disabled ? 'is-disabled' : '';

    return `${invalidClass} ${focusedClass} ${validClass} ${disabledClass}`;
  }

  renderErrorMessage() {
    const { error } = this.props.inputData || {};

    if (this.isInvalid() || !this.state.isValid) {
      return (<span className="Form-error">{error}</span>);
    }

    return null;
  }

  spinnerClass() {
    const { isLoading } = this.state;

    return isLoading ? 'is-visible' : '';
  }

  formatPercentage(rawValue) {
    if (rawValue) {
      let value = rawValue;

      value = value.replace(/[^\d]/g, '');
      value = value.indexOf('0') === 0 ? value.replace('0', '') : value;

      if (value > 100) {
        value = 100;
      } else if (value < 0) {
        value = 0;
      }

      return `${value}%`;
    }

    return rawValue;
  }

  onChange(evt) {
    const { mask, name, percentage, inputData, replace, maxlength } = this.props;
    let value = evt.target.value;

    const input = ReactDOM.findDOMNode(this.refs.input);

    if (percentage) {
      this.formatPercentage(value);
    }

    if (replace) {
      value = replace(value);
    }

    if (maxlength && value.length > maxlength) {
      value = value.substr(0, maxlength);
    }

    if (name === 'phone') {
      VMasker(input).unMask();
      value = value.replace(/\D/g, '');
      const isFullPhone = value.length > 11 ? 1 : 0;

      VMasker(input).maskPattern(mask[isFullPhone]);
      value = VMasker.toPattern(value, mask[isFullPhone]);
    }

    inputData.onChange(value);
  }

  testRegex(evt) {
    const { regex } = this.props;

    if (regex) {
      const { rule, setInputValidation } = regex;

      const value = evt.target.value;
      const validation = new RegExp(rule);

      if (value && !validation.test(value)) {
        this.setState({isValid: false});
        setInputValidation(false);
        return;
      }

      setInputValidation(true);
    }
  }

  render() {
    const { name, label, inputData, placeholder, disabled, number } = this.props;

    return (
      <div id={`${name}Wrapper`}
        className={`Form-inputWrapper form-group ${this.wrapperClass()} ${!this.state.isValid && 'is-error'}`}
      >
        <div className="Form-labelWrapper">
          <label className="Form-label" htmlFor={name}>
            {label}{this.renderErrorMessage()}
          </label>
          <span className={`Form-fieldSpinner ss-icon ss-loading ${this.spinnerClass()}`}></span>
        </div>
        <input
          className="Form-input"
          placeholder={placeholder}
          id={name}
          ref="input"
          type={ number && 'number' || 'text' }
          {...inputData}
          onChange={evt => this.onChange(evt)}
          value={inputData.value}
          disabled={disabled}
          onFocus={() => this.setState({isValid: true})}
          onBlur={(evt) => this.testRegex(evt)}
        />
      </div>
    );
  }
}

TextField.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  mask: PropTypes.string,
  placeholder: PropTypes.string,
  inputData: PropTypes.object,
  disabled: PropTypes.bool,
  regex: instanceOf(RegExp)
};

export default TextField;
