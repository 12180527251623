import React, { Component } from "react";

import Loading from "../Global/Loading/Loading";
import ServiceEdit from "./ServiceEdit";
export default class EditServiceComponent extends Component {

  render() {
    return (
      <div className="row">
        <div className="col-md-12">
          {!this.props || (!this.props.services && !this.props.neighbors) ? (
            <Loading />
          ) : (
            <ServiceEdit 
              services={this.props && this.props.services} 
              neighbors={this.props && this.props.neighbors}
            />
          )}
        </div>
      </div>
    );
  }
}
