export function hasNullValue(obj) {
  const keys = Object.keys(obj);

  const nullList = keys
                    .filter(key => obj[key] === null )
                    .map(key => ({ element: key, value: obj[key]}))

  if(nullList.length === 0) return false;

  return nullList
}

export function downloadBlobResponse(response, filename) {
  const { data } = response; 

  const url = window.URL.createObjectURL(new Blob([data]));
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', filename);
  document.body.appendChild(link);
  link.click();
}