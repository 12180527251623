import { backendAPI } from '../../helper/apis'

export const NEW_ARTIST = 'NEW_ARTIST';

export function newArtist(data) {
  if (data.singuPayment) {
    data.smartMEI = false
  }

  const newArtistPromise = backendAPI.post(`/v2/bo/artists`, data);

  return {
    type: NEW_ARTIST,
    payload: newArtistPromise
  };
}

