import axios from 'axios';
import config from '../../../config.json';
const env = process.env.REACT_APP_ENVIRONMENT || 'stage';
const BACKEND_URL = config.api[env];
import { getToken } from './../../models/auth';

export const FETCH_ARTIST = 'FETCH_ARTIST';

export function fetchArtist(id) {
    const artistsPromise = axios.get(`${BACKEND_URL}/v2/bo/artist/${id}`, {
        headers: { Authorization: `Bearer ${getToken()}` }  
    }) ;
    return {
        type: FETCH_ARTIST,
        payload: artistsPromise
    };
}
