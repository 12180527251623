import axios from 'axios';
import config from '../../../config.json';
const env = process.env.REACT_APP_ENVIRONMENT || 'stage';
const ARTIST_API_URL = config.artistApi[env];

export function createAccount(artistId) {
    const BASE_ARTIST_URL = ARTIST_API_URL;

    return axios.post(`${BASE_ARTIST_URL}/moip/create-account/${artistId}`, {}, {
        headers: {
            'Content-Type': 'application/json'
        }
    });
}