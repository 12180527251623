import axios from 'axios';
import config from '../../../config.json';
const env = process.env.REACT_APP_ENVIRONMENT || 'stage';
const BACKEND_URL = config.api[env];

import { getToken } from './../../models/auth';

export const CANCEL_REALTIME = 'CANCEL_REALTIME_ORDERS';

export function cancelRealTimeOrder(scheduleId) {
    const cancelPromise = axios.put(`${BACKEND_URL}/v2/bo/order/realtime/${scheduleId}/cancel`, {}, {
        headers: { Authorization: `Bearer ${getToken()}` }
    });

    return {
        type: CANCEL_REALTIME,
        payload: cancelPromise,
        meta: { id: scheduleId }
    };
}
