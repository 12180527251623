import React from 'react';
import { Button } from 'react-bootstrap';
import moipImage from '../../images/moip.jpg';
import "./ArtistActions.css";

const ArtistActions = props => {
  return (
    <div className="ArtistActions">
      <div className="ArtistActions-btnWrapper">
      <Button onClick={() => props.rowData.actions.artist(props.rowData)}
          className="ArtistActions-btn btn btn-info"
          title="Editar profissional"
          style={{ height: 40, width: 54 }}>
          <span className="glyphicon glyphicon-info-sign"></span>
        </Button>
        <Button onClick={() => props.rowData.actions.editArtist(props.rowData)}
          className="ArtistActions-btn btn btn-info"
          title="Editar profissional"
          style={{ height: 40, width: 54 }}>
          <span className="glyphicon glyphicon-edit"></span>
        </Button>
        
      </div>
      <div className="ArtistActions-btnWrapper">
      <Button onClick={() => props.rowData.actions.addInfraction(props.rowData)}
          className="ArtistActions-btn btn btn-info"
          title="Infrações"
          style={{ height: 40, width: 54 }}>
          <span className="glyphicon glyphicon-warning-sign"></span>
        </Button>
        <Button onClick={() => props.rowData.actions.openMoipModal(props.rowData)}
          className="ArtistActions-btn btn btn-info"
          style={{ backgroundColor: '#c1c0c0 !important' }}
          title="Criar conta Moip">
          <span><img src={moipImage} height={30}></img></span>
        </Button>
      </div>
      <div className="ArtistActions-btnWrapper">
      </div>
    </div>
  );
};

export default ArtistActions;
