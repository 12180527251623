import config from '../../../config.json';
const env = process.env.REACT_APP_ENVIRONMENT || 'stage';
const BACKEND_URL = config.api[env];
import axios from 'axios';

import { getToken } from './../../models/auth';

export function getService(serviceId) {
  return axios.get(`${BACKEND_URL}/services/${serviceId}`, {
    headers: { Authorization: `Bearer ${getToken()}` },
  });
}
