import React, { Component } from "react";
import { Button } from "react-bootstrap";
import NewInfractionModal from "../../../containers/Infractions/NewInfractionModal";

export default class AddInfraction extends Component {
  constructor(props) {
    super(props);
    const { schedule } = this.props.details;
    this.state = { schedule: schedule || {}, artist: schedule.partner || {} };
  }

  componentWillReceiveProps(newProps) {
    this.props = newProps;
    const { schedule } = this.props.details;
    this.state = { schedule: schedule || {}, artist: schedule.partner || {} };
  }

  openNewInfractionModal() {
    this.setState({
      modalOpen: true
    });
  }

  closeModal() {
    this.setState({
      modalOpen: false
    });
  }
  render() {
    return (
      <div className="col-xs-12">
        <Button onClick={this.openNewInfractionModal.bind(this)} style={{marginBottom: "15px", textAlign: "center", width: "100%"}}>
          Nova Infração para "{this.state.artist.name}" no agendamento "{this.state.schedule._id}"
        </Button>
        <NewInfractionModal
          artist={this.state.artist}
          schedule={this.state.schedule}
          modalOpen={this.state.modalOpen}
          closeModal={this.closeModal.bind(this)}
        />
      </div>
    );
  }
}
