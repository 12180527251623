import React from "react"


export default function ({ onChange, checked, text, value }) {
  return (
    <div
      className={`Form-choice ${checked ? "is-selected" : ""}`}
      key={value}
    >
      <label
        htmlFor={`${value}`}
        className="Form-choiceLabel"
        key={value}
      >
        <input
          type="checkbox"
          className="Form-checkbox"
          name={value}
          value={value}
          id={`${value}`}
          onChange={evt => onChange(evt.target.value)}
        />
        <div className="Form-fakeCheckbox">
          <div className="ss-icon ss-check"></div>
        </div>
        {text}
      </label>
    </div>
  )
}