import axios from 'axios';
import config from '../../config.json';
const env = process.env.REACT_APP_ENVIRONMENT || 'stage';
const BACKEND_URL = config.api[env];

import { getToken } from '../models/auth';


const getScheduleById = (scheduleId) => {
    const schedulePromise = axios.get(`${BACKEND_URL}/v2/bo/schedule/${scheduleId}`, {
        headers: { Authorization: `Bearer ${getToken()}` }
    });

    return schedulePromise;
}

export default getScheduleById


export const getScheduleByIdV3 = async (scheduleId) => {
    const {data} = await axios.get(`${BACKEND_URL}/v3/bo/schedule/${scheduleId}`, {
        headers: { Authorization: `Bearer ${getToken()}` }
    });
    
    return data;
}