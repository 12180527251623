import config from '../../../config.json';
const env = process.env.REACT_APP_ENVIRONMENT || 'stage';
const BACKEND_URL = config.api[env];
import axios from 'axios';

import { getToken } from '../../models/auth';

export function updateVouchers(voucher) {
  return axios.put(`${BACKEND_URL}/v2/bo/vouchers/${voucher.code}`, voucher, {
    headers: { Authorization: `Bearer ${getToken()}` },
  });
}
