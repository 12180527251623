import React, { Component } from 'react';
import Swal from 'sweetalert2';

import { generatePixPayment } from '../../../actions/schedules/pix_payments';

export default class GeneratePixPayment extends Component {
  constructor(props) {
    super(props);
  }

  async handleGeneratePixPayment() {
    try {
      const { scheduleId, generatePixPaymentCallback } = this.props;

      const result = await Swal.fire({
        title: 'Você tem certeza?',
        text: 'Essa ação irá gerar um pagamento PIX para ser pago',
        icon: 'warning',
        reverseButtons: true,
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Sim, gerar!',
        cancelButtonText: 'Cancelar',
      })
      
      if(!result.isConfirmed) return;

      Swal.fire({
        title: 'Gerando pagamento PIX',
        allowOutsideClick: false
      });
      Swal.showLoading();

      const response = await generatePixPayment(scheduleId);
      console.log('response', response)
      await Swal.fire({ icon: 'success', title: 'Sucesso', text: 'Pagamento PIX gerado.' });

      try {
        const { linkCompartilhamento, copyPaste } = JSON.parse(response.data.Payload);
        generatePixPaymentCallback(linkCompartilhamento, copyPaste);
      } catch (pixLinksError) {
        console.error(pixLinksError);
      }
    } catch (err) {
      console.error('GENERATE PIX ERROR', err);
      await Swal.fire({ icon: 'error', title: 'Erro', text: 'Erro ao gerar pagamento PIX.' });
    }
  }

  render() {
    
    return (
      <b>
        PAGAMENTO PENDENTE: 
        <button 
          className="btn btn-sm btn-info" 
          style={{ marginLeft: 5 }}
          onClick={() => this.handleGeneratePixPayment()}
        >
        Gerar pagamento PIX
        </button>
      </b>
    )
  }
}
