import config from '../../../config.json';
const env = process.env.REACT_APP_ENVIRONMENT || 'stage';
const BACKEND_URL = config.api[env];
import axios from 'axios';

import { getToken } from './../../models/auth';

export const UPDATE_SINGUCREDITS = 'UPDATE_SINGUCREDITS';

export function updateSinguCredits(userId, credits, description, scheduleId, reason) {
    const userUpdatePromise = axios.put(`${BACKEND_URL}/v2/bo/user/singuCredits`, {
        userId,
        credits,
        description,
        scheduleId,
        reason
    },
        {
            headers: { Authorization: `Bearer ${getToken()}` },
        });

    return userUpdatePromise;
}
