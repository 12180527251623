import React from "react";
import { Col, Row } from "react-bootstrap";
import moment from "moment";

import "./ArtistInfos.css";
import formatters from '../../helper/formatters';

const handleAddress = (address) => {
  if (address) {
    return (
      <p>{address.place} {address.streetNumber} {address.complement} {address.neighbor} {address.city} </p>
    )
  }

  return (<p></p>)
};

const Infos = ({ artist }) => (
  <div className="infos-container">
    <h2 className="section-title">Informações</h2>
    <Row className="infos-row">
      <Col md={4}>
        <h3>Data de Nascimento</h3>
        <p>{moment.tz(artist.birthdate, "Etc/UTC").format('DD/MM/YY')}</p>
      </Col>
      <Col md={4}>
        <h3>Data de entrada na Singu</h3>
        <p>{moment.tz(artist.createdDate, "Etc/UTC").format('DD/MM/YY')}</p>
      </Col>
      <Col md={4}>
        <h3>Sexo</h3>
        <p>{artist.gender}</p>
      </Col>
    </Row>

    <Row className="infos-row">
      <Col md={4}>
        <h3>Endereço</h3>
        {handleAddress(artist.address)}
      </Col>
      <Col md={4}>
        <h3>Telefone</h3>
        {artist.phone ? formatters.formatPhone(artist.phone) : 'N/A'}
      </Col>
      <Col md={4}>
        <h3>CPF</h3>
        <p>{artist.cpf ? artist.cpf.documentNumber : ''}</p>
      </Col>
    </Row>

    <Row className="infos-row">
      <Col md={4}>
        <h3>RG</h3>
        <p>{artist.rg ? artist.rg.documentNumber : ''}</p>
      </Col>
      <Col md={4}>
        <h3>Data de Expedição</h3>
        <p>{artist.dataexpedition}</p>
      </Col>
      <Col md={4}>
        <h3>Orgão Emissor RG</h3>
        <p>{artist.entityemission}</p>
      </Col>
    </Row>

    <Row className="infos-row">
      <Col md={4}>
        <h3>Nome do Pai</h3>
        <p>{artist.fatherName}</p>
      </Col>
      <Col md={4}>
        <h3>Nome da Mãe</h3>
        <p>{artist.motherName}</p>
      </Col>
    </Row>

    <Row className="infos-row">
      <Col md={4}>
        <h3>Nacionalidade</h3>
        <p>{artist.nacionality}</p>
      </Col>
      <Col md={4}>
        <h3>Naturalidade</h3>
        <p>{artist.naturality}</p>
      </Col>
    </Row>

  </div>

);

export default Infos;