'use strict';
import mask from './masks.js';
import VMasker from 'vanilla-masker';

const formatters = {
  assemblePhoneObject: (phone) => {
    const maskedPhonePattern = /\d{1}[\s]\d{4}-\d{3,4}/;
    const numbersPhonePattern = /\d{10,11}/g;
    const maskedPhone = phone.match(maskedPhonePattern);
    const onlyNumbersPhone = phone.match(numbersPhonePattern);

    if (maskedPhone) {
      const areaCodePattern = /\([0-9][0-9]\)/;
      const countryCode = '+55';
      const areaCode = phone.match(areaCodePattern)[0].substr(1, 2);
      const number = maskedPhone[0].replace(/[\D]/g, '');

      return { countryCode, areaCode, number };
    }

    if (onlyNumbersPhone) {
      const countryCode = '+55'; // onlyNumbersPhone[0].slice(0, 2);
      const areaCode = onlyNumbersPhone[0].slice(0, 2); // onlyNumbersPhone[0].slice(2, 4);
      const number = onlyNumbersPhone[0].slice(2); // onlyNumbersPhone[0].slice(4);

      return { countryCode, areaCode, number };
    }

    return false;
  },
  formatPhone: (phone) => {
    if (phone && phone.areaCode && phone.number) {
      const phoneNumber = `${phone.countryCode}${phone.areaCode}${phone.number}`.replace(/\D/g, '');

      const phoneMaskOption = phoneNumber.length > 11 ? 1 : 0;

      return VMasker.toPattern(phoneNumber, { pattern: mask.phone[phoneMaskOption] });
    }
    
    return '';
  },
  phoneToObject: (phone) => {
    const formattedPhone = phone
      .replace(/[^A-Za-z0-9]/g, '')
      .replace(' ', '');

    if(formattedPhone.length > 11) {
      return {
        countryCode: `+${formattedPhone.substr(0, 2)}`,
        areaCode: formattedPhone.substr(2, 2),
        number: formattedPhone.substr(4)
      }
    }

    return {
      countryCode: '+55',
      areaCode: formattedPhone.substr(0, 2),
      number: formattedPhone.substr(2)
    };
  },
  formatMaskedValue: (value, maskPattern) => {
    if (value && maskPattern) {
      return VMasker.toPattern(value, { pattern: maskPattern });
    }

    return value;
  },

  formatAddress: (address) => {
    let result;
    let addr = address;

    if (addr && addr[0]) {
      addr = addr[0];
    }

    if (addr && addr.place) {
      result = addr.place;
    }
    if (addr && addr.streetNumber) {
      result = result ? result.concat(`, ${addr.streetNumber}`) : '';
    }
    if (addr && addr.complement) {
      result = result ? result.concat(`, ${addr.complement}`) : '';
    }
    if (addr && addr.neighbor) {
      result = result ? result.concat(`, ${addr.neighbor}`) : '';
    }
    if (addr && addr.city) {
      result = result ? result.concat(`, ${addr.city}`) : '';
    }
    if (addr && addr.region) {
      result = result ? result.concat(`, ${addr.region}`) : '';
    }
    if (addr && addr.postal_code) {
      result = result ? result.concat(`, ${addr.postal_code}`) : '';
    }
    if (addr && addr.zipCode) {
      result = result ? result.concat(`, ${addr.zipCode}`) : '';
    }

    return result;
  },
  formatGender: (gender) => {
    if (gender) {
      return ["F", "f", "Feminino", "feminino", "woman"].includes(gender) ? "Feminino" : "Masculino";
    }
    return "";
  }
}


export default formatters