import React, { Component, PropTypes } from 'react';
import NotificationSystem from 'react-notification-system';
import EditableTextField from './EditableTextField';
import EditableSelectField from './EditableSelect';
import EditableDateField from './EditableDateField';
import masks from '../../helper/masks';

// styles
import './EditableField.css';

class EditableField extends Component {
  constructor(props) {
    super(props);
    const { initialValue, fieldList } = this.props;

    this.state = {
      editing: false,
      initialValue,
      fieldList
    };
  }

  componentWillReceiveProps({ initialValue, fieldList }) {
    this.setState({ initialValue, fieldList });
  }

  notify(message, level) {
    const { notificationSystem } = this.refs;
    const title = level === 'success' ? 'Sucesso' : 'Erro';

    notificationSystem.addNotification({
      title,
      message,
      level,
      position: 'tc'
    });
  }

  saveField(newValue, fulfillCallback) {
    let data = {};
    const { updateCallback, fieldName, parentObject, parentName } = this.props;
    const fulfill = action => {
      this.setState({
        editing: false
      }, () => fulfillCallback(action));
    };

    if (parentObject) {
      parentObject[fieldName] = newValue;
      data = {
        [parentName]: parentObject,
        backofficeUpdate: true
      };
    } else {
      data = {
        field: fieldName,
        [fieldName]: newValue,
        backofficeUpdate: true
      };
    }

    return updateCallback(data).then(fulfill);
  }

  editField(callback) {
    this.setState({
      editing: true
    }, callback);
  }

  cancelEdit() {
    this.setState({
      editing: false
    });
  }

  isEditingClass() {
    return this.state.editing ? 'EditableField--editing' : '';
  }

  publicMethods() {
    return {
      notify: (message, level) => this.notify(message, level),
      editField: (callback) => this.editField(callback),
      saveField: (value, suc, err) => this.saveField(value, suc, err),
      cancelEdit: () => this.cancelEdit()
    };
  }

  displayField() {
    const { type, dateFormat, editDisabled } = this.props;
    const { initialValue, fieldList } = this.state;

    switch (type) {
      case 'date':
        return (
          <EditableDateField 
            methods={this.publicMethods()}
            currentValue={initialValue}
            type={type}
            dateFormat={dateFormat}
            editDisabled={editDisabled}
          />
        );
      case 'dateAndTime':
        return (
          <EditableDateField 
            methods={this.publicMethods()}
            currentValue={initialValue}
            type={type}
            dateFormat={dateFormat}
            editDisabled={editDisabled}
          />
        );
      case 'phone':
        return (
          <EditableTextField 
            methods={this.publicMethods()}
            currentValue={initialValue}
            mask={masks.phone}
            type={type}
            editDisabled={editDisabled}
          />
        );
      case 'select':
        return (
          <EditableSelectField
            methods={this.publicMethods()}
            fieldList={fieldList}
            currentValue={initialValue}
            type={type}
            editDisabled={editDisabled}
          />
        );
      case 'zipcode':
        return (
          <EditableTextField methods={this.publicMethods()}
            currentValue={initialValue}
            mask={masks.zipcode}
            type={type}
            editDisabled={editDisabled}
          />
        );
      default:
        return (
          <EditableTextField 
            methods={this.publicMethods()}
            currentValue={initialValue}
            type={type}
            editDisabled={editDisabled}
          />
        );
    }
  }

  render() {
    const { fieldTitle } = this.props;

    return (
      <div className={`EditableField ${this.isEditingClass()}`}>
        <NotificationSystem ref="notificationSystem" />
        <div className="EditableField-title"><b>{fieldTitle}</b></div>
        {this.displayField()}
      </div>
    );
  }
}

EditableField.propTypes = {
  fieldList: PropTypes.array,
  initialValue: PropTypes.string,
  fieldTitle: PropTypes.string,
  fieldName: PropTypes.string,
  defaultValue: PropTypes.string,
  editDisabled: PropTypes.bool,
  updateCallback: PropTypes.func,
  type: PropTypes.string
};

export default EditableField;
