// styles
import React from "react"
import './Loading.css';

const Loading = () => (
  <div className="Loading">
    <div className="Loading-spinner ss-icon ss-loading"></div>
  </div>
);

export default Loading;
