import axios from "axios";
import React from 'react';
import { Modal, Button } from "react-bootstrap";
import Swal from "sweetalert2";

import { getToken } from './../../models/auth';


const daysOfWeek = {
  monday: "Segunda-Feira",
  tuesday: "Terça-Feira",
  wednesday: "Quarta-Feira",
  thursday: "Quinta-Feira",
  friday: "Sexta-Feira",
  saturday: "Sábado",
  sunday: "Domingo"
}

class ZoneModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      zone: null,
      saveButton: {
        disabled: false
      }
    }
  }

  componentWillReceiveProps(newProps) {
    if (newProps.zone) {
      this.setState({
        currentZone: newProps.zone,
        zone: newProps.zone
      })
    }
  }

  setName(event, key, index) {
    const value = event.target.value;
    const { zone } = this.state;
    zone.name = value;
    this.setState({
      zone: zone
    });
  }

  setStartHour(event, key, index) {
    const value = event.target.value;
    const { zone } = this.state;
    zone.multiplierPerDayOfWeek[key].range[index].startHour = value;
    this.setState({
      zone: zone
    });
  }

  setEndHour(event, key, index) {
    const value = event.target.value;
    const { zone } = this.state;
    zone.multiplierPerDayOfWeek[key].range[index].endHour = value;
    this.setState({
      zone: zone
    });
  }

  setMultiplier(event, key, index) {
    const value = event.target.value;
    const { zone } = this.state;
    zone.multiplierPerDayOfWeek[key].range[index].value = value;
    this.setState({
      zone
    });
  }

  saveZone() {
    this.setState({
      saveButton: { disabled: true }
    });
    const params = {
      _id: this.state.zone._id,
      multiplierPerDayOfWeek: this.state.zone.multiplierPerDayOfWeek,
      name: this.state.zone.name
    }
    console.log('params: ', params)
    axios.put(`${BACKEND_URL}/v2/prices/multipliers/zone`, params, {
      headers: {
        Authorization: `Bearer ${getToken()}`
      }
    }).then(res => {
      Swal.fire({
        title: "Sucesso",
        text: "Zona alterada com sucesso",
        icon: "success"
      });
      this.setState({
        saveButton: { disabled: false }
      });
    }).catch(err => {
      console.log(err);
      const { data } = err && err.response || {};
      Swal.fire({
        title: "Ops...",
        text: data.error && data.error.description || "Não foi possível alterar a zona",
        icon: "error"
      });
      this.setState({
        saveButton: { disabled: false }
      });
    })
  }

  renderDaysOfWeek() {
    const renderedObject = Object.keys(this.state.zone.multiplierPerDayOfWeek).map(key => {
      if (!daysOfWeek[key]) return;
      return (
        <div className="col-xs-12 form-group">
          <strong>{daysOfWeek[key]}</strong>
          {
            this.state.zone.multiplierPerDayOfWeek[key].range.map((item, index) => {
              return (
                <div id={key} className={"form-group"}>
                  <div className="col-md-4 col-xs-12">
                    <label htmlFor="">Horário de Início</label>
                    <input type="text" name={"startHour"} disabled onChange={(e) => this.setStartHour.bind(this)(e, key, index)} className={"form-control form-control-normal"} value={this.state.zone.multiplierPerDayOfWeek[key].range[index].startHour || 7} />
                  </div>
                  <div className="col-md-4 col-xs-12">
                    <label htmlFor="">Horário Final</label>
                    <input type="text" name={"endHour"} disabled onChange={(e) => this.setEndHour.bind(this)(e, key, index)} className={"form-control form-control-normal"} value={this.state.zone.multiplierPerDayOfWeek[key].range[index].endHour || 22} />
                  </div>
                  <div className="col-md-4 col-xs-12">
                    <label htmlFor="">Multiplicador</label>
                    <div className="input-group">
                      <input type="number" className="form-control" id="" aria-describedby="Porcentagem de aumento do preço" onChange={(e) => this.setMultiplier.bind(this)(e, key, index)} value={this.state.zone.multiplierPerDayOfWeek[key].range[index].value} />
                      <span className="input-group-addon">%</span>
                    </div>
                  </div>
                </div>
              )
            })
          }
        </div>
      )
    });
    return renderedObject;
  }

  render() {
    if (this.state.zone && this.props.isZoneModalOpen) {
      return (
        <Modal
          className="Modal Modal--medium"
          show={this.props.isZoneModalOpen || false}
          onHide={this.props.closeModal}
        >
          <Modal.Header closeButton>
            <Modal.Title className="Modal-title">
              Zona Administrativa - {this.props.zone.name}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="row">
              <form className={"form"}>
                <div className="col-xs-12">
                  <h4>Editar dados da area</h4>
                  <div className={"form-group"}>
                    <label>Nome da Zona</label>
                    <input type="text" disabled={true} className={"form-control form-control-normal"} id="exampleInputEmail1" value={this.props.zone.name} />
                  </div>
                  <p>Valores do Preço Dinâmico</p>
                  <div className="col-xs-12">
                    {this.renderDaysOfWeek()}
                  </div>
                </div>
              </form>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={this.props.closeModal}>Cancelar</Button>
            <Button
              disabled={this.state.saveButton.disabled}
              className={'btn-success'}
              onClick={this.saveZone.bind(this)}>
              {this.state.saveButton.disabled ? "Salvando..." : "Salvar"}
            </Button>
          </Modal.Footer>
        </Modal>
      );
    } else {
      return (
        <div>
        </div>
      )
    }
  }
}

export default ZoneModal;