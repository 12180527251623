import React, { Component, PropTypes } from 'react';
import { SimpleSelect } from 'react-selectize';
import { Button } from 'react-bootstrap';
import swal from 'sweetalert2';
import reactivateSchedule from '../../../actions/schedules/reactivate_schedule';
import { scheduleStatusEnum } from '../../../helper/enumHelper';
import requestHandle from './../../../helper/requests';

// style
import './CancelSchedule.css';

class CancelSchedule extends Component {
  constructor(props) {
    super(props);
    this.state = {
      statusReasons: [
        {
          label: scheduleStatusEnum.cancelledByOperations.name,
          value: { code: 'cancelledByOperations', codeNumber: 20 },
        },
        {
          label: scheduleStatusEnum.cancelByBackOffice.name,
          value: { code: 'cancelByBackOffice', codeNumber: 4 },
        },
        {
          label: scheduleStatusEnum.cancelByUser.name,
          value: { code: 'cancelByUser', codeNumber: 5 },
        },
        {
          label: scheduleStatusEnum.waitingAccept.name,
          value: { code: 'waitingAccept', codeNumber: 1 },
        },
      ],
      selectedStatusReason: null,
      selectedCancelReason: null,
      comment: '',
    };
  }

  onStatusChange(selectedStatusReason) {
    this.setState({ selectedStatusReason });
  }

  onCancelChange(selectedCancelReason) {
    this.setState({ selectedCancelReason });
  }

  changeStatus() {
    const {
      rowData: { id },
      notify,
      cancelCallback,
    } = this.props;
    const statusReason = this.state.selectedStatusReason.value;
    const cancelReason = { code: statusReason.code };
  
    if (statusReason.codeNumber === 1) {
      reactivateSchedule(id)
        .then(() => {
          swal.fire({
            icon: 'success',
            title: 'Sucesso!',
            text: 'Pedido atualizado com sucesso!',
          });
        })
        .catch(err => {
          console.log(err);
          const message =
            err.data && err.data.error && err.data.error.description;
          swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: message || 'Ocorreu um problema ao reativar o pedido.',
          });
        });
      return;
    }
  
    if (statusReason.codeNumber === 4) {
      if (!this.state.comment) {
        swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Insira um comentário.',
        });
        return;
      }
      cancelReason.comment = this.state.comment;
    }
  
    if (statusReason.codeNumber === 5) {
      if (!this.state.selectedCancelReason) {
        swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Selecione um motivo para cancelamento.',
        });
        return;
      }
      cancelReason.comment = this.state.comment;
      cancelReason.cancelReasonCode = statusReason.code;
    }
  
    const actionCreator = () => cancelCallback(id, cancelReason);

    requestHandle(actionCreator, notify, 'Agendamento cancelado com sucesso');
  }
  
  transformCancelReasons(cancelReasons) {
    return cancelReasons.map(cancelReason => ({
      label: cancelReason.text,
      value: cancelReason.key,
    }));
  }

  isDisabled() {
    const { selectedStatusReason, selectedCancelReason, comment } = this.state;
    const isCancelByUser = selectedStatusReason?.value?.codeNumber === 5;
    const isCancelByBackOffice = selectedStatusReason?.value?.codeNumber === 4;

    return (
      !selectedStatusReason ||
      (isCancelByUser && !selectedCancelReason) ||
      (isCancelByBackOffice && !comment)
    );
  }

  render() {
    return (
      <div>
        <div className="CancelSchedule-row">
          <SimpleSelect
            className="CancelSchedule-select"
            placeholder="Selecione..."
            options={this.state.statusReasons}
            onValueChange={this.onStatusChange.bind(this)}
            value={this.state.selectedStatusReason}
          />
          <Button
            className="CancelSchedule-btn"
            disabled={this.isDisabled()}
            bsStyle="danger"
            onClick={this.changeStatus.bind(this)}
          >
            Alterar
          </Button>
        </div>
        {this.state?.selectedStatusReason?.value?.codeNumber === 4 && (
          <div className="CancelSchedule-Comment">
            <div className="Modal-sectionTitle">Comentário</div>
            <textarea
              className="Form-input"
              type="text"
              style={{
                height: '75px',
                resize: 'none',
              }}
              value={this.state.comment}
              onChange={e => {
                this.setState({ comment: e.target.value });
              }}
            />
          </div>
        )}
        {this.state?.selectedStatusReason?.value?.codeNumber === 5 && (
          <div className="CancelSchedule-Comment">
            <div className="Modal-sectionTitle">Motivo</div>
            <div className="CancelSchedule-row">
              <SimpleSelect
                className="CancelSchedule-CommentSelect"
                placeholder="Selecione..."
                options={this.transformCancelReasons(this.props.cancelReasons)}
                onValueChange={this.onCancelChange.bind(this)}
                value={this.state.selectedCancelReason}
              />
            </div>
          </div>
        )}
      </div>
    );
  }
}

CancelSchedule.propTypes = {
  rowData: PropTypes.object,
  notify: PropTypes.func,
  cancelCallback: PropTypes.func,
  cancelReasons: PropTypes.array,
};

export default CancelSchedule;
