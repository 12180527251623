import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Griddle from 'griddle-react';
import { Button, Modal } from 'react-bootstrap';
import moment from 'moment';

import PageHeader from '../Global/PageHeader';
import { Toast } from '../../helper/alerts';
import VoucherEdit from './EditVoucher';
import VoucherView from './VoucherView';
import { updateVouchers } from '../../actions/vouchers/update_vouchers';
import Loading from '../Global/Loading/Loading';
import { isEmpty } from 'lodash';

class ListVoucherComponent extends Component {
  state = {
    currentPage: 1,
    showInactive: false,
    showOthers: false,
    updateModalOpen: false,
    viewModalOpen: false,
    voucher: {},
    itensPerPage: 10,
    filter: '',
  };

  openUpdateModal(voucher) {
    this.setState({
      updateModalOpen: true,
      voucher,
    });
  }

  closeUpdateModal() {
    this.setState({ updateModalOpen: false });
  }

  openViewModal(voucher) {
    this.setState({
      viewModalOpen: true,
      voucher,
    });
  }

  closeViewModal() {
    this.setState({ viewModalOpen: false });
  }

  changeVoucherAquisition = (field, value) => {
    const { aquisition } = this.state.voucher;
    if (field === 'maxTimes' || field === 'value') {
      aquisition[field] = parseInt(value);
    } else {
      aquisition[field] = value;
    }
    this.setState({ aquisition });
  };

  changeVoucherRecurrence = (field, value) => {
    const { recurrence } = this.state.voucher;
    if (field === 'maxTimes' || field === 'value') {
      recurrence[field] = parseInt(value);
    } else {
      recurrence[field] = value;
    }
    this.setState({ recurrence });
  };

  changeVoucherValue = (field, value) => {
    const { voucher } = this.state;
    if (field === 'maxTimes' || field === 'value') {
      voucher[field] = parseInt(value);
    } else {
      voucher[field] = value;
    }

    this.setState({ voucher });
  };

  changeVoucherJustNew = field => {
    const { voucher } = this.state;
    voucher[field] = !voucher.justNew;
    this.setState({ voucher });
  };

  changeServices = services => {
    this.setState({ services });
  };

  changeAreas = locations => {
    this.setState({ locations });
  };

  saveEdit = () => {
    const { voucher, locations, services } = this.state;

    const updatedVoucher = {
      code: voucher.code,
      end: moment(voucher.end).format(),
      dateRangeStart: moment(voucher.dateRangeStart).format(),
      dateRangeEnd: moment(voucher.dateRangeEnd).format(),
      services: services,
      locations: locations,
      maxTimes: voucher.maxTimes,
    };

    if (voucher.aquisition && voucher.recurrence) {
      Object.assign(updatedVoucher, { aquisition: voucher.aquisition, recurrence: voucher.recurrence });
    } else {
      Object.assign(updatedVoucher, { justNew: voucher.justNew, type: voucher.type, value: voucher.value });
    }

    updateVouchers(updatedVoucher)
      .then(() => {
        Toast().fire({
          icon: 'success',
          title: 'Voucher alterado com sucesso',
        });
        window.location.reload();
      })
      .catch(error => {
        console.log(error);
        Toast().fire({
          icon: 'error',
          title: 'Erro ao alterar voucher',
        });
      });
  };

  search = (code, inactive, others) => {
    this.props.handlerSearch(code, inactive, others);
  };

  componentDidMount(){
    const fullUrl = window.location.href;
    const hashIndex = fullUrl.indexOf('#');
    const urlAfterHash = fullUrl.substring(hashIndex + 1);
    const queryParamsIndex = urlAfterHash.indexOf('?');
    const queryString = urlAfterHash.substring(queryParamsIndex + 1);
    const queryParams = new URLSearchParams(queryString);
    const voucherString = queryParams.get('voucher');
    const voucher = JSON.parse(voucherString);

    
    if(voucher && isEmpty(this.state.voucher)){
      this.openUpdateModal(voucher)
    }
  }

  render() {
    const { vouchers, totalItems } = this.props;
    const { code } = this.state;
    async function clipboardText(text) {
      try {
        await navigator.clipboard.writeText(text);

        Toast().fire({
          icon: 'success',
          title: 'Informação copiada com sucesso',
        });
      } catch (err) {
        Toast().fire({
          icon: 'error',
          title: 'Erro ao copiar informação',
        });
      }
    }

    const columns = [
      'code',
      'campaign',
      'start',
      'end',
      'dateRangeStart',
      'dateRangeEnd',
      'usedTimesAquisition',
      'usedTimesRecurrence',
      'valueAquisition',
      'valueRecurrence',
      'actions',
    ];

    if (this.props.status === 'LOADING') {
      return <Loading />;
    }

    return (
      <div className="row">
        <div style={{ marginBottom: 30 }}>
          <PageHeader title="Vouchers" />
          <b style={{ marginLeft: 10, marginRight: 10 }}>Filtrar:</b>{' '}
          <input
            type="text"
            width="300px"
            className="form-control"
            defaultValue={code}
            onChange={evt => this.setState({ code: evt.target.value })}
            placeholder="Código do voucher"
          />
          <input
            style={{ marginLeft: 15 }}
            type="checkbox"
            name="showInactive"
            onChange={() => this.setState({ showInactive: !this.state.showInactive })}
            value="showInactive"
          />{' '}
          Visualizar inativos
          <input
            style={{ marginLeft: 15 }}
            type="checkbox"
            name="showInactive"
            onChange={() => this.setState({ showOthers: !this.state.showOthers })}
            value="showInactive"
          />{' '}
          Visualizar MGM/PGM
          <button
            className="btn btn-info"
            onClick={() => this.search(this.state.code, this.state.showInactive, this.state.showOthers)}
            style={{ marginLeft: 10 }}
          >
            BUSCAR
          </button>
        </div>
        <div>
          <Griddle
            useExternal={true}
            noDataMessage={'Não há dados para serem exibidos'}
            columns={columns}
            results={vouchers || []}
            showFilter={true}
            externalSetFilter={() => { }}
            resultsPerPage={10}
            showSettings={false}
            useFixedLayout={false}
            useGriddleStyles={false}
            externalSetPage={this.props.setPage}
            externalCurrentPage={this.props.page - 1}
            externalMaxPage={Math.ceil(totalItems / 10)}
            tableClassName={'GriddleTable table'}
            settingsToggleClassName={'btn btn-default'}
            columnMetadata={[
              {
                columnName: 'code',
                displayName: 'Código',
                customComponent: ({ rowData }) => {
                  return (
                    <p>
                      {rowData.code}
                      <Button
                        style={{ padding: '0 0.25rem', color: '#ff7a66' }}
                        bsStyle="link"
                        onClick={() => clipboardText(rowData.code)}
                        title="Copiar"
                      >
                        <span className="glyphicon glyphicon-copy"></span>
                      </Button>
                    </p>
                  );
                },
              },
              {
                columnName: 'campaign',
                displayName: 'Campanha',
                customComponent: ({ rowData }) => {
                  return <p>{rowData.campaign}</p>;
                },
              },
              {
                columnName: 'start',
                displayName: 'Inicio',
                customComponent: ({ rowData }) => {
                  return <p>{moment(rowData.start).add(3, 'hours').format('DD/MM/YYYY')}</p>;
                },
              },
              {
                columnName: 'end',
                displayName: 'Fim',
                customComponent: ({ rowData }) => {
                  return <p>{moment(rowData.end).tz('America/Sao_Paulo').format('DD/MM/YYYY')}</p>;
                },
              },
              {
                columnName: 'dateRangeStart',
                displayName: 'Uso - Início',
                customComponent: ({ rowData }) => {
                  return <p>{rowData?.dateRangeStart ? moment(rowData?.dateRangeStart).tz('America/Sao_Paulo').format('DD/MM/YYYY hh:mm') : 'Sem limitação'}</p>;
                },
              },
              {
                columnName: 'dateRangeEnd',
                displayName: 'Uso - Final',
                customComponent: ({ rowData }) => {
                  return <p>{rowData?.dateRangeEnd ? moment(rowData?.dateRangeEnd).tz('America/Sao_Paulo').format('DD/MM/YYYY hh:mm') : 'Sem limitação'}</p>;
                },
              },
              {
                columnName: 'usedTimesAquisition',
                displayName: 'Util. Aquisição',
                customComponent: ({ rowData }) => {
                  if (rowData.aquisition && rowData.aquisition.usedTimes) return <p>{rowData.aquisition.usedTimes}</p>;
                  if (!rowData.aquisition && rowData.justNew === true)
                    if (rowData.usedTimes) return <p>{rowData.usedTimes}</p>;
                  if (!rowData.usedTimes) return <p>0</p>;
                  return <p>--</p>;
                },
              },
              {
                columnName: 'usedTimesRecurrence',
                displayName: 'Util. Recorrencia',
                customComponent: ({ rowData }) => {
                  if (rowData.recurrence && rowData.recurrence.usedTimes) return <p>{rowData.recurrence.usedTimes}</p>;
                  if (!rowData.recurrence && rowData.justNew === false)
                    if (rowData.usedTimes) return <p>{rowData.usedTimes}</p>;
                  if (!rowData.usedTimes) return <p>0</p>;
                  return <p>--</p>;
                },
              },
              {
                columnName: 'valueAquisition',
                displayName: 'Valor Aqu.',
                customComponent: ({ rowData }) => {
                  if (rowData.aquisition && rowData.aquisition.type === 'P') return <p>{rowData.aquisition.value}%</p>;
                  else if (rowData.aquisition && rowData.aquisition.type === 'V')
                    return <p>R$ {rowData.aquisition.value}</p>;
                  if (rowData.justNew === true)
                    if (rowData.type === 'P') return <p>{rowData.value}%</p>;
                    else return <p>R$ {rowData.value}</p>;
                  return <p>--</p>;
                },
              },
              {
                columnName: 'valueRecurrence',
                displayName: 'Valor Rec.',
                customComponent: ({ rowData }) => {
                  if (rowData.recurrence && rowData.recurrence.type === 'P') return <p>{rowData.recurrence.value}%</p>;
                  else if (rowData.recurrence && rowData.recurrence.type === 'V')
                    return <p>R$ {rowData.recurrence.value}</p>;
                  if (rowData.justNew === false)
                    if (rowData.type === 'P') return <p>{rowData.value}%</p>;
                    else return <p>R$ {rowData.value}</p>;
                  return <p>--</p>;
                },
              },
              {
                columnName: 'actions',
                displayName: 'Ações',
                customComponent: ({ rowData }) => {
                  return (
                    <div style={{ display: 'flex' }}>
                      <Button
                        onClick={() => {
                          this.openUpdateModal(rowData);
                        }}
                        className="ScheduleActions-btn btn btn-info"
                        title="Editar Voucher"
                      >
                        <span className="glyphicon glyphicon-edit" />
                      </Button>
                      <Button
                        onClick={() => {
                          this.openViewModal(rowData);
                        }}
                        className="ScheduleActions-btn btn btn-info"
                        title="Visualizar Voucher"
                      >
                        <span className="glyphicon glyphicon-eye-open"></span>
                      </Button>
                    </div>
                  );
                },
              },
            ]}
          />
        </div>
        <div>
          <Modal bsSize="large" show={this.state.updateModalOpen} onHide={() => this.closeUpdateModal()}>
            <Modal.Header closeButton>
              <Modal.Title>Atualizar voucher</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <VoucherEdit
                voucher={this.state.voucher || {}}
                changeValue={this.changeVoucherValue}
                changeJustNew={this.changeVoucherJustNew}
                changeAquisition={this.changeVoucherAquisition}
                changeRecurrence={this.changeVoucherRecurrence}
                changeAreas={this.changeAreas}
                changeServices={this.changeServices}
              />
            </Modal.Body>
            <Modal.Footer>
              <Button onClick={() => this.closeUpdateModal()}>Fechar</Button>
              <Button className="btn btn-info" onClick={() => this.saveEdit()}>
                Atualizar
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
        <div>
          <Modal show={this.state.viewModalOpen} onHide={() => this.closeViewModal()}>
            <Modal.Header closeButton>
              <Modal.Title>Visualizar voucher</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <VoucherView voucher={this.state.voucher || {}} />
            </Modal.Body>
            <Modal.Footer>
              <Button onClick={() => this.closeViewModal()}>Fechar</Button>
            </Modal.Footer>
          </Modal>
        </div>
      </div>
    );
  }
}

ListVoucherComponent.propTypes = {
  vouchers: PropTypes.array,
};

export default ListVoucherComponent;
