import React, { Component } from "react";
import { fetchAreasService } from "../../actions/areas/fetch_areas";
import ListAreaComponent from "../../components/Areas/List";

export default class ListAreas extends Component {
  constructor() {
    super();
    this.state = {
      status: "READY",
      areas: [],
      selectedAreaId: null,
    }
  }

  componentDidMount() {
    this.fetchAreas()
  }

  fetchAreas = () => {
    this.setState({ status: "LOADING" })
    fetchAreasService().then(({ data }) => {
      this.setState({ areas: data });
    }).finally(() => {
      this.setState({ status: "READY" })
    })
  }



  render() {
    return (
      <ListAreaComponent
        areas={this.state.areas}
        status={this.state.status}
      />
    )
  }
}