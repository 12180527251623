import _ from "lodash";
import React, { Component, PropTypes } from 'react';
import AvatarEditor from "react-avatar-editor";
import { Button, Modal } from "react-bootstrap";
import uploadHelper from "./../../helper/upload";
import imageConverter from "./../../helper/imageConverter";
import fileHelper from "./../../helper/file.js";

const NOT_UPLOADED = "NOT_UPLOADED";
const UPLOADING = "UPLOADING";
const UPLOADED = "UPLOADED";

class UploadField extends Component {
  constructor(props) {
    super(props);

    this.state = {
      uploadStatus: NOT_UPLOADED,
      uploadProgress: 0,
      isPreviewModalOpened: false,
      isCropModalOpened: false,
      uploadCallback: null,
      imageToCropUrl: null,
      initialValueSet: false
    };
  }

  isInvalid() {
    const { inputData } = this.props;

    return !inputData.valid && inputData.touched;
  }

  wrapperClass() {
    const { uploadStatus } = this.state;
    const { inputData, disabled } = this.props;
    const { active, valid, touched } = inputData;
    const invalidClass = this.isInvalid() ? "is-error" : "";
    const focusedClass = active ? "is-focused" : "";
    const validClass = uploadStatus === UPLOADED && touched && valid && !active ? "is-valid" : "";
    const disabledClass = disabled ? "is-disabled" : "";

    return `${invalidClass} ${focusedClass} ${validClass} ${disabledClass}`;
  }

  uploadFieldClass() {
    const { uploadStatus } = this.state;

    if (uploadStatus === UPLOADING) {
      return "is-uploading";
    } else if (uploadStatus === UPLOADED) {
      return "is-uploaded";
    }

    return "";
  }

  componentWillReceiveProps({ inputData }) {
    const { initialValueSet } = this.state;
    const fileUrl = inputData.value.fileUrl;

    if (inputData.autofilled && fileUrl && !initialValueSet) {
      const fileName = _.takeRight(fileUrl.split("/"))[0].split(".")[0];
      const uncachedFileUrl = `${fileUrl}?${parseInt(Math.random() * 10000, 10)}`;

      inputData.onChange({ fileUrl });
      this.setState({
        fileName,
        fileUrl: uncachedFileUrl,
        uploadStatus: UPLOADED,
        initialValueSet: true
      });
    }
  }

  onChange(evt) {
    const {
      crop,
      convert,
      type,
      uploadCallback,
      inputData: { onChange }
    } = this.props;
    const file = evt.target.files[0];

    const onProgress = progressEvt => {
      const progress = Math.round((progressEvt.loaded / progressEvt.total) * 100);
      this.setState({ uploadProgress: progress });
    };

    const doneUploading = action => {
      const { config } = action.payload;
      const rawUrl = config.url;
      const fileName = config.data.name;
      const fileUrl = rawUrl.split("?")[0];
      const uncachedFileUrl = `${fileUrl}?${parseInt(Math.random() * 10000, 10)}`;
      const uploadFinished = () => {
        const fname = convert ? fileName.split(".")[0] : `${fileName}.${fileName.split(".")[1]}`;

        this.setState({
          uploadStatus: UPLOADED,
          fileUrl: uncachedFileUrl,
          fileName: fname,
          uploadProgress: 0
        });
        onChange({ file, fileUrl });
      };

      if (type === "image") {
        const img = document.createElement("img");

        img.onload = uploadFinished;
        img.src = uncachedFileUrl;
      } else {
        uploadFinished();
      }
    };

    const uploadFile = () => {
      if (file && uploadHelper.isValidPartnerImage(file).valid) {
        this.setState({ uploadStatus: UPLOADING });

        if (convert) {
          const fileCallback = e => {
            const base64 = e.target.result;
            const img = document.createElement("img");
            const doneConverting = data => {
              const convertedFile = new File([data.blob], file.name);
              console.log(data)
              uploadCallback(convertedFile, onProgress).then(doneUploading);
            };

            img.src = base64;
            imageConverter(img, convert)
              .then(doneConverting)
              .catch(err => {
                console.log(err);
                alert("Não foi possível fazer upload da image. Por favor, tente novamente");
              });
          };

          fileHelper.getFileDataUrl(file, fileCallback);
        } else {
          uploadCallback(file, onProgress).then(doneUploading);
        }
      }
    };

    if (crop) {
      const fileCallback = e => {
        const base64 = e.target.result;
        const cropCallback = () => {
          const croppedBase64 = this.refs.avatarEditor.getImage();
          const img = document.createElement("img");
          const doneConverting = data => {
            console.log(data.blob.type);
            const typeSplited = data.blob && data.blob.type && data.blob.type.split("/");

            const croppedFile = new File([data.blob], `profile_picture.${typeSplited[typeSplited.length - 1]}`);
            this.setState({ uploadStatus: UPLOADING }, () => {
              this.closeModal();
              uploadCallback(croppedFile, onProgress).then(doneUploading);
            });
          };

          img.src = croppedBase64;
          imageConverter(img, convert).then(doneConverting);
        };

        this.openCropWindow(cropCallback, base64);
      };

      fileHelper.getFileDataUrl(file, fileCallback);
    } else {
      uploadFile();
    }
  }

  onUploadClick() {
    // Forces blur trigger so touched flag
    // will be set to true
    const {
      disabled,
      inputData: { onBlur }
    } = this.props;

    if (!disabled) {
      if (onBlur) onBlur();
    }
  }

  openUploadPreview() {
    const { fileUrl } = this.state;

    if (fileUrl) {
      this.setState({
        isPreviewModalOpened: true
      });
    }
  }

  openCropWindow(uploadCallback, imageToCropUrl) {
    if (imageToCropUrl) {
      this.setState({
        isCropModalOpened: true,
        uploadCallback,
        imageToCropUrl
      });
    }
  }

  closeModal() {
    this.setState({
      isPreviewModalOpened: false,
      isCropModalOpened: false
    });
  }

  renderErrorMessage() {
    const { error } = this.props.inputData;

    if (this.isInvalid()) {
      return <span className="Form-error">{error}</span>;
    }

    return null;
  }

  renderTypeContent() {
    const { type, name } = this.props;
    const { fileUrl } = this.state;
    const isUploaded = this.state.uploadStatus === UPLOADED;
    const isCircle = !!this.props.circlePreview;
    const previewClass = `Form-uploadPreview ${isCircle ? "Form-uploadPreview--circle" : ""} ${isUploaded ? "is-uploaded" : ""
      }`;

    if (type === "image") {
      const bgStyle = {
        backgroundImage: `url(${fileUrl})`
      };

      return (
        <div className={previewClass}>
          <div className="Form-uploadPreviewImage" style={bgStyle}>
            <div className="Form-uploadPreviewChangeIndicator">
              <div className="ss-icon ss-view"></div>
            </div>
            <div className="Form-uploadPreviewChangeContent" onClick={() => this.openUploadPreview()}>
              <div className="Form-uploadPreviewChangeIcon">
                <div className="ss-icon ss-view"></div>
              </div>
            </div>
          </div>
          <label className="Form-uploadPreviewEmptyIcon" htmlFor={name}>
            <span className="ss-icon ss-picture"></span>
          </label>
        </div>
      );
    }

    return null;
  }

  renderUploadPreview() {
    const { avatar } = this.props;
    const imageClass = `Form-uploadModalImage ${avatar ? "Form-uploadModalImage--avatar" : ""}`;

    return (
      <div className={imageClass}>
        <img src={this.state.fileUrl} />
      </div>
    );
  }

  renderUploadFace() {
    const { name } = this.props;
    const { uploadStatus, uploadProgress, fileName } = this.state;

    if (uploadStatus === UPLOADING) {
      const barStyle = { width: `${uploadProgress}%` };

      return (
        <div className="Form-uploadFace">
          <div className="Form-uploadProgress" style={barStyle}></div>
          <span className="Form-uploadingText">{uploadProgress}%</span>
        </div>
      );
    }

    if (uploadStatus === UPLOADED) {
      return (
        <label htmlFor={name} className="Form-uploadFace">
          <span className="Form-uploadedText">{fileName}</span>
          <span className="Form-uploadIcon ss-icon ss-repeat"></span>
        </label>
      );
    }

    return (
      <div className="Form-uploadFace">
        <label className="Form-uploadLabel" htmlFor={name}>
          <span>UPLOAD</span>&nbsp;
          <span className="Form-uploadIcon ss-icon ss-upload"></span>
        </label>
      </div>
    );
  }

  render() {
    const { label, name, disabled } = this.props;

    return (
      <div id={`${name}Wrapper`} className={`Form-inputWrapper form-group ${this.wrapperClass()}`}>
        <div className="Form-labelWrapper">
          <div className="Form-label">
            {label}
            {this.renderErrorMessage()}
          </div>
        </div>
        {this.renderTypeContent()}
        <div className={`Form-uploadField ${this.uploadFieldClass()}`} onClick={() => this.onUploadClick()}>
          {this.renderUploadFace()}
          <input type="file" disabled={disabled} onChange={evt => this.onChange(evt)} id={name} name={name} />
        </div>

        <Modal className="Modal" show={this.state.isCropModalOpened} onHide={() => this.closeModal()}>
          <Modal.Header closeButton>
            <Modal.Title className="Modal-title">Posicionar imagem</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="Form-uploadCropAvatar">
              <AvatarEditor
                image={this.state.imageToCropUrl}
                width={this.props.cropWidth}
                height={this.props.cropHeight}
                border={0}
                scale={this.state.cropScale}
                ref="avatarEditor"
              />
              <div className="Form-submitWrapper">
                <div className="row">
                  <div className="col-sm-12">
                    <button className="Form-submit" type="submit" onClick={() => this.state.uploadCallback()}>
                      <span>Prosseguir</span>
                      <span className="ss-icon ss-picture"></span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={() => this.closeModal()}>Fechar</Button>
          </Modal.Footer>
        </Modal>

        <Modal className="Modal" show={this.state.isPreviewModalOpened} onHide={() => this.closeModal()}>
          <Modal.Header closeButton>
            <Modal.Title className="Modal-title">Visualizar Upload</Modal.Title>
          </Modal.Header>
          <Modal.Body>{this.renderUploadPreview()}</Modal.Body>
          <Modal.Footer>
            <Button onClick={() => this.closeModal()}>Fechar</Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

UploadField.propTypes = {
  name: PropTypes.string,
  type: PropTypes.string,
  inputData: PropTypes.object,
  disabled: PropTypes.bool
};

export default UploadField;
