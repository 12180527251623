import { FETCH_AREAS } from './../actions/dynamic_prices/fetch_areas';
import { FETCH_PRICES_BY_AREA } from './../actions/dynamic_prices/fetch_price_by_area';
import { FETCH_POLYGONS } from './../actions/dynamic_prices/fetch_polygons';
import { CLICK_SEARCH_POLYGONS_BY_SERVICEID } from './../actions/dynamic_prices/click_search_polygons';
import { FETCH_POLYGONSBYAREAID } from './../actions/dynamic_prices/fetch_polygons_by_area';

const INITIAL_STATE = {
  all: []
};

const areasReducer = (state = INITIAL_STATE, action) => {
  if (action.error) {
    return state;
  }

  switch (action.type) {
    case CLICK_SEARCH_POLYGONS_BY_SERVICEID: 
      return {
        ...state,
        serviceId: action.serviceId,
        cityId: action.cityId
      }
    case FETCH_AREAS:
      return {
        ...state,
        all: action.payload.data
      };
    case FETCH_PRICES_BY_AREA:
      return {
        ...state,
        all: action.payload.data
      };
    case FETCH_POLYGONS:
      return {
        ...state,
        all: action.payload.data
      };
    case FETCH_POLYGONSBYAREAID:{
      return{
        ...state,
        all: action.payload.data
      }
    }
    default:
      return state;
    }
};

export default areasReducer;
