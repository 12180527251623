// user info actions
import { FETCH_USER } from "./../actions/user_info/fetch_user.js";
import { UPDATE_USER } from "./../actions/user_info/update_user.js";
import { REMOVE_ADDRESS } from "./../actions/user_info/remove_address.js";
import { ADD_ADDRESS } from "../actions/user_info/add_address.js";
import { REMOVE_CARD } from "./../actions/user_info/remove_card.js";

const INITIAL_STATE = {
  user: null,
};

const userReducer = (state = INITIAL_STATE, action) => {
  if (action.error) {
    return state;
  }

  switch (action.type) {
    case FETCH_USER:
      return { ...state, user: action.payload.data };

    case UPDATE_USER:
      return {
        user: {
          ...state.user,
          ...action.meta.data,
        },
      };

    case REMOVE_ADDRESS:
      return {
        user: {
          ...state.user,
          address: [
            ...state.user.address.slice(0, action.meta.index),
            ...state.user.address.slice(action.meta.index + 1),
          ],
        },
      };

    case ADD_ADDRESS:
      return {
        user: {
          ...state.user,
          address: [...state.user.address, action.payload.data.address],
        },
      };

    case REMOVE_CARD:
      return {
        user: {
          ...state.user,
          creditCards: [
            ...state.user.creditCards.slice(0, action.meta.index),
            ...state.user.creditCards.slice(action.meta.index + 1),
          ],
        },
      };

    default:
      return state;
  }
};

export default userReducer;
