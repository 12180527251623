import React, { Component } from "react";
import { Modal, Button, Label } from "react-bootstrap";
import moment from "moment";
import Swal from 'sweetalert2';

import { updateInfractionComment } from '../../actions/infractions/update_infraction_comment';

const updateInfractionTags = ['ESTORNO']
export default class InfractionDetails extends Component {
  constructor(props) {
    super(props);
    const { infraction } = props;

    this.state = { 
      infraction,
      updatingInfraction: false,
      isUpdateInfractionEnable: false,
      infractionComment: infraction.comment || ''
    };
  }

  componentWillReceiveProps(newProps) {
    const { infraction } = newProps;

    this.setState({  infraction });
  }

  handleSelectTag = (tag) => {
    this.setState({
      infractionComment: tag,
      isUpdateInfractionEnable: true,
    })
  }

  handleUpdateInfractionComment = async () => {
    try {
      this.setState({ updatingInfraction: true });

      const { id_professional_infraction } = this.state.infraction;
      const { infractionComment } = this.state;

      if(!id_professional_infraction) throw { description: 'Nenhuma infração selecionada' };

      await updateInfractionComment(id_professional_infraction, {
        comment: infractionComment
      });

      Swal.fire({
        icon: 'success',
        title: 'Sucesso',
        text: 'Comentário atualizado com sucesso.'
      });
    } catch (err) {
      console.error('Update Infraction Comment Error', err);
      Swal.fire({
        icon: 'error',
        title: 'Oops',
        text: 'Erro ao atualizar comentário'
      });
    } finally {
      this.setState({ updatingInfraction: false, isUpdateInfractionEnable: false });
    }
  }

  render() {
    const { infraction, infractionComment, isUpdateInfractionEnable } = this.state;

    let penalty = infraction.penalty ? parseFloat(infraction.penalty): 0.0;
    let client_discount = infraction.client_discount ? parseFloat(infraction.client_discount): 0.0;
    let lost_money = client_discount - penalty;
    
    return (
      <Modal show={this.props.open}>
        <Modal.Header closeButton onHide={this.props.closeModal}>
          <Modal.Title className="Modal-title">
            Detalhes da Infração
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="col-xs-12">
              <h3>
                {infraction.description}
                <br />
                <small>
                  {infraction.singu_informed
                    ? "Singu Informada"
                    : "Singu Não Informada"}
                </small>
              </h3>
              <br />
            </div>
            <div className="col-xs-12">
              <p>
                Infração para a Profissional:{" "}
                <a href={`https://backoffice.singu.io/edit-artist/${infraction.professional_id}`}><strong>{infraction.professional_id}</strong></a>
              </p>
            </div>
            <div className="col-xs-12">
              <p>
                Pertence a um agendamento? :{" "}
                <a><strong>{infraction.schedule_id || "Não"}</strong></a>
              </p>
            </div>
            <div className="col-xs-12">
              <p>
                Informou a Singu? :{" "}
                <strong>{infraction.singu_informed ? "Sim" : "Não"}</strong>
              </p>
            </div>
            <div className="col-xs-6">
              <p>
                {" "}
                ID da Infração:{" "}
                <strong>{infraction.id_professional_infraction}</strong>{" "}
              </p>
            </div>
            <div className="col-xs-6">
              <p>
                {" "}
                ID da Regra: <strong>
                  {infraction.id_infraction_rule}
                </strong>{" "}
              </p>
            </div>
            <div className="col-xs-6">
              <p>
                Data da Infração:{" "}
                <strong>
                  {moment(infraction.occurrence_date).format("DD/MM/YYYY")}
                </strong>
              </p>
            </div>
            <div className="col-xs-6">
              <p>
                Data da Criação:{" "}
                <strong>
                  {moment(infraction.created_at).format("DD/MM/YYYY")}
                </strong>
              </p>
            </div>
            <div className="col-xs-6">
              <p>
                Infração está paga?:{" "}
                <strong>{infraction.paid ? "Sim" : "Não"}</strong>
              </p>
            </div>
            <div className="col-xs-6">
              <p>
                Gravidade: <strong>{infraction.level}</strong>
              </p>
            </div>
            <div className="col-xs-6">
              <p>
                Pontos gerados na infração: <strong>{infraction.points}</strong>
              </p>
            </div>
            <div className="col-xs-6">
              <p>
                Valor da Multa:{" "}
                <strong>
                  R${" "}
                  {penalty
                    ? parseFloat(penalty)
                        .toFixed(2)
                        .toString()
                        .replace(".", ",")
                    : ""}
                </strong>
              </p>
            </div>
            <div className="col-xs-6">
              <p>
                Valor do desconto:{" "}
                <strong>
                  R${" "}
                  {client_discount
                    ? parseFloat(client_discount)
                        .toFixed(2)
                        .toString()
                        .replace(".", ",")
                    : ""}
                </strong>
              </p>
            </div>
            <div className="col-xs-6">
              <p>
                Valor do prejuízo:{" "}
                <strong style={{ color: "red" }}>
                  R${" "}
                  {lost_money
                    ? parseFloat(lost_money)
                        .toFixed(2)
                        .toString()
                        .replace(".", ",")
                    : ""}
                </strong>
              </p>
            </div>
            <div className="col-xs-12">
              <p>
                <br/>
                Infração inserida por :{" "}
                <a><strong>{infraction.schedule_id ? "System" : infraction.created_by}</strong></a>
              </p>
            </div>
            <div className="col-xs-12">
              <p style={{ marginBottom: '2px' }}>Comentário sobre a infração: </p>
              <div style={{ marginBottom: '10px' }}>
                {updateInfractionTags.map(tag => (
                  <Label bsStyle="default" style={{ marginRight: '5px', cursor: 'pointer' }} onClick={() => this.handleSelectTag(tag)}>
                    {tag}
                  </Label>
                ))}
              </div>
              <textarea 
                className="Form-input" 
                disabled={!isUpdateInfractionEnable} 
                style={{ height: '200px' }}
                placeholder="Sem comentários"
                value={infractionComment}
                onChange={(e) => this.setState({ infractionComment: e.target.value })}
              ></textarea>
              {isUpdateInfractionEnable && 
                <button 
                  type="submit" 
                  className="btn btn-info" 
                  style={{ display: 'block', marginLeft: 'auto' }}
                  onClick={() => this.handleUpdateInfractionComment()}
                >
                  Salvar comentário
                </button>
              }
              {!isUpdateInfractionEnable &&
                <button 
                type="submit" 
                className="btn btn-info"
                style={{ display: 'block',  marginLeft: 'auto' }}
                onClick={() => this.setState({ isUpdateInfractionEnable: true })}
                >
                  <span className="glyphicon glyphicon-edit" style={{ marginRight: '3px' }}></span>
                  Editar comentário
                </button>
              }
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={this.props.closeModal}>
            Fechar
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}
