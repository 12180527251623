import UserLink from './components/UserLink';
import Actions from './components/Actions';
import Package from './components/Package';
import CreatedTime from './components/CreatedTime';
import Payment from './components/Payment';

const packagePurchasesMetadata = [
  {
    columnName: '_id',
    order: 0,
    locked: false,
    visible: true,
    displayName: 'ID',
  },
  {
    columnName: 'client',
    order: 1,
    locked: false,
    visible: true,
    displayName: 'Cliente',
    customComponent: UserLink
  },
  {
    columnName: 'package',
    order: 2,
    locked: false,
    visible: true,
    displayName: 'Pacote',
    customComponent: Package
  },
  {
    columnName: 'date',
    order: 3,
    locked: false,
    visible: true,
    displayName: 'Data da compra',
    customComponent: CreatedTime
  },
  {
    columnName: 'payment',
    order: 4,
    locked: false,
    visible: true,
    displayName: 'Pagamento',
    customComponent: Payment
  },
  {
    columnName: 'actions',
    order: 5,
    locked: false,
    visible: true,
    displayName: 'Ações',
    customComponent: Actions

  },
];

export default packagePurchasesMetadata;
