import { backendAPI } from '../../helper/apis';

export const findPixPayments = (scheduleId) => {
  return backendAPI.get(`/schedule/pix/${scheduleId}`);
}

export const createPixPayment = (scheduleId, data) => {
  return backendAPI.post(`/schedule/pix/${scheduleId}`, data);
}

export const deletePixPayment = (scheduleId, pixPaymentId) => {
  return backendAPI.delete(`/schedule/pix/${scheduleId}/${pixPaymentId}`);
}

export const updatePixPaymentStatus = (scheduleId) => {
  return backendAPI.put(`/schedule/pix/status/${scheduleId}`);
}

export const generatePixPayment = (scheduleId) => {
  return backendAPI.post(`schedule/pix/${scheduleId}/generate`);
}
