import React from 'react';
import { loggedIn, logout } from '../../models/auth.js';

export default class Logout extends React.Component {
  componentDidMount() {
    logout();
    this.state = {
      loggedIn: loggedIn()
    };
  }

  render() {
    return (
      <div className="col-sm-offset-2 col-sm-8 alert alert-danger" role="alert">
        <span className="glyphicon glyphicon-exclamation-sign" aria-hidden="true"></span>
        <span className="sr-only">Error:</span>
        Usuário desconectado
      </div>
    );
  }
}
