/**
 * SINGU CORE -> https://gitlab.com/singu-project/npm/core
 * Receive price in float
 * Receive the percentage as a Integer
 * Returns a string with the value follow by two numbers after the coma.
 *
 * @param {Float} price
 * @param {Int} percentage
 */
const percentDesc = function (price, percentage) {
  price = getValue(price);
  const result = parseInt(price - ((price * percentage) / 100));
  return getParsed(result);
}

const getPercentValue = function (price, percentage) {
  price = getValue(price);
  const result = parseInt(price * percentage / 100);
  return getParsed(result)
}

const getTakeRate = function (price, takeRate) {
  price = getValue(price);
  const result = parseInt(price * (100 - takeRate) / 100);
  return getParsed(result);
}

const calculateDinamicPrice = function (price, multiplier) {
  price = getValue(price);
  const result = price + (price * multiplier);
  return getParsed(result);
}

/**
 * Returns the integer of the calculation
 * It is multiplied by 10000
 *
 * @param {Float} price
 * @param {Int} percentage
 */
const percentIncrease = function (price, percentage) {
  price = getValue(price);
  const result = parseInt(price + ((price * percentage) / 100));
  return getParsed(result);
}

/**
 * Returns the integer of the calculation
 *
 * @param {Int} price
 * @param {Int} discount
 */
const valueDesc = function (price, discount) {
  price = getValue(price);
  discount = getValue(discount);
  const result = parseInt(price - discount);
  return getParsed(result);
}

const valueIncrease = function (price, increase) {
  price = getValue(price);
  increase = getValue(increase);
  return getParsed(price + increase);
}

const valueMultiply = function (price, multiply = 1) {
  price = getValue(price) * multiply;
  return getParsed(price);
}

/**
 * Return the value multiplied by 10000
 * @param {Int} value
 */
const getValue = function (value) {
  return parseInt(parseFloat(value) * 10000);
}

/**
 * Returns the value with the decimal value as a string
 * @param {Int} value
 */
const getParsed = function (value) {
  return parseFloat((parseInt(value) / 10000).toFixed(4).match(/^-?\d+(?:\.\d{0,2})?/)[0]);
}

module.exports = {
  percentDesc,
  getPercentValue,
  getTakeRate,
  calculateDinamicPrice,
  percentIncrease,
  valueMultiply,
  valueDesc,
  valueIncrease,
  getValue,
  getParsed
}