/* global _, $ */

import React, { Component } from "react"
import moment from "moment"
import Swal from 'sweetalert2';
import NotificationSystem from 'react-notification-system';
import DateTime from 'react-datetime';

import PageHeader from '../Global/PageHeader';
import TextField from '../FormFields/TextField';
import RadioGroup from '../FormFields/RadioGroup';
import AreaService from '../Helpers/AreasMultiSelect';
import { batchVoucher } from '../../actions/vouchers/batch_voucher';

// styles
import './../../style/form.css';

const typeOptions = [
    {
        label: 'Valor',
        value: 'V'
    },
    {
        label: 'Percentual',
        value: 'P'
    }
];

const codeOptions = [
    {
        label: "Empresa + 4 digitos aleatórios",
        value: "random"
    },
    {
        label: "Empresa + numero sequencial",
        value: "increment"
    },
    {
        label: "Aleatório",
        value: "full_random"
    }
];

const yesAndNo = [
    {
        label: 'Sim',
        value: 'true'
    },
    {
        label: 'Não',
        value: 'false'
    }
];
const replaceCampaign = value => value.replace(/[^A-Za-z0-9]/g, '');
const today = moment();

class NewBatchVoucherForm extends Component {
    state = {
        codeType: '',
        campaign: 'Singu Empresa',
        startDate: '',
        endDate: '',
        dateRangeStart: '',
        dateRangeEnd: '',
        locations: '',
        services: '',
        userId: '',
        description: '',
        aquisitionType: '',
        aquisitionValue: '',
        aquisitionMaxTimes: '',
        aquisitionNumberOfClients: '',
        aquisitionTimesByUser: '',
        aquisitionPublicVoucher: false,
        recurrenceType: '',
        recurrenceValue: '',
        recurrenceMaxTimes: '',
        recurrenceNumberOfClients: '',
        recurrenceTimesByUser: '',
        recurrencePublicVoucher: false,
    }

    isValidStartDate = date => date.isAfter(today) || date.isSame(today, 'day');
    isValidEndDate = date => {
        const { startDate } = this.state;
        const isAfterToday = date.isAfter(today) || date.isSame(today, 'day');

        if (startDate) {
            return isAfterToday && startDate && date.isAfter(startDate, 'day');
        }

        return isAfterToday;
    };
    selectedAreas = (locations) => {
        this.setState({ locations });
    };

    selectedServices = (services) => {
        this.setState({ services })
    };

    formatVoucherData = values => {
        const formatted = {
            codeType: values.codeType,
            companyName: values.companyName,
            voucherQtd: values.voucherQtd,
            campaign: values.campaign,
            description: values.description,
            end: moment(values.endDate).toDate(),
            start: moment(values.startDate).toDate(),
            dateRangeStart: moment(values.dateRangeStart).toDate(),
            dateRangeEnd: moment(values.dateRangeEnd).toDate(),
            locations: values.locations,
            services: values.services,
            value: parseInt(values.aquisitionValue, 10) || parseInt(values.recurrenceValue, 10),
        };

        if (values.aquisitionType && values.aquisitionValue) {
            Object.assign(formatted, {
                aquisition: {
                    type: values.aquisitionType,
                    value: parseInt(values.aquisitionValue, 10),
                    maxTimes: parseInt(values.aquisitionMaxTimes, 10),
                    numberOfClients: parseInt(values.aquisitionNumberOfClients, 10),
                    timesByUser: parseInt(values.aquisitionTimesByUser, 10),
                    justNew: true,
                }
            });
        }

        if (values.recurrenceType && values.recurrenceValue) {
            Object.assign(formatted, {
                recurrence: {
                    type: values.recurrenceType,
                    value: parseInt(values.recurrenceValue, 10),
                    maxTimes: parseInt(values.recurrenceMaxTimes, 10),
                    numberOfClients: parseInt(values.recurrenceNumberOfClients, 10),
                    timesByUser: parseInt(values.recurrenceTimesByUser, 10),
                    justNew: false,
                },
            });
        }

        return formatted;
    };



    onSubmit = (e) => {
        const {
            codeType,
            campaign,
            companyName,
            voucherQtd,
            description,
            startDate,
            endDate,
            dateRangeStart,
            dateRangeEnd,
            locations,
            services,
            aquisitionType,
            aquisitionMaxTimes,
            aquisitionNumberOfClients,
            aquisitionPublicVoucher,
            aquisitionTimesByUser,
            aquisitionValue,
            recurrenceMaxTimes,
            recurrenceNumberOfClients,
            recurrencePublicVoucher,
            recurrenceTimesByUser,
            recurrenceType,
            recurrenceValue
        } = this.state;

        const data = {
            codeType,
            campaign,
            companyName,
            voucherQtd,
            description,
            startDate,
            endDate,
            dateRangeStart,
            dateRangeEnd,
            locations,
            services,
            aquisitionType,
            aquisitionMaxTimes,
            aquisitionNumberOfClients,
            aquisitionPublicVoucher,
            aquisitionTimesByUser,
            aquisitionValue,
            recurrenceMaxTimes,
            recurrenceNumberOfClients,
            recurrencePublicVoucher,
            recurrenceTimesByUser,
            recurrenceType,
            recurrenceValue
        };

        e.preventDefault();

        const format = this.formatVoucherData(data);

        batchVoucher(format)
            .then(() => {
                Swal.fire({
                    icon: "success",
                    title: "Sucesso",
                    text: "Voucher criado com sucesso!"
                });
                window.location.href = '/#/vouchers';
            }).catch(error => {
                console.error(error);
                Swal.fire({
                    icon: "error",
                    title: "Opss..",
                    text: "Erro ao criar voucher"
                });
            }).finally(() => {
            })
    }
    render() {
        const {
            codeType,
            campaign,
            companyName,
            voucherQtd,
            description,
            startDate,
            endDate,
            dateRangeStart,
            dateRangeEnd,
            locations,
            services,
            aquisitionType,
            aquisitionMaxTimes,
            aquisitionNumberOfClients,
            aquisitionPublicVoucher,
            aquisitionTimesByUser,
            aquisitionValue,
            recurrenceMaxTimes,
            recurrenceNumberOfClients,
            recurrencePublicVoucher,
            recurrenceTimesByUser,
            recurrenceType,
            recurrenceValue
        } = this.state;

        return (
            <form onSubmit={this.onSubmit}>
                <NotificationSystem ref="notificationSystem" />
                <PageHeader title="Adicionar Voucher" />

                <div className="Form-formGroup">
                    <div className="row">
                        <div className="col-sm-5">
                            <RadioGroup
                                name="codeType"
                                label="Apenas novos usuários *"
                                list={codeOptions}
                                inputData={{
                                    value: codeType,
                                    onChange: (codeType) => this.setState({ codeType })
                                }}
                            />
                        </div>
                        <div className="col-sm-2">
                            <TextField
                                name="campaign"
                                label="Campanha *"
                                inputData={{
                                    value: campaign,
                                    onChange: (campaign) => this.setState({ campaign })
                                }}
                                placeholder="Insira a campanha"
                                replace={replaceCampaign}
                                maxlength={100}
                            />
                        </div>
                        <div className="col-sm-2">
                            <TextField
                                name="companyName"
                                label="Empresa *"
                                inputData={{
                                    value: companyName,
                                    onChange: (companyName) => this.setState({ companyName })
                                }}
                                isV
                                placeholder="Insira o nome da empresa"
                                maxlength={100}
                            />
                        </div>
                        <div className="col-sm-3">
                            <TextField
                                name="voucherQtd"
                                label="Quantidade de vouchers *"
                                required
                                inputData={{
                                    value: voucherQtd,
                                    onChange: (voucherQtd) => this.setState({ voucherQtd })
                                }}
                                placeholder="Insira a quantidade de vouchers"
                                number={true}
                            />
                        </div>
                    </div>
                </div>

                <div className="Form-formGroup">
                    <div className="row">
                        <div className="col-sm-6">
                            <TextField
                                name="description"
                                label="Descrição"
                                inputData={{
                                    value: description,
                                    onChange: (description) => this.setState({ description })
                                }}
                                multiline
                                margin="normal"
                                placeholder="Descrição sobre o voucher"
                                maxlength={200}
                            />
                        </div>
                        <div className="col-sm-3">
                            <div className="Form-labelWrapper">
                                <div className="Form-label">
                                    <span>Data de início *</span>
                                </div>
                            </div>
                            <DateTime
                                name="evaluationDate"
                                viewMode="time"
                                value={startDate}
                                dateFormat="DD/MM/YYYY"
                                isValidDate={this.isValidStartDate}
                                onChange={(e) => this.setState({ startDate: e })}
                                className="Form-input input-date"
                            />
                        </div>
                        <div className="col-sm-3">
                            <div className="Form-labelWrapper">
                                <div className="Form-label">
                                    <span>Data final *</span>
                                </div>
                            </div>
                            <DateTime
                                name="evaluationDate"
                                viewMode="time"
                                value={endDate}
                                dateFormat="DD/MM/YYYY"
                                isValidDate={this.isValidEndDate}
                                onChange={(e) => this.setState({ endDate: e })}
                                className="Form-input input-date"
                            />
                        </div>
                    </div>
                </div>

                <div className="Form-formGroup">
                    <div className="row">
                        <div className="col-sm-3">
                            <div className="Form-labelWrapper">
                                <div className="Form-label">
                                    <span>Horário permitido para uso - início</span>
                                </div>
                            </div>
                            <DateTime
                                name="evaluationDate"
                                viewMode="time"
                                value={dateRangeStart}
                                dateFormat="DD/MM/YYYY"
                                isValidDate={this.isValidStartDate}
                                onChange={(e) => this.setState({ dateRangeStart: e })}
                                className="Form-input input-date"
                            />
                        </div>
                        <div className="col-sm-3">
                            <div className="Form-labelWrapper">
                                <div className="Form-label">
                                    <span>Horário permitido para uso - final</span>
                                </div>
                            </div>
                            <DateTime
                                name="evaluationDate"
                                viewMode="time"
                                value={dateRangeEnd}
                                dateFormat="DD/MM/YYYY"
                                isValidDate={this.isValidEndDate}
                                onChange={(e) => this.setState({ dateRangeEnd: e })}
                                className="Form-input input-date"
                            />
                        </div>
                    </div>
                </div>

                <div className="Form-formGroup">
                    <h3>Aquisição:</h3>
                    <div className="row">
                        <div className="col-sm-4">
                            <RadioGroup
                                name="aquisitionType"
                                label="Tipo *"
                                list={typeOptions}
                                inputData={{
                                    value: aquisitionType,
                                    onChange: (aquisitionType) => this.setState({ aquisitionType })
                                }}
                            />
                        </div>
                        <div className="col-sm-4">
                            <RadioGroup
                                name="aquisitionPublicVoucher"
                                label="Mostrar no aplicativo *"
                                list={yesAndNo}
                                inputData={{
                                    value: aquisitionPublicVoucher,
                                    onChange: (aquisitionPublicVoucher) => this.setState({ aquisitionPublicVoucher })
                                }}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-3">
                            {aquisitionType && aquisitionType === 'P' ?
                                <TextField
                                    name="value"
                                    label="Valor *"
                                    inputData={{
                                        value: aquisitionValue,
                                        onChange: (aquisitionValue) => this.setState({ aquisitionValue })
                                    }}
                                    placeholder="Insira o valor"
                                    percentage={true}
                                /> :
                                <TextField
                                    name="value"
                                    label="Valor *"
                                    inputData={{
                                        value: aquisitionValue,
                                        onChange: (aquisitionValue) => this.setState({ aquisitionValue })
                                    }}
                                    placeholder="Insira o valor"
                                    number={true}
                                />}
                        </div>
                        <div className="col-sm-3">
                            <TextField
                                name="aquisitionMaxTimes"
                                label="Máximo de vezes de uso *"
                                inputData={{
                                    value: aquisitionMaxTimes,
                                    onChange: (aquisitionMaxTimes) => this.setState({ aquisitionMaxTimes })
                                }}
                                placeholder="Insira 0 para ilimitado"
                                number={true}
                            />
                        </div>
                        <div className="col-sm-3">
                            <TextField
                                name="aquisitionTimesByUser"
                                label="Vezes por usuário *"
                                inputData={{
                                    value: aquisitionTimesByUser,
                                    onChange: (aquisitionTimesByUser) => this.setState({ aquisitionTimesByUser })
                                }}
                                placeholder="Insira o número de vezes de uso por usuário"
                                number={true}
                            />
                        </div>
                        <div className="col-sm-3">
                            <TextField
                                name="aquisitionNumberOfClients"
                                label="Máximo de clientes no pedido *"
                                inputData={{
                                    value: aquisitionNumberOfClients,
                                    onChange: (aquisitionNumberOfClients) => this.setState({ aquisitionNumberOfClients })
                                }}
                                placeholder="Insira 0 para indiferente"
                                number={true}
                            />
                        </div>
                    </div>
                </div>

                <div className="Form-formGroup">
                    <h3>Recorrência:</h3>
                    <div className="row">
                        <div className="col-sm-4">
                            <RadioGroup
                                name="recurrenceType"
                                label="Tipo *"
                                list={typeOptions}
                                inputData={{
                                    value: recurrenceType,
                                    onChange: (recurrenceType) => this.setState({ recurrenceType })
                                }}
                            />
                        </div>
                        <div className="col-sm-4">
                            <RadioGroup
                                name="recurrencePublicVoucher"
                                label="Mostrar no aplicativo *"
                                list={yesAndNo}
                                inputData={{
                                    value: recurrencePublicVoucher,
                                    onChange: (recurrencePublicVoucher) => this.setState({ recurrencePublicVoucher })
                                }}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-3">
                            {recurrenceType && recurrenceType === 'P' ?
                                <TextField
                                    name="value"
                                    label="Valor *"
                                    inputData={{
                                        value: recurrenceValue,
                                        onChange: (recurrenceValue) => this.setState({ recurrenceValue })
                                    }}
                                    placeholder="Insira o valor"
                                    percentage={true}
                                /> :
                                <TextField
                                    name="value"
                                    label="Valor *"
                                    inputData={{
                                        value: recurrenceValue,
                                        onChange: (recurrenceValue) => this.setState({ recurrenceValue })
                                    }}
                                    placeholder="Insira o valor"
                                    number={true}
                                />}
                        </div>
                        <div className="col-sm-3">
                            <TextField
                                name="recurrenceMaxTimes"
                                label="Máximo de vezes de uso *"
                                inputData={{
                                    value: recurrenceMaxTimes,
                                    onChange: (recurrenceMaxTimes) => this.setState({ recurrenceMaxTimes })
                                }}
                                placeholder="Insira 0 para ilimitado"
                                number={true}
                            />
                        </div>
                        <div className="col-sm-3">
                            <TextField
                                name="recurrenceTimesByUser"
                                label="Vezes por usuário *"
                                inputData={{
                                    value: recurrenceTimesByUser,
                                    onChange: (recurrenceTimesByUser) => this.setState({ recurrenceTimesByUser })
                                }}
                                placeholder="Insira o número de vezes de uso por usuário"
                                number={true}
                            />
                        </div>
                        <div className="col-sm-3">
                            <TextField
                                name="recurrenceNumberOfClients"
                                label="Máximo de clientes no pedido *"
                                inputData={{
                                    value: recurrenceNumberOfClients,
                                    onChange: (recurrenceNumberOfClients) => this.setState({ recurrenceNumberOfClients })
                                }}
                                placeholder="Insira 0 para indiferente"
                                number={true}
                            />
                        </div>
                    </div>
                </div>
                <AreaService
                    defaultAreas={locations}
                    defaultServices={services}
                    setServices={this.selectedServices}
                    setAreas={this.selectedAreas} />

                <div className="Form-submitWrapper">
                    <div className="row">
                        <div className="col-sm-12">
                            <button className="Form-submit"
                                type="submit">
                                <span>CRIAR NOVO VOUCHER</span>
                                <span className="ss-icon ss-book"></span>
                            </button>
                        </div>
                    </div>
                </div>
            </form >
        );
    }
}

export default NewBatchVoucherForm;
