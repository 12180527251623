const { backendAPI } = require("../helper/apis")

const AreaService = function () {
  const api = backendAPI;
  return {
    changeServicePrice: async (areaId, serviceId, price) => {
      try {
        const result = await api.put(`/v3/bo/area/change-service-price/${areaId}/${serviceId}`, price)
        return result.data;
      } catch (err) {
        console.error(err)
        // const error = {
        //   status: err.response?.status,
        //   message: err.response.data.message ?? err.response.data.error ?? err.response.data.description
        // }
        // throw error;
      }
    },
    getLastPriceChange: async (areaId, serviceId) => {
      try {
        const result = await api.get(`/v3/bo//area/last-price-history/${areaId}/${serviceId}`);
        return result.data;
      } catch (err) {
        console.error(err)
        // const error = {
        //   status: err.response.status,
        //   message: err.response.data.message ?? err.response.data.error ?? err.response.data.description
        // }
        // throw error;
      }
    }
  }

}





module.exports = AreaService();