import React, { Component } from 'react'
import TextField from "../FormFields/TextField"
import DateField from "../FormFields/DateField"
import SelectField from "../FormFields/SelectField"
import moment from 'moment'
import { cacList, sectorList, serviceList } from './InvestimentMetaData'

import "./../../style/form.css"

class InvestimentForm extends Component {
    constructor(props) {
        super(props)

        this.state = {
            value: 0,
            date: moment().format('yyyy-MM-DD'),
            category: '',
            description: '',
            cac: '',
            sector: '',
            service: ''
        }
    }

    handleOnSubmit = (ev) => {
        ev.preventDefault()
        this.props.onSubmit(this.state)

        this.setState({
            value: 0,
            date: moment().format('yyyy-MM-DD'),
            category: '',
            description: '',
            cac: null,
            sector: null,
            service: null
        })
    }

    render() {
        return (
            <form onSubmit={this.handleOnSubmit}>
                <div className="Form-formGroup">
                    <div className="row">
                        <div className="col-sm-6">
                            <TextField
                                name="value"
                                label="Valor do Custo(R$) *"
                                number="true"
                                inputData={{
                                    value: this.state.value,
                                    onChange: value => this.setState({ value })
                                }}
                                placeholder="Insira aqui o valor do custo"
                            />
                        </div>

                        <div className="col-sm-6">
                            <DateField
                                name="date"
                                label="Data de Entrada *"
                                inputData={{
                                    value: this.state.date,
                                    onChange: date => this.setState({ date })
                                }}
                            />
                        </div>

                        <div className="col-sm-6">
                            <TextField
                                name="description"
                                label="Descrição *"
                                required="true"
                                inputData={{
                                    value: this.state.description,
                                    onChange: description => this.setState({ description })
                                }}
                                placeholder="Insira aqui a descrição do custo"
                            />
                        </div>

                        <div className="col-sm-6">
                            <TextField
                                name="catogory"
                                label="Categoria *"
                                inputData={{
                                    value: this.state.category,
                                    onChange: category => this.setState({ category })
                                }}
                                placeholder="Insira aqui a categoria do custo ex: Ferramenta, Serviço"
                            />
                        </div>

                        <div className="col-sm-4">
                            <SelectField
                                name="cac"
                                label="Tipo de CAC *"
                                listValues={cacList}
                                inputData={{
                                    value: this.state.cac,
                                    onChange: cac => this.setState({ cac })
                                }}
                            />
                        </div>

                        <div className="col-sm-4">
                            <SelectField
                                name="sector"
                                label="Setor *"
                                listValues={sectorList}
                                inputData={{
                                    value: this.state.sector,
                                    onChange: sector => this.setState({ sector })
                                }}
                            />
                        </div>

                        <div className="col-sm-4">
                            <SelectField
                                name="service"
                                label="Serviço *"
                                listValues={serviceList}
                                inputData={{
                                    value: this.state.service,
                                    onChange: service => this.setState({ service })
                                }}
                            />
                        </div>
                    </div>
                </div>

                <div className="Form-submitWrapper">
                    <div className="row">
                        <div className="col-sm-12">
                            <button className="Form-submit"
                                type="submit">
                                <span>INSERIR CUSTO</span>
                            </button>
                        </div>
                    </div>
                </div>
            </form>
        )
    }
}

export default InvestimentForm