const services = {
  c100s1000a: { category: "Unhas", name: "Mãos Inverno" },
  c100s1001a: { category: "Unhas", name: "Mãos" },
  c100s1001b: { category: "Unhas", name: "Pés" },
  c100s1000: { category: "Unhas", name: "Pés e Mãos" },
  c102s1000a: { category: "Cabelos", name: "Escova Cabelos Curtos" },
  c102s1000b: { category: "Cabelos", name: "Escova Cabelos Médios e Longos" },
  c102s1000c: { category: "Cabelos", name: "Escova Modelada Cabelos Curtos" },
  c102s1000d: { category: "Cabelos", name: "Escova Modelada Cabelos Médios e Longos" },
  c104s1000: { category: "Depiladora", name: "Limpeza de Sobrancelhas" },
  c104s1001: { category: "Depiladora", name: "Axilas e Buço" },
  c104s1002: { category: "Depiladora", name: "Virilha Íntima" },
  c104s1003: { category: "Depiladora", name: "Meia Perna" },
  c104s1004: { category: "Depiladora", name: "Perna Inteira" },
  c104s1005: { category: "Depiladora", name: "Completa" },
  c106s1010: { category: "Depiladora Masculina", name: "Pernas (masculino)" },
  c106s1011: { category: "Depiladora Masculina", name: "Braços (masculino)" },
  c106s1012: { category: "Depiladora Masculina", name: "Tórax e Abdômen (masculino)" },
  c106s1013: { category: "Depiladora Masculina", name: "Costas (masculino)" },
  c106s1014: { category: "Depiladora Masculina", name: "Nariz, Orelhas e Sobrancelhas (masculino)" },
  c106s1015: { category: "Depiladora Masculina", name: "Axilas (masculino)" },
  c106s1016: { category: "Depiladora Masculina", name: "Completa (masculino)" },
  c101s1000: { category: "Massoterapeuta", name: "Massagem Relaxante" },
  c101s1001: { category: "Massoterapeuta", name: "Modeladora" },
  c101s1002: { category: "Massoterapeuta", name: "Drenagem Linfática" },
  c101s1003: { category: "Massoterapeuta", name: "Drenomodeladora" },
  c101s1004: { category: "Massoterapeuta", name: "Reflexologia" },
  c101s1005: { category: "Massoterapeuta", name: "Desportiva" },
  c101s1006: { category: "Massoterapeuta", name: "Shiatsu" }
};

export default services;
