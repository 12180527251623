import React, { Component, PropTypes } from 'react';

const notAvailable = 'Não informado';

class EditableTextField extends Component {
  constructor(props) {
    super(props);
    const { currentValue, fieldList } = this.props;

    this.state = {
      newValue: "",
      currentValue: currentValue && currentValue.toString(),
      fieldList,
      wasEdited: false
    };
  }

  componentWillMount() {
    const { currentValue, fieldList } = this.props;

    this.setState({
      currentValue: currentValue && currentValue.toString(),
      fieldList,
    });
  }

  componentWillReceiveProps(props) {
    const { currentValue, fieldList } = props;

    this.setState({
      currentValue: currentValue && currentValue.toString(),
      fieldList,
    });
  }

  editField() {
    const { methods } = this.props;
    methods.editField();
  }

  doneEditing() {
    const { methods: { saveField, notify } } = this.props;
    const { newValue } = this.state;
    const fulfill = (action) => {
      if (action.error) {
        if (action.payload.data) {
          notify(action.payload.data.error.description, 'error');
        } else {
          notify(action.payload.message, 'error');
        }
      } else {
        this.setState({
          currentValue: newValue,
          newValue,
          wasEdited: true
        }, () => notify('Valor alterado com sucesso', 'success'));
      }
    };
    const error = () => notify('Ocorreu um erro inesperado', 'error');
    let saveValue = newValue;

    saveField(saveValue, fulfill, error);
  }

  cancelEdit() {
    const { cancelEdit } = this.props.methods;
    cancelEdit();
  }

  onFieldChange = (evt) => {
    this.setState({
      newValue: evt.target.value && evt.target.value.toString()
    });
  }

  getValue(value) {
    let helperValue = value.toString() || "";
    const { fieldList } = this.state;
    const filterResult = fieldList.filter(field => field.value && field.value.toString() === helperValue.toString())

    return filterResult && filterResult[0] && filterResult[0].text || "Não informado"
  }

  render() {
    const { newValue, currentValue, fieldList, wasEdited } = this.state;

    return (
      <div className="EditableField-detail">
        <div className="EditableField-infoWrapper">
          <span className="EditableField-infoDisplay">
            {this.getValue(wasEdited ? newValue : currentValue)}
          </span>
          <span className="EditableField-infoInput">
            <select
              defaultValue={newValue && newValue.toString() || currentValue && currentValue.toString()}
              onChange={this.onFieldChange}>
              <option value="">{notAvailable}</option>)
              {fieldList && fieldList.length > 0 && fieldList.map(field => (
                <option value={field.value && field.value.toString()}>{field.text}</option>
              ))}
            </select>
          </span>
        </div>
        <div className="EditableField-buttonsWrapper">
          <span onClick={() => this.editField()} className="EditableField-btn EditableField-btn--edit glyphicon glyphicon-edit"></span>
          <span className="EditableField-controls">
            <span onClick={() => this.doneEditing()} className="EditableField-btn EditableField-btn--save glyphicon glyphicon-ok"></span>
            <span onClick={() => this.cancelEdit()} className="EditableField-btn EditableField-btn--cancel glyphicon glyphicon-remove"></span>
          </span>
        </div>
      </div>
    );
  }
}

EditableTextField.propTypes = {
  methods: PropTypes.object,
  currentValue: PropTypes.string,
  mask: PropTypes.string,
  type: PropTypes.string
};

export default EditableTextField;
