/* global _ */

import React, { Component, PropTypes } from 'react';
import ReactDOM from 'react-dom';

class CheckboxGroup extends Component {
  constructor(props) {
    super(props);

    this.state = {
      initialValueSet: false
    };
  }

  componentDidMount() {
    const { inputData: { onChange, value } } = this.props;

    if (!_.isArray(value)) {
      // Adds initial value as an empty array for
      // validation purposes
      onChange([]);
    } else {
      this.checkInitialValue(value);
    }
  }

  componentWillReceiveProps(newProps) {
    const { initialValueSet } = this.state;
    const { value, autofilled } = newProps.inputData;

    if (autofilled && !initialValueSet) {
      this.checkInitialValue(value);
      this.setState({ initialValueSet: true });
    }
  }

  checkInitialValue(value) {
    _.forEach(this.refs, node => {
      if (_.includes(value, node.value)) {
        ReactDOM.findDOMNode(node).checked = true;
      }
    });
  }

  onCheckboxChange(checkbox) {
    const { checked } = checkbox;
    const { disabled = false, inputData: { value, onChange } } = this.props;
    let finalValue = [];

    if (!disabled) {
      if (!!value && value.length > 0) {
        if (checked) {
          finalValue = value.concat(checkbox.value);
        } else {
          const valueIndex = value.indexOf(checkbox.value);

          finalValue = [
            ...value.slice(0, valueIndex),
            ...value.slice(valueIndex + 1)
          ];
        }
      } else if (checked) {
        finalValue = [checkbox.value];
      }

      onChange(finalValue);
    }
  }

  onCheckboxClick() {
    // Forces blur trigger so touched flag
    // will be set to true
    const { disabled, inputData: { onBlur } } = this.props;

    if (!disabled && onBlur) onBlur();
  }

  isInvalid() {
    const { inputData } = this.props;
    const { value, valid, touched } = inputData;

    return (
      value &&
      !value.length > 0 &&
      !valid &&
      touched
    );
  }

  renderErrorMessage() {
    const { error } = this.props.inputData;

    if (this.isInvalid()) {
      return (<span className="Form-error">{error}</span>);
    }

    return null;
  }

  renderLabel() {
    const { label, noLabel } = this.props;

    if (!noLabel) {
      return (
        <div className="Form-labelWrapper">
          <div className="Form-label">
            {label}{this.renderErrorMessage()}
          </div>
        </div>
      );
    }

    return null;
  }

  isCheckedClass(value) {
    const formValue = this.props.inputData.value;

    if (formValue && formValue.length > 0) {
      return _.includes(formValue, value.toString()) ? 'is-selected' : '';
    }

    return '';
  }

  wrapperClass() {
    const { disabled, inputData } = this.props;
    const { valid } = inputData;
    const invalidClass = this.isInvalid() ? 'is-error' : '';
    const validClass = valid ? 'is-valid' : '';
    const disabledClass = disabled ? 'is-disabled' : '';

    return `${invalidClass} ${validClass} ${disabledClass}`;
  }

  renderCheckboxes() {
    const { name, list } = this.props;
    const mapCheckboxes = (checkbox, i) => (
      <div className={`Form-choice ${this.isCheckedClass(checkbox.value)}`} key={i}>
        <label htmlFor={`${checkbox.value}-${i}`}
               className="Form-choiceLabel"
               key={i}
        >
          <input type="checkbox"
                 className="Form-checkbox"
                 name={name}
                 value={checkbox.value}
                 id={`${checkbox.value}-${i}`}
                 onChange={evt => this.onCheckboxChange(evt.target)}
                 onClick={() => this.onCheckboxClick()}
                 ref={`checkboxInput-${i}`}
          />
          <div className="Form-fakeCheckbox">
            <div className="ss-icon ss-check"></div>
          </div>
          {checkbox.label}
        </label>
      </div>
    );

    return _.map(list, mapCheckboxes);
  }

  render() {
    const { name } = this.props;
    return (
      <div id={`${name}Wrapper`}
           className={`Form-inputWrapper form-group ${this.wrapperClass()}`}>
        {this.renderLabel()}
        <div className="Form-choiceGroup">
          {this.renderCheckboxes()}
        </div>
      </div>
    );
  }
}

CheckboxGroup.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  inputData: PropTypes.object,
  disabled: PropTypes.bool,
  noLabel: PropTypes.bool,
  list: PropTypes.array
};

export default CheckboxGroup;
