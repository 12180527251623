import React, { Component, PropTypes } from 'react';
import { connect } from 'react-redux';
import { fetchZones } from './../../actions/administrative_zones/fetch_zones';
import ZoneModal from './ZoneModal';
import ZoneMapModal from './ZoneMapModal';

class Zones extends Component {

    constructor(props) {
        super(props);
        this.state = {
            fetchedZones: false,
            err: false
        };
    }

    componentWillMount() {
        this.loadZones().then(() => this.setState({ fetchedZones: true }));
    }

    loadZones() {
        return this.props.fetchZones(this.props.zoneParams || {});
    }


    closeModal() {
        this.setState({
            isZoneModalOpen: false,
            isZoneMapModalOpen: false
        });
    }

    openZoneModal(zone, type) {
        const state = {
            selectedZone: zone
        };
        if (type === 'edit') {
            state.isZoneModalOpen = true;
        }
        if (type === 'map') {
            state.isZoneMapModalOpen = true;
        }
        this.setState(state);
    }

    componentWillReceiveProps(newProps) {
        const { administrativeZones } = newProps;

        if (administrativeZones && administrativeZones.all) {
            this.setState({
                administrativeZones: administrativeZones.all
            })
        }
    }

    renderAreas() {
        if (!this.state.administrativeZones) return;
        const administrativeZones = this.state.administrativeZones;
        const areas = administrativeZones.map(zone => {
            return (
                <div className="panel panel-default col-xs-12" style={{ padding: 15 }}>
                    <div className="col-md-8 col-xs-12">
                        <p>Nome da Zona: {zone.name}</p>
                        <p>Serviço: {zone.serviceId}</p>
                    </div>
                    <div className="col-md-2 col-xs-12">
                        <button onClick={this.openZoneModal.bind(this, zone, 'edit')} className=" btn btn-info">
                            Editar
                            </button>
                    </div>
                    {/* <div className="col-md-2 col-xs-12">
                        <button onClick={this.openZoneModal.bind(this, zone, 'map')} className=" btn btn-info">
                            Ver no Mapa
                            </button>
                    </div> */}
                </div>
            )
        });
        return areas;
    }

    render() {
        return (
            <div className={"col-xs-12"}>
                <div className={"col-xs-12"}>
                    {this.renderAreas()}
                    <ZoneModal
                        zone={this.state.selectedZone}
                        isZoneModalOpen={this.state.isZoneModalOpen}
                        closeModal={this.closeModal.bind(this)}
                    />
                    {/* <ZoneMapModal
                        zone={this.state.selectedZone}
                        isZoneMapModalOpen={this.state.isZoneMapModalOpen}
                        closeModal={this.closeModal.bind(this)}
                    /> */}
                </div>
            </div>
        );
    }
}

Zones.propTypes = {
    polygon: PropTypes.array
};

Zones.contextTypes = {
    router: PropTypes.object.isRequired
};

function mapStateToProps(state) {
    return {
        administrativeZones: state.administrativeZones
    }
}
export default connect(mapStateToProps, {
    fetchZones
})(Zones);