import axios from 'axios';
import config from '../../../config.json';
const env = process.env.REACT_APP_ENVIRONMENT || 'stage';
const BACKEND_URL = config.api[env];

import { getToken } from './../../models/auth';

export const BAN_PARTNER = 'BAN_PARTNER';

export function banPartner(partnerId) {
  const banPartnerPromise = axios.delete(`${BACKEND_URL}/v2/bo/blocked-artists/${partnerId}`,
    {
      headers: { Authorization: `Bearer ${getToken()}` }
    }
  );

  return {
    type: BAN_PARTNER,
    payload: banPartnerPromise,
    meta: { id: partnerId }
  };
}
