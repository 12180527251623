import React, { Component } from "react";
import { Button } from 'react-bootstrap'

import { fetchPurchasePackages } from '../../../actions/package_purchases/fetch_packages';
import PageHeader from '../../../components/Global/PageHeader';
import PackagePurchasesTable from './PackagePurchasesTable/Index';
import PackagePurchaseDetailsModal from './PackagePurchaseDetailsModal';

class PackagePurchases extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loadingPackagePurchases: true,
      packagePurchases: [],
      currentPage: 1,
      filter: '',
      limit: 10, 
      total: 0,
      showPackagePurchaseDetails: false,
      selectedPackagePurchase: {},
    }
  }

  componentDidMount() {
    const { currentPage, limit, filter } = this.state;
    this._fetchPurchasePackages(currentPage, limit, filter);
  }

  _fetchPurchasePackages = async (page, limit, filter) => {
    try {
      this.setState({ loadingPackagePurchases: true });
      const query = `page=${page}&limit=${limit}&filter=${filter}`;
      const { data, headers } = await fetchPurchasePackages(query);

      const packagePurchases = data.map(p => ({
        _id: p._id,
        client: p.user,
        package: p.pack,
        date: p.createdTime,
        voucher: p.voucher,
        payment: p.payment,
        actions: {
          openPackagePurchaseDetails: this.openPackagePurchasesModal,
        },
        packagePurchase: p,
      }));

      this.setState({
        packagePurchases,
        total: headers['x-total-count'],
        currentPage: headers['x-current-page']
      })
    } catch(err) {
      console.error('Load PurchasePackages', err);
    } finally {
      this.setState({
        loadingPackagePurchases: false
      })
    }
  }

  openPackagePurchasesModal = async (packagePurchase) => {
    this.setState({
      showPackagePurchaseDetails: true,
      selectedPackagePurchase: packagePurchase
    });
  }

  closePackagePurchasesModal = async () => {
    this.setState({ showPackagePurchaseDetails: false, selectedPackagePurchase: {} })
  }

  getNextPage = async (page) => {
    const { limit, filter } = this.state;
    await this._fetchPurchasePackages(page + 1, limit, filter);
  }

  handleFormSubmit = async (event) => {
    try {
      const { page, limit, filter } = this.state;
      event.preventDefault();

      await this._fetchPurchasePackages(page, limit, filter)
    } catch(err) {
      console.error('Form submit', err)
    }
  };

  render() {
    const { 
      loadingPackagePurchases, 
      packagePurchases,
      currentPage,
      total,
      limit,
      filter,
      showPackagePurchaseDetails,
      selectedPackagePurchase
    } = this.state;
    return (
      <div>
        <PageHeader title="Compras de Pacote" />
        <div className="row">
          {!loadingPackagePurchases && (
            <div className="col-12">
              <h4>Filtrar compras de pacotes</h4>
              <form onSubmit={this.handleFormSubmit} style={{ display: 'flex', alignItems: 'center' }}>
                <input 
                  type="text" 
                  className="form-control" 
                  placeholder="Nome, e-mail ou id do cliente"
                  value={filter}
                  onChange={(e) => this.setState({ filter: e.target.value })}
                />
                <Button type="submit" bsStyle="info" style={{ height: '100%', marginLeft: '10px' }}>
                  Buscar
                </Button>
              </form>
            </div>
          )}
          <div className="col-12">
            {selectedPackagePurchase && (
              <PackagePurchasesTable 
                data={packagePurchases}
                loading={loadingPackagePurchases}
                currentPage={currentPage}
                total={total}
                limit={limit}
                getNextPage={this.getNextPage} />
            )}
          </div>
          <PackagePurchaseDetailsModal show={showPackagePurchaseDetails} onHide={this.closePackagePurchasesModal} data={selectedPackagePurchase}/>
        </div>
      </div>
    );
  }
}

export default PackagePurchases;