"use strict";
import React, { Component } from "react";
import PageHeader from "../Global/PageHeader";
import Loading from "../Global/Loading/Loading";
import { Modal, Col, Row } from "react-bootstrap";
import "./Campaigns.css";
import Paginate from "../Global/Paginate";
import EditCampaign from "./EditCampaign";

export default class CampaignsList extends Component {
  state = {
    status: "READY",
    areas: [],
    selectedArea: {},
    campaigns: [],
    campaingItem: {},
    showEditModal: false,
    page: 1,
    limit: 9,
    total: 1
  };

  componentWillReceiveProps(newProps) {
    const { areas } = newProps;
    this.setState({ areas });
  }

  handleClose() {
    this.setState({
      showEditModal: false
    });
  }

  newCampaign() {
    this.props.history.push('/campaigns/new');
  }

  editCampaign(campaignId) {
    const campaigns = this.state.campaigns;
    this.setState({
      campaignItem: Object.assign({}, campaigns.filter(campaign => campaign._id == campaignId)[0]),
      cities: this.state.areas,
      showEditModal: true
    });
  };

  deleteCampaign(campaignId) {
    if (window.confirm("Você realmente quer excluir essa campanha?")) {
      this.props.deleteCampaign(campaignId)
        .then(_ => {
          alert("Campanha excluida com sucesso.");
          window.location.reload();
        });
    }
  }

  listCampaigns(areaId, page, limit) {
    this.setState({ status: "LOADING" })
    if (!page)
      page = this.state.page;

    if (!limit)
      limit = this.state.limit;

    this.setState({ page });

    this.props.listCampaigns(areaId, { page, limit })
      .then(response => {
        const { data, totalItems } = response.data;
        this.setState({
          campaigns: data,
          total: totalItems
        });
      }).finally(() => this.setState({ status: "READY" }))
  }

  paginateCampaigns(page, limit) {
    const areaId = this.state.selectedArea._id;
    this.listCampaigns(areaId, page, limit);
  }

  handleAreaChoice(selectedArea) {
    this.setState({ selectedArea });
    this.listCampaigns(selectedArea._id, 1);
  }

  renderCampaigns() {
    const campaigns = this.state.campaigns;
    if (this.state.status === "LOADING") return <Col xs={12} md={12}><h3>Carregando campanhas, aguarde...</h3></Col>
    if (this.state.status === "READY" && (!campaigns || campaigns.length === 0)) return <Col xs={12} md={12}><h3>Nenhuma campanha para visualizar.</h3></Col>
    const renderedCampaigns = campaigns.map((campaign, index) => {
      return (
        <Col md={4} xs={12} id={`${campaign._id}`}>
          <div className="panel panel-default">
            <div className="panel-heading">
              <span>ID: {campaign._id}</span>
              <button
                value={index}
                className={"btn-edit-campaign"}
                onClick={this.editCampaign.bind(
                  this,
                  campaign._id
                )}
              >
                [EDITAR]
              </button>
              <button
                className={"btn-edit-campaign"}
                onClick={this.deleteCampaign.bind(
                  this,
                  campaign._id
                )}
              >
                [EXCLUIR]
              </button>
            </div>
            <div className="panel-body">
              <Col xs={8} xsOffset={2} style={{ padding: "20px 0" }}>
                <img
                  src={campaign.img}
                  alt="Imagem da campanha"
                  className="img-responsive"
                />
              </Col>
              <div className="clearfix" />
              <h3 className={"text-center pack-title"}>{campaign.title}</h3>
              <p className={"text-center"}>{campaign.introduction}</p>
            </div>
          </div>
        </Col>
      );
    });
    return (
      <div className="col-xs-12" style={{ marginTop: "20px" }}>
        {renderedCampaigns}
      </div>
    );
  }

  renderAreas() {
    if (!this.state.areas && this.state.areas.length > 0) {
      return <Loading />;
    }
    return (
      <div className="row">
        <button
          onClick={this.newCampaign.bind(this)}
          className="btn btn-add btn-campaign btn-default"
          type="button"
        >
          Nova Campanha &nbsp; <i className="glyphicon glyphicon-plus" />
        </button>
        <h3 className={"text-center"}>
          Escolha a cidade para gerenciar/visualizar as campanhas:{" "}
        </h3>
        <div className="col-xs-12 text-center">
          {this.state.areas.map(area => {
            return (
              <button
                key={area._id}
                id={area._id}
                className={`btn btn-campaign btn-default ${this.state.selectedArea &&
                  this.state.selectedArea._id === area._id
                  ? "active"
                  : ""
                  }`}
                onClick={this.handleAreaChoice.bind(this, area)}
              >
                {area.city} - {area.stateShort}
              </button>
            );
          })}
        </div>
      </div>
    );
  }

  renderPaginate() {
    return (
      <Paginate
        page={this.state.page}
        pages={Math.ceil(this.state.total / this.state.limit)}
        paginate={this.paginateCampaigns.bind(this)}
      />
    );
  }

  render() {
    const {
      selectedArea,
      campaignItem,
      showEditModal,
      cities
    } = this.state;

    return (
      <Col xs={12}>
        <Row>
          <PageHeader title={"Campanhas"} />
          {this.renderAreas()}
          {Object.keys(this.state.selectedArea).length > 0 ? this.renderCampaigns() : ""}
        </Row>
        <Modal
          show={showEditModal}
          onHide={this.handleClose.bind(this)}
          className="Modal Modal--large"
        >
          <Modal.Header closeButton>
            <Modal.Title>Editar Campanha</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <EditCampaign
              editCampaign={this.props.editCampaign}
              showEditModal={showEditModal}
              campaign={campaignItem}
              cities={cities}
              area={selectedArea}
              handleClose={this.handleClose.bind(this)}
            />
          </Modal.Body>
        </Modal>
        {this.renderPaginate()}
      </Col>
    );
  }
}
