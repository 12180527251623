import config from '../../../config.json';
const env = process.env.REACT_APP_ENVIRONMENT || 'stage';
const BACKEND_URL = config.api[env];
import axios from 'axios';

import { getToken } from "../../models/auth";

export function savePassword(newPassword, token) {
  let url = `${BACKEND_URL}/staff/password`;
  const authToken = `Bearer ${token || getToken()}`
  return axios.post(
        url,
        { newPassword },
        {
          headers: { Authorization:  authToken}
        }
      )
}
