import React from 'react';
import _ from 'underscore';
import { Component, } from 'react';
import { connect } from 'react-redux';
import Griddle from 'griddle-react';

import { fetchAirport } from './../../actions/airport/fetch_airport';
import PageHeader from '../../components/Global/PageHeader';
import airportsMetadata from './Airport_metadata';
import Loading from '../../components/Global/Loading/Loading';

class Airport extends Component {
  constructor(props) {
    super(props);

    this.state = {
      currentPage: 1,
      filter: '',
      airportsArrived: false,
      error: false,
      airports: [],
    };
  }

  componentWillMount() {
    this.getAllAirport();
  }

  componentWillReceiveProps(newProps) { 
    const { airports } = newProps;
    if (airports) {
      this.mapAirport(airports);
    }
  }

  getAllAirport() {
    const success = () => this.setState({ airportsArrived: true });
    const error = () => this.setState({ error: true });
    this.props.fetchAirport(this.state.currentPage, this.state.filter).then(success, error);
  }

  mapAirport(airports) { 
    const mappedAirports = _.map(airports, item => {
      const { _id, address, user, payment } = item;
      return {
        airport: address?.name || "--",
        userName: user?.name || "--",
        idSchedule: _id || "--", 
        idClient: user?._id || "--", 
        updatedAt:payment?.paid? item?.payment?.updatedAt : "--",
        status: payment?.status? payment?.status : "--"
      }
    });


    this.setState({ airports: mappedAirports });
  }

  getPage = nextPage => {
    const currentPage = nextPage + 1;

    this.setState({ currentPage }, this.getAllAirport);
  };


  _handleChange = e => {
    this.setState({ filter: e.target.value });
  };

  _handleClick = () => {
    this.setState({ currentPage: 1 }, this.getAllAirport);
  };

  render() {
    const { airports, airportsArrived} = this.state;

    if (!airportsArrived) {
      return (
        <div>
          <PageHeader title="Aeroportos" />
          <Loading />
        </div>
      );
    }

    return (
      <div>
        <PageHeader title="Aeroportos" />
        <b style={{ marginLeft: 10, marginRight: 10 }}>Filtrar:</b>{' '}
        <input
          value={this.state.filter}
          onChange={this._handleChange}
          type="text"
          width="300px"
          className="form-control"
          placeholder="Cliente, Id do pedido, id do cliente..."
        />
        <button className="btn btn-info" style={{ marginLeft: 10 }} onClick={this._handleClick}>
          BUSCAR
        </button>
        <div className="row">
          <Griddle
            results={airports}
            columnMetadata={airportsMetadata}
            resultsPerPage={5}
            useFixedLayout={false}
            tableClassName={'GriddleTable table'}
            useGriddleStyles={false}
            settingsToggleClassName={'btn btn-default'}
            useExternal
            externalSetFilter={() => { }}
            externalSetPageSize={15}
            externalSetPage={this.getPage}
            externalCurrentPage={this.state.currentPage - 1}
            externalMaxPage={Math.ceil(this.props.totalItems / this.props.itemsPerPage)}
          />
        </div>
      </div>
    );
  }
}

Airport.propTypes = {
};

function mapStateToProps(state) {
  return {
    airports: state.airports.all,
    totalItems: state.airports.totalItems,
    itemsPerPage: state.airports.itemsPerPage,
  };
}

export default connect(mapStateToProps, { fetchAirport })(Airport);
