import { FETCH_CONFIGS } from './../actions/configs/fetch_configs';

const INITIAL_STATE = {
  config: null
};

const configsReducer = (state = INITIAL_STATE, action) => {
  if (action.error) {
    return state;
  }

  switch (action.type) {
  case FETCH_CONFIGS:
    return {
      ...state,
      config: action.payload.data
    };

  default:
    return state;
  }
};

export default configsReducer;
