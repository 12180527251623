import config from '../../config.json';
const env = process.env.REACT_APP_ENVIRONMENT || 'stage';
const BACKEND_URL = config.api[env];
import axios from 'axios';

import { getToken } from './../models/auth';
const auth = { 
  'Authorization': `Bearer ${getToken()}` 
}

export function createInvestiment(data) {
  return axios.post(`${BACKEND_URL}/investments`, 
    data,
    {headers: auth}
  );
}

export function updateInvestiment(id, data) {
  return axios.put(`${BACKEND_URL}/investments/${id}`, 
    data,
    {headers: auth}
  )
}

export function getInvestiments(currentPage, itemsPerPage = 10, minDate, maxDate) {
  return axios.get(`${BACKEND_URL}/investments`, 
  { params: { currentPage, itemsPerPage, minDate, maxDate },
    headers: auth
  })
}