import React, { Component } from 'react';
import Swal from 'sweetalert2';
import moment from 'moment'
import Autocomplete from 'react-google-autocomplete';

import CheckboxGroup from '../../Helpers/CheckboxGroup';
import RadioGroup from '../../Helpers/RadioButtonGroup';
import AreaSelect from '../../Helpers/AreaSelect';

import { weekDaysValueList, scheduleTypes } from '../../../helper/leads';

const defaultLocation = {
  geometry: { type: "Point", coordinates: [] },
  formatted_address: '',
  place: '',
  streetNumber: '',
  neighbor: '',
  city: '',
  uf: '',
  zipCode: '',
  complement: ''
}

class LeadSchedulesConfiguration extends Component {
  constructor(props) {
    super(props);
    this.state = {
      submitting: false,
      days: [],
      aeaaId: '',
      start: moment().format('YYYY-MM-DD'),
      category: '',
      end: '',
      type: '',
      limit: '',
      addressArrived: false,
      timeRange: { start: '', end: '' },
      location: defaultLocation
    }
  }

  populateAddressInfo = (address) => {
    let place, streetNumber, neighbor, city, uf, zipCode, lat, lng, complement = '';

    if (address && address.geometry && address.geometry.location) {
      lat = address.geometry.location.lat && address.geometry.location.lat() || 0;
      lng = address.geometry.location.lng && address.geometry.location.lng() || 0;
    }

    address.address_components.forEach(loc => {
      if (loc.types.indexOf('street_number') !== -1) {
        streetNumber = loc.long_name;
      } else if (loc.types.indexOf('route') !== -1 || loc.types.indexOf('street_address') !== -1) {
        place = loc.long_name;
      } else if (loc.types.indexOf('sublocality_level_1') !== -1 || loc.types.indexOf('sublocality') !== -1 || loc.types.indexOf('neighborhood') !== -1) {
        neighbor = loc.long_name;
      } else if (loc.types.indexOf('administrative_area_level_2') !== -1 || loc.types.indexOf('locality') !== -1) {
        city = loc.long_name;
      } else if (loc.types.indexOf('administrative_area_level_1') !== -1) {
        uf = loc.short_name;
      } else if (loc.types.indexOf('postal_code') !== -1) {
        zipCode = loc.long_name;
      }
    });

    this.setState({
      addressArrived: true,
      location: {
        geometry: { type: "Point", coordinates: [lng, lat] },
        place: place || "",
        streetNumber,
        neighbor,
        city,
        uf,
        zipCode,
        complement,
        formatted_address: address && address.formatted_address || ""
      }
    });
  };

  componentWillReceiveProps(props) {
    if (props && props.scheduleDate && props.scheduleDate._id) {
      const { scheduleDate } = props
      if (scheduleDate) {
        if (scheduleDate.location && scheduleDate.location.place) {
          Object.assign(scheduleDate, { addressArrived: true })
        } else {
          Object.assign(scheduleDate, { location: {} })
        }
      }

      this.setState(scheduleDate)
    }
  }

  onSubmit = async (evt) => {
    try {
      evt.preventDefault();
      this.setState({ submitting: true })
      const { days, start, category, end, type, limit, location, timeRange, _id, areaId } = this.state;

      console.log(type !== 3 ? location : {})
      await this.props.onSubmit({
        days,
        start,
        category,
        end,
        type,
        limit,
        location: +type !== 3 ? location : null,
        timeRange,
        areaId
      },
        _id || undefined
      );
      Swal.fire('Tudo certo', 'Horário cadastrado com sucesso.', 'success');
    } catch (err) {
      let message = "Houve um erro ao cadastrar o endereço";

      if (err.message) {
        message = err.message
      }
      if (err && err.data && err.data.error && err.data.error.description) {
        message = err.data.error.description
      }
      Swal.fire('Ops...', message, 'error');
    } finally {
      this.setState({ submitting: false });
    }
  }

  selectDayOfTheWeek = (days) => {
    this.setState({ days });
  }

  selectScheduleType = (type) => {
    const isOnline = +type === 3;

    const updateState = { type: +type }

    if (isOnline) {
      Object.assign(updateState, {
        location: defaultLocation,
        addressArrived: false
      })
    }

    this.setState(updateState);
  }

  setTimeRange = (evt) => {
    const { timeRange } = this.state;
    if (evt.target.name === "start") timeRange.start = evt.target.value
    else timeRange.end = evt.target.value
    this.setState({ timeRange });
  }

  setAreaId = (value) => {
    this.setState({ areaId: value })
  }

  render() {
    const {
      place,
      streetNumber,
      neighbor,
      city,
      uf,
      zipCode,
      complement
    } = this.state.location || {}

    const { addressArrived, submitting } = this.state
    return (
      <div className="container">
        <h2>Configuração de dias para agendamento de testes</h2>

        <form
          action="submit"
          onSubmit={this.onSubmit}
          className={`Form ${submitting || this.props.loading ? "is-submitting" : ""}`}
        >
          <div className="row">
            <div className="col-xs-3">
              <p>Data de início</p>
              <input
                required
                name="start"
                type="date"
                value={this.state.start}
                onChange={(evt) => { this.setState({ start: evt.target.value }) }}
                style={{ width: '100%' }}
                className="Form-input"
              />
            </div>
            <div className="col-xs-3">
              <p>Data Final</p>
              <input
                required
                name="end"
                type="date"
                value={this.state.end}
                onChange={(evt) => { this.setState({ end: evt.target.value }) }}
                style={{ width: '100%' }}
                className="Form-input"
              />
            </div>
            <div className="col-xs-2">
              <p>Horário de Início</p>
              <input
                required
                name="start"
                type="time"
                value={this.state.timeRange.start}
                onChange={this.setTimeRange}
                style={{ width: '100%' }}
                className="Form-input"
              />
            </div>
            <div className="col-xs-2">
              <p>Horário Final</p>
              <input
                required
                name="end"
                type="time"
                value={this.state.timeRange.end}
                onChange={this.setTimeRange}
                style={{ width: '100%' }}
                className="Form-input"
              />
            </div>
            <div className="col-xs-1">
              <AreaSelect selected={this.state.areaId} onChange={this.setAreaId} />
            </div>
          </div>
          <div className="row">
            <div className="col-xs-7">
              <p>Dias da semana disponíveis:</p>
              <CheckboxGroup onChange={this.selectDayOfTheWeek} selectedItems={this.state.days} itemsList={weekDaysValueList} />
            </div>
            <div className="col-xs-3">
              <p>Selecione uma categoria para o teste: </p>
              <select
                required
                className="Form-input"
                value={this.state.category}
                onChange={(evt) => this.setState({ category: evt.target.value })}
              >
                <option disabled value="">Selecione uma categoria</option>
                <option value="c100">Manicure</option>
                <option value="c101">Massoterapeuta</option>
                <option value="c102">Escovista</option>
                <option value="c104">Depilador(a)</option>
                <option value="c108">Limpeza de Pele</option>
                <option value="c110">Maquiadora</option>
              </select>
            </div>
            <div className="col-xs-2">
              <p>Limite de pessoas</p>
              <input
                required
                name="limit"
                type="number"
                value={this.state.limit}
                onChange={(evt) => { this.setState({ limit: evt.target.value }) }}
                style={{ width: '100%' }}
                className="Form-input"
              />
            </div>
          </div>
          <div className="row">
            <div className="col-xs-4">
              <p>Tipos de testes disponíveis:</p>
              <RadioGroup
                name={"availableScheduleTypes"}
                onChange={this.selectScheduleType}
                selected={this.state.type}
                itemsList={scheduleTypes}
              />
            </div>
            {
              [1, 2].includes(this.state.type) &&
              <div className="col-xs-8">
                Endereço:
                <Autocomplete
                  apiKey={"AIzaSyBdlcgxa3ueG4y5U7qbPUVbcnndWL3Oabc"}
                  placeholder="Insira aqui o endereço do teste"
                  className="Form-input"
                  style={{ width: '100%' }}
                  defaultValue={this.state.location.formatted_address}
                  onPlaceSelected={addr => this.populateAddressInfo(addr)}
                  types={[]}
                  componentRestrictions={{ country: "br" }}
                />
                {addressArrived &&
                  <div>
                    <div className="row" style={{ marginTop: "10px", marginBottom: "10px" }}>
                      <div className="col-sm-4">
                        <input
                          required
                          onChange={(evt) => this.setState({ location: Object.assign(this.state.location, { place: evt.target.value }) })}
                          name="place"
                          label="Rua *"
                          value={place}
                          style={{ width: '100%' }}
                          className="Form-input"
                        />
                      </div>
                      <div className="col-sm-4">
                        <input
                          required
                          name="streetNumber"
                          placeholder="Número"
                          label="Número *"
                          onChange={(evt) => this.setState({ location: Object.assign(this.state.location, { streetNumber: evt.target.value }) })}
                          value={streetNumber}
                          style={{ width: '100%' }}
                          className="Form-input"
                        />
                      </div>
                      <div className="col-sm-4">
                        <input
                          name="complement"
                          label="Complemento"
                          value={complement}
                          onChange={(evt) => this.setState({ location: Object.assign(this.state.location, { complement: evt.target.value }) })}
                          placeholder="Insira aqui o complemento"
                          style={{ width: '100%' }}
                          className="Form-input"
                        />
                      </div>
                    </div>
                    <div className="row" style={{ marginTop: "10px", marginBottom: "10px" }}>
                      <div className="col-sm-3">
                        <input
                          required
                          disabled
                          name="neighbor"
                          label="Bairro *"
                          placeholder="Bairro"
                          value={neighbor}
                          className="Form-input"
                          style={{ width: '100%' }}
                        />
                      </div>
                      <div className="col-sm-3">
                        <input
                          disabled
                          name="city"
                          label="Cidade *"
                          placeholder="Bairro"
                          value={city}
                          className="Form-input"
                          style={{ width: '100%' }}
                        />
                      </div>
                      <div className="col-sm-3">
                        <input
                          disabled
                          required
                          name="uf"
                          label="Estado *"
                          value={uf}
                          className="Form-input"
                          style={{ width: '100%' }}
                        />
                      </div>
                      <div className="col-sm-3">
                        <input
                          disabled
                          required
                          name="zipcode"
                          label="CEP *"
                          value={zipCode}
                          placeholder="Insira aqui o cep"
                          className="Form-input"
                          style={{ width: '100%' }}
                        />
                      </div>
                    </div>
                  </div>
                }
              </div>
            }
          </div>

          <div className="Form-submitWrapper">
            <div className="row">
              <div className="col-sm-12">
                <button
                  className="Form-submit"
                  type="submit"
                  disabled={submitting}
                >
                  <span>{this.props.scheduleDate._id ? "EDITAR HORÁRIO" : "CADASTRAR HORÁRIO"}</span>
                  <span className="ss-calendar ss-icon" />
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    )
  }
}

export default LeadSchedulesConfiguration;