/* global _, LANG */
import React, { Component } from "react";

import NewVoucherForm from '../../components/Vouchers/NewVoucherForm';

class NewVoucher extends Component {
  render() {
    return (
      <div>
        <NewVoucherForm />
      </div>
    );
  }
}

export default NewVoucher;
