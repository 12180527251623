import React from "react";
import DateTime from "react-datetime";
import moment from "moment";

export default class RealtimeInputField extends React.Component {
  render() {
    return (
      <div className="form-group form-inline">
        <div className="col-xs-5">
          <label>{this.props.serviceName}: </label>
        </div>
        <div className="col-xs-2">
          <div className="input-group">
            <input
              data-serviceid={this.props.serviceId}
              type="number"
              value={this.props.value}
              onChange={this.props.onChange}
              className="form-control"
            />
            <span className="input-group-addon">%</span>
          </div>
        </div>
        <div className="col-xs-4">
          <div className="input-group">
            <DateTime
              data-serviceid={this.props.serviceId}
              isValidDate={date => date.isAfter(moment()) || date.isSame(moment(), "day")}
              value={moment(this.props.expiration)}
              locale="pt-br"
              utc={true}
              closeOnSelect={true}
              onChange={this.props.onChangeDate}
            />
            <span className="input-group-addon">Validade</span>
          </div>
        </div>
        <button
          data-serviceid={this.props.serviceId}
          className="btn btn-success"
          onClick={this.props.onSave}
          disabled={this.props.loading}
        >
          {this.props.loading ? "Salvando" : "Salvar"}
        </button>
      </div>
    );
  }
}
