import axios from 'axios';
import config from '../../../config.json';
const env = process.env.REACT_APP_ENVIRONMENT || 'stage';
const BACKEND_URL = config.api[env];

import { getToken } from '../../models/auth';

export const FETCH_PRICES_BY_AREA = 'FETCH_PRICES_BY_AREA';

export function fetchPriceByAreaId(areaId) {
    return {
        type: FETCH_PRICES_BY_AREA,
        payload: axios.get(`${BACKEND_URL}/v2/prices/area/${areaId}`, {
            headers: { Authorization: `Bearer ${getToken()}` }
        })
      };
}