import axios from 'axios';
const API_ENV = process.env.REACT_APP_ENVIRONMENT || 'stage';

export function fetchBalance() {
    const env = {
        stage: {
            url: "https://sandbox.moip.com.br/v2/balances",
            token: "Mjg5VFdITTJOSzVSUk1SS0ZVQ0tCSFdJQkhLWlBJWVk6NlBHRUtDRlRJMkZDS1FWV04zVUVRMFdSSllYT0dTTDhNWERIVEFTUg=="
        },
        production: {
            url: "https://api.moip.com.br/v2/balances",
            token: "VFlJS05TVEVNTFZTN0kxSU5VVTJGR0laR0dDRkI2S1o6UkFJU1VBWVhWOEFLS0taTVNDWk1BVk9GM1dRMlRCQUVPTERUSFBEVw=="
        }
    };

    const environment = API_ENV === "production" ? "production" : "stage"

    return axios.get(env[environment].url, {
        headers: { Authorization: `Basic ${env[environment].token}` },
        params: {
            itemsPerPage: 999999
        }
    });
}
