/* global _ */
import S from 'string';

const capitalizeAll = (text, splitChar) => {
  if (text && splitChar) {
    const textArray = text.split(splitChar);
    const mapArray = word => S(word).capitalize().s;

    return _.map(textArray, mapArray).join(splitChar);
  }

  return text;
};

export default { capitalizeAll };
