const API_ENV = process.env.REACT_APP_ENVIRONMENT || 'staging';
const env = process.env.REACT_APP_ENVIRONMENT || 'stage';
import config from '../../../../config.json'
const BACKOFICCE_V2 = config.backofficeV2[env];
import React, { Component } from 'react';
import { loggedIn, userIsAdmin } from './../../../models/auth.js';
import NavbarLink from './NavbarLink';
import { Grid } from 'react-bootstrap'

// styles
import './Navbar.css';

const subMenuItemsSchedules = [
  {
    linkTo: '/#/schedules/combos',
    iconClass: 'glyphicon glyphicon-th-large',
    text: 'Combos'
  },
  {
    linkTo: `${BACKOFICCE_V2}/physicalSchedules`,
    iconClass: 'ss-icon ss-calendar',
    text: 'Espaço físico',
  },
];

const subMenuItemsClients = [
  {
    linkTo: '/#/send-push-client',
    iconClass: 'ss-icon ss-phone',
    text: 'Enviar Push Notification'
  }, {
    linkTo: '/#/investiments',
    iconClass: 'glyphicon glyphicon-usd',
    text: 'Investimentos'
  },
  {
    linkTo: '/#/mgm/edit',
    iconClass: 'glyphicon glyphicon-sunglasses',
    text: 'MGM'
  },
  {
    linkTo: '/#/airport',
    iconClass: 'glyphicon glyphicon-plane',
    text: 'Aeroporto'
  },
];

const subMenuItemsArtists = [
  {
    linkTo: '/#/new-artist',
    iconClass: 'ss-icon ss-plus',
    text: 'Adicionar profissional'
  },
  {
    linkTo: '/#/artist/import-batch',
    iconClass: 'ss-icon ss-upload',
    text: 'Importar pagamentos manuais'
  },
  {
    linkTo: '/#/send-push',
    iconClass: 'ss-icon ss-phone',
    text: 'Enviar Push Notification'
  },
  {
    linkTo: '/#/challenges',
    iconClass: 'ss-icon ss-box',
    text: 'Desafios (Gamificação)'
  },
  {
    linkTo: '/#/artist/pgm',
    iconClass: 'glyphicon glyphicon-sunglasses',
    text: 'PGM'
  },
  {
    linkTo: '/#/artist/pgp',
    iconClass: 'glyphicon glyphicon-sunglasses',
    text: 'PGP'
  }
];

const subMenuItemsVouchers = [
  {
    linkTo: '/#/new-voucher',
    iconClass: 'ss-icon ss-plus',
    text: 'Adicionar voucher'
  },
  {
    linkTo: '/#/voucher-batch',
    iconClass: 'ss-icon ss-plus',
    text: 'Adicionar voucher em quantidade'
  }
];

const subMenuSettingsMap = [
  {
    linkTo: '/#/campaigns',
    iconClass: 'glyphicon glyphicon-bullhorn',
    text: 'Campanhas'
  },
  {
    linkTo: '/#/payments',
    iconClass: 'glyphicon glyphicon-stats',
    text: 'Pagamentos'
  },
  {
    linkTo: '/#/staff',
    iconClass: 'glyphicon glyphicon-calendar',
    text: 'Acessos'
  },
  {
    linkTo: '/#/change-password',
    iconClass: 'ss-key',
    text: 'Trocar Senha'
  },
  {
    linkTo: '/#/logout',
    iconClass: 'ss-key',
    text: 'Sair'
  }
];

const subMenuSettingAtuationArea = [
  {
    linkTo: '/#/services',
    iconClass: 'glyphicon glyphicon-list-alt',
    text: 'Serviços Disponíveis'
  },
  {
    linkTo: '/#/service-category',
    iconClass: 'glyphicon glyphicon glyphicon-th',
    text: 'Categorias dos serviços'
  },
  {
    linkTo: '/#/areas',
    iconClass: 'glyphicon glyphicon-pushpin',
    text: 'Cidades Disponíveis'
  },
  {
    linkTo: '/#/administrative-zone-new',
    iconClass: 'glyphicon glyphicon-usd',
    text: 'Preço Dinâmico - Agendado'
  },
  {
    linkTo: '/#/administrative-zone',
    iconClass: 'glyphicon glyphicon-usd',
    text: 'Zonas Administrativas'
  },
  {
    linkTo: '/#/realtime-dynamic-prices',
    iconClass: 'glyphicon glyphicon-usd',
    text: 'Preço Dinâmico - Singu Agora'
  },
  {
    linkTo: '/#/polygons',
    iconClass: 'glyphicon glyphicon-stop',
    text: 'Polígonos'
  }
]

const subMenuItemsPackages = [
  {
    linkTo: '/#/packages/purchase',
    iconClass: 'glyphicon glyphicon-list',
    text: 'Compras de pacote'
  }
];

const subMenuItemsLeads = [
  {
    linkTo: '/#/new-lead',
    iconClass: 'ss-icon ss-plus',
    text: 'Adicionar lead'
  }, {
    linkTo: '/#/leads/report',
    iconClass: 'glyphicon glyphicon-signal',
    text: 'Relatório'
  }, {
    linkTo: '/#/leads/evaluation/schedules/config/list',
    iconClass: 'glyphicon ss-calendar',
    text: 'Datas para Agendamento'
  },
  {
    linkTo: '/#/leads-calendar',
    iconClass: 'ss-icon ss-calendar',
    text: 'Visualizar calendário'
  }
];

const menuAll = [
  {
    linkTo: '/#/schedules',
    iconClass: 'ss-icon ss-calendar',
    text: 'Agendamentos',
    subMenu: true,
    subMenuItems: subMenuItemsSchedules
  },
  {
    linkTo: '/#/realtime-orders',
    iconClass: 'glyphicon glyphicon-flash',
    text: 'Singu Agora',
    subMenu: false,
    subMenuItems: [],
  },
  {
    linkTo: '/#/transfers',
    iconClass: 'glyphicon glyphicon-transfer',
    text: 'Transferências',
    subMenu: false,
    subMenuItems: [],
  },
  {
    linkTo: `${BACKOFICCE_V2}/vouchers`,
    iconClass: 'glyphicon glyphicon-tag',
    text: 'Vouchers',
    subMenu: true,
    subMenuItems: subMenuItemsVouchers
  },
  {
    linkTo: '/#/packages',
    iconClass: 'ss-icon ss-box',
    text: 'Pacotes',
    subMenu: true,
    subMenuItems: subMenuItemsPackages
  },
  {
    linkTo: `${BACKOFICCE_V2}/users`,
    iconClass: 'ss-icon ss-users',
    text: 'Clientes',
    subMenu: true,
    subMenuItems: subMenuItemsClients
  },
  {
    linkTo: '/#/artists',
    iconClass: 'glyphicon glyphicon-briefcase',
    text: 'Profissionais',
    subMenu: true,
    subMenuItems: subMenuItemsArtists
  },
  {
    linkTo: '/#/leads',
    iconClass: 'ss-icon ss-phone',
    text: 'Leads',
    subMenu: true,
    subMenuItems: subMenuItemsLeads
  },
  {
    linkTo: '/#/dynamic-price',
    iconClass: 'glyphicon glyphicon-globe',
    text: 'Area de Atuação',
    subMenu: true,
    subMenuItems: subMenuSettingAtuationArea
  },
  {
    linkTo: '/#/settings',
    iconClass: 'glyphicon glyphicon-cog',
    text: 'Ajustes',
    subMenu: true,
    subMenuItems: subMenuSettingsMap
  }
];

class Navbar extends Component {
  constructor(props) {
    super(props);
  }

  filterMenuByProfile(menu) {
    if (!userIsAdmin()) {
      const permissions = JSON.parse(localStorage.permissions);
      const hasPermission = (path) => {
        const adjustedPath = path.replace("/#/", "").replace(`${BACKOFICCE_V2}/`, "")

        const routes = permissions.map(p => {
          return p.router.substring(1)
        })
        const includes = routes.includes(adjustedPath);
        return includes
      };

      const filterMenu = (menu) => (menu || []).map(item => {
        const has = hasPermission(item.linkTo);
        const updatedItem = { ...item };

        if (updatedItem.subMenuItems) {
          updatedItem.subMenuItems = filterMenu(updatedItem.subMenuItems).filter(subItem => hasPermission(subItem.linkTo));
        }

        if (!has && updatedItem.subMenuItems && updatedItem.subMenuItems.length > 0) {
          updatedItem.notClickable = true;
        } else {
          updatedItem.notClickable = false;
        }

        return has ? updatedItem : null;
      }).filter(Boolean);

      return filterMenu(menu);
    }
    return menu;
  }

  navbarClass() {
    return API_ENV !== 'production' ? 'staging' : '';
  }
  renderNavigation() {
    if (loggedIn()) {
      return (
        <ul className="Navbar-linksWrapper">
          {this.filterMenuByProfile(menuAll).map((item, index) => (
            <NavbarLink
              key={index}
              linkTo={item.linkTo}
              iconClass={item.iconClass}
              text={item.text}
              subMenu={item.subMenu}
              subMenuItems={item.subMenuItems}
              notClickable={item.notClickable}
            />
          ))}
        </ul>
      );
    } else {
      return (
        <ul className="Navbar-linksWrapper">
          <NavbarLink linkTo="login" iconClass="ss-icon ss-user" text="Login" />
        </ul>
      );
    }

  }


  render() {
    const version = `v${process.env.REACT_APP_VERSION}`;

    return (
      <div>
        <nav className={`Navbar ${this.navbarClass()}`}>
          <Grid>
            {this.renderNavigation()}
            <div className="Navbar-appVersion">{version}</div>
          </Grid>
        </nav>
        <Grid className="main-container">
          {this.props.children}
        </Grid>
      </div>
    );
  }
}

export default Navbar;
