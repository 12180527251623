import axios from 'axios';
import config from '../../../config.json';
const env = process.env.REACT_APP_ENVIRONMENT || 'stage';
const BACKEND_URL = config.api[env];

import { getToken } from '../../models/auth';

export function addPointsToArtist(artistId, points, reason) {
    return axios.post(`${BACKEND_URL}/gamification`,
        { artistId, points, reason },
        {
            headers: { Authorization: `Bearer ${getToken()}` }
        }
    );
}