/* global _ */
import React, { PropTypes, Component } from 'react';
import EditableField from './../../EditableField/EditableField';
import formatter from './../../../helper/formatters';
import { leadTechniques, scheduleTypes, leadStatusList, areasList } from '../../../helper/leads';

// styles
import './LeadDetails.css';
import moment from 'moment';

const notAvailable = 'Não informado';

class LeadDetails extends Component {

  constructor(props) {
    super(props);

    const { migrateCallback, updateCallback, router, lead = {}, getAllLeads } = props;

    if (!lead.address) {
      lead.address = {}
    }

    const mapServices = (service) => service.serviceName;
    const mapDisponibility = (weekDay) => {
      const days = [
        'Domingo',
        'Segunda-feira',
        'Terça-feira',
        'Quarta-feira',
        'Quinta-feira',
        'Sexta-feira',
        'Sábado'
      ]; // TODO: nomes dos dias de semana i18n

      return days[+weekDay];
    };

    const intermediateMappedServices = _.map(typeof lead.services === "object" ? lead.services : [], mapServices);
    const intermediateMappedDisponibility = _.map(lead.weekDisponibility || [], mapDisponibility);

    this.state = {
      lead,
      migrateCallback, updateCallback, router, getAllLeads,
      mappedServices: intermediateMappedServices.length > 0 ? intermediateMappedServices.join(', ') : lead.services || '',
      mappedDisponibility: intermediateMappedDisponibility.length > 0 ? intermediateMappedDisponibility.join(', ') : '',
      evaluationLocationList: props.evaluationLocationList || []
    };
  }

  componentWillMount() {
    this.setState({ evaluationLocationList: this.props.evaluationLocationList || [] });
  }

  componentWillReceiveProps(newProps) {
    const { evaluationLocationList } = newProps;
    this.setState({ evaluationLocationList: evaluationLocationList || [] });
  }

  onUpdate(data) {
    if (data.field === 'techniques' && data.techniques === '') {
      return alert('Uma profissão é obrigatória');
    }

    return this.state.updateCallback(this.state.lead._id, data)
      .then((response) => {
        this.state.getAllLeads();
        return response;
      });
  }

  migrateLead() {
    this.state.migrateCallback(this.state.lead);
    this.state.router.push('/new-artist');
  }

  renderStatusHistory = () => {
    const { status: statusList } = this.state.lead;

    if (!statusList || typeof statusList !== "object" || statusList.length < 1) {
      return (
        <div className="row">
          <div className="col-xs-12">
            <p>Nenhuma atualização de status registrada.</p>
          </div>
        </div>
      )
    }

    return (
      <div className="row">
        {statusList.map(status => (
          <div className="col-xs-12 col-sm-6 col-md-6 col-lg-4">
            <div className="lead-status-card">
              <h4>
                <span className="label label-info">
                  {status.description}
                </span>
              </h4>
              <div className="lead-status-card-topic">
                <b>Data de alteração</b>
                <p>{moment(status.date).format('DD/MM/YYYY [às] HH:mm:ss')}</p>
              </div>
              <div className="lead-status-card-topic">
                <b>Alterado por</b>
                <p>{status.blame ? status.blame.email : 'Informação inexistente'}</p>
              </div>
            </div>
          </div>
        ))}
      </div>
    )
  }

  renderEvaluationsHistory = () => {
    const { evaluationHistory } = this.state.lead;

    if (!evaluationHistory) {
      return (
        <div className="row">
          <div className="col-xs-12">
            <p>Nenhum teste registrado.</p>
          </div>
        </div>
      )
    }

    return (
      <div className="row">
        {evaluationHistory.map(evaluation => (
          <div className="col-xs-12 col-sm-6 col-md-6 col-lg-4">
            <div className="lead-status-card">
              <h4>
                <span className="label label-info">
                  {evaluation.type && evaluation.type.description}
                </span>
              </h4>
              <div className="lead-status-card-topic">
                <b>Data de alteração</b>
                <p>{evaluation.date ? moment(evaluation.date).format(`DD/MM/YYYY [às] HH:mm:ss`) : 'Sem data cadastrada'}</p>
              </div>
              <div className="lead-status-card-topic">
                <b>Alterado por</b>
                <p>{evaluation.blame ? evaluation.blame.email : 'Informação inexistente'}</p>
              </div>
            </div>
          </div>
        ))}
      </div>
    );
  }

  renderMeetLink = () => {
    
    const { evaluation } = this.state.lead;
    if (!evaluation || evaluation?.type?.code != 3) return;

    return (
      <div className="col-sm-4">
        <EditableField initialValue={evaluation.meetLink || "Nenhum link foi adicionado"}
          fieldTitle="Link do teste"
          fieldName="meetLink"
          defaultValue={notAvailable}
          type="text"
          parentName="evaluation"
          parentObject={evaluation}
          updateCallback={this.onUpdate.bind(this)}
        />
      </div>
    );
  }

  render() {
    const { lead } = this.state;

    const cities = (() => {
      if (!lead.areaIds || lead.areaIds.length === 0) return '-';

      return lead.areaIds.reduce((total, areaId) => {
        const area = areasList.find(area => area.value === areaId);

        if (!area) throw { description: 'Área nao encontrada' };

        return total += `${area.text} `;
      }, '');
    })();

    return (
      <div className="LeadDetails">
        <div className="LeadDetails-section">
          <div className="LeadDetails-title">Dados pessoais</div>
          <div className="row">
            <div className="col-sm-4">
              <div className="LeadDetails-displayLabel">Nome</div>
              <div className="LeadDetails-displayInfo">{lead.name}</div>
            </div>
            <div className="col-sm-4">
              <div className="LeadDetails-displayLabel">E-mail</div>
              <div className="LeadDetails-displayInfo">{lead.email}</div>
            </div>
            <div className="col-sm-2">
              <div className="LeadDetails-displayLabel">Telefone</div>
              <div className="LeadDetails-displayInfo">{`(${lead.phone.areaCode}) ${lead.phone.number}`}</div>
            </div>

            <div className="col-sm-2">
              <EditableField initialValue={lead.indicationCode}
                fieldTitle="Indicação"
                fieldName="indicationCode"
                defaultValue={notAvailable}
                type="text"
                updateCallback={this.onUpdate.bind(this)}
              />
            </div>
          </div>
        </div>

        <div className="LeadDetails-section">
          <div className="LeadDetails-title">Dados profissionais</div>
          <div className="row">
            <div className="col-sm-4">
              <div className="LeadDetails-displayOnly">
                <div className="LeadDetails-displayLabel">Cidades de atendimento</div>
                <div className="LeadDetails-displayInfo">{cities}</div>
              </div>
            </div>
            <div className="col-sm-4">
              <div className="LeadDetails-displayOnly">
                <div className="LeadDetails-displayLabel">Tempo de experiência</div>
                <div className="LeadDetails-displayInfo">{lead.experience}</div>
              </div>
            </div>
            <div className="col-sm-4">
              <EditableField initialValue={lead.techniques}
                fieldTitle="Profissão"
                fieldName="techniques"
                type="select"
                fieldList={leadTechniques}
                updateCallback={this.onUpdate.bind(this)}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-sm-4">
              <div className="LeadDetails-displayOnly">
                <div className="LeadDetails-displayLabel">Cursos, workshops e especializações</div>
                <div className="LeadDetails-displayInfo">{lead.specializations}</div>
              </div>
            </div>
            <div className="col-sm-4">
              <div className="LeadDetails-displayOnly">
                <div className="LeadDetails-displayLabel">Possui certificado</div>
                <div className="LeadDetails-displayInfo">{lead.hasCertification ? 'Sim' : 'Não'}</div>
              </div>
            </div>
            <div className="col-sm-4">
              <div className="LeadDetails-displayOnly">
                <div className="LeadDetails-displayLabel">Outra cidade</div>
                <div className="LeadDetails-displayInfo">{lead.areaIds && lead.areaIds.length === 0 ? lead.anotherCity : '-'}</div>
              </div>
            </div>
          </div>
        </div>

        <div className="LeadDetails-section">
          <div className="LeadDetails-title">Endereço</div>
          <div className="row">
            <div className="col-sm-4">
              <EditableField initialValue={lead.address.city}
                fieldTitle="Cidade"
                fieldName="city"
                defaultValue={notAvailable}
                type="text"
                parentName="address"
                parentObject={lead.address}
                updateCallback={this.onUpdate.bind(this)}
              />
            </div>
            <div className="col-sm-4">
              <EditableField initialValue={lead.address.uf}
                fieldTitle="Estado"
                fieldName="uf"
                defaultValue={notAvailable}
                type="text"
                parentName="address"
                parentObject={lead.address}
                updateCallback={this.onUpdate.bind(this)}
              />
            </div>
            <div className="col-sm-4">
              <EditableField initialValue={lead.address.neighborhood || lead.address.neighbor}
                fieldTitle="Bairro"
                fieldName="neighborhood"
                defaultValue={notAvailable}
                type="text"
                parentName="address"
                parentObject={lead.address}
                updateCallback={this.onUpdate.bind(this)}
              />
            </div>
          </div>

          <div className="row">
            <div className="col-sm-4">
              <EditableField initialValue={lead.address.streetName || lead.address.place}
                fieldTitle="Rua"
                fieldName="streetName"
                defaultValue={notAvailable}
                type="text"
                parentName="address"
                parentObject={lead.address}
                updateCallback={this.onUpdate.bind(this)}
              />
            </div>
            <div className="col-sm-4">
              <EditableField initialValue={lead.address.streetNumber}
                fieldTitle="Número"
                fieldName="streetNumber"
                defaultValue={notAvailable}
                type="text"
                parentName="address"
                parentObject={lead.address}
                updateCallback={this.onUpdate.bind(this)}
              />
            </div>
            <div className="col-sm-4">
              <EditableField initialValue={lead.address.complement}
                fieldTitle="Complemento"
                fieldName="complement"
                defaultValue={notAvailable}
                type="text"
                parentName="address"
                parentObject={lead.address}
                updateCallback={this.onUpdate.bind(this)}
              />
            </div>
          </div>

          <div className="row">
            <div className="col-sm-12">
              <EditableField initialValue={lead.address.zipcode}
                fieldTitle="CEP"
                fieldName="zipcode"
                defaultValue={notAvailable}
                type="zipcode"
                parentName="address"
                parentObject={lead.address}
                updateCallback={this.onUpdate.bind(this)}
              />
            </div>
          </div>
        </div>

        <div className="LeadDetails-section">
          <div className="LeadDetails-title">Status do Lead</div>
          <div className="row">
            <div className="col-sm-4">
              <EditableField
                initialValue={lead.status && lead.status[0] && +lead.status[0].code || ""}
                fieldTitle="Status do lead"
                fieldName="status"
                defaultValue={notAvailable}
                type="select"
                fieldList={leadStatusList}
                updateCallback={this.onUpdate.bind(this)}
              />
            </div>
            <div className="col-sm-4">
              <EditableField initialValue={lead.statusDescription || ""}
                fieldTitle="Motivo do status"
                fieldName="statusDescription"
                defaultValue={notAvailable}
                type="text"
                parentName="status"
                parentObject={lead.statusDescription}
                updateCallback={this.onUpdate.bind(this)}
              />
            </div>
            {this.renderMeetLink()}
            {/* <div className="col-sm-4">
              <EditableField initialValue={lead.statusDescription || ""}
                fieldTitle="Link do teste"
                fieldName="meetLink"
                defaultValue={notAvailable}
                type="text"
                parentName="status"
                parentObject={lead.meetLink}
                updateCallback={this.onUpdate.bind(this)}
              />
            </div> */}
          </div>

          <div className="row">
            <div className="col-sm-4">
              <EditableField
                initialValue={lead.evaluation && lead.evaluation.type && lead.evaluation.type.code || ""}
                fieldTitle="Tipo do Teste"
                fieldName="evaluationType"
                defaultValue={notAvailable}
                type="select"
                fieldList={scheduleTypes}
                updateCallback={this.onUpdate.bind(this)}
              />
            </div>
            <div className="col-sm-4">
              <EditableField
                initialValue={lead.evaluation && lead.evaluation.date || ""}
                fieldTitle="Data e Hora"
                fieldName="date"
                defaultValue={notAvailable}
                type="dateAndTime"
                updateCallback={this.onUpdate.bind(this)}
              />
            </div>
            <div className="col-sm-4">
              <EditableField
                initialValue={lead.evaluation && lead.evaluation._id || ""}
                fieldTitle="Local do Teste"
                fieldName="evaluationLocation"
                defaultValue={notAvailable}
                type="select"
                fieldList={this.state.evaluationLocationList}
                updateCallback={this.onUpdate.bind(this)}
              />
            </div>
          </div>

          <div className="row" style={{ marginTop: '0.5rem' }}>
            <div className="col-xs-12">
              <div className="LeadDetails-title" style={{ marginBottom: '0.5rem' }}>Histórico do Status</div>
            </div>
            <div className="col-xs-12">
              {this.renderStatusHistory()}
            </div>
          </div>
          <div className="row" style={{ marginTop: '0' }}>
            <div className="col-xs-12">
              <div className="LeadDetails-title" style={{ marginBottom: '0.5rem' }}>Histórico de Testes</div>
            </div>
            <div className="col-xs-12">
              {this.renderEvaluationsHistory()}
            </div>
          </div>
        </div >

        <div className="row">
          <div className="col-sm-12">
            <div className="LeadDetails-migrateLeadBtnWrapper">
              <button className="LeadDetails-migrateLeadBtn" onClick={this.migrateLead.bind(this)}>
                <span>Transformar em artista</span>
                <span className="ss-icon ss-users"></span>
              </button>
            </div>
          </div>
        </div>
      </div >
    );
  }
}

LeadDetails.propTypes = {
  updateCallback: PropTypes.func,
  resetPartner: PropTypes.func,
  resetArtist: PropTypes.func,
  lead: PropTypes.object
};

export default LeadDetails;