import Griddle from "griddle-react";
import React, { Component } from "react";
import { Modal } from "react-bootstrap";

import StaffForm from "./Form";

import { googleAuthorizationFlow } from '../../helper/google/authorization';
export default class ListStaffComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
      staff: {},
      filterByName: "",
    };
  }

  profiles = {
    administrator: "Administrador",
    staff: "Acesso básico",
    marketing: "Marketing",
    operation: "Operações",
    finance: "Financeiro",
    operationHead: "Head Operacional",
    financeHead: "Head Financeiro",
  }

  handleClose = () => {
    this.setState({ showModal: false, staff: {} });
  };

  showModal = async (event) => {
    try {
      const eventTarget = event.target;

      const scopes = ['https://www.googleapis.com/auth/admin.directory.user'];

      //await googleAuthorizationFlow(scopes);

      return this.setState({
        showModal: true,
        staff: JSON.parse(eventTarget.name || "{}"),
      });
    } catch (err) {
      console.error(err);
    }
  };

  handleFilterByNameChange = (e) => {
    this.setState({ filterByName: e.target.value });
  };



  render() {
    const { staffs } = this.props;
    const { filterByName } = this.state;
    const filteredStaffs = staffs.filter(
      (staff) =>
        staff.name.toLowerCase().includes(filterByName.toLowerCase())
    );

    return (
      <div>
        <div className="row">
          <div className="col-xs-10">
            <h1>Acessos</h1>
          </div>
          <div className="col-xs-2 align-right">
            <h1>
              <button className="btn btn-default" onClick={this.showModal}>
                Adicionar usuário
              </button>
            </h1>
          </div>
        </div>
        <div>
          <div>
            <b style={{ marginRight: 10 }}>Filtrar:</b>
            <input
              className="form-control"
              type="text"
              width="300px"
              value={filterByName}
              onChange={this.handleFilterByNameChange}
              placeholder="Pesquisar por nome"
            />
          </div>
        </div>
        <Griddle

          results={filteredStaffs}
          filterPlaceholderText={"Filtrar Resultados"}
          showFilter={true}
          noDataMessage={"Não há dados para serem exibidos"}
          resultsPerPage={10}
          useFixedLayout={false}
          columnMetadata={[
            {
              columnName: "_id",
              displayName: "Id",
            },
            {
              columnName: "name",
              displayName: "Nome",
            },
            {
              columnName: "email",
              displayName: "Email",
            },
            {
              columnName: "type",
              displayName: "Perfil",
              customComponent: (props) => {
                return (<div>{this.profiles[props.data]}</div>)
              },
            },
            {
              columnName: "active",
              displayName: "Ativo?",
            },
            {
              columnName: "actions",
              displayName: "Ações",
              customComponent: (props) => {
                const { rowData } = props;
                return (
                  <button className="btn-info" name={JSON.stringify(rowData)} onClick={this.showModal}>
                    Editar
                  </button>
                );
              },
            },
          ]}
          tableClassName={"GriddleTable table"}
          settingsToggleClassName={"btn btn-default"}
          columns={["_id", "name", "type", "email", "actions"]}
        />

        <Modal show={this.state.showModal} onHide={this.handleClose} className="Modal Modal--medium">
          <Modal.Header closeButton>
            <h1>Acessos</h1>
          </Modal.Header>
          <Modal.Body>
            <StaffForm
              staff={this.state.staff}
              handleClose={this.handleClose}
              saveStaff={this.props.saveStaff}
              getStaffs={this.props.getStaffs}
              getProfiles={this.profiles}
            />
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}
