import React, { Component } from 'react';
export default class Package extends Component {
  render() {
    const { data } = this.props;
    const { totalAmount, title } = data;

    return (
      <div>
        <h4 style={{ margin: 0, fontWeight: 'bold' }}>
            R$ {totalAmount.toFixed(2)}
        </h4>
        <h5 style={{ margin: 0 }}>
          {title['pt-BR']}
        </h5>
      </div>
    )
  }
}