/* global _ */

import { FETCH_SCHEDULES } from './../actions/schedules/fetch_schedules';
import { SET_SCHEDULES } from './../actions/schedules/set_schedules';
import { UPDATE_SCHEDULE_ADDRESS } from './../actions/schedules/update_schedule_address';
import { UPDATE_SCHEDULE_PARTNER } from './../actions/schedules/update_schedule_partner';
import { UPDATE_SCHEDULE_DATE } from './../actions/schedules/update_schedule_date';
import { UPDATE_SCHEDULE_SERVICE } from './../actions/schedules/update_schedule_service';
import { UPDATE_SCHEDULE_VOUCHER } from './../actions/schedules/update_schedule_voucher';
import { REMOVE_SCHEDULE_PARTNER } from './../actions/schedules/remove_schedule_partner';
import { CANCEL_SCHEDULE } from './../actions/schedules/cancel_schedule';
import { RECHARGE_SCHEDULE } from './../actions/schedules/recharge_schedule.js';

const schedule = (state, action) => {
  switch (action.type) {
    case CANCEL_SCHEDULE:
      if (state._id === action.meta.id) {
        return {
          ...state,
          status: [{
            code: action.meta.cancelReason.codeNumber
          },
            ...state.status
          ]
        };
      }
      
      return state;
    
    case UPDATE_SCHEDULE_PARTNER:
      if (state._id === action.meta.id) {
        return {
          ...state,
          partner: action.meta.partner,
          status: [{
            code: 2
          },
            ...state.status
          ]
        };
      }
      
      return state;
    
    case REMOVE_SCHEDULE_PARTNER:
      if (state._id === action.meta.id) {
        return {
          ...state,
          partner: {},
          status: [{
            code: 1
          },
            ...state.status
          ]
        };
      }
      
      return state;
    
    case UPDATE_SCHEDULE_VOUCHER:
      if (state._id === action.meta.id) {
        return {
          ...state,
          voucher: action.meta.voucher
        };
      }
      
      return state;
    
    case UPDATE_SCHEDULE_SERVICE:
      if (state._id === action.meta.id) {
        return {
          ...state,
          services: [action.meta.service]
        };
      }
      
      return state;
    
    case UPDATE_SCHEDULE_DATE:
      if (state._id === action.meta.id) {
        return {
          ...state,
          date: action.meta.newDate,
          dateRangeStart: action.meta.newDate,
          dateRangeEnd: action.meta.newDateEnd
        };
      }
      
      return state;
    
    case UPDATE_SCHEDULE_ADDRESS:
      if (state._id === action.meta.id) {
        return {
          ...state,
          address: {
            ...state.address,
            ...action.meta.data
          }
        };
      }
      
      return state;
  }
};

const INITIAL_STATE = {
  all: []
};

const schedules = (state = INITIAL_STATE, action) => {
  if (action.error) {
    return state;
  }
  
  const {
    data
  } = action.payload || {};
  
  switch (action.type) {
    // case UPDATE_SCHEDULE_TAKE_RATE:
    //   return {
    //     ...state,
    //       all: state.all.map(schedule => {
    //         if (action.meta.id === schedule._id) {
    //           console.log("Volto o takeRate");
    //           return schedule
    //         }
    //         console.log("Volto o schedule")
    //         return schedule
    //       })
    //   }
    
    case FETCH_SCHEDULES:
      return {
        ...state,
        all: data.schedules,
        numberOfPages: Math.ceil(data.totalItems / data.itemsPerPage)
      };
    
    case SET_SCHEDULES:
      return {
        ...state,
        all: action.meta.schedules
      };
    
    case CANCEL_SCHEDULE:
      return {
        ...state,
        all: _.map(state.all, s => schedule(s, action))
      };
    
    case UPDATE_SCHEDULE_PARTNER:
      return {
        ...state,
        all: _.map(state.all, s => schedule(s, action))
      };
    
    case REMOVE_SCHEDULE_PARTNER:
      return {
        ...state,
        all: _.map(state.all, s => schedule(s, action))
      };
    
    case UPDATE_SCHEDULE_VOUCHER:
      return {
        ...state,
        all: _.map(state.all, s => schedule(s, action))
      };
    
    case UPDATE_SCHEDULE_SERVICE:
      return {
        ...state,
        all: _.map(state.all, s => schedule(s, action))
      };
    
    case UPDATE_SCHEDULE_DATE:
      return {
        ...state,
        all: _.map(state.all, s => schedule(s, action))
      };
    
    case UPDATE_SCHEDULE_ADDRESS:
      return {
        ...state,
        all: _.map(state.all, s => schedule(s, action))
      };
    
    case RECHARGE_SCHEDULE:
      return state;
    
    default:
      return state;
  }
};

export default schedules;