import React, { Component } from "react";

import AreasGridle from "./AreasGriddle";
import Loading from "../Global/Loading/Loading";
import PageHeader from '../Global/PageHeader';

export default class ListAreasComponent extends Component {
  constructor() {
    super();
    this.state = {
      activeKey: "1"
    }
  }

  handleSelect = (activeKey) => {
    this.setState({ activeKey });
  }

  render() {
    return (
      <div className="row">
        <div className="col-md-12">
          <PageHeader title="Cidades disponíveis" />
          {this.props.status === "LOADING" ? 
            <Loading /> :
            <AreasGridle areas={this.props.areas} />
          }
        </div>
      </div>
    )
  }
}