import React, { Component } from 'react';

import './ProgressBar.css';

export default class ProgressBar extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { height, value, max, steps, style } = this.props;

    const heightAdjusted = `${height || 30}px`

    return (
      <div className="progressbar_container" style={style}>
        <progress
          id="current_progress"
          value={value}
          max={max}
          style={{ height: heightAdjusted }}
        ></progress>
        <div className="progressbar_steps">{steps}</div>
      </div>
    )
  }
}