const convertImage = (imageToConvert, newExtention) => {
  return new Promise((resolve, reject) => {
    const canvas = document.createElement('canvas');
    const retryInterval = setInterval(() => {
      canvas.width = imageToConvert.width;
      canvas.height = imageToConvert.height;
      canvas.getContext('2d').drawImage(imageToConvert, 0, 0, imageToConvert.width, imageToConvert.height);
      canvas.toBlob(handleBlob, `image/${newExtention}`, 1);
    }, 1000)

    let count = 0;
    const handleBlob = blob => {
      if(blob){
        clearInterval(retryInterval); 
        resolve({ blob, base64: canvas.toDataURL(`image/${newExtention}`) })
      } else{
        if(count >= 10) {
          reject({ error: "Não foi possível fazer o upload da imagem." })
          clearInterval(retryInterval);
        }else{
          count++;
          console.log(count)
        }
      }
    }
    
  });
};

export default convertImage;
 