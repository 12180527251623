import config from '../../../config.json';
const env = process.env.REACT_APP_ENVIRONMENT || 'stage';
const BACKEND_URL = config.api[env];
import axios from 'axios';

import { getToken } from './../../models/auth';

export const UPDATE_USER = 'UPDATE_USER';

export function updateUser(id, data) {
  const userUpdatePromise = axios.put(`${BACKEND_URL}/v2/bo/user/${id}`, data,
    {
      headers: { Authorization: `Bearer ${getToken()}` }
    }
  );

  return {
    type: UPDATE_USER,
    payload: userUpdatePromise,
    meta: { data }
  };
}
