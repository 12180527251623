import React from 'react';

import RadioButton from "./RadioButton";

export default function ({ name, selected, itemsList, onChange }) {
  return (
    <div className="col-xs-12">
      {
        itemsList.map(item => (
          <RadioButton
            key={item.value.toString()}
            onChange={onChange}
            name={name}
            checked={selected === item.value}
            text={item.text}
            value={item.value.toString()}
          />
        ))
      }
    </div>
  )
}