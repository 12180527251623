import axios from 'axios';
import config from '../../../config.json';
const env = process.env.REACT_APP_ENVIRONMENT || 'stage';
const NODE_API = config.nodeAPI[env];

import { getToken } from '../../models/auth';

export function getUrl({ email, id }) {

  let url = `${NODE_API}/natura/getIntegrationUrl`
  if (email) url += `?email=${email}`;
  if (id) url += `?id=${id}`;

  return axios.get(url, {
    headers: { Authorization: `Bearer ${getToken()}` }
  })
}