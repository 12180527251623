import React, { Component, PropTypes } from 'react';

import CheckboxGroup from './../FormFields/CheckboxGroup';
import PageHeader from './../../components/Global/PageHeader';

import { sendPushNotification } from '../../actions/push_notifications';
import { Toast } from '../../helper/alerts';

// styles
import './../../style/form.css';

class SendPushForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      submitting: false
    }
  }

  renderCities() {
    const { cities, fields: { enabledCities } } = this.props;

    if (!cities) return null;

    return (
      <div className="Form-formGroup">
        <div className="row">
          <div className="col-sm-12">
            <CheckboxGroup
              name="enabledCities"
              label="Cidades"
              list={cities}
              inputData={enabledCities}
            />
          </div>
        </div>
      </div>
    );
  }

  onSubmit = async (event) => {
    try {
      event.preventDefault();

      const { fields: { pushText, pushTitle, pushQuery, pushLink, enabledCities } } = this.props;

      let query = pushQuery.value.replaceAll(/\s/g, '');

      const params = {
        title: pushTitle.value,
        message: pushText.value,
        link: pushLink.value,
        query,
        cities: enabledCities.value,
        targetType: 'user'
      }

      if (!params.title) {
        throw { description: 'Informe o titulo da notificação' };
      }

      if (!params.message) {
        throw { description: 'Informe a mensagem da notificação' };
      }

      if (params.link && params.link[0] === '/') {
        throw { description: 'A rota não deve iniciar com /' };
      }

      if (params.query) {
        try {
          const parsedQuery = JSON.parse(params.query);

          if (!Array.isArray(parsedQuery)) throw { description: 'Precisa ser um array' };

        } catch (error) {
          console.error(error);
          throw { description: 'Query está em formato invalido' };
        }
      }

      const confirmed = confirm(`Uma vez que a menssagem for enviada, não poderá ser cancelada.\nConfirma o envio da menssagem?`);

      if (!confirmed) return;

      this.setState({ submitting: true });

      const { data } = await sendPushNotification(params);

      Toast(3000).fire({ icon: 'success', title: data && data.description || 'Mensagem enviada com sucesso' });
    } catch (err) {
      console.error(err);

      let message = 'Ocorreu um erro ao enviar a mensagem';

      if (err.description) message = err.description;

      if (err && err.data && err.data.error && err.data.error.descriotion) message = err.data.error.descriotion;

      Toast(2000).fire({ icon: 'error', title: message });
    } finally {
      this.setState({ submitting: false });
    }
  }

  changeMessage(evt) {
    let { fields: { pushText } } = this.props
    pushText.onChange(evt.target.value)
  }

  changeTitle(evt) {
    let { fields: { pushTitle } } = this.props
    pushTitle.onChange(evt.target.value)
  }

  changeLink = (evt) => {
    let { fields: { pushLink } } = this.props
    pushLink.onChange(evt.target.value)
  }

  changeQuery(evt) {
    let { fields: { pushQuery } } = this.props
    pushQuery.onChange(evt.target.value)
  }

  render() {
    const { submitting } = this.state;

    return (
      <form onSubmit={evt => this.onSubmit(evt)} className={`Form ${submitting ? "is-submitting" : ""}`}>
        <PageHeader title={"Enviar Push Notification - Clientes"} />
        <div className="Form-labelWrapper">
          <label className="Form-label" htmlFor={name}>
            Título da Notificação *
          </label>
        </div>
        <input className="Form-input"
          type="text" id={"title"}
          ref="input"
          style={{ width: '100%' }}
          value={this.state.message}
          onChange={this.changeTitle.bind(this)}
        />
        <br /><br />
        <div className="Form-labelWrapper">
          <label className="Form-label" htmlFor={name}>
            Texto da Notificação *
          </label>
        </div>
        <textarea className="Form-input"
          type="text" id={"message"}
          ref="input"
          style={{ width: '100%', height: '200px' }}
          value={this.state.message}
          onChange={this.changeMessage.bind(this)}
        />
        <br /><br />
        <div className="Form-labelWrapper">
          <label className="Form-label" htmlFor={name}>
            Rota para o app (sem barra no início) ou link externo
          </label>
        </div>
        <input className="Form-input"
          type="text" id={"link"}
          ref="input"
          style={{ width: '100%' }}
          value={this.state.link}
          onChange={this.changeLink.bind(this)}
        />
        <br /><br />
        <div className="Form-labelWrapper">
          <label className="Form-label" htmlFor={name}>
            Query para segmentação
          </label>
        </div>
        <textarea className="Form-input"
          type="text"
          id={"query"}
          ref="input"
          style={{ width: '100%', height: '200px' }}
          value={this.state.query}
          onChange={this.changeQuery.bind(this)}
        />
        {this.renderCities()}

        <div className="Form-submitWrapper">
          <div className="row">
            <div className="col-sm-12">
              <button
                className="Form-submit"
                type="submit"
                disabled={submitting}
              >
                <span>{"Enviar Notificação"}</span>
                <span className="ss-users ss-icon" />
              </button>
            </div>
          </div>
        </div>
      </form >
    );
  }

}

SendPushForm.propTypes = {
  lead: PropTypes.object,
  fields: PropTypes.object,
  cities: PropTypes.array
};

export default SendPushForm;
