import React, { Component, PropTypes } from "react";
import PackageList from "../../components/Packages/PackagesList";
import { fetchAreas } from "./../../actions/areas/fetch_areas";
import { connect } from "react-redux";
import { backendAPI } from '../../helper/apis';
class Packages extends Component {
  componentWillMount() {
    this.getAreas();
  }

  getAreas() {
    return this.props.fetchAreas();
  }

  deletePackage(areaId, packId) {
    return backendAPI.delete(`/v2/pack/${areaId}/${packId}`);
  }

  addPackage(areas, pack) {
    pack.areas = areas;
    return backendAPI.post(`/v2/pack`, pack);
  }

  editPackage(areaId, params) {
    return backendAPI.put(`/v2/pack/${areaId}/${params._id}`, params);
  }

  render() {
    return (
      <PackageList
        areas={this.props.areas}
        deletePackage={this.deletePackage}
        addPackage={this.addPackage}
        editPackage={this.editPackage}
      />
    );
  }
}

Packages.propTypes = {
  fetchAreas: PropTypes.func
};

const mapActionsToProps = {
  fetchAreas
};

function mapStateToProps(state) {
  return {
    areas: state.areas.all
  };
}

export default connect(
  mapStateToProps,
  mapActionsToProps
)(Packages);
